import React from 'react';
import moment from 'moment';
import { Table, Icon, Popover, Tooltip } from 'antd';
import { IHLoading } from 'ihcomponent';
import { generatingCommonColumns } from '../helper/inventoryListComponentHelper';
import { NUM_OF_ROWS, checkHasToday } from '../constant/constants';

export default function InventoryListComponent(props) {
  const { dataSource, // sortDataSource,
          setTodayRef, //setSorterUpRef, setSorterDownRef,
          setIntersectionObserver, 
          fetchingMore, canFetchMore, openNoteModal, 
  } = props;
  // define columns
  const columns = [
    {
      title: 
      <div style={{ textAlign: 'center' }}>
        {/* <InventoryListSorter 
          sortDataSource={sortDataSource} 
          setSorterUpRef={setSorterUpRef} 
          setSorterDownRef={setSorterDownRef}
        /> */}
      </div>,
      // a,b are objects contain key date; if a.date or b.date is undefined => always 0 to keep last row / part of fetchMore staying at the same position,
      sorter: (a, b) => a.date ? a.date.valueOf() - b.date.valueOf() : 0, 
      children: [
        {
          title: (
            <div>
              <span>FORECAST</span>
              <Popover trigger='click' content='Estimated amount needed for next 2 weeks' placement='right'>
                <Icon type='info-circle' theme='outlined' />
              </Popover>
            </div>
          ),
          key: 'date',
          dataIndex: 'date',
          render: (_, record, index) => {
            const { date, week, month, notes } = record;
            // console.log('dateee ', week);
            // console.log('today ', moment().week());
            let ofToday = checkHasToday(record);
            // index < dataSource.length -1 is to support fetchMore effect
            return (index < dataSource.length -1) || !canFetchMore ? (
              <div 
                ref={ref => { if(ofToday) { setTodayRef(ref); }}}
                style={{ 
                  textAlign: 'center', 
                  fontWeight: ofToday ? 'bold' : '' ,
                  fontSize: ofToday ? 14 : '',
                }}
              >
                {
                  !week && !month ?
                    <div>
                      <span>{moment(date).format('MM/DD/YYYY')} </span>
                      <span style={{ marginLeft: 2 }}>
                        {
                          notes ? 
                            <Tooltip title='Click to view notes' placement='right'>
                              <Icon style={{fontSize: 13}} type="profile" onClick={() => openNoteModal({ date, notes })}/>
                            </Tooltip> 
                            : 
                            ''
                        }
                      </span>
                    </div>
                  :
                    week ?
                      <div>
                        Week {week} <br/>
                        <small>
                          {moment(date).startOf('week').format('MM/DD')} 
                          -
                          {ofToday ? moment().format('MM/DD/YY') : moment(date).endOf('week').format('MM/DD/YY')}
                        </small>
                      </div>
                      :
                      <div>
                        {month}
                      </div>
                }
              </div>
            ) :
            ({
              children: (
                <div
                  ref={ref => { if(canFetchMore) setIntersectionObserver(ref) }}
                  style={{ textAlign: 'center', color: '#1890ff', minHeight: 74 }}
                >
                  {fetchingMore ? <div><IHLoading style={{ fontSize: 20 }}/></div> : 'Scroll down to fetch more'}
                </div>
              ),
              props: { colSpan: 9 }
            })
          },
          width: 115,
        }
      ]
    },
    ...generatingCommonColumns(props)
  ];

  return (
    <div id='inventory-div-container'>
      <Table 
        columns={columns} 
        dataSource={dataSource}
        scroll={{ y: (NUM_OF_ROWS * 54) }} 
        rowKey={(_, i) => i}
        bordered={true} 
        pagination={false} 
      />
    </div>
  )
}
