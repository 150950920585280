import React, { Component } from 'react';
import {Col, Row, Select, Form, Input} from "antd";
import {helpers as dataHelper} from "../helper"

const {Item} = Form;
const {Option} = Select;

class VisitInputVisitAndNote extends Component{

    checkVisitRoles(rule, value, callback){
        if(value.length === 1 && value[0] ==='MD'){
            callback("You can't schedule a visit only for MD.");
        }
        callback();
    }

    render() {
        const {visitInfo, editStatus} = this.props;
        const { getFieldDecorator } = this.props.form;
        let reason = null;
        let visitRoles = Object.keys(dataHelper.VISIT_ROLES);
        if(editStatus){
            reason = visitInfo.reason;
            visitRoles = visitInfo.visitRoles;
        }

        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Item label="Visit" >
                        {getFieldDecorator('visitRoles', {
                            initialValue: visitRoles,
                            rules: [
                                { required: true, message: 'Please select the care teams!', type: 'array' },
                                { validator: this.checkVisitRoles}
                            ],
                        })(
                            <Select mode="multiple">
                                {
                                    _.map(dataHelper.VISIT_ROLES, (item, key) => {
                                        return (
                                            <Option key={key} value={key}>{item}</Option>
                                        )
                                    })
                                    // dataHelper.VISIT_ROLES.map(value => {
                                    //     return (
                                    //         <Option key={value} value={value}>{value}</Option>
                                    //     )
                                    // })
                                }
                            </Select>
                        )}
                    </Item>
                </Col>
                <Col span={12}>
                    <Item label="Note" >
                        {getFieldDecorator('reason', {
                            initialValue: reason,
                            rules: [{required: false}]
                        })
                        (
                            <Input />
                        )}
                    </Item>
                </Col>
            </Row>
        )
    }
}

export default VisitInputVisitAndNote;
