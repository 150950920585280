import React from 'react';
import formMap from '../constant/formMap';
import helpers from '../helpers/';
import { Form, Row, Col, Button, Modal } from 'antd';
import { IHLoading, message } from 'ihcomponent';
import editVisit from 'graphqlModule/mutation/editVisit';
import Client from 'libModule/gqlClient';
import HostAndBPMComponent from '../../patient/DevicesAssign/component/hotSpotAndBPMComponent';
import LoanDeviceComponent from '../../patient/loanDevices/components/LoadDeviceComponent';
import iotDeviceList from '../../patient/DevicesAssign/query/iotDeviceList';
import getLoanDevice from '../../patient/loanDevices/query/getLoanDevice';
import API from '../../patient/DevicesAssign/query/API';
import { compose, graphql } from 'react-apollo/index';
import UnassignLoanDevice from './UnassignLoanDevice';
import singleVisit from '../../graphql/signleVisit';
const fieldName = ['EMRAttached', 'a1c'];
const { createForm, leaveAndSaveWorkTask } = helpers;
const {
  onBoardFormMap,
  editDeviceFormMap,
  giveOutDevicesAndUserProfile
} = formMap;

const titles = {
  trackSet: {
    title: 'Track Set device',
    note: 'To uncheck Track Set, you need to unassign the device first'
  },
  iPhone: {
    title: 'IPhone Device',
    note: 'To uncheck iPhone, you need to unassign the device first'
  }
};

const parseValue = (props, state, localThis) => {
  const {
    userId,
    toNextStep,
    form,
    visit,
    id: {visitId},
    setIsEditMode,
    followUpEditDevices
  } = props;
  // const { followUpEditDevices } = state;
  let { userDeviceRecords = {}, id, type } = visit;
  const isFollowUp = type === 'FOLLOW_UP';
  const { getFieldsValue } = form;
  const {
    easeCheckbox, easeAppSync,
    alignCheckbox, alignAppSync,
    linaCheckbox, linaAppSync,
    a1c, a1cDate, a1cId,
    EMRAttached, autoUpdateStatus, addToContactsStatus,
    Strips, Lancets,
    spokenLanguage, appLanguage, techLevel,
    iPhone, trackSet: TrackSet,
    giveOutEase, giveOutLina, giveOutAlign, giveOutLancets, giveOutStrips
  } = getFieldsValue();
  const StripsFromDeviceCount = _.get(visit, 'deviceRecordCount.Strips');
  const LancetsFromDeviceCount = _.get(visit, 'deviceRecordCount.Lancets');

  let updatedUserDeviceRecords = Object.assign(userDeviceRecords, {
    Ease: easeAppSync || 'INVALID',
    Align: alignAppSync || 'INVALID',
    Lina: linaAppSync || 'INVALID',
    Strips,
    Lancets,
    iPhone,
    TrackSet
  });

  let userDeviceRecordsFromUser = _.get(
    props,
    'visit.member.profile.deviceRecords',
    {}
  );

  if (!isFollowUp) {
    userDeviceRecordsFromUser = Object.assign(
      userDeviceRecordsFromUser || {},
      updatedUserDeviceRecords
    );
  }

  let deviceRecordCount = {
    Ease: isFollowUp ? giveOutEase || 0 : easeAppSync == 'APP_SYNC' ? 1 : 0,
    Align: isFollowUp ? giveOutAlign || 0 : alignAppSync == 'APP_SYNC' ? 1 : 0,
    Lina: isFollowUp ? giveOutLina || 0 : linaAppSync == 'APP_SYNC' ? 1 : 0,
    Strips: isFollowUp ? giveOutStrips || 0 : StripsFromDeviceCount,
    Lancets: isFollowUp ? giveOutLancets || 0 : LancetsFromDeviceCount
  };

  let A1CInput = {
    id: a1cId,
    value: a1c,
    dateCollected: a1cDate
  };

  //   console.log('followUpEditDevices ', followUpEditDevices);
  //   console.log(userDeviceRecordsFromUser);
  //   console.log('omit ', _.omit(userDeviceRecordsFromUser, '__typename'));

  let variables = {
    id,
    EMRAttached,
    A1C: A1CInput,
    userLanguage: spokenLanguage,
    userAppLanguage: appLanguage,
    userTechLevel: techLevel,
    autoUpdateStatus: autoUpdateStatus ? 'COMPLETED' : 'PENDING',
    addToContactsStatus: addToContactsStatus ? 'COMPLETED' : 'PENDING',
    userDeviceRecords: followUpEditDevices
      ? updatedUserDeviceRecords
      : _.omit(userDeviceRecordsFromUser, '__typename')
  };
  if (spokenLanguage) {
    variables.deviceRecordCount = deviceRecordCount;
  }
  const copyVal = Object.assign({}, variables);
  copyVal.visitType = type;
  const session = JSON.parse(sessionStorage.getItem(props.id));
  if(isFollowUp && !session) {
    if(localThis.originalStatus.phone) _.set(copyVal, 'userDeviceRecords.iPhone', false);
    if(localThis.originalStatus.trackSet) _.set(copyVal, 'userDeviceRecords.TrackSet', false);
  }
  _.set(copyVal, 'visitId', visitId);
  _.set(copyVal, 'memberId', userId); // patientId
  sessionStorage.setItem(props.id, JSON.stringify(copyVal));
  return variables;
};
let OnBoardComponent = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleType: '',
      followUpEditDevices: false
    };

    (this.assignedTrackSet = undefined),
      (this.assignedPhone = undefined),
      (this.trackSetInfo = {
        deviceId: 0
      }),
      (this.phoneInfo = {
        deviceId: 0,
        serialNumber: 'N/A',
        version: 'N/A'
      });
    this.easeCheckbox = false;
    this.originalStatus = { trackSet: undefined, phone: undefined }; // for inventory check
  }

  saveEdit = (flag, setEditMode, msg) => {
    const {
      toNextStep,
      form,
      visit,
      setIsEditMode,
      user,
      resetDraftState
    } = this.props;
    const { followUpEditDevices } = this.state;
    // let { userDeviceRecords={},id } = visit;
    // const { getFieldsValue } = form;
    // const { easeCheckbox,easeAppSync,alignCheckbox,alignAppSync,linaCheckbox,linaAppSync,a1c,a1cDate,a1cId,
    //         EMRAttached,autoUpdateStatus,addToContactsStatus,Strips,Lancets,spokenLanguage,appLanguage,techLevel, iPhone, trackSet: TrackSet } = getFieldsValue();
    // let updatedUserDeviceRecords = Object.assign(userDeviceRecords,{
    //     Ease:easeAppSync,Align:alignAppSync,Lina:linaAppSync,Strips,Lancets, iPhone, TrackSet
    // })
    const userId = _.get(user, 'id');
    const visitId = _.get(visit, 'id');
    let refetchQueries = [];
    if (!flag) {
      refetchQueries = [
        {
          query: singleVisit,
          variables: {
            id: visitId
          },
          fetchPolicy: 'network-only'
        }
      ];
    }
    Client.mutate({
      mutation: editVisit,
      variables: parseValue(Object.assign({ followUpEditDevices }, this.props), {}, this),
      refetchQueries
    }).then(res => {
      flag ? toNextStep('onBoard', null, msg) : message.success(msg);
      setIsEditMode(false);
      resetDraftState();
      this.setState({
        followUpEditDevices: false
      });
    });
  };

  handleOnClick = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll(fieldName, (error, fields) => {
      if (!error) {
        this.saveEdit(true, true, 'On Board is Saved.');
      }
    });
  };
  saveDevices = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll((error, fields) => {
      if (!error) {
        this.saveEdit(false, true, 'Devices is Saved.');
      }
    });
  };
  componentDidMount = () => {
    const {
      iotDeviceData,
      loanDeviceData,
      loadingIoTDeviceData,
      loadingLoanDeviceData
    } = this.props;
    const { assignedTrackSet, assignedPhone } = this.props;
    if (!(loadingIoTDeviceData || loadingLoanDeviceData)) {
      if (iotDeviceData && iotDeviceData.length > 0 && !assignedTrackSet) {
        const trackSetInfo = { deviceId: iotDeviceData[0].deviceId };
        this.setState({ assignedTrackSet: true, trackSetInfo });
      }
      if (loanDeviceData && !assignedPhone) {
        const phoneInfo = {
          deviceId: loanDeviceData.deviceId,
          serialNumber: loanDeviceData.serialNumber,
          version: loanDeviceData.appVersion
        };
        this.setState({ assignedPhone: true, phoneInfo });
      }
    }
  };

  componentWillUnmount() {
    const { props } = this;
    const { setIsEditMode, isEditMode } = props;
    if (isEditMode) {
      setIsEditMode(false);
    }
  }

  handleOnClickSaveDraft = () => {
    const { saveEdit, props } = this;

    saveEdit(false, true, 'On Board Draft is Saved.');
  };

  renderGaveOutDevices = () => {};

  render() {
    const { handleOnClick, handleOnClickSaveDraft, saveDevices } = this;
    const {
      loadingIoTDeviceData,
      loadingLoanDeviceData,
      refetchIoTDeviceData,
      refetchLoanDeviceData,
      currentProgram,
      form,
      isEditMode,
      iotDeviceData,
      loanDeviceData,
      visit
    } = this.props;
    const { getFieldValue, getFieldsValue, setFieldsValue } = form;
    const { followUpEditDevices } = this.state;
    const deviceRecord = _.get(visit, 'member.profile.deviceRecords') || {};
    const { Ease, Align, Lina } = deviceRecord;
    const { easeCheckbox, alignCheckbox, linaCheckbox } = getFieldsValue();
    const visitType = _.get(visit,'type');
    const isFollowUp = visitType == 'FOLLOW_UP';
    if (loadingIoTDeviceData || loadingLoanDeviceData) {
      return <IHLoading />;
    }

    const tasks = _.get(currentProgram, 'tasks', []);
    let hasBP = !Ease ? _.filter(tasks, t => t.type == 'BP').length > 0 : true; // device is pre-selected => true all-time
    let hasBG = !Align ? _.filter(tasks, t => t.type == 'BG').length > 0 : true; // device is pre-selected => true all-time
    let hasHS = !Lina ? _.filter(tasks, t => t.type == 'HS').length > 0 : true; // device is pre-selected => true all-time
    let bpChecked = !Ease
      ? hasBP
      : easeCheckbox !== undefined
      ? easeCheckbox
      : Ease === 'APP_SYNC';
    let bgChecked = !Align
      ? hasBG
      : alignCheckbox !== undefined
      ? alignCheckbox
      : Align === 'APP_SYNC';
    let hsChecked = !Lina
      ? hasHS
      : linaCheckbox !== undefined
      ? linaCheckbox
      : Lina === 'APP_SYNC';

    // set default value
    _.set(this, 'assignedTrackSet', false);
    _.set(this, 'assignedPhone', false);

    if (
      iotDeviceData &&
      iotDeviceData.length > 0 &&
      !_.get(this, 'assignedTrackSet')
    ) {
      _.set(this, 'assignedTrackSet', true);
      _.set(this, 'trackSetInfo', { deviceId: iotDeviceData[0].deviceId });
    }
    if (loanDeviceData && !_.get(this, 'assignedPhone')) {
      _.set(this, 'assignedPhone', true);
      _.set(this, 'phoneInfo', {
        deviceId: loanDeviceData.deviceId,
        serialNumber: loanDeviceData.serialNumber,
        version: loanDeviceData.appVersion
      });
    }

    const session = JSON.parse(sessionStorage.getItem(this.props.id));
    if(isFollowUp && !session && _.get(this, 'originalStatus.trackSet') === undefined && _.get(this, 'originalStatus.phone') === undefined){
      _.set(this, 'originalStatus.trackSet', _.get(this, 'assignedTrackSet'));
      _.set(this, 'originalStatus.phone', _.get(this, 'assignedPhone'));
    }

    const formMapFn =
      isFollowUp && followUpEditDevices
        ? editDeviceFormMap
        : isFollowUp
        ? giveOutDevicesAndUserProfile
        : onBoardFormMap;
    const handleCheck = (type, e) => {
      // if device with given type is assigned, prompt user to unassign before unchecking
      const assignedType =
        type === 'trackSet' ? 'assignedTrackSet' : 'assignedPhone';
      if (_.get(this, `${assignedType}`)) {
        return this.setState({ visible: true, visibleType: type }, () => {
          // keep checkbox checked
          setFieldsValue({
            [type]: true
          });
        });
      }
      this.setState({ visible: true, visibleType: type });
    };

    const openModal = type =>
      this.setState({ visible: true, visibleType: type });

    const onClose = () => {
      const type =
        this.state.visibleType === 'trackSet' ? 'trackSet' : 'iPhone';
      const assignedType =
        this.state.visibleType === 'trackSet'
          ? 'assignedTrackSet'
          : 'assignedPhone';
      setFieldsValue({
        [type]: _.get(this, assignedType)
      });
      this.setState({ visible: false, visibleType: '' });
    };

    const setHostDeviceAssignment = isAssigned => {
      if (!isAssigned) {
        setFieldsValue({
          easeCheckbox: _.get(this, 'easeCheckbox'),
          easeAppSync: _.get(this, 'easeCheckbox')
            ? 'APP_SYNC'
            : 'MANUAL_INPUT',
          trackSet: false
        });
        refetchIoTDeviceData();
      } else {
        _.set(this, 'easeCheckbox', getFieldValue('easeCheckbox'));
        setFieldsValue({
          easeCheckbox: false,
          easeAppSync: null,
          trackSet: true
        });
      }
      const stayOn = isAssigned === true;
      this.setState({ visible: stayOn, visibleType: stayOn ? 'trackSet' : '' });
    };

    const setLoanDeviceAssignment = isAssigned => {
      // use manually setFieldsValue here to make sure iPhone checkbox works correctly
      refetchLoanDeviceData();
      this.setState({ visible: false, visibleType: '' }, () =>
        setFieldsValue({ iPhone: isAssigned })
      );
    };

    const updateTrackSetConfig = () => {
      refetchIoTDeviceData();
      onClose();
    };

    let newThis = Object.assign({}, this);
    return (
      <div>
        <Row>
          {isFollowUp && followUpEditDevices ? (
            <div>
              <Col span={24}>
                <a
                  onClick={() => this.setState({ followUpEditDevices: false })}
                  style={{ fontSize: 18, color: '#555555' }}
                >{`< Back`}</a>
              </Col>
              <Col span={24} style={{ marginTop: 40, marginBottom: 10 }}>
                <h4>Edit Devices</h4>
              </Col>
            </div>
          ) : (
            ''
          )}
          <Col span={24}>
            {createForm(
              newThis,
              formMapFn(
                this,
                hasBP,
                hasBG,
                hasHS,
                bpChecked,
                bgChecked,
                hsChecked,
                _.get(this, 'assignedTrackSet'),
                _.get(this, 'assignedPhone')
              ),
              'onBoardForm',
              handleCheck,
              openModal,
              _.get(this, 'phoneInfo')
            )}
            {/*{isFollowUp ?  <a onClick={()=>this.setState({ followUpEditDevices : true })}>Edit Configuration</a> : ''}*/}
          </Col>
        </Row>
        <Modal
          visible={this.state.visible}
          onCancel={onClose}
          destroyOnClose={true}
          footer={null}
          width={360}
        >
          {titles[this.state.visibleType] && (
            <div style={{ marginTop: 25, paddingLeft: 9 }}>
              <h4>{titles[this.state.visibleType].title}</h4>
              {_.get(this, [
                `assigned${
                  this.state.visibleType === 'trackSet' ? 'TrackSet' : 'Phone'
                }`
              ]) && <small>{titles[this.state.visibleType].note}</small>}
            </div>
          )}
          <div
            style={{
              marginTop: 10,
              padding: this.state.visibleType === 'iPhone' ? 10 : 0
            }}
          >
            {this.state.visibleType === 'trackSet' ? (
              <HostAndBPMComponent
                {...this.props}
                forModal={true}
                state={{ clearInfo: { disabled: false } }} // mimic behavior of patient vitals dashboard
                updateIoTDeviceMemberAPI={API.updateIoTDeviceMemberAPI}
                editIoTDeviceConfigAPI={API.editIoTDeviceConfigAPI}
                removeIotDeviceMemberAPI={API.removeIotDeviceMemberAPI}
                setAssignment={setHostDeviceAssignment}
                isAssigned={_.get(this, 'assignedTrackSet')}
                deviceId={_.get(this, 'trackSetInfo.deviceId')}
                iotDeviceList={iotDeviceData}
                updateTrackSetConfig={updateTrackSetConfig}
              />
            ) : _.get(this, 'assignedPhone') ? (
              <UnassignLoanDevice
                {...this.props}
                phoneInfo={_.get(this, 'phoneInfo')}
                setAssignment={setLoanDeviceAssignment}
              />
            ) : (
              <LoanDeviceComponent
                {...this.props}
                state={{ loanDeviceInfo: { disabled: false } }} // mimic behavior of patient vitals dashboard
                forModal={true}
                setAssignment={setLoanDeviceAssignment}
              />
            )}
          </div>
        </Modal>

        <Row>
          {!followUpEditDevices ? (
            <Col style={{ float: 'right' }}>
              {isEditMode ? (
                <Button
                  onClick={() => handleOnClickSaveDraft()}
                  style={{ width: 130, marginRight: 10 }}
                >
                  Save Draft
                </Button>
              ) : (
                ''
              )}
              <Button
                onClick={() => handleOnClick()}
                type={'primary'}
                style={{ width: 130 }}
              >
                Complete
              </Button>
            </Col>
          ) : (
            ''
          )}
          {followUpEditDevices ? (
            <Col>
              <Button
                onClick={() => saveDevices()}
                type={'primary'}
                style={{ width: 130, marginRight: 10 }}
              >
                Save
              </Button>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </div>
    );
  }
};

OnBoardComponent = Form.create({
  onFieldsChange(props, fields) {
    const isCompleted =
      _.get(props, 'visit.maWorkingList.onBoard') == 'COMPLETED';
    const { childProps, setChildProps, isEditMode, user, visit } = props;
    const userId = _.get(user, 'id');
    const visitId = _.get(visit, 'id');
    if (!childProps) {
      let refetchQueries = [
        {
          query: singleVisit,
          variables: {
            id: visitId
          },
          fetchPolicy: 'network-only'
        }
      ];
      setChildProps(props, parseValue, editVisit, refetchQueries, 'On Board');
    }
    if (!isEditMode) {
      props.setIsEditMode(true);
    }
    if (isCompleted) {
      leaveAndSaveWorkTask('onBoard', null, props);
    }
  }
})(OnBoardComponent);
// Fetch trackSet / iPhone data for patient, if applicable
const getIoTDeviceListData = graphql(iotDeviceList, {
  options: ownProps => {
    const userId = _.get(ownProps, 'data.user.id', _.get(ownProps, 'userId'));
    return {
      variables: {
        memberId: userId
      },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const iotDeviceList = _.get(data, 'iotDeviceList.data', []);
    return {
      iotDeviceData: iotDeviceList,
      refetchIoTDeviceData: data.refetch,
      loadingIoTDeviceData: data.loading
    };
  }
});

const getLoanDeviceData = graphql(getLoanDevice, {
  options: ownProps => {
    const memberId = _.get(ownProps, 'data.user.id', _.get(ownProps, 'userId'));
    return {
      variables: {
        memberId
      },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const getLoanDevice = _.get(data, 'getLoanDevice', null);
    return {
      loanDeviceData: getLoanDevice,
      // doesn't work properly without notifyNetworkStatusChange which causes refresh the page and retains initial state
      refetchLoanDeviceData: data.refetch,
      loadingLoanDeviceData: data.loading
    };
  }
});

export default compose(
  getLoanDeviceData,
  getIoTDeviceListData
)(OnBoardComponent);
