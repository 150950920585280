import { React, IHButton, IHBaseFormWithRow,message } from 'ihcomponent';
import { connect } from 'react-redux';
import moment  from 'moment';
import Client from 'libModule/gqlClient';
import getFoodLog from 'graphqlModule/getFoodLog';
import BGRes from '../query/index'
import { compose } from 'react-apollo';
import {graphql} from "react-apollo/index";
import addFoodLogComment from 'graphqlModule/mutation/addFoodLogComment';
import rateFoodLog from 'graphqlModule/mutation/rateFoodLog';
import { Rate,Alert,Form,Input } from 'antd';
import "antd/lib/alert/style/index.css";
import { convertBG_mmolTomgdl } from "libModule/utils/convertUnits";
import I18N from 'modulesAll/I18N';
import * as timerActions from '../../timer/actions/index';
import TimerContainer from '../../timer/containers/TimerContainer';
import foodLogUnreadList from "graphqlModule/foodLogUnreadList";
import foodLogList from 'modulesAll/graphql/foodLogList';
import {browserHistory} from "react-router";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'lib/utils'
import helper from '../helper'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const { stopTimer,updateInterventionDetails }= timerActions.default;

let AddFoodLogNotes = class extends React.Component {
    static  displayName = 'AddFoodLogNotes';

    constructor(props){
        super(props);
        this.getPreImage.bind(this);
        this.getNextImage.bind(this);
        this.renderStartRating.bind(this);
        this.getFormProps.bind(this);
        this.renderFooter.bind(this);
        this.renderCommentsHis.bind(this);
        this.submitForm.bind(this);

        this.state= {
            imgIndex: 0,
        }
    }

    componentWillUnmount(){
        const id = this.props.row.id;
        this.props.reset(id);
    }

    renderFooter() {
        const styles = {
            footer: {
                display: 'flex',
                flexBasis: '100%',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: 10,
            }
        }

        const buttonProps = {
            cancel: {
                bsStyle: 'default',
                label: 'Cancel',
                style: {
                    marginRight: 10
                },
                onClick:(e)=>{
                    const user_id = _.get(this.props,'row.member.id');
                    Mixpanel.track('clicked','cancel','food log', {PATIENT_ID : user_id});
                    this.props.handleShowAddFoodLogModal(false);
                }
            },
            done: {
                onClick : (e)=>{
                    const user_id = _.get(this.props,'row.member.id');
                    this.submitForm();
                    Mixpanel.track('clicked','submit','food log', {PATIENT_ID : user_id});
                },
                bsStyle: 'primary',
                label: 'Submit',
            }
        }

        const footer = (
            <div style={styles.footer}>
                <IHButton {...buttonProps.cancel} />
                <IHButton {...buttonProps.done} />
            </div>
        )
        return footer
    }

    getVariables(ownProps){
        const { filter } = ownProps;
        const  variables = {
            page: _.get(ownProps,'page.current',1),
            count: 10,
        }
        const defaultFilter = ownProps.userId ? '0' : 'false';
        const rating = _.get(filter,'rating[0]',defaultFilter);
        if (rating== '0') {
            variables.filters = {};
        }
        else
            variables.filters = {
                reviewed: (rating === 'true')
            }

        const sortField = 'CREATED_AT';
        variables.filters = Object.assign(variables.filters||{},ownProps.userId ? { memberId: ownProps.userId } : { });
        variables.sort = {
            field: sortField,
            direction: ownProps.sort.direction || 'DESC'
        }
        return variables;
    }

    validForm(validateFields){

          return new Promise((resolve,reject)=>{
              validateFields((error,data)=>{
                  if(!error){
                      resolve(true);
                  }
                  else{
                      resolve(false);
                  }
          });
        });
    }

    async submitForm (){
        const { props } = this;
        const { form } = props;
        const { id } = props.row;
        const { validateFields,getFieldsValue } = form;
        const  userId = sessionStorage.getItem('currentUserId');
        const patientId = _.get(props,'row.member.id');
        const { rating, text } = getFieldsValue();

        if(!(await this.validForm(validateFields) )){
                return;
        }

        const comment = {
                            text: text.trim(),
                            commentedById: userId
                        };

        const rateVariables = {
                                id:id,
                                rating:{ star:rating }
                              };

        const addFoodLogCommentVars = {
                                        id: id,
                                        comment
                                      };

        const foodlogUnreadVariables =helper.getFoodLogVariables( {
                                            count: 9999,
                                            filter:{
                                                reviewed:false
                                            }
                                       });

        const refetchQueries = [{
                                    query:foodLogList,
                                    //variables:this.getVariables(this.props),
                                    variables:helper.getFoodLogVariables(this.props),
                                    fetchPolicy:'network-first'
                                },{
                                    query:foodLogUnreadList,
                                    variables:foodlogUnreadVariables,
                                    //variables:helper.getFoodLogVariables(this.props),
                                    fetchPolicy:'network-first'
                                }];
        if(_.get(this,'props.keepEventListener')){
            const  memberId =this.props.userId;// _.get(props,'row.member.id');
            const variables=helper.getFoodLogVariables( {
                    count: 9999,
                    userId:memberId,
                    filter: {
                        reviewed: false,
                        memberId
                    }
                });

            refetchQueries.push({
                query:foodLogUnreadList,
                variables,
                fetchPolicy:'network-first'
            });
        }

        /*if update rating and comments*/
        if(rating&&text) {
            Client.mutate({
                mutation: rateFoodLog,
                variables: rateVariables,
                // refetchQueries
            }).then((res)=>{
                try{
                    const apiName = Object.keys(res.data)[0];
                    const docId = res.data[apiName].id;
                    const summary = res.data.rateFoodLog.rating.star;
                    const resource = atob(docId).split(':')[0];
                    let displayName = this.constructor.displayName;
                    displayName = `${displayName}:${patientId}`;

                    this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);

                    Client.mutate({
                        mutation: addFoodLogComment,
                        variables: addFoodLogCommentVars,
                        refetchQueries:refetchQueries
                    }).then((res) => {
                        const apiName = Object.keys(res.data)[0];
                        const docId = res.data[apiName].id;
                        const summary = _.last(res.data[apiName].comments).text;
                        const resource = atob(docId).split(':')[0];
                        let displayName = this.constructor.displayName;
                        displayName = `${displayName}:${patientId}`;
                        this.props.updateInterventionDetails(displayName, apiName, resource, docId, summary);
                        this.props.stopTimer(displayName, 'saveModal');
                        message.success('Comment added');
                        this.props.handleShowAddFoodLogModal(false);
                        this.props.reset(id);
                    })
                }catch(e){
                    console.log(e);
                }
            })
                .catch((error)=>{ this.props.openErrorModal(GQLHelper.formatError(error)); })
        }
        /*end of update rating and comments*/
        /* if only update comments*/
        else {
            if ((text || (!text.replace(/\s/g, '').length))) {
                Client.mutate({
                    mutation: addFoodLogComment,
                    variables: addFoodLogCommentVars,
                    refetchQueries: [{
                        query: foodLogList,
                        //variables: this.getVariables(this.props),
                        variables: helper.getFoodLogVariables(this.props),
                        fetchPolicy: 'network-first'
                    }, {
                        query: foodLogUnreadList,
                        variables: foodlogUnreadVariables,
                        fetchPolicy: 'network-first'
                    }]
                }).then((res) => {
                    const apiName = Object.keys(res.data)[0];
                    const docId = res.data[apiName].id;
                    const summary = _.last(res.data[apiName].comments).text;
                    const resource = atob(docId).split(':')[0];
                    let displayName = this.constructor.displayName;
                    displayName = `${displayName}:${patientId}`;
                    this.props.updateInterventionDetails(displayName, apiName, resource, docId, summary);
                    this.props.stopTimer(displayName, 'saveModal');
                    message.success('Comment added');
                    this.props.handleShowAddFoodLogModal(false);
                    this.props.form.resetFields();
                    this.props.reset(id);

                }).catch((error) => {
                    console.log(error);
                    this.props.openErrorModal(GQLHelper.formatError(error));
                })
            }
        }
        /*ends of update comments*/
        // const { props } = this;
        // const PATIENT_FULL_NAME = _.get(props,'row.member.fullName');
        // const PATIENT_ID = _.get(props,'row.member.id');
        // const FOOD_LOG_ID = _.get(props,'id');
        // Mixpanel.track('click','cancel','food_log',{PATIENT_FULL_NAME, PATIENT_ID, FOOD_LOG_ID});
    }

    sendMixPanelReq = (item)=>{
        const { props } = this;
        // const PATIENT_FULL_NAME = _.get(props,'row.member.profile.fullName');
        const PATIENT_ID = _.get(props,'row.member.id');
        const FOOD_LOG_ID = _.get(props,'row.id');
        Mixpanel.track('clicked',item,'food_log',{ PATIENT_ID, FOOD_LOG_ID});

    }
    getFormProps(){
            const itemList =
                [
                    [{
                        key: 'comments',
                        label: "Add Comment *",
                        type: 'text',
                        col: { span: 25, offset: 0 },
                        style:{height:'60px'},
                        size:'default',
                        className:'addComment',

                        onChange:(comment)=>{
                            const { props } = this;
                            const id = props.row.id;
                            props.updateComment(id,comment)
                        }
                    }]
                ]

        return itemList;

    }

    renderCommentsHis(){
        const { comments } = this.props.row;
        let commentsList = [];
        if(comments.length==0){
            return <div className='commentHis emptyHis'>
                     No Comments Yet
                   </div>
        }
        comments.map((comment,index)=>{
            const { text } = comment;
            const fullName = _.get(comment,'commentedBy.profile.fullName');
            const commentedAt = moment(comment.commentedAt).format('MM/DD/YYYY  HH:mm');
            commentsList.push(
                <div className='commentItem row' key={index}>
                    <div className='col-lg-12'>
                        <strong>{fullName}</strong><span>{commentedAt}</span>
                    </div>
                    <div className='col-lg-12'>
                        <p>{text}</p>
                    </div>
                </div>
            )
        })
        return  <div className='commentHis'>
                    {commentsList}
                </div>
    }

    renderSetStarRating(){
        const { props } = this;
        const { getFieldDecorator } = props.form;
        return <Form.Item label='Rating' style={{display:'flex',flexDirection:'row'}}>
                    { getFieldDecorator('rating',{
                            rules:[ {
                                         required:true,
                                         message:'Please rate this food log.',
                                         validator:(rule,value,callback)=>{
                                             if(!value){
                                                 callback('Please rate this food log.');
                                             }
                                             callback();
                                         }
                                    }
                                  ]})
                        (
                            <Rate />
                        )
                    }
              </Form.Item>
    }

    renderStartRating(rating){
        if(rating!==0){
            return this.props.renderStar(rating);
        }
        else{
           return this.renderSetStarRating();
        }
    }

    renderImgs(imgs){
        //TODO add onload spinner for better rendering;
        const imageIndex = this.state.imgIndex;
        if(imgs&&imgs.length >0 ) {
            const src = imgs[imageIndex].link;
            return (<div className='row' style={{display: 'flex', alignItems: 'center'}}>
                    <div className='col-lg-10 updateCol'>
                        <img className='mealImg' src={src} id='foodLogImg'/>
                    </div>
                </div>
            )
        }
    }

    getPreImage(){
        let imgIndex = this.state.imgIndex;
        if(imgIndex>0){
            imgIndex = imgIndex-1;
            this.setState({
                imgIndex:imgIndex
            })
        }
    }

    getNextImage(){
        const length = this.props.row.images.length-1;
        let imgIndex = this.state.imgIndex;
        if(imgIndex<length){
            imgIndex = imgIndex+1;
            this.setState({
                imgIndex:imgIndex
            })
        }
    }

    render() {
        const { props } = this;
        const { keepEventListener } = this.props;
        const { note,createdAt,images,uploadedAt,vitalTypes } = props.row;
        const { getFieldDecorator } = this.props.form;
        const member = _.get(props,'row.member',{});
        const userId = member.id;
        const rating = _.get(props,'row.rating',{});
        const docId = _.get(props,'row.id');
        const {fullName = '', fullNameWithNickname = ''} = _.get(props,'row.member.profile',{});
        const {language = ''} = _.get(props,'row.member.profile',{});
        const languageDescription = _.get(language,'description');

        const {appLanguage = ''} = _.get(props,'row.member.profile',{});
        const appLanguageDescription = _.get(appLanguage,'description');

        const mealTime = moment(createdAt).format('MM/DD/YYYY HH:mm');
        const uploadedAtTime = uploadedAt ? moment(uploadedAt).format('MM/DD/YYYY HH:mm'):'--';
        const { resultList,hidePatientName } = props;
        const mealType = _.toLower(_.upperFirst(_.get(props,'row.mealType','')));
        const beforeMeal = _.filter(resultList,(res)=>(res.beforeMeal&&res.mealType.toUpperCase()==mealType.toUpperCase()&&res.severity!="MISSED"));
        const afterMeal = _.filter(resultList,(res)=>(!res.beforeMeal&&res.mealType.toUpperCase()==mealType.toUpperCase()&&res.severity!="MISSED"));

        const beforeMealRes = beforeMeal[0] && _.get(beforeMeal[0],'blood_glucose') || "";
        const beforeMealSeverity = beforeMeal[0] && _.get(beforeMeal[0],'severity') || "";
        const beforeMealDate = beforeMeal[0] && _.get(beforeMeal[0],'date')||'';
        const formatedBeforeMealDate = beforeMealDate ? moment(beforeMealDate).format('HH:mm'):'';
        const beforeMealValue = (convertBG_mmolTomgdl(beforeMealRes.value)) || "";
        const beforeMealUnit = !!beforeMealValue ? I18N.get('measure.units_BG_US_standard'): "";
        const beforeMealResClassName = 'col-lg-12 BGRes'+' '+beforeMealSeverity;

        const afterMealRes = afterMeal[0] && _.get(afterMeal[0],'blood_glucose') || "";
        const afterMealValue = (convertBG_mmolTomgdl(afterMealRes.value)) || "";
        const afterMealUnit = !!afterMealValue ? I18N.get('measure.units_BG_US_standard'): "";
        const afterMealSeverity = afterMeal[0] && _.get(afterMeal[0],'severity') || "";
        const afterMealDate = afterMeal[0] && _.get(afterMeal[0],'date')||'';
        const formatedAfterMealDate = afterMealDate ? moment(afterMealDate).format('HH:mm'):'';
        const afterMealResClassName = 'col-lg-12 BGRes'+' '+afterMealSeverity;

        let formProps = {};
        let displayName = this.constructor.displayName;

        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        }
        const renderPatientName = (fullName, hidePatientName, userId)=>{
            const onClick = () => {
                Mixpanel.track('clicked','patient name','food log pop up review', {PATIENT_ID : userId});
                console.log('clicked patient name on food log pop up review, patient id: ' + userId)
                browserHistory.push(`/patients/${userId}/enrolledprogram/default`);
                this.props.handleShowAddFoodLogModal(false);
            }
            const language = languageDescription ?  '('+languageDescription+')' : ''
            const appLanguage = appLanguageDescription ?  '('+appLanguageDescription+')' : ''

            if(appLanguage){
                return <div className='patientNameContainer'>
                    <a className='patientName v-name' onClick={onClick}>{fullName} </a> App Language: {appLanguage}
                </div>
            }else {
                return <div className='patientNameContainer'>
                    <a className='patientName v-name' onClick={onClick}>{fullName} </a>
                </div>
            }

            // if(!hidePatientName) {
            //     return <div className='patientNameContainer'>
            //       <a className='patientName v-name' onClick={onClick}>{fullName} </a> App Language: {appLanguage}
            //     </div>
            // }
        }
        const hasBGReading = beforeMealValue || afterMealValue;

        const bgReading = hasBGReading ? <div className='col-lg-12 BGResHeader' >
                                            Glucose Results
                                            <div className='row'>
                                                <div className={beforeMealResClassName} >
                                                    Before Meal - {formatedBeforeMealDate}
                                                    <span className='BGReading'>{beforeMealValue}</span><span className='BGUnit'>&nbsp;{beforeMealUnit} </span>
                                                </div>
                                                <div className={afterMealResClassName}>
                                                    After Meal - {formatedAfterMealDate}
                                                    <span className='BGReading'>{afterMealValue}</span><span className='BGUnit'>&nbsp;{afterMealUnit} </span>
                                                </div>
                                            </div>
                                        </div> :<div className='col-lg-12 BGResHeader' style={{height:100}}></div>;
        formProps.itemList = this.getFormProps();

        return (<div className="" style={{backgroundColor:'white',padding:20}}>
                    <div className='row foodlogNotes'>
                        <TimerContainer displayName={displayName} category='FOOD_LOG' action='REVIEW'
                                        viewingDetails={viewingDetails} patientId={member.id} docId={docId}
                                        keepEventListener={keepEventListener}
                        />
                        <div className='col-lg-6 foodlogNotesLeft'>
                           { renderPatientName(fullNameWithNickname,hidePatientName,userId)}
                            <div className='foodlogInfo'>
                                <div><strong className='foodlogInfoHeader'>Posted:</strong><span>{mealTime}</span></div>
                                <div><strong className='foodlogInfoHeader'>Uploaded:</strong><span>{uploadedAtTime}</span></div>
                                <div><strong className='foodlogInfoHeader'>Meal Type:</strong><span>{mealType}</span></div>
                                <div><strong className='foodlogInfoHeader'>Vitals:</strong><span>{vitalTypes.join(',')}</span></div>
                            </div>
                           <div className='patientNotesContainer'>
                               <strong className='foodlogInfoHeader'>Patient Comments</strong>
                               <div className='patientNotes'>{note}</div>
                           </div>
                            <div className="foodlogImgContainer">
                                <div className='foodlogImg'>
                                    {this.renderImgs(images)}
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <Form className='addFoodLogNote'>
                                <div className='row' style={{height:'100%'}}>
                                    {bgReading}
                                    <div className='col-lg-12' style={{'marginTop':'10px',display:'flex',alignItems:'center'}}>
                                        { this.renderStartRating(rating.star) }
                                    </div>
                                    <div className='col-lg-12 commentContainer'>
                                        <div className='header'>
                                            <label>Provider Comments:</label>
                                        </div>
                                        {this.renderCommentsHis()}
                                    </div>
                                    <div className='col-lg-12' style={{'marginTop':'15px'}}>
                                            <Form.Item label='Comments'>
                                                {getFieldDecorator('text',{ rules:[{ required:true,message:'Please add comment.',whitespace:true }]})(
                                                    <Input.TextArea type="text" autosize={{minRows: 2, maxRows: 6 }} />,
                                                )}
                                            </Form.Item>
                                        {this.renderFooter()}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
        </div>)
    }
};


const BGResultData = graphql(BGRes,{
    options:(ownProps)=>{
        const memberId = _.get(ownProps,'row.member.id');
        const createdAt = _.get(ownProps,'row.createdAt');
        const fromDate = moment(createdAt).startOf('day');
        const toDate = moment(createdAt).endOf('day');
        return {
                variables: {
                count:9999,
                filters:
                    {
                        memberId,
                        fromDate,
                        toDate
                    }
                },
                fetchPolicy:'network-only'
            }
    },
    props: (data) => {
        const { loading,resultList } = data.data;
        const type = _.get(data,'ownProps.row.mealType');
        if(resultList) {
            return {
                resultList: _.filter(resultList.data, (res) => res.mealType == type)

            }
        }
    }
})

const mapToState = ( state ,ownProps) => {
    const foodlog = state.foodlog;
    const id = ownProps.row.id;
    const logInfo = foodlog.main.logInfo[id] || {};

    return {
        logInfo: logInfo,
        ...state.globalTables.food_log_list,
    }
};

AddFoodLogNotes = Form.create()(AddFoodLogNotes);

const mapToDispatch = (dispatch)=>{
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}

export default compose(connect(mapToState,mapToDispatch),BGResultData)(AddFoodLogNotes);
