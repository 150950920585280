import {
  React, BaseComponent, ND, _, RB
} from './util'
import enUS from 'antd/lib/date-picker/locale/en_US'
import IHButton from './IHButton';
import IHSelect from './IHSelect'
import IHDatePicker from './IHDatePicker'
import IHTimePicker from './IHTimePicker'
import IHRadio from './IHRadio'
import IHTimePickerGroup from './IHTimePickerGroup'
import IHInputNumber from './IHInputNumber'
import IHCheckboxGroup from './IHCheckboxGroup'
import IHCheckboxButtonGroup from './IHCheckboxButtonGroup'
import detectIE from '../common/detectIE'
import debounce from 'lodash/debounce'

const isIE = detectIE()

const Component = class extends BaseComponent{

  render(){
    let p = this.props
    const type = p.type || 'text'
    //delete p.type

    let rs = null
    switch (type) {
      case 'selfDefine':
        rs = this.renderSelfDefine()
        break
      // component class, not instance or element
      case 'selfComponent':
        rs = this.renderSelfComponent()
        break
      case 'label':
        rs = this.renderLabel()
        break
      case 'divider':
        rs = this.renderDivider()
        break
      case 'text':
      case 'password':
        rs = this.renderText()
        break
      case 'inputNumber':
        rs = this.renderInputNumber()
        break
      case 'date':
        rs = this.renderDate()
        break
      case 'time':
        rs = this.renderTime()
        break
      case 'select':
        rs = this.renderSelect()
        break
      case 'textarea':
        rs = this.renderTextarea()
        break
      case 'radio':
      case 'radioButton':
        rs = this.renderRadio(type)
        break
      case 'timePickerBox':
      case 'timePickerBoxGroup':
        rs = this.renderTimePickerGroup()
        break;
      case 'checkboxButtonGroup':
        rs = this.renderCheckboxButtonGroup()
        break;
      case 'checkboxGroup':
        rs = this.renderCheckboxGroup()
        break;
      case 'button':
        rs = this.renderButton();
        break;
    }

    return rs
  }

  // https://stackoverflow.com/a/28046731
  debouncedOnChange = debounce(this.props.onChange, 100)
  onChangeForDebounce = (e) => {
    const value = e.target.value
    //this.debouncedOnChange(value) // temporally disable debounce until find a fix for type slow issue.
    this.props.onChange(value);
  }
  setBaseProp({ omitClearErrors = true, toDebounce = false } = {}){
    const prop  = _.omit(this.props, [
      'labelCol', 'wrapperCol', 'rules', 'hasFeedback', 'help',
      'initialValue', 'validateStatus',
      'extra', 'col', omitClearErrors && 'clearErrors',
    ])

    if(toDebounce) {
      prop.onChange = this.onChangeForDebounce
      // prop.defaultValue = prop.value
      // delete prop.value
    }

    return prop
  }

  renderText(){
    const p = this.setBaseProp({toDebounce: true})
    return (
      <ND.Input suffix={p.suffix} {...p} />
    )
  }

  renderTextarea(){
    const p = this.setBaseProp({toDebounce: true})
    return (
      <ND.Input.TextArea className="ih-textarea" {...p} />
    )
  }
  renderLabel(){
    const p = this.setBaseProp()
    return (
      <div className="ant-col-24 ant-form-item-label">
        <label className={p.className}></label>
      </div>
    )
  }
  renderDivider(){
    return (
      <div style={{height: '1px', border: '1px solid #ccc'}}>
      </div>
    )
  }
  renderInputNumber(){
    const p = this.setBaseProp()
    return (
      <IHInputNumber {...p} type="number"/>
    )
  }
  renderDate(){
    const p = this.setBaseProp()
    return (
      <IHDatePicker {...p} allowClear={false} />
    )
  }
  renderTime(){
    const p = this.setBaseProp()
    return (
      <IHTimePicker {...p} />
    )
  }
  renderSelect(){
    const p = this.setBaseProp()
    if (p.placeholder && !p.value) {
      delete(p.value)
    }
    return <IHSelect {...p} />
  }

  renderCheckboxButtonGroup(){
    const p = this.setBaseProp()
    const { onFieldsChange, id } = p
    const onChange = (value) => {
      const changedFields = {
        [id]: {
          name: id,
          value: value
        }
      }
      typeof onFieldsChange === 'function' && onFieldsChange(null, changedFields)
    }
    p.type = p.checkType
    delete p.checkType
    p.initValue = p.value || [] // value from antd form getFieldDecorator ? should we get initialValue back here ?
    p.onChange = onChange
    return <IHCheckboxButtonGroup {...p} />
  }

  renderCheckboxGroup() {
    const p = this.setBaseProp()
    const { onFieldsChange, id } = p
    const onChange = (value) => {
      const changedFields = {
        [id]: {
          name: id,
          value
        }
      }
      typeof onFieldsChange === 'function' && onFieldsChange(null, changedFields)
    }
    p.initValue = p.value || [] // value from antd form getFieldDecorator ? should we get initialValue back here ?
    p.onChange = onChange
    return <IHCheckboxGroup {...p} />
  }
  renderButton() {
    const p = this.setBaseProp();
    if (p.showButton) {
      return <IHButton label={ p.btnLabel } onClick={ p.onClick } className={ p.className } />
    } else {
      return null;
    }
  }
  renderRBTextarea(){
    const p = _.omit(this.setBaseProp(),['autosize']);
    return (
        <RBInputTextArea {...p}/>
    );
  }
  onValueChange=()=>{
    clearTimeout(this.timer);
    this.timer=setTimeout(()=>{
      if(this.props.onChange){
        this.props.onChange(this.textarea.refs.input.value);
      }
    },150);
  }

  renderTimePickerGroup(){
    const p = this.setBaseProp({ omitClearErrors: false })
    const { onFieldsChange, id } = p
    const onChange = (value) => {
      const changedFields = {
        [id]: {
          name: id,
          value: value
        }
      }
      onFieldsChange(null, changedFields)
    }
    p.initialValue = p.value
    delete p.value
    p.onChange = onChange;

    return <IHTimePickerGroup {...p} />
  }

  renderRadio(){
    const p = this.setBaseProp()
    return (
      <IHRadio className="ih-radio" {...p} />
    )
  }

  renderSelfComponent(){
    const p = this.setBaseProp()
    const SelfComponent = p.component
    delete p.component
    return <SelfComponent {...p} />
  }
  renderSelfDefine(){
    const p = this.setBaseProp()
    return <div>{p.element}</div>
  }
}
Component.propTypes = {

}

export default Component
