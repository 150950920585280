import {Address, Phone} from './common'

const UserProfile = `
  title
  firstName
  lastName
  nickname
  fullName
  fullNameWithNickname
  gender
  avatar {
    link
  }
  ...on MemberProfile {
    techLevel
    motivation
    birthday
    lastMeasuredAt
    race {
      code
      description
    }
    economicStatus
    occupation
    doctor{
      id
      profile{
        fullName
      }
    }
    insuranceProvider
    programCategories{
      name
      enrolled
      enrolledDate
    }
    language {
      code
      description
    }
    appLanguage {
      code
      description
    }
    maritalStatus{
      code
      description
    }
    nationality {
      code
      description
    }
    height
    weight
    latestWeight
    healthConditions
    billableHealthConditions{
      description
      code
    }
    yearOfDiagnose {
      condition
      diagnosedDate
    }
    insulinDependent
        BGTreatments
        stroke
        cardiovascularDisease
        retinopathy
        BGOther
        BGVisits
        BGMedicine
        BGTest{
          testRegularly
          freq
        }
        BPTest{
          testRegularly
          freq
        }
        kidneyDisease
        liverDisease
        heartAttack{
          hasHeartAttack
          year
         }
        exerciseHabits{
          value
          freq{
            value
            unit
          }
          duration
        }
        dietHabits{
          value
          other
          dietProperties
        }
        drinkingHistory{
          value
          startSinceYear
          drinkType
          freq{
            value
            unit
          }
          amount
          quitYear
        }
        smokingHistory{
           value
          startSinceYear
          freq{
            value
            unit
          }
          amount
          quitYear
        }
        spouseMedicalHistory
        fatherMedicalHistory
        motherMedicalHistory
        siblingMedicalHistory
        paternalFatherMedicalHistory
        paternalMotherMedicalHistory
        paternalUnclesOrAuntsMedicalHistory
        maternalFatherMedicalHistory
        maternalMotherMedicalHistory
        maternalUnclesOrAuntsMedicalHistory

  }
  ...on EmployeeProfile {
    employeeId
    department
    specialty
  }
`

const Email = `
  address
  verified
`

const Identification = `
  type {
    code
    description
  }
  value
  MRN
`

const Organization = `
  id
  name
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
  departments {
    name
    code
  }
  specialties {
    name
    code
  }
  notePreset {
    category
    display
    subCategories
  }
`

const UserWithoutCaregivers = `
  id
  devices{
    status
    address
    model
    type
  }
  createdAt
  updatedAt
  registeredAt
  selectedRole {
    refId
    name
    category
    organization {
      ${Organization}
    }
    access  {
      action
      rule
    }
  }
  allRoles {
    refId
    name
    category
    organization {
      id
      name
    }
    active
  }
  username
  email {
    ${Email}
  }
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
  identification {
    ${Identification}
  }
  profile {
    ${UserProfile}
  }

  team {
    id
    name
    healthCondition
  }
  userSettings {
    preferredUnit
    notification
  }
  starred
  starredReason
  hasFlag
  flagDetails {
    reason
    createdAt
  }
  hasPassword
`
const User = `
  ${UserWithoutCaregivers}
  caregiver {
    ${UserWithoutCaregivers}
  }
   monthlyTimeSpent {
      totalTime
      monthOfYear
      updatedAt
    }
    taskAssignmentCount
`
const UserWithoutTeam = `
  id
  createdAt
  updatedAt
  selectedRole {
    category
    name
    organization {
      ${Organization}
    }
  }
  username
  email {
    ${Email}
  }
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
  identification {
    ${Identification}
  }
  allRoles {
    refId
    name
    category
    organization {
      id
      name
    }
    active
  }
  profile {
    ${UserProfile}
  }
`

const UserForSessionStorage = `
  id
  username
  profile {
    firstName
    lastName
    fullName
    avatar {
      link
    }
  }
  identification {
    value
  }
  allRoles {
    refId
    name
    category
    organization {
      id
      leaf
      active
      name
      departments {
        name
        code
      }
      specialties {
        name
        code
      }
      notePreset {
        category
        display
        subCategories
      }
    }
  }
  selectedRole {
    organization {
      id
      name
      departments {
        name
        code
      }
      specialties {
        name
        code
      }
      notePreset {
        category
        display
        subCategories
      }
    }
  }
  team {
    id
    name
    healthCondition
    members {
      user {
        id
        profile {
          firstName
          lastName
          fullName
          avatar {
            link
          }
        }
        allRoles {
          refId
          name
          category
        }
        selectedRole {
          category
          name
        }
      }
      primary
    }
  }
`

const UserMini = `
  id
  profile {
    fullName
    fullNameWithNickname
  }
  identification {
    value
  }
  allRoles {
    name
    category
    organization{
      id
    }
  }
`
const UserName= `
  profile {
    fullName
    fullNameWithNickname
  }
`

const UserMiniWithEmail = `
  id
  profile {
    fullName
    fullNameWithNickname
  }
  emails {
    ${Email}
  }
`

const UserMiniWithPhone = `
  id
  profile {
    fullName
    fullNameWithNickname
  }
  phones {
    ${Phone}
  }
`

const UserMiniForVendor = `
  id
  emails {
    ${Email}
  }
  addresses {
    ${Address}
  }
  phones {
    ${Phone}
  }
  identification {
    value
  }
  profile {
    fullName
    fullNameWithNickname
    gender
    avatar
    ...on MemberProfile {
      birthday
      language {
        description
      }
      nationality {
        description
      }
      height
      weight
      latestWeight
    }


  }

`


export {
  User,
  UserName,
  UserMini,
  UserWithoutTeam,
  UserMiniWithEmail,
  UserMiniWithPhone,
  UserMiniForVendor,
  UserForSessionStorage,
  Address,
  UserProfile
}
