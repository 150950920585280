import React, { Component } from 'react';
import {Col, Row, Form, Input} from "antd";

const {Item} = Form;

class VisitInputNote extends Component{

    render() {
        const { getFieldDecorator } = this.props.form;

        const {visitInfo, editStatus} = this.props;
        const reason = editStatus ? visitInfo.reason : null;

        return (
            <Row gutter={16}>
                <Col span={24}>
                    <Item label="Note" >
                        {getFieldDecorator('reason', {
                            initialValue: reason,
                            rules: [{required: false}]
                        })
                        (
                            <Input />
                        )}
                    </Item>
                </Col>
            </Row>
        )
    }
}

export default VisitInputNote;
