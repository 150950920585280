import {
  React, IHLoading, message, IHButton
} from 'ihcomponent'
import Detail from '../components/Detail'
import EditPhoto from '../../ProviderEdit/components/ProviderEditPhotoComponent';
import { createContainer, getRouterParam } from 'libModule/utils'
import resendOnboardEmail from 'modulesAll/graphql/resendOnboardEmail'
import { userWithoutTeam as userGQL } from '../../../graphql/user'
import { graphql } from 'react-apollo'
import get from 'lodash/get'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import { formatErrorMessage, goPath } from 'libModule/utils'
import { openEditEmailModal, openEditPhoneModal,openEditPhotoModal } from 'modulesAll/provider/ProviderEdit/ProviderEditDuck'
import { openModal, closeModal } from 'layoutModule/actions/MainModal';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

const Component = class extends React.Component {
  static displayName = 'provider/ProviderShow/containers/Detail'

  componentDidMount() {
    const { user } = this.props.data
    if (user)
      this.props.setBreadCrumb(`Providers / ${user.profile.fullName} / Demographics`)
  }
  componentDidUpdate() {
    const { user } = this.props.data
    if (user)
      this.props.setBreadCrumb(`Providers / ${user.profile.fullName} / Demographics`)
  }
  editProviderEmail = () => {
    const { user } = this.props.data
    this.props.openEditEmailModal(user)
  }
  editProviderPhone = () => {
    const { user } = this.props.data
    this.props.openEditPhoneModal(user)
  }
  openEditPhoto = () => {
    const { user } = this.props.data;
    this.props.openEditPhotoModal(user)
  }
  resendActivationEmail = async () => {
    const id = get(this.props, 'data.user.id')
    const variables = {
      id
    }
    const { user } = this.props.data
    const auditLogDetails = {
      loginId: user.username,
      fullName: user.profile.fullName
    }

    try {
      const res = await this.props.mutate({ variables })
      createAuditLog({
        action: I18N.get('auditLog.admin.provider.resendActivationEmail'),
        details: auditLogDetails,
        request: variables,
        response: res
      })

      console.log('got data', res.data);
      message.success('Successfully sent activation email.')
    }
    catch (err) {
      console.log('there was an error sending the query', err);
      message.error(formatErrorMessage(err))

      createAuditLog({
        action: I18N.get('auditLog.admin.provider.resendActivationEmail'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false
      })
    }
  }

  render() {
    return (
      <div className="vsm-main" style={{ minWidth: 'auto', padding: '0 20px' }}>

        {this.renderTopButton()}
        {this.renderBody()}
      </div>
    )
  }

  renderTopButton() {
    const b1 = {
      label: '< Back to Providers List',
      size: 'large',
      type: 'link',
      onClick: () => {
        goPath('/providers')
      }
    }

    return (
      <div className="v-top">
        <IHButton style={{ color: '#ff8932' }} {...b1} />
      </div>
    )
  }

  renderBody() {
    const data = this.props.data
    if (!data.user) {
      return <IHLoading />
    }

    // setPagePath(`Providers / ${data.user.profile.fullName} / Full Profile`)

    const childProps = {
      data: data.user,
      resendActivationEmail: this.resendActivationEmail,
      patientId: this.props.patientId,
      editProviderEmail: this.editProviderEmail,
      editProviderPhone: this.editProviderPhone,
      openEditPhoto:this.openEditPhoto
    }

    return <Detail {...childProps} />
  }
  renderBackLink() {
    const goBack = () => {
      this.props.dispatch(goPath('/providers/testid'))
    }
    return (
      <div className="v-back" onClick={goBack.bind(this)}>
        <i className="fa fa-angle-left"></i>
        <span>Back to Care Plan</span>
      </div>
    )
  }
}

const withGql = graphql(userGQL, {
  options: (ownProps) => {
    const id = ownProps.id
    return {
      variables: {
        id
      },
      notifyOnNetworkStatusChange: true,
    }
  }
})

export default createContainer(withGql(graphql(resendOnboardEmail)(Component)), (state) => {
  const id = getRouterParam(state, 'providerId')

  return {
    id
  }
}, { setBreadCrumb, openEditEmailModal, openEditPhoneModal, openEditPhotoModal})
