import gql from "graphql-tag";

export  default gql`query getVisit($id:EID!){
  getVisit(id:$id){
        
          id
           deviceRecordCount{
                Lancets
                Strips
                Ease
                Align
                Lina
            }
          member{
            id
            identification{
                type{
                    code
                    description
                }
                value
                MRN
            }
           
            profile{
                fullName    
                
                ...on MemberProfile{
                    birthday
                    race {
                      code
                      description
                    }
                    language {
                      code
                      description
                    }
                    nationality {
                      code
                      description
                    }
                    deviceRecords{
                        Lancets
                        Strips
                        Ease
                        Align
                        Lina
                        iPhone
                        TrackSet
                    }
                    height
                    weight
                    appLanguage{
                        code
                        description
                    }
                    language{
                        code
                        description
                    }
                    latestWeight
                    healthConditions
                    billableHealthConditions{
                        description
                        code
                    }
                    insuranceProvider
                    motivation
                    techLevel
                    programCategories{
                        name
                        enrolled
                        enrolledDate
                    }
                    emrList{
                        EMR_Charting
                        ChartA1C
                        CCM
                    }
                    
                }
            }
            
            allRoles{
                refId
                category
            }
          }
          enrolledProgram{
            id
            assessments
            goals
            tasks{
                id
                type
            }
          }
          type
          reason
          appointmentAt
          appointmentTo
          checkinAt
          checkoutAt
          providers{
            id
            profile{
              fullName
            }
          
          }
          rdWorkingList{
              Assessment
              Charting
              GoalStatement
          }
          maWorkingList{
              onBoard
              LabResults
              VisitBilling
              Medication
              FollowUpVisit
          }
          conditionsList{
            HLD
            HTN
            DM
            CKD
            Obesity
            PreDM
            COPD
          }
          associatedVisit{
            id
            type
            visitRoles
            providers{
                id
                profile{
                  fullName
                }
          }
          appointmentAt
          reason
            
            
          }
          tasks{
            key
            value
          }  
          labResult{
            id
            results{
                value
                id
            }
            dateCollected
          }
          shouldSeeDoctor
          visitRoles
          details{
            doctorSession
            cdeSession
            autoUpdateStatus
            addToContactsStatus
            technicalSetup
            eyeExam
            height
            weight
            waistHipRatio
            bgMeasurement{
              blood_glucose
                beforeMeal
              mealType
            }
            EMRAttached
            bpMeasurement{
              systolic_blood_pressure
              diastolic_blood_pressure
              heart_rate
            }
            
        }
        labTests{
            createdAt
			updatedAt
            dateCollected
            templateId
            templateName
            results{
                name
                value
                unit
                isInHouse
            }
        }    
     }

}`
