import gql from 'graphql-tag'

export const resultList =  gql`
 query resultList($filters:ResultListOpts!){
  resultList(filters:$filters,count:9999){
    data{
      ...on BGResult {
        blood_glucose
        mealType
        severity
        beforeMeal
        date
        id
        measure {
            id
            user_notes
        }
      }
      ...on BPResult {
          systolic_blood_pressure
          diastolic_blood_pressure
          heart_rate
          severity
          date
          measure {
            id
            user_notes
          }
      }
      moodTags
      hadMedicine
      hadActivity
      hadInsulin
    }
    stat {
      bg {
        averageFasting
        averageBeforeMeal
        averageAfterMeal
      	pairedMeasurement {
          pairCount
          deltaAverage
          deltaHighCount
          deltaMin
          deltaMax
      	}
        hypoglycemiaCount
        bedtimeCount
        overnightCount
        criticalHighCount
        criticalLowCount
        lowCount
        normalCount
        highCount
      }
      bp {
        averageBP {
          systolic
          diastolic
        }
        averageMorningBP {
          systolic
          diastolic
        }
        normalCount
        elevatedCount
        hypertension1Count
        hypertension2Count
        criticalHighCount
        arrhythmiaCount
        total
      }
    }
   
  
    
  }
}
`


export const foodLogList = gql`
query foodLogList($count: Int, $page: Int, $filters: FoodLogListOpts!,$sort:FoodLogListSort) {
  foodLogList(count: $count, page: $page, filters: $filters, sort: $sort) {
    data {
      id
      member {
        id
        profile {
          fullName
        }
      }
      mealType
      note
      images{
        link
      }
      createdAt
      rating {
        star
        category {
          carb
          fat
          fruit
          vegetable
        }
      }
      comments {
        text
        commentedBy {
          profile {
            fullName
          }
        }
        commentedAt
      }
    }
    pageInfo {
      total
      lastPage
    }
  }
}`
