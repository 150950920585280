import { React, IHSearchTable, IHLoading, _, moment } from 'ihcomponent';
import { graphql } from 'react-apollo';
import getOrderList from "../query/getOrderList";
import { compose } from 'redux';
import VISIT_TYPE_ENUM from '../../../visit/constants/map';
import {mappingDevices} from '../constants/mappingDevices';

class RenderOrdersComponent extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      // isStatusFilterClicked: false
    }
    this.changeTableActionFilter = this.changeTableActionFilter.bind(this);
    // this.onChange = this.onChange.bind(this);
  };

  changeTableActionFilter(newFilter) {
    const { onTableChange, filter = {}, sort = {}, page } = this.props;
    const sortParam = _.pick(sort, ['field', 'order']);
    const filterParam = {
      ...filter,
      ...newFilter,
    };
    onTableChange({current: 1, size: 10}, filterParam, sortParam);
  }

  getTableProps = () => {
    const {
      inventoryHistoryList
    } = this.props;
    const formatTimePart = (data)=>{
      return (moment(data).isValid() ? moment(data).format('MM/DD/YYYY') :'time format error');
    };

    if(!inventoryHistoryList){
      return (
        <IHLoading />
      )
    }
    
    const productTypeList = inventoryHistoryList.data.map(p => ({text: p.product.productType, value: p.product.productType}))
    const visitTypeList = inventoryHistoryList.data.map(p => (p.visit)?({text: VISIT_TYPE_ENUM[p.visit.type] + ' Visit', value: p.visit.type}): ({text: '--', value: 'Null'}))
    const visitFilter = [
      {value: 'INIT' , text: 'Initial visit'},
      {value: 'FOLLOW_UP', text: 'Follow-up visit'},
      {value: 'ADDITIONAL', text: 'Additional visit'},
      {value: 'MOBILE', text: 'Online order'},
      {value: 'INVENTORY', text: 'Manual input'},
      {value: 'Null', text: 'Other'}
    ];
    let filteredArr = _.filter(_.get(inventoryHistoryList, 'data'), el => _.get(el, 'product.quantity') !== 0);
    return {
      title: `Request History`,
      dataSource: filteredArr,
      columns: [
        {
          title:'Name',
          key: 'product.productType',
          width: '20%',
          dataIndex:'product.productType',
          render: (val) =>  mappingDevices[val],
          filters: _.uniqBy(productTypeList, 'value'),
          filterMultiple:true,
          onFilter: (val, record) => record.product.productType.indexOf(val) === 0,
        },
        {
          title: 'Amount',
          key: 'product.quantity',
          width: '20%',
          dataIndex: 'product.quantity',
          render: (val) => Math.abs(val),
          sorter:(o1, o2) => o1.product.quantity - o2.product.quantity,
          defaultSortOrder: ['ascend'],
        },
        {
          title: 'Date',
          key: 'createdAt',
          width: '20%',
          dataIndex:'createdAt',
          render: (val, record) => formatTimePart(val.createdAt),
          sorter: (o1,o2)=> o1.createdAt - o2.createdAt,
          defaultSortOrder: ['descend'],
        },
        {
          title: 'Type',
          key: 'visit.type',
          dataIndex:'visit.type',
          // filters: _.uniqBy(visitTypeList, 'value'),
          filters: visitFilter,
          filterMultiple: true,
          onFilter: (val, record) => {
            return val ? (val === 'Null' ? !record.visit : record.visit && record.visit.type.indexOf(val) === 0) : !record.visit
          },
          render: (val, record) => {
            return val? VISIT_TYPE_ENUM[val] + ' Visit': '--'
          },
        },
      ]
    }
  }
 
  render() {
    return (
      <div className = "render-orders-component">
        {<IHSearchTable {...this.getTableProps()} />}
      </div>
    );
  }
}

const withData = graphql(getOrderList, {
  options :(props) =>({
      variables:{
        filters:{
          memberId: props.user.id,
        }
      },
      fetchPolicy: "network-only"
  }),
  props: ({ownProps, data: {loading, inventoryHistoryList, refetch}}) => {
    if(loading) return {
      loading
    };
    return {
      inventoryHistoryList,
      refetch
    }
  }
})

export default compose(
  withData
)(RenderOrdersComponent);