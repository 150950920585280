import {
    React, _, IHButton, IHLoading, IHTooltip
} from 'ihcomponent'
import UserClass from 'modulesAll/graphql/class/User'
import { goPath, RequestCache } from 'libModule/utils'
import PatientStarModal from 'modulesAll/common/containers/PatientStarModal'
import { modalAction } from 'modulesAll/common/actions'
import I18N from 'modulesAll/I18N'
import './bootstrap_white.css';
import { TimeSpentComponent } from '../../TimeSpent/components/';
import yearOfDiagnoseMap from "../../../patientList/registerNew/constants/yearOfDiagnose";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { graphql } from "react-apollo/index";
import getTempEnrolledPatient from 'modulesAll/graphql/getTempEnrolledPatient';
import getPatientSmartAlert from 'modulesAll/smartAlert/query/getPatientSmartAlert.js'
import PatientSmartAlertContainer from 'modulesAll/smartAlert/container/PatientSmartAlertTasksContainer.js';
import phoneListInfo from 'graphqlModule/phoneListInfo.js';
import { Tooltip } from 'antd';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import CreateNewTaskContainer from '../../../taskAssignment/containers/CreateNewTaskContainer';

//import NumberFormat from 'react-number-format';
//Message>Patients>
const ViewOnlyMemberDetail = class extends React.Component {
    static displayName = 'patient/main/components/ViewOnlyMemberDetail'

    constructor(props) {
        super(props);
        this.ref = null;
        this.state = {
            showModal:false,
            modalData: null,
        }
    }
    setModalData = ({showModal,modalData})=>{
        this.setState({
            showModal,
            modalData
        })
    }
    renderSmartAlertTags() {
        const { smartAlertsList, patientId,currentProgramId,data } = this.props;
        const identification = _.get(data,'user.identification[0].value');
        const fullName = _.get(data,'user.profile.fullName');

        return <PatientSmartAlertContainer smartAlertsList={smartAlertsList} memberId={patientId}
                                           identification={identification} fullName={fullName}
                                           currentProgramId={currentProgramId}/>
    }

    renderPhoneInfo(){
        const { phoneReportInfoList } = this.props;
        const added = phoneReportInfoList==='added';
        const text =  added ? `Care team number has been added on patient’s phonebook.` :`Please remind patient to add Care team number into phonebook.`;

        return <Tooltip placement="top" title={text} arrowPointAtCenter={true}>
            <img src={`/image/${added ? 'phoneBook.png' :'phoneBook_dot.png'}`} width={23} style={{ marginRight:10,marginBottom:10 }}/>
        </Tooltip>
    }
    render() {
        const { loading,refetchUser,patientId,currentProgramId } = this.props;
        if(loading){
            return <IHLoading/>;
        }
        RequestCache.set('nurse/currentPatient', this.props.data.user);
        this.uc = new UserClass(this.props.data.user);
        const { setModalData } = this;
        const { showStickyHeader, menuOpened } = this.props;
        const { modalData,showModal } = this.state;

        const className = "vsm-patient-member-detail" + (showStickyHeader ? ' stickyHeader' : '') + (menuOpened ? ' menuOpened' : '');

        const stickyHeader = showStickyHeader ?
            <div className={className} id='sticky_profile_detail'>
                {this.renderAvator()}
                <div>
                    {this.renderName(this.props.data.user)}
                    {this.renderTags()}
                </div>
                {<PatientStarModal refetch={this.props.data.refetch} />}
            </div> : '';
        return (
            <div className='vsm-patient-member-detail' id='profile_detail' >
                {this.renderAvator()}
                <div style={{
                    display: 'flex',
                    alignItems: 'stretch',
                    flexDirection: 'row',
                    justifyItems: 'stretch'
                }}>
                    <div>
                        {this.renderName(this.props.data.user)}
                        {this.renderTags()}
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                <div>
                                    {this.renderHealthConditions()}
                                </div>
                                <div className={"profile-keyvalue-box"}>
                                    {this.renderYearOfDiagnose()}
                                    {this.renderCurrentProgramNames()}
                                    {this.renderEnrollment()}
                                    {this.renderTechLevel()}
                                    {this.renderMotivation()}
                                    { this.renderPhoneInfo() }

                                    {this.renderHomePhones()}
                                    {this.renderMobilePhones()}
                                </div>
                                {this.renderSmartAlertTags()}
                            </div>
                            <div style={{ display: 'flex', width: '15%', justifyContent: 'center' }}>
                                {this.renderTimeSpend()}
                            </div>
                        </div>
                    </div>

                </div>

                {<PatientStarModal refetch={this.props.data.refetch} />}
                { showModal ? <CreateNewTaskContainer setModalData={setModalData} modalData={modalData} refetchUser={refetchUser}
                                                      renderBody={true} memberId={patientId} currentProgramId={currentProgramId}/>:''}
                {stickyHeader}
            </div>
        )
    }

    renderAvator() {
        return (
            <a className="v-avator" style={{ height: 48 }}>
                <img src={this.uc.format('avator')} />
            </a>
        )
    }

    // renderYearOfDiagnose() {
    //     const { data } = this.props;
    //     const yearOfDiagnose = _.get(data, 'user.profile.yearOfDiagnose', []);
    //     const keys = Object.values(yearOfDiagnoseMap);
    //     const yearOfDiagnoseList = yearOfDiagnose.filter((key) => keys.indexOf(key.condition) != -1);

    //     return (yearOfDiagnoseList.length > 0 ?
    //         <div style={{ lineHeight: 2 }} >
    //             <span>Year Of Diagnosis: </span>
    //             {
    //                 _.map(yearOfDiagnoseList, (tag, index) => {
    //                     const now = new Date().getFullYear();
    //                     const { diagnosedDate, condition } = tag;
    //                     const diagnosedDateTillNow = now - diagnosedDate;
    //                     const yearString = diagnosedDateTillNow == 0 ? '<1 year'
    //                         : diagnosedDateTillNow == 1 ? '1 year' : `${diagnosedDateTillNow} years`;
    //                     return (
    //                         <span className="v-tag" key={index}>
    //                             {_.upperFirst(condition.toLowerCase())} ({yearString})
    //                             </span>
    //                     )
    //                 })
    //             }
    //         </div> : ''
    //     )
    // }

    renderYearOfDiagnose() {
        const { data } = this.props;
        const yearOfDiagnose = _.get(data, 'user.profile.yearOfDiagnose', []) || [];
        const keys = Object.values(yearOfDiagnoseMap);
        const yearOfDiagnoseList = yearOfDiagnose.filter((key) => keys.indexOf(key.condition) != -1);

        return (yearOfDiagnoseList.length > 0 ?
            <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span style={{ fontWeight: 'bold' }}>Year Of Diagnosis: </span>
                {
                    _.map(yearOfDiagnoseList, (tag, index) => {
                        const now = new Date().getFullYear();
                        const { diagnosedDate, condition } = tag;
                        const diagnosedDateTillNow = now - diagnosedDate;
                        const yearString = diagnosedDateTillNow == 0 ? '<1 year'
                            : diagnosedDateTillNow == 1 ? '1 year' : `${diagnosedDateTillNow} years`;
                        return (
                            <span className="v-tag" key={index}>
                                {_.upperFirst(condition.toLowerCase())} ({yearString})
                                </span>
                        )
                    })
                }
            </div> : ''
        )
    }

    renderEnrollment() {
        const user = _.get(this, 'props.data.user');
        let programCategories = _.get(user, 'profile.programCategories', []);
        let categories = [];
        _.forEach(programCategories, (p) => {
            if (p.enrolled) {
                categories.push(p.name);
            }
        });
        if (categories.length > 0) {
            return <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span style={{ fontWeight: 'bold' }}>Enrollment: </span>
                {_.join(categories, ',')}
            </div>
        }
        else {
            return '';
        }
    }

    renderCurrentProgramNames() {
        const currentProgramNames = _.get(this.props, "currentProgramNames") || [];

        return (
            <div style={{ lineHeight: 2 }} className='profile-keyvalue'>
                <span style={{ fontWeight: 'bold' }}>Active Enrolled Programs(s):   </span>
                {
                    currentProgramNames.length > 0 ?
                        currentProgramNames.map((tag, index) => {
                            return (
                                <span className="v-tag" key={index}>{tag}</span>
                            )
                        }) : I18N.get('keywords.N/A')
                }
            </div>
        )

    }

    renderTechLevel() {
        const techLevel = this.uc.format('techLevel');
        if (techLevel) {
            return <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span style={{ fontWeight: 'bold' }}>Tech Level: </span>
                {I18N.get(techLevel)}
            </div>
        } else {
            return '';
        }
    }
    renderMotivation() {
        const motivation = this.uc.format('motivation');
        if (motivation) {
            return <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span style={{ fontWeight: 'bold' }}>Motivation: </span>
                {motivation}
            </div>
        } else {
            return '';
        }
    }



    renderTimeSpend() {
        const user = _.get(this.props, 'data.user', {});
        const id = _.get(user, 'id', '');
        const totalTime = _.get(user, 'monthlyTimeSpent.totalTime', 0);
        const timeInMinutes = !totalTime ? 0 : Math.round(totalTime / 60);

        return (
            <TimeSpentComponent timeInMinutes={timeInMinutes} userId={id} />
        )
    }


    renderName(uc) {
        const flag = _.get(uc,'taskAssignmentCount',0);
        const className = flag ? 'stickerHeader' : '';
        return (
            <div className={''}>
                <span className="v-name">
                    <a onClick={() => {
                        Mixpanel.track('clicked', 'patient_name', 'message', { PATIENT_ID: this.props.patientId });
                        goPath(`/patients/${this.props.patientId}/enrolledProgram/default`)
                    }
                    }>
                        {this.uc._get('profile.fullNameWithNickname')}
                    </a>
                    <span>({this.uc.format('nric')})</span>
                </span>
                {/* <span className={`v-status v-${user.status}`}>{user.status}</span> */}
                { this.renderA1CTag() }
                { this.renderStar(flag,uc) }

            </div>
        )
    }

    renderA1CTag() {
        const tempPatient = this.props.tempPatient;

        if (tempPatient) {
            return (
                <span className="v-a1c">{tempPatient.category}</span>
            );
        }
    }

    renderStar(flag,uc) {
        // const { data } = this.props;
        const patientName = _.get(uc,'profile.fullName');
        const memberId = _.get(uc,'id');
        if (flag) {
            const click = () => {
                // this.props.dispatch(modalAction.showPatientStarModal(true, 'delete', this.uc.variables))
                Mixpanel.track('clicked', 'task tag', 'patient profile page');
                this.setModalData({ showModal:true,modalData:{ patientName,memberId,inPatientDetail: true }});
            }
            return (
                <IHTooltip overlayClassName="vsm-tooltip"
                           title={'Click to add task for this patient'}
                           placement="right">
                    <a onClick={click} className="v-star v-y"><i className="fa fa-star" /> Tagged</a>
                </IHTooltip>
            )
        }
        else {
            const click = () => {
                // this.props.dispatch(modalAction.showPatientStarModal(true, 'add', this.uc.variables))
                Mixpanel.track('clicked', 'task tag', 'patient profile page');
                this.setModalData({ showModal:true,modalData:{ patientName,memberId,inPatientDetail: true }});
            }
            return (
                <IHTooltip overlayClassName="vsm-tooltip" title={`Click to tag patient`} placement="right">
                    <span onClick={click} className="v-star v-n"><i className="fa fa-star-o" /></span>
                </IHTooltip>
            )
        }
    }

    renderTags() {
        const tags = this.uc.format('tag');
        const mobilePhone = this.uc.format('mobilePhone');
        const homePhone = this.uc.format('homePhone');
        const len = tags.length;
        return (
            <div style={{ paddingBottom: 10 }}>
                {
                    _.map(tags, (tag, index) => {
                        if (index == len - 1) {
                            return (
                                <span className="v-tag" key={index}>{tag}</span>
                            )
                        }
                        else return <span className="v-tag" key={index}>{tag},</span>
                    })
                }
            </div>
        )
    }

    renderHealthConditions() {
        const healthConditionsArray = this.uc.format('healthConditions');
        return (
            <div style={{ lineHeight: 2 }}>
                <span style={{ fontWeight: 'bold' }}>Health Condition(s):   </span>
                {
                    healthConditionsArray.length > 0 ?
                        _.map(this.uc.format('healthConditions'), (tag, index) => {
                            return (
                                <span className="v-tag" key={index}>{tag}</span>
                            )
                        }) : I18N.get('keywords.N/A')
                }
            </div>
        )
    }


    renderMobilePhones() {
        const mobilePhone = this.uc.format('mobilePhone');
        if (mobilePhone) {
            return <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span><img src={'/image/call-phone.png'} /></span>
                <span style={{ fontWeight: 'bold' }}>Mobile: </span>
                {mobilePhone}
            </div>
        }
        else {
            return '';
        }
    }

    renderHomePhones() {
        const homePhone = this.uc.format('homePhone');
        if (homePhone) {
            return <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span><img src={'/image/call-phone.png'} /></span>
                <span style={{ fontWeight: 'bold' }}>Home: </span>
                {homePhone}
            </div>
        }
        else {
            return '';
        }
    }

}
const smartAlerts = graphql(getPatientSmartAlert, {
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        return {
            smartAlertsList: _.get(data, 'getPatientSmartAlert.tasks', [])
        }
    }
})
const tempPatient = graphql(getTempEnrolledPatient, {
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        return {
            tempPatient: data.getTempEnrolledPatient
        }
    }
});
const mapToProps = (state) => {
    return {
        menuOpened: state.Nav.menuOpened
    }
}

const phoneInfo = graphql(phoneListInfo,{
    options: (ownProps) => {
        console.log(ownProps);
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        const { loading } = data;
        if(loading) {
            return  {
                loading:loading
            }
        }
        return {
            phoneReportInfoList: _.get(data, 'phoneReportInfoList.data.0.addToContactsStatus', false),
        }
    }
})
export default compose(connect(mapToProps, null), tempPatient, smartAlerts,phoneInfo)(ViewOnlyMemberDetail);
//export default ViewOnlyMemberDetail;
