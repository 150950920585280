import PropTypes from 'prop-types';
import React from 'react';
import { IHSearchTable, IHLoading, IHButton, _,IHInput,message } from 'ihcomponent';
import { goPath } from 'libModule/utils'
import { connect }from 'react-redux';
import { Divider } from 'antd';
import {commonActionsHelper} from 'libModule/helpers/action-helpers';
import moment  from 'moment';
import VISIT_TYPE_ENUM from '../constants/map';
import '../styles/index.scss';
import { API } from '../query/index';
import { openModal,closeModal } from 'layoutModule/actions/MainModal'
import createTableAction from 'libModule/table/TableActions';
import { helpers} from "../helpers";
import {compose, graphql} from "react-apollo/index";
import visitList from 'modulesAll/graphql/visitList';
import actions from '../actions';
import { Modal } from 'antd';
import $ from 'jquery';
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'lib/utils';

const appointmentTableActions = createTableAction('appointment_List');
const confirm = Modal.confirm;

const PatientAppointmentList = class extends React.Component {
    static displayName = 'AppointmentListComponent';
    constructor(props){
        super(props);
        this.deleteVisit.bind();
    }

    componentWillUnmount(){
        this.props.resetVisit();
    }

    componentWillReceiveProps(nextProps){
        const { visitList }  = nextProps;
        const { initVisitList } = this.props;

        if(visitList&&visitList.data.length>0&&_.isEmpty(initVisitList)) {
                this.props.initVisitListAction(visitList.data);
            }

            return;

    }
    filterCheckOut(data){

       return  _.filter(data,(row)=>{return (!row.checkoutAt||row.checkoutAt==null)})
    }

    deleteVisit(id){
        return API.deleteVisit(id)
            .then(()=>{
                this.props.deleteVisit(id);
                message.success('Patient Appointment canceled');
            })
            .catch((error)=>{
                this.props.openErrorModal(GQLHelper.formatError(error));
            })
    }
    showConfirm(id) {
        confirm({
            title: 'Are you sure to cancel this appointment?',
            okText:'Yes',
            cancelText:'No',
            onOk:()=>{
                this.deleteVisit(id)
            }
        });
    }
    render() {
        const doctors = _.map(helpers.filterDoctors(),(data)=>{
            return {
                text:data.fullName,
                value:data.id
            }
        });

        const types = _.map(VISIT_TYPE_ENUM,(index,data)=>{
            return {
                text:index,
                value:data
            }
        })

        const { loading,initVisitList,patientId,programOptions } =this.props;
        const data = initVisitList;
        const tableWidth  = $('.IH-SearchTable').width();
        const columns = [
            {
                title: tableWidth < 650 ? 'Type' : 'Type',
                dataIndex: 'type',
                width: '15%',
                // render:(value)=>VISIT_TYPE_ENUM[value]||'unknown',
                render: (value) => {
                    return {
                        props: {
                            className: VISIT_TYPE_ENUM[value].replace(/\s/g, ''),   // there it is!
                        },
                        children: VISIT_TYPE_ENUM[value]||'unknown',
                    };
                }
            },
            {
                title:'Date',
                width:'15%',
                render:(value,record)=>record.appointmentAt ? moment(record.appointmentAt).format('MM/DD/YYYY'):'no date',
            },
            {
                title: 'Time',
                width:'15%',
                dataIndex: 'appointmentAt',
                render:(a)=> a ? moment(a).format('HH:mm'):'no time',

            },
            {
                title: 'Description',
                dataIndex: 'reason',
                width:'30%'
            },
            {
                title: 'Action',
                dataIndex:'id',
                render: (id, row) => {

                    const cancelbuttonProps = {
                        label: '',
                        bsStyle: 'primary',
                        className:'messageVisitButton appointment_cancel_button',
                        onClick:()=>{
                            this.showConfirm(id);
                        }

                    }
                    const reschedulebuttonProps = {
                        label: '',
                        bsStyle: 'primary',
                        className:'messageVisitButton appointment_reschedule_button',
                        onClick:()=>{
                            goPath(`/appointments/reschedule/${id}`);
                        }
                    }

                    const render = (
                                    <div className='appointmentListButton'>
                                        <IHButton {...cancelbuttonProps}/>
                                        <Divider type='vertical'/>
                                        <IHButton {...reschedulebuttonProps}/>
                                    </div>
                                    )

                    return render;

                }

            }
        ]

        const fullName = _.get(this,'props.patientName');
        const parsedFullName = btoa(fullName);
        const tableProps = {
            columns:  columns,
            rowKey: 'id',
            pagination: {
                current: 1,
                pageSize: 99,
            },
            title: `Upcoming Appointments`,
            loading: { spinning:loading,indicator: <IHLoading/> },
            onChange:(a,b)=>this.props.onTableChange(a,b) ,
            dataSource: _.isEmpty(data) ? []:_.sortBy(this.filterCheckOut(data),(o)=>o.appointmentAt),
            optionsBox: true,
            actions:<IHButton bsStyle="primary"
                              label='Create an Appointment' className="ih-small"
                              onClick={()=>goPath(`/appointments/create/${patientId}/${parsedFullName}`)}
                              style={{float:'right'}}

            />
        };

        const noDataElem = (
            <div>
                <p style={{fontSize: '18px'}}>No appointments scheduled</p>
            </div>
        );

        return (
            <IHSearchTable noDataElem={noDataElem} {...tableProps} className='patientVisitList'/>
        );
    }
}

const withData = graphql(visitList, {
    options: (ownProps) => {
        // const patientId = btoa(atob(ownProps.patientId).split(':')[1]);
        const { patientId } = ownProps;
        const  variables = {
            page: 1,
            count: 99,
        }

        variables.memberId = patientId||"";
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ownProps, data}) => {
        const { loading,visitList } = data;
        if(visitList&&visitList.data) {
            return {
                visitList,
                loading
            }
        }
        return {
            loading
        }
    }
})

const mapToProps = (state)=>{
    return {
        ...appointmentTableActions,
        initVisitList:state.visit.main.initVisitList,
        ...state.globalTables.appointment_List

    }
}

const mapToDispatch = (dispatch)=> {
    return {
        initVisitListAction:(data) => dispatch(actions.initVisitList(data)),
        deleteVisit:(id)=>dispatch(actions.deleteVisit(id)),
        resetVisit:()=>dispatch(actions.resetVisit()),
        openModal: (content, row) => dispatch(openModal(content, row)),
        closeModal:()=>dispatch(closeModal()),
        openErrorModal:(error)=>dispatch(openErrorModal(error))
    }
}

export default compose(connect(mapToProps,mapToDispatch),withData)(PatientAppointmentList);
