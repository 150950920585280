import React from 'react';
import  { DATE_HELPERS  } from '../../../../../lib/helpers/date-helpers';
import obj from '../../Measurement/constants/index';
import { Divider } from 'antd';
import moment from 'moment';
import I18N from 'modulesAll/I18N'

const timeFormatString = I18N.get('dates.L');

class BPMeasurementComponent extends React.Component{

    render(){
        const { BPMeasureTypeMap , BPSeverityMap,style } = obj;
        const { diastolic,systolic, unit,measuredAt,text,type,severity,arrhythmia } = this.props;
        const { container,header,divider1,message } = style;
        const { title } = BPMeasureTypeMap[type];
        const measuretAtDate = DATE_HELPERS.formattedDate(moment(measuredAt),timeFormatString);
        const keys = Object.keys(BPSeverityMap);
        let severityValue ='unknown';
        let severityColor='white';
        const arrhythmiaIndicator = arrhythmia ? 'arrhythmia ' :'';

        keys.map((k)=> {
                if (severity.includes(k)) {
                    severityValue = BPSeverityMap[k].text;
                    severityColor = BPSeverityMap[k].color;
                }
            }
        );

        const severityStyle = {
            width:'100%',
            height:'25px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:severityColor,
            borderRadius:'12px',
            lineHeight:'4.7px',
            color:'white',
            fontSize:'10px'
        }
        const divider3 ={
            width:'3px',
            height:'inherit'
        }

        return <div style={container}>
            <div style={header}>
                <span style={{marginBottom:10,whiteSpace:'nowrap'}}><p>{arrhythmiaIndicator}</p>{title}({measuretAtDate})</span>
                <Divider style={divider1}/>
            </div>
            <div style={message}>
                <div style={header}>
                    <span style={{fontSize:18,fontWeight:'bold',lineHeight:'10pt'}}>{systolic}/{diastolic}</span>
                    <span >{unit}</span>
                    <div style={severityStyle}>{severityValue}</div>
                </div>
                <Divider type='vertical' style={divider3}/>
                <div>
                    {text}
                </div>
            </div>
        </div>
    }
}

export default BPMeasurementComponent;
