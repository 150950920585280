import { AutoComplete, Input, Icon, Pagination } from 'antd';

import { Query } from 'react-apollo';
import ICDCodeListGQL from 'modulesAll/graphql/ICDCodeList';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
const Option = AutoComplete.Option;
const PAGE_COUNT = 10;

class HealthConditionPicker extends Component {
  static displayName =
    '/patient/profileDetail/containers/HealthConditionPicker';
  constructor(props) {
    super(props);
    this.state = {
      keyWords: 'I10',
      value: ''
    };
  }
  handleSearch = value => {
    this.setState({
      keyWords: value
    });
  };

  onSelect(value) {
    const { addHealthCondition } = this.props;
    addHealthCondition(value);
  }

  getOptions(data) {
    const options = data.ICDCodeList.data.map(item => {
      return (
        <Option
          key={item.id}
          code={item.code}
          value={`${item.title}::${item.code}`}
        >
          {item.code}: {item.title}
        </Option>
      );
    });

    return options;
  }

  // infinit scroll is not supported yet
  getLoadMore({ loading, data, fetchMore }) {
    //see if there are more to load
    if (loading || _.get(data, 'ICDCodeList.pageInfo.lastPage') === 1)
      return [];
    const pageInfo = data.ICDCodeList.pageInfo;
    const loadMore = (
      <Option disabled key={'loadMore'}>
        <Pagination
          defaultCurrent={1}
          total={pageInfo.total}
          onChange={(page, pageSize) => {
            fetchMore({
              variables: {
                search: {
                  fields: ['CODE', 'TITLE'],
                  match: this.state.keyWords
                },
                page: page,
                count: pageSize
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }
                return Object.assign({}, prev, {
                  ICDCodeList: {
                    ...prev.ICDCodeList,
                    data: fetchMoreResult.ICDCodeList.data
                  }
                });
              }
            });
          }}
        ></Pagination>
      </Option>
    );
    return [loadMore];
  }
  render() {
    const { disabled } = this.props;
    return (
      <Query
        query={ICDCodeListGQL}
        variables={{
          search: {
            fields: ['CODE', 'TITLE'],
            match: this.state.keyWords
          },
          page: 1,
          count: PAGE_COUNT
        }}
      >
        {//handle data:
        ({ loading, error, data, fetchMore }) => {
          //if (loading) return "Loading...";
          // if (error) return `Error! ${error.message}`;
          const children = error
            ? null
            : loading
            ? []
            : [
                ...this.getOptions(data),
                ...this.getLoadMore({ data, fetchMore })
              ];
          //console.log(` chileren:`,children);

          if (!loading) {
            const pageInfo = data.ICDCodeList.pageInfo;
            //console.log('pageInfo:', pageInfo);
          }
          return (
            <AutoComplete
              //style={{ width: 300 }}
              disabled={disabled}
              onSearch={this.handleSearch}
              onSelect={this.onSelect.bind(this)}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 800 }}
              //placeholder="type code or title"
              dataSource={children}
              optionLabelProp='code'
              allowClear={true}
              backfill={false}
            >
              <Input style={{ height: 32 }} placeholder='type code or title' />
              {/* value={this.state.value} /> */}
            </AutoComplete>
          );
        }}
      </Query>
    );
  }
}
HealthConditionPicker.propTypes = {
  onSelect: PropTypes.func
};
export default HealthConditionPicker;
