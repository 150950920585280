import {Modal} from 'antd';
import React, {Component} from 'react';

import InputVisitFormComponent from "../components/InputVisitFormComponent";
import '../CSS/style.scss';
import {message} from "../../../../../package/IHComponent";
import {connect} from "react-redux";
import { GQLHelper } from 'lib/utils';
import CURRENT_COMPONENT from "../constants/CurrentComponent";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { API } from '../../visit/query/index';
import VisitInputDeleteConfirm from "../components/VisitInputDeleteConfirm";

class EditVisitInDashboardContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            value: CURRENT_COMPONENT.INPUT_VISIT_COMPONENT,
            disableSaveButton: true
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.executeDelete = this.executeDelete.bind(this);
        this.editVisit = this.editVisit.bind(this);
        this.setDisableSaveButton = this.setDisableSaveButton.bind(this);
    }

    setDisableSaveButton(value){
        this.setState({
            disableSaveButton: value
        });
    }

    handleDelete(){
        this.setState({
            value: CURRENT_COMPONENT.DELETE_VISIT,
        })
    }

    executeDelete(){
        API.deleteVisit(this.props.visitInfo.id)
            .then((res) => {
                this.props.refetch();
                this.props.onCancel();
            })
            .catch(error => {
                console.log(error);
                this.props.openErrorModal(GQLHelper.formatError(error));
            })
    }

    editVisit(variables){
        API.updateVisitFromVisitList(variables)
            .then((res)=>{
                message.success('Appointment Updated');
                this.props.refetch();
            this.props.onCancel();
        }).catch((error)=>{
            console.log(error);
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    renderSelectComponent(){

        if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT) {
            return (<InputVisitFormComponent visitInfo={this.props.visitInfo}
                                             editStatus={true}
                                             handleDelete={this.handleDelete}
                                             editVisit={this.editVisit}
                                             disableSaveButton={this.state.disableSaveButton}
                                             setDisableSaveButton={this.setDisableSaveButton}
        />)
        }

        if(this.state.value === CURRENT_COMPONENT.DELETE_VISIT) {
            return (
                <VisitInputDeleteConfirm onCancel={this.props.onCancel}
                                         executeDelete={this.executeDelete}
                />
            )
        }

    }

    getTitle(){
        if(this.state.value === CURRENT_COMPONENT.DELETE_VISIT){
            return "Please confirm"
        }
        if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT){
            return "Edit Visit"
        }
    }

    render() {
        Mixpanel.track('showed', 'edit a visit popup', '', {});
        return (
            <Modal width={550}
                   title={this.getTitle()}
                   visible={true}
                   footer={null}
                   onCancel={this.props.onCancel}
                   maskClosable={false}
            >
                {this.renderSelectComponent()}
            </Modal>
        );
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
    }
}

export default connect(null,mapToDispatch)(EditVisitInDashboardContainer);
