import I18N from '../modules/I18N';
import {moment} from 'ihcomponent'

export const emailValidator = (value) => {
  if (!value) {
    return I18N.get('validator.email.require');
  }
  const emailPattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
  if (!emailPattern.test(value)) {
    return I18N.get('validator.email.invalid');
  }
};

export const emailValidator2 = (value) => {
  const emailPattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
  if (value && !emailPattern.test(value)) {
    return I18N.get('validator.email.invalid2');
  }
};

// First Name & Last Name cannot contain any digits/numbers
export const firstAndLastNameValidator = (value, nameParam) => {
  if (value && (!(/^[',\/.A-Za-z\s\-]+$/.test(value)) || !value.trim())) {
    if (nameParam === 'firstName') {
      return I18N.get('validator.firstName.invalid')
    } else if (nameParam === 'lastName') {
      return I18N.get('validator.lastName.invalid')
    }
  }
}

export const NRICValidator = (value) => {
  if (value && !/^[STFGstfg]\d{7}[A-z]$/.test(value)) {
    //return I18N.get('validator.NRIC.invalid');
  }
}

export const usernameValidator = (value) => {
  if (!value) {
    return I18N.get('validator.username.require');
  }
};

export const usernameValidator2 = (value) => {
  if (value && value.length < 5) {
    return I18N.get('validator.username.tooShort');
  }
};

/***** Password Validators *****/

// const regExArr = [/\d/, /[a-z]/, /[A-Z]/, /[^\w\s]/];
const regExArr = [/\d/, /[a-z]/, /[A-Z]/]; // Per Carmina remove special character requirement IH-1087

export const passwordValidator = (value) => {
  if (!value) {
    return I18N.get('validator.password.require');
  }

  if (value.length < 8) {
    return I18N.get('validator.password.tooShort');
  }
  const combinations = regExArr.filter(reg => reg.test(value)).length;

  if (combinations !== regExArr.length) {
    return I18N.get('validator.password.combinationFail');
  }
};

export const pwValidatorNoErrorMsgs = value => {
  if (!value) {
    return ' ';
  }

  if (value.length < 8) {
    return ' ';
  }

  const combinations = regExArr.filter(reg => reg.test(value)).length;

  if (combinations !== regExArr.length) {
    return ' ';
  }
}

export const passwordConfirmValidator = (value, pwd) => {
  if (!value) {
    return I18N.get('validator.passwordConfirm.require');
  }
  if (value !== pwd) {
    return I18N.get('validator.passwordConfirm.notSame');
  }
};

export const tooShortPassword = (pw, errClass, validClass) => {
  if (pw.length < 8 && pw.length >= 0) {
    return errClass;
  } else {
    return validClass;
  }
};

export const requireDigitsInPassword = (pw, errClass, validClass) => {
  const regex = /\d/;
  if (regex.test(pw)) {
    return validClass;
  } else {
    return errClass;
  }
};

export const requireLowercaseInPassword = (pw, errClass, validClass) => {
  const regExArr = [/[a-z]/];
  const passedArr = regExArr.filter(regEx => regEx.test(pw));
  if (passedArr.length === regExArr.length) {
    return validClass;
  } else {
    return errClass;
  }
}

export const requireUppercaseInPassword = (pw, errClass, validClass) => {
  const regExArr = [/[A-Z]/];
  const passedArr = regExArr.filter(regEx => regEx.test(pw));
  if (passedArr.length === regExArr.length) {
    return validClass;
  } else {
    return errClass;
  }
}

export const reqSpecCharsInPassword = (pw, errClass, validClass) => {
  const regEx = /[^\w\s]/;
  if (regEx.test(pw)) {
    return validClass;
  } else {
    return errClass;
  }
}

/***** Phone Validators *****/

export const mobileValidator = (value) => {
  if (!value) return
  if (!/^\d{10}$/.test(value)) {
    return I18N.get('validator.mobile.invalid');
  }
};

export const homePhoneValidator = (value) => {
  if (!value) return
  if (!/^\d{10}$/.test(value)) {
    return I18N.get('validator.homePhone.invalid');
  }
}
export const verificationCodeValidator = (value) => {
  if (!value) {
    return I18N.get('validator.verifyCode.require');
  }
  // if (!/^\d{6}$/.test(value)) {
  //   return I18N.get('validator.verifyCode.invalid');
  // }
};

export const dobValidator = (inputValue) => {
  if(!_.isString(inputValue)){
      const value = inputValue ? moment(inputValue, 'MM/DD/YYYY').toDate() : null;
      const date = new Date();
      date.setFullYear(date.getFullYear() - 1);

      if (value && value > Date.now()) {
          return I18N.get('validator.dob.invalid');
      }
      if (value && value >= date) {
          return I18N.get('validator.dob.minimumAge');
      }
  } else {
      if (!_.isEmpty(inputValue) && (!/^\d{1,2}[\/]\d{1,2}[\/]\d{4}$/.test(inputValue) || !moment(inputValue, 'MM/DD/YYYY').isValid())) {
          return 'Please enter valid DOB.'
      }

      const value = inputValue ? moment(inputValue, 'MM/DD/YYYY').toDate() : null;
      const date = new Date();
      date.setFullYear(date.getFullYear() - 1);

      if (value && value > Date.now()) {
          return I18N.get('validator.dob.invalid');
      }
      if (value && value >= date) {
          return I18N.get('validator.dob.minimumAge');
      }
  }
};

export const yearOfDiagnoseValidator = (value) =>{
    const inputYear = parseInt(value);
    const maxYear = new Date().getFullYear();
    if(value&&(_.isNaN(inputYear)||inputYear<0||inputYear>maxYear||inputYear<1800)){
        return I18N.get('validator.yearOfDiagnose.invalid')
    }
    return;
}

export const floorValidator = (value) => {
  if (value && !/^[\d]+$/.test(value)) {
    return I18N.get('validator.floor.invalid')
  }
}

export const blockValidator = (value) => {
  if (value && !/^[\dA-Za-z]+$/.test(value)) {
    return I18N.get('validator.block.invalid')
  }
}

export const unitValidator = (value) => {
  if (value && !/^[\dA-Za-z]+$/.test(value)) {
    return I18N.get('validator.unit.invalid')
  }
}

export const postCodeValidator = (value) => {
  if (value && !/^\d{5}$/.test(value)) {
    return I18N.get('validator.postCode.length');
  }
};

/** *************
 *  Assessment
 ***************/
export const assessmentNameValidator = (value) => {
  if (!value) {
    return I18N.get('validator.assessment.name.require');
  }

  if (value.length > 40) {
    return I18N.get('validator.assessment.name.tooLong');
  }
};

export const assessmentDescriptionValidator = (value) => {
  if (!value) {
    return I18N.get('validator.assessment.description.require');
  }

  if (value.length > 400) {
    return I18N.get('validator.assessment.description.tooLong');
  }
};

export const assessmentHealthConditionValidator = (value) => {
  const VALUES = ['DIABETES', 'HEART_FAILURE', 'AMI'];

  if (!VALUES.find(v => v === value)) {
    return I18N.get('validator.assessment.healthCondition.require');
  }
};

export const questionTitleValidator = (value) => {
  if (!value) {
    return I18N.get('validator.assessment.question.require');
  }

  if (value.length > 400) {
    return I18N.get('validator.assessment.question.tooLong');
  }
};

export const questionOption40Validator = (value) => {
  if (!value) {
    return I18N.get('validator.assessment.option.require');
  }

  if (value.length > 40) {
    return I18N.get('validator.assessment.option.tooLong40');
  }
};

export const questionOption400Validator = (value) => {
  if (!value) {
    return I18N.get('validator.assessment.option.require');
  }

  if (value.length > 400) {
    return I18N.get('validator.assessment.option.tooLong400');
  }
};

export const toDoDueDateValidator = (value) => {
  if (value && moment(value).isBefore(new Date(), 'day')) {
    return I18N.get('validator.duedate.invalid');
  }
};

/** *************
 *  Patient Reading Input
 ***************/

/**
** return true for whole number
** return false for number with decimal
**/
const checkWholeNumber = (value) => {
  return value % 1 === 0;
}
export const patientGlucoseInputValidator = (value) => {
  if (!value && value !== 0) {
    return I18N.get('validator.patient.glucose.require');
  }
  if(value < 1.1 || value > 33.3 ) {
    return I18N.get('validator.patient.glucose.invalid');
  }
  if (value && !checkWholeNumber(value) && value.countDecimals() != 1) {
    return I18N.get('validator.patient.glucose.oneDecimalPoint');
  }
}

export const patientSystolicInputValidator = (value) => {
  if (!value && value !== 0) {
    return I18N.get('validator.patient.systolic.require');
  }
  if(value < 0 || value > 295 ) {
    return I18N.get('validator.patient.systolic.invalid');
  }
  if(!checkWholeNumber(value))
    return I18N.get('validator.patient.systolic.wholeNumber');
}

export const patientDiastolicInputValidator = (value) => {
  if (!value && value !== 0) {
    return I18N.get('validator.patient.diastolic.require');
  }
  if(value < 0 || value > 295 ) {
    return I18N.get('validator.patient.diastolic.invalid');
  }
  if(!checkWholeNumber(value))
    return I18N.get('validator.patient.diastolic.wholeNumber');
}

export const patientHeartrateInputValidator = (value) => {
  if (!value && value !== 0) {
    return I18N.get('validator.patient.heartrate.require');
  }
  if(value < 30 || value > 250 ) {
    //use by  BP and HR patient inputs
    return I18N.get('validator.patient.heartrate.invalid');
  }
  if(!checkWholeNumber(value))
    return I18N.get('validator.patient.heartrate.wholeNumber');
}

export const patientWeightInputValidator = (value) => {
  if (!value && value <= 0) {
    return I18N.get('validator.patient.weight.require');
  }
  if(value < 10 || value > 999 ) {
    return I18N.get('validator.patient.weight.invalid');
  }
  if (value && !checkWholeNumber(value) && value.countDecimals() != 1) {
    return I18N.get('validator.patient.weight.oneDecimalPoint');
  }
}

export const patientWeightRegiterValidator = (value) => {
  if (typeof value!='number') {
    return
  }
  if (value < 25 || value > 999) {
    return I18N.get('validator.patient.register.weight.invalid');
  }
}

export const patientHeightInputValidator = (value) => {
  if (isNaN(value) && !value) {
    return
  }

  if(value > 9 || value <= 0) {
    return I18N.get('validator.patient.height.invalid');
  }
}

export const patientHeightInchesInputValidator = (value) => {
  if (!value) {
    return
  }

  if(value < 0 || value > 11 ) {
    return I18N.get('validator.patient.height.invalid_inches');
  }
}

export const patientPhotoUploadValidator = (value) => {
  if (value && value.loading) {
    return I18N.get('validator.patient.photo.uploading');
  }
}

export const patientDiastolicValueInputValidator = (value) => {
  if (isNaN(value) && !value) {
    return I18N.get('validator.patient.diastolic.require');
  }

  if(value > 295 || value <= 0) {
    return I18N.get('validator.patient.diastolic.invalid');
  }
}

export const patientSystolicValueInputValidator = (value) => {
  if (isNaN(value) && !value) {
    return I18N.get('validator.patient.systolic.require');
  }

  if(value > 295 || value <= 0) {
    return I18N.get('validator.patient.systolic.invalid');
  }
}

/** *************
 *  Broadcast
 ***************/

export const startDateValidator = (value, endDate) => {
  const date = new Date();
  if (value && moment(value).isBefore(date, "day")) {
    return I18N.get('validator.broadcast.startDate.invalid');
  }
  // if (value && endDate && moment(value).isBefore(date, "day") && value > endDate) {
  //   return I18N.get('validator.broadcast.startDate.afterEndDate');
  // }
}

export const endDateValidator = (value, startDate) => {
  const date = new Date();
  if (value && moment(value).isBefore(date, "day")) {
    return I18N.get('validator.broadcast.endDate.invalid');
  }
  // if (value && startDate && moment(value).isBefore(date, "day") && value < startDate) {
  //   return I18N.get('validator.broadcast.endDate.beforeStartDate');
  // }
}
