import React from 'react';
import TaskAssignmentsByPatientComponent from '../components/TaskAssignmentsByPatientComponent';
import {connect} from "react-redux";
import createTableAction from 'libModule/table/TableActions';
import getTaskAssignmentsByPatientId from '../query/getTaskAssignmentsByPatientId';
import { graphql, compose } from 'react-apollo';
const tableActions = createTableAction('task_assignment_list_patient',{ shouldUpdateHistory: false ,  initState:{filter:{ status:["OPEN", "RESOLVED"] }},
});

class TaskAssignmentByPatientContainer extends React.Component {
    constructor(){
        super();
    }

    componentWillUnmount(){
        const { resetTableProps } = this.props;
        resetTableProps();
    };

    render(){
       const { props }= this;
       return   <TaskAssignmentsByPatientComponent {...props}/>

    }

}
const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    ...state.globalTables.task_assignment_list_patient,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...tableActions
    };
}
const sortMap = {
    taggedDate:'CREATED_AT',
    dueDate:'DUE_DATE'
}
// const withData =()=> [{ }]
const withData = graphql(getTaskAssignmentsByPatientId,{
    options:(props)=>{
        const { patientId } = props;
        let variables = {};

        variables.status = ['OPEN','RESOLVED'];
        variables.memberId = patientId;
        return{
            variables,
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{

        const { loading, getTaskAssignmentsByPatientId,refetch,pageInfo } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            taskList:_.get(getTaskAssignmentsByPatientId,'taskAssignments'),
            // pageInfo:_.get(getTaskAssignmentsByPatientId,'pageInfo'),
        }
    }
})
export default compose(connect(mapStateToProps,mapDispatchToProps),withData)(TaskAssignmentByPatientContainer);
