const map = {
    bedtimeCount:'Bedtime ( Critical Low + 10 mg/dL )',
    hypoglycemiaCount:'Hypoglycemia ( Critical Low )',
    overnightCount:'Overnight ( Critical Low + 10 mg/dL )',
    pairCount:'Paired Measurements',
    deltaAverage:'Average ( After Meal – Before Meal )',
    deltaHighCount:'After Meal – Before Meal > 54 mg/dL',
    deltaMin:'Minimum ( After Meal – Before Meal )',
    deltaMax:'Maximum ( After Meal – Before Meal )',
    averageBP:'Average BP',
    averageMorningBP:'Average Morning BP',
    normalCount:'Normal Count',
    elevatedCount:'Elevated Count',
    hypertension1Count:'Stage 1 Hypertension',
    hypertension2Count:'Stage 2 Hypertension',
    criticalHighCount: 'Critical High',
    arrhythmiaCount: 'Arrhythmia Count',
}


export default map;
