import React, {Component} from 'react';
import {Col, Row, Form, InputNumber, DatePicker, Checkbox} from "antd";
import moment from "moment";
import {helpers as dataHelper} from "../helper"

const {Item} = Form;

const dateFormat = 'MM/DD/YYYY';

class VisitInputA1C extends Component{

    constructor(props){
        super(props);
        this.state = {
            inputA1C: null,
            inputA1CDate: null,
        }
        this.selectDate = this.selectDate.bind(this);
    }

    checkWhetherShowA1C(){

        const {patient, editStatus, visitInfo} = this.props;

        //check whether show a1c, if the use has program and the program doesn't contain BG, hidden A1C
        if(editStatus){
            const type = visitInfo.vitalTypes;
            if(type && type.length > 0){
                const allType = type.join('/');
                if(allType.indexOf('BG') === -1){
                    return false;
                }
            }
            return true;
        } else {
            const typeAndStatus = dataHelper.getTypeAndStatus(patient.enrolledPrograms, patient.user.profile.lastMeasuredAt);
            if(typeAndStatus.status !== 'Potential'){
                if(typeAndStatus.type.indexOf('BG') === -1){
                    return false;
                }
            }
            return true;
        }
    }

    //check whether disable checkbox, more than three month or no A1C value

    // checkWhetherDisableCheckBox(){
    //     const {inputA1CDate} = this.state;
    //     let disableA1CBox = false;
    //
    //     if(inputA1CDate){
    //         const dateDiff = this.calculateDateDiff(inputA1CDate);
    //         if(dateDiff > 30){
    //             disableA1CBox = true;
    //         }
    //         return disableA1CBox;
    //     }
    //
    //     const {A1C, A1CDate} = this.props.data;
    //     if(A1C){
    //         const dateDiff = this.calculateDateDiff(new Date(A1CDate));
    //         if (dateDiff > 30) {
    //             disableA1CBox = true;
    //         }
    //     } else {
    //         disableA1CBox = true;
    //     }
    //     return disableA1CBox;
    // }

    checkWhetherShowNeedInHouse(){
        const {inputA1CDate} = this.state;
        let checkWhetherShowNeedInHouse = false;

        if(inputA1CDate){
            const dateDiff = this.calculateDateDiff(inputA1CDate);
            if(dateDiff > 30){
                checkWhetherShowNeedInHouse = true;
            }
            return checkWhetherShowNeedInHouse;
        }

        const {A1C, A1CDate} = this.props.data;
        if(A1C){
            const dateDiff = this.calculateDateDiff(new Date(A1CDate));
            if (dateDiff > 30) {
                checkWhetherShowNeedInHouse = true;
            }
        } else {
            checkWhetherShowNeedInHouse = true;
        }
        return checkWhetherShowNeedInHouse;
    }

    calculateDateDiff(date){
        const start_date = moment(date);
        const end_date = moment();
        return end_date.diff(start_date, 'days');
    }

    disabledDate(current){
        return current > moment().endOf('day');
    }

    renderA1CLabel(){

        const {A1C, A1CDate} = this.props.data;

        if(!A1C){
            return '';
        }

        const start_date = moment(A1CDate);
        const end_date = moment();
        let style = null;
        if ((end_date.diff(start_date, 'days')) > 30) {
            style = {marginTop: 10, color:'red'}
        } else {
            style = {marginTop: 10}
        }

        return (
            <Col span={24} style={style}>
                Last A1C recorded: {A1C}% Date: {moment(A1CDate).format(dateFormat)}
            </Col>
        )
    }

    renderNeedInHouseLabel(){
        const style = {marginTop: 35, color:'red'};
        return (
                <Col span={12} style={style}>
                    Need in-house A1C
                </Col>
            )
    }

    selectDate(value){
        this.setState({
            inputA1CDate: value
        })
        // this.setState({
        //     inputA1CDate: value
        // }, () => {
        //     const checkBoxValue = this.checkWhetherDisableCheckBox();
        //     this.props.form.setFieldsValue({
        //     'needInHouseA1C': checkBoxValue
        //     });
        // });
    }

    render() {
        const showA1CComponent = this.checkWhetherShowA1C();
        if(!showA1CComponent){
            return '';
        }

        const { getFieldDecorator } = this.props.form;
        // const disableA1CBox = this.checkWhetherDisableCheckBox();
        const showNeedInHouse = this.checkWhetherShowNeedInHouse();
        const {visitInfo} = this.props;

        return (
            <Row gutter={16}>
                <Col span={5}>
                    <Item label="A1C%" >
                        {getFieldDecorator('A1C', {
                            rules: [
                                { required: false},
                            ],
                        })(
                            <InputNumber min={0} />
                        )}
                    </Item>
                </Col>

                <Col span={7}>
                    <Item label=" " colon={false} >
                        {getFieldDecorator('A1CDate', {
                            rules: [{required: false}]})
                        (
                            <DatePicker format={dateFormat}
                                        onChange={this.selectDate}
                                        disabledDate={this.disabledDate}/>
                        )}
                    </Item>
                </Col>
                {/*<Col span={12}>*/}
                    {}
                    {/*<Item label=" " colon={false}>*/}
                    {/*    {getFieldDecorator('needInHouseA1C', {*/}
                    {/*        valuePropName: 'checked',*/}
                    {/*        initialValue: visitInfo && visitInfo.needInHouseA1C ? visitInfo.needInHouseA1C : disableA1CBox,*/}
                    {/*        rules: [{required: false}]})*/}
                    {/*    (*/}
                    {/*        <Checkbox disabled={disableA1CBox} >Need in-house A1C</Checkbox>*/}
                    {/*    )}*/}
                    {/*</Item>*/}
                {/*</Col>*/}
                {showNeedInHouse ? this.renderNeedInHouseLabel() :''}
                {this.renderA1CLabel()}

            </Row>
        )
    }

}


export default VisitInputA1C;
