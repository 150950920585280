import Store from 'libModule/reduxStore';
import config from 'libModule/config';
import { DataHelper } from 'libModule/utils';
import { _ } from 'ihcomponent';
import printReduxSize from './printReduxSize';
import { decryptRole, encryptText } from 'libModule/utils';

/**
 * sendLog helper
 *
 * @param {String} patientNRIC
 * @param {String} patientName
 * @param {String} institution
 * @param {String} source
 * @param {String} action
 * @param {Object} details
 * @param {Object} request
 * @param {Object} response
 * @param {Boolean} success
 *
 */
export const createAuditLog = ({
  loginId,    // For Login Audit Log
  patientNRIC,
  patientName,
  institution,
  source,
  action,
  details,
  request,
  response,
  success = true
}) => {

  const currentUser = DataHelper.getCurrentUser();
  const currentUserRoles = decryptRole();
  source = source || _.get(Store.getState(), 'Nav.breadCrumb')
  institution = institution || _.get(Store.getState(), 'Nav.institution')
  let loginName = undefined;

  // TODO: bring it back if needed;
  return ;

  if(currentUser && currentUserRoles) {
    switch (currentUserRoles) {
      case 'PROVIDER:Nurse':
      case 'PROVIDER:Doctor':
      case 'ADMIN:Admin':
      case 'OTHER:Vendor':
      case 'SUPER_ADMIN:Super Admin':
      case 'PROVIDER:Medical Assistant':
        loginId = currentUser.username;
        loginName = _.get(currentUser, 'profile.fullName');
        break;

      case 'MEMBER:Member':
        loginId = _.get(currentUser, 'identification.0.value');
        loginName = _.get(currentUser, 'profile.fullName');
        break;

     default:
    }
  }

  try {
    const paramInJson = {
      LoginID: loginId || '',
      LoginName: loginName || '',
      PatientNRIC: patientNRIC || '',
      PatientName: patientName || '',
      Institution: institution || '',
      Path: source || '',
      Action: action || '',
      Details: details ? JSON.stringify(details, null, 2) : '',
      Request: request ? JSON.stringify(request, null, 2) : '',
      Response: response ? JSON.stringify(response, null, 2) : '',
      Success: success,
      r: new Date().getTime()
    }

    // send the request
    fetch(`${config.SERVER_URL}/log`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: encryptText(config.SECRET_KEY, JSON.stringify(paramInJson))
    });

    //print the size of the redux store if LOG_STORE_SIZE is set to true. this will slow down the app.
    window.VSM_CONFIG.LOG_STORE_SIZE && printReduxSize();

    // (new Image()).src = `${config.SERVER_URL}/log?${params.map(p => `${p.key}=${encodeURIComponent(p.value)}`).join('&')}`;
  }
  catch (e) {
    console.log('Error occurred in AuditLog: ', e)
  }
}
