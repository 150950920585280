import gql from 'graphql-tag'

export default gql`
mutation editOrganization(
  $id:EID!,
  $identifier:String, 
  $name:String!,
  $phone:InputPhone,
  $description:String, 
  $address:InputAddress,
  $timezone:String,
  ) {
  	editOrganization(
      id:$id,
      identifier: $identifier,
      name: $name,
      phone: $phone,
      description: $description,
      address: $address,
      timezone: $timezone
    )
    {
      id
      name
    }
  }
`
