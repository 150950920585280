import gql from 'graphql-tag';

export default gql`
query phoneReportInfoList($memberId:EID){
  phoneReportInfoList(memberId:$memberId){
    data{
      memberId
      FirstUsedAt
      LastUsedAt
    	mobileInfo{
      	phoneId
      	phoneName
      	phoneModel
      	phoneOSVersion
      	appVersion
      }
      addToContactsStatus
      authorityInfo{
        cameraStatus
        locationAlwaysStatus
        contactsStatus
        microphoneStatus
        photoLibraryStatus
        notificationStatus
      }
      connectedDeviceInfo{
        deviceType
        macAddress
      }
    }
  }
}`;
