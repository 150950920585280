import PATRACE from '@ihealth/codetranslate/lib/PATRACE'
import PATNAT from '@ihealth/codetranslate/lib/PATNAT'
import PATLANG from '@ihealth/codetranslate/lib/PATLANG'
import PHONECODE from '@ihealth/codetranslate/lib/PHONECODE'
import PATCOUNTRY from '@ihealth/codetranslate/lib/PATCOUNTRY'
import PATDOCTYPE from '@ihealth/codetranslate/lib/PATDOCTYPE'
import { helpers} from "modulesAll/visit/helpers";

// import CAREGIVERRELATIONSHIP from '@ihealth/codetranslate/lib/CAREGIVERRELATIONSHIP'
const CAREGIVERRELATIONSHIP = {
  "CHILD": "Child",
  "DOMESTICHELPER": "Domestic Helper",
  "GRANDCHILD": "Grandchild",
  "GRANDPARENT": "Grandparent",
  "LEGALGUARDIAN": "Legal Guardian",
  "PARENT": "Parent",
  "SIBLINGS": "Siblings",
  "SPOUSE": "Spouse",
  "OTHERRELATIVE": "Other Relative",
  "OTHERS": "Others",
}
// const DoctorNames = helpers.filterDoctors();
export const HEALTH_CONDITION_DISPLAY = {
  // Diabetes_Type_I: 'Diabetes Type I',
  // Diabetes_Type_II: 'Diabetes Type II',
  // Hypertension: 'Hypertension',
  // Heart_Failure: 'Heart Failure',
  // Ischemic_Heart_Disease: 'Ischemic Heart Disease',
  // COPD: 'Chronic Obstructive Pulmonary Disease (COPD)',
  // Osteoporosis: 'Osteoporosis',
  // Obesity: 'Obesity',
  // Arthritis: 'Arthritis',
  // Asthma: 'Asthma',
  // Cancer: 'Cancer',
  // Depression: 'Depression',
  // Autism_Spectrum_Disorders: 'Autism Spectrum Disorders',
  // Alzheimer: 'Alzheimer’s and Related Dementia',
}
export const HEALTH_CONDITION_ICDCODE = {
  // Diabetes_Type_I: 'A00',
  // Diabetes_Type_II: 'A01',
  // Hypertension: 'A02',
  // Heart_Failure: 'A03',
  // Ischemic_Heart_Disease: 'A04',
  // COPD: 'A05',
  // Osteoporosis: 'A06',
  // Obesity: 'A07',
  // Arthritis: 'A08',
  // Asthma: 'A09',
  // Cancer: 'A10',
  // Depression: 'A11',
  // Autism_Spectrum_Disorders: 'A12',
  // Alzheimer: 'A13',
}
export const nationOptions = Object.keys(PATNAT).map(key => ({name: PATNAT[key], value: key}))
export const langOptions = Object.keys(PATLANG).map(key => ({name: PATLANG[key], value: key}))
export const raceOptions = Object.keys(PATRACE).map(key => ({name: PATRACE[key], value: key}))
export const phoneOptions = Object.keys(PHONECODE).map(key => ({name: `+${key}`, value: `+${key}`}))
export const countryOptions = Object.keys(PATCOUNTRY).map(key => ({name: PATCOUNTRY[key], value: key}))
export const docTypeOptions = Object.keys(PATDOCTYPE).map(key => ({name: PATDOCTYPE[key], value: key}))
export const relationOptions = Object.keys(CAREGIVERRELATIONSHIP).map(key => ({name: CAREGIVERRELATIONSHIP[key], value: key}))
export const DoctorNameArray = helpers.filterDoctors();
export const DoctorNamesOptions = _.map(DoctorNameArray,({fullName})=>({name:fullName.trim(),value:fullName.trim(),key:fullName.trim()}));



