import {Component} from "react";
import React from 'react';
import { Button,Modal,Input,Checkbox,message } from 'antd';
import moment from "moment/moment";
import map from "../constants/tableMap";
import resolveTaskAssignment from '../query/resolveTaskAssignment';
import Client from 'libModule/gqlClient';
import helper from '../helper/';
import {connect} from "react-redux";
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from '../../timer/actions/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import API from '../API/index';

const { stopTimer,updateInterventionDetails }= timerActions.default;
const { priorityMap } = map;
const { TextArea } = Input;

const style = {
        rowStyle:{
            display:'flex',
            flexDirection:'row'
        },
        buttonDivStyle:{
          margin:'10px 0px'
        },
        labelStyle:{
          marginRight: 10
        },
        headerStyle:{
          fontWeight:'bold',
          fontSize:18,
          color: '#000000'
        },
        buttonStyle:{

        },
        reasonStyle:{
           paddingBottom: '10px',
           color: '#474747',
           opacity:0.7
        },
        assignerStyle:{
            color:'#555555',
            marginRight: 10,
            fontWeight:'bold'
        },
        resolveStyle:{
            color:'#555555',
            display:'flex',
            justifyContent:'flex-end'
        }

}

class MyTasksModalComponent extends Component {
    static displayName ='MyTasksModalComponent';

    constructor(){
        super();
        this.state = {
            note:'',
            addToPN: false
        }

    }

    onChangeInput = (e)=>{
        const note = e.target.value;
        this.setState({
            note
        })
    }

    onChangeCheckBox = ()=>{
        const { addToPN } = this.state;
        const { fromTaskList } = this.props;
        this.setState({
            addToPN:!addToPN
        })
        if(fromTaskList === 'TaskList') {
            Mixpanel.track('checked', 'add to provider notes', 'patient task list popup');
        } else {
            Mixpanel.track('checked', 'add to provider notes', 'task detail popup', {});
        }
    }

    close = ()=>{
        const { setModalData } = this.props;
        Mixpanel.track('clicked', 'close', 'task detail popup');
        setModalData({showModal:false,modalData:null});
    }

    handleClick = ()=>{
        const { note,addToPN } = this.state;
        const { modalData,refetchUserList,refetchUser } = this.props;
        const { id,refetch } = modalData;
        const { fromTaskList } = this.props;
        const memberId = _.get(modalData,'member.id');
        Client.mutate({
            mutation:resolveTaskAssignment,
            variables:{ id, resolvedNote:note }
        }).then(res=>{
            if(refetch){
                refetch();
            }
            if(refetchUser){
                refetchUser();
            }
            if(refetchUserList){
                refetchUserList();
            }
            API.fetchTaskUnreadList();
            message.success('Task Resolved');
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            const resource = atob(docId).split(':')[0];
            let displayName = this.constructor.displayName;
            const summary = res.data[apiName].id;
            displayName = `${displayName}:${memberId}`;
            this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
            this.props.stopTimer(displayName, 'saveModal');
            if(addToPN){
                helper.createPN({
                    memberId,
                    content:note
                }).then(res=>{
                    this.close();
                });
            }else {
                this.close();
            }
            const {priorityLevel} = this.props.modalData;
            if(fromTaskList === 'TaskList') {
                Mixpanel.track('clicked', 'resolve', 'patient task list popup');
            } else {
                Mixpanel.track('clicked', 'resolve', 'task detail popup', {PRIORITY_LEVEL: priorityLevel});
            }
        }).catch(e=>{
            Modal.error({ content : e.message});
        })

    }
    renderBody = ()=>{
        const { handleClick,onChangeInput,props,state, fromTaskList } = this;
        const { modalData,renderBody } = props;
        const { note } = state;
        const needReason = !note;
        const { reason,priorityLevel,dueDate,assignedTo,createdAt,member,resolvedNote } = modalData||{};
        const  patientName  = _.get(member,'profile.fullName');
        const mrn = _.get(member,'identification.0.value');
        const assignedToName = _.get(assignedTo,'profile.fullName');
        const resolvedNoteBy = _.get(resolvedNote,'resolvedBy.profile.fullName');
        const resolvedAt = _.get(resolvedNote,'resolvedAt');
        const resolvedNoteContent = _.get(resolvedNote,'note','');
        const outterContainer = !resolvedNote ?
            <div className='outerContainer'>
            <div className='upperContainer'>
                <div style={style.headerStyle}>{ renderBody? 'Reason' :`${patientName} (${mrn})`}</div>
                <div style={style.rowStyle} className='leftContainer'>
                    <div style={{ marginRight:15 }}>
                        <b style={style.labelStyle}>Priority Level:</b>{priorityMap[priorityLevel]}
                    </div>
                    <div>
                        <b style={style.labelStyle}>Due Date:</b>{ dueDate ? moment(dueDate).format('MM/DD/YYYY'):'Not Specified'}
                    </div>
                </div>
                <div className='rightContainer'>
                    <div style={style.assignerStyle}>Assigner:
                        <div style={style.assignerStyle}>{assignedToName}</div>
                    </div>
                    <div>{moment(createdAt).format('MM/DD/YYYY hh:mm')}</div>
                </div>
                <div style={style.reasonStyle}>
                    {reason}
                </div>
            </div>
            <div className='lowerContainer'>
                <TextArea rows={6} placeholder="Add Note Here"  onChange={ (e)=>onChangeInput(e) }/>
                <div style={{ marginTop:10 }}>
                    { !needReason ?  <Checkbox onChange={ this.onChangeCheckBox }>Add to Provider Notes</Checkbox> :'' }
                </div>
            </div>
        </div> :
        <div className='outerContainer'>
            <div className='upperContainer'>
                <div style={style.headerStyle}>{ renderBody? 'Reason' :`${patientName}(${mrn})`}</div>
                <div style={style.rowStyle} className='leftContainer'>
                    <div style={{ marginRight:15 }}>
                        <b style={style.labelStyle}>Priority Level:</b>{priorityMap[priorityLevel]}
                    </div>
                    <div>
                        <b style={style.labelStyle}>Due Date:</b>{ moment(dueDate).format('MM/DD/YYYY')}
                    </div>
                </div>
                <div className='rightContainer'>
                    <div style={style.assignerStyle}>Assigner:
                        <div style={style.assignerStyle}>{assignedToName}</div>
                    </div>
                    <div>{moment(createdAt).format('MM/DD/YYYY hh:mm')}</div>
                </div>
                <div style={style.reasonStyle}>
                    {reason}
                </div>
                <div>
                    <div style={style.assignerStyle}>Resolved Note:
                        <div style={style.reasonStyle}>{resolvedNoteContent}</div>
                    </div>
                    <div style={style.resolveStyle}>
                        Resolved By:<div style={style.reasonStyle}>{resolvedNoteBy}</div>
                        <div style={Object.assign({marginLeft:20},style.reasonStyle)}>{moment(resolvedAt).format('MM/DD/YYYY hh:mm')}</div>
                    </div>
                </div>
            </div>
        </div>
        const footer =  !resolvedNote ? <div style={style.buttonDivStyle}>
            <Button onClick={ ()=>handleClick() } type='primary' disabled={needReason}>Resolve Task</Button>
        </div>:'';
        return <div className={`myTaskModal ${renderBody ? 'inPatientProfile':''}`}>
                  {/*<div className='outerContainer'>*/}
                    {/*<div className='upperContainer'>*/}
                        {/*<div style={style.headerStyle}>{ renderBody? 'Reason' :`${patientName}(${mrn})`}</div>*/}
                        {/*<div style={style.rowStyle} className='leftContainer'>*/}
                            {/*<div style={{ marginRight:15 }}>*/}
                                {/*<b style={style.labelStyle}>Priority Level:</b>{priorityMap[priorityLevel]}*/}
                            {/*</div>*/}
                            {/*<div>*/}
                                {/*<b style={style.labelStyle}>Due Date:</b>{ moment(dueDate).format('MM/DD/YYYY')}*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className='rightContainer'>*/}
                            {/*<div style={style.assignerStyle}>Assigner:*/}
                                {/*<div style={style.assignerStyle}>{assignedToName}</div>*/}
                            {/*</div>*/}
                            {/*<div>{moment(taggedDate).format('MM/DD/YYYY hh:mm')}</div>*/}
                        {/*</div>*/}
                        {/*<div style={style.reasonStyle}>*/}
                            {/*{reason}*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className='lowerContainer'>*/}
                        {/*<TextArea rows={6} placeholder="Add Note Here"  onChange={ (e)=>onChangeInput(e) }/>*/}
                        {/*<div style={{ marginTop:10 }}>*/}
                            {/*{ !needReason ?  <Checkbox onChange={ this.onChangeCheckBox }>Add to Provider Notes</Checkbox> :'' }*/}
                        {/*</div>*/}
                    {/*</div>*/}

                  {/*</div>*/}
                  { outterContainer }
                  { footer }
               </div>
    }
    renderTimer(){
        const { modalData } = this.props;
        let displayName = this.constructor.displayName;
        const memberId = _.get(modalData,'member.id');
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const keepEventListener = true;
        return <TimerContainer displayName={displayName} category='TASK_ASSIGNMENT' action='UPDATE'
                               viewingDetails={viewingDetails}
                               patientId={memberId}
                               keepEventListener={keepEventListener}
        />
    }
    render(){
        const { props,state } = this;
        const { setModalData,renderBody } = props;

        return <div>
                        {this.renderTimer()}
                        {
                            !renderBody ?
                                <Modal visible={true} footer={null} maskClosable={false}
                                       onCancel={() => setModalData({showModal: false, modalData: null})} destroyOnClose={true}>
                                    {this.renderBody()}
                                </Modal> : this.renderBody()
                        }
                </div>
    }

}
const mapToProps = (state,props)=>{
    const uuid = _.get(state,'timer.main.uuid',null);
    return {
        uuid
    }
};
const mapToDispatch = (dispatch)=>{
    return {
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
};
export default connect(mapToProps,mapToDispatch)(MyTasksModalComponent);

