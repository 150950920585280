import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo'
import find from 'lodash/find'
import moment from 'moment'
import { i18nPanel, i18nRadioButton } from 'libModule/helpers/component-helpers'

//files
import VitalList from '../components/VitalListComponent';
import actions from '../actions/VitalListBPActions';
const { changeRange, changeView, onTableChange, changeMode, openPatientVitalModal } = actions;
import { changeRange as changeRangeWrapper } from '../actions/VitalListWrapperActions'
import { severity }from '../constants/map';

//components
import { IHRadio, IHPanel, VSMDateRange } from 'ihcomponent'
//queries
import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList'
import BPChartComponent from '../components/BPChartComponent'
import { formatVariables } from '../util/tasks'

const style = {
  panel: {
    margin : '20px',
    borderRadius: '4px'
  }
}
class BPWrapper extends React.Component {
  static displayName = 'patient/vitalList/containers/BPContainer'

  // componentDidUpdate(prevProps) {
  //   if((prevProps.currentPage !== this.props.currentPage) || (prevProps.sorter !== this.props.sorter)) {
  //     const { sorter, pagination, currentPage } = this.props
  //     const count = get(pagination, 'pageSize')
  //     this.props.refetch && this.props.refetch({sort: sorter, count, currentPage})
  //   }
  // }
  componentWillReceiveProps(nextProps) {
    if ((nextProps.enrolledProgramId !== this.props.enrolledProgramId) ||
      (nextProps.BP !== this.props.BP) &&
      this.props.BP &&
      this.props.enrolledProgramId) {
      this.props.onTableChange({pageSize: 5, current: 1}, {}, { columnKey: 'date', order: null })
      this.props.changeMode('BP')
      this.props.changeView('List')
    }
  }
  componentWillUnmount() {
    this.props.onTableChange({pageSize: 5, current: 1}, {}, { columnKey: 'date', order: null })
    this.props.changeMode('BP')
    this.props.changeView('List')
  }
  _generateData(data, range) {
    if (data === void 0) // when loading, data is undefined
      return []
    const start = moment(range[0]).startOf('day').toDate()
    const end = moment(range[1]).endOf('day').toDate()
    return data.data.filter(d => (moment(d.date).toDate() >= start && moment(d.date).toDate() <= end) && d.severity !== 'MISSED' )
  }
  _renderChartSwitch() {
    if (this.props.view === 'Chart') {
      const radioProps = {
        type: 'radioButton',
        value: this.props.mode,
        option: [{name: 'BP', value: 'BP'}, {name: 'HR', value: 'HR'}],
        className: 'vitals-radio-btn',
        onChange: (e) => {
          this.props.changeMode(e.target.value)
        }
      }
      return (
        <div style={{display: 'inline-block'}}>
          <IHRadio {...i18nRadioButton(radioProps, 'vitalsPanelAndButtons')}/>
        </div>)
    }
    return null
  }
  _getThresholds(thresholds) {
    if (thresholds && thresholds.length > 0) {
      const systolic = find(thresholds, { measure: 'SYSTOLIC' }).range
      const diastolic = find(thresholds, { measure: 'DIASTOLIC' }).range
      return {
        Systolic: [systolic[1], systolic[2]],
        Diastolic: [diastolic[1], diastolic[2]]
      }
    }
  }
  render() {
    const radioProps = {
      type: 'radioButton',
      value: this.props.view,
      option: [ {name:'List', value:'List'}, {name:'Chart', value:'Chart'}],
      className: 'vitals-radio-btn',
      onChange: (e) => {this.props.changeView(e.target.value)}
    }
    const bpData = this._generateData(this.props.vitalList, this.props.range)
    const listProps = {
      ...this.props,
      data: bpData,
      type: 'BP',
      title: 'Blood Presssure'
    }
    const p = {
      header : {
        title: 'Blood Pressure',
        right: (
          <div>
            {/*{this._renderChartSwitch.bind(this)()}*/}
            <div style={{display: 'inline-block', margin: '0 20px'}}>
              <IHRadio {...i18nRadioButton(radioProps, 'vitalsPanelAndButtons')}/>
            </div>
            <div style={{display: 'inline-block'}}>
              <VSMDateRange value={this.props.range}
                            onChange={(v) => {
                              this.props.changeRange([moment(v[0]), moment(v[1])])
                              this.props.changeRangeWrapper(null)
                            }
                            }/>
            </div>
          </div>)
      },
      body: (this.props.view === 'List' || bpData.length === 0 || this.props.loading)?
        (<VitalList {...listProps} />) :
        (<BPChartComponent data={bpData}
                           mode={this.props.mode}
                           range={this.props.range}
                           thresholds_BP={this._getThresholds(this.props.BP_thresholds)}
        />)
    }
    return (
      <div style={style.panel} className="vital-list-wrapper">
        <IHPanel {...i18nPanel(p, 'vitalsPanelAndButtons')} />
      </div>
    )
  }
}

const withData = graphql( resultList, {
  options: (ownProps) => {
    let variables = formatVariables(ownProps, 'BP', severity);
    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ownProps, data}) => {
    const { loading, refetch, resultList } = data
    return {
      vitalList: resultList,
      loading,
      refetch
    }
  }
})

const BPWrapperWithData = withData(BPWrapper)

const mapState = (state, ownProps) => {
  const { BP: { sorter, pagination, view, range, mode } } = state.VitalListForProvider
  // const { loading, refetch, programList } = ownProps.data || {}
  return {
    sorter,
    pagination,
    view,
    range,
    mode
  }
}

// const mapDispatchObj = {
//   ...actions,
//   dispatch: (dispatch) => dispatch,
// }

BPWrapper.propTypes = {
  sorter: PropTypes.object,
  pagination: PropTypes.object,
  currentPage: PropTypes.number,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  view: PropTypes.string,
  changeView: PropTypes.func,
  range: PropTypes.any,
  changeRange: PropTypes.func,
  changeRangeWrapepr: PropTypes.func,
  mode: PropTypes.string,
  changeMode: PropTypes.func,
  onTableChange: PropTypes.func
}

export default connect(
  mapState, { changeRange, changeView, onTableChange, changeMode, changeRangeWrapper, openPatientVitalModal }
)(BPWrapperWithData)
