const types = {
    'init/followUp':{
        text:'Initial Visit',
        color:'#36c5cc'
    },
    'followUp':{
        text:'Follow Up',
        color:'#4278c3'
    },
    'additionalVisit':{
        text:'Additional Visit',
        color:'#ee7c81'
    },
    // 'annual':{
    //     text:'Annual Visit',
    //     color:'#fdb543'
    // }

}

export default types;
