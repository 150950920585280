import ApolloClient from 'apollo-client';
import {HttpLink, createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import {InMemoryCache} from "apollo-cache-inmemory";
import { browserHistory } from 'react-router';
import config from './config';
import { decryptRole } from 'libModule/utils';
import { getAPI_KEY } from 'modulesAll/utils/helpers/API_KEY'
import fragmentMatcher from './fragmentMatcher';
import dataIdFromObject from './dataIdFromObject';
import { onError } from 'apollo-link-error';
import { Modal } from 'antd';
const URI = `${config.SERVER_URL}/graphql`;
const graphiURL = `${config.SERVER_URL}/graphiql`

// const networkInterface = createNetworkInterface({
//   uri: URI,
//  opts: {
//     credentials: 'same-origin',
//   },
// });
const linkError = onError(({networkError,graphQLErrors,forward,operation,response})=>{

  if(networkError){
    Modal.error({
        title:'Network Error.',
        content:'Network Error:connection disconnected.'
    })
  }
/*
  if(graphQLErrors){
      graphQLErrors.map(({ message, locations, path }) =>{
          Modal.error({
              title:`Graphql Error`,
              content:message
          })
  })
      response.errors = null;
  };*/
})

const httpLink = new HttpLink({
  uri: URI,
  credentials: 'same-origin'
})

const cache = new InMemoryCache({
  dataIdFromObject,
  addTypename: true,
  cacheRedirects: {},
  fragmentMatcher,
});

const globalRequests = {};

const middleWareLink = setContext((req) => {
  // flatten options for the API change
  let variables = req.variables;
  variables = {
    ...variables,
    ...variables.options
  };
  if (variables.options) {
    console.log('variables have options, this is likely from an out-dated query ', variables.options, req);
    delete variables.options;
  }
  //console.log(variables);
  const debug = globalRequests[req.options] = {
    input: req.query.loc.source.body,
    name: req.operationName,
    variables,
  };

  const userRole = decryptRole()
  let sessionToken = sessionStorage.getItem('authToken') || '';

  const noTokenMethods = ['loginWithUsernameOrEmail', 'resetPassword', 'requestResetPassword'];
  if (noTokenMethods.indexOf(req.operationName) !== -1) {
    sessionToken = null;
  }

  const API_KEY = process.env.API_KEY || window.VSM_CONFIG.API_KEY; //getAPI_KEY(window.VSM_CONFIG.NODE_ENV, userRole) || window.VSM_CONFIG.API_KEY;

  const url = `${graphiURL}?query=${encodeURIComponent(debug.input)}&variables=${encodeURIComponent(JSON.stringify(debug.variables))}`;
  if (debug.name.indexOf('login') === -1 && debug.name.indexOf('authenticate') === -1 && debug.name !== 'user') {
    Log.debug(`%c${debug.name}:`,  `font-size: 14px; font-weight: bold; color: rgb(0,191,255);`);
    Log.debug('variables', debug.variables);
    Log.debug('graphiURL', url.length >= 10000 ? 'too long' : url);
  }

  return {headers:{
    'Accept-Encoding': 'gzip',
    'x-session-token': sessionToken,
    'x-api-key':API_KEY,
  }};

});

const afterWareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((res) => {
    const context = operation.getContext();
    const { response,options } = context;
    if (globalRequests[options] && window.VSM_CONFIG.NODE_ENV === 'development') {
      const debug = globalRequests[options];
      // const output = response.clone().json().then(j => {
      //   const hasError = j.errors;
      //
      //   const url = `${graphiURL}?query=${encodeURIComponent(debug.input)}&variables=${encodeURIComponent(JSON.stringify(debug.variables))}`;
      //   if (false) {
      //     Log.group(`%c${debug.name}: ${hasError ? '×' : '√'}`, `font-size: 14px; font-weight: bold; color: ${hasError ? 'red' : 'green'}`);
      //     Log.debug('variables', debug.variables);
      //     Log.debug('errors', j.errors);
      //     Log.debug('response', j.data);
      //     Log.debug('graphiURL', url.length >= 10000 ? 'too long' : url);
      //     Log.groupEnd();
      //   }
      //   delete globalRequests[options];
      // });
    }
    // log out user if there is internal server error (happens when redis is flushed)
    if (response.status === 500) {
      sessionStorage.clear();
      browserHistory.push('/login');
    }
    return res;
  })
});

export default  new ApolloClient({
  link:ApolloLink.from([linkError,middleWareLink,afterWareLink,httpLink]),
  // use restore on the cache instead of initialState
  cache: cache.restore(window.__APOLLO_CLIENT__),//?
  ssrMode:false,//?
  //ssrForceFetchDelay: 100,//?
  connectToDevTools: true,
  queryDeduplication: true,//?
});
