import React from "react";
import {compose, graphql} from "react-apollo/index";
import iotDeviceConfigList from "../query/iotDeviceConfigList";
import { Form,Select,AutoComplete,Button,Switch,Icon,Tooltip } from 'antd';
import '../style/index.scss';
import {connect} from "react-redux";
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'libModule/utils';
import { message } from 'ihcomponent';
import { language,languageConfig } from '../constant/';

const Item = Form.Item;
const Option = AutoComplete.Option;


let AddNewDevice =  class extends React.Component {
    constructor(){
        super();
    }

    getOptions(iotDeviceConfigList) {
        const options = iotDeviceConfigList.map(({deviceId}) => {
            return <Option key={deviceId} value={deviceId}>{deviceId}</Option>
        });

        return options;
    }

    renderAutoComplete(iotDeviceList) {
        const children = [...this.getOptions(iotDeviceList)];
        const { disabled }  = _.get(this,'props.state.clearInfo');

        return (
            <Select
                dropdownMatchSelectWidth={true}
                dataSource={children}
                style={{ width: 200 }}
                allowClear={true}
                showSearch
                disabled={disabled}
                notFoundContent='Device Number not Found'
            >
                { children }
            </Select>
        )
    }

    getLanguageOptions() {
        const { disabled }  = _.get(this,'props.state.clearInfo');

        const options = language.map(({key,value}) => {
            return <Select.Option key={key} value={key}>{value}</Select.Option>
        });
        return <Select
                dataSource={options}
                style={{ width: 200 }}
                onChange={()=>this.setVolume()}
                disabled={disabled}
               >
                {options}
               </Select>

    }

    onSubmit = (e)=>{
        e.preventDefault();
        const { form,updateIoTDeviceMemberAPI,editIoTDeviceConfigAPI,openErrorModal,setInputFieldDisabled, setAssignment } = this.props;
        const memberId = _.get(this.props, 'data.user.id', _.get(this.props,'userId'));
        const { validateFields } = form;

        validateFields(['deviceId', 'language', 'volume'], (errors, { deviceId, language, volume }) => {
            if(!errors){
                let variables = {
                    deviceId,
                    memberId,
                };
                updateIoTDeviceMemberAPI(variables,memberId)
                    .then(res=>
                        editIoTDeviceConfigAPI({ deviceId,language,volume: volume ? 50 : 0 },memberId)
                            .then(res=>{
                                message.success('Device Assigned');
                                if(setInputFieldDisabled) setInputFieldDisabled('clearInfo');
                                if(setAssignment) setAssignment(true, { deviceId });
                            })
                            .catch(e=>{
                                openErrorModal(GQLHelper.formatError(e))
                            })
                            
                    )
                    .catch(e=>{
                        openErrorModal(GQLHelper.formatError(e))
                    });

            }
        })
    }

    setVolume(){
        const { form } = this.props;
        const { resetFields } = form;
        resetFields(['volume'])
    }

    renderForm=()=>{
        const { form,iotDeviceConfigList } = this.props;
        const { getFieldDecorator,getFieldValue } = form;
        const selectedLanguage = getFieldValue('language');
        const { disabled }  = _.get(this,'props.state.clearInfo');

        return <Form className='addDeviceForm' layout={'vertical'}>
                    <Item label="Device Id" required={true}>
                        {getFieldDecorator('deviceId',{rules:[{
                                required:true, message: 'Please select a device Id'
                            }]})(this.renderAutoComplete(iotDeviceConfigList||[]))}
                    </Item>
                    <Item label="Device Language" >
                        {getFieldDecorator('language',{ initialValue:'en'})(this.getLanguageOptions())}
                    </Item>
                    <Item label={
                                    <div>
                                    Text to Speech
                                    <Tooltip title={`Enable speech output on the blood pressure monitor to read out results.Currently only available for English and French`} >
                                        <Icon type='info-circle' style={{ marginLeft: 10 }}/>
                                    </Tooltip>
                                    </div>
                                 }
                    >
                        {getFieldDecorator('volume', { initialValue:false,valuePropName: 'checked' })(
                                <Switch disabled={disabled||(!_.get(languageConfig,`${selectedLanguage}.volume`,false))} />
                            )}
                    </Item>
                    <Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Button type="primary" htmlType="submit" onClick={(e)=>this.onSubmit(e)} disabled={disabled}>
                            Assign Device
                        </Button>
                    </Item>
               </Form>
    }

    render(){
        return this.renderForm();
    }
}
const withData = graphql(iotDeviceConfigList,{
    options:(ownProps)=>{
        return {
            variables:{
                deviceStatusFilter:'AVAILABLE',
            },
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
        const iotDeviceConfigList  = _.get(data,'iotDeviceConfigList.data',[]);
        const loading = _.get(data,'loading');
        if(loading){
            return{
                loading
            }
        }
        return{
            iotDeviceConfigList
        }
    }
});

AddNewDevice = Form.create({onFieldsChange(props){
        props.handleOnFocus('clearInfo')
    }})(AddNewDevice);
const mapToDispatch = (dispatch)=>{
    return{
        openErrorModal: err => dispatch(openErrorModal(err)),
    }
}
export  default compose(connect(null,mapToDispatch),withData)(AddNewDevice);
