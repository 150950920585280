import { IHButton, React, _ } from 'ihcomponent'
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { graphql, compose } from 'react-apollo';
import createTableAction from 'libModule/table/TableActions';
import { modalAction } from 'modulesAll/common/actions'
import I18N from '../../../I18N';
import ListComponent from '../components/ListComponent';
import actions from '../actions/MainActions'
import userList from 'graphqlModule/patientList'
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';

const BUTTON_LABELS = I18N.get('patientlist.buttons');
const tableActions = createTableAction('patient_list',{
  sortFieldMap: { age: 'BIRTHDAY', fullName:'LAST_NAME',timeSpent:'TIME_SPENT',registeredAt:'REGISTERED',timeSpent:'TIME_SPENT'},
  shouldUpdateHistory: true,
  initState:{filter:{ status:["INCOMPLETED", "ENROLLED", "SUSPENDED"] }},
});

const withData = graphql(userList, {
  options: (ownProps) => {
    let filter = _.cloneDeep({ ...ownProps.filter });
    // if(!ownProps.isStatusFilterClicked){
    //     filter.status = defaultFilter;
    // }
    let { timeSpent } = filter;
    filter.showAll = true;
    if (!isNaN(filter.daysRemaining)) {
      filter.daysRemaining = filter.daysRemaining[0];
    }
    const searchStrDatabase = _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', '');
    const searchStr = searchStrDatabase //(filter.showAll && !searchStrDatabase.length) ? '~&$' : searchStrDatabase; //if searching the database (checkbox is checked), don't return any result if the search string is empty
    //if searching patient with search bar, remove any condition applied.
    if(searchStr){
        filter = _.omit(filter,['status','gender']);
    }

    //parse time spent in min-max range
    if(timeSpent){
        let timeSpentObj = (()=>{
            return _.map(timeSpent,(t)=>{
                const timeRange = t.split(':');
                return { min: timeRange[0]*60, max: timeRange[1]*60 };
            })
        })()
        filter.timeSpent = timeSpentObj;
    }

    const variables = {
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      filters: filter,
      search: {
        fields: ['NAME', 'IDENTIFICATION', 'PHONE'],
        match: searchStr,
      },
      sort: { 'field': 'LAST_NAME', 'direction': 'ASC' } //{ 'field': 'BIRTHDAY', 'direction': 'DESC' }
    };
    const sortField = _.get(ownProps, 'sort.fieldGraph', null);
    if (sortField) {
      let direction = _.get(ownProps, 'sort.direction');
      const INVERSE = {
        'ASC': 'DESC',
        'DESC': "ASC"
      };

      if (sortField === 'BIRTHDAY')direction = INVERSE[direction]; //sorting by birthday is inverse of sorting by age

      variables.sort = {
        field: sortField,
        direction: direction
      };
    }

    return {
      variables,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    };
  },
  props:({data})=>{
        return {
            data
        };
  }
});


const Component = class extends React.Component {
    // (props) => {
    constructor(){
        super();
        this.state = {
            showModal: false,
            modalData: null
        }
    }

    setModalData = ({showModal,modalData})=>{
        this.setState({
            showModal,
            modalData
        })
    }

    render() {
        const { setModalData,state,props } = this;
        const { showModal,modalData } = state;
        const buttonProps = {
            label: BUTTON_LABELS.newPatientButton,
            size: '',
            type: 'primary',
            onClick: () => {
                Mixpanel.track('clicked', 'register new Patient', '', '');
                browserHistory.push('/patients/register/manual_input');
                // Mixpanel.track('click',BUTTON_LABELS.newPatientButton.split(' ').join('_'),'patient_list');
            }
        };
        return (
            <div className="vsm-main-page vsm-patientList-main">
                <div className="vsm-main-990">
                    <div className="v-rd">
                        <IHButton {...buttonProps} />
                    </div>
                    <div className="v-table">
                        <ListComponent {...props} setModalData={setModalData} modalData={modalData} showModal={showModal}/>
                    </div>
                </div>
            </div>
        )
    }
};


const mapStateToProps = (state, ownProps) => ({
  ...state.globalTables.patient_list,
  ...state.PatientList
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...tableActions,
    setSearchDatabase: (searchDatabase) => dispatch(actions.setSearchDatabase(searchDatabase)),
    showPatientStarModal: (show, type, user) => dispatch(modalAction.showPatientStarModal(show, type, user)),
    setStatusFilterClicked:()=>dispatch(actions.setStatusFilterClicked())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withData
)(Component);
