import store from './reduxStore'
import { decryptRole } from 'libModule/utils';
import { HOMEPAGES } from 'modulesAll/utils/constants/menuPath'

const requireAuth = (nextState, replace) => {
  if(!sessionStorage.getItem("authToken") || !sessionStorage.getItem("currentUser")) {
    if(nextState.location.pathname !== '/onboard/' && nextState.location.pathname !== '/resetpass/') {
      replace('/login')
    }
  }
}

const setLocation = function(nextState, replace){
    if(nextState.location){
      store.dispatch({
        type : 'root/app/SET_LOCATION',
        location : nextState.location,
        params : nextState.params
      })
    }
}

const enterIndex = (ns, replace)=>{
  const role = decryptRole()
  if (HOMEPAGES[role]) {
    replace(HOMEPAGES[role])
  }
  else {
    replace('/not-authorized')
  }
}

export { requireAuth, setLocation, enterIndex}
