import {React,moment} from 'ihcomponent'
import PropTypes from 'prop-types';
import {graphql} from 'react-apollo'
import i18nMap from '../../../I18N/index';
import {createContainer, mapPropsToFields, goPath, FormHelper, GQLHelper} from 'libModule/utils'
import AddNewPatient from '../components/AddNewPatient'
import action from '../actions/AddNewPatient'
import createUser from 'graphqlModule/createUser';
// import registerMemberWithEmailGQL from 'modulesAll/graphql/registerMemberWithEmail'
import {modalAction} from 'modulesAll/common/actions'
import {getFormProps} from 'modulesAll/patientList/registerNew/components/formData/AddNewPatientForm'
import get from 'lodash/get'
import convertor from '@ihealth/convertor';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { convertFeetToInches } from 'libModule/utils/convertUnits'
import yearOfDiagnoseMap from '../constants/yearOfDiagnose';
import { DATE_HELPERS } from '../../../../lib/helpers/date-helpers';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';
const BUTTON_LABELS = I18N.get('patientlist.buttons');

const Container = class extends React.Component {
    static displayName = 'patientList/registerNew/containers/AddNewPatient'

    getProps() {
      const {openSuccessModal, openErrorModal, resetForm, updateForm} = this.props
      const {fieldsValue} = this.props.AddNewPatient

      const formValue = FormHelper.getValue(this.props.AddNewPatient.fieldsValue)

      // in IHForm, when render, get 'onFieldsChange' and 'mapPropsToFields', then map them
      // then ND.Form.create(reduxOptions)
      const onFieldsChange = (fieldsProps, fields) => {
        // console.log('fieldsProps:', fieldsProps, 'fields:', fields);
        // fieldsProps is form{}, wrappedComponentRef{} I guess it's this. this container
        // update redux, which is patient - register - AddNewPatient - fieldsValue - firstname/lastname...
        updateForm(fieldsProps, fields)
      }

      // defination of ../components/formData/AddNewPatientForm
      const formProps = getFormProps({onFieldsChange, mapPropsToFields, fieldsValue})

      return {
        ...this.props,
        itemList: formProps.itemList,
        onFieldsChange,
        mapPropsToFields,
        handleCancelClick: () => {
          // goPath('/patients/register/manual_input')
            Mixpanel.track('clicked',BUTTON_LABELS.cancelButton,'create_patient');
            goPath('/patients')
        },
        formError: !formValue,
        handleCreateClick: async(close) => {
          const value = formValue
          if (false === value) {
            return false
          }
          // const variables = this.getMutateVariable(value, this.props.AddNewPatient.caregiverList)
          const mutatedValue = this.getMutateVariable(value);
          const variables = DATE_HELPERS.trimAllValues(mutatedValue,'',mutatedValue);

          try {
            const res = await this.props.registerMemberWithEmail({variables})
            if (res.data.createUser) {
              createAuditLog({
                action: I18N.get('auditLog.provider.patient.registerNew'),
                details: fieldsValue,
                request: variables,
                response: res
              })
              Mixpanel.track('clicked',BUTTON_LABELS.createButton,'create_patient');
              const modalTitle = 'Patient Registration Successful'
              const modalContent = `Patient has been created!`
              // openSuccessModal(modalTitle, modalContent)
              resetForm()
              goPath(`/patients/${get(res, 'data.createUser.id')}/enrolledprogram/default`)

                // goPath('/patients')
            }
          } catch (e) {
            createAuditLog({
              action: I18N.get('auditLog.provider.patient.registerNew'),
              details: fieldsValue,
              request: variables,
              response: e,
              success: false
            })
            openErrorModal(GQLHelper.formatError(e))
            close(); // only reset button state when there is error; don't need to reset upon successful creation bc button already unmounted
          }

        },
        handleEnrollClick: async(close) => {

          const value = formValue
          if (false === value) {
            return false
          }

          // const variables = this.getMutateVariable(value, this.props.AddNewPatient.caregiverList)
          const variables = this.getMutateVariable(value)
          try {
            const res = await this.props.registerMemberWithEmail({variables})
            if (res.data.createUser) {
              createAuditLog({
                action: I18N.get('auditLog.provider.patient.registerNew'),
                details: fieldsValue,
                request: variables,
                response: res
              })
              const modalTitle = 'Patient Registration Successful'
              const modalContent = `Patient has been created!`
              openSuccessModal(modalTitle, modalContent)
              resetForm()
              createAuditLog({ action: I18N.get('auditLog.provider.patient.continueToEnrollment')})
              goPath(`/patients/${get(res, 'data.createUser.id')}/enrolledprogram/default`)
            }
          } catch (e) {
            createAuditLog({
              action: I18N.get('auditLog.provider.patient.registerNew'),
              details: fieldsValue,
              request: variables,
              response: e,
              success: false
            })

            openErrorModal(GQLHelper.formatError(e))
            close(); // only reset button state when there is error; don't need to reset upon successful creation bc button already unmounted
          }
        }
      }
    }

    render() {
      // add AddNewPatient from ../components
      // p is itemList, onFieldChange, and functions(cancel, create, enroll)
      const p = this.getProps()
      return <AddNewPatient {...p}/>
    }

    getMutateVariable(data) {
      const appRoles = JSON.parse(sessionStorage.getItem('appRoles'));
      const phone = []
      let address = null;
      let canUseForLogin = _.get(data,'canUseForLogin','true');
      if (data.mobilePhone) {
        phone.push({
          countryCode: data.mobileCountryCode,
          number: parseInt(data.mobilePhone, 10),
          type: 'MOBILE',
          canUseForLogin: (canUseForLogin==='true')
        })
      }
      if (data.homePhone) {
        phone.push({
          countryCode : data.homeCountryCode,
          number : parseInt(data.homePhone, 10),
          type : 'HOME'
        })
      }
      if (data.streetName || data.streetNumber || data.city || data.state) {
        address = [
          {
            streetName: data.streetName,
            streetNumber: data.streetNumber,
            city: data.city,
            state: data.state,
            country: data.country,
          }
        ]
        if (data.unit) {
          address[0].unit = data.unit
        }
        if (data.floor) {
          address[0].floor = data.floor
        }
        if (data.postCode) {
          address[0].postCode = data.postCode
        }
      }
      const memberProfile = {
        // birthday: data.DOB.format('YYYY-MM-DD')
        birthday: moment(data.DOB).format('YYYY-MM-DD')
      };
      const commonProfile = {
        firstName: data.firstName,
        lastName: data.lastName,
        nickname: data.nickname
      }
      if (data.gender) {
        commonProfile.gender = data.gender
      }
      if (data.race) {
        memberProfile.race = data.race
      }
      if (data.language) {
        memberProfile.language = data.language
      }
      if (data.nationality) {
        memberProfile.nationality = data.nationality
      }
      if (data.height || data.height_inches) {
        memberProfile.height = {
          value: convertor.height(Number(convertFeetToInches(data.height)) + Number(data.height_inches), 'inch', 'cm'),
          unit: i18nMap.get('measure.units_height_cm')
        }
      }
      if (data.weight) {
        memberProfile.weight = {
          value: convertor.HS(data.weight, 'lbs', 'kg'),
          unit: i18nMap.get('measure.units_weight_kg')
        }
      }
      if(data.doctorId){
        memberProfile.doctorId = data.doctorId;
      }
      //added insurance provider field
      if(data.insuranceProvider){
        memberProfile.insuranceProvider = data.insuranceProvider;
      };
      memberProfile.yearOfDiagnose = [];
      const keys = Object.keys(yearOfDiagnoseMap);
      _.forEach(keys,(k)=>{
        if(data[`${k}`]){
            memberProfile.yearOfDiagnose.push({
                condition:yearOfDiagnoseMap[`${k}`],
                diagnosedDate:data[`${k}`]
            })
        }
      });

      if(memberProfile.yearOfDiagnose.length==0){
        _.omit(memberProfile,'yearOfDiagnose');
      }
      const result = {
        //to fix: not hard-code organizationId
        // organizationId: btoa('5841068ad7978436eeeca3ee'), // 'NTg0MTA2OGFkNzk3ODQzNmVlZWNhM2Vl'
        // role: 'MEMBER',
          role: appRoles['MEMBER:Member'],
          address,
          identification: {
            type: data.nationtype,
            value: data.identificationValue
          },
          commonProfile,
          memberProfile
        // caregiver: caregivers
      }

      if (data.email) {
        result.email = data.email
      }

      if (phone.length > 0) {
        result.phone = phone
      }
      if (data.username) {
        result.username = data.username;
      }

      return result
    }
}

Container.propTypes = {
  dispatch: PropTypes.func,
  AddNewPatient: PropTypes.object,
  registerMemberWithEmail: PropTypes.func,
  openSuccessModal: PropTypes.func,
  openErrorModal: PropTypes.func,
  resetForm: PropTypes.func,
  updateForm: PropTypes.func
}

const ComponentWithData = graphql(createUser, {name: 'registerMemberWithEmail'})(Container)

export default createContainer(ComponentWithData, ({patient}) => {
    return {
        ...patient.register,
        flag: false,
        importValue: {}
    }
}, (dispatch) => {
    return {
      openSuccessModal: (title, content) => dispatch(modalAction.openSuccessModal(title, content)),
      openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
      resetForm: () => dispatch(action.resetForm()),
      updateForm: (fieldsProps, changedFields) => dispatch(action.updateForm(fieldsProps, changedFields)),
      addCaregiver: () => dispatch(action.addCaregiver()),
      removeCaregiver: (idx) => dispatch(action.removeCaregiver(idx))
  }
})
