import gql from 'graphql-tag'
import {Device} from './schema/common'

export default gql`
  mutation setDevicesForEnrolledProgram(
    $id: EID,
    $token: Token,
    $BG: DeviceAddressInput,
    $HR: DeviceAddressInput,
    $HS: DeviceAddressInput,
    $BP: DeviceAddressInput,
    $TM: DeviceAddressInput,
    $tablet: DeviceAddressInput,
    $forms: [EnrolledProgramFormInput]
  ) {
    setDevicesForEnrolledProgram(
      id: $id,
      token: $token,
      BG: $BG,
      HS: $HS,
      HR: $HR,
      BP: $BP,
      TM: $TM,
      TABLET: $tablet
      forms: $forms
    ) {
      secret
      certificate
      enrolledProgram {
        id
        status
        devices{
          ${Device}
        }
        tasks {
          id
        }
      }
    }
  }
`
