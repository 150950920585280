import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo'
import find from 'lodash/find'
import moment from 'moment'
import { i18nPanel, i18nRadioButton } from 'libModule/helpers/component-helpers'

//files
// import VitalListTable from '../components/VitalListTableComponent';
import VitalList from '../components/VitalListComponent';
import actions from '../actions/VitalListHRActions';
const { changeRange, changeView, onTableChange, openPatientVitalModal } = actions;
import { changeRange as changeRangeWrapper } from '../actions/VitalListWrapperActions'
import { severity }from '../constants/map';

//components
import { IHRadio, IHPanel, VSMDateRange } from 'ihcomponent'
import HRChartComponent from '../components/HRChartComponent'

//queries
import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList';
import { formatVariables } from '../util/tasks'

const style = {
  panel: {
    margin : '20px',
    borderRadius: '4px'
  }
}
class HRWrapper extends React.Component {
  static displayName = 'patient/vitalList/containers/HRContainer'

  componentWillReceiveProps(nextProps) {
    if ((nextProps.enrolledProgramId !== this.props.enrolledProgramId) ||
      (nextProps.HR !== this.props.HR) &&
      this.props.HR &&
      this.props.enrolledProgramId) {
      this.props.onTableChange({pageSize: 5, current: 1}, {}, { columnKey: 'date', order: null })
      this.props.changeView('List')
    }
  }
  componentWillUnmount() {
    this.props.onTableChange({pageSize: 5, current: 1}, {}, { columnKey: 'date', order: null })
    this.props.changeView('List')
  }
  _generateData(data, range) {
    if (data === void 0) // when loading, data is undefined
      return []
    const start = moment(range[0]).startOf('day').toDate()
    const end = moment(range[1]).endOf('day').toDate()
    return data.data.filter(d => (moment(d.date).toDate() >= start && moment(d.date).toDate() <= end))
  }
  _getThresholds(thresholds) {
    const thresholds_heartRate = find(thresholds, { measure: 'HEART_RATE' }).range
    //assuming that thresholds are required
    return {
      'Heart Rate': [thresholds_heartRate[1], thresholds_heartRate[2]]
    }
  }
  render() {
    // console.log(this.props.range)
    const radioProps = {
      type: 'radioButton',
      value: this.props.view,
      option: [ {name:'List', value:'List'}, {name:'Chart', value:'Chart'}],
      onChange: (e) => {this.props.changeView(e.target.value)},
      className: 'vitals-radio-btn'
    }
    const hrData = this._generateData(this.props.vitalList, this.props.range)
    const listProps = {
      ...this.props,
      data: hrData,
      type: 'HR',
      title: 'Heart Rate'
    }
    const p = {
      header : {
        title: 'Heart Rate',
        right: (
          <div>
            <div style={{display: 'inline-block', margin: '0 20px'}}>
              <IHRadio {...i18nRadioButton(radioProps, 'vitalsPanelAndButtons')}/>
            </div>
            <div style={{display: 'inline-block'}}>
              <VSMDateRange value={this.props.range}
                            onChange={(v) => {
                              this.props.changeRange([moment(v[0]), moment(v[1])])
                              this.props.changeRangeWrapper(null)
                            }
                            }/>
            </div>
          </div>
        )
      },
      body: (this.props.view === 'List' || hrData.length === 0 || this.props.loading)?
        (<VitalList {...listProps} />) :
        (<HRChartComponent data={hrData}
                           range={this.props.range}
                           thresholds_heartRate={this._getThresholds(this.props.HR_thresholds)} />)
    }
    return (
      <div style={style.panel} className="vital-list-wrapper">
        <IHPanel {...i18nPanel(p, 'vitalsPanelAndButtons')} />
      </div>
    )
  }
}


const withData = graphql( resultList, {
  options: (ownProps) => {
    let variables = formatVariables(ownProps, 'HR', severity);
    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ownProps, data}) => {
    const { loading, refetch, resultList } = data
    return {
      vitalList: resultList,
      loading,
      refetch
    }
  }
})

const HRWrapperWithData = withData(HRWrapper)

const mapState = (state, ownProps) => {
  const { HR: { sorter, pagination, view, range } } = state.VitalListForProvider || {}
  // const { loading, refetch, programList } = ownProps.data || {}
  return {
    sorter,
    pagination,
    view,
    range
  }
}

HRWrapper.propTypes = {
  sorter: PropTypes.object,
  pagination: PropTypes.object,
  currentPage: PropTypes.number,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  viewHR: PropTypes.string,
  vitalList: PropTypes.object,
  range: PropTypes.any,
  changeRange: PropTypes.func,
  changeRangeWrapepr: PropTypes.func,
  changeView: PropTypes.func,
}

export default connect(
  mapState, { changeView, changeRange, changeRangeWrapper, onTableChange, openPatientVitalModal }
)(HRWrapperWithData)
