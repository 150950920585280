import {
  React, IHLoading, IHButton, BaseComponent, message, _
} from 'ihcomponent';
import { graphql } from 'react-apollo';
import I18N from 'modulesAll/I18N';
import { lightEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList';
import MemberDetail from './MemberDetail';
import Tab from './Tab';
import Dropdown from './Dropdown';
import EnrolledProgramInfo from '../../enrolledProgramInfo/containers/main'
import { modalAction } from 'modulesAll/common/actions'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import actions from 'modulesAll/patient/deviceTab/actions'
import UserClass from 'graphqlModule/class/User'
import { compose, withProps } from 'recompose';
import * as R from 'ramda';
import userGQL from '../../../graphql/user';
import ChatWindow from 'modulesAll/chat/containers/ChatContainer';
import CallWindow from 'modulesAll/call/containers/callContainer';
// import { terminateCCP } from 'modulesAll/call/helper/helpers';
import { createContainer, GQLHelper, getRouterParam, RequestCache, goPath } from 'libModule/utils';
import { scrollToBottom } from 'libModule/helpers/scrollTo';
import { setAsyncRouteLeaveHook } from 'libModule/utils';
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import dischargeEnrolledProgram from 'modulesAll/graphql/dischargeEnrolledProgram';
import resumeEnrolledProgram from 'modulesAll/graphql/resumeEnrolledProgram';
import { ND } from 'ihcomponent';
import { openModal, closeModal, openErrorModal } from 'layoutModule/actions/MainModal';
import { setResuming, setExtendStep, resetExtendValueType } from 'patientModule/enrolledProgramInfo/actions/EnrolledProgramInfoActions';
import { createAuditLog } from 'libModule/utils/auditLog'
import { setTab } from 'patientModule/enrolledProgramInfo/actions/ProgramInfoTab';
import TimerContainer from 'modulesAll/timer/containers/TimerContainer';
import PatientAppointmentContainerWrapper from 'modulesAll/visit/containers/PatientAppointmentContainerWrapper';
import getUserChatInfo from "modulesAll/graphql/getUserChatInfo";
import Client from 'libModule/gqlClient';
import { routerWillLeaveHelperWithOneButton } from '../../enrolledProgramInfo/helper/helpers';
import $ from 'jquery';
import get from "lodash/get";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const debug = false;
const tabMap = {
  programDetails: 'CARE_PLAN',
  providerNotes: 'PROVIDER_NOTE',
  vitals: 'VITALS',
  foodlog: 'FOOD_LOG',
  LogBook: 'LOG_BOOK',
  labresults: 'LAB_RESULTS',
  medicine: 'MEDICATION'
}
const wrapResumeGQLData = graphql(resumeEnrolledProgram, {
  name: 'resumeEnrolledProgram',
});

const wrapDischargeGQLData = graphql(dischargeEnrolledProgram, {
  name: 'dischargeEnrolledProgram',
});

const wrapGQLData1 = graphql(enrolledProgramGQL, {
  options: ownProps => ({
    variables: {
      id: ownProps.enrolledProgramId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
  props: ({ ownProps, data }) => { return { data, refetch: data.refetch } },
});

const wrapGQLData2 = graphql(lightEnrolledProgramList, {
  options: ownProps => ({
    variables: {
      page: 1,
      count: 999, //need to get all of the enrolled programs, there's no pagination here
      filters: {
        memberId: ownProps.patientId,
      },
      sort: {
        field: 'NAME',
        direction: 'ASC',
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
  props: ({ ownProps, data }) => { return { data, lightEnrolledProgramListRefetch: data.refetch } },
});

const displayName = 'patient/main/containers/main'
class ProviderViewPatientContainer extends BaseComponent {
  static displayName = displayName;
  constructor() {
    super();
    this.state = {
      showStickyHeader: false,
      tabSelected: 'programDetails',
      showChat: null,
      channelName: '',
      callPhoneNumber: '',
      callUserName: '',
      callStatus: 7,    //7 is close
      callDate: null,
      callDuration: 0
    }
    this.ref = React.createRef();
  }

  componentDidUpdate() {
    //a workaround that do no need major changes to existing file, so that console does not throw error for breadcumb
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNameFromProgramme = _.get(this.currentProgram, 'user.profile.fullName')
    const patientNameFromRequestCache = _.get(currentPatient, 'profile.fullName')

    if ((this.currentProgram && this.currentProgram.status === 'DISCHARGED') || this.props.enrolledProgramId === 'past') {
      this.props.setBreadCrumb(`Patients / ${patientNameFromProgramme || patientNameFromRequestCache} / Past ${I18N.get('keywords.Programs')} / ${this.currentProgram ? this.currentProgram.name : 'New'}`);
    }
    else {
      this.props.setBreadCrumb(`Patients / ${patientNameFromProgramme || patientNameFromRequestCache} / Current ${I18N.get('keywords.Programs')} / ${this.currentProgram ? this.currentProgram.name : 'New'}`);
    }
  }

  componentWillMount() {
    // setAsyncRouteLeaveHook(this.props.router, this.props.route, this.routerWillLeave.bind(this))
  }

  routerWillLeave() {
    return new Promise((resolve, reject) => {
      const { contactStatus } = this.props;
      if (contactStatus == 'onConnected' || contactStatus == 'onConnecting') {
        routerWillLeaveHelperWithOneButton(resolve, resolve)
      } else {
        resolve(true);
        return;
      }
    })
  }

  componentWillUnmount() {
    //terminateCCP()
  }

  renderNoDataHtml = () => {
    return (
      <div className="vsm-main flex_center">
        <div>
          <h4>
            Currently there are no enrolled {I18N.get('keywords.programs')} for this patient
          </h4>
        </div>
      </div>
    )
  }

  renderNoDataHtmlPast = () => {
    return (
      <div className="vsm-main flex_center">
        <div>
          <h4>
            No past {I18N.get('keywords.programs')} currently
            </h4>
        </div>
      </div>
    )
  }

  handleContainerScroll(e) {
    const target = e.target;
    const height = $(target).find("#profile_detail").outerHeight();
    const visitListTop = _.get($(target).find('.patientAppointCollapse').offset(), 'top');

    const { profileHeight } = this.props;
    // if(this.props.profileHeight==0){
    //     this.props.setPatientDivHeight(height);
    // }
    //54 is the height of top menu;
    //87 is the height of collapsed patient profile div;
    //if scroll to height less than top menu height plus collapsed patient profile div,
    //then show the sticky header;
    if (visitListTop < 55) {
      this.setState(((preState) => {
        if (!preState.showStickyHeader) {
          return { showStickyHeader: true }
        }
      }))
      return;
    }
    //if scroll to height to 100px less than original patient profile div
    // hide sticky header;
    if (visitListTop > 55) {
      this.setState((preState) => {
        if (preState.showStickyHeader) {
          return { showStickyHeader: false }
        }
      })
      return;
    }
  }

  updateTabSelected = (tabSelected) => {
    // const PATIENT_FULL_NAME =  _.get(this.props,'patientName');
    const PATIENT_ID = _.get(this.props, 'patientId');
    const TAB_NAME = tabMap[tabSelected];
    this.setState({
      tabSelected
    }, () => {
      Mixpanel.track('clicked', tabMap[tabSelected], 'patient_profile', { PATIENT_ID, TAB_NAME });
    })
  }

  setChatStatus = (showChat) => {
    this.setState({
      showChat,
    })
  }

  getChatInfo = async () => {
    const { patientId } = this.props;
    let { channelName } = this.state;
    if (channelName.length == 0) {
      const res = await Client.query({
        query: getUserChatInfo,
        variables: { userId: patientId },
        fetchPolicy: 'network-policy',
      });
      channelName = _.get(res, 'data.getUserChatInfo.channels[0].name');
    }
    this.setState({
      channelName
    })
    return channelName;
  }

  render() {
    const self = this;
    const { data, enrolledProgramId, viewerId, patientId, patientName, patientGender, patientBirthday, route, doctor, setBreadCrumb, openAlertModal, menuOpened, refetchUser } = this.props;
    const { showStickyHeader, tabSelected, showChat, channelName, callPhoneNumber, callUserName } = this.state;
    const userId = patientId ? R.last(R.split(':', atob(patientId))) : null;
    let { enrolledProgramList, loading, refetch } = data;
    enrolledProgramList = _.get(enrolledProgramList, 'data') || [];

    if (debug) console.log('ProviderViewPatientContainer props', this.props);
    let dischargedEPList = _.filter(enrolledProgramList, (e => e.status == 'DISCHARGED'));

    let memberDetail = <MemberDetail patientId={patientId}
      dischargedEPList={dischargedEPList}
      showStickyHeader={showStickyHeader}
      callPhone={(phoneNumber, username) => this.callPhone(phoneNumber, username)}
      updateCallStatus={(status, duration, date) => this.updateCallStatus(status, duration, date)}
      callDate={this.state.callDate} callDuration={this.state.callDuration}
      refetchUser={refetchUser}
      curPage = {this.props.curPage} handleChangePage = {this.props.handleChangePage} />;
    let body = <IHLoading />;

    if (dischargedEPList.length == 0) {
      dischargedEPList = '';
    }

    const parentWidth = _.get(this, 'ref.current.offsetWidth');
    if (loading) {
      return (
        <div >
          {memberDetail}
          {body}
        </div>
      );
    }
    RequestCache.set('refetch-enrolledProgramList', refetch)

    // enrolledProgramList = _.get(enrolledProgramList, 'data') || [];
    const sortedEnrolledProgramList = _.orderBy(enrolledProgramList, [programme => programme.name.toLowerCase()], ['asc'])
    let showStarted = false;

    const nurseEPList = UserClass.getEnrolledProgramIdList()
    let canViewEPList = sortedEnrolledProgramList;
    // sortedEnrolledProgramList.filter( (ep) => (nurseEPList || []).indexOf(ep.id) !== -1 )

    const startedPrograms = sortedEnrolledProgramList.filter(enrolled => (enrolled.status !== 'DISCHARGED' && enrolled.status !== 'RETURNED'))
    const pastPrograms = sortedEnrolledProgramList.filter(enrolled => (enrolled.status === 'DISCHARGED' || enrolled.status === 'RETURNED'))

    const startedProgramsCanView = canViewEPList.filter(enrolled => (enrolled.status !== 'DISCHARGED' && enrolled.status !== 'RETURNED'))
    const pastProgramsCanView = canViewEPList.filter(enrolled => (enrolled.status === 'DISCHARGED' || enrolled.status === 'RETURNED'))

    //handle discharged program
    let showDischargedPrograms = false;
    let signFromDash = false;

    //TODO please check logic here. make showStarted=false will have some errors.
    if (enrolledProgramId === 'default' || enrolledProgramId === 'current' || enrolledProgramId === 'new' || _.endsWith(enrolledProgramId, '_')) {
      this.currentProgram = startedProgramsCanView[0];
      if (enrolledProgramId === 'new') {
        this.currentProgram = null
      }
      if(_.endsWith(enrolledProgramId, '_')) {
        signFromDash = true;
      }
      showStarted = true;
    } else if (enrolledProgramId === 'past') {
      this.currentProgram = pastProgramsCanView[0];
      showStarted = false;
    } else {
      this.currentProgram = _.find(enrolledProgramList, enrolled => enrolled.id === enrolledProgramId)
      if (_.find(startedPrograms, (p) => p.id === enrolledProgramId)) {
        showStarted = true
      }
      // showStarted = !currentProgram
      //   || currentProgram.status === 'STARTED'
      //   || currentProgram.status === 'SUSPENDED';
    }

    //handle discharged program
    if (this.currentProgram && this.currentProgram.status === 'DISCHARGED') {
      showDischargedPrograms = true;
      canViewEPList = sortedEnrolledProgramList.filter(enrolled => (enrolled.status === 'DISCHARGED'));
      dischargedEPList = '';
    }
    RequestCache.set('enrolledProgramList', enrolledProgramList);

    //console.log('CurrentProgram -> ', currentProgram)
    //let qrcode = null
    const showIncompletedEP = !this.currentProgram || this.currentProgram.status === 'CREATED' || this.currentProgram.status === 'PENDING';
    // if(R.contains(tabSelected, ['summary', 'addresult'])) {
    //   body = null;
    // }

    let noDataFlag = false
    let noDataHtml = null
    if (enrolledProgramId === 'past' && !this.currentProgram) {
      noDataFlag = true
      noDataHtml = this.renderNoDataHtmlPast()
    }
    else if (enrolledProgramId !== 'new' && !this.currentProgram) {
      noDataFlag = true
      noDataHtml = this.renderNoDataHtml()
    }

    const timerInfo = {
      viewerId,
      userId,
      name: patientName,
      gender: patientGender,
      requirementComplete: true,
      path: route.path,
      component: displayName,
    };

    let bodyMain;
    //4/3/2018 edited by Adam to pass currentProgramId to member detail for chatting
    if (this.currentProgram && this.currentProgram.id) {
      memberDetail = <MemberDetail
        patientId={patientId}
        currentProgramId={this.currentProgram.id}
        currentProgram={this.currentProgram}
        currentProgramNames={startedProgramsCanView.map(item => item.name)}
        showStickyHeader={showStickyHeader}
        parentWidth={parentWidth}
        menuOpened={menuOpened}
        showChat={showChat}
        dischargedEPList={dischargedEPList}
        callPhone={(phoneNumber, username) => this.callPhone(phoneNumber, username)}
        updateCallStatus={(status, duration, date) => this.updateCallStatus(status, duration, date)}
        callDate={this.state.callDate} callDuration={this.state.callDuration} refetchUser={refetchUser}
        handleChangePage = {this.props.handleChangePage}
      />;
    }

    const ProgramDropDown = () => (
      <div style={{ marginRight: 29, width: 400 }}>
        <Dropdown
          value={this.currentProgram ? this.currentProgram.id : 'new'}
          epStatus={this.currentProgram ? this.currentProgram.status : '' /** case when enrol new program **/}
          enrolledProgramList={showStarted ? startedPrograms : canViewEPList}
          key="1"
          {...this.props}
          devices={this.currentProgram ? this.currentProgram.devices : []}
        />
        {this.currentProgram && this.currentProgram.status === 'SUSPENDED' && (
          <ND.Alert
            className="vsm-patient-warning"
            message={`Patient Suspended from ${I18N.get('keywords.Program')}`}
            description={`Patient cannot complete any tasks in this ${I18N.get('keywords.program')} during suspension period.`}
            type="warning"
            showIcon
          />
        )}
      </div>
    );

    const enrollButtonClick = () => {
      const _currentPatient = RequestCache.get('nurse/currentPatient')
      const patientNRIC = _currentPatient.identification && _currentPatient.identification.length > 0 ? _currentPatient.identification[0].value : [];
      const patientName = _currentPatient.profile.fullName
      // createAuditLog({action:I18N.get('auditLog.provider.patient.enrollInNewProgram.enroll_in_new_program'), patientNRIC, patientName })
      goPath(`/patients/${patientId}/enrolledprogram/default`);
    };

    if (!this.currentProgram) {
      // program states are passed through the ID field
      const knownAlternateStates = ['new', 'default'];
      const enrolledProgramId = this.props.enrolledProgramId;
      if (!R.contains(enrolledProgramId, knownAlternateStates)) {
        console.warn('Unknown state for this.currentProgram not defined', enrolledProgramId);
      };
    };
    const displayName = this.constructor.displayName;
    const viewingDetails = {
      component: displayName,
      path: window.location.pathname
    }
    const hasStartedProgram = this.currentProgram && this.currentProgram.status == 'STARTED';
    let width = showChat && menuOpened ? '100%' : '100%';
    // if(menuOpened&&!showChat||(menuOpened&&showChat)){
    //     width = '75%';
    // } else{
    //     width = '100%'
    // }

    const patient = {
      user: _.get(this.props, 'user'),
      enrolledPrograms: _.get(this.props, 'data.enrolledProgramList.data')
    };

    return (
      <div className='outtercontainer' onScroll={(e) => this.handleContainerScroll(e)} ref={this.ref} style={{ width: width, display: 'flex' }}>
        <TimerContainer displayName={displayName} category='PATIENT_PROFILE' action='REVIEW'
          patientId={patientId} canRestUUid={true} viewingDetails={viewingDetails} />
        <div className={`leftDiv ${showChat ? 'showChat' : 'closeChat'}`} >
          {memberDetail}

          {/*{*/}
          {/*hasStartedProgram ? <PatientAppointmentContainerWrapper patientId={ patientId } patientName = { patientName } patientBirthday={ patientBirthday }/> :''*/}
          {/*}*/}
          <PatientAppointmentContainerWrapper patientId={patientId} patientName={patientName} patientBirthday={patientBirthday}
                                              doctor={doctor} router={this.props.router} route={this.props.route}
                                              currentProgram={this.currentProgram} patient={patient}
          />
          {
            ((!this.currentProgram || _.get(startedProgramsCanView, 'length') === 1) && !showDischargedPrograms) ? null : <div style={{
              display: 'flex',
              padding: '18px 44px',
              alignItems: 'center',
              background: 'white',
              justifyContent: 'space-between',
            }}>
              <ProgramDropDown />
              {_.get(startedProgramsCanView, 'length') > 0 ? null : <IHButton bsStyle="primary" label={`Enroll in New ${I18N.get('keywords.Program')}`} className="v-btn v1" onClick={enrollButtonClick} />}
            </div>
          }
          {/*<PatientTimeTracker { ...timerInfo } />*/}
          {
            showIncompletedEP
              ? (<Tab key="2" currentProgram={this.currentProgram} />)
              : <EnrolledProgramInfo refetchEnrolledProgramList={refetch} enrolledProgramId={this.currentProgram.id}
                currentProgram={this.currentProgram} location={this.props.location} {...this.props}
                canRestUUid={false}
                route={this.props.route}
                tabSelected={tabSelected}
                updateTabSelected={this.updateTabSelected}
                programInfo={this.currentProgram}
                lightEnrolledProgramListRefetch={this.props.lightEnrolledProgramListRefetch}
                submitEnrolledProgram={this.apiEditEnrolledProgram}
              />
          }
        </div>
        {/*{*/}
        {/*channelName ? <ChatWindow chatWithIndividual={ true } channelName={channelName || ''}*/}
        {/*showProfileStickyHeader={showStickyHeader} showChat={showChat} setChatStatus={ this.setChatStatus }/>*/}
        {/*:''*/}
        {/*}*/}
        {
          _.get(this, 'currentProgram.id') && this.currentProgram.status != 'DISCHARGED' ? <ChatWindow chatWithIndividual={true} channelName={channelName || ''} getChatInfo={this.getChatInfo}
            showProfileStickyHeader={showStickyHeader} showChat={showChat} setChatStatus={this.setChatStatus}
            patientId={patientId}
          /> : ''
        }
        {/* {this.renderCallWindow()} */}
      </div>
    );
  }
  callPhone = (phoneNumber, username) => {
    /**
     * callArgs
     * {
     *      phoneNumber
     *      username
     *      status    0:connecting    1:connected    2:ended     3:incoming    4:accepted    5:destroy    6:acw   7:close
     *      duration   min
     *      date
     *      showChat
     * }
     */
    this.setState({ callPhoneNumber: phoneNumber, callUserName: username, callStatus: 0 });
  }

  updateCallStatus = (status, duration, date) => {
    let owerState = {
      callStatus: status, callDuration: this.state.callDuration, callDate: this.state.callDate
    }
    if (date) {
      owerState.callDate = date;
    }
    if (duration) {
      owerState.callDuration = duration;
    }
    this.setState(owerState);
  }

  /**
   * close Call Box
   */
  closeCallBox = () => {
    this.setState({ callStatus: 7 });
  }

  renderCallWindow() {
    const { showChat, callPhoneNumber, callUserName, callStatus, callDuration, callDate } = this.state;
    const callArgs = {
      showChat: showChat,
      phoneNumber: callPhoneNumber,
      username: callUserName,
      status: callStatus,
      duration: callDuration,
      date: callDate
    }

    return <CallWindow callArgs={callArgs} close={() => { this.closeCallBox() }} />;
  }
};

const mapState = (state) => {
  const params = getRouterParam(state);
  return {
    ...params,
    menuOpened: state.Nav.menuOpened,
    providerNotesModalIsVisible: state.AWSConnect.providerNotesModalIsVisible,
    lastCallPatientIdStatus: state.AWSConnect.lastCallPatientId,
    contactStatus: state.AWSConnect.contact
  };
};

const mapDispatch = (dispatch, ownProps) => {
  return {
    setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
    openAlertModal: (opts) => dispatch(modalAction.alert(opts)),
    setInitDeviceData: (table, value) => dispatch(actions.setInitDeviceData(table, value)),
    openModal: (content, row) => dispatch(openModal(content, row)),
    closeModal: () => dispatch(closeModal()),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setResuming,
  }
};

const enhance = compose(
  wrapGQLData2,
  wrapDischargeGQLData,
  wrapResumeGQLData,
  withProps(() => {
    const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
    const viewerId = viewerInfo ? R.last(R.split(':', atob(viewerInfo.id))) : null;
    return { viewerInfo, viewerId }
  }),
  graphql(userGQL, {
    options: ({ patientId }) => ({
      variables: { id: patientId },
      fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data: { loading, user,refetch } }) => ({
      patientLoading: loading,
      patientName: loading ? '' : R.path(['profile', 'fullName'], user),
      patientGender: loading ? '' : R.path(['profile', 'gender'], user),
      patientBirthday: loading ? '' : R.path(['profile', 'birthday'], user),
      doctor: loading ? '' : R.path(['profile', 'doctor'], user),
      user: loading ? '':  user,
      refetchUser:refetch
    }),
  }),
);

export default createContainer(enhance(ProviderViewPatientContainer), mapState, mapDispatch);
