import React from "react";
import { IHSearchTable } from 'ihcomponent';
import map from '../constants/tableMap';
const  { tableColumnsByPatient } = map;
class TaskAssignmentsByPatientComponent extends React.Component {
    constructor(){
        super();
    }
    getTableProps = ()=> {
        const {taskList,filters,sort} = this.props;
        const noDataElem = (
            <div style={{height: '150px', paddingTop: '2em'}}>
                <p style={{fontSize: '18px'}}>There is no data!</p>
            </div>
        );

        const tableProps = {
            columns:tableColumnsByPatient(filters,sort),
            title:'Task Assignments',
            noDataElem,
            dataSource:taskList,
        }
        return tableProps;
    }

    render(){
        const tableProps = this.getTableProps();
        return <IHSearchTable  { ...tableProps} className="taskAssignmentListByPatientTable" />
    }

}


export default TaskAssignmentsByPatientComponent;

