
import gql from 'graphql-tag';

export  default gql`
query foodLogList($count: Int, $page: Int, $filters: FoodLogListOpts!,$sort:FoodLogListSort) {
  foodLogList(count: $count, page: $page, filters: $filters, sort: $sort) {
    data {
      id
      member {
        id
        profile {
          fullName
          fullNameWithNickname
          ...on MemberProfile {
            language {
              description
            }
            appLanguage {
               description
            }
          }
        }
      }
      mealType
      note
      images{
        link
      }
      uploadedAt
      vitalTypes
      createdAt
      rating {
        star
        category {
          carb
          fat
          fruit
          vegetable
        }
      }
      comments {
        text
        commentedBy {
          profile {
            fullName
          }
        }
        commentedAt
      }
    }
    pageInfo {
      total
      lastPage
    }
  }
}`
