import {Form, Input, Button, Select, Row, Col, Radio, Tooltip, Icon} from 'antd';
import React, { Component } from 'react';
const { Item } = Form;
const { Option } = Select;
import { helpers } from '../../visit/helpers';
import { console } from 'mixpanel-browser/src/utils';
import { langOptions } from '../../../lib/constants';
import { dobValidator, mobileValidator, homePhoneValidator, firstAndLastNameValidator, NRICValidator } from 'libModule/validator';
import { ReactInput, templateParser, templateFormatter, parseDigit } from 'input-format';
import I18N from "../../I18N";
// DOB template
const TEMPLATE = 'xx/xx/xxxx';
const parse = templateParser(TEMPLATE, parseDigit);
const dobParseFormat = templateFormatter(TEMPLATE);

class CreatePatientFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        values.firstName = values.firstName.charAt(0).toUpperCase() + values.firstName.substring(1);
        values.lastName = values.lastName.charAt(0).toUpperCase() + values.lastName.substring(1);
        values.nationality = 'US';
        values.nationtype = 'SP';
        values.country = 'US';

        if (values.DOB) {
          const result = this.handleString(2, 0, '/', values.DOB);
          const result2 = this.handleString(5, 0, '/', result);
          values.DOB = result2;
        }
        this.props.onCreatePatient(values);
      } else {
        console.log(err);
      }
    });
  }

    handleString(idx, rem, str, originString){
        return originString.slice(0, idx) + str + originString.slice(idx + Math.abs(rem));
    }

    handleDOBValidator(rule, value, callback){
        if(value && value.length >= 8){
            value = this.handleString(2, 0, '/', value);
            value = this.handleString(5, 0, '/', value);
        }
        callback(dobValidator(value));
    }

  renderName() {
    const { getFieldDecorator } = this.props.form;

    const getAutoUserName = (firstNameValue, lastNameValue) => {
      let autoUsername = '';
      if (firstNameValue && firstNameValue.trim().length > 0) {
        autoUsername += firstNameValue.trim().replace(/\s/g, '').toLowerCase();
      }
      if (lastNameValue && lastNameValue.trim().length > 0) {
        autoUsername += lastNameValue.trim().replace(/\s/g, '').toLowerCase();
      }
      // autoUsername has no leading/trailing spaces or space in-between
      return autoUsername;
    }

    return (
      <Row gutter={16}>
        <Col span={8}>
          <Item label='First Name'>
            {getFieldDecorator('firstName', {
              rules: [
                { required: true, message: 'First Name is required' }, 
                { validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName')) }
              ]
            })(
              <Input
                style={{ textTransform: 'capitalize' }}
                onChange={e => {
                  let v = e.target.value.trim();
                  const firstNameValue = v; //this.props.form.getFieldValue('firstName');
                  const lastNameValue = this.props.form.getFieldValue('lastName');
                  this.props.form.setFieldsValue({ username: getAutoUserName(firstNameValue, lastNameValue) });
                }}
              />
            )}
          </Item>
        </Col>

        <Col span={8}>
          <Item label='Last Name'>
            {getFieldDecorator('lastName', {
              rules: [
                { required: true, message: 'Last Name is required' }, 
                { validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName')) }
              ]
            })(
              <Input
                style={{ textTransform: 'capitalize' }}
                onChange={e => {
                  let v = e.target.value.trim();
                  const firstNameValue = this.props.form.getFieldValue('firstName');
                  const lastNameValue = v; //this.props.form.getFieldValue('lastName');
                  this.props.form.setFieldsValue({ username: getAutoUserName(firstNameValue, lastNameValue) });
                }}
              />
            )}
          </Item>
        </Col>

        <Col span={8}>
          <Item label='Username'>
            {getFieldDecorator('username', {
              rules: [
                { required: true, message: 'User name is required' },
                { min: 3, message: 'Need to more than 3 characters' }
              ]
            })(<Input />)}
          </Item>
        </Col>
      </Row>
    );
  }

  renderLanguageGenderDOB() {
    const { getFieldDecorator } = this.props.form;
    var formValue = this.props.form.getFieldValue('DOB');
    return (
      <Row gutter={16}>
        <Col span={8}>
          <Item label='DOB'>
            {getFieldDecorator('DOB', {
              rules: [
                { required: true, message: 'DOB is required' },
                {
                  validator: this.handleDOBValidator.bind(this)
                }
              ],
              initialValue: formValue
            })(
              <ReactInput className='ant-input' placeholder='MM/DD/YYYY' onChange={DOB => {}} parse={parse} format={dobParseFormat} />
              // <Input placeholder={"MM/DD/YYYY"} />
            )}
          </Item>
        </Col>

        <Col span={8}>
          <Item label='Spoken Language'>
            {getFieldDecorator('language', { initialValue: 'EL', rules: [{ required: true, message: 'language is required' }] })(
              <Select>
                {_.map(langOptions, ({ value, name }) => (
                  <Option value={value} key={value}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>

        <Col span={8}>
          <Item label='Gender'>
            {getFieldDecorator('gender', { rules: [{ required: true, message: 'gender is required' }] })(
              <Radio.Group buttonStyle='solid'>
                  <Radio.Button value="Male" className="gender_padding">M</Radio.Button>
                  <Radio.Button value="Female" className="gender_padding">F</Radio.Button>
              </Radio.Group>
            )}
          </Item>
        </Col>
      </Row>
    );
  }

  renderDoctorAndMedID() {
    const { getFieldDecorator } = this.props.form;
    const doctorOptions = helpers.filterDoctors();

    return (
      <Row gutter={16}>
        <Col span={8}>
          <Item label='Doctor Name'>
            {getFieldDecorator('doctorId', { rules: [{ required: true, message: 'Doctor is required' }] })(
              <Select filterOption={false}>
                {_.map(doctorOptions, program => {
                  return (
                    <Option value={program.id} key={program.id}>
                      {program.fullName}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Item>
        </Col>

        <Col span={8}>
          <Item label='Med ID'>{getFieldDecorator('identificationValue', { rules: [{ required: true, message: 'Med ID is required' }, { validator: (rule, value, callback) => callback(NRICValidator(value)) }] })(<Input />)}</Item>
        </Col>
      </Row>
    );
  }

  renderPhone() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Row gutter={16}>
        <Col span={8}>
          <Item label='Home Phone'>{getFieldDecorator('homePhone', { rules: [{ required: false }, { validator: (rule, value, callback) => callback(homePhoneValidator(value)) }] })(<Input />)}</Item>
        </Col>

        <Col span={8}>
          <Item label='Mobile Phone'>{getFieldDecorator('mobilePhone', { rules: [{ required: false }, { validator: (rule, value, callback) => callback(mobileValidator(value)) }] })(<Input />)}</Item>
        </Col>

          <Col span={8}>
              <Item label={<div>Otp Login<Tooltip title={I18N.get('OTPLogInInfo')}><Icon type="info-circle" style={{marginLeft: 5}} /></Tooltip></div>} >
                  {getFieldDecorator('canUseForLogin', {initialValue: 'true'})
                  (
                      <Radio.Group >
                          <Radio.Button value='true' className="opt_padding">On</Radio.Button>
                          <Radio.Button value='false' className="opt_padding">Off</Radio.Button>
                      </Radio.Group>
                  )}
              </Item>
          </Col>

      </Row>


    );
  }

  render() {
    return (
      <div className='visit_popup_patient'>
        <Form onSubmit={this.handleSubmit}>
          {this.renderName()}
          {this.renderLanguageGenderDOB()}
          {this.renderDoctorAndMedID()}
          {this.renderPhone()}
          <Row style={{marginTop: 10}}>
            <Col span={3} offset={21}>
              <Item>
                <Button type='primary' htmlType='submit'>
                  Create
                </Button>
              </Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

CreatePatientFormComponent = Form.create()(CreatePatientFormComponent);

export default CreatePatientFormComponent;
