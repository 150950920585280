import React from 'react';
import { Form,Row, Col, Input, Button, Icon, Checkbox } from 'antd';
import taskListFormMap from '../constants/taskListFormMap';
import { API } from '../query/';
import convertor from "@ihealth/convertor/index";
import i18nMap from "../../I18N";
import { convertFeetToInches, convertInchesToFeet, getRemainderAfterConvertingToFeet,convertBG_mmolTomgdl,convertBG_mgdlTommol } from 'libModule/utils/convertUnits';
import { dobValidator, emailValidator2, postCodeValidator, blockValidator, floorValidator, unitValidator,
    firstAndLastNameValidator, NRICValidator, patientWeightRegiterValidator, patientHeightInputValidator,
    patientHeightInchesInputValidator, mobileValidator, homePhoneValidator,yearOfDiagnoseValidator } from 'libModule/validator';
import {helpers} from "../helpers";
import {message} from "../../../../../package/IHComponent";
import Client from 'libModule/gqlClient';
import moment from 'moment';
import editVisit from 'graphqlModule/mutation/editVisit';
import visitList from 'modulesAll/graphql/visitList';
import visitTaskStatus from '../constants/visitTaskStatus';
import {compose} from "react-apollo/index";
import {connect} from "react-redux";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'lib/utils';
import { clone } from 'ramda';
const Item = Form.Item;


const style = {
    colStyle:{
        margin:10,
        marginTop:'-10px',
        display:'flex',
        minWidth:'270px'
    },
    inputStyle:{
        width: 100,
        marginTop:8
    },
    divStyle:{
        display:'flex',
        flex:1,
        height:44
    },
    dotButton:{
        display:'flex',
        padding:0,
        height:30,
        width:30,
    },
    dotButtonContent:{
        display:'inline-block',
        transform:'rotate(90deg)',
        height:'inherit',
        width:'inherit'
    }
}
const defaultStartDay = moment().startOf('day');
const defaultEnDay = moment().endOf('day');

const convertMap = {
    weight:(v)=>v ? Math.round(convertor.HS(v, 'kg', 'lbs')).toFixed(1):'',
    height:(v)=>v,
    waistHipRatio:(v)=>v,
    bgMeasurement:(v)=>_.get(v,'blood_glucose.value') ? convertBG_mmolTomgdl(_.get(v,'blood_glucose.value','')) : '',
    bpMeasurement:(v)=>v,
    feet_value:(height)=> height ? convertInchesToFeet(convertor.height(height.value, 'cm', 'inch', 0)) : '',
    inch_value:(height)=> height ? getRemainderAfterConvertingToFeet(convertor.height(height.value, 'cm', 'inch', 0)) : '',
    systolic_blood_pressure_value:(v)=> _.get(v,'systolic_blood_pressure.value',''),
    diastolic_blood_pressure_value:(v)=> _.get(v,'diastolic_blood_pressure.value',''),
    heart_rate:(v)=>_.get(v,'heart_rate.value',''),
    waist_value:(v)=>'',
    hip_value:(v)=>''
}

const taskListFiledMap = {
    oneInput:(data,t,getFieldDecorator,key,props,localThis,className,alreadyCheckedIn)=> <div style={ style.divStyle } key={t} className={className} >
                                                                <Item>
                                                                    <label> { localThis.title }</label>
                                                                    {
                                                                        getFieldDecorator(`${t}_value`,{ rules:localThis.rules,initialValue:convertMap[localThis.convertMapKey||localThis.inputKey](_.get(data,`${localThis.inputKey}.value`,_.get(data,`${localThis.inputKey}`,''))) })(
                                                                            <Input key={`${key}_value`}
                                                                                   className='taskListInput'
                                                                                   style={style.inputStyle}
                                                                                   disabled={ !alreadyCheckedIn }
                                                                            />
                                                                        )
                                                                    }
                                                                </Item>
                                                             </div>,
    oneCheckBox:(data,t,getFieldDecorator,key,props,localThis,alreadyCheckedIn)=> <div style={ style.divStyle, {whiteSpace:'nowrap'} } key={t} className='taskListCol'>
                                                                    <Item> {
                                                                        getFieldDecorator(`${t}_value`,{ rules:localThis.rules, initialValue: (_.get(data, `${t}`) == 'COMPLETED') ,valuePropName: 'checked' })(
                                                                            <Checkbox key={`${key}_value`} disabled={ !alreadyCheckedIn } >{localThis.title}</Checkbox>)}
                                                                    </Item>
                                                                </div>,
    twoInput:(data,t,getFieldDecorator,key,props,localThis,className,alreadyCheckedIn)=><div style={ style.divStyle } key={t} className={className}>
                                                                        <div style={_.extend(style.divStyle,{width:'100%'})}>
                                                                            <Item>
                                                                                <label> { localThis.title }</label>
                                                                                {
                                                                                    getFieldDecorator(localThis.input1Key,{
                                                                                        force:true,
                                                                                        rules:localThis.input1Rules(props),
                                                                                        initialValue: convertMap[localThis.input1Key](_.get(data,`${t}`))
                                                                                    })(
                                                                                        <Input key={localThis.input1Key} style={{ width:50,marginTop:8 }}
                                                                                               className='taskListInput'
                                                                                               disabled={ !alreadyCheckedIn }

                                                                                        />
                                                                                    )}
                                                                            </Item>
                                                                            <span style={{ fontSize:18,marginTop:8 }} className='separator'>{localThis.separator}</span>
                                                                            <Item style={{flex:1}}> {
                                                                                getFieldDecorator(localThis.input2Key,{
                                                                                    force:true,
                                                                                    rules:localThis.input2Rules(props),
                                                                                    initialValue: convertMap[localThis.input2Key](_.get(data,`${t}`))
                                                                                })(
                                                                                    <Input key={localThis.input2Key} style={{ width:50,marginTop:8 }}
                                                                                           className='taskListInput'
                                                                                           disabled={ !alreadyCheckedIn }

                                                                                    />
                                                                                )}
                                                                            </Item>
                                                                        </div>
                                                                     </div>,
    renderData:(content)=>content
    }
const obj = {
    'height':{
        content:taskListFiledMap.twoInput,
        separator:`'`,
        title:'Height',
        unit:'"',
        input1Key:'feet_value',
        input2Key:'inch_value',
        input1Rules:()=>[{
            validator: (rule, value, callback) => {
                if(value==''||value==undefined){
                    callback()
                    return;
                }
                if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                    callback('Input Invalid');
                    return;
                }
                if(value < 0 || value > 9 ) {
                    return callback('should between 0 and 9 feet')
                }
                callback();
            }
        }],
        input2Rules:()=>[{
            validator: (rule, value, callback) => {
                if(value==''||value==undefined){
                    callback()
                    return;
                }
                if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                    callback('Input Invalid');
                    return;
                }
                if(value < 0 || value > 11 ) {
                    return callback('should between 0 and 10 inch')
                }
                callback();
            }
        }],
    },
    'weight':{
        content:taskListFiledMap.oneInput,
        title:'Weight',
        unit:'lbs',
        rules : [ {
            validator: (rule, value, callback) => {
                if(value==''||value==undefined){
                    callback()
                    return;
                }
                if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                    callback('Input Invalid');
                    return;
                }
                if(value < 25 || value > 999 ) {
                    return callback('should between 25 and 999')
                }
                callback()
            }
        }],
        inputKey:'weight'
    },
    'waistHipRatio':{
        content:taskListFiledMap.twoInput,
        title:'Waist/Hip',
        unit:(props)=>{
            const { data } = props;
            const { getFieldValue } = props.form;
            const waist = getFieldValue('waist_value');
            const hip = getFieldValue('hip_value');

            if(waist&&hip){
                return  ' = '+(waist/hip).toFixed(2);
            }

            if(_.get(data,'details.waistHipRatio')){
                return ' = '+ _.get(data,'details.waistHipRatio');
            }

        },
        separator:`/`,
        input1Key:'waist_value',
        input2Key:'hip_value',
        input1Rules :(props)=> [ {
            validator: (rule, value, callback) => {
                if(value==''){
                    callback();
                    return;
                }
                if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                    callback('Input Invalid');
                    return;
                }
                callback()
            }
        } ],
        input2Rules:(props)=>[ {
            validator:(rule, value, callback) => {
                if(value==''){
                    callback();
                    return;
                }
                if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                    callback('Input Invalid');
                    return;
                }
                callback()
            }
        } ]
    },
    'bgMeasurement':{
        content:taskListFiledMap.oneInput,
        title:'Blood Glucose',
        unit:'mg/dL',
        inputKey:'bgMeasurement',
        rules:[
            {
                validator:(rule,value,callback)=>{
                    if(value==''){
                        callback()
                        return;
                    }
                    if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                        callback('Input Invalid');
                        return;
                    }

                    callback()
                }}]
    },
    'bpMeasurement':{
        content:taskListFiledMap.twoInput,
        title:'Blood Pressure',
        separator:`/`,
        unit:`mmHg`,
        input1Key:'systolic_blood_pressure_value',
        input2Key:'diastolic_blood_pressure_value',
        input1Rules:(props)=>[ {
            validator:(rule, value, callback) => {
                if(value==''||value==undefined){
                    callback();
                    return;
                }

                if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                    callback('Input Invalid');
                    return;
                }
                if(Number(value)>750||Number(value)<1){
                    callback('Input needs in range 1-750');
                    return;
                }
                callback()
            }
        } ],
        input2Rules:(props)=>[ {
            validator:(rule, value, callback) => {
                if(value==''||value==undefined){
                    callback();
                    return;
                }

                if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                    callback('Input Invalid');
                    return;
                }
                if(Number(value)>750||Number(value)<1){
                    callback('Input needs in range 1-750');
                    return;
                }
                callback()
            }
        } ],
    },
    'heart_rate':{
        content:taskListFiledMap.oneInput,
        title:'Heart Beat',
        inputKey:'bpMeasurement',
        convertMapKey:'heart_rate',
        unit:'b/min',
        rules:[
            {
            validator:(rule,value,callback)=>{
                if(value==''){
                    callback()
                    return;
                }
                if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                    callback('Input Invalid');
                    return;
                }
                if(Number(value)>300||Number(value)<1){
                    callback('Input needs in range 1-300')
                    return;
                }
                    callback()
            }}]
    },
    'eyeExam':{
        content:(data,props)=>{
            const resArray = _.sortBy(_.get(data,'labTests',[]).filter(lt => lt.templateId === 'eye_exam'),(r)=>-r.dateCollected);
            const alreadyCheckedIn = !!_.get(data,'checkinAt',false);

            if(resArray.length!=0){
                let lastRes =  _.first(resArray);
                let dateCollected = lastRes.dateCollected;
                return <div className='taskListCol' style={{ minHeight:44,display:'flex',flexDirection:'row',
                    alignItems:'center',justifyContent:'space-between'}}>
                    Eye Exam:
                    <span style={{float:'right'}}>{moment(dateCollected).format('MM/DD/YYYY')}</span>
                </div>
            }
            return taskListFiledMap.oneCheckBox(data.details,'eyeExam',props.form.getFieldDecorator,'eyeExam',props,{
                title:`Eye Exam`,
                rules:[]
            },alreadyCheckedIn)
        },
        render:taskListFiledMap.renderData,
        viewOnly:true,
    },
    'footExam':{
        content:(data,props)=>{
            const resArray = _.sortBy(_.get(data,'labTests',[]).filter(lt => lt.templateId === 'foot_exam'),(r)=>-r.dateCollected);
            const alreadyCheckedIn = !!_.get(data,'checkinAt',false);

            if(resArray.length!=0){
                let lastRes =  _.first(resArray);
                let dateCollected = lastRes.dateCollected;
                return <div className='taskListCol' style={{ minHeight:44,display:'flex',flexDirection:'row',
                    alignItems:'center',justifyContent:'space-between'}}>
                    Foot Exam:
                    <span style={{float:'right'}}>{moment(dateCollected).format('MM/DD/YYYY')}</span>
                </div>
            }
            return taskListFiledMap.oneCheckBox(data.details,'footExam',props.form.getFieldDecorator,'footExam',props,{
                title:`Foot Exam`,
                rules:[]
            },alreadyCheckedIn)
        },
        render:taskListFiledMap.renderData,
        viewOnly:true,
    },
    "urineTest":{
        content:(data,props)=>{
            const resArray = _.sortBy(_.get(data,'labTests',[]).filter(lt => lt.templateId === 'urine_test'),(r)=>-r.dateCollected);
            const alreadyCheckedIn = !!_.get(data,'checkinAt',false);

            if(resArray.length!=0){
                let lastRes =  _.first(resArray);
                let dateCollected = lastRes.dateCollected;
                return <div className='taskListCol' style={{ minHeight:44,display:'flex',flexDirection:'row',
                    alignItems:'center',justifyContent:'space-between'}}>
                    Urine Test:
                    <span style={{float:'right'}}>{moment(dateCollected).format('MM/DD/YYYY')}</span>
                </div>
            }
            return taskListFiledMap.oneCheckBox(data.details,'urineTest',props.form.getFieldDecorator,'urineTest',props,{
                title:`Urine Test`,
                rules:[]
            },alreadyCheckedIn)
        },
        render:taskListFiledMap.renderData,
        viewOnly:true,
    },
    'a1cTest':{
        content:(data,props)=>{
            const a1cs = _.get(data,'labTests',[]).filter(lt => lt.templateId === 'hemoa1c');
            if(a1cs.length==0){
                return <div className='taskListCol' style={{ minHeight:44,display:'flex',flexDirection:'row',
                    alignItems:'center'}}>
                        Hba1C<span style={{marginLeft:'auto',marginRight:'auto'}}>--</span>
                       </div>;
            }
            const lastA1cInRes = _.last(a1cs);
            const { dateCollected,results } = lastA1cInRes;
            const lastA1C = _.first(_.filter(results,(test)=>test.name=="Hemoglobin A1c"));
            const { value,unit } = lastA1C;
            return <div className='taskListCol' style={{ minHeight:44,display:'flex',flexDirection:'row',
                                                         alignItems:'start',justifyContent:'space-between'}}>
                     Hba1C
                     <span style={{float:'right'}}>{value} {unit} - {moment(dateCollected).format('MM/DD/YYYY')}</span>
                   </div>
        },
        render:taskListFiledMap.renderData,
        viewOnly:true,
    },
    'mpTest':{
        render:taskListFiledMap.renderData,
        viewOnly:true,
        content:(data,props)=>{
            const cmps = _.sortBy(_.get(data,'labTests',[]).filter(lt => lt.templateId === 'metabolic_comprehensive'),(r)=>-r.dateCollected);
            const bmps = _.sortBy(_.get(data,'labTests',[]).filter(lt => lt.templateId === 'metabolic_basic'),(r)=>-r.dateCollected);
            const alreadyCheckedIn = !!_.get(data,'checkinAt',false);

            if(cmps.length||bmps.length){
                let lastRes = { };
                let type = '';
                if(cmps.length!=0) {
                    lastRes = _.first(cmps);
                    type = 'CMP';
                }

                if(bmps.length!=0){
                    const lastBmp = _.first(bmps);
                    if(_.isEmpty(lastRes)&&type.length==0){
                        lastRes = lastBmp;
                        type = 'BMP';
                    }
                    else{

                        moment(lastRes.dateCollected).isBefore(moment(lastBmp.dateCollected)) ? ()=>{ lastRes = lastBmp; type='BMP';  }  : lastBmp;
                    }
                }

                let dateCollected = lastRes.dateCollected;

                return <div className='taskListCol' style={{ minHeight:44,display:'flex',flexDirection:'row',
                    alignItems:'center',justifyContent:'space-between'}}>
                    Blood Labs({type}):
                    <span style={{float:'right'}}>{moment(dateCollected).format('MM/DD/YYYY')}</span>
                </div>
            }

            return taskListFiledMap.oneCheckBox(data.details,'mpTest',props.form.getFieldDecorator,'mpTest',props,{
                title:`Blood Labs`,
                rules:[]
            },alreadyCheckedIn)
        }
    },
    'addToContactsStatus':{
        render: taskListFiledMap.renderData,
        viewOnly: true,
        content: (data, props) => {
            const alreadyCheckedIn = !!_.get(data,'checkinAt',false);

            return taskListFiledMap.oneCheckBox(data.details, 'addToContactsStatus', props.form.getFieldDecorator, 'addToContactsStatus', props, {
                title: `Add Care Team Number into Phone Book`,
                rules: []
            }, true)
        }
    },
    'autoUpdateStatus':{
        render: taskListFiledMap.renderData,
        viewOnly: true,
        content: (data, props) => {
            return taskListFiledMap.oneCheckBox(data.details, 'autoUpdateStatus', props.form.getFieldDecorator, 'autoUpdateStatus', props, {
                title: `Set up app auto update`,
                rules: []
            }, true)    
        }
    },
    'appIsUptoDate':{
        render: taskListFiledMap.renderData,
        viewOnly: true,
        content: (data, props) => {
            return taskListFiledMap.oneCheckBox(data.details, 'appIsUptoDate', props.form.getFieldDecorator, 'appIsUptoDate', props, {
                title: `App is up to date`,
                rules: []
            }, true)
        }
    }
}

const taskListKeyMap = function(data, t, getFieldDecorator, key, props, localThis, alreadyCheckedIn){
    let className = 'taskListCol '+ t;
    if (localThis.state[`${t}Error`]) {
        className += ' has-error';
    }
    return obj[t].content(data, t, getFieldDecorator, key, props, obj[t], className, alreadyCheckedIn);
}


let TaskListFormComponent = class extends React.Component {
    static displayName = 'TaskListFormComponent';

    constructor(){
        super();
        this.state = {
            errors:[],
            bpMeasurementError:false,
            waistHipRatioError:false
        }
    }

    validateValuesBeforeSubmit = (values)=>{
        const { diastolic_blood_pressure_value,systolic_blood_pressure_value,hip_value,waist_value } = values;
        const errors = [];
        if((diastolic_blood_pressure_value!=''&&systolic_blood_pressure_value=='')||
            (diastolic_blood_pressure_value==''&&systolic_blood_pressure_value!='')){
            errors.push('Both values for BP');
            this.setState({
                bpMeasurementError:true
            })
        }
        if((hip_value==''&&waist_value!='')||
            (waist_value==''&&hip_value!='')){
            errors.push('Both values for ratio');
            this.setState({
                waistHipRatioError:true
            })
        }
        return errors;
    }
    handleOnSubmit = (e) =>{
        e.preventDefault();
        const variables = this.getMutateVariables();
        const refetchVar = {};
        this.props.form.validateFields((error, values) => {
           const errors = this.validateValuesBeforeSubmit(values);
           if (errors.length != 0) {
              this.setState({
                errors:errors
              })
              return;
           } else {
              this.setState({
                errors:[],
                bpMeasurementError:false,
                waistHipRatioError:false
              })
           }
            if(!error) {
                Client.mutate({
                    mutation: editVisit,
                    notifyOnNetworkStatusChange: true,
                    variables: variables,
                    refetchQueries:[{
                        query: visitList,
                        variables: helpers.setFromAndToDate(refetchVar, defaultStartDay, defaultEnDay),
                        fetchPolicy:'network-only'
                    }]
                }).then(()=>{
                    this.props.setIsSaved(true);
                    message.success('Patient Appointment Saved.');
                }).catch((error)=>{
                    this.props.openErrorModal(GQLHelper.formatError(error));
                })
            }
        })
    }

    getMutateVariables = ( ) =>{
        const { tasks } = this.props.data;
        const { getFieldsValue } = this.props.form;
        const id = _.get(this.props,'data.id');
        let variables = getFieldsValue();
        const keys = Object.keys(variables);
        let parsedVariable = {};
        const a1cs = _.get(this,'props.data.labTests',[]).filter(lt => lt.templateId === 'hemoa1c');
        const a1cRes = a1cs.length > 0 ? _.last(a1cs) : { };
        const a1cValue = _.get(a1cRes,'results[0].value');
        _.forEach(tasks,(t)=>{
            if(visitTaskStatus[t.key]) {
                parsedVariable[t.key] = t.value;
            }
        });

        _.forEach(keys,(k)=> {
            const prefixKey = k.split('_value')[0];
                if ((prefixKey != k)&&variables[k]!=undefined) {
                    parsedVariable[prefixKey] = variables[k];
                    if(variables[k]===true){
                        parsedVariable[prefixKey]='COMPLETED';
                    }

                    if(variables[k]===false){
                        parsedVariable[prefixKey]='PENDING';
                    }
                }
            }
        );

        parsedVariable.id = id;
        const{ weight,systolic_blood_pressure,diastolic_blood_pressure,bgMeasurement,feet,inch,heart_rate,waist,hip } = parsedVariable;

        if ( feet || inch) {
            parsedVariable.height = {
                value: convertor.height(Number(convertFeetToInches(feet)) + Number(inch), 'inch', 'cm'),
                unit: i18nMap.get('measure.units_height_cm')
            }
        }

        if(weight!=undefined&&weight!=''){
            parsedVariable.weight = {
                value:convertor.HS(weight, 'lbs', 'kg'),
                unit:'kg'
            }
        }else{
            parsedVariable.weight = null;
        }

        if(bgMeasurement!=undefined&&bgMeasurement!=''){
            parsedVariable.bgMeasurement = {
                blood_glucose:convertBG_mgdlTommol(bgMeasurement)
            }
        }else{
            parsedVariable.bgMeasurement = null;
        }

        if(waist&&hip) {
            parsedVariable.waistHipRatio = Number(waist / hip).toFixed(2);
        }

        if(systolic_blood_pressure!=undefined&&diastolic_blood_pressure!=undefined&&systolic_blood_pressure!=''&&diastolic_blood_pressure!=''){
            parsedVariable.bpMeasurement = {
                systolic_blood_pressure:{
                    value:systolic_blood_pressure,
                    unit:'mmHg'
                },
                diastolic_blood_pressure:{
                    value:diastolic_blood_pressure,
                    unit:'mmHg'
                },
                heart_rate: heart_rate!='' ? {
                    value:heart_rate,
                    unit:"beats/min"
                }:null

            }
        }
        else if(heart_rate!=undefined&&heart_rate!=''){
            parsedVariable.bpMeasurement = {
                heart_rate:{
                    value:heart_rate,
                    unit:"beats/min"
                }
            }
        }

        if((systolic_blood_pressure==undefined||systolic_blood_pressure=='')
          &&(diastolic_blood_pressure==undefined||diastolic_blood_pressure=='')
          &&(heart_rate==undefined||heart_rate=='')) {
            parsedVariable.bpMeasurement = null;
        }

        if(a1cValue){
            parsedVariable.a1cTest = a1cValue;
        }

        return parsedVariable;
    }

    createTaskListMapBasedOnTasks = (tasks) => {
      let keys = {};
      _.forEach(tasks, (t) => keys[`${t.key}`] = t.key);
      let cloneMap =  _.cloneDeep(taskListFormMap);
      for(let arrayIndex in cloneMap){
        let array = cloneMap[arrayIndex];
        _.remove(array, (i, v) => !keys[i]);
        cloneMap[arrayIndex] = array;

        if(array.indexOf('bpMeasurement') != -1){
          const indexOfBP = array.indexOf('bpMeasurement');
          array.splice(indexOfBP + 1, 0, 'heart_rate');
        }
      }

      return cloneMap;
    }

    render(){
        const { getFieldDecorator } = this.props.form;
        const { data } = this.props;
        const alreadyCheckedIn = !!_.get(data, 'checkinAt', false);
        const tasks   = _.get(data, 'tasks');
        let taskList = this.createTaskListMapBasedOnTasks(tasks);
        console.log('Prev--', taskList);
        taskList[taskList.length - 1] = [...taskList[taskList.length - 1], 'addToContactsStatus', 'appIsUptoDate'];
        taskList = [...taskList, ['autoUpdateStatus']];
        {/* .concat([{type: 'addToContactsStatus'},{type: 'autoUpdateStatus'}, {type: 'appIsUptoDate'}]); */}
        console.log('New--', taskList);
        const { errors } = this.state;

        return <Form onSubmit={ this.handleOnSubmit }
                     layout='horizontal' style={{ display:'flex', flexDirection:'column' }}
                     className='TaskListForm'>
                <div>
                    <Row style={{ display:'flex' }}>
                        <Col style={{ marginRight:10 }}><label>Description:</label></Col>
                        <Col style={{ maxWidth: 800,marginBottom: 20}}>{ data.reason }</Col>
                    </Row>
                </div>
                <div style={{display:'flex',flexDirection:'row'}}>
                <Col style={{ marginRight:20, whitespace:'nowrap'}}>
                    <label style={{whiteSpace:'nowrap'}}>Task list:</label>
                </Col>
                 {
                     _.map(taskList,(i,index)=>{
                     if(i.length!=0)
                        return <div style={style.colStyle} key={index}>
                                    <Col key={index} style={{ minWidth:'80%'}}>
                                        {
                                            _.map(i,(t,index2)=>{
                                                if(obj[t].viewOnly){
                                                    console.log(t);
                                                    return obj[t].render(obj[t].content(data,this.props,));
                                                }
                                                return taskListKeyMap(data.details,t,getFieldDecorator,`${index}_${index2}`,this.props,this,alreadyCheckedIn);
                                            })
                                        }
                                   </Col>
                                   <Col style={{display:'flex',flexDirection:'column'}} key={ taskList.length+index }>
                                       { _.map(i,(t)=>
                                           <div style={{height:44}} key={t}>
                                              <span key={t} style={{ height:35,display:'flex',
                                                             flexDirection:'column',justifyContent:'center',
                                                             paddingLeft:5,paddingTop:5,color:'#aaaaaa'
                                                           }}
                                                    className='unit'
                                              >{ typeof obj[t].unit=='function' ? obj[t].unit(this.props) : obj[t].unit }</span>
                                           </div>)
                                       }
                                   </Col>
                               </div>
                    })
                 }
                 <Col style={{display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                     <Button type='primary' htmlType='submit' style={{backgroundColor: '#4278c3', borderColor: '#4278c3'}}>Save</Button>
                 </Col>
                </div>
                { errors.length>0 ?
                    <div style={{display:'flex',flexDirection:'column'}} className='has-error'>
                        {_.map(errors,(e)=><span className='ant-form-explain'>{e}</span>)}
                    </div>:''
                }
                </Form>
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error))
    }
}
TaskListFormComponent = Form.create({ name: 'TaskListForm',onFieldsChange:(props)=>props.setIsSaved(false) })(TaskListFormComponent);

export default compose(connect(null,mapToDispatch))(TaskListFormComponent);

