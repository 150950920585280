import {
    React,IHSearchTable,IHButton,IHLoading
} from 'ihcomponent';
import { connect } from 'react-redux'

import { Checkbox } from 'antd';
import createTableAction from 'libModule/table/TableActions';
const TimeSpentListActions = createTableAction('TimeSpent_List');

class TimeSpentListComponent extends React.Component{
    renderTable(){
        const { data,page,pageInfo,loading } = this.props;

        const columns = [
                {
                    title: 'Month/Year',
                    dataIndex: 'monthOfYear',
                    render:(value)=>("0" + value%100).slice(-2)+'/'+Math.round(value/100)
                },
                // {
                //     title:'CPT Code',
                //     dataIndex:null,
                //     render:()=>'109367'
                // },

                {
                    title:'Total Time',
                    dataIndex:'totalTime',
                    render:(value)=>(
                        Math.round(value/60) + (Math.round(value/60) > 1 ? ` mins` : ` min`)
                    )
                },
                // {
                //     title:'Billed',
                //     dataIndex:null,
                //     render:()=><Checkbox/>
                // }
            ]
        const tableProps = {
            columns:  columns,
            rowKey: (key,index)=>index,
            pagination: {
                current: _.get(page, 'current', 1),
                pageSize: 10,
                total: _.get(pageInfo, 'total', 0),
            },
            onChange:(a,b)=>this.props.onTableChange(a,b) ,
            loading: { spinning:loading,indicator: <IHLoading/> },
            dataSource: _.isEmpty(data) ? []:data,
            className:'timeSpentTable'
            // title: `Medication `,
            // optionsBox: true,
            // actions: <IHButton {...b}/>
        };
        const noDataElem = (
            <div style={{  paddingTop: '2em',paddingBottom: '2em',display:'flex',flexDirection:'column',alignItems:'center',fontFamily:'HelveticaNeue',color:'#c5c5c5' }}>
                <div style={{ fontSize: '26px',marginTop:20,marginBottom:10}}>No Time Spent Records</div>
                <div style={{fontSize:'16px',maxWidth:'405px'}}>
                    There is no Time Spent Recorded for this patient.
                </div>
            </div>
        );

        return (
            <div className="patient-task-table">
                <IHSearchTable {...tableProps} noDataElem={noDataElem} />
            </div>
        );
    }

    render(){
        return <div style={{padding:20,backgroundColor:'white'}}>
            {this.renderTable()}
        </div>

    }
}

const mapToProps = (state,props)=>{
    return {
        ...TimeSpentListActions,
        ...state.globalTables.TimeSpent_List,
    }
}


export default connect(mapToProps,null)(TimeSpentListComponent);
