const dataIdFromObject = (o) => {
  switch(o.__typename) {
    case 'ASSESSMENTResult':
      return o.refId + o.id; //this is needed to because in resultsFromToday there's no id field for Apollo to differentiate between an assessment result and a survey result
    default:
      return o.id;
  }
};

export default dataIdFromObject;
