import { constants } from 'libModule/utils'

export default constants('foodlog', 'main', [
    'SET_RATING','UPDATE_COMMENTS','RESET',
    'INIT_FOOD_LOG_LIST',
    'UPDATE_INIT_FOOD_LOG_LIST','RESET_INIT_FOOD_LOG_LIST',
    'REMOVE_FOODLOG',
    'CHANGEPRE','CHANGENEXT','SETTIMEINTERVAL','RESETSTATE','CHANGEYEAR','CHANGEMONTH','SET_INITIAL_STATE'

])
