import React from "react";
import { Tabs } from 'antd';
import HubInfo from '../../AdditionalInfo/Components/HubInfoComponent';
import HostAndBPMComponent from '../component/hotSpotAndBPMComponent';
import LoanDeviceComponent from '../../loanDevices/components/LoadDeviceComponent'
import {compose, graphql} from "react-apollo/index";
import iotDeviceList from "../query/iotDeviceList";
import API  from '../query/API';
import '../style/index.scss';
import { IHLoading } from 'ihcomponent'

const { TabPane } = Tabs;

const tabs = [
    { title: 'EDevice Hub', content:(props)=><HubInfo {...props} />, key: '1' ,className:'hubTab'},
    { title: 'iHealth Clear', content:(props)=><HostAndBPMComponent {...props}
                                            updateIoTDeviceMemberAPI={API.updateIoTDeviceMemberAPI}
                                            editIoTDeviceConfigAPI={API.editIoTDeviceConfigAPI}
                                            removeIotDeviceMemberAPI = {API.removeIotDeviceMemberAPI}
             />, key: '2',className:'hotSpotAndBPM1' },
    { title:'iPhone Tracking',content:(props)=><LoanDeviceComponent {...props}/>,key:'3',className:'loanDevice' }
]


class Main extends React.Component {

    render(){
        const { props } = this;
        return <div>
                <p className="care-plan-fields"><b>Connected Devices</b></p>
                <Tabs defaultActiveKey="1" type="card" className='deviceTabs'>
                {
                    _.map(tabs,({title,content,key,className})=>
                        <TabPane tab={title} key={key} className={className}>
                            {
                                props.loading ?
                                <IHLoading key={key}/> : content(props)
                            }
                        </TabPane>)
                }
               </Tabs>
               </div>
    }
}

const withData = graphql(iotDeviceList,{
    options:(ownProps)=>{
        const userId = _.get(ownProps,'data.user.id');
        return {
            variables:{
                memberId: userId,
            },
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
        const iotDeviceList  = _.get(data,'iotDeviceList.data',[]);
        const loading = _.get(data,'loading');

        if(loading){
            return{
                loading
            }
        }

        return{
            iotDeviceList
        }
    }
});

export default compose(withData)(Main);
