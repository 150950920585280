import {Component} from "react";
import React from 'react';
import map  from '../constants/tableMap';
import { IHSearchTable,IHTable,IHLoading } from 'ihcomponent';
import { Table } from 'antd';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const { tableMap,expandedRowColumn } = map;
class TaskAssignmentListComponent extends Component {
    constructor(){
        super();
        this.state ={
            expandIndex:[]
        }
    }
    getAllAssignedTo = ()=>{
        const teamsFromLoginUser = JSON.parse(sessionStorage.getItem('currentUser')).team;
        const currentUserId = sessionStorage.getItem('currentUserId');
        let currentUser = null;

        const members = [];
        _.forEach(teamsFromLoginUser,t=>_.forEach(t.members,m=>{
            const user = { id:m.user.id,fullName: m.user.profile.fullName };
            if(currentUserId!=m.user.id) {
                members.push(user);
            }else{
                currentUser = user;
            }
        }));
        if(currentUser) members.unshift(currentUser);
        return _.uniqBy(members,m=>m.id);
    }
    getArray = (taskType)=>{
        let arr  = [];
        if(taskType=='0'){
            arr.push({
                id:'createdBy',
                dataIndex:'createdBy',
                title:'Created By',
                width: '120px',
                render:(d,{taskAssignments})=>{
                    const firstTask = _.first(taskAssignments);
                    const profile = _.get(firstTask,'createdBy');
                    return _.get(profile,'profile.fullName');
                },
                filters:_.map(this.getAllAssignedTo(), ({id,fullName})=>({ text:fullName,value:id,key:id }))
            })
        }
        if(taskType=='1'){
            arr.push({
                id:'assignedTo',
                dataIndex:'assignedTo',
                title:'Assigned To',
                width: '120px',
                render:(d,{taskAssignments})=>{
                    const firstTask = _.first(taskAssignments);
                    const profile = _.get(firstTask,'assignedTo');
                    return _.get(profile,'profile.fullName');
                },
                filters:_.map(this.getAllAssignedTo(), ({id,fullName})=>({ text:fullName,value:id,key:id }))
            })
        }
        if(taskType=='2'){
            // arr.push({
            //     id:'assignedTo',
            //     dataIndex:'assignedTo',
            //     title:'Assignee',
            //     width: '120px',
            //     render:(d,{taskAssignments})=>{
            //         const firstTask = _.first(taskAssignments);
            //         const profile = _.get(firstTask,'assignedTo');
            //         return _.get(profile,'profile.fullName');
            //     },
            //     filters:_.map(this.getAllAssignedTo(), ({id,fullName})=>({ text:fullName,value:id,key:id }))
            //
            // })
            arr.push({
                id:'createdBy',
                dataIndex:'createdBy',
                title:'Created By',
                width: '120px',
                render:(d,{taskAssignments})=>{
                    const firstTask = _.first(taskAssignments);
                    const profile = _.get(firstTask,'createdBy');
                    return _.get(profile,'profile.fullName');
                },
                filters:_.map(this.getAllAssignedTo(), ({id,fullName})=>({ text:fullName,value:id,key:id }))
            })
            arr.push({
                id:'assignedTo',
                dataIndex:'assignedTo',
                title:'Assigned To',
                width: '120px',
                render:(d,{taskAssignments})=>{
                    const firstTask = _.first(taskAssignments);
                    const profile = _.get(firstTask,'assignedTo');
                    return _.get(profile,'profile.fullName');
                },
                filters:_.map(this.getAllAssignedTo(), ({id,fullName})=>({ text:fullName,value:id,key:id }))

            })
        }
        return arr;
    }

    getExpandableArray =(taskType)=> {
        let arr  = []

        if(taskType=='0'){
            arr.push({
                id:'created',
                dataIndex:'createdBy',
                title:'Created By',
                width: '120px',
                render:(d,{taskAssignments})=>{
                    return _.get(d,'profile.fullName');
                }
            })
        }
        if(taskType=='1'){
            arr.push({
                id:'assignedTo',
                dataIndex:'assignedTo',
                title:'Assigned To',
                width: '120px',
                render:(d,{taskAssignments})=>{
                    return _.get(d,'profile.fullName');
                }
            })
        }
        if(taskType=='2'){
            arr.push({
                id:'createdBy',
                dataIndex:'createdBy',
                title:'Created By',
                width: '120px',
                render:(d,{taskAssignments})=>{
                    return _.get(d,'profile.fullName');
                }
            })
            arr.push({
                id:'assignedTo',
                dataIndex:'assignedTo',
                title:'Assigned To',
                width: '120px',
                render:(d,{taskAssignments})=>{
                    return _.get(d,'profile.fullName');
                }
            })

        }
        return arr;
    }
    getTableProps = ()=>{
        const { onTableChange, page, loading,setModalData,taskList,refetch,pageInfo,taskType } = this.props;
        const { expandIndex } = this.state;
        const columns = tableMap(this.props,this.getArray(taskType)).map(({ id,dataIndex,title,render,width,sorter,sortOrder,filters,filteredValue,onFilter },i)=>{
            return {
                key: id,
                title,
                render,
                width,
                dataIndex:dataIndex||id,
                sorter,
                sortOrder,
                filters,
                filteredValue,
                onFilter,
                id
            }
        });
        const localThis = this;
        const noDataElem = (
            <div style={{ height: '150px', paddingTop: '2em' }}>
                <p style={{ fontSize: '18px' }}>There is no data!</p>
            </div>
        );

        const expandedRowRender = (a, b, c) =>{
           return <Table dataSource={a.taskAssignments} columns={expandedRowColumn(this.getExpandableArray(taskType))}
                         rowKey={a.member.id}
                         loading = {{ spinning: !!loading,indicator: <IHLoading/> }}
                         onRow = {(doc)=>{
                             console.log(doc);
                            return {
                                onClick: () => setModalData({ showModal:true, modalData:Object.assign({ refetch },doc) })
                                }
                            }}
                         pagination={false} showHeader={false} rowClassName='nestedRowClass'/>
        }
        const pagination = {
            current: _.get(page, 'current', 1),
            pageSize: _.get(page, 'pageSize', 10),
            total: _.get(pageInfo, 'total', 0),
        };
        const tableProps = {
            columns,
            pagination,
            title:'Task Assignments',
            noDataElem,
            dataSource:taskList,
            loading: { spinning:!!loading,indicator: <IHLoading/> },
            expandedRowKeys: expandIndex || null ,
            expandedRowRender:(a, b, c) => expandedRowRender(a, b, c),
            onExpand:(d,doc)=>{
                let res = [];
                let rowIndex = _.findIndex(taskList,(t)=>doc.member.id==t.member.id);
                if(!expandIndex.includes(rowIndex)){
                    res.push(rowIndex);
                }
                localThis.setState({
                    expandIndex:res
                })
            },
            onChange: (p, f, s) => {
                const newFilter = { ...f};
                const newPage = {...p};
                return onTableChange(newPage, newFilter, s)
            },
            onRow(d){
                const firstTask = _.first(d.taskAssignments);
                const { reason,priorityLevel,dueDate,status,createdBy,assignedTo,member,id,resolvedNote,createdAt } = firstTask;
                const doc = {
                    reason,priorityLevel,dueDate,status,createdAt,
                    assignedTo,createdBy,member,id,refetch,resolvedNote
                }
                return {
                    onClick: () => {
                        setModalData({ showModal:true, modalData:doc })
                        Mixpanel.track('clicked', 'task', 'task list page', {PATIENT_ID: id, PRIORITY_LEVEL: priorityLevel, DUE_IN: dueDate});
                    }
                }
            }

        }
        return tableProps
    }

    render(){
        const tableProps = this.getTableProps();
        return <IHSearchTable  { ...tableProps} className="taskAssignmentListTable" />
    }

}

export  default TaskAssignmentListComponent;
