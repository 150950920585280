import  React  from 'react';
import  { Tag } from 'antd';
import '../style/index.scss';
import constant from "../constant";
const { alertLevelColorMap } = constant;
const style ={
    tagStyle:{
        display: 'flex',
        height: 26,
        alignItems: 'center',
        border: 'solid 1px',
        borderRadius: 15,
        marginBottom: 15
    }
}
const PatientSmartAlertComponent = class extends React.Component {

    closeAlert=(e,id,taskType)=>{
        e.preventDefault();
        const { showConfirmForCloseAlert } = this.props;
        showConfirmForCloseAlert(id,taskType);
    }

    addSmartAlertPN = (alert) => {
        const { changeSmartPNModalStatus, showSmartPNModal } = this.props;
        changeSmartPNModalStatus(alert);
    }


    render(){
        const { addSmartAlertPN } = this;
        const { smartAlertsList } = this.props;
        const lists = <div className='PatientSmartAlertComponent'>
                            { _.map(smartAlertsList, (m, i) => {
                                const {color, backgroundColor} = alertLevelColorMap[m.alertLevel];
                                return <Tag key={i} style={{color: color, backgroundColor: backgroundColor,...style.tagStyle}} closable
                                            onClose={(e) => this.closeAlert(e, m.id,m.taskType)}><p style={{fontWeight:'bold',cursor:'point'}} onClick={ ()=> addSmartAlertPN(m) }>{m.display}</p>
                                       </Tag>
                            })
                        }
                      </div>
        return lists;
    }
}

export default PatientSmartAlertComponent;
