import  React  from 'react';
import {compose, graphql} from "react-apollo/index";
import {connect} from "react-redux";
import createTableAction from 'libModule/table/TableActions';
import SmartAlertListComponent from '../component/SmartAlertListComponent';
import smartAlertTaskList from'../query/smartAlertTasksList';
import maps from '../constant/index';
const tableActions = createTableAction('CDEWorking_List',{ shouldUpdateHistory: true });

const CDEWorkingListContainer = class extends React.Component {

    render(){
        const { props } = this;
        return <SmartAlertListComponent {...props}/>
    }
}

const  smartAlertTasksList = graphql(smartAlertTaskList,{
    options:(ownProps)=>{
        let variables = {};
        let filter = _.cloneDeep({ ...ownProps.filter });
        filter.category = ownProps.type
        let page = _.get(ownProps,'page.current',1);
        let count = _.get(ownProps,'page.size',10);
        // let { filterType } = maps;
        // let type = _.get(ownProps,'type');
        // filter.taskType = filterType[type];
        variables.page = page;
        variables.count = count;
        variables.filters = filter;
        // console.log('CDE---', variables.filters);

        return {
            variables:variables,
            fetchPolicy:'network-only'
        }
    },
    props:({data})=> {
        const { smartAlertTasksList, loading } = data;
        if (loading) {
            return {
                loading
            }
        }

        return {
            smartAlertTasksList: smartAlertTasksList.data,
            pageInfo:smartAlertTasksList.pageInfo
        };
    }
})

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    ...state.globalTables.CDEWorking_List,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...tableActions
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    // ,withData,
    smartAlertTasksList
)(CDEWorkingListContainer);
