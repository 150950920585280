import { React, IHSearchTable, IHCheckbox, IHInputNumber, IHButton, _, moment } from 'ihcomponent';
import { Button, Menu, Dropdown, Icon } from 'antd';
import { browserHistory } from 'react-router';
import { showMonthsOrDays, RequestCache} from 'libModule/utils';
import UserClass from 'modulesAll/graphql/class/User';
import Store from 'libModule/reduxStore'
import I18N from 'modulesAll/I18N';

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isStatusFilterClicked: false
    }
    this.changeTableActionFilter = this.changeTableActionFilter.bind(this);
  }

//   componentWillMount() {
//     this.props.onLoadParams(null, true)
//     RequestCache.set('nurse/currentPatient', undefined); //reset
//   }
//   componentWillUnmount() {
//     if (!new RegExp(/^(\/patients)/).test(Store.getState().routing.location.pathname)) {
//         this.props.onLoadParams(null, false)
//     }
//   }

  changeTableActionFilter() {
    const { onTableChange, sort = {}, phoneInfoList} = this.props;

    const sortParam = _.pick(sort, ['field', 'order']);
    
    onTableChange({current: 1, size: 10}, sortParam);
  }

  getTableProps(){
    const formatTime = (data)=>{
        return (moment(data).isValid() ? moment(data).format('MM/DD/YYYY, HH:mm') :'time format error');
    };
    // const {onTableChange, data, sort = ''} = this.props;
    const { phoneInfoList } = this.props;
    let deviceMap = [];

    _.each(phoneInfoList,({connectedDeviceInfo})=>{
          _.each(connectedDeviceInfo,d=>{
            deviceMap.push(d);
          })
    })
    return {
      title: `Device`,
      dataSource: deviceMap,

    //   onChange: (p, f, s) => {
    //     let result = null;
    //     if(this.props.sort.order && !s.order) {
    //     Mixpanel.track('clicked', 'sort', 'age is canceled', '');
    //     return onTableChange(p, newFilter, s);
    //     } else {
    //     Mixpanel.track('clicked', 'sort', 'age ' + s.order == 'descend' ? 'old to young' : 'young to old', '');
    //     return onTableChange(p, newFilter, s);
    //     }
    //   },
    //   inputPlaceholder: `Search by Name ${I18N.get('keywords.NRIC/FIN')} or ${I18N.get('keywords.PHONE')}`,
    //   pagination: {
    //     current: _.get(page, 'current', 1),
    //     pageSize: _.get(page, 'pageSize', 10),
    //     total: _.get(patientList, 'pageInfo.total', 0),
    //     onChange: (e) => {
    //       Mixpanel.track('clicked','pager','patients', {PAGE_NUM : "Page" + e});
    //     }
    //   },

      columns: [
        {
          title:'Device Name',
          key: 'deviceName',
          dataIndex:'deviceType',
          render: (t, doc) => { 
            return t
          }
        },
        {
          title: 'Mac Address',
          key: 'macAddress',
          // dataIndex: 'mobileInfo.phoneModel',
          dataIndex: 'macAddress',
          render: (t, doc) => { 
              return t
          }
        },
        {
          title: 'Model',
          key: 'model',
          width: '60%',
          // dataIndex: 'mobileInfo.phoneModel',
          dataIndex: '',
          render: (t, doc) => { 
              return t
          }
        },
        // {
        //   title: 'Initial Used',
        //   key: 'initialUsed',
        //   dataIndex:'firstUsed',
        //   render:(data)=>formatTime(data),
        // //   render: (t, doc) => doc.user.format('age') + 'yr',
        //   sorter: true,
        //   sortOrder: _.get(sort, 'field', null) === 'age' ? _.get(sort, 'order', false) : null,
        // },
        // {
        //     title: 'Last Used',
        //     key: 'lastUsed',
        //     dataIndex:'lastUsed',
        //     width: '55%',
        //     render:(data)=>formatTime(data),
        //     sorter: true,
        //     sortOrder: _.get(sort, 'field', null) === 'age' ? _.get(sort, 'order', false) : null,
        //   },
      ]
    }
  }

  render() {
    return (
      <div>
        {<IHSearchTable {...this.getTableProps()} />}
      </div>
    );
  }
}
