import { React, IHPanel, IHButton, IHBaseForm, IHLoading, _ } from 'ihcomponent';
import { usernameValidator, passwordValidator } from '../../../lib/validator';
import { submittingStyle } from 'libModule/utils'
import I18N from '../../I18N'

const COL = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default (props) => {
  const {
    submit,
    isSubmitting,
    loginWithoutPasswordAsAdmin,
    loginWithoutPasswordAsSuperAdmin,
    loginWithoutPasswordAsNurse,
    loginWithoutPasswordAsPatient,
    loginWithoutPasswordAsVendor,
    loginFromLocalStorageForTest,
    forgotPassword,
  } = props;

  const validAndSubmit = (e, { validateFieldsAndScroll }) => {
    e.preventDefault();

    validateFieldsAndScroll((err, values) => {
      !err && submit(values);
    });
  };

  const formProp = {
    onSubmit: validAndSubmit,
    horizontal: true,
    itemList: [
      {
        key: 'username',
        label: 'Login ID',
        type: 'text',
        ...COL,
        autoComplete: 'off',
        rules: [
          { validator: (rule, value, callback) => callback(usernameValidator(value)) },
        ],
      },
      {
        key: 'password',
        label: 'Password',
        type: 'password',
        ...COL,
        rules: [
          {required : true, message: I18N.get('validator.password.require')}
        ],
        className: 'hide-required-mark login-unset-margin'
      },
    ],
  };

  const bodyElem = (
    <div className='login-container'>
      <div className='login-sharecare-logo-container'>
        <img className='login-sharecare-logo' src='/image/sharecare-logo.svg' />
      </div>
      <div className='login-title-container'>
        {/*<img src='/image/ihealth-logo-login-256.png' style={{ width:60, height:19, paddingTop:3 }} />&nbsp;*/}
          <span className='login-sharecare-title'>UnifiedCare</span>
      </div>
      <IHBaseForm {...formProp}>
        <div className='login-forgot-password-wrap'>
          <button className="link-like" type="button" onClick={forgotPassword}>Forgot password?</button>
        </div>
            {
                  isSubmitting ? <IHLoading/> :
                  <div className="buttons-wrap">
                    <IHButton className='login-submit-btn' bsStyle="primary" disabled={isSubmitting} type="submit"
                       style={submittingStyle(isSubmitting)}>Log In
                    </IHButton>
                  </div>
            }
      </IHBaseForm>
    </div>
  );

  const panelProps = {
    // header: {
    //   title: 'iHealth ShareCare',
    // },
    body: bodyElem,
    bodyStyle: {
      border:'unset'
    },
    style: {
      padding:50,
      height:625
    }
  };

  if (window.VSM_CONFIG.DEBUG === true) {
    return (
      <div className="vsm-auth-box auth-step1">
        <IHPanel {...panelProps} />
        <br />
        <div className="shortcut-wrap">

          {
            // <button className="link-like" onClick={loginWithoutPasswordAsPatient}>Login as a patient without password for testing</button>
            // <button className="link-like" onClick={loginWithoutPasswordAsNurse}>Login as a nurse without password for testing</button>
            // <button className="link-like" onClick={loginWithoutPasswordAsAdmin}>Login as an admin without password for testing</button>
            //<button className="link-like" onClick={loginWithoutPasswordAsSuperAdmin}>Login as a superadmin without password for testing</button>
            //<button className="link-like" onClick={loginWithoutPasswordAsVendor}>Login as a vendor without password for testing</button>
          }
          <br/>
          {
            //<button className="link-like" onClick={loginFromLocalStorageForTest}>Login from local storage for test real user</button>
          }
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="vsm-auth-box auth-step1">
        <IHPanel {...panelProps} />
        <br />
      </div>
    );
  }
};
