import gql from 'graphql-tag'
import {User, UserWithoutTeam, UserForSessionStorage} from './schema/User'
import {Address, Phone} from './schema/common';

export default gql`
  query user($id:EID){
    user(id:$id){
      ${User}
    }
  }
`

export const userForAvatar = gql`
  query user($id:EID) {
    user(id:$id) {
      id
      allRoles {
        name
        category
      }
      profile {
        firstName
        lastName
        nickname
        fullName
        fullNameWithNickname
        avatar {
          link
        }
      }
    }
  }
`

export const userForChat = gql`
  query user($id:EID) {
    user(id:$id) {
      id
      createdAt
      registeredAt
      allRoles {
        refId
        name
        category
        active
      }
      username
      email {
        address
      }
      address {
        ${Address}
      }
      phone {
        ${Phone}
      }
      profile {
        title
        firstName
        lastName
        nickname
        fullName
        fullNameWithNickname
        avatar {
          link
        }
        ...on MemberProfile {
          birthday
          language {
            code
            description
          }
          height
          weight
        }
      }
      userSettings {
        preferredUnit
      }
      hasPassword
    }
  }
`

export const userForSessionStorage = gql`
  query user($id:EID){
    user(id:$id){
      ${UserForSessionStorage}
    }
  }
`

export const userWithoutTeam = gql`
  query user($id:EID!){
    user(id:$id){
      ${UserWithoutTeam}
    }
  }
`

export const presetNotes = gql`
  query User($id: EID!) {
    user(id: $id) {
      id
      allRoles {
        organization {
          id
          notePreset {
            category
            display
            subCategories
          }
        }
      }
    }
  }
`
