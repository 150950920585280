import {Component} from "react";
import React from 'react';
import TaskAssignmentListContainer from '../containers/TaskAssignmentListContainer';
import { Tabs, Button } from 'antd';
const { TabPane } = Tabs;

class TaskAssignmentListContainerWrapper extends Component {
    constructor(){
        super();
        this.state = {
            taskType: '0',
            showModal: false,
            modalData: null
        }

    }
    setTaskType = (taskType)=>{
        this.setState({
            taskType
        })
    }

    setModalData = ({showModal,modalData})=>{
        this.setState({
            showModal,
            modalData
        })
    }

    render(){
        const { taskType,showModal,modalData } = this.state;
        return <TaskAssignmentListContainer setTaskType={this.setTaskType}
                                            taskType={taskType}
                                            showModal={showModal}
                                            setModalData={this.setModalData}
                                            modalData={modalData}
                />
    }

}

export default TaskAssignmentListContainerWrapper;
