import React from 'react';

const divStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 100
}
const EmptyChannelComponent  = class extends React.Component {
    constructor() {
        super();
    }
    render(){
        return <div style={divStyle}>
                    There is No message on this Channel, which is not good.<br/><br/>
                    Please check the patient profile first. If you think it shouldn't be in this situation, please report this issue to the product team.<br/>
                    <br/> Thank you.
               </div>
    }
}

export default EmptyChannelComponent;
