import React from 'react';
import { Table, Input, Button, Icon } from 'antd';
import * as R from 'ramda';
import { browserHistory } from 'react-router';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { mkShortDate } from './utils';
import { withState, compose, withProps } from 'recompose';
import { trace, spinnerWhileLoading } from '../../lib/helpers/component-helpers';

const Search = Input.Search;

const mkSearchStateKeyName = (searchField) => 'searchString_' + searchField;
const mkFilterVisibleStateKeyName = (searchField) => 'filterVisible_' + searchField;
const mkInputComponentName = (searchField) => 'searchInput_' + searchField;

const mkCol = (rc, { colKey: colKey0, colName, width, sortedInfo, data }) => {
  const colKey = colKey0 || colName.toLowerCase();
  ;
  return R.mergeAll([
    width ? { width } : {}, 
    {
    title: colName,
    dataIndex: colKey,
    key: colKey,
    sorter: R.comparator((a0, b0) => {
      let a, b;
      if(typeof a0[colKey] === 'object' || b0[colKey] === 'object') {
        a = R.find(R.propEq('key', a0.key), data);
        b = R.find(R.propEq('key', b0.key), data);
      } else {
        a = a0;
        b = b0;
      };
      // const getState = (obj) => R.trim(R.last(R.split(',', obj.location)));
      return a[colKey] < b[colKey];
    }),
    sortOrder: sortedInfo.columnKey === colKey && sortedInfo.order,
  }])
};

  // const ageFiltersArr = [ 'Under 20', '20-40', '40-60', 'Over 60' ];
  // const ageFilterDict = {
  //   'Under 20': [null, 20],
  //   '20-40': [20, 40],
  //   '40-60': [40, 60],
  //   'Over 60': [60, null],
  // };

  // const deviceFiltersArr = [ 'BP', 'BG', 'PO', 'HS', 'AM`' ];

  // const phoneFiltersArr = [ 'iOS', 'Android' ];
  // const phoneFilterDict = {
  //   'iOS': [ 'iPhone', 'iPad', 'Apple' ],
  //   'Android': [ 'Nexus', 'Galaxy', 'Samsung', 'HTC' ],
  // };

const searchFields = {
  name: 'Name',
  email: 'Email address',
  userIPList: 'IP address',
  username: 'Username',
  devices: 'Device MAC'
};

class TroubleshootingListComponent extends React.Component {
  constructor(props) {
    super(props);
    const self = this;
    const searchStateKeysInit = R.fromPairs(R.map((searchField) => ([ mkSearchStateKeyName(searchField), '']), R.keys(searchFields)));
    const filterVisibleKeysInit = R.fromPairs(R.map((searchField) => ([ mkFilterVisibleStateKeyName(searchField), false]), R.keys(searchFields)));
    self.state = R.mergeAll([searchStateKeysInit, filterVisibleKeysInit, {
      filterEmailDropdownVisible: false,
      filteredInfo: {},
      sortedInfo: {
        order: 'ascend',
        columnKey: 'name',
      },
    }]);
  }

  clearFilters = () => {
    const searchStateKeysInit = R.fromPairs(R.map((searchField) => ([ mkSearchStateKeyName(searchField), '']), R.keys(searchFields)));
    this.setState(R.merge(searchStateKeysInit, { filteredInfo: {}}));
  }
  clearAll = () => {
    const searchStateKeysInit = R.fromPairs(R.map((searchField) => ([ mkSearchStateKeyName(searchField), '']), R.keys(searchFields)));
    this.setState(R.merge(searchStateKeysInit, {
      filteredInfo: {},
      sortedInfo: {},
    }));
  }
  setUsernameSort = () => {
    this.setState({
      sortedInfo: {
        order: 'ascend',
        columnKey: 'username',
      },
    });
  }
  onRowClick(e) {
    browserHistory.push('superadmin/troubleshooting/'+e.key);
  }
  emitEmpty = (searchField) => {
    const inputComponentName = mkInputComponentName(searchField);
    this[inputComponentName].focus();
    this.setState({ [mkSearchStateKeyName(searchField)]: '' });
  }
  showFilteredResults = (data) => {
    const self = this;
    const filterableFields = R.keys(searchFields);
    const checkMatch = (field, doc0)=> {
      const stateKey = mkSearchStateKeyName(field);
      const searchQuery = this.state[stateKey];
      const res = (searchQuery === '') || (!R.isNil(doc0[field]) && (new RegExp(searchQuery, 'gi')).test(doc0[field]));
      // console.log('docfield', doc0[field], res);
      return res;
    }
    const matchAll = R.allPass(R.map((field)=>R.partial(checkMatch, [field]), filterableFields));
    const data_filtered = R.filter(matchAll, data);
    // console.log('testfilter', R.map(matchAll, data), matchAll(R.last(data)));
    // console.log('data_filtered', data_filtered);
    const highlightSelection = (doc) => {
      const transformField = (field, val) => {
        if(R.isNil(val)) return;
        const stateKey = mkSearchStateKeyName(field);
        const searchText = this.state[stateKey];
        const reg = new RegExp(searchText, 'gi');
        const match = val.match(reg);
        // console.log('transformField', searchText, match);
        return (
          <span>
            {val.split(reg).map((text, i) => (
              i > 0 ? [<span className="highlight">{match[0]}</span>, text] : text
            ))}
          </span>
        )};
      const modifyField = (val, field) => {
        // console.log('modifyField', field, val);
        return R.contains(field, filterableFields) ? transformField(field, val) : val
      };
      const doc2 = R.mapObjIndexed(modifyField, doc);
      // console.log('data2', doc, doc2);
      return doc2;
    };
    const data2 = R.map(highlightSelection, data_filtered);
    return data2;
  }
  render() {
    const { sortedInfo, filteredInfo, 
    // , searchLocation 
    } = this.state;
    const self = this;
    const { data: dataObj, updatePaginationDetails, paginationDetails }  = this.props;
    const { loading, userList: { data, pageInfo } = { data: [], pageInfo: {}}} = dataObj;
    const columns = [
      mkCol(self, { colName: 'Name', sortedInfo, data, width: 200 }),
      mkCol(self, { colName: 'Email', sortedInfo, data, width: 150 }),
      mkCol(self, { colName: 'IP Address', colKey: 'userIPList', sortedInfo, data, width: 150 }),
      mkCol(self, { colName: 'Device MAC', colKey: 'devices', sortedInfo, data, width: 150 }),
      mkCol(self, { colName: 'Username', sortedInfo, data }),
      R.merge(mkCol(self, { colName: 'Gender', sortedInfo, data }),{
        filters: [
          { text: 'Male', value: 'M' },
          { text: 'Female', value: 'F' },
        ],
      }),
      // R.merge(mkCol(self, { colName: 'Age', sortedInfo, data }),{
      //   filters: R.map((filterValue)=>({ text: filterValue, value: filterValue }), ageFiltersArr ),
      //   filteredValue: filteredInfo.age || null,
      //   onFilter: (ageFilterKey, { age }) => {
      //     const [min_age, max_age] = ageFilterDict[ageFilterKey];
      //     console.log('age', [ min_age, max_age ]);
      //     return ( R.isNil(min_age) ? true : age >= min_age ) && ( R.isNil(max_age) ? true : age <= max_age )
      //   },
      // }),
      // R.merge(mkCol(self, { colName: 'Phone Model', colKey: 'phoneModel', sortedInfo, data }),{
      //   filters: R.map((filterValue)=>({ text: filterValue, value: filterValue }), phoneFiltersArr ),
      //   filteredValue: filteredInfo.phoneModel || null,
      //   onFilter: (phoneFilterKey, { phoneModel }) => {
      //     const phoneExamples = phoneFilterDict[phoneFilterKey];
      //     return (new RegExp(R.join('|', phoneExamples),'i')).test(phoneModel);
      //   },
      // }),
    ];
    const searchName = self.state[mkSearchStateKeyName('name')];
    const title = () => {
      return <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className="table-search">
          <Search
            placeholder="Search name or email"
            onSearch={value => self.props.updateSearchQuery(R.set(R.lensProp('string'), value))}
          />
        </div>
      </div>
    };
    return (
      <div>
        <div className="table-operations">
          <Button onClick={this.setUsernameSort}>Sort Username</Button>
          <Button onClick={this.clearFilters}>Clear filters</Button>
          <Button onClick={this.clearAll}>Clear filters and sorters</Button>
        </div>
        <Table size='small' title={title} columns={columns} dataSource={this.showFilteredResults(data)} 
          onRow={ (record) => {return {onClick:()=> this.onRowClick(record)}; }}
          rowClassName={(record, index)=>'table-row-fixed-height-70'}
          pagination={{
            total: pageInfo.total,
            current: paginationDetails.page,
            pageSize: paginationDetails.pageSize,
          }} loading={loading}
          onChange={(pagination, filters, sorter) => {
            self.props.updateSearchQuery(R.set(R.lensProp('gender'), filters.gender))
            updatePaginationDetails({ page: pagination.current, pageSize: pagination.pageSize });
            this.setState({
              // filteredInfo: filters,
              sortedInfo: sorter,
            });
          }
        }/>
      </div>
    );
  }
};
const allRolesQuery = graphql(gql`
  query allRoles {
    organizationList(count: 100) {
      data {
        roles {
          refId
        }
      }
    }
  }
`, {
  options: ({ size }) => ({ variables: { size },fetchPolicy: 'network-only'}),
  props: ({ data: { loading, organizationList: { data: organizationList } = { data: [] } }}) => {
    const allRoles = R.unnest(R.map(R.compose(R.pluck('refId'), R.prop('roles')), organizationList));
    return ({
      allRoles,
      allRolesLoading: loading,
    })
  },
});

const userListWithAudit = graphql(gql`
  query list($page: Int, $count: Int, $sort: UserListSort, $UserListFilters: UserListFilters!, $search: InputUserSearch) {
    userList(filters: $UserListFilters, search: $search, page: $page, count: $count, sort: $sort) {
      pageInfo {
        total
        lastPage
      }
      data {
        id
        username
        profile {
          fullName
          gender
          ... on MemberProfile{
            birthday
          }
        }
        phone {
          type
          countryCode
          areaCode
          number
          primary
          verified
        }
        email {
          address
        }
        userIPList {
          ip
          count
          firstUsed
          lastUsed
        }
        devices {
          address
          model
        }
      }
    }
  }`, {
  options: ({ allRoles, paginationDetails: { page, pageSize }, searchQuery }) => ({
    variables: {
      page,
      count: pageSize,
      UserListFilters : {
        "roles": allRoles,
        "age": searchQuery.age,
        "gender": searchQuery.gender
      },
      "search": {
          "fields": [
              "NAME",
              "EMAIL"
          ],
          "match": searchQuery.string
      },
      "sort": {
          "field": "REGISTERED",
          "direction": "DESC"
      }
    }
  }),
  props: (props) => R.isNil(props.data.userList) ? props : R.over(R.lensPath(['data', 'userList', 'data']), R.map(reshape), props),
});

const joinDefined = (s, arr0) => {
  const arr = R.reject(R.or(R.isNil, R.isEmpty), arr0);
  return R.isEmpty(arr) ? null : R.join(s, arr);
};
const reshape = ({ email: emailArr, id, profile, username, devices: devicesArr, userIPList: userIPListObjArr }) => {
  const { fullName, gender } = profile;
  const email = R.prop('address', R.head(emailArr));
  const devices = joinDefined(', ', R.map(({ model, address }) => joinDefined(' ', [model, address]), devicesArr));
  const userIPList = joinDefined(', ', R.map(({ ip, count, firstUsed, lastUsed }) => ip + ': ' + mkShortDate(firstUsed) + '~' + mkShortDate(lastUsed) + ' ' + (count ? count + 'x' : '') , userIPListObjArr));
  return { email, key: id, username, name: fullName, gender, devices: devices, userIPList };
};

const TroubleshootingList1 = compose(
  allRolesQuery,
  spinnerWhileLoading(R.prop('allRolesLoading')),
  withState('searchQuery', 'updateSearchQuery', {
    string: '',
    gender: [],
    // age: {
    //   min: null,
    //   max: null
    // },
    // phone: '',
  }),
  withState('paginationDetails', 'updatePaginationDetails', {
    page: 1,
    pageSize: 10,
  }),
  userListWithAudit,
  // withProps(trace('abc')),
)(TroubleshootingListComponent);

export const TroubleshootingList = () => {
  return (
  <div className='code-box-demo' >
    <TroubleshootingList1 />
  </div>
)};