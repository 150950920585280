import gql from 'graphql-tag';
import { mutate } from 'graphql';
import Client from 'libModule/gqlClient';
import uuidv4 from "uuid/v4";

const createTimeTrackingEventQuery =  gql `
                mutation createTimeTrackingEvent($viewerId:EID!,$userId:EID!,$startTime:Date!,$category:TimeTrackerCategoryEnum!,
                                                 $stopTime:Date,$abortTime:Date,$action:TimeTrackerActionEnum!,
                                                 $interventionDetails:[TimeTrackerInterventionDetailsInput],
                                                 $totalTime:Int,$clientEvents:[TimeTrackerClientEventInput],
                                                 $viewingDetails: TimeTrackerViewingDetailsInput,$version:Int,$sessionId:String,$timeout:Int){
                                                createTimeTrackingEvent(viewerId:$viewerId,
    											userId:$userId,startTime:$startTime,	
    											stopTime:$stopTime,abortTime:$abortTime,action:$action,
    											clientEvents:$clientEvents,interventionDetails:$interventionDetails,
    											category:$category,totalTime:$totalTime,viewingDetails:$viewingDetails,version:$version,sessionId:$sessionId,timeout:$timeout){
                                                                                                id
      
    
                  }
                }`

const createTimeTrackingEvent = (variables)=>{
    return Client.mutate({
        mutation: createTimeTrackingEventQuery,
        variables: variables,
        fetchPolicy: 'no-cache'
    })
}

const startTimeTracker  = (event,category,action,userId)=>{
    let startTime = new Date();
    let clientEvents = new Array();
    const viewerId = sessionStorage.getItem('currentUserId');
    clientEvents.push({
        event:event,
        action:'START_TIMER',
        ts:new Date()
    });

    return {
        clientEvents,
        userId,
        viewerId,
        category,
        action,
        startTime
    };
}

const createTimeTracker = (event,timeTrackerEvent,resetuuid,uuid)=>{
    const stopTime = new Date();
    const totalTime = stopTime - timeTrackerEvent.startTime;
    timeTrackerEvent.stopTime = stopTime;
    timeTrackerEvent.totalTime = totalTime;
    timeTrackerEvent.clientEvents.push({
        event:event,
        action:'STOP_TIMER',
        ts:new Date()
    })

    timeTrackerEvent.sessionId = resetuuid ? uuidv4() : uuid;
    createTimeTrackingEvent(timeTrackerEvent);
}

const saveModalAndUpdateTimer = (props,displayName,apiName,patientId,docId,summary)=>{
    const resource = atob(docId).split(':')[0];
    displayName = `${displayName}:${patientId}`;
    props.updateInterventionDetails(displayName, apiName, resource, docId, summary);
    props.stopTimer(displayName, 'saveModal');
}
export const API = {
    createTimeTrackingEvent,
    startTimeTracker,
    createTimeTracker,
    saveModalAndUpdateTimer
}
