import React from 'react';
import { Icon } from 'antd';
import moment from 'moment';

export const symbols = {
  Removed: <Icon type='arrow-down' style={{ color: 'red', fontSize: 10, cursor: 'unset' }} />,
  Added: <Icon type='arrow-up' style={{ color: 'green', fontSize: 10, cursor: 'unset' }} />
};

export const mappingDevices = {
    'BP': 'BP3L',
    'LANCET': 'Lancets',
    'CLEAR': 'Hub',
    'BG': 'BG1',
    'STRIP': 'Strips',
    'PHONE': 'iPhone',
    'HS': 'HS2',
    'A1C': 'A1C'
};

export const NUM_OF_ROWS = 10;

export const getRowTemplate = () => ({ ease: 0, align: 0, lina: 0, clear: 0, iphone: 0, lancet: 0, strip: 0, a1c: 0 });

export const checkHasToday = data => {
  const { date, week, month } = data;
  return month ? moment().format('YYYY, MMM') === month :
        week ? moment().week() === week :
        moment().format('MM/DD/YYYY') === moment(date).format('MM/DD/YYYY');
}