import {
    React, IHButton, IHBaseFormWithRow,message
} from 'ihcomponent';
import { connect } from 'react-redux';
import Client from 'libModule/gqlClient';
import medicationList  from  'modulesAll/graphql/medicationList';
import getVariables  from '../query/variables';
import { Form, Select, Button,Radio,Modal,Row,Col,Checkbox,DatePicker,Timeline,Input,AutoComplete } from 'antd';
import "antd/lib/button/style/index.css";
import '../style/style.scss';
import actions from "../../medicine/actions";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'lib/utils'
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from '../../timer/actions/index';
import { API } from '../../timer/query/index';
import medTypes from '../constants/medTypes.js';
import moment from 'moment';
import {graphql,compose} from "react-apollo/index";
import medNameList from "../../graphql/medNameList";
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const Item = Timeline.Item;
const { stopTimer,updateInterventionDetails } = timerActions.default;

const FormItem = Form.Item;
const typeMap = {
    'Oral':'ORAL',
    'Injectable':'INJECTABLE',
    'External':'EXTERNAL'
}

const enumToTyep = {
    'ORAL':'Oral',
    'INJECTABLE':'Injectable',
    'EXTERNAL':'External'
}
let MedicineModalComponent = class extends React.Component{
    static displayName ='AddMedicineComponent';
    constructor(props){
        super(props);
        this.renderInputFields.bind();
        this.renderRadioField.bind();
        this.renderFooter.bind();
        this.createMed.bind();
        this.onSubmit.bind();
        this.removeMed.bind();
        this.showDeleteConfirm.bind();
        this.state = {
            showMedAddedMsg: false,
            showAddedMedHistory: false,
            showMedInTakingMsg : false
        }
    }

    componentWillUnmount(){
        if(this.props.modal.type=='update') {
            this.props.isUpdated(false);
        }
    }
    renderRadioField(){
        const { getFieldDecorator } = this.props.form;
        const data = this.props.curMed||{};
        const itemList =
            [{
                key: 'type',
                label: "Medication Type",
                type: 'radioButton',
                className: 'radio-button add_medicine_header',
                required:true,
                initialValue:data.type
            }]
        return  itemList.map((value)=>{
            return(
                <FormItem
                    label={value.label}
                    key={value.label}
                    style={{display:'flex',flexDirection:'column'}}
                    className='radio_option'
                >
                    {
                        getFieldDecorator(value.key, {
                            rules: [
                                { required: value.required, message: 'Please Select Medicine Type'},
                            ],
                            initialValue:value.initialValue || 'ORAL'
                        })
                        (
                            <Radio.Group>
                                {
                                    _.map(medTypes,({name,value},index)=><Radio value={value} key={index}>{name}</Radio> )
                                }
                                {/*<Radio value='External'>External</Radio>*/}
                                {/*<Radio value='Oral'>Oral</Radio>*/}
                                {/*<Radio value='Injectable'>Injectable</Radio>*/}
                            </Radio.Group>
                        )
                    }
                </FormItem>
            )
        });

    }

    renderRadioGroup(){
        const { getFieldDecorator,setFieldsValue } = this.props.form;
        const data = this.props.curMed||{};
        const itemList =
            [
                {
                    key: 'TAKING',
                    label:'Taking',
                    type:'checkbox',
                    cols:5,
                    valuePropName:'checked',
                },
                {
                    key: 'NOT_SURE',
                    label:'Not Sure',
                    type:'checkbox',
                    cols:5,
                    valuePropName:'checked',
                },
                {
                    key: 'NOT_TAKING',
                    label:'Not Taking',
                    type:'checkbox',
                    cols:5,
                    valuePropName:'checked',
                },

            ];

        return  <Row style={{ display:'flex' }}>
                        <FormItem
                            label={'Status'}
                            style={{display:'flex',flexDirection:'column'}}
                            className='radio_option'
                        >
                            {
                                getFieldDecorator('status',{
                                    initialValue: data.status||'TAKING',
                                })
                                (
                                    <Radio.Group>
                                        { _.map(itemList,(value,index)=> <Radio value={value.key} key={index}>{value.label}</Radio>) }
                                    </Radio.Group>
                                )
                            }
                        </FormItem>
                </Row>
    }

    handleOnBlur = (e,key)=>{
        const { uniqueMedNames,medicationList } = this.props;
        const name = e.target.value;
        const addedMed = _.find(medicationList.data,m=> m.name == name);
        if(_.indexOf(uniqueMedNames,name)!=-1) {
            if(!addedMed.endDate || addedMed.endDate>= new Date().getTime()){
                this.setState({
                    showMedInTakingMsg: addedMed
                })
            }else {
                this.setState({
                    showMedAddedMsg: name
                })
            }
        }else{
            this.setState({
                showMedAddedMsg: false
            })
        }

    }

    handleOnChange = ()=>{
        const { showMedInTakingMsg,showMedAddedMsg } = this.state;
        if( showMedAddedMsg ||showMedInTakingMsg ) {
            this.setState({
                showMedAddedMsg: false,
                showMedInTakingMsg: false
            })
        }
    }

    medAddedMsg = ()=>{
        return <p>*Patient has taken this medication before.
                    <a href="javascript:void(0)" onClick={()=>this.setState({ showAddedMedHistory:true })}>Click here </a>
                to check the medication history.
               </p>
    }

    renderMedTaking = ()=>{
        return <p>*Patient is current taking this medication.
                   To change the dosage, please click the button below
               </p>
    }

    renderInputFields (showMedInTakingMsg,showMedAddedMsg){
        const { getFieldDecorator } = this.props.form;
        const data  = this.props.curMed||{};
        const { modal } = this.props;

        return <div>
                    { this.renderNameAndDosage(data,modal,getFieldDecorator,showMedInTakingMsg)}
                    { showMedAddedMsg ? this.medAddedMsg() : null}
                    { !showMedInTakingMsg ? this.renderStartDate(data,modal,getFieldDecorator,showMedInTakingMsg) : '' }
                </div>
        // let itemList =
        //     [
        //         [
        //             {
        //                 key: 'dosage',
        //                 label: "Dosage",
        //                 type: 'textarea',
        //                 initialValue: data.dosage||'',
        //                 className: 'textareaInput',
        //                 size:'default',
        //                 required:true,
        //                 message:'Please enter dosage',
        //                 cols:8
        //             }
        //         ],
        //         [
        //             {
        //                 key:'startDate',
        //                 label:'Start Date',
        //                 cols:9,
        //                 initialValue:data.startDate ? moment(data.startDate) : null,
        //                 render:()=><DatePicker />
        //             },
        //             {
        //                 key: 'otherMD',
        //                 label:'Other MD',
        //                 type:'checkbox',
        //                 cols:5,
        //                 valuePropName:'checked',
        //                 initialValue: data.otherMD,
        //                 required: false,
        //                 message: null,
        //                 render:()=><Checkbox />
        //             },
        //
        //         ],
        //     ]
        // if(modal.type=='add') {
        //     itemList[0].unshift(
        //         {
        //             key: 'name',
        //             label: "Medication Name",
        //             className: 'textareaInput',
        //             type: 'text',
        //             initialValue:data.name||'',
        //             message:'Please enter Medicine Name',
        //             cols:12
        //         })
        // }
        // if(showMedInTakingMsg){
        //     itemList = [[
        //         {
        //             key: 'name',
        //             label: "Medication Name",
        //             className: 'textareaInput',
        //             type: 'text',
        //             initialValue:showMedInTakingMsg.name||'',
        //         }
        //     ]]
        // }
        // return _.map(itemList,(arr,i) =>
        //    <Row key={i}>
        //        {
        //            _.map(arr, (value, index) => {
        //                return (
        //                    <Col span={value.cols} key={index}>
        //                        <FormItem
        //                        label={value.label}
        //                        key={value.key}
        //                        style={{display: 'flex', flexDirection: 'column'}}
        //                        >
        //                            {getFieldDecorator(value.key, {
        //                                rules: [{
        //                                    required: value.required,
        //                                    message: value.message || null,
        //                                }],
        //                                initialValue: value.initialValue,
        //                                valuePropName: value.valuePropName || 'value'
        //
        //                            })
        //                            (
        //                                value.render ? value.render(value) :
        //                                    <input rows={value.rows || 1} cols={value.col || 55} onBlur={(e)=>this.handleOnBlur(e,value.key)}
        //                                                                                         onChange={()=>this.handleOnChange()}/>
        //                            )}
        //                        </FormItem>
        //                    </Col>
        //                )
        //            })
        //        }
        //    </Row>
        // )
    }

    renderAddedMedHistory = ()=>{
        const { medicationList } = this.props;
        const { showMedAddedMsg } = this.state;
        const addedMed = _.find(medicationList.data,m=> m.name == showMedAddedMsg);
        const { histories } = addedMed;
        const filteredHis = _.filter(histories,h=>h.field);
        return <div>
                {
                    filteredHis.length > 0 ?
                        <div style={{ padding:20 }}>
                            <h4 style={{ marginLeft: 18,marginBottom: 30 }}>{ showMedAddedMsg }</h4>
                            <Timeline mode={'alternate'}>
                                {_.map(filteredHis, ({originalValue, newValue, field, modifiedAt}, index) =>
                                    <div style={{ display:'flex' }} key={index}>
                                        <li className='ant-timeline-item' style={{ marginRight:10 }}>
                                            <div className='ant-timeline-item-content'>{ moment(modifiedAt).format('MM/DD/YYYY') }</div>
                                        </li>
                                        <Item key={index}>{`${_.upperFirst(field)} changed ${originalValue} to ${newValue}`}</Item>
                                    </div>)
                                }
                            </Timeline>
                        </div>
                        :
                        <div>
                            <h4 style={{ marginLeft: 20 }}>{ name }</h4>
                            <span style={{ marginLeft: 20 }}>No History</span>
                        </div>
                }
                <Button onClick={()=>this.setState({ showAddedMedHistory: false })}>Back</Button>
            </div>
    }

    renderNameAndDosage = (showMedInTakingMsg)=>{
        const { getFieldDecorator } = this.props.form;
        const { medNameList } = this.props;
        const data  = this.props.curMed||{};
        const { modal } = this.props;
        let itemList =
            [
                [
                    {
                        key: 'dosage',
                        label: "Dosage",
                        type: 'textarea',
                        initialValue: data.dosage||'',
                        className: 'textareaInput',
                        size:'default',
                        required:true,
                        message:'Please enter dosage',
                        cols:8
                    }
                ]
            ]
        if(modal.type=='add') {
            itemList[0].unshift(
                {
                    key: 'name',
                    label: "Medication Name",
                    className: 'textareaInput',
                    type: 'text',
                    initialValue:data.name||'',
                    message:'Please enter Medicine Name',
                    cols:12,
                    // render:()=><Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>{
                    //     _.map(_.uniq(medNameList), (v,index) => <Select.Option value={v} key={v}>{v}</Select.Option>)
                    // }
                    //</Select>
                    render:()=><AutoComplete dataSource={_.uniq(medNameList)} filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }/>,
                })
        }
        if(showMedInTakingMsg){
            itemList = [[
                {
                    key: 'name',
                    label: "Medication Name",
                    className: 'textareaInput',
                    type: 'text',
                    initialValue:showMedInTakingMsg.name||'',
                    render:()=><Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>{
                        _.map(_.uniq(medNameList), (v,index) => <Select.Option value={_.replace(v,`"`,'')} key={v}>{v}</Select.Option>)
                    }
                    </Select>
                }
            ]]
        }
        return _.map(itemList,(arr,i) =>
            <Row key={i}>
                {
                    _.map(arr, (value, index) => {
                        return (
                            <Col span={value.cols} key={index}>
                                <FormItem
                                    label={value.label}
                                    key={value.key}
                                    style={{display: 'flex', flexDirection: 'column'}}
                                >
                                    {getFieldDecorator(value.key, {
                                        rules: [{
                                            required: value.required,
                                            message: value.message || null,
                                        }],
                                        initialValue: value.initialValue,
                                        valuePropName: value.valuePropName || 'value'

                                    })
                                    (
                                        value.render ? value.render(value) :
                                            <Input rows={value.rows || 1} cols={value.col || 55}
                                                   onBlur={(e)=>this.handleOnBlur(e,value.key)}
                                                   onChange={()=>this.handleOnChange()}/>
                                    )}
                                </FormItem>
                            </Col>
                        )
                    })
                }
            </Row>)
    }

    renderStartDate = ()=>{
        const { getFieldDecorator } = this.props.form;
        const data  = this.props.curMed||{};
        let itemList = [[
                    {
                        key:'startDate',
                        label:'Start Date',
                        cols:9,
                        initialValue:data.startDate ? moment(data.startDate) : null,
                        render:()=><DatePicker />
                    },
                    {
                        key: 'otherMD',
                        label:'Other MD',
                        type:'checkbox',
                        cols:5,
                        valuePropName:'checked',
                        initialValue: data.otherMD,
                        required: false,
                        message: null,
                        render:()=><Checkbox />
                    },
                ]]
        return _.map(itemList,(arr,i) =>
            <Row key={i}>
                {
                    _.map(arr, (value, index) => {
                        return (
                            <Col span={value.cols} key={index}>
                                <FormItem
                                    label={value.label}
                                    key={value.key}
                                    style={{display: 'flex', flexDirection: 'column'}}
                                >
                                    {getFieldDecorator(value.key, {
                                        rules: [{
                                            required: value.required,
                                            message: value.message || null,
                                        }],
                                        initialValue: value.initialValue,
                                        valuePropName: value.valuePropName || 'value'

                                    })
                                    (
                                        value.render ? value.render(value) :
                                            <input rows={value.rows || 1} cols={value.col || 55}
                                                   onBlur={(e)=>this.handleOnBlur(e,value.key)}
                                                   onChange={()=>this.handleOnChange()}/>
                                    )}
                                </FormItem>
                            </Col>
                        )
                    })
                }
            </Row>)
    }

    renderInstruction(){
        const { getFieldDecorator } = this.props.form;
        const data  = this.props.curMed||{};
        const value =      {
            key: 'instruction',
            label: "Instruction",
            type: 'textarea',
            initialValue:data.instruction||'',
            className: 'textareaInput',
            rows:'2',
            size:'default',
            cols:24,
            render:(value)=><textarea rows={ value.rows || 1 } cols={ value.col || 60 }/>
        }
        return <Row>
                <Col span={value.cols}>
                    <FormItem
                        label={value.label}
                        key={value.key}
                        style={{display: 'flex', flexDirection: 'column'}}
                    >
                        {getFieldDecorator(value.key, {
                            rules: [{
                                required: value.required,
                                message: value.message || null,
                            }],
                            initialValue: value.initialValue,
                        })
                        (
                            value.render ? value.render(value) :
                                <input rows={value.rows || 1} cols={value.col || 55}/>
                        )}
                    </FormItem>
                </Col>
               </Row>
    }

    showDeleteConfirm() {
        Modal.confirm({
            title: 'Are you sure you want to delete this medication?',
            content:"Once it’s deleted, this medication will not show in the medication history.",
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex:2000,
            mask:true,
            className:'deleteMedModule',
            maskClosable:true,
            onOk:()=>{
               this.removeMed();
            }
        });
    }

    discontinueMed = () =>{
        const id = this.props.curMed.id;
        const query = this.props.modal.query;
        const patientId = this.props.patientId;
        const { setShowMedModal } = this.props;

        let variables = {
            id:id,
            memberId: patientId,
            endDate: new Date()
        }

        Client.mutate({
            mutation: query,
            variables: variables,
            refetchQueries:[{
                query:medicationList,
                variables:getVariables(this.props),
                fetchPolicy:'network-only'
            }]
        }).then((res)=>{
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'discontinueMedicine');
            setShowMedModal(false);
            message.success('Medication Discontinued');
        }).catch((error)=>{
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    handleClickEditMed = (showMedInTakingMsg)=>{
        const { setModal,setMedicine } = this.props;
        this.setState({
            showMedInTakingMsg: false,
        })
        setModal({type:'update'});
        setMedicine(showMedInTakingMsg);

    }

    renderHeader = ()=>{
        const data  = this.props.curMed||{};
        const { modal } = this.props;
        if(modal.type=='update') {
            return <h4>Edit Medication: { data.name}</h4>;
        }
        return <h4>Add Medication</h4>
    }

    renderFooter(showMedInTakingMsg) {
        const { modal } = this.props;
        let isUpdated = false;

        if(showMedInTakingMsg){
            return <Button onClick={()=>this.handleClickEditMed(showMedInTakingMsg) }>Edit Medicine</Button>
        }

        if(modal.type=='update') {
             isUpdated = this.props.status;
        }else{
            isUpdated = true;
        }
        const baseStyle = {
            margin:5,
            minWidth:100
        }

        let buttonList =  modal.type=='update' ? [
            {
                key: 'Remove',
                label: "Delete Medication",
                type: 'danger',
                size:'default',
                className:'remove',
                style:baseStyle,
                onClick:()=>{
                    this.showDeleteConfirm();
                }
            },
            {
                key: 'submit',
                label: "Save",
                type: 'primary',
                htmlType:"submit",
                className:"addMed",
                style:baseStyle,
                disabled:!isUpdated
            },
            {
                key: 'Discontinue',
                label: "Discontinue",
                type: 'danger',
                size:'default',
                className:'remove',
                style:baseStyle,
                onClick:()=>{
                    Modal.confirm({
                        title: 'Discontinue Medication?',
                        content: 'Are you sure you would like to Discontinue this Medication?',
                        okText: 'Yes',
                        okType: 'danger',
                        cancelText: 'No',
                        zIndex:2000,
                        mask:true,
                        className:'deleteMedModule',
                        maskClosable:true,
                        onOk:()=>{
                            this.discontinueMed();
                        }
                    });
                }
            }
        ] :
        [{
            key: 'submit',
            label: "Save",
            type: 'primary',
            htmlType:"submit",
            className:"addMed",
            style:baseStyle,
            disabled:!isUpdated
        }]

        return  buttonList.map((value,index)=>{
                return (
                    <FormItem
                        key={value.label}
                    >
                        <Button className={value.className || ''} disabled={value.disabled} onClick={value.onClick}
                                type={value.type} style={value.style} htmlType={value.htmlType}>{value.label}</Button>
                    </FormItem>
                )
        });
    }



    createMed(){
        const patientId = this.props.patientId;
        const formVariables  = this.props.form.getFieldsValue();
        const createMedication = this.props.modal.query;

        let variables = {
            memberId: patientId,
            ...formVariables,
        };
        Client.mutate({
            mutation: createMedication,
            variables: variables,
            refetchQueries:[{
                query:medicationList,
                variables:getVariables(this.props),
                fetchPolicy:'network-only'
            }]
        }).then((res)=>{
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'createMedicine');
            this.props.setShowMedModal(false);
            message.success('Medication added');
        }).catch((error)=>{
           this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    updateMed(){
        const patientId = this.props.patientId;
        const formVariables  = this.props.form.getFieldsValue();
        const query = this.props.modal.query;
        const id = this.props.curMed.id;
        const { name } = this.props.curMed;
        let variables = {
            id:id,
            memberId: patientId,
            ...formVariables,
            name
        };
        Client.mutate({
            mutation: query,
            variables: variables,
            refetchQueries:[{
                query:medicationList,
                variables:getVariables(this.props),
                fetchPolicy:'network-only'

            }]
        }).then((res)=>{
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'updateMedicine');
            message.success('Medication Updated');
            this.props.setShowMedModal(false);

        }).catch((error)=>{
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    removeMed=()=>{
        const id = this.props.curMed.id;
        const query = this.props.modal.query;
        const patientId = this.props.patientId;
        const { setShowMedModal } = this.props;

        let variables = {
            id:id,
            memberId: patientId,
            active:false
        }

        Client.mutate({
        mutation: query,
        variables: variables,
        refetchQueries:[{
            query:medicationList,
            variables:getVariables(this.props),
            fetchPolicy:'network-only'
        }]
        }).then((res)=>{
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'removeMedicine');
            setShowMedModal(false);
            message.success('Medication Removed');

        }).catch((error)=>{
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    onSubmit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((error)=>{
            if(!error){
               if(this.props.modal.type=='add'){
                   this.createMed();
               }
               if(this.props.modal.type=='update'){
                    this.updateMed();
               }
            }
        })
    }
    renderForm = (showMedAddedMsg,showAddedMedHistory,showMedInTakingMsg)=>{
        return  <Form onSubmit={this.onSubmit} style={{display:showAddedMedHistory ? 'none' : 'flex',flexDirection:'column'}} className='medicine_form'>
                    { this.renderHeader() }
                    { this.renderNameAndDosage(showMedInTakingMsg)}
                    {  showMedAddedMsg ? this.medAddedMsg() :'' }
                    { !showMedInTakingMsg ? this.renderRadioField() :'' }
                    { !showMedInTakingMsg ? this.renderStartDate() :'' }
                    {/*{ this.renderInputFields(showMedInTakingMsg,showMedAddedMsg) }*/}
                    { !showMedInTakingMsg ? this.renderRadioGroup() : null }
                    { !showMedInTakingMsg ? this.renderInstruction() : null }
                    {  showMedInTakingMsg ? this.renderMedTaking() : '' }
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                        { this.renderFooter(showMedInTakingMsg) }
                    </div>
                </Form>
    }

    render(){
        const { showMedAddedMsg,showAddedMedHistory,showMedInTakingMsg } = this.state;
        let displayName = this.constructor.displayName;
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const patientId = _.get(this.props,'patientId');
        const keepEventListener = true;

        return(
            <div style={{ width:500 }}>
                <TimerContainer displayName={displayName} category='MEDICINE' action='UPDATE'
                                viewingDetails={viewingDetails} patientId={patientId}
                                keepEventListener={keepEventListener}
                />
                { showAddedMedHistory ? this.renderAddedMedHistory() :'' }
                { this.renderForm(showMedAddedMsg,showAddedMedHistory,showMedInTakingMsg) }
            </div>
        )

    }
}
const withData = graphql(medNameList,{
    options:()=>{
        return{
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
        const { loading,medNameList } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            medNameList
        }
    }
})
function handleValueChange(props){
    if(props.modal.type=='update'){
        props.isUpdated(true);
    }
}

const mapToProps = (state)=>{
    return {
        ...state.medicineList.main,

    }
}
const mapToDispatch = (dispatch)=> {

    return {
       isUpdated:(isUpdated)=>dispatch(actions.isUpdated(isUpdated)),
       openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
       stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
       updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}

MedicineModalComponent = Form.create({onValuesChange:(props,changed,allValues)=>handleValueChange(props,changed,allValues)})(MedicineModalComponent);

export default compose(connect(mapToProps,mapToDispatch),withData)(MedicineModalComponent);
