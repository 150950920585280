import { React, BaseComponent, _, IHButton, IHTooltip, IHSearchTable,IHMessage } from 'ihcomponent'
import {FormatHelper} from 'libModule/utils'
import UserClass from 'modulesAll/graphql/class/User'
import {goPath} from 'libModule/utils'
import {Button, Tooltip,message, Modal, Input} from 'antd';
import I18N from 'modulesAll/I18N'
import * as R from 'ramda';
import { API } from 'modulesAll/chat/actions/api';
import MobilePhoneContainer from '../containers/MobilePhoneContainer';
import AdditionalInfoContainer from '../../AdditionalInfo/Containers/AdditionalInfoContainer';
import Client from 'libModule/gqlClient';
import deleteUser from 'modulesAll/graphql/deleteUser';
import {connect} from "react-redux";
import actions  from 'modulesAll/common/actions/modal'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'lib/utils';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const { TextArea } = Input;

const Component = class extends BaseComponent{
  static displayName = 'patient/profileDetail/components/Detail'

  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      showDeleteUserModal: false,
      id: this.props.patientId,
      disabled: true
    }
    this.onAvatarError = this.onAvatarError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderDeleteUserModal = this.renderDeleteUserModal.bind(this);
  }
  renderMobilePhoneList(currentUserId){
    return <MobilePhoneContainer userId={currentUserId}/>
  }

  renderAdditionInfo(currentUserId){
    const { route } = this.props;
    return <AdditionalInfoContainer patientId={this.props.patientId} userId={currentUserId} route={route}/>
  }

  renderDeleteUserModal() {
    const userProfile = this.props.data.profile;
    const name =  "Delete Patient: " + userProfile.fullName;
    return (
      <Modal
        title = {name}
        visible={this.state.showDeleteUserModal}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleSubmit} disabled={this.state.reason.trim() == '' ? true : false}>
            Submit
          </Button>,
        ]}
      >
      <Input.TextArea rows={6} 
                value={this.state.reason || ''} 
                onChange={e => {this.setState({reason: e.target.value})}}
                placeholder="Please state the reason of why you want to delete this patient..."/>
      </Modal>);
    }

  handleSubmit() {
    this.handleDeleteUser(this.state.id, this.state.reason);
    console.log(this.state.reason);
    console.warn('Submit');
  }

  handleCancel() {
    this.setState({
      reason: '',
      showDeleteUserModal: false
    });
  }

  render(){
    this.user = this.props.data;
    this.userObject = new UserClass(this.user);
    const currentUserId = atob(_.get(this.props.data, 'id')).split(':')[1];
    // 9/26/17 - JV - Hide caregivers per IH-65
    return (
      <div>
        <div className="v-patient-profileDetail">
          {this.renderBox1()}
          {/*{this.renderBox2()}*/}
          {/*{this.renderCareGiversTable()}*/}
          {this.renderBottomButton()}
          {/*{this.renderBottomButton1()}*/}
        </div>
        <div>
          {this.renderAdditionInfo(currentUserId)}
        </div>
          {/* {this.renderMobilePhoneList(currentUserId)} */}
          {this.renderDeleteUserModal()}
      </div>
    )
  }
  onAvatarError(userId) {
    API.getUserAvatar(userId)
      .then( res => {
        const userProfile = get(res.data.user, 'profile') || {};
        const currentUserId = atob(get(res.data.user, 'id')).split(':')[1];
        const userRole = res.data.user.allRoles.length > 0 ? res.data.user.allRoles[0].name : '';

        const newUser = {
          uuid: currentUserId,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          nickname: userProfile.nickname,
          profileType: userRole,
          avatar: R.isNil(userProfile.avatar) ? '/image/default_avator.png' : userProfile.avatar.link ? userProfile.avatar.link : '/image/default_avator.png'
        };

        this.props.addToUserMap(newUser);
      })
      .catch(err => console.log('onAvatarError, err: ', err));
  }
  renderBox1(){
    const healthConditionsArray = this.userObject.format('healthConditions');
    const userId = this.userObject.variables.id;

    return (
      <div className="v-box-1">
        <img src={this.userObject.format('avator')} onError={ () => this.onAvatarError(userId) } />
        <div>
          <p className="c-n1">
            {this.userObject._get('profile.fullNameWithNickname')}
            <span className="s1">({this.userObject.format('nric')})</span>
            <span className="s2 active">Active</span>
            {this.renderStar()}
          </p>
          <p className="c-n2">
            {
              _.map(this.userObject.format('tag'), (tag, i)=>{

                  if(i == 3){
                      return <span key={i} className="c-tag">Spoken Language: {tag}</span>
                  }else if(i == 4 && typeof(tag) == 'string' ){
                      return <span key={i} className="c-tag">App Language: {tag}</span>
                  }else{
                      return <span key={i} className="c-tag">{tag}</span>
                  }
              })
            }
          </p>
          { this.renderHealthConditions() }
          { this.renderInsuranceProvider() }
        </div>
      </div>
    )
  }

  renderHealthConditions() {
    const healthConditionsArray = this.userObject.format('healthConditions');
    return (
      <div style={{ lineHeight: 2, paddingTop: 6 }}>
        <span>Health Condition(s):   </span>
        {
          healthConditionsArray.length > 0 ?
            _.map(this.userObject.format('healthConditions'), (tag, index)=>{
              return (
                <span className="v-tag" key={index}>{tag}</span>
              )
            }) : I18N.get('keywords.N/A')
        }
      </div>
    )
  }

  renderInsuranceProvider(){
    const insuranceProvider = _.get(this.user,'profile.insuranceProvider','');
      return (
          <div style={{ lineHeight: 2, paddingTop: 6 }}>
              <span>Insurance Provider:</span>
              <span style={{fontSize:12}}>{insuranceProvider}</span>
          </div>
              )
  }
  renderBox2(){
    const emailIconButton = {
      icon : 'edit',
      shape : 'circle',
      size : 'small',
      style : {
        marginLeft : '10px'
      },
      onClick : this.props.editPatientEmail
    }
    const homePhoneIconButton = {
      icon : 'edit',
      shape : 'circle',
      size : 'small',
      style : {
        marginLeft : '14px'
      },
      onClick : this.props.editPatientHomePhone
    }
    const mobilePhoneIconButton = {
      icon : 'edit',
      shape : 'circle',
      size : 'small',
      style : {
        marginLeft : '10px'
      },
      onClick : this.props.editPatientMobilePhone
    }

    return (
      <div className="v-box-2">
        <p className="c-tlt">Contact Info</p>
        <div className="c-flex c-m">
          <div className="ea">
            <b>Address</b>
            <span>{this.userObject.format('address')}</span>
          </div>
          <div className="ea">
            <b>Email</b>
            <span>{this.userObject.format('email')}
              <Tooltip placement="rightTop" title="Click to edit email address">
                <Button {...emailIconButton} />
              </Tooltip>
            </span>
          </div>
          <div className="ea">
            <b>Contact</b>
            <span>Home {this.userObject.format('homePhone')}
              <Tooltip placement="rightTop" title="Click to edit home phone">
                <Button {...homePhoneIconButton} />
              </Tooltip>
            </span>
            <span>Mobile {this.userObject.format('mobilePhone')}
              <Tooltip placement="rightTop" title="Click to edit mobile phone">
                <Button {...mobilePhoneIconButton} />
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
    )
  }

  getCareGiversTableProps() {
    const list = this.user.caregiver || [];

    return {
      // actions : this.renderCreateButton(), // 9/25/17 - JV - Hide per IH-65
      rowKey: (caregiver, index) => `cg_${index}`,
      title: `Caregivers`,
      dataSource: list,
      columns: [
        {
          title: 'Name',
          key: 'fullName',
          width: '25%',
          render: (caregiver, index) => _.get(caregiver, 'profile.fullName')
        },
        {
          title: I18N.get('keywords.NRIC/FIN'),
          key: 'nric',
          width: '15%',
          render: (caregiver, index) => _.get(caregiver.identification[0], 'value')
        },
        {
          title: 'Email',
          key: 'email',
          width: '30%',
          render: (caregiver, index) => _.get(caregiver.emails[0], 'address') || ''
        },
        {
          title: 'Mobile Phone',
          key: 'mobile',
          width: '20%',
          render: (caregiver, index) => _.get(caregiver.phones[0], 'number') || ''
        },
        {
          title: 'Actions',
          key: 'actions',
          dataIndex: 'actions',
          render: (value, row, index) => {
            const editCaregiver = this.props.editPatientCareGiver
            const buttonProps = {
              label : 'Edit >',
              size : 'small',
              type : 'primary',
              onClick : (e) => editCaregiver(index),
              className: 'table-action-button'
            };
            return <IHButton {...buttonProps} />
          }
        }
      ]
    }
  }

  showConfirmForDeleteUser(id) {
      return new Promise((resolve,reject)=>{
          // const Yes = {
          //     label: 'Yes, Delete Patient',
          //     handler: ()=>{
          //         resolve(this.handleDeleteUser(id,reason));
          //     },
          //     style:{
          //         // border: 'solid 1px #aaaaaa',
          //         color:'white',
          //         background:'#f0666b',
          //         textShadow:'none'
          //     },
          //     closeModal: true
          // }
          // const No = {
          //     label: 'No',
          //     style:{
          //         border: 'solid 1px #4278c3',
          //         color:'#4278c3',
          //         background:'white',
          //         textShadow:'none'
          //     },
          //     handler: () => {
          //         resolve(false)
          //     }
          // }
          // const modalProps = {
          //     Yes,
          //     No,
          //     title: 'Delete Patient?',
          //     content: 'Are you sure you want to delete this user? This action can not be undone.'
          // }
          this.setState({showDeleteUserModal: true});
          this.renderDeleteUserModal();
          // this.props.openConfirmModal(modalProps)
      });
  }
  handleDeleteUser(patientId, reason){
    console.warn(patientId + '-----' + reason);
    Client.mutate({
        mutation:deleteUser,
        variables:{ 
          id: patientId,
          reason: {
            key: "MANUALINPUT",
            value: reason,
          },
        },
    }).then(()=>{
        goPath('/patients');
        message.success('Patient deleted successfully.');
      })
      .catch(e=>{
          console.log(e);
          this.props.openErrorModal(GQLHelper.formatError(e))
      })
  }

  renderCreateButton() {
    const addCaregiver = this.props.addPatientCareGiver
    const props = {
      type : 'primary',
      label : 'Add New Caregiver',
      style : {
        position : 'absolute',
        top : '8px',
        right : '12px'
      },
      onClick : (e) => {
        addCaregiver();
      },
      size : 'small'
    };
    return <IHButton {...props} />
  }

  renderCareGiversTable() {
    const cgProps = this.getCareGiversTableProps();
    const noDataElem = (
      <div style={{ height: '100px', paddingTop: '2em' }}>
        <p style={{ fontSize: '18px' }}>This patient has no Caregivers assigned.</p>
      </div>
    );

    return <IHSearchTable {...cgProps} noDataElem={noDataElem} style={{ marginTop: '2em'}} />
  }

  renderBottomButton1(){
    const b3 = {
      label : 'Done',
      size : 'large',
      type : 'primary',
      onClick : ()=>{
        goPath('/patients/'+this.props.patientId+'/enrolledprogram/default')
      }
    }

    return (
      <div className="v-bottom">
        <IHButton {...b3} />
      </div>
    )
  }

  renderBottomButton(){
    const b1 = {
      label : 'Edit Profile',
      size : 'large',
      onClick : ()=>{
        Mixpanel.track('clicked', 'edit profile', 'patient detail', {PATIENT_ID: this.props.patientId});
        goPath(`/patients/profile/${this.props.patientId}/edit`)
      }
    }
    const b2 = {
      label : 'Reimport Patient Information',
      size : 'large',
      onClick : ()=>{
        this.props.showReimportModal()
      }
    }
    const b3 = {
        label : 'Delete Patient',
        size : 'large',
        type:'danger',
        onClick : ()=>{
          const patientId = this.props.patientId;
          Mixpanel.track('clicked', 'delete', 'patient detail', {PATIENT_ID: patientId});
          this.showConfirmForDeleteUser(patientId);
        }
    }
    const b4 = {
      label : 'Resend Account Activation Email',
      size : 'large',
      type : 'primary',
      style : {
        position : 'absolute',
        left : 0,
        marginLeft : 0,
        display: 'none'
      },
      onClick : this.props.resendActivationEmail
    }
    return (
      <div className="v-bottom">
        <IHButton {...b3} />
        <IHButton {...b1} />
        {
          // <IHButton {...b2} />
          // To Fix: bring back the resend activation email button when backend supports the resendOnboardEmail API
          // <IHButton {...b4} />
        }
      </div>
    )
  }

  renderStar(){
    const star = this.userObject.format('star')

    if(star.status){
        const click = ()=>{
            this.props.showPatientStarModal(true, 'delete', this.props.data)
        }
        return (
            <IHTooltip overlayClassName="vsm-tooltip" title={star.reason || ''} placement="right">
                <a onClick={click} className="v-star v-y"><i className="fa fa-star" /></a>

            </IHTooltip>
        )
    }
    else{
        const click = ()=>{
            this.props.showPatientStarModal(true, 'add', this.props.data)
        }
        return (
            <IHTooltip overlayClassName="vsm-tooltip" title={`Click to tag patient`} placement="right">
                <span onClick={click} className="v-star v-n"><i className="fa fa-star-o" /></span>
            </IHTooltip>
        )
    }
  }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(actions.confirm(props)),
    }
}

export default connect(null,mapToDispatch)(Component);
