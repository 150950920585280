import { connect } from 'react-redux';
import { SendIcon } from '../icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Client from 'libModule/gqlClient';
import actions from "../actions";
import * as timerActions from "../../timer/actions";
import UploadImgComponent from '../../upload/components/UploadImgComponent';
import InputAreaWithImageComponent from './InputAreaWithImageComponent';
import helpers from '../../upload/API/helpers';
import API from '../../upload/API/index';
import { Input,Modal,message,Progress } from 'antd';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import getDeviceHub from '../../patient/AdditionalInfo/query/getDeviceHub';
import getIoTDevice from '../../patient/DevicesAssign/query/getIoTDevice';
import { GQLHelper } from 'libModule/utils';

const { updateInterventionDetails,startChat,startTimer } = timerActions.default;
const { TextArea } = Input;
const getBase64 = helpers.getBase64;

class ChatInputComponent extends Component {
  static displayName = 'chat/components/ChatInputComponent';

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.state = {
        fileList:[ ],
        sendingImg: false,
        processingImg: false,
        processImgProgress: 0,
        previewImage: null,
        message: '',
        isNotAppUser: false,
        previewVisible:false,
        progress: 0,
        showUploadModal: false,
        fileKey:null,

    }
  }

  onKeyUp(e) {
    if (e.keyCode === 13) {
      this.onSubmit(e);
    }
  }

  removeImg = () =>{
    this.setState({
        fileList:[],
        previewImage: null,
        previewVisible: false
    })
  }

  beforeUpload = async (file)=>{
        const fileType= file.name.split('.')[1];
        const isLt10M = file.size / 1024 / 1024 < 10;

        if (!isLt10M) {
            Modal.error({
                content: 'Image must be smaller than 10MB',
            });
            return;
        }

        if(fileType.toLowerCase() =='jpeg'){
            Modal.error({
                content: 'jpeg format is not allowed',
            });
            return;
        }
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file);
        }
        const newFileList = new Array();
        newFileList.push(file);
        this.setState({
            fileList: newFileList,
            showUploadModal: true
        },()=>{
            Mixpanel.track('selected','image','message');
        });

  }

  componentDidMount() {
    // add prompt for hub users, CDE don't want to message nonApp users
    this.checkIsAppUser()
  }

  componentDidUpdate(preProps) {
      const preSelectedChannel = _.get(preProps, 'selectedChannel');
      const curSelectedChannel = _.get(this, 'props.selectedChannel');

      if (preSelectedChannel != curSelectedChannel) {
          this.setState({
              previewImage: null,
              fileList: []
          })
          // add prompt for hub users, CDE don't want to message nonApp users
          // update each time when switch channels
          this.checkIsAppUser()
      }
  }

  startImgProcessTimer = ()=>{
      const { processingImg,processImgProgress } = this.state;

          if(processingImg) {
              this.setState({
                  processImgProgress: processImgProgress + 0.5
              },()=>{
                  setTimeout(this.startImgProcessTimer,1000);
              })
          };

  }

  renderModal =  ()=>{
      const { showUploadModal,previewImage,progress,fileList,processingImg,processImgProgress } = this.state;
      const { props:{ userId,displayName} } = this;
      const userIdParsed = displayName.split(':')[1];
      const file = fileList[0];

      const handleOk = async ()=>{
          try {
              const fileKey = await this.saveImage({ userId:userIdParsed });
              this.setState({
                  processingImg: true,
              },()=> {
                  Mixpanel.track('uploaded','image','message');
                  this.startImgProcessTimer();
                  API.getFileUrl({fileKey, userId, type: 'THUMBNAIL'}).then((res)=> {

                      this.setState({
                          message: '',
                          previewVisible: false,
                          fileList: [],
                          showUploadModal: false,
                          fileKey,
                          progress: 0,
                          sendingImg: false,
                          processingImg: false,
                          previewImage: file.url || file.preview,
                          processImgProgress: 0,
                      });

                      const link  = _.get(res,'data.getSignedFileDownload.link');
                      if(!link) {
                          Modal.error({ content:'Process takes longer than expected.'})
                      }
                  });
              });
          }catch(e){
              Modal.error({ content : e });
              this.setState({
                  message:'',
                  previewVisible: false,
                  fileList: [],
                  showUploadModal:false,
                  previewImage: file.url || file.preview,
                  progress: 0,
                  processingImg: false,
                  processImgProgress: 0,
                  sendingImg: false
              });

              return;
          }
      }

      const handleCancel = ()=>{
          this.setState({
              previewVisible: false,
              fileList: [],
              showUploadModal: false,
              previewImage: false,
              fileKey:null,
              progress: 0
          })
      }

      return showUploadModal ? <Modal visible={showUploadModal} onOk={ handleOk } onCancel={ handleCancel } maskClosable={false} okText={'Upload'} className='imageUploadModal'>
                <img src={_.get(file,'url',_.get(file,'preview',''))} width={'100%'}/>
                {
                    progress&&progress!=1 ? <div style={{ display:'flex',flexDirection:'row',marginTop:20,fontSize:14 }} className='uploadingBar'>
                                                <span style={{ marginRight: 10 }}>Uploading:</span> <Progress percent={progress*100} showInfo={false}/>
                                            </div> :''
                }
                {
                    processingImg ? <div style={{ display:'flex',flexDirection:'row',marginTop:20,fontSize:14 }} className='processingBar'>
                                        <span style={{ marginRight: 10 }}>Processing: </span><Progress showInfo={false} percent={processImgProgress*10}/>
                                    </div>:''
                }
             </Modal> :'';
  }

  saveImage = async ({userId})=>{

      const { fileList } = this.state;
      const file = fileList[0];
      const filename = _.get(file,'name');

      if(!file){
          return;
      }
      this.setState({
          sendingImg: true
      });
      let res = {};
      try{
          res = await API.getPresignedFileUpload({ filename,userId });
      }
      catch(e){
          console.log(e);
          throw(e);
      }
      const ctx = {};
      const fields = _.isString(res.fields) ? JSON.parse(res.fields) : res.fields;
      const fileExtension = filename.split('.')[1];
      const  mimeType = fileExtension ? ( `image/${_.toLower(fileExtension)}`) : 'image/jpg';
      fields['Content-Type'] = mimeType;
      ctx.fields = fields;
      const uploadUrl = res.url;
      let fileKey = {};

      try {
          fileKey = await API.sendAJaxReq({ uploadUrl, filename, mimeType, fields, file,setProgress:this.setProgress });
      }catch(e){
          console.log(e);
          throw(e);
      }

      return fileKey
  }


  async onSubmit(e) {
    e.preventDefault();

    const { props:{ chatStarted,startChat,updateInterventionDetails,displayName,selectedChannel,sendMessage,userId } } = this;
    const { previewImage } = this.state;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const { message,fileKey } = this.state;
    // const userIdParsed = displayName.split(':')[1];

    if (message.trim().length === 0&&!previewImage) {
          return;
    }
    // let fileKey = '';
    // try{
    //     fileKey = await this.saveImage({userId:userIdParsed});
    // }
    // catch(e){
    //     Modal.error({ content : e });
    //     this.setState({
    //         message:'',
    //         previewImage: null,
    //         previewVisible: false,
    //         fileList: []
    //     });
    //     return;
    // }
    // for push notifications on mobile
    const pn_apns = {
        aps: {
          alert: 'You have a new message!',
          // ‘summary_for_mobile’: ‘You have a new chat message...’,
        },
        pn_bundle_ids: [
          'com.ihealthlabs.sharecare',
          'com.ihealthlabs.sharecarebp'
        ]
    };

    // Google Cloud Messaging / Firebase Cloud Messaging
    const pn_gcm = {
        data: {
          message: 'You have a new message!',
          // ‘summary_for_mobile’: [ ‘You have a new chat message...’ ]
        }
    };

    let msgObj = {
        type: 'text',
        text: message ? `${message}` : 'Uploaded a file',
        publisher: userId,
        displayName: currentUser.profile.fullName,
        userRole: _.get(currentUser,'selectedRole.name') || 'Unknown',
        // userRole: currentUser.allRoles.length > 0 ? currentUser.allRoles[0].name : 'Unknown',
        // refactored from allRole[0] to selectedRole(IH-1052)
        pn_apns,
        pn_gcm
    };

    if(fileKey){
        msgObj.type = 'fileUpload';
        msgObj.fileKey = fileKey;
    }
    sendMessage(selectedChannel, msgObj);

    this.setState({
        message:'',
        previewImage: null,
        previewVisible: false,
        fileList: [],
        progress: 0,
        fileKey: null,
        sendingImg: false
    })

    if(!chatStarted) {
          const apiName = 'pubnub.publish';
          const resource = 'teams';
          const docId = btoa(selectedChannel.split('-')[0]);
          const summary = 'pubnub send message to channel ' + selectedChannel;
          startChat(displayName);
          updateInterventionDetails(displayName, apiName, resource, docId, summary);
    }

    // this.refs.txtMessage.value = '';
    // this.refs.txtMessage.focus();
  }

  onClickImg = ()=>{
     this.setState({
         previewVisible: true
     })
  }

  setProgress = (progress)=>{
      this.setState({
          progress
      })
  }
  showSendButton  = ({ bottom = {} })=>{
      const { message, previewImage,sendingImg,progress } = this.state;
      const hasInput = message.length >0 || previewImage;

      return <button className="send-icon" style={{ bottom }}>
               { !sendingImg ? <SendIcon width={20} height={20} hasInput={hasInput}/> : '' }
             </button>
  }

  showImgModal = ()=>{
    const { previewVisible, previewImage } = this.state;

    return  <Modal visible={previewVisible}
                   footer={null}
                   className='imageUploadModal'
                   onCancel={()=>this.setState({
                        previewVisible: false
                   })}>
                <img src={previewImage} width={'100%'}/>
           </Modal>;
  }

  showAttachButton = (state,{ bottom = { } })=>{
      const { beforeUpload, removeImg } = this;
      const { sendingImg } = this.state;
      const { fileList } = state;
      return  !sendingImg ? <UploadImgComponent beforeUpload={ beforeUpload }
                                 removeImg={ removeImg }
                                 fileList={fileList}
                                 bottom = { bottom }
                           /> :''

  }

  checkIsAppUser = async () => {
    const { props:{ selectedChannel } } = this;
    const memberId = btoa(selectedChannel.split('-')[1]);
    // console.log('------memeberId:', memberId);
    let hubResult;
    try {
      hubResult = await Client.query({
        query: getDeviceHub,
        variables: { memberId },
        fetchPolicy: 'network-only'
      });
    } catch (err) {
        console.error('---error', err);
    }
    let deviceResult;
    try {
      deviceResult = await Client.query({
        query: getIoTDevice,
        variables: { memberId },
        fetchPolicy: 'network-only'
      });
    } catch (err) {
        console.error('---error', err);
    }

    const deviceHub = _.get(hubResult, 'data.getDeviceHub');
    const iotDevice = _.get(deviceResult, 'data.getIoTDevice');
    if (deviceHub) {
        //here is a hub assigned to this patient.
        this.setState({
            isNotAppUser: true
        })
    } else if (!_.isEmpty(iotDevice)) {
        //There is a Blood Pressure device assigned to this patient.
        this.setState({
            isNotAppUser: true
        })
    } else {
        this.setState({
            isNotAppUser: false
        })
    }
  }

  render() {
    const { previewImage,message,fileList, isNotAppUser } = this.state;
    const file = fileList[0];
    const placeholder = isNotAppUser ? "This patient doesn’t use our app, so you cannot use Messages to contact the patient. Just let you know. Thanks." : "Type message"
    return (
      <footer className="message-form">
        <form className='form-container' onSubmit={ this.onSubmit }>
            <div className='inputBox-container'>
              <TextArea
                     className={ !file ? "inputBox bottomBorder": "inputBox"}
                     type="text"
                     value = { message}
                     id="message-to-send"
                     placeholder={placeholder}
                     autosize = {{ minRows:3,maxRows:10 }}
                     onChange = {e =>this.setState({
                         message: e.target.value
                     })}
                     onPressEnter={this.onSubmit}
              />
              { this.showImgModal() }
              { this.renderModal() }
              { !previewImage ? this.showAttachButton(this.state,{ bottom:20 }) :'' }
              { !previewImage ? this.showSendButton({ bottom:20 }) :'' }
              { previewImage ? <InputAreaWithImageComponent src = {previewImage} removeImg={ this.removeImg } width={85} height={85} onClickImg={ this.onClickImg }/> : ''}
              { previewImage ? this.showAttachButton(this.state,{ bottom:20 }) :'' }
              { previewImage ? this.showSendButton({ bottom:20 }) :'' }
            </div>
        </form>
      </footer>
    );
  }
}

const mapState = ({ chat }) => {
  return {
    selectedChannel: chat.main.selectedChannel
  }
};

const mapDispatch = (dispatch) => {
    return {
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
        startChat:(displayName)=>dispatch(startChat(displayName)),

    }
}
ChatInputComponent.propTypes = {
  userId: PropTypes.string,
  sendMessage: PropTypes.func,
  selectedChannel: PropTypes.string
};

export default connect(mapState, mapDispatch)(ChatInputComponent);
