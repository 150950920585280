import { browserHistory } from 'react-router'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import logoutQL from 'modulesAll/graphql/logout'
import Client from 'libModule/gqlClient';
import {RequestCache} from 'libModule/utils'
import createAccountEventHistory from "../../graphql/mutation/createAccountEventHistory";

const apiLogout = async (token) => {
    browserHistory.push('/logout');

    await Client.mutate({
      mutation: logoutQL,
      variables: { token }
    })
  await Client.cache.reset();
};

export const logout = (event) => {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('authToken');
    if(token) {
        Client.mutate({
            mutation:createAccountEventHistory,
            variables:{
                event:event
            }
        });
        apiLogout(token)
        .catch(e => {
          console.log('apiLogout, e: ', e);
        })
        .finally( () => {
          //either logout success or fail , force user to login page
          // browserHistory.push('/logout');

          sessionStorage.clear();
          browserHistory.push('/login');
          RequestCache.set('userAccess', undefined); //reset
          RequestCache.set('appRoles', undefined);
        });
    }
  }
};

export const autoLogout = (event) => {
  return (dispatch, getState) => {
    dispatch(logout(event));
    const errorMessage = 'You have been logged out due to inactivity.';
    dispatch(openErrorModal(errorMessage));
  }
};
