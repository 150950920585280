import gql from 'graphql-tag'
import {User} from './schema/User'
export default gql`
    query userList(
        $page: Int,
        $count: Int,
        $sort: UserListSort
        $filters: UserListFilters!
    ) {
        userList(
            page: $page,
            count: $count,
            filters: $filters,
            sort: $sort
        ) {
            data {
                ${User}
            }
            pageInfo {
                total
                lastPage
            }
        }
    }

`
