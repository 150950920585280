import React from 'react';
import WorkListComponent from '../component/workListComponent';
import { graphql, compose } from 'react-apollo'
import singleVisit from '../../graphql/signleVisit';

const  WorkListContainer  = class extends React.Component {
    render() {
        if(this.props.loading){
            return this.props.loading;
        }
        return <WorkListComponent {...this.props}/>
    }
}

const withData =  graphql(singleVisit,{
    options:(ownProps)=>{
        const { id } = ownProps;
        return {
            variables: {
                id
            },
            fetchPolicy:'network-only',
        }
    },
    props:({data})=>{
        const { loading, getVisit, refetch }  = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            visit: getVisit,
            refetch
        }
    }
})
export default compose(withData)(WorkListContainer);
