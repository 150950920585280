import React from "react";
import { Form,Table,Button,Icon,Tabs,Modal } from 'antd';
import { getMutateVariableInfo,getMutateVariableFamilyHistory,getMutateVariableLifeStyle,getMutateVariableMedicalHistory } from "../helpers/getMutateValueHelper";
import { basicInfoConfig,medicalHistoryConfig,liefStyleConfig,familyHistoryConfig} from "../constants/tableConfig";
import { renderForm, renderLoading } from '../helpers/index';
import { setAsyncRouteLeaveHook } from 'libModule/utils';
import { withRouter } from 'react-router'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const TabPane = Tabs.TabPane;

import '../../../patient/AdditionalInfo/styles/index.scss';
import HealthConditionPicker from '../Components/HealthConditionPickerComponent/HealthConditionPicker';
import RenderFormComponent from "./RenderFormComponent";
import PropTypes from "prop-types";
import RenderMetersComponent from "./RenderMetersComponent";
import RenderDeviceInfo from "./RenderDeviceInfo";
import RenderOrdersComponent from "./RenderOrdersComponent";
const keys = ['basicInfo','medicalHistory','lifeStyle','familyHistory','deviceInfo'];
const getValueFunMap = {
    'basicInfo':getMutateVariableInfo,
    'familyHistory':getMutateVariableFamilyHistory,
    'lifeStyle':getMutateVariableLifeStyle,
    'medicalHistory':getMutateVariableMedicalHistory
}
const titleArr = ['Personal information', 'Medical history', 'Lifestyle', 'Family history', 'Devices', 'Meters'];

class MainContainer extends React.Component{
    constructor(props){
        super(props);
        this.state={
            current:'0',
            ccmEnrolled:false,
            rpmEnrolled:false,
        }
    }

    // componentWillReceiveProps(nextProps){
    //     if(nextProps.user&&!this.state.isInited){
    //         this.setState({
    //             healthConditions:_.get(nextProps,'user.profile.healthConditions'),
    //             isInited:true
    //         })
    //     }
    // }

    componentWillMount(){
       // setAsyncRouteLeaveHook(this.context.router, this.props.route, this.routerWillLeave.bind(this))
    }

    getMutateValues(key){
        const { getFieldsValue } = this.props.form;
        const formValues = getFieldsValue();
        const parsedValue = getValueFunMap[key](this.props,formValues,this.state);

        return parsedValue;
    }

    routerWillLeave() {
        const { isEditMode,userId,form,handleEnableEditMode } = this.props;
        const { current } = this.state;
        const stopResetEditMode = true;
        //if the current tab is edit mode;return new Promise((resolve, reject) => {

    return new Promise((resolve,reject)=> {

        if(!isEditMode){
            resolve(true);
            return;
        }

        if(isEditMode) {
            Modal.confirm({
                title: 'You have unsaved changes on this patient profile, are you sure you want to continue without saving?',
                okText: `Yes, leave without saving`,
                onOk: () => {
                    resolve(true);
                },
                cancelText: `Cancel`,
                onCancel:()=>{
                   resolve(false);
                }
            })
        }
        })
    }

    onChange(activeKey){
        const { isEditMode,userId,form,handleEnableEditMode} = this.props;
        const { current } = this.state;

        //if the current tab is edit mode;
        if(isEditMode){
            Modal.confirm({
                title:'You have unsaved changes on this patient profile, are you sure you want to continue without saving?',
                okText: `Yes, leave without saving`,
                onOk:()=>{
                    this.setState({
                        current:activeKey,
                    })
                    handleEnableEditMode(false);
                },
                cancelText:`Cancel`,
                onCancel:()=>{
                    // this.setState({
                    //     current:activeKey,
                    // })
                    // handleEnableEditMode(false);
                }
            })
        }
        //if no change made change tab directly
        else{
            this.setState({
                current:activeKey,
            })
            handleEnableEditMode(false);
        }
    }
    // addHealthCondition=(value)=>{
    //     const { healthConditions } = this.state;
    //     if(healthConditions.indexOf(value)==-1) {
    //         this.setState({
    //             healthConditions: healthConditions.concat([value])
    //         },()=>this.props.handleEnableEditMode(true) )
    //     }
    // }
    //
    // removeHealthCondition=(index)=> {
    //     const healthConditions = this.state.healthConditions.slice();
    //
    //     this.setState({
    //         healthConditions: _.remove(healthConditions, function (_, i) {
    //             return i != index;
    //         })},()=>this.props.handleEnableEditMode(true))
    // }
    //
    // renderHealthConditionPicker=()=>{
    //     const placeHolderStyle={
    //         paddingTop: 20,
    //         paddingBottom: 10,
    //         marginTop: 20
    //     }
    //
    //     return <div>
    //         <div style={placeHolderStyle}><h4>Health Conditions:</h4></div>
    //         <div><label title="Heart Attack" style={{fontSize: 12}}>ICD Code Search</label></div>
    //         <HealthConditionPicker  addHealthCondition={ this.addHealthCondition }/>
    //     </div>
    // }
    //
    // renderHealthConditionTable() {
    //     const { healthConditions } = this.state;
    //     const healthConditionsSource = _.map(healthConditions, (value) => (
    //         {
    //             name: value.split('::')[0],
    //             code: value.split('::')[1],
    //             value: value
    //         }))
    //     const tableColumn = [
    //         {
    //             key: 'code',
    //             title: 'Code',
    //             dataIndex: 'code',
    //             width:'20%'
    //         },
    //         {
    //             key: 'name',
    //             title: 'Name',
    //             dataIndex: 'name'
    //         },
    //         {
    //             key: 'remove',
    //             render: (text, record, index) => <Button onClick={() => this.removeHealthCondition(index)}><Icon
    //                 type="close"/></Button>
    //         }
    //     ]
    //
    //     return <div style={{padding: '20px 10px 0 10px'}}>
    //             <Table dataSource={ healthConditionsSource }
    //                columns={ tableColumn }
    //                rowKey={(key) => key.code}
    //                pagination={false}
    //             />
    //            </div>
    // }

    renderTabs(){
        const { current } = this.state;
        const { userId, save, loading, form, user, phoneInfoList} = this.props;

        const tabs = [
            {
                title: 'Personal information',
                content:loading ? renderLoading() : <RenderFormComponent user={user} form={form} config={basicInfoConfig()} handleEnableEditMode={this.props.handleEnableEditMode}/>,
                key:'0',
            },
            {
                title: 'Medical history' ,
                content:loading ? renderLoading() : <RenderFormComponent user={user} form={form} config={medicalHistoryConfig} renderPicker={true} handleEnableEditMode={this.props.handleEnableEditMode}/>,
                key:'1',
            },
            {
                title: 'Lifestyle',
                content:loading ? renderLoading() : <RenderFormComponent user={user} form={form} config={liefStyleConfig} handleEnableEditMode={this.props.handleEnableEditMode}/>,
                key:'2',
            },
            {
                title: 'Family history',
                content:loading ? renderLoading() : <RenderFormComponent user={user} form={form} config={familyHistoryConfig} handleEnableEditMode={this.props.handleEnableEditMode}/>,
                key:'3',
            },
            {
                title: 'Devices',
                content:loading ? renderLoading() : <RenderDeviceInfo user={user} phoneInfoList={phoneInfoList}/>,
                key:'4',
            },
            {
                title: 'Meters',
                content: loading ? renderLoading() : <RenderMetersComponent user={user} phoneInfoList={phoneInfoList}/>,
                key: '5',
            },
            {
                title: 'Orders',
                content: loading ? renderLoading() : <RenderOrdersComponent user={user} phoneInfoList={phoneInfoList}/>,
                key: '6',
            }
        ];

        return <Tabs defaultActiveKey='0' type='card' activeKey={current} 
                    onChange={(activeKey)=> {
                        Mixpanel.track('clicked', titleArr[activeKey], 'patient detail', {PATIENT_ID: this.props.userId})
                        this.onChange(activeKey)}}>
                {
                    tabs.map((item,index) => <TabPane key={`${index}`} tab={item.title} forceRender={false}>{current==item.key&&item.content}</TabPane>)
                }
              </Tabs>
    }

    handleOnClick(){
        const { current } = this.state;
        const { userId,form } = this.props;
        const  parsedValue = this.getMutateValues(keys[current]);
        form.validateFieldsAndScroll((error,fields)=>{
            if(!error) {
                this.props.save(parsedValue);
            }
        })
        // this.props.save(parsedValue,userId)
    }

    renderSaveButton(){
        const { isEditMode } = this.props;
        return <div style={{display:'flex',justifyContent:'center',marginTop:20}}>
                 <Button type='primary' onClick={()=>this.handleOnClick()} disabled={!isEditMode}>
                    Save
                 </Button>
               </div>
    }

    renderSteps(){
        let curKey = this.state.current;
        if(curKey == "4" || curKey == '5' || curKey == '6') {
            return (
                <div style={{padding:50,display:'flex',border:'1px solid #d9d9d9',borderRadius:4,margin:'20px 0px 20px',background:'white',flexDirection:'column'}} className='additionInfoTabs'>
                    { this.renderTabs() }
                </div>
            );
        } else {
            return (
                <div style={{padding:50,display:'flex',border:'1px solid #d9d9d9',borderRadius:4,margin:'20px 0px 20px',background:'white',flexDirection:'column'}} className='additionInfoTabs'>
                    { this.renderTabs() }
                    { this.renderSaveButton() }
                </div>
            );
        }
    }

    render(){
        return <div>
                { this.renderSteps() }
               </div>
    }
}

MainContainer.contextTypes = {
    router: PropTypes.object.isRequired
}

MainContainer = Form.create({ onFieldsChange:function(props,fields){
            if(!props.isEditMode) {
                props.handleEnableEditMode(true);
            }
        }
    })(MainContainer);

export default withRouter(MainContainer);