import React from 'react';
import ImageRenderComponent from '../../upload/components/ImageRenderComponent';

export default class extends React.Component {
    constructor(){
        super();
    }

    render(){
        const { props } =  this;
        return <div className='hasImage'>
                <ImageRenderComponent {...props} />

               </div>
    }
}
