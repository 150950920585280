const types = {
    'INIT':{
        text:'Initial Visit',
        borderColor:'#36c5cc',
        backgroundColor:'rgba(196, 252, 255, 0.24)'
    },
    "FOLLOW_UP2":{
        text:'Initial / Follow Up Visit',
        borderColor:'#4278c3',
        backgroundColor:'rgba(181, 214, 255, 0.24)'
    },
    "FOLLOW_UP1":{
        text:'Initial / Follow Up Visit',
        borderColor:'#4278c3',
        backgroundColor:'rgba(181, 214, 255, 0.24)'
    },
    "FOLLOW_UP3":{
        text:'Initial / Follow Up Visit',
        borderColor:'#4278c3',
        backgroundColor:'rgba(181, 214, 255, 0.24)'
    },
    "FOLLOW_UP":{
        text:'Initial / Follow Up Visit',
        borderColor:'#4278c3',
        backgroundColor:'rgba(181, 214, 255, 0.24)'
    },
    'ANNUAL':{
        text:'Annual',
        borderColor:'#fdb543',
        backgroundColor:'rgb(255, 225, 178,0.24)'

    },
    'ADDITIONAL':{
        text:'Additional Visit',
        borderColor:'#ee7c81',
        backgroundColor:'rgba(255, 204, 204, 0.24)'
    },


}

export default types;
