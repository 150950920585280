import gql from 'graphql-tag'
import {EnrolledProgram} from 'graphqlModule/schema/EnrolledProgram'

export default gql`
  mutation editEnrolledProgram(
    $id: EID!
    $tasks: [InputTaskEnrolled]
    $careCoordination: String
    $followupSchedule: String
    $problemList: String
    $assessments: String
    $interventions: String
    $goals: String
    $resource: String
    $manualInputEnable: Boolean
    $manualInputEnabledTaskTypes: [TaskType]
    $deviceInputEnabledTaskTypes: [TaskType]
  ){
    editEnrolledProgram(
      id: $id
      tasks: $tasks
      careCoordination: $careCoordination
      followupSchedule: $followupSchedule
      problemList: $problemList
      assessments: $assessments
      interventions: $interventions
      goals: $goals
      resource: $resource
      manualInputEnable: $manualInputEnable
      manualInputEnabledTaskTypes: $manualInputEnabledTaskTypes
      deviceInputEnabledTaskTypes: $deviceInputEnabledTaskTypes
    ){
      ${EnrolledProgram}
    }
  }
`
