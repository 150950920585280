import gql from "graphql-tag";

export default gql `
mutation editVisit ($id:EID!,$type:VisitTypeEnum,$appointmentAt:Date,$appointmentTo:Date,$providers:[EID],
                    $reason:String,$programIds:[EID],$checkinAt:Date,$checkoutAt:Date,$deviceRecordCount:InputUserDeviceRecordCount,
                    $height:HTValue,$weight:HSValue,$waistHipRatio:Float,$bgMeasurement:VisitBgMeasureInput,$bpMeasurement:VisitBpMeasureInput
                    $eyeExam: VisitTaskStatus,$doctorSession: VisitTaskStatus,$cdeSession: VisitTaskStatus,$technicalSetup: VisitTaskStatus
                    $footExam: VisitTaskStatus,
                    $urineTest: VisitTaskStatus
                    $mpTest:VisitTaskStatus,
                    $a1cTest:Float,
                    $shouldSeeDoctor:Boolean,
                    $visitRoles:[String],
                    $addToContactsStatus: VisitTaskStatus,
                    $autoUpdateStatus: VisitTaskStatus,
                    $appIsUptoDate: VisitTaskStatus,
                    $EMRAttached: Boolean,
                    $userDeviceRecords: InputUserDeviceRecords
                    $conditionsList:InputConditionsListType,
                    $userLanguage:Language,
                    $userAppLanguage: Language,
                    $userTechLevel: TechLevel,
                    $A1C:InputA1CObject,
                    $needInHouseA1C: Boolean
                    $associatedVisitId:EID
                    ){
    editVisit(id:$id,type:$type,appointmentAt:$appointmentAt,appointmentTo:$appointmentTo,providerIds:$providers,reason:$reason,associatedVisitId:$associatedVisitId,
              programIds:$programIds,checkinAt:$checkinAt,checkoutAt:$checkoutAt,shouldSeeDoctor:$shouldSeeDoctor,visitRoles:$visitRoles,deviceRecordCount:$deviceRecordCount,
              conditionsList:$conditionsList,
              details:{addToContactsStatus:$addToContactsStatus, EMRAttached: $EMRAttached,
                autoUpdateStatus:$autoUpdateStatus,
                appIsUptoDate:$appIsUptoDate,
                height:$height,weight:$weight,waistHipRatio:$waistHipRatio,bgMeasurement:$bgMeasurement,bpMeasurement:$bpMeasurement,
              eyeExam:$eyeExam,footExam:$footExam,mpTest:$mpTest,urineTest:$urineTest,doctorSession:$doctorSession,cdeSession:$cdeSession,technicalSetup:$technicalSetup,
              a1cTest:$a1cTest
              },userDeviceRecords:$userDeviceRecords,userLanguage:$userLanguage, userAppLanguage:$userAppLanguage, userTechLevel:$userTechLevel,A1C:$A1C, needInHouseA1C: $needInHouseA1C){
        id
        reason
        type
        shouldSeeDoctor
        appointmentAt
        visitRoles
        appointmentTo
        checkinAt
        checkoutAt
        member{
            id
            profile{
                fullName    
                ...on MemberProfile{
                     deviceRecords{
                        Lancets
                        Strips
                        Ease
                        Align
                        Lina
                    }
                }

            }
        }
        providers{
            id
            profile{
            
                fullName
            }
        }
        createdAt
        programs{
            id
            name
        }
        providers{
            profile{
                fullName
            }
        }
        vitalTypes
        tasks{
            key
            value
          }
        labTests{
            id
            createdAt
			updatedAt
            dateCollected
            templateId
            templateName
            results{
                name
                value
                unit
                isInHouse
            }
        }    
        confirm
        details{
            doctorSession
            cdeSession
            technicalSetup
            eyeExam
            footExam
            urineTest
            mpTest
            height
            weight
            waistHipRatio
            addToContactsStatus
            autoUpdateStatus
            appIsUptoDate
            bgMeasurement{
              blood_glucose
                beforeMeal
              mealType
            }
            EMRAttached
            a1cTest
            bpMeasurement{
              systolic_blood_pressure
              diastolic_blood_pressure
              heart_rate
            }
        } 
    }
}`

