import gql from 'graphql-tag'
import WeakUserAuthentication from './schema/WeakUserAuthentication'
export default gql `
  mutation loginWithUsernameOrEmail(
    $usernameOrEmail: String!,
    $password: String!
  ) {
    loginWithUsernameOrEmail(
      usernameOrEmail: $usernameOrEmail,
      password: $password
    ) {
      id
      sessionToken
      role {
        refId
        name
        category
        access {
          action
          rule
        }
        organization {
          id
          name
          leaf
        }
      }
      lastLogIn{
        createdAt
      }
      allRoles {
        refId
        name
        category
        organization {
          id
          name
        }
      }
      appSettings {
        role {
          refId
          name
          category
        }
      }
    }
  }
`
