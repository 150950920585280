import React from 'react';
import { Icon } from 'antd';

const icon = status =>
  status == 'COMPLETED' ? (
    <img src={'/image/icon_check.png'} width={20} />
  ) : (
    <img src={'/image/Oval.png'} width={20} />
  );
{
  /*<Icon type={status == 'COMPLETED' ? "check-circle" : 'question-circle'} style={{fontSize: 15,color:'#00D580'}} theme={'filled'}/> } ;*/
}
const mainIcon = (status, no) =>
  status ? (
    <img src={`/image/icon_check.png`} width={20} />
  ) : (
    <img src={`/image/icon_${no}_green.png`} width={25} />
  );
{
  /*<Icon type={status ? "check-circle" : 'question-circle'} style={{fontSize: 20,color:'#00D580'}}/>;*/
}
const MAWorkListMap = localThis => ({
  title: 'MA Worklist',
  direction: 'vertical',
  steps: [
    {
      title: 'CA WorkList',
      key: 'CA WorkList',
      className: 'mainStep',
      icon: status => mainIcon(status, '1'),
      onClick: () => localThis.changeShowMAWorkingList()
    },
    {
      title: 'On Board',
      key: 'On Board',
      className: 'substep',
      icon: status => icon(status),
      onClick: e => localThis.onChangeMAStep(e)
    },
    {
      title: 'Lab Results',
      key: 'Lab Results',
      className: 'substep',
      icon: status => icon(status),
      onClick: e => localThis.onChangeMAStep(e)
    },
    {
      title: 'Vitals&Billing',
      key: 'Vitals&Billing',
      className: 'substep',
      icon: status => icon(status),
      onClick: e => localThis.onChangeMAStep(e)
    },
    {
      title: 'Medication',
      key: 'Medication',
      className: 'substep',
      icon: status => icon(status),
      onClick: e => localThis.onChangeMAStep(e)
    },
    {
      title: 'Follow-up Visit',
      key: 'Follow-up Visit',
      className: 'substep',
      icon: status => icon(status),
      onClick: e => localThis.onChangeMAStep(e)
    }
  ]
});

const RDWorkingList = localThis => ({
  title: 'RD Worklist',
  direction: 'vertical',
  steps: [
    {
      title: 'RD WorkList',
      key: 'RD WorkList',
      className: 'mainStep',
      icon: status => mainIcon(status, '2'),
      onClick: () => localThis.changeShowRDWorkingList()
    },
    {
      title: 'Assessment',
      key: 'Assessment',
      className: 'substep',
      icon: status => icon(status),
      onClick: e => localThis.onChangeMAStep(e)
    },
    {
      title: 'Goal statement',
      key: 'Goal statement',
      className: 'substep',
      icon: status => icon(status),
      onClick: e => localThis.onChangeMAStep(e)
    },
    {
      title: 'Charting',
      key: 'Charting',
      className: 'substep',
      icon: status => icon(status),
      onClick: e => localThis.onChangeMAStep(e)
    }
  ]
});

export default {
  MAWorkListMap,
  RDWorkingList
};
