import React, { Component } from 'react';
import {Col, Row, Button, Form} from "antd";

const { Item } = Form;

class VisitInputBottomButton extends Component{

    render() {
        const {editStatus, disableSaveButton} = this.props;
        if(editStatus){
            return (
                <Row>
                    <Col span={4} offset={16}>
                        <Item>
                            <Button type="primary" htmlType="submit" disabled={disableSaveButton}>Save</Button>
                        </Item>
                    </Col>
                    <Col span={4}>
                        <Item>
                            <Button onClick={this.props.handleDelete} >Delete</Button>
                        </Item>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row>
                    <Col span={3} offset={21}>
                        <Item>
                            <Button type="primary" htmlType="submit">Create</Button>
                        </Item>
                    </Col>
                </Row>
            )
        }
    }

}

export default VisitInputBottomButton;
