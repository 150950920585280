import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { moment,IHLoading} from "../../../../../package/IHComponent";
import AddFoodLogNotes from '../../foodLog/components/AddFoodLogNotes';
import { connect } from 'react-redux'
import I18N from 'modulesAll/I18N'
import get from 'lodash/get'
import * as R from 'ramda';
import { Divider,Button } from 'antd';
import getFoodLog from 'graphqlModule/getFoodLog';
import { API } from '../../chat/actions/api';
import actions from "../../foodLog/actions";
import Client from 'libModule/gqlClient';
import { Rate } from 'antd';
import { onAddClick } from "../../patient/alertTable/actions/AlertTableActions";
import { openModal,closeModal } from 'layoutModule/actions/MainModal'
import  { DATE_HELPERS  } from '../../../lib/helpers/date-helpers';
// import fetchData from '../components/SingleMeasureComponent';
import { getResultNotes } from 'modulesAll/graphql/result'
import BGMeasurementComponent from './Measurement/components/BGMeasurement';
import BGMeasurementsComponent from './Measurement/components/BGMeasurements';
import BPMeasurementComponent from './Measurement/components/BPMeasurement';
import BPMeasurementsComponent from './Measurement/components/BPMeasurements';
import FileUploadMessageComponent from './FileUpload/FileUploadMessageComponent';
import chatActions from '../actions/index';
import FoodlogView from './FoodLog/FoodlogView'
import EmptyChannelComponent from './EmptyChannelComponent';
import hideMessageType from '../constants/hideMessageType';
const timeFormatString = I18N.get('dates.L');
const timestampFormat = I18N.get('dates.LTS');
const foodLogType = ['foodReview','foodUpload' ];
const openLink = 'openLink';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';

const measureTypeMap = {
    'ONE_MONTH':{
        title:'1-Month Blood Sugar Data',
        path:'/image/1-month-icon.svg'
    },
    'TWO_WEEK':{
        title:'2-Week Blood Sugar Data',
        path:'/image/2-weeks-icon.svg'
    },
    'measurement':{
        title:'Single Blood Sugar Data.'
    }
}

const severityMap = {
    'Critical':{
        text:'CRITICAL',
        color:'rgb(255,100,100)'
    },
    'Normal':{
        text:'NORMAL',
        color:'rgb(253,181,67)'
    },
    'High':{
        text:'HIGH',
        color:'rgb(54,204,141)'
    }
}

class ChatHistoryComponent extends Component {

static displayName = 'chat/components/ChatHistoryComponent';

constructor(props) {
  super(props);
  this.messagesEnd = React.createRef();
  this.scrollToTop = this.scrollToTop.bind(this);
  this.scrollToBottom = this.scrollToBottom.bind(this);
  this.fetchMoreMessages = this.fetchMoreMessages.bind(this);
  // this.getAvatar = this.getAvatar.bind(this);
  // this.onAvatarError = this.onAvatarError.bind(this);
  this.state = {
      renderSignleMeasure:false
  }

}

renderDateSpe(flag,dateHeader){
  if(flag)
  return(
      <div className='messageTimestamp'>
          <Divider className='chatDateDivider'>
            <header>{ dateHeader }</header>
          </Divider>
      </div>
  )
}
scrollToBottom() {

    if(this.messagesEnd&&this.messagesEnd.current) {
        this.messagesEnd.current.scrollIntoView({behavior: 'instant'});
    }
}
scrollToTop() {
  const { refs, fetchMoreMessages } = this;
  const scrollTop = refs.messageList.scrollTop;
  if (scrollTop === 0) {
    fetchMoreMessages();
  }
}

fetchMoreMessages() {
  const { fetchHistory, selectedChannel, channels } = this.props;
  if (selectedChannel) {
    Mixpanel.track('clicked','load_more_message','message', {CHANNEL_ID: selectedChannel});
    this.scrollToHeight = this.refs.messageList.scrollHeight;
    
    this.shouldScrollToView = true;
    this.scrollToViewHeight = this.refs.messageList.scrollHeight;
    this.scrollToViewScroll = this.refs.messageList.scrollTop;
    
    // this.scrollToViewTimestamp = this.refs.messageList.querySelector('.collection-item-container').getAttribute('data-timetoken');
    fetchHistory(selectedChannel, channels[selectedChannel].patientId, channels[selectedChannel].lastMessageTimestamp,false, false);
  }
}

//removed so 'load more' will stay on top
componentDidUpdate(prevProps) {
  const { props: { selectedChannel, channels, moveToBottom, toBottom }, refs } = this;
  // const notPreToBottomAndNotCurToBottom = !prevProps.toBottom&&!toBottom;
  //move to bottom if init channel and move to bottom if channel fetch again;
  if (toBottom){
    this.scrollToBottom();
    moveToBottom(false);
  }

  if (this.shouldScrollToView) {

    const scrollHeight = this.refs.messageList.scrollHeight;
    if (scrollHeight !== this.scrollToViewHeight) {
      this.refs.messageList.scrollTop = this.scrollToViewScroll + scrollHeight - this.scrollToViewHeight;
    }
  }
}

componentDidMount(){
    this.scrollToBottom();
}

getAvatar(userId, userRole) {

  if (userRole.toLowerCase() === 'admin'&&!userId) {
    return <img src='/image/systemmsg-chat-avtr.svg' className='avatar' onError={ (e) => this.onAvatarError(userId,e) } />
  }
  else if (this.props.userMap[userId] && this.props.userMap[userId].avatar) {
    return <img src={ this.props.userMap[userId].avatar } className='avatar' onError={ (e) => this.onAvatarError(userId,e) } />
  }
  else {
    return <img src='/image/default_avator.png' className='avatar' onError={ (e) => this.onAvatarError(userId,e) } />
  }
}

onAvatarError(id,e) {
  const encoded = btoa(`accounts:${id}`);
  API.getUserAvatar(encoded)
    .then( res => {

      const userProfile = get(res.data.user, 'profile') || {};
      const currentUserId = atob(get(res.data.user, 'id')).split(':')[1];
      const userRole = res.data.user.allRoles.length > 0 ? res.data.user.allRoles[0].name : '';

      const newUser = {
        uuid: currentUserId,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        profileType: userRole,
        avatar: _.get(userProfile,'avatar.link','/image/default_avator.png')
      };

      this.props.addToUserMap(newUser,true);
    })
    .catch(err => console.log('onAvatarError, err: ', err));
}


renderStar(startCount){
    return <Rate className='tableStar' value = {startCount} disabled/>;
}

renderMsg(classString,msgObj,ind){

    const { type,duration,dateFrom,dateTo,text,measuredAt,value,unit,severity,vitalName,diastolic,systolic,arrhythmia, fileKey,foodLogId } = msgObj.entry;

    switch(vitalName) {

        case 'BG': {
            if (type == 'measurements') {

                return <BGMeasurementsComponent dateFrom={dateFrom} dateTo={dateTo}
                                                text={text} duration={duration}/>

            }
            if (type == 'measurement') {
                return <BGMeasurementComponent measuredAt={measuredAt} type={type}
                                               value={value} unit={unit} text={text}
                                               type={type} severity={severity}/>

            }
        }
        case 'BP':{
            if (type == 'measurements') {

                return <BPMeasurementsComponent dateFrom={dateFrom} dateTo={dateTo}
                                                text={text} duration={duration} />

            }
            if (type == 'measurement') {
                return <BPMeasurementComponent measuredAt={measuredAt} type={type}
                                               unit={unit} text={text}
                                               type={type} severity={severity}
                                               diastolic={diastolic} systolic={systolic}
                                               arrhythmia={arrhythmia}
                />

            }
        }
    }

    if (type === 'fileUpload') {
      return <FileUploadMessageComponent key={ind}
        text={text} fileKey={fileKey} userId={btoa(`accounts:${get(msgObj, 'entry.publisher')}`)} />
    }

    if(type === 'commentFoodLog'){
      return <FoodlogView  foodLogId={foodLogId} text={text}/>
    }
    return <li className={classString}>
            {msgObj.entry ? msgObj.entry.text : null}
           </li>


}

renderMessageBody(msgObj,ind,self,isNewDay,dateHeader,timeStamp, gender,timetoken){
    let classString = "collection-item-avatar".concat(self ? ' self':'');
    const userRole = msgObj.entry.userRole ? msgObj.entry.userRole : 'UnknownRole';
    const userId = msgObj.entry.publisher;

   return (
        <div className='collection-item-container before' key={ind} id={ind} data-timetoken={timetoken}>
            <div style={{width:'100%'}}>
                {this.renderDateSpe(isNewDay,dateHeader)}
                <div className='messageItemContainer'>
                  { this.getAvatar(userId, userRole) }
                    <div>
                        <span style={{'fontWeight': 'bold'}}>
                              { msgObj.entry.displayName ? msgObj.entry.displayName : 'Unknown' }
                            <span className='userRole'> { userRole === 'admin' ? '' : ` - ${_.upperFirst(_.toLower(userRole))}` }</span>
                        </span>
                        <span className='timeStamp'>{timeStamp}</span>
                        {this.renderMsg(classString,msgObj,ind)}
                    </div>
                </div>
            </div>
        </div>
    )
}

renderLoadingChatInfoSpinner = ()=> <IHLoading/>

render() {
  const { selectedChannel, channels, userId, userMap,isLoading,chatWithIndividual,loadingChatInfo } = this.props;
  let history =selectedChannel && channels[selectedChannel] ? channels[selectedChannel].history : [];
  const hasMoreMessage = _.get(selectedChannel && channels[selectedChannel],'hasMoreMessage');
  const renderSpin = isLoading ? <IHLoading/> :'';
  const renderHasMoreMessageButton = !isLoading && hasMoreMessage ?
                                    <div>
                                        <Divider className='chatDivider'>
                                            <Button type="solid"  onClick={()=>this.fetchMoreMessages()}>Load More Message</Button>
                                        </Divider>
                                    </div> :'';

    //resort messages to fix message disorder
    history = _.uniqBy(history,'timetoken');
    history.sort((a,b)=>a.timetoken-b.timetoken);
    let preDate = new Date(0,0,0,0);
    if(loadingChatInfo){
        return this.renderLoadingChatInfoSpinner();
    }
    return (
    <ul ref='messageList' className={`chat-history-collection`}>
        { renderHasMoreMessageButton }
        { renderSpin }
        {
          history && history.length > 0 ?
              history.map((msgObj, ind) => {
                  const type = _.get(msgObj,'entry.type','');
                  if(msgObj && msgObj.entry.text&&!hideMessageType[type]) {
                      const userRole = msgObj.entry.userRole;
                      const isToday = moment(msgObj.timetoken / 1e4).format(timeFormatString) == moment(new Date()).format(timeFormatString);
                      const isNewDay = moment(preDate).format(timeFormatString) != moment(new Date(msgObj.timetoken / 1e4)).format(timeFormatString);
                      preDate = new Date(msgObj.timetoken / 1e4);
                      const dateHeader = isToday ? 'Today' : moment(preDate).format(timeFormatString);
                      const timeStamp = moment(preDate).format(timestampFormat);
                      const isPatient = userRole === 'patient';
                      const gender = userMap[msgObj.entry.publisher] ? userMap[msgObj.entry.publisher].gender : '';

                      return this.renderMessageBody(msgObj, ind, isPatient, isNewDay, dateHeader, timeStamp, gender, msgObj.timetoken);
                  }})
          :<EmptyChannelComponent />
        }
        <div style={{ float:"left", clear: "both" }} ref={this.messagesEnd}></div>

    </ul>
  );

}
}

const mapToDispatch = (dispatch)=>{

    return {
        setRating: (id,rating)=>dispatch(actions.setRating(id,rating)),
        updateComment:(id,comments)=>dispatch(actions.updateComment(id,comments)),
        reset: (id)=>dispatch(actions.reset(id)),
        resetInitList:()=>dispatch(actions.resetInitList()),
        openModal: (content, row) => dispatch(openModal(content, row)),
        closeModal:()=>dispatch(closeModal()),
        onAddClick:(row)=>dispatch(onAddClick(row)),
        moveToBottom:(val)=>dispatch(chatActions.moveToBottom(val))

    }
}

const mapState = ({ chat }, ownProps) => {
  return {
    selectedChannel: chat.main.selectedChannel,
    channels: chat.main.channels,
    userMap: chat.main.userMap,
    toBottom: chat.main.toBottom
  }
};

ChatHistoryComponent.propTypes = {
  userId: PropTypes.string,
  history: PropTypes.array,
  selectedChannel:PropTypes.string
};

export default connect(mapState, mapToDispatch)(ChatHistoryComponent);
