import  React from 'react';
import { Form } from 'antd';
import { Steps,Row,Col,Radio,DatePicker,Checkbox,Input,Button,AutoComplete } from 'antd';

import Client from 'libModule/gqlClient';
import medTypes from "../../medicine/constants/medTypes";
import moment from "moment/moment";
import getVariables from "../../medicine/query/variables";
import createMedication from "../../graphql/mutation/createMedication";
import medicationList  from  'modulesAll/graphql/medicationList';
const FormItem = Form.Item;
const fieldName = ['name','dosage','type','startDate','otherMD','status']
let AddMedComponent = class extends React.Component {
    createMed(){
        const { resetFields,validateFields } = this.props.form;
        const patientId = this.props.patientId;
        const formVariables  = this.props.form.getFieldsValue();

        let variables = {
            memberId: patientId,
            ...formVariables,
        };
        validateFields((err,res)=> {
            if(res) {
                Client.mutate({
                    mutation: createMedication,
                    variables: variables,
                    refetchQueries: [{
                        query: medicationList,
                        variables: getVariables(this.props),
                        fetchPolicy: 'network-only'
                    }]
                }).then(() => {
                    resetFields();
                })
            }
        })
    }
    renderRadioField() {
        const {getFieldDecorator} = this.props.form;
        const itemList =
            [{
                key: 'type',
                label: "Medication Type",
                type: 'radioButton',
                className: 'radio-button add_medicine_header',
                required: true,
                initialValue:'ORAL'
            }]
        return itemList.map((value) => {
            return (
                <FormItem
                    label={value.label}
                    key={value.label}
                    className='radio_option'
                >
                    {
                        getFieldDecorator(value.key, {
                            rules: [
                                {required: value.required, message: 'Please Select Medicine Type'},
                            ],
                            initialValue: value.initialValue
                        })
                        (
                            <Radio.Group>
                                {
                                    _.map(medTypes, ({name, value}, index) => <Radio value={value} key={index}>{name}</Radio>)
                                }
                            </Radio.Group>
                        )
                    }
                </FormItem>
            )
        });
    }
    renderRadioGroup(){
        const { getFieldDecorator,setFieldsValue } = this.props.form;
        const data = this.props.curMed||{};
        const itemList =
            [
                {
                    key: 'TAKING',
                    label:'Taking',
                    type:'checkbox',
                    cols:5,
                    valuePropName:'checked',
                },
                {
                    key: 'NOT_SURE',
                    label:'Not Sure',
                    type:'checkbox',
                    cols:5,
                    valuePropName:'checked',
                },
                {
                    key: 'NOT_TAKING',
                    label:'Not Taking',
                    type:'checkbox',
                    cols:5,
                    valuePropName:'checked',
                },

            ];

        return  <Row style={{ display:'flex' }}>
            <FormItem
                label={'Status'}
                className='radio_option'
            >
                {
                    getFieldDecorator('status',{
                        initialValue: data.statu||'TAKING',
                    })
                    (
                        <Radio.Group>
                            { _.map(itemList,(value,index)=> <Radio value={value.key} key={index}>{value.label}</Radio>) }
                        </Radio.Group>
                    )
                }
            </FormItem>
        </Row>
    }
    renderStartDate = ()=>{
        const { getFieldDecorator } = this.props.form;
        const data  = this.props.curMed||{};
        let itemList = [[
            {
                key:'startDate',
                label:'Start Date',
                span:12,
                gutter:12,
                initialValue:data.startDate ? moment(data.startDate) : null,
                render:()=><DatePicker />
            },
            {
                key: 'instruction',
                label: "Instruction",
                type: 'textarea',
                className: 'textareaInput',
                span:12,
                gutter:12,
            }
        ]]
        return _.map(itemList,(arr,i) =>
            <Row key={i} gutter={12}>
                {
                    _.map(arr, (value, index) => {
                        return (
                            <Col span={value.span} key={index}>
                                <FormItem
                                    label={value.label}
                                    key={value.key}
                                >
                                    {getFieldDecorator(value.key, {
                                        rules: [{
                                            required: value.required,
                                            message: value.message || null,
                                        }],
                                        initialValue: value.initialValue,
                                        valuePropName: value.valuePropName || 'value'

                                    })
                                    (
                                        value.render ? value.render(value) :
                                            <Input rows={value.rows || 1} cols={value.col || 55} />
                                    )}
                                </FormItem>
                            </Col>
                        )
                    })
                }
            </Row>)
    }


    renderOtherMD=()=> {
        const { getFieldDecorator } = this.props.form;
        const value = {
                        key: 'otherMD',
                        label: '',
                        type: 'checkbox',
                        valuePropName: 'checked',
                        required: false,
                        render: () => <Checkbox>Other MD</Checkbox>
                      };

        return <FormItem label={value.label}>
                    {
                        getFieldDecorator(value.key, {
                        rules: [{
                            required: value.required,
                            message: value.message || null,
                        }],
                    })
                    (
                        value.render ? value.render(value) :
                            <Input />
                    )}
               </FormItem>
    }

    renderNameAndDosage = ()=> {
        const { getFieldDecorator } = this.props.form;
        const { medNameList } = this.props;
        const itemList = [[
            {
                key: 'name',
                label: "Medication Name",
                className: 'textareaInput',
                type: 'text',
                initialValue:'',
                required:true,
                span:12,
                render:()=><AutoComplete dataSource={_.uniq(medNameList)} filterOption={(inputValue, option) =>
                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }/>,
                // render:()=><Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>{
                //         _.map(_.uniq(medNameList), (v,index) => <Select.Option value={_.replace(v,`"`,'')} key={v}>{v}</Select.Option>)
                //     }
                // </Select>
            },
            {
                key: 'dosage',
                label: "Dosage",
                type: 'textarea',
                initialValue: '',
                className: 'textareaInput',
                size:'default',
                required:true,
                message:'Please enter dosage',
                span:12
            }
        ]];
        return _.map(itemList,(arr,i) =>
            <Row key={i} style={{display:'flex'}} gutter={12}>
                {
                    _.map(arr, (value, index) => {
                        return (
                            <Col span={value.span} key={index}>
                                <FormItem
                                    label={value.label}
                                    key={value.key}
                                >
                                    {getFieldDecorator(value.key, {
                                        rules: [{
                                            required: value.required,
                                            message: value.message || null,
                                        }],
                                        initialValue: value.initialValue,
                                        valuePropName: value.valuePropName || 'value'
                                    })
                                    (
                                        value.render ? value.render(value) :
                                            <Input />
                                    )}
                                </FormItem>
                            </Col>
                        )
                    })
                }
            </Row>)
    }
    render(){
        return <Form>
                 <Row gutter={12}>
                     <Col span={12}>{ this.renderNameAndDosage() }</Col>
                     <Col span={12}>{ this.renderRadioField() }</Col>
                 </Row>
                 <Row gutter={12} style={{display:'flex'}}>
                     <Col span={12}>{ this.renderStartDate() }</Col>
                     <Col span={9}>{ this.renderRadioGroup()}</Col>
                 </Row>
                 <Row><Col span={3}>{ this.renderOtherMD() }</Col></Row>
                 <Row>
                     <Col>
                         <Button onClick={ ()=>this.createMed() } type='primary' style={{ float:'right',marginBottom:10 }}>Add Medication</Button>
                     </Col>
                 </Row>
               </Form>
    }
}
AddMedComponent = Form.create()(AddMedComponent);

export default AddMedComponent;
