
import React from 'react'
import BaseComponent from '../BaseComponent'
import {Modal} from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types';

const Component = class extends BaseComponent{
    defineBaseClassName(){
        let cn = 'IH-Modal'
        const size = this.props.size
        if(size){
            cn += ` ih-${size}`
        }
        return cn
    }

    render(){

        const size = this.props.size,
            showModal = this.props.showModal || false
        let p = _.omit(this.props, ['size', 'showModal', 'body', 'footer', 'showHeaderCloseButton','className'])
        p.show = showModal
        if(_.find(['lg', 'large', 'sm', 'small', 'md'], size))
            p.bsSize = size

        const sc = this.getClassAndStyleProp()
        return (
            <Modal ref={refNode => { this.input = refNode }} {...p} {...sc}>
                {this.renderHeader()}
                {this.renderBody()}
                {this.renderFooter()}
            </Modal>
        )
    }

    renderHeader(){
        let hp = {}
        if(this.props.showHeaderCloseButton !== false){
            hp.closeButton = true
        }
        const title = this.props.title

        return (
            <Modal.Header {...hp}>
                {
                    _.isString(this.props.title)
                    ?
                    <Modal.Title>{title}</Modal.Title>
                    :
                    {title}
                }
            </Modal.Header>
        )
    }

    renderBody(){
        let p = {}
        if(!this.props.size){
            //p.style = {width : '450px'}
        }
        return (
            <Modal.Body {...p}>{this.props.body}</Modal.Body>
        )
    }
    renderFooter(){
        return (
            this.props.footer ? <Modal.Footer>{this.props.footer}</Modal.Footer> : ''
        )
    }

}

Component.propTypes = {
    size : PropTypes.string,
    showModal : PropTypes.bool,
    title : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    className : PropTypes.string,
    showHeaderCloseButton : PropTypes.bool,
    body  : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    footer : PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element
    ])
}

export default Component
