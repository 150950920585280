import {
    React, IHSelect, IHDropdown, _, IHTooltip, message
} from 'ihcomponent';
import { logout } from 'authModule/actions/CurrentUser'
import { goPath } from 'libModule/utils'
import '../css/master.scss';
import get from 'lodash/get'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { decryptRole } from 'libModule/utils';
import { API } from '../../chat/actions/api';
import * as R from 'ramda';
import Client from 'libModule/gqlClient';
import chooseLoginOrganization from 'modulesAll/graphql/mutation/chooseLoginOrganization';
import TaskAssignmentBannerContainer from '../../taskAssignment/containers/TaskAssignmentBannerContainer'
/*
content: '';
height: 10px;
width: 10px;
border-radius: 5px;
background: red;
color: red;
position: absolute;
top: 20px;
right: 655px;
z-index: 2;
*/

const UserArea = class extends React.Component{

    render(){
        const currentUser = this.props.currentUser;
        const role = get(currentUser, 'role')
        let itemList = null

        if (role && role.category === 'MEMBER') {
         itemList = [
            {
                title : 'Settings',
                id : 'settings'
            },
            {
                title : 'Terms of Use',
                id : 'terms_of_use',
                href : '/terms_patient.pdf'
            },
            {
                title : 'Logout',
                id : 'logout'
            }
          ]
        }
        else {
          itemList = [
            // {
            //     title : 'Terms of Use',
            //     id : 'terms_of_use',
            //     href : '/terms_provider.pdf'
            // },
            {
                title : 'Logout',
                id : 'logout'
            }
          ]
        }

        const p = {
            title : this.renderTitle(),
            className : 'v-user',
            itemList,
            onClick : (key)=>{
                if(key === 'logout'){
                    this.logout('LOGOUT')
                }
                else if (key === 'settings'){
                    goPath('/patient/settings')
                }
                // else if (key === 'terms_of_use'){
                //     createAuditLog({ action: I18N.get('auditLog.Navigation.termsOfUse') })
                //     const getPdfLink = _.find(itemList,['id', 'terms_of_use']).href
                //     window.open(getPdfLink)
                // }
            }
        }

        return (
            <IHDropdown {...p}  />
        )
    }
    onAvatarError(id) {
      API.getUserAvatar(id)
        .then( res => {
          const userProfile = get(res.data.user, 'profile') || {};
          const currentUserId = atob(get(res.data.user, 'id')).split(':')[1];
          const userRole = res.data.user.allRoles.length > 0 ? res.data.user.allRoles[0].name : '';

          const newUser = {
            uuid: currentUserId,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            profileType: userRole,
            avatar: R.isNil(userProfile.avatar) ? '/image/default_avator.png' : userProfile.avatar.link ? userProfile.avatar.link : '/image/default_avator.png'
          };

          this.props.addToUserMap(newUser);
        })
        .catch(err => console.log('onAvatarError, err: ', err));
    }

    renderTitle(){
        const userProfile = get(this.props.currentUser, 'profile') || {}
        const encodedUserId = get(this.props.currentUser, 'id');
        const currentUserId = atob(encodedUserId).split(':')[1];

        const roleName = decryptRole()
        const roleMap = {
          'MEMBER:Member': 'Patient',
          'PROVIDER:Nurse': 'Care Manager',
          'PROVIDER:Doctor': 'Doctor',
          'PROVIDER:Medical Assistant': 'Medical Assistant',
          'ADMIN:Admin': 'Admin',
          'SUPER_ADMIN:Super Admin': 'Super Admin',
          'OTHER:Vendor': 'Vendor'
        }
        const userName = `${userProfile.firstName || ''} ${userProfile.lastName || ''}`;
        const role = roleMap[roleName];

        return (
            <div className="v-ub">
                <img src={ _.get(this,`props.userMap[${currentUserId}]`) ? this.props.userMap[currentUserId].avatar
                         : '/image/default_avator.png' } onError={ () => this.onAvatarError(encodedUserId) } />
                <span><span style={{fontWeight:'bold'}}>{userName}</span> - { role }</span>
                <i className="fa fa-caret-down"></i>
            </div>
        )
    }

    logout(event){
        createAuditLog({ action: I18N.get('auditLog.logout') })
        this.props.dispatch(logout(event))
        this.props.resetState()
    }

}

const Nav = class extends React.Component{
    static displayName = 'layout/components/NavComponent'

    constructor(props) {
      super(props);
      this.renderRedDot = this.renderRedDot.bind(this);
    }
    componentWillMount() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
        this.props.setInstitution(this.currentUser.selectedRole.organization.id);

        const userProfile = get(this.currentUser, 'profile') || {}
        const currentUserId = atob(get(this.currentUser, 'id')).split(':')[1];
        const userRole = _.get(this.currentUser,'selectedRole.name') || '';
        const newUser = {
          uuid: currentUserId,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          profileType: userRole,
          avatar: R.isNil(userProfile.avatar) ? '/image/default_avator.png' : userProfile.avatar.link ? userProfile.avatar.link : '/image/default_avator.png'
        };

        this.props.addToUserMap(newUser);
    }

    render(){
        const p = this.props
        return (
            <div className="vsm-top-nav">
              <div className="v-box">
                {/*<IHTooltip title={p.breadCrumb || ''} placement="bottomLeft" overlay=''>*/}
                  {/*<p className="v-path" id="vsm-path-box">{p.breadCrumb}</p>*/}
                {/*</IHTooltip>*/}
                  {/*<TaskAssignmentBannerContainer />*/}
                  { this.renderDropBox() }
                  { this.renderUserBox() }
              </div>
            </div>
        )
    }
    createDropDownOptions() {
      const authAllRoles = JSON.parse(sessionStorage.getItem('authAllRoles'));

      return authAllRoles.map(val => {
        return {
          value: val.refId,
          name: val.organization.name,
          renderRedDot: this.renderRedDot(val.organization.id)
        }
      })
    }
    renderRedDot(val) {
      // const { props } = this;
      // const channelsArr = Object.keys(props.channels);
      //
      // const curOrg = atob(val).split(':')[1];
      // const selectedOrg = atob(props.institution).split(':')[1];
      // const btoaCurOg = btoa(curOrg);
      //
      // if (curOrg !== selectedOrg && Object.keys(props.orgTeamMap).length > 0 && props.orgTeamMap[btoaCurOg]) {
      //
      //   let finalChannelsArr = [];
      //
      //   // iterate through channels by team, omit selected org
      //   props.orgTeamMap[btoaCurOg].forEach(team => {
      //
      //     if (channelsArr.length > 0) {
      //
      //       // for ea team, get collection of channels in that team
      //       const channelsWithTeam = channelsArr.filter(ch => {
      //         return ch.split('-')[0] === team;
      //       });
      //
      //       finalChannelsArr = [...finalChannelsArr, ...channelsWithTeam];
      //     }
      //   });
      //
      //   // for ea channel, check if new messages
      //   const unreadMsgCh = finalChannelsArr.find( channel => props.channels[channel].counter > 0);
      //   return !!unreadMsgCh;
      // }
      // return false;
        const { OrgHasUnread } = this.props;
        return _.get(OrgHasUnread,`${btoa(atob(val).split(':')[1])}`,0);
    }
    renderDropBox(){
        const roleName = decryptRole()
        const selectedRole = JSON.parse(sessionStorage.getItem('currentUser')).selectedRole;
        const { OrgHasUnread } = this.props;
        const p = {
            option: this.createDropDownOptions(),
            // option : [
            //     {
            //         name : organizationName,
            //         value : organizationId
            //     }
            // ],
            optionParentClass: 'parent-select-option',
            optionLeftDiv: 'select-option-left',
            optionRightDiv: 'select-option-right',
            optionLeftDivContainer: 'select-option-left-container',
            value : selectedRole ? selectedRole.organization.name : null,
            // refactored from allRole[0] to selectedRole(IH-1052)
            onChange: async (v) => {
              const { institution } = this.props;
              const curOrg = atob(institution).split(':')[1];
              const nextOrg = atob(v).split('organizationId:')[1];

              if(curOrg ===  nextOrg) return;

              await Client.mutate({
                mutation: chooseLoginOrganization,
                variables: { ref: v },
              }).then(async res => {

                await this.props.handleChangeOrg(res.data, v);
              }).catch( error => {
                console.log('chooseLoginOrganization, error: ', error);
              })
              // this.setState({
              //   category: v,
              //   subCats: _.find(notes, ['category', v]).subCategories.map(n => ({ value: n, checked: false }))
              //   // note: ''
              // })
            },
        }
        const curOrgId = atob(_.get(selectedRole,'organization.id',':')).split(':')[1];
        //some unselected org
        let hasUnRead = false;
        _.forEach(OrgHasUnread,(v,i)=>{
            if(i!=curOrgId&&v[i]){
                hasUnRead = true;
            }
        })

        if (roleName === 'MEMBER:Member'
        || roleName === 'OTHER:Vendor') {
          return
        }
        else {
          const className = 'v-drop';
          return (
              <div className={className}>
                  <IHSelect {...p} />
              </div>
          )
        }
    }

    renderUserBox(){
        return (
            <UserArea {...this.props} currentUser={this.currentUser}/>
        )
    }
}


Nav.propTypes = {

}
export default Nav
