// Super Admin
import redirect from '../../helpers/auditTrailRedirect'

const menus = {
  AuditTrail: {
    id: 'audittrail',
    title: 'Audit Trail',
    icon: 'assessment',
    href: '/superadmin/audittrail',
    class:'audittrail'
    // onClick: redirect
  },
  UserTroubleshooting: {
    id: 'UserTroubleshooting',
    title: 'User Troubleshooting',
    icon: 'bell',
    href: '/superadmin/troubleshooting',
    class:'UserTroubleshooting'
  },
  AppAnalytics: {
    id: 'appanalytics',
    title: 'App Analytics',
    icon: 'bar-chart',
    href: '/superadmin/appanalytics',
    class:'appanalytics'
  },
  Organizations: {
    id: 'organizations',
    title: 'Organizations',
    icon: 'peopled',
    href: '/superadmin/organizations',
    class:'organizations'
  },
  Providers: {
    id: 'providers',
    title: 'Providers',
    icon: 'peopled',
    href: '/superadmin/providers',
    class:'providers'
  },
  SystemDynamicConfigs: {
    id: 'SystemDynamicConfigs',
    title: 'System Dynamic Configs',
    icon: 'assessment',
    href: '/superadmin/systemdynamicconfigs',
    class: 'SystemDynamicConfigs'
  }
  
};

const menusArray = Object.values(menus);

export default menusArray;

export const HOMEPAGE = '/superadmin/audittrail'
// export const HOMEPAGE = '/audit_trails'
