import React from "react";
import AppointmentMonthCalenderComponent from '../components/AppointmentMonthCalenderComponent';
import AppointmentWeekCalenderComponent from '../components/AppointmentWeekCalenderComponent';
import {compose, graphql} from "react-apollo/index";
import RescheduleAndCancelVisitComponent from '../components/RescheduleAndCancelVisitComponent';
import CreateVisitWithSearchPatientComponent from '../components/CreateVisitWithSearchPatientComponent';
import {message} from "../../../../../package/IHComponent";
import Client from 'libModule/gqlClient';
import editVisit from 'graphqlModule/mutation/editVisit';
import deleteVisitQuery from 'graphqlModule/mutation/deleteVisit';
import createVisitQuery from 'graphqlModule/mutation/createVisit';
import visitList from 'modulesAll/graphql/visitList';
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'lib/utils';
import {connect} from "react-redux";
import {API} from "../query";
import {goPath} from "../../../lib/utils";
import { API  as TimerAPI } from "../../timer/query";
import * as timerActions from '../../timer/actions/index';

import CreateVisitInAppointmentsContainer from "./CreateVisitInAppointmentsContainer";
import EditVisitInAppointmentsContainer from './EditVisitInAppointmentsContainer';

const { stopTimer,updateInterventionDetails } = timerActions.default;

const style = {
    weekCalenderStyle:{
        paddingLeft:15,
        paddingRight:15,
        display:'flex',
        flexDirection:'column',
        width:'60%'
    },
    dateStyle:{
        fontSize: 24,
        fontWeight: 600
    },
    headerStyle:{
        marginTop:25,
        marginBottom:25,
        marginLeft:20
    }
}
const IsValidJSONString = (str)=>{
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const AppointmentCalenderContainer = class extends React.Component {
    static displayName = ''
    constructor() {
        super();
    }

    renderMonthCalender(){
        const { props } = this;
        return <AppointmentMonthCalenderComponent {...props}/>
    }

    renderWeekCalender(){
        const { props } = this;
        return <AppointmentWeekCalenderComponent {...props}/>
    }

    componentDidMount(){
        const visitId = _.get(this,'props.routeParams.visitId',null);
        const patientId = _.get(this,'props.routeParams.patientId',null);
        const fullName = _.get(this,'props.routeParams.fullName',null);
        const birthday = _.get(this,'props.routeParams.birthday',null);

        if(visitId){
            API.getVisit(visitId)
                .then((res)=> {
                    const visit = _.get(res,'data.getVisit',null);
                    this.props.setSelectedVisit(visit);
                    this.props.enableEdit(true);
                })
                .catch((error)=>{
                    this.props.openErrorModal(GQLHelper.formatError(error));
                })
        }

        if(patientId&&fullName&&birthday){
            this.props.enableEdit(true)
            this.props.setSelectedVisit(`{ "id":"${patientId}","value":"${atob(fullName)}","birthday":"${birthday}"}`)
        }

        else if(patientId&&fullName){
            this.props.enableEdit(true)
            this.props.setSelectedVisit(`{ "id":"${patientId}","value":"${atob(fullName)}"}`)
        }
        else{
            this.props.setSelectedVisit({});
            this.props.enableEdit(false);
        }

    }

    scheduleVisit= (variables)=>{
        Client.mutate({
            mutation: createVisitQuery,
            variables: variables,
            fetchPolicy: 'no-cache',
            refetchQueries:[{
                query: visitList,
                variables: setFromAndToDate({},this.props),
                fetchPolicy: 'network-only'
            }]
        }).then((res)=>{
            this.props.setSearchName('');
            this.props.setSelectedVisit({});
            this.props.setIsCreate(true);
            this.props.enableEdit(false);
            goPath('/appointments');
            message.success('Appointment Scheduled');
        }).catch((error)=>{
            console.log(error);
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    saveChanges =  (variables)=>{
        const { selectedVisit } = this.props;
        Client.mutate({
            mutation: editVisit,
            variables: variables,
            fetchPolicy: 'no-cache',
            refetchQueries:[{
                query: visitList,
                variables: setFromAndToDate({},this.props),
                fetchPolicy: 'network-only'
            }]
        }).then((res)=>{
                const { editVisit } = res.data;
                this.props.setSelectedVisit(editVisit);

                const apiName = Object.keys(res.data)[0];
                const docId = res.data[apiName].id;
                const { memberId } = variables;
                TimerAPI.saveModalAndUpdateTimer(this.props,'RescheduleAndCancelVisitComponent',apiName,memberId,docId,'RescheduleVisit');
                this.props.enableEdit(false);
                this.props.setIsCreate(true);
                this.props.setSelectedVisit({});
                // this.props.setSelectedVisit(selectedVisit);
                message.success('Appointment Updated');
        }).catch((error)=>{
                this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    deleteVisit = (id)=>{
        const { setSelectedVisit } = this.props;
        let variables = {};
        Client.mutate({
            mutation: deleteVisitQuery,
            notifyOnNetworkStatusChange: true,
            variables: {id:id},
            refetchQueries:[{
                query: visitList,
                variables: setFromAndToDate(variables,this.props),
                fetchPolicy:'network-only'
            }]
        }).then(()=>{
                message.success('Patient Appointment canceled');
                this.props.setIsCreate(true);
                setSelectedVisit({});
        }).catch((error)=>{
                this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    renderFromEditToCreate = () =>{
        console.log(456);
        this.props.setIsCreate(true);
        this.props.setSelectedVisit({});
    }

    renderVisitFormComponent=()=>{
        const { props } = this;
        const { selectedVisit,setSearchName,searchName,isCreate,isEdit } = props;

        if((!isCreate)&&_.isEmpty(selectedVisit)&&(!isEdit)){
            return<div></div>;
        }

        if(isCreate && !selectedVisit.id){
            return <CreateVisitInAppointmentsContainer {...props} scheduleVisit={this.scheduleVisit}/>
            // return <CreateVisitWithSearchPatientComponent {...props} scheduleVisit={this.scheduleVisit} />
        }
        if(IsValidJSONString(selectedVisit)){
            console.log(2);
            return <CreateVisitWithSearchPatientComponent {...props} scheduleVisit={this.scheduleVisit} />
        }

        if(_.isString(selectedVisit)){
            return;
            // return <CreateVisitWithSearchPatientComponent {...props} scheduleVisit={this.scheduleVisit} />
        }
        else{
            return <EditVisitInAppointmentsContainer visitInfo={selectedVisit}
                                          {...props}
                                          isFromVisitTable = { true }
                                          renderFromEditToCreate={this.renderFromEditToCreate}
                                          saveChanges={this.saveChanges}
                                          deleteVisit={this.deleteVisit}/>
            // return <RescheduleAndCancelVisitComponent {...props} saveChanges={this.saveChanges} deleteVisit={this.deleteVisit}/>
        }


    }

    render() {
        return <div style={{display:'flex'}}>
                <div style={style.weekCalenderStyle}>
                    { this.renderWeekCalender() }
                </div>
                <div style={{width:'40%',background:'white'}}>
                    { this.renderMonthCalender() }
                    { this.renderVisitFormComponent() }
                </div>
              </div>

    }
}

const setFromAndToDate = (variables,props,str)=>{

    const { startOfWeek,endOfWeek } = props;
    variables.appointmentFrom  = Number(startOfWeek.toDate());
    variables.appointmentTo = Number(endOfWeek.toDate());
    variables.count = 99999;
    return variables;
}

const withData = graphql(visitList, {
    options: (ownProps) => {
        let  variables = { };
        return {
            variables: setFromAndToDate(variables,ownProps),
            fetchPolicy: 'network-only'
        };
    },
    props: ({_, data}) => {
        const { loading,visitList } = data;
        if(visitList&&visitList.data) {
            return {
                visitList,
                loading
            }
        }
        return {
            loading
        }
    }
})

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}

export default compose(connect(null,mapToDispatch),withData)(AppointmentCalenderContainer);

