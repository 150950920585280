import React from 'react';
import {
  Checkbox,
  Radio,
  InputNumber,
  Select,
  Input,
  DatePicker,
  Icon
} from 'antd';
const Option = Select.Option;
import moment from 'moment';
import { langOptions } from '../../../lib/constants';

const techLevel = [
  { value: 'HIGH', name: 'High' },
  { value: 'NORMAL', name: 'Normal' },
  { value: 'LOW', name: 'Low' }
];
const deviceNameMap = {
  Ease: `Ease (BP3L): App Sync`,
  Align: `Align (BG1): App Sync`,
  Lina: `Lina (HS2): App Sync`,
  iPhone: `iPhone`,
  TrackSet: 'TrackSet'
};
const onBoardFormMap = (
  localThis,
  hasBP,
  hasBG,
  hasHS,
  checkedBP,
  checkedBG,
  checkedHS,
  checkedTS,
  checkedIP
) => {
  // console.log('formmapTS ', checkedTS);
  // console.log('formmapIP ', checkedIP);
  const visitType = _.get(localThis, 'props.visit.type');
  const isInit = visitType == 'INIT';
  const emrRules = isInit
    ? [
        {
          validator: (rule, value, callback) =>
            value ? callback() : callback('Need to check to finish task.')
        }
      ]
    : [{ required: false }];
  return [
    [
      {
        key: 'easeCheckbox',
        label: '',
        render: () => (
          <Checkbox
            disabled={checkedTS}
            onChange={e =>
              localThis.props.form.setFieldsValue({
                easeAppSync: e.target.checked ? 'APP_SYNC' : 'MANUAL_INPUT'
              })
            }
          >
            Ease (BP3L)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        //path:'visit.member.profile.deviceRecords.Ease',
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: checkedTS ? false : checkedBP,
        valuePropName: 'checked'
      },
      {
        key: 'easeAppSync',
        label: '',
        // path:'visit.member.profile.deviceRecords.Ease',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='easeAppSync'
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  easeCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio disabled={checkedTS} value='APP_SYNC'>
                App Sync
              </Radio>
              <Radio disabled={checkedTS} value='MANUAL_INPUT'>
                Manual Input
              </Radio>
            </Radio.Group>
          );
        },
        initialData: checkedTS
          ? null
          : hasBP
          ? checkedBP
            ? 'APP_SYNC'
            : 'MANUAL_INPUT'
          : null,
        itemStyle: { display: 'flex' },
        span: 6
      }
    ],
    [
      {
        key: 'alignCheckbox',
        label: '',
        type: 'checkbox',
        render: () => (
          <Checkbox
            //disabled={!hasBG}
            onChange={e => {
              localThis.props.form.setFieldsValue({
                alignAppSync: e.target.checked ? 'APP_SYNC' : 'MANUAL_INPUT'
              });
              if (!e.target.checked) {
                localThis.props.form.setFieldsValue({
                  Lancets: 2,
                  Strips: 2
                });
              }
            }}
          >
            Align (BG1)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: checkedBG,
        // path:'visit.member.profile.deviceRecords.Align',
        valuePropName: 'checked'
      },
      {
        key: 'alignAppSync',
        label: '',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='alignAppSync'
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  alignCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio /*disabled={!hasBG}*/ value='APP_SYNC'>App Sync</Radio>
              <Radio /*disabled={!hasBG}*/ value='MANUAL_INPUT'>
                Manual Input
              </Radio>
            </Radio.Group>
          );
        },
        initialData: hasBG ? (checkedBG ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
        itemStyle: { display: 'flex' },
        span: 6
        // path:'visit.member.profile.deviceRecords.Align',
      },
      {
        key: 'Lancets',
        label: 'Lancets',
        className: 'numberInput',
        initialData: 2,
        path: 'visit.member.profile.deviceRecords.Lancets',
        render: () => {
          return (
            <InputNumber
              min={2}
              max={6}
              onChange={e => localThis.props.form.setFieldsValue({ Strips: e })}
            />
          );
        },
        itemStyle: {
          display:
            localThis.props.form.getFieldValue('alignAppSync') === 'APP_SYNC'
              ? 'flex'
              : 'none',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },
      {
        key: 'Strips',
        label: 'Strips',
        className: 'numberInput',
        path: 'visit.member.profile.deviceRecords.Strips',
        initialData: 2,
        render: () => {
          return (
            <InputNumber
              min={2}
              max={6}
              onChange={e =>
                localThis.props.form.setFieldsValue({ Lancets: e })
              }
            />
          );
        },
        itemStyle: {
          display:
            localThis.props.form.getFieldValue('alignAppSync') === 'APP_SYNC'
              ? 'flex'
              : 'none',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }
      }
    ],
    [
      {
        key: 'linaCheckbox',
        label: '',
        type: 'checkbox',
        render: () => (
          <Checkbox
            // disabled={!hasHS}
            onChange={e =>
              localThis.props.form.setFieldsValue({
                linaAppSync: e.target.checked ? 'APP_SYNC' : 'MANUAL_INPUT'
              })
            }
          >
            Lina (HS2)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        //changeToBoolean:(val) => val=='APP_SYNC',
        initialData: checkedHS,
        // path:'visit.member.profile.deviceRecords.Lina',
        valuePropName: 'checked'
      },
      {
        key: 'linaAppSync',
        label: '',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='linaAppSync'
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  linaCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio /*disabled={!hasHS}*/ value='APP_SYNC'>App Sync</Radio>
              <Radio /*disabled={!hasHS}*/ value='MANUAL_INPUT'>
                Manual Input
              </Radio>
            </Radio.Group>
          );
        },
        initialData: hasHS ? (checkedHS ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
        itemStyle: { display: 'flex' }
        // path:'visit.member.profile.deviceRecords.Lina',
      }
    ],
    [
      {
        key: 'trackSet',
        label: '',
        type: 'checkbox',
        render: onChange => (
          <Checkbox disabled={checkedIP} onChange={e => onChange(e)}>
            iHealth Clear
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        initialData: checkedTS,
        // path:'visit.member.profile.deviceRecords.TrackSet',
        valuePropName: 'checked'
      },
      {
        key: 'editTrackSet',
        label: '',
        render: openModal => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            <a
              onClick={e => {
                e.preventDefault();
                openModal();
              }}
            >
              Edit Device
            </a>
          </div>
        ),
        span: 6,
        itemStyle: { display: checkedTS ? 'flex' : 'none' }
      }
    ],
    [
      {
        key: 'iPhone',
        label: '',
        type: 'checkbox',
        render: onChange => (
          <Checkbox disabled={checkedTS} onChange={onChange}>
            iPhone
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        valuePropName: 'checked',
        initialData: checkedIP
        // path:'visit.member.profile.deviceRecords.iPhone',
      },
      {
        key: 'appVersion',
        label: '',
        render: appVersion => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            Version: {appVersion || 'N/A'}
          </div>
        ),
        span: 4,
        itemStyle: { display: checkedIP ? 'flex' : 'none' }
      },
      {
        key: 'unassignIPhone',
        label: '',
        render: action => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            <a
              onClick={e => {
                e.preventDefault();
                action();
              }}
            >
              Unassign device
            </a>
          </div>
        ),
        span: 3,
        itemStyle: { display: checkedIP ? 'flex' : 'none' }
      }
    ],
    // [
    //     {
    //         key:'title',
    //         label:'',
    //         renderOnly:true
    //     }
    // ],
    [
      {
        key: 'spokenLanguage',
        label: 'Spoken Language',
        initialData: 'EL',
        path: 'visit.member.profile.language.code',
        render: () => (
          <Select>
            {_.map(langOptions, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        span: 3
      },
      {
        key: 'appLanguage',
        label: 'App Language',
        initialData: 'EL',
        path: 'visit.member.profile.appLanguage.code',
        render: () => (
          <Select>
            {_.map(langOptions, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        span: 3
      },
      {
        key: 'techLevel',
        label: 'Tech Level',
        path: 'visit.member.profile.techLevel',
        render: () => (
          <Select>
            {_.map(techLevel, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        initialData: 'NORMAL',
        span: 3
      }
    ],
    [
      {
        key: 'a1c',
        label: 'A1C%',
        className: 'a1cInput',
        render: () => <Input disabled={true} />,
        // rules: [{ required: true, message: 'A1C Required' }],
        path: 'getLatestA1C.results.0.value',
        firstPath: 'visit.labResult.results.0.value',
        span: 6
      },
      {
        key: 'a1cDate',
        label: 'Collected Date',
        // rules: [{ required: true, message: 'A1C Date is Required' }],
        path: 'getLatestA1C.dateCollected',
        firstPath: 'visit.labResult.dateCollected',
        changeToBoolean: val => moment(val),
        render: () => <DatePicker disabled={true} />
      },
      {
        key: 'a1cId',
        label: '',
        firstPath: 'visit.labResult.id',
        path: 'getLatestA1C.id',
        render: () => <div></div>
      },
      {
        key: 'needInHouseA1c',
        label: '',
        path: 'needInHouseA1C',
        // itemStyle: {
        //   marginTop: 30,
        //   display: localThis.props.needInHouseA1C ? 'flex' : 'none'
        // },
        // type: 'checkbox',
        // initialData: localThis.props.needInHouseA1C ? true : false,
        // valuePropName: 'checked',
        render: () => {
          const firstPath = 'visit.labResult.dateCollected';
          const path = 'getLatestA1C.dateCollected';
          const dateInMS = _.get(
            localThis,
            `props.${firstPath}`,
            _.get(localThis, `props.${path}`)
          );
          const range = Date.now() - dateInMS;
          const needInHouseA1C = range > 30 * (1000 * 60 * 60 * 24);
          return needInHouseA1C ? (
            <div
              style={{
                marginTop: 30,
                display: needInHouseA1C ? 'flex' : 'none',
                alignItems: 'center',
                height: '100%',
                color: 'red'
              }}
            >
              Need in-house A1C
            </div>
          ) : (
            <div></div>
          );
        }
      }
    ],
    [
      {
        key: 'EMRAttached',
        label: '',
        render: () => (
          <Checkbox style={{ color: '#4278C3' }}>
            {`EMR (attach consent form)${isInit ? '*' : ''}`}
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        rules: emrRules,
        span: 8,
        firstPath: 'visit.member.profile.',
        path: 'visit.details.EMRAttached',
        valuePropName: 'checked'
      },
      {
        key: 'autoUpdateStatus',
        label: '',
        render: () => <Checkbox>Set up app auto update</Checkbox>,
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 8,
        changeToBoolean: val => val == 'COMPLETED',
        valuePropName: 'checked',
        path: 'visit.details.autoUpdateStatus'
      },
      {
        key: 'addToContactsStatus',
        label: '',
        render: () => <Checkbox>Add Care Team Number into Phone Book</Checkbox>,
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 8,
        changeToBoolean: val => val == 'COMPLETED',
        valuePropName: 'checked',
        path: 'visit.details.addToContactsStatus'
      }
    ]
  ];
};
const giveOutDevicesAndUserProfile = localThis => {
  const visitType = _.get(localThis, 'props.visit.type');
  const isInit = visitType == 'INIT';
  const emrRules = isInit
    ? [
        {
          validator: (rule, value, callback) =>
            value ? callback() : callback('Need to check to finish task.')
        }
      ]
    : [{ required: false }];
  return [
    [
      {
        key: 'giveOutEase',
        label: '',
        render: () => <Checkbox>Ease (BP3L)</Checkbox>,
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        path: 'visit.deviceRecordCount.Ease',
        valuePropName: 'checked'
      },
      {
        key: 'giveOutAlign',
        label: '',
        render: () => <Checkbox>Align (BG1)</Checkbox>,
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        path: 'visit.deviceRecordCount.Align',
        valuePropName: 'checked'
      },
      {
        key: 'giveOutLina',
        label: '',
        render: () => <Checkbox>Lina (HS2)</Checkbox>,
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        path: 'visit.deviceRecordCount.Lina',
        valuePropName: 'checked'
      }
    ],
    [
      {
        key: 'giveOutLancets',
        label: 'Lancets',
        className: 'numberInput',
        initialData: 0,
        path: 'visit.deviceRecordCount.Lancets',
        render: () => {
          return <InputNumber min={0} max={6} />;
        },
        itemStyle: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },
      {
        key: 'giveOutStrips',
        label: 'Strips',
        className: 'numberInput',
        initialData: 0,
        path: 'visit.deviceRecordCount.Strips',
        render: () => {
          return <InputNumber min={0} max={6} />;
        },
        itemStyle: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }
      }
    ],
    [
      {
        key: 'link',
        renderOnly: () => {
          let syncedDeviceRecords = [];
          const deviceRecords = _.get(
            localThis,
            'props.visit.member.profile.deviceRecords',
            {}
          );
          // console.log(deviceRecords);
          for (let [key, index] of Object.entries(deviceRecords || {})) {
            // console.log(key);
            if ((typeof index == 'boolean' && index) || index == 'APP_SYNC') {
              syncedDeviceRecords.push(deviceNameMap[key]);
            }
          }
          return (
            <div style={{ marginTop: 25, marginBottom: 50 }}>
              Current Devices: {syncedDeviceRecords.join(', ')}
              {/* <span>
                Current Devices:{' '}
                {syncedDeviceRecords.length == 0 && <span>None</span>}
              </span>
              <ul>
                {syncedDeviceRecords.length > 0 &&
                  syncedDeviceRecords.map((device, id) => (
                    <li key={id}>{device}</li>
                  ))}
              </ul> */}
              <a
                onClick={e => {
                  e.preventDefault();
                  localThis.setState({ followUpEditDevices: true });
                }}
                style={{ textDecoration: 'underline', marginLeft: 15 }}
              >
                Edit Devices
              </a>
            </div>
          );
        }
      }
    ],
    [
      {
        key: 'spokenLanguage',
        label: 'Spoken Language',
        initialData: 'EL',
        path: 'visit.member.profile.language.code',
        render: () => (
          <Select>
            {_.map(langOptions, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        span: 3
      },
      {
        key: 'appLanguage',
        label: 'App Language',
        initialData: 'EL',
        path: 'visit.member.profile.appLanguage.code',
        render: () => (
          <Select>
            {_.map(langOptions, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        span: 3
      },
      {
        key: 'techLevel',
        label: 'Tech Level',
        path: 'visit.member.profile.techLevel',
        render: () => (
          <Select>
            {_.map(techLevel, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        initialData: 'NORMAL',
        span: 3
      }
    ],
    [
      {
        key: 'a1c',
        label: 'A1C%',
        className: 'a1cInput',
        render: () => <Input disabled={true} />,
        // rules: [{ required: true, message: 'A1C Required' }],
        path: 'getLatestA1C.results.0.value',
        firstPath: 'visit.labResult.results.0.value',
        span: 6
      },
      {
        key: 'a1cDate',
        label: 'Collected Date',
        //rules: [{ required: true, message: 'A1C Date is Required' }],
        path: 'getLatestA1C.dateCollected',
        firstPath: 'visit.labResult.dateCollected',
        changeToBoolean: val => moment(val),
        render: () => (
          <DatePicker
            disabled={true}
            //disabledDate={current => current && current > moment().endOf('day')}
          />
        )
      },
      {
        key: 'a1cId',
        label: '',
        firstPath: 'visit.labResult.id',
        path: 'getLatestA1C.id',
        render: () => <div></div>
      },
      {
        key: 'needInHouseA1c',
        label: '',
        path: 'needInHouseA1C',
        // itemStyle: {
        //   marginTop: 30,
        //   display: localThis.props.needInHouseA1C ? 'flex' : 'none',
        //   alignItems: 'center',
        //   height: '100%'
        // },
        // type: 'checkbox',
        // initialData: localThis.props.needInHouseA1C ? true : false,
        // valuePropName: 'checked',
        render: () => {
          const firstPath = 'visit.labResult.dateCollected';
          const path = 'getLatestA1C.dateCollected';
          const dateInMS = _.get(
            localThis,
            `props.${firstPath}`,
            _.get(localThis, `props.${path}`)
          );
          const range = Date.now() - dateInMS;
          const needInHouseA1C = range > 30 * (1000 * 60 * 60 * 24);
          return needInHouseA1C ? (
            <div
              style={{
                marginTop: 30,
                display: needInHouseA1C ? 'flex' : 'none',
                alignItems: 'center',
                height: '100%',
                color: 'red'
              }}
            >
              Need in-house A1C
            </div>
          ) : (
            <div></div>
          );
        }
      }
    ],
    [
      {
        key: 'EMRAttached',
        label: '',
        render: () => (
          <Checkbox style={{ color: '#4278C3' }}>
            {`EMR (attach consent form)${isInit ? '*' : ''}`}
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        rules: emrRules,
        span: 8,
        path: 'visit.details.EMRAttached',
        valuePropName: 'checked'
      },
      {
        key: 'autoUpdateStatus',
        label: '',
        render: () => <Checkbox>Set up app auto update</Checkbox>,
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 8,
        changeToBoolean: val => val == 'COMPLETED',
        valuePropName: 'checked',
        path: 'visit.details.autoUpdateStatus'
      },
      {
        key: 'addToContactsStatus',
        label: '',
        render: () => <Checkbox>Add Care Team Number into Phone Book</Checkbox>,
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 8,
        changeToBoolean: val => val == 'COMPLETED',
        valuePropName: 'checked',
        path: 'visit.details.addToContactsStatus'
      }
    ]
  ];
};
const editDeviceFormMap = (
  localThis,
  hasBP,
  hasBG,
  hasHS,
  checkedBP,
  checkedBG,
  checkedHS,
  checkedTS,
  checkedIP
) => {
  // console.log('formmapTS ', checkedTS);
  // console.log('formmapIP ', checkedIP);
  return [
    [
      {
        key: 'easeCheckbox',
        label: '',
        render: () => (
          <Checkbox
            disabled={checkedTS}
            onChange={e =>
              localThis.props.form.setFieldsValue({
                easeAppSync: e.target.checked ? 'APP_SYNC' : 'MANUAL_INPUT'
              })
            }
          >
            Ease (BP3L)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        //path:'visit.member.profile.deviceRecords.Ease',
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: checkedTS ? false : checkedBP,
        valuePropName: 'checked'
      },
      {
        key: 'easeAppSync',
        label: '',
        // path:'visit.member.profile.deviceRecords.Ease',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='easeAppSync'
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  easeCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio disabled={checkedTS} value='APP_SYNC'>
                App Sync
              </Radio>
              <Radio disabled={checkedTS} value='MANUAL_INPUT'>
                Manual Input
              </Radio>
            </Radio.Group>
          );
        },
        initialData: checkedTS
          ? null
          : hasBP
          ? checkedBP
            ? 'APP_SYNC'
            : 'MANUAL_INPUT'
          : null,
        itemStyle: { display: 'flex' },
        span: 6
      }
    ],
    [
      {
        key: 'alignCheckbox',
        label: '',
        type: 'checkbox',
        render: () => (
          <Checkbox
            //disabled={!hasBG}
            onChange={e =>
              localThis.props.form.setFieldsValue({
                alignAppSync: e.target.checked ? 'APP_SYNC' : 'MANUAL_INPUT'
              })
            }
          >
            Align (BG1)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: checkedBG,
        // path:'visit.member.profile.deviceRecords.Align',
        valuePropName: 'checked'
      },
      {
        key: 'alignAppSync',
        label: '',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='alignAppSync'
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  alignCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio /*disabled={!hasBG}*/ value='APP_SYNC'>App Sync</Radio>
              <Radio /*disabled={!hasBG}*/ value='MANUAL_INPUT'>
                Manual Input
              </Radio>
            </Radio.Group>
          );
        },
        initialData: hasBG ? (checkedBG ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
        itemStyle: { display: 'flex' },
        span: 6
        // path:'visit.member.profile.deviceRecords.Align',
      }
    ],
    [
      {
        key: 'linaCheckbox',
        label: '',
        type: 'checkbox',
        render: () => (
          <Checkbox
            // disabled={!hasHS}
            onChange={e =>
              localThis.props.form.setFieldsValue({
                linaAppSync: e.target.checked ? 'APP_SYNC' : 'MANUAL_INPUT'
              })
            }
          >
            Lina (HS2)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        //changeToBoolean:(val) => val=='APP_SYNC',
        initialData: checkedHS,
        // path:'visit.member.profile.deviceRecords.Lina',
        valuePropName: 'checked'
      },
      {
        key: 'linaAppSync',
        label: '',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='linaAppSync'
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  linaCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio /*disabled={!hasHS}*/ value='APP_SYNC'>App Sync</Radio>
              <Radio /*disabled={!hasHS}*/ value='MANUAL_INPUT'>
                Manual Input
              </Radio>
            </Radio.Group>
          );
        },
        initialData: hasHS ? (checkedHS ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
        itemStyle: { display: 'flex' }
        // path:'visit.member.profile.deviceRecords.Lina',
      }
    ],
    [
      {
        key: 'trackSet',
        label: '',
        type: 'checkbox',
        render: onChange => (
          <Checkbox disabled={checkedIP} onChange={e => onChange(e)}>
            iHealth Clear
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        initialData: checkedTS,
        // path:'visit.member.profile.deviceRecords.TrackSet',
        valuePropName: 'checked'
      },
      {
        key: 'editTrackSet',
        label: '',
        render: openModal => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            <a
              onClick={e => {
                e.preventDefault();
                openModal();
              }}
            >
              Edit Device
            </a>
          </div>
        ),
        span: 6,
        itemStyle: { display: checkedTS ? 'flex' : 'none' }
      }
    ],
    [
      {
        key: 'iPhone',
        label: '',
        type: 'checkbox',
        render: onChange => (
          <Checkbox disabled={checkedTS} onChange={onChange}>
            iPhone
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        valuePropName: 'checked',
        initialData: checkedIP
        // path:'visit.member.profile.deviceRecords.iPhone',
      },
      {
        key: 'appVersion',
        label: '',
        render: appVersion => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            Version: {appVersion || 'N/A'}
          </div>
        ),
        span: 4,
        itemStyle: { display: checkedIP ? 'flex' : 'none' }
      },
      {
        key: 'unassignIPhone',
        label: '',
        render: action => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            <a
              onClick={e => {
                e.preventDefault();
                action();
              }}
            >
              Unassign device
            </a>
          </div>
        ),
        span: 3,
        itemStyle: { display: checkedIP ? 'flex' : 'none' }
      }
    ]
  ];
};
export default {
  onBoardFormMap,
  editDeviceFormMap,
  giveOutDevicesAndUserProfile
};
