import React from "react";
import {Form, Modal} from "antd/lib/index";
import { Button,DatePicker,TimePicker,Select,Input,Checkbox } from 'antd';
import { helpers} from "../helpers";
import VISIT_TYPE_ENUM from '../constants/map';
import {connect} from "react-redux";
import {compose, graphql} from "react-apollo/index";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'lib/utils';
import moment from 'moment-timezone';
import actions  from 'modulesAll/common/actions/modal'
import userList from 'graphqlModule/patientList'
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';
import {goPath} from "../../../lib/utils";
import I18N from 'modulesAll/I18N';
import { IHLoading } from 'ihcomponent';

const { TextArea } = Input;
const Option = Select.Option;
const dateFormat = 'MM/DD/YYYY';
const FormItem = Form.Item;

const IsValidJSONString = (str)=>{
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

let CreateVisitWithSearchPatientComponent = class extends React.Component {
    static displayName = 'CreateVisitWithSearchPatientComponent';

    constructor(props){
        super(props);
        this.state = {
            visitType:null
        }

    }
    parsePatientList = (patientList)=>{
        return _.map(patientList,(m)=>
            ({
                    value:_.get(m,'user.profile.fullName',''),
                    id:_.get(m,'user.id'),
                    birthday:_.get(m,'user.profile.birthday','')
        }))
    }

    handlePatientNameNav = (e,patientId)=>{
        try{
            atob(patientId);
        }catch (e){
            console.log(e);
        }
        goPath(`/patients/${patientId}`);

    }

    componentWillReceiveProps(nextProps){

        const curStartTimeObj = _.get(this,'props.selectedVisit');
        const nextStartTimeObj = _.get(nextProps,'selectedVisit');
        const curDatePickerValue = this.props.form.getFieldValue('startTime');
        //no selected visit and next visit obj is json(from patient page);
        if(!curDatePickerValue&&IsValidJSONString(nextStartTimeObj)){
            const nextStartTime = _.get(JSON.parse(nextStartTimeObj),'appointmentAt');
            if(nextStartTime) {
                this.props.form.setFieldsValue({
                    Date: moment(nextStartTime),
                    startTime: moment(nextStartTime),
                    endTime: moment(nextStartTime).add(1, 'hours')
                })
            }
            return;
        }

        //no selected visit and from next visit is string (create button);
        if(!curDatePickerValue&&_.isString(nextStartTimeObj)){
            this.props.form.setFieldsValue({
                Date:moment(nextStartTimeObj),
                startTime: moment(nextStartTimeObj),
                endTime:moment(nextStartTimeObj).add(1,'hours')
            })
        }
        //selected visit from empty slot to empty slot from patient profile page
        if(IsValidJSONString(curStartTimeObj)&&IsValidJSONString(nextStartTimeObj)){
            const curStartTime = _.get(JSON.parse(curStartTimeObj),'appointmentAt');
            const nextStartTime = _.get(JSON.parse(nextStartTimeObj),'appointmentAt');

            if(curStartTime!=nextStartTime){
                this.props.form.setFieldsValue({
                    Date: moment(nextStartTime),
                    startTime: moment(nextStartTime),
                    endTime: moment(nextStartTime).add(1, 'hours')
                })
            }
            return;
        }
        //selected visit is string and next is scheduled slot;
        if(_.isString(curStartTimeObj)&&_.isObject(nextStartTimeObj)){
            const nextStartTime = _.get(nextStartTimeObj,'appointmentAt');

            this.props.form.setFieldsValue({
                Date: moment(nextStartTime),
                startTime: moment(nextStartTime),
                endTime: moment(nextStartTime).add(1, 'hours')
            });

            return;
        }
        //selected visit is string and next is empty slot;
        if(_.isString(curStartTimeObj)&&_.isString(nextStartTimeObj)){

            if(curStartTimeObj!=nextStartTimeObj){
                this.props.form.setFieldsValue({
                    Date: moment(nextStartTimeObj),
                    startTime: moment(nextStartTimeObj),
                    endTime: moment(nextStartTimeObj).add(1, 'hours')
                })
            }
            return;
        }
        //no selected visit and next is scheduled slot;
        if(_.isEmpty(curDatePickerValue)&&!_.isEmpty(nextStartTimeObj)&&_.isObject(nextStartTimeObj)){

            this.props.form.setFieldsValue({
                Date:moment(nextStartTimeObj.appointmentAt),
                startTime:moment(nextStartTimeObj.appointmentAt),
                endTime: moment(nextStartTimeObj.appointmentAt).add(1,'hours')
            })
            return;
        }
        //current selected visit is scheduled slot next is scheduled slot
        if(_.isObject(nextStartTimeObj)&&_.isObject(curStartTimeObj)){

            const curStartTime = _.get(curStartTimeObj,'appointmentAt');
            const nextStartTime = _.get(nextStartTimeObj,'appointmentAt');
            if(curStartTime!=nextStartTime){
                this.props.form.setFieldsValue({
                    Date:moment(nextStartTime),
                    startTime:moment(nextStartTime),
                    endTime: moment(nextStartTime).add(1,'hours')
                })
            }
            return;
        }
        //selected visit is scheduled slot and next is empty slot;
        if(_.isObject(curStartTimeObj)&&!_.isEmpty(curStartTimeObj)&&_.isString(nextStartTimeObj)){
            this.props.form.setFieldsValue({
                Date:moment(nextStartTimeObj),
                startTime:moment(nextStartTimeObj),
                endTime: moment(nextStartTimeObj).add(1,'hours')
            })
            return;
        }
    }

    changeVisitType(visitType){
        this.setState({ visitType });
        this.props.setIsCreate(true);
    }

    getSelectedPatientId(props,patientId){
        const { patientList } = props;
        const { getFieldValue,setFieldsValue } = props.form;
        const selectedPatientId = patientId ? patientId : getFieldValue('Patient');
        const selectedPatient = _.first(_.filter(patientList,(p)=>p.user.id==selectedPatientId));
        const doctorId = _.get(selectedPatient,'user.profile.doctor.id');

        return doctorId;
    }

    setDoctorSel(props,patientId,doctorOptions){
        const { setFieldsValue } = props.form;
        const { patientList } = props;
        const selectedPatient = _.first(_.filter(patientList,(p)=>p.user.id==patientId));
        const doctorIdFromPatient = _.get(selectedPatient,'user.profile.doctor.id');
        const doctorId = doctorIdFromPatient ? doctorIdFromPatient : _.get(_.first(doctorOptions),'id');

        if(doctorId){
            setFieldsValue({
                'Doctor':doctorId
            })
        }
    }
    renderDropDown(){
        const { props } = this;
        const visit = _.get(props,'selectedVisit','');
        const isIdAndFullName = !moment(visit).isValid();
        const { setSearchName,patientList,loading,setIsCreate } = props;
        const { getFieldDecorator,getFieldValue } = this.props.form;
        const visitObj = isIdAndFullName ? JSON.parse(visit) : visit;
        const { visitType } = this.state;
        const patientOptions =  isIdAndFullName ? [ { id:visitObj.id,value:visitObj.value,birthday:visitObj.birthday } ] : this.parsePatientList(patientList);
        const doctorIdBySelectedPatient = isIdAndFullName ? this.getSelectedPatientId(props,visitObj.id) : null;
        const doctorOptions = helpers.filterDoctors();
        const initDoctorOption = doctorIdBySelectedPatient ? doctorIdBySelectedPatient : _.get(_.filter(doctorOptions,d=>d.primary),'0');
        const patientName = getFieldValue('Patient');
        const patientNameSelected = !!patientName||isIdAndFullName;
        const patientId = _.get(patientName,'key',patientName);
        const teamRoles = I18N.get('teamRoles');
        const isINIT= visitType==='INIT';

        const onChangeShouldSeeDoctor = (v)=>{
            const { checked } = v.target;
            Mixpanel.track(`${checked ? 'checked' :'unchecked'}`, 'see_provider', 'creating_appointment')
        }

        return (
            <div className="rescheduleAndCancel">
                <div style={{ marginLeft:5,color:'#303639' }}>
                    <label>Create Appointment</label>
                </div>
                <div className='firstRow'>
                    <FormItem label="Patient Name" style={{marginRight:0}}>
                        {getFieldDecorator('Patient',{
                            initialValue: moment(visit).isValid() ? null : visitObj.id,
                            rules:[ {required:true} ]
                        })
                        (
                            <Select
                                    showSearch
                                    filterOption={false}
                                    onSearch={(val)=>setSearchName(val)}
                                    onChange={()=>{ setIsCreate(true); }}
                                    notFoundContent={loading ? <IHLoading  /> : null}
                                    className='patientNameSelInCreate'
                                    disabled = { isIdAndFullName }
                                    dropdownMatchSelectWidth = {false}
                                    onSelect = {(e)=>this.setDoctorSel(props,e,doctorOptions)}
                            >
                                {
                                    _.map(patientOptions,(p)=>{
                                        return (
                                            <Option value={p.id} key={p.id}>{`${p.value} (birthday:${moment(p.birthday).format('MM/DD/YYYY')})`}</Option>
                                        )}
                                    )
                                }
                            </Select>
                        )}
                    </FormItem>
                    <div style={{width:'50%',marginBottom:10,display:'flex',justifyContent:'flex-end',flexDirection:'column'}}
                         className='patientProfileNav' onClick={(e)=>patientId ? this.handlePatientNameNav(e,patientId):''}>
                        { patientNameSelected ? <Button type='default'>View Patient Profile</Button>  : '' }
                    </div>
                </div>
                <div className='modalRow'>
                    <FormItem
                        label="Type"
                    >
                        {getFieldDecorator('Type', {
                            rules: [
                                { required: true, message: 'Please Select Appointment Type'},
                            ],
                        })(
                            <Select onChange={(e)=>this.changeVisitType(e)}>
                                {_.map(VISIT_TYPE_ENUM,(type,val)=>{
                                    return (
                                        <Option value={val} key={val}>{type}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                </div>
                <div className='secondRow'>
                    <FormItem label='Clinic Provider' style={{ marginRight:'0px' }} >
                        {getFieldDecorator('Doctor',{
                            rules:[{required:true}],
                            initialValue:initDoctorOption.id ||null
                        })
                        (
                            <Select onChange={()=>{
                                Mixpanel.track('checked','see_provider','creating_appointment');
                                setIsCreate(true)
                            }}
                                    filterOption={false}

                            >
                                {_.map(doctorOptions,(program)=>{
                                    return (
                                        <Option value={program.id} key={program.id}>{program.fullName}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem className='seeDoctorCheck' style={{display:'flex',flexDirection:'column',justifyContent:'flex-end',marginLeft:0,marginRight:0}}>
                        {
                            getFieldDecorator('shouldSeeDoctor', {
                                initialValue:true,
                                valuePropName: 'checked',
                            })(
                                <Checkbox onChange={(v)=>onChangeShouldSeeDoctor(v)}>See Provider</Checkbox>,
                            )}
                    </FormItem>
                </div>
                <div className='modalRow'>
                    <FormItem label="Care Team">
                        {
                            getFieldDecorator('visitRoles',{
                                rules:[{ required: isINIT,message:'Care Team is Required'}]
                            })(
                            <Select mode="multiple">
                                {_.map(teamRoles,(type,val)=>{
                                    return (
                                        <Option value={val} key={val}>{type}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                </div>
                <div className='thirdRow'>
                    <FormItem
                        label="Date"
                    >
                        {getFieldDecorator('Date', {
                            rules: [
                                { required: true, message: 'Please Select Date'},
                            ],
                            initialValue:_.isString(visit)&&moment(visit).isValid() ? moment(visit) : moment(visitObj.appointmentAt||'invalid time').isValid() ? moment(visitObj.appointmentAt) : null
                        })(
                            <DatePicker
                                format={dateFormat}
                                placeholder="Select Date"
                                onChange={()=>setIsCreate(true)}
                                disabled={true}
                            />
                        )}
                    </FormItem>
                    <FormItem
                        label="Start Time"
                    >
                        {getFieldDecorator('startTime', {
                            rules: [
                                { required: true, message: 'Please Select Time'}
                            ],
                            initialValue:_.isString(visit)&&moment(visit).isValid() ? moment(visit) : moment(visitObj.appointmentAt||'invalid time').isValid() ? moment(visitObj.appointmentAt) : null
                        })(
                            <TimePicker
                                format="HH:mm"
                                placeholder="Select Time"
                                use12Hours={false}
                                minuteStep = {30}
                                onChange={()=>setIsCreate(true)}
                                disabled={true}
                            />
                        )}
                    </FormItem>
                    <FormItem
                        label="End Time"
                    >
                        {getFieldDecorator('endTime', {
                            rules: [
                                { required: true, message: 'Please Select Time'},
                                { validator:(rule,value,callback)=>{
                                        const formVariables  = this.props.form.getFieldsValue();
                                        const { startTime } = formVariables;
                                        if(moment(value).isBefore(startTime)||moment(value).isSame(startTime)){
                                            callback('End time must be latter than start Time');
                                        }
                                        callback();
                                    }
                                }
                            ],
                            initialValue:_.isString(visit)&&moment(visit).isValid() ? moment(visit).add(1,'hours') : moment(visitObj.appointmentAt||'invalid time').isValid() ? moment(visitObj.appointmentAt).add(1,'hours')  : null
                        })(
                            <TimePicker
                                format="HH:mm"
                                placeholder="Select Time"
                                use12Hours={false}
                                minuteStep = {30}
                                onChange={()=>{
                                    setIsCreate(true)
                                }}
                                allowEmpty={false}
                            />
                        )}
                    </FormItem>
                </div>
                <div className='forthRow'>
                    <FormItem
                        label="Description (Optional)"
                    >
                        {getFieldDecorator('Reason', {
                            rules: [
                                { required: false },
                            ],
                        })(
                            <TextArea  onChange={()=>setIsCreate(true)}/>
                        )}
                    </FormItem>
                </div>
            </div>
        )

    }
    renderFooter() {

        const { enableEdit,setSelectedVisit,setIsCreate,setSearchName } = this.props;
        const baseStyle = {
            margin:5
        }

        const leftButton = {
                                key: 'Cancel',
                                label: "Cancel",
                                type: 'default',
                                size:'default',
                                style:{ margin:5 },
                                onClick:()=>{
                                    Mixpanel.track('clicked','cancel','creating_appointment');
                                    setIsCreate(false);
                                    enableEdit(false);
                                    setSelectedVisit({});
                                    setSearchName('');
                                    this.props.form.resetFields();
                                    this.setState({visitType:null})
                                }
                           };
        const rightButton  = {
                                key: 'submit',
                                label: "Submit",
                                type: 'default',
                                htmlType:"submit",
                                style:baseStyle,
                                onClick:()=>{
                                    Mixpanel.track('clicked','submit','creating_appointment');
                                    this.props.form.validateFields((error)=>{
                                        if(!error){
                                            this.showConfirmForMakeVisit();
                                        }
                                    })
                                }
                            }



        let buttonList = [ leftButton, rightButton ]


        const buttons =   buttonList.map((value,index)=>{

            return (
                <FormItem
                    key={value.label}
                >
                    <Button className={value.className || ''}
                            onClick={value.onClick}
                            type={value.type} style={value.style}
                            htmlType={value.htmlType}>{value.label}
                    </Button>
                </FormItem>
            )
        });

        return (
            <div className='createButtons' style={{marginRight:15}}>{buttons}</div>
        )
    }

    showConfirmForMakeVisit = ()=>{

        return new Promise((resolve,reject)=>{
            const Yes = {
                label: 'Confirm',
                handler: ()=>{
                    resolve(this.createVisit());
                },
                closeModal: true
            }
            const No = {
                label: 'Cancel',
                handler: () => {
                    resolve(false)
                }
            }
            const modalProps = {
                Yes,
                No,
                title: 'Schedule Appointment?',
                content: 'Would you like to schedule this appointment?'
            }
            this.props.openConfirmModal(modalProps)
        });
    }

    createVisit(){
        const { scheduleVisit } = this.props;
        const formVariables  = this.props.form.getFieldsValue();
        const date = formVariables.Date;
        const startTime = formVariables.startTime;
        const endTime = formVariables.endTime;

        const combinedStartTime = helpers.combineDateAndTime(date,startTime);
        const combinedEndTime = helpers.combineDateAndTime(date,endTime);
        const variables = {
            memberId:_.get(formVariables,'Patient.key',_.get(formVariables,'Patient')),
            type:formVariables.Type,
            appointmentAt:combinedStartTime,
            appointmentTo:combinedEndTime,
            reason:formVariables.Reason,
            providers:[formVariables.Doctor],
            shouldSeeDoctor: formVariables.shouldSeeDoctor,
            visitRoles: formVariables.visitRoles
        }
        scheduleVisit(variables);
        this.setState({ visitType: null })
    }

    onSubmit=()=>{
        this.props.form.validateFields((error)=>{
            if(!error){
                this.createVisit();
            }
        })
    }

    render(){
        return(
            <div>
                <Form style={{display:'flex',flexDirection:'column'}}>
                    { this.renderDropDown()}
                    { this.renderFooter() }
                </Form>
            </div>
        )

    }

};

const withData = graphql(userList, {
    skip:(ownProps)=>{
        return _.isEmpty(ownProps.searchName);
    },
    options: (ownProps) => {
        const { searchName } = ownProps;
        const variables = {
            count: 9999,
            search: {
                fields: ['NAME','IDENTIFICATION'],
                match: searchName,
            },
            filters:{showAll:true},
            sort: { 'field': 'LAST_NAME', 'direction': 'ASC' } //{ 'field': 'BIRTHDAY', 'direction': 'DESC' }
        };

        return {
            variables,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        };
    },
    props:({data:{loading,patientList},ownProps})=>{
        if(loading){
            return loading
        }
        return {
            patientList:patientList.data
        };
    }
});

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(actions.confirm(props)),
    }
}
CreateVisitWithSearchPatientComponent = Form.create()(CreateVisitWithSearchPatientComponent);

export default compose(connect(null,mapToDispatch),withData)(CreateVisitWithSearchPatientComponent);


