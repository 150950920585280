import gql from 'graphql-tag'
import {User} from './schema/User'
import {MiniEnrolledProgram} from './schema/MiniEnrolledProgram'
import {PaginationInfo} from './schema/common'

export const taggedPatients = gql`
  query patientList(
    $page:Int,
    $count:Int,
    $sort:UserListSort,
    $filters: InputPatientFilters
  ){
    patientList(
      filters:$filters,
      page:$page,
      count:$count,
      sort:$sort
    ){
      data{
        user{
          ${User}
        }
        enrolledPrograms{
          ${MiniEnrolledProgram}
        }

      }
      pageInfo{
        ${PaginationInfo}
      }

    }
  }
`;

export default gql`
  query patientList(
    $page:Int,
    $count:Int,
    $sort:UserListSort,
    $filters: InputPatientFilters,
    $search: InputUserSearch
  ){
    patientList(
      filters:$filters,
      search:$search,
      page:$page,
      count:$count,
      sort:$sort
    ){
      data{
        user{
          ${User}
        }
        enrolledPrograms{
          ${MiniEnrolledProgram}
        }

      }
      pageInfo{
        ${PaginationInfo}
      }

    }
  }
`;
