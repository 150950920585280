import React from 'react';
import types from '../constants/VitalListHSConstants';
import { commonActions } from 'libModule/helpers/action-helpers';
import { openModal } from 'layoutModule/actions/MainModal';
// files
import InterventionNotes from '../../../alerts/InterventionNotes/containers/InterventionNotes'

export const changeRange = (range) => {
  console.log(range)
  return ({
    type: types.CHANGE_RANGE,
    range
  })
}

const changeView = (view) => ({
  type: types.CHANGE_VIEW,
  view
})

const openPatientVitalModal = (row) => {
  return (dispatch) => {
    const props = {
      showModal: true,
      showHeaderCloseButton: true,
      // title: `Reading on ${moment(row.date).format("MMM-DD-YYYY")}`,
      className: 'vsm-provider-modal',
      size: 'lg'
    }
    dispatch(openModal(<InterventionNotes row={row} noteType={'hs_note'} />, props))
  }
}

export default Object.assign({
  changeRange,
  changeView,
  openPatientVitalModal
}, commonActions(types, ['onTableChange']))
