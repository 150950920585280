import React from 'react';
import $ from 'jquery';
import moment from 'moment';
import '../styles/index.scss';
import { connect } from 'react-redux';
import {
  DatePicker,
  Table,
  Button,
  Icon,
  Popconfirm,
  Popover,
  Modal,
  Tooltip
} from 'antd';
import VISIT_TYPE_ENUM from '../constants/map';
import { API } from '../query/index';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import {
  GQLHelper,
  setAsyncRouteLeaveHook,
  submittingStyle,
  goPath,
  FormHelper
} from 'libModule/utils';
import actions from 'modulesAll/common/actions/modal';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { IHLoading, IHSearchTable, IHButton, _, IHInput } from 'ihcomponent';

import WorkListContainer from '../../visitWorkList/container/workListContainer';
import EditVisitInDashboardContainer from '../../visitNewWorkFlow/containers/EditVisitInDashboardContainer';
import { browserHistory } from 'react-router';
import Client from 'libModule/gqlClient';
import removeFromInventory from '../../graphql/updateQueries/removeFromInventory'

import TaskListFormContainer from '../containers/TaskListFormContainer';
import { helpers } from '../helpers';
import { commonActionsHelper } from 'libModule/helpers/action-helpers';
import { none } from 'ramda';
import CreateNewVisitContainer from '../../visitNewWorkFlow/containers/CreateNewVisitContainer';
import UserClass from 'modulesAll/graphql/class/User';

const btnStyle = {
  width: '22px',
  height: '25px'
};

const deleteText = {
  textDecoration: 'underline',
  color: '#000000',
  '&:hover': {
    background: '#efefef'
  }
};

const getAge = t => {
  return moment().diff(moment(t, 'YYYY-MM-DD'), 'years');
};

const getMins = t => {
  return moment().diff(moment(t, 'HH:mm'), 'minutes');
};

const curUrl = browserHistory.getCurrentLocation().pathname;
const visitId = curUrl.substring(1, curUrl.length - 1);

const AppointmentListComponent = class extends React.Component {
  static displayName = 'AppointmentListComponent';
  constructor(props) {
    super(props);
    this.state = {
      expandedRowKeys: [],
      isSaved: true,
      showReschedule: false
    };
    this.confirm.bind();
    this.onReschedule = this.onReschedule.bind(this);
  }

  onReschedule(doc) {
    const show = this.state.showReschedule;
    if (show) {
      this.setState({
        showReschedule: false
      });
    } else {
      this.setState({
        showReschedule: doc
      });
    }
    if (this.props.curTab === 'Today') {
      Mixpanel.track('clicked', 'edit', "on today's visit page", {});
    } else {
      Mixpanel.track('clicked', 'edit', "on tomorrow's visit page", {});
    }
  }

  routerWillLeave = () => {
    const { openConfirmModal } = this.props;
    const { isSaved } = this.state;
    return new Promise((resolve, reject) => {
      if (isSaved) {
        resolve(true);
        return;
      }

      const Yes = {
        label: 'Confirm',
        handler: () => {
          resolve(true);
        },
        closeModal: true
      };

      const No = {
        label: 'Cancel',
        handler: () => {
          resolve(false);
        }
      };

      const modalProps = {
        Yes,
        No,
        title: 'Leave This Page?',
        content:
          'Please confirm if you would like to leave this page without saving your changes'
      };
      openConfirmModal(modalProps);
    });
  };

  componentWillMount() {
    setAsyncRouteLeaveHook(
      this.props.router,
      this.props.route,
      this.routerWillLeave
    );
  }

  componentDidMount() {
    let data = null;
    if(sessionStorage.getItem('backToCurVisit')) {
      data = JSON.parse(sessionStorage.getItem('backToCurVisit'));
      sessionStorage.removeItem('backToCurVisit');
      this.setShowTaskModal(data);
    }
    // let visitInfo = '';
    // if(typeof(browserHistory.getCurrentLocation().search) != 'undefined' && browserHistory.getCurrentLocation().search != null) {
    //   const searchInfo = browserHistory.getCurrentLocation().search;
    //   visitInfo = searchInfo.substring(1, searchInfo.length);
    //   console.log(visitInfo)
    // }
    // let targetObj = null;
    // let firstTime = true;
  }

  confirm(id, type, index) {
    API.updateVisit(id, type)
      .then(res => {
        const data = res.data.editVisit;
        const id = data.id;
        this.props.updateVisit(id, data);
        if (typeof index != undefined) {
          this.setState({
            expandedRowKeys: [index]
          });
        }
      })
      .catch(error => {
        console.log(error);
        this.props.openErrorModal(GQLHelper.formatError(error));
      });
  }

  replaceWeekDayAbbr(string) {
    let weekday = string.substring(0, 3);
    let weekdayMap = { Tue: 'Tues', Thu: 'Thur' };

    if (weekdayMap[weekday]) {
      string = string.replace(`${weekday}`, weekdayMap[weekday]);
    }
    return string;
  }

  setIsSaved = isSaved => {
    this.setState({
      isSaved
    });
  };

  renderTaskModal = () => {
    const {
      setShowTaskModal,
      state: { showTaskModal },
      props: { refetch: refetchVisitList }
    } = this;
    return showTaskModal ? (
      <Modal
        visible={true}
        onCancel={() => {
          if (refetchVisitList) refetchVisitList();
          setShowTaskModal(null);
        }}
        footer={null}
        width={1280}
        className='workListModal'
        destroyOnClose={true}
        maskClosable={false}
      >
        <WorkListContainer {...showTaskModal} />
      </Modal>
    ) : (
      <React.Fragment></React.Fragment>
    );
  };

  setShowTaskModal = visit => {
    this.setState({
      showTaskModal: visit
    });
  };

  renderPendingConfirmVisits = () => {
    const { props } = this;
    let { indexVisitList, curTab, curPage, handleChangePage, confirmVisit, refetch, loading} = props;
    const types = _.map(VISIT_TYPE_ENUM, (index, data) => {
        return {
            text: index,
            value: data
        };
    });
    if(loading){
        return <IHLoading/>
    }
    const doctors = _.map(JSON.parse(sessionStorage.getItem('doctorName')),
          data => {
              return {
                  text: data.fullName,
                  value: data.id
              };
          }
    );

      const columns = [
          {
              title: 'No.',
              width: '8%',
              dataIndex:'confirm',
              key: 'no.',
              render: (value, doc) => <a style={{ fontWeight: 'bold' }} 
                onClick={
                  () => {
                    Mixpanel.track('clicked', 'confirm', 'on pending appointment', {})
                    confirmVisit(doc.id, !value)
                  }
                }>Confirm</a>
          },
          {
              title: 'Time',
              dataIndex: 'appointmentAt',
              width: '8%',
              key: 'time',
              // sorter:
              //     curTab === 'Today'|| curTab === 'All'
              //         ? (a, b) => a.appointmentAt - b.appointmentAt
              //         : false,
              // defaultSortOrder: 'ascend',
              render: a => (
                  <span style={{ fontWeight: 'bold', color: '#000000' }}>
            {curTab !='All' ? moment(a).format('HH:mm'): moment(a).format('MM/DD/YYYY HH:mm') }
          </span>
              )
          },
          {
              title: 'Visit Type',
              width: '10%',
              dataIndex: 'type',
              // filters:curTab === 'Today'|| curTab === 'All' ? types : null,
              // filterMultiple: curTab === 'Today' ? true : false,
              // onFilter: (value, record) => record.type.includes(value),
              render: value =>
                  value === 'INIT' ? (
                      <span style={{ color: '#FF0000' }}>{VISIT_TYPE_ENUM[value]}</span>
                  ) : (
                      <span style={{ color: '#000000' }}>{VISIT_TYPE_ENUM[value]}</span>
                  )
          },
          {
              title: 'Name',
              width: '10%',
              dataIndex: 'member.profile.fullName',
              key: 'name',
              render: (a, b) => {
                  const id = (b.member && b.member.id) || null;
                  return id ? (
                      <p
                          onClick={() => {
                              Mixpanel.calculateDuration(curPage);
                              Mixpanel.track('clicked', 'Patient name', 'Dashboard', {
                                  PATIENT_ID: id
                              });
                              goPath(`/patients/${id}/enrolledprogram/default`);
                              Mixpanel.time_event('patient_profile');
                              handleChangePage('patient_profile');
                          }}
                      >
                          <a
                              href='JavaScript:void(0);'
                              style={{ fontWeight: 'bold', color: '#000000' }}
                          >
                              {a}
                          </a>
                      </p>
                  ) : (
                      <a href={'/'}>invalid data</a>
                  );
              }
          },
          {
              title: 'Age',
              width: '7%',
              key: 'age',
              dataIndex: 'member.profile.birthday',
              // sorter:
              //     curTab === 'Today'|| curTab === 'All'
              //         ? (a, b) => {
              //             return (
              //                 getAge(a.member.profile.birthday) -
              //                 getAge(b.member.profile.birthday)
              //             );
              //         }
              //         : null,
              render: t => {
                  return getAge(t);
              }
          },
          {
              title: 'G',
              width: '6%',
              key: 'sex',
              dataIndex: 'member.profile.gender',
              // filters:
              //     curTab === 'Today'|| curTab === 'All'

              //         ? [
              //             { value: 'M', text: 'Male' },
              //             { value: 'F', text: 'Female' }
              //         ]
              //         : null,
              // onFilter: (value, record) => {
              //   return record.member.profile.gender.includes(value);
              // }
          },
          {
              title: 'Doctor',
              width: '9%',
              key: 'provider',
              // filters: curTab === 'Today'|| curTab === 'All' ? doctors : null,
              // onFilter: (value, record) => {
              //     const indexArr = _.map(record.providers, provider => provider.id);
              //     return indexArr.includes(value);
              // },
              render: (value, record) => {
                  return _.map(record.providers, provider => {
                      return provider.profile.fullName;
                  });
              }
          },
          // {
          //     title: 'Visit',
          //     width: '8%',
          //     key: 'visit',
          //     dataIndex: 'visitRoles',
          //     filters:
          //         curTab === 'Today'|| curTab === 'All'
          //             ? [
          //                 { value: ['MA'], text: 'CA' },
          //                 { value: ['RD'], text: 'RD' },
          //                 { value: ['MA,RD'], text: 'CA/RD' },
          //                 { value: ['MD,RD'], text: 'RD/MD' },
          //                 { value: ['MA,MD'], text: 'CA/MD' },
          //                 { value: ['MA,MD,RD'], text: 'ALL' }
          //             ]
          //             : null,
          //     onFilter: (value, record) => {
          //         let roleList = record.visitRoles.sort();
          //         let roleStr = '';
          //         for (let i = 0; i < roleList.length; i++) {
          //             roleStr =
          //                 i == roleList.length - 1
          //                     ? roleStr + roleList[i]
          //                     : roleStr + roleList[i] + ',';
          //         }
          //         return _.isEqual(roleStr, value);
          //     },
          //     render: (value, record) => {
          //         const newValue = _.map(value, text => {
          //             return text === 'MA' ? 'CA' : text;
          //         });
          //         if (Array.isArray(newValue) && newValue.length > 0) {
          //             return newValue.join('/');
          //         } else {
          //             return newValue == null || newValue.length == 0 ? '-' : newValue;
          //         }
          //     }
          // },
          {
              title: 'Vitals',
              width: '7%',
              key: 'vitals',
              dataIndex: 'vitalTypes',
              // filters:
              //     curTab === 'Today'|| curTab === 'All'
              //         ? [
              //             { value: 'BP', text: 'BP' },
              //             { value: 'BG', text: 'BG' },
              //             { value: 'HS', text: 'HS' }
              //         ]
              //         : null,
              // onFilter: (value, record) => record.vitalTypes.includes(value),
              render: value => {
                  if (Array.isArray(value) && value.length > 0) {
                      return value.join('/');
                  } else {
                      return value == null || value.length == 0 ? '-' : value;
                  }
              }
          },
          {
              title: 'Note',
              key: 'note',
              dataIndex: 'reason',
              render: (value, doc) => {
                  let text = '';
                  if (doc.conditionsList != null) {
                      const keyPairArr = _.toPairs(doc.conditionsList);
                      for (let i = 0; i < keyPairArr.length - 1; i++) {
                          if (keyPairArr[i][1] != null) {
                              text += keyPairArr[i][0] + ' ';
                          }
                      }
                  }
                  if (value == null || value.trim().length == 0) {
                      return text === '' ? '-' : text;
                  } else if (value.length > 0 && value.length <= 15) {
                      return (
                          <div style={{ whiteSpace: 'pre-wrap' }}>
                              {value + '\n' + text}
                          </div>
                      );
                  } else if (value.length > 15) {
                      return (
                          <Popover
                              style={{ whiteSpace: 'pre-wrap', width:'unset !important' }}
                              content={
                                  <div>
                                      {value}
                                      <br />
                                      {text}
                                  </div>
                              }
                          >
                              <div>{value.substring(0, 16) + '...'}</div>
                          </Popover>
                      );
                  }
              }
          },
          {
              title: 'Task',
              width: '5%',
              key: 'task',
              dataIndex: 'confirm',
              render: (value, record) =>
                  value ? (
                    <a>
                      <img
                        src='/image/init_confirmed_file.png'
                        style={{ width: '22px', height: '25px' }}
                      />
                    </a>
                  ) : (
                    <a>
                      <img
                        src='/image/init_file.png'
                        style={{ width: '22px', height: '25px' }}
                      />
                    </a>
                  ),
                onCell: record => ({
                  onClick: () => {
                    if (record.confirm) {
                      Mixpanel.track('clicked', 'task', 'on pending appointment', {})
                      return this.setShowTaskModal(
                        Object.assign({ refetchVisitList: refetch }, record)
                      );
                    } else {
                      return;
                    }
                  }
                })
          },
          {
              width: '5%',
              key: 'edit',
              render: (value, doc) => (
                  <div 
                    onClick={
                      Mixpanel.track('clicked', 'edit', 'on pending appointment', {}),
                      () => this.onReschedule(doc)
                      }>
                      <a>
                          <img
                              src='/image/edit.png'
                              style={{ width: '22px', height: '25px' }}
                          />
                      </a>
                  </div>
              )
          },
          {
              width: '9%',
              key: 'delete',
              render: (value, doc) => {
                  const { id } = doc;
                  return (
                      <a>
                <span
                    style={deleteText}
                    onClick={() => {
                      Mixpanel.track('clicked', 'delete', 'on pending appointment', {}),
                      props.showConfirmForCancel(id,false);
                    }}
                >
                  Delete
                </span>
                      </a>

                  );
              }
          }
      ];
      const tableProps = {
        rowKey: (row, index) => index,
        onChange:(a, b, c) => this.props.onTableChange(a, b, c),
      };
      return <div style={{ marginTop:30 }}>
              <div style={{ marginBottom: 15 }}><span style={{ color:'#555555',fontSize:16,fontWeight:'bold' }}>Pending for confirmation</span></div>
              <Table dataSource={ _.filter(indexVisitList,i => !i.confirm) }
                     columns={columns}
                     pagination={false}
                     {...tableProps}/>
             </div>

  }

  render() {
    const { props } = this;
    const { isSaved, showTaskModal } = this.state;
    const {
      visitReduxList,
      handleChangePage,
      curPage,
      curTab,
      confirmVisit,
      refetch
    } = props;
    const hasDateFilter = this.props.dateRangeToFilter;
    const isSameDay =
      hasDateFilter &&
      moment(this.props.dateRangeToFilter[0]).isSame(
        this.props.dateRangeToFilter[1],
        'day'
      );
    const { loading, visitList, indexVisitList } = this.props;
    // const data = visitReduxList;
    const data = indexVisitList;
    const doctors = _.map(
      JSON.parse(sessionStorage.getItem('doctorName')),
      data => {
        return {
          text: data.fullName,
          value: data.id
        };
      }
    );

    //short solution for JIRA-1635
    const types = _.map(VISIT_TYPE_ENUM, (index, data) => {
      return {
        text: index,
        value: data
      };
    });

    const onClickCheckIn = (visitId, doc) => {
      API.updateVisit(visitId, 'checkIn').then(res =>
        refetch().then(res => {
          this.setShowTaskModal(doc);
        })
      );

      Mixpanel.track('clicked', 'Check In', "on Today's" + ' visit page', {});
    };

    const onClickCheckOut = visitId => {
      API.updateVisit(visitId, 'checkOut').then(res => refetch());
      if(sessionStorage.getItem(visitId)) {
        let data = JSON.parse(sessionStorage.getItem(visitId));
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const visitType = _.get(data, 'visitType');
        const memberId = _.get(data, 'memberId'); // patientId
        const products = [
          {
            productType: 'LANCET',
            quantity: visitType === 'FOLLOW_UP' ? _.get(data, 'deviceRecordCount.Lancets', '0') : (_.get(data, 'deviceRecordCount.Align') === 0 ? 0 : _.get(data, 'userDeviceRecords.Lancets', '0'))
          },
          {
            productType: 'STRIP',
            quantity: visitType === 'FOLLOW_UP' ? _.get(data, 'deviceRecordCount.Strips', '0') :  (_.get(data, 'deviceRecordCount.Align') === 0 ? 0 : _.get(data, 'userDeviceRecords.Strips', '0'))
          },
          {
            productType: 'BP',
            quantity: _.get(data, 'deviceRecordCount.Ease') === true ? 1 : _.get(data, 'deviceRecordCount.Ease')
          },
          {
            productType: 'BG',
            quantity:_.get(data, 'deviceRecordCount.Align') === true ? 1 : _.get(data, 'deviceRecordCount.Align')
          },
          {
            productType: 'HS',
            quantity:_.get(data, 'deviceRecordCount.Lina') === true ? 1 : _.get(data, 'deviceRecordCount.Lina')
          },
          {
            productType: 'PHONE',
            quantity: _.get(data, 'userDeviceRecords.iPhone') ? 1 : 0
          },
          {
            productType: 'CLEAR',
            quantity: _.get(data, 'userDeviceRecords.TrackSet') ? 1 : 0
          },
          {
            productType: 'A1C',
            quantity: _.get(data, 'A1C.value', 0) === 0 ? 0 : 1
          }
        ]
        console.log('\n\nproducts ', products);
        Client.mutate({
          mutation: removeFromInventory,
          variables: {
              organizationId: _.get(currentUser, 'selectedRole.organization.id'),
              memberId,
              products,
              note: '--',
          },
          fetchPolicy: 'no-cache'
        })
        sessionStorage.removeItem(visitId);
      }
      Mixpanel.track('clicked', 'Check Out', "on Today's" + ' visit page', {});
    };

    let numIndex = 1;
    const columns = [
      {
        title: 'No.',
        // width: curTab === 'Today' ? '5%' : '8%',
        width: '8%',
        dataIndex: curTab === 'Today' ? 'index' : 'confirm',
        key: 'no.',
        render: (value, doc) => {
          //today -> num
          //tomorrow -> confirmed ? num : confirm
          switch (curTab) {
            case 'Today':
              return (
                <span style={{ fontWeight: 'bold', color: '#000000' }}>
                  {value}
                </span>
              );
            case 'Tomorrow':
              let render = !value ? (
                <a
                  style={{ fontWeight: 'bold' }}
                  onClick={() => confirmVisit(doc.id, !value)}
                >
                  Confirm
                </a>
              ) : (
                <a style={{ fontWeight: 'bold', color: '#000000' }}>
                  {numIndex++}
                </a>
              );
              return render;
          }
        }
      },
      {
        title: 'Time',
        width: curTab === 'Today' || curTab === 'Tomorrow' ? '8%' : '13%',
        dataIndex: 'appointmentAt',
        key: 'time',
        sorter:
          curTab === 'Today'|| curTab === 'All'
            ? (a, b) => a.appointmentAt - b.appointmentAt
            : false,
        defaultSortOrder: 'ascend',
        render: a => (
          <span style={{ fontWeight: 'bold', color: '#000000' }}>
            {curTab !='All' ? moment(a).format('HH:mm'): moment(a).format('MM/DD/YYYY HH:mm') }
          </span>
        )
      },
      {
        title: 'Visit Type',
        width: '10%',
        dataIndex: 'type',
        filters:curTab === 'Today'|| curTab === 'All' ? types : null,
        filterMultiple: curTab === 'Today' || curTab === 'All' ? true : false,
        onFilter: (value, record) => record.type.includes(value),
        render: value =>
          value === 'INIT' ? (
            <span style={{ color: '#FF0000' }}>{VISIT_TYPE_ENUM[value]}</span>
          ) : (
            <span style={{ color: '#000000' }}>{VISIT_TYPE_ENUM[value]}</span>
          )
      },
      {
        title: 'Name',
        width: '10%',
        dataIndex: 'member.profile.fullName',
        key: 'name',
        render: (a, b) => {
          const id = (b.member && b.member.id) || null;
          return id ? (
            <p
              onClick={() => {
                Mixpanel.calculateDuration(curPage);
                Mixpanel.track('clicked', 'Patient name', 'Dashboard', {
                  PATIENT_ID: id
                });
                goPath(`/patients/${id}/enrolledprogram/default`);
                Mixpanel.time_event('patient_profile');
                handleChangePage('patient_profile');
              }}
            >
              <a
                href='JavaScript:void(0);'
                style={{ fontWeight: 'bold', color: '#000000' }}
              >
                {a}
              </a>
            </p>
          ) : (
            <a href={'/'}>invalid data</a>
          );
        }
      },
      {
        title: 'Age',
        // width: curTab === 'Today' ? '6%' : '7%',
        width: '7%',
        key: 'age',
        dataIndex: 'member.profile.birthday',
        sorter:
            curTab === 'Today'|| curTab === 'All'
                ? (a, b) => {
                return (
                  getAge(a.member.profile.birthday) -
                  getAge(b.member.profile.birthday)
                );
              }
            : null,
        render: t => {
          return getAge(t);
        }
      },
      {
        title: 'G',
        width: '6%',
        key: 'sex',
        dataIndex: 'member.profile.gender',
        filters:
            curTab === 'Today'|| curTab === 'All'

                ? [
                { value: 'M', text: 'Male' },
                { value: 'F', text: 'Female' }
              ]
            : null,
        onFilter: (value, record) => {
          return record.member.profile.gender.includes(value);
        }
      },
      {
        title: 'Doctor',
        width: '9%',
        key: 'provider',
        filters: curTab === 'Today'|| curTab === 'All' ? doctors : null,
        onFilter: (value, record) => {
          const indexArr = _.map(record.providers, provider => provider.id);
          return indexArr.includes(value);
        },
        render: (value, record) => {
          return _.map(record.providers, provider => {
            return provider.profile.fullName;
          });
        }
      },
      // {
      //   title: 'Visit',
      //   width: '8%',
      //   key: 'visit',
      //   dataIndex: 'visitRoles',
      //   filters:
      //       curTab === 'Today'|| curTab === 'All'
      //       ? [
      //           { value: ['MA'], text: 'CA' },
      //           { value: ['RD'], text: 'RD' },
      //           { value: ['MA,RD'], text: 'CA/RD' },
      //           { value: ['MD,RD'], text: 'RD/MD' },
      //           { value: ['MA,MD'], text: 'CA/MD' },
      //           { value: ['MA,MD,RD'], text: 'ALL' }
      //         ]
      //       : null,
      //   onFilter: (value, record) => {
      //     let roleList = record.visitRoles.sort();
      //     let roleStr = '';
      //     for (let i = 0; i < roleList.length; i++) {
      //       roleStr =
      //         i == roleList.length - 1
      //           ? roleStr + roleList[i]
      //           : roleStr + roleList[i] + ',';
      //     }
      //     return _.isEqual(roleStr, value);
      //   },
      //   render: (value, record) => {
      //     const newValue = _.map(value, text => {
      //       return text === 'MA' ? 'CA' : text;
      //     });
      //     if (Array.isArray(newValue) && newValue.length > 0) {
      //       return newValue.join('/');
      //     } else {
      //       return newValue == null || newValue.length == 0 ? '-' : newValue;
      //     }
      //   }
      // },
      {
        title: 'Vitals',
        width: '7%',
        key: 'vitals',
        dataIndex: 'vitalTypes',
        filters:
            curTab === 'Today'|| curTab === 'All'
            ? [
                { value: 'BP', text: 'BP' },
                { value: 'BG', text: 'BG' },
                { value: 'HS', text: 'HS' }
              ]
            : null,
        onFilter: (value, record) => record.vitalTypes.includes(value),
        render: value => {
          if (Array.isArray(value) && value.length > 0) {
            return value.join('/');
          } else {
            return value == null || value.length == 0 ? '-' : value;
          }
        }
      }
    ];
    const columnsToday = [
      {
        title: 'Note',
        key: 'note',
        // width: '13%',
        dataIndex: 'reason',
        render: (value, doc) => {
          let text = '';
          if (doc.conditionsList != null) {
            const keyPairArr = _.toPairs(doc.conditionsList);
            for (let i = 0; i < keyPairArr.length - 1; i++) {
              if (keyPairArr[i][1] != null) {
                text += keyPairArr[i][0] + ' ';
              }
            }
          }
          if (value == null || value.trim().length == 0) {
            return text === '' ? '-' : text;
          } else if (value.length > 0 && value.length <= 15) {
            return (
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {value + '\n' + text}
              </div>
            );
          } else if (value.length > 15) {
            return (
              <Popover
                style={{ whiteSpace: 'pre-wrap', width:'unset !important' }}
                content={
                  <div>
                    {value}
                    <br />
                    {text}
                  </div>
                }
              >
                <div>{value.substring(0, 16) + '...'}</div>
              </Popover>
            );
          }
        }
      },
      {
        title: 'Start In',
        width: '9%',
        dataIndex: 'appointmentAt',
        key: 'start in',
        render: (value, doc) => {
          const curMin = moment(value).format('HH:mm');
          const minutes = getMins(curMin);
          const onGoing = !doc.checkinAt === null;
          const finish = doc.checkoutAt != null;
          if (finish) {
            const finishMin = moment(doc.checkoutAt).format('HH:mm');
            const checkInMin = moment(doc.checkinAt).format('HH:mm');
            return <span>{checkInMin + '-' + finishMin}</span>;
          } else if (onGoing) {
            return <span style={{ color: '#FF0000' }}>On Going</span>;
          } else {
            if (minutes >= 180) {
              return <span>No Show</span>;
            } else if (minutes >= -120 && minutes < 0) {
              return (
                <span style={{ color: '#00FF00' }}>
                  {Math.abs(minutes) + 'min'}
                </span>
              );
            } else if (minutes < -120) {
              return <span></span>;
            } else if (minutes >= 0 && minutes < 180) {
              return <span>Waiting</span>;
            }
          }
        }
      },
      {
        title: 'Task',
        width: '5%',
        key: 'task',
        dataIndex: 'workingListStatus',
        render: (value, record) => {
          let href;
          switch (value) {
            case 'TODO':
              href = '/image/init_confirmed_file.png';
              return (
                <a>
                  <img src={href} style={{ width: '22px', height: '25px' }} />
                </a>
              );
            case 'PENDING':
              href = '/image/pending_file.png';
              return (
                <a>
                  <img src={href} style={{ width: '25px', height: '27px' }} />
                </a>
              );
            case 'COMPLETED':
              href = '/image/done_file.png';
              return (
                <a>
                  <img src={href} style={{ width: '25px', height: '27px' }} />
                </a>
              );
            default:
              href = '/image/init_confirmed_file.png';
              return (
                <a>
                  <img src={href} style={{ width: '22px', height: '25px' }} />
                </a>
              );
          }
        },
        onCell: (record, rowIndex) => ({
          onClick: () => {
            Mixpanel.track(
              'clicked',
              'Task List',
              "on Today's" + ' visit page',
              {}
            );
            return this.setShowTaskModal(
              Object.assign({ refetchVisitList: refetch }, record)
            );
          }
        })
      },
      {
        width: '5%',
        key: 'edit',
        render: (value, doc) => (
          <div onClick={() => this.onReschedule(doc)}>
            <a>
              <img
                src='/image/edit.png'
                style={{ width: '22px', height: '25px' }}
              />
            </a>
          </div>
        )
      },
      {
        title: 'Status',
        width: '9%',
        key: 'status',
        render: (value, doc) => {
          const curMin = moment(doc.appointmentAt).format('HH:mm');
          const minutes = getMins(curMin);
          const id = (doc.member && doc.member.id) || null;
          const visitId = doc.id;
          const checkInButtonProps = {
            type: null
          };
          let textBtn = '';
          if (doc.type === 'INIT') {
            //INIT: checkIn && checkOut => Done
            //      checkIn && !checkOut => checkOut
            //      !checkIn && !checkOut => Enroll
            if (doc.checkinAt === null) {
              checkInButtonProps.type = 'primary';
              textBtn = minutes >= 180 ? 'Reschedule' : 'Enroll';
            } else if (doc.checkinAt != null && doc.checkoutAt == null) {
              checkInButtonProps.type = 'primary';
              textBtn = 'Check Out';
            } else if (doc.checkinAt != null && doc.checkoutAt != null) {
              return <span>Done</span>;
            }
          } else {
            if (doc.checkinAt === null) {
              checkInButtonProps.type = 'primary';
              textBtn = minutes >= 180 ? 'Reschedule' : 'Check In';
            } else if (doc.checkinAt != null && doc.checkoutAt == null) {
              checkInButtonProps.type = 'primary';
              textBtn = 'Check Out';
            } else if (doc.checkinAt != null && doc.checkoutAt != null) {
              return <span>Done</span>;
            }
          }
          // const isAfterToday = moment(row.appointmentAt).isAfter(new Date(), 'day');
          // const PATIENT_ID = row.member && row.member.id || null;
          switch (textBtn) {
            case 'Reschedule':
              return (
                <Button
                  {...checkInButtonProps}
                  onClick={
                    () => {
                      Mixpanel.track('clicked', 'reschedule', 'on Today\'s visit page', {})
                      return this.onReschedule(doc)
                    }
                  }
                >
                  {textBtn}
                </Button>
              );
            case 'Enroll':
              return (
                <Button
                  {...checkInButtonProps}
                  style={{ width: '100px' }}
                  onClick={() => {
                    sessionStorage.setItem('backToCurVisit', JSON.stringify(doc));
                    goPath(`/patients/${id}/enrolledprogram/${visitId}_`);
                  }}
                >
                  {textBtn}
                </Button>
              );
            case 'Check In':
              return (
                <Popconfirm
                  onConfirm={() => onClickCheckIn(visitId, doc)}
                  title='Check in this Appointment?'
                >
                  <Button {...checkInButtonProps} style={{ width: '100px' }}>
                    {textBtn}
                  </Button>
                </Popconfirm>
              );
            case 'Check Out':
              return (
                <Button
                  {...checkInButtonProps}
                  ghost
                  style={{ width: '100px' }}
                  onClick={() => onClickCheckOut(visitId)}
                >
                  {textBtn}
                </Button>
              );
          }
        }
      }
    ];

    const columnsTomorrow = [
      {
        title: 'Note',
        key: 'note',
        dataIndex: 'reason',
        // render: (value) => (value == null || value.length == 0) ? "-" : <div>{value}</div>
        render: (value, doc) => {
          let text = '';
          if (doc.conditionsList != null) {
            const keyPairArr = _.toPairs(doc.conditionsList);
            for (let i = 0; i < keyPairArr.length - 1; i++) {
              if (keyPairArr[i][1] != null) {
                text += keyPairArr[i][0] + ' ';
              }
            }
          }
          if (value == null || value.trim().length == 0) {
            return text === '' ? '-' : text;
          } else if (value.length > 0 && value.length <= 30) {
            return (
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {value + '\n' + text}
              </div>
            );
          } else if (value.length > 30) {
            return (
              <Popover
                style={{ whiteSpace: 'pre-wrap', width:'unset !important' }}
                content={
                  <div>
                    {value}
                    <br />
                    {text}
                  </div>
                }
              >
                <div>{value.substring(0, 16) + '...'}</div>
              </Popover>
            );
          }
        }
      },
      {
        title: 'Task',
        width: '5%',
        key: 'task',
        dataIndex: 'confirm',
        render: (value, record) =>
          value ? (
            <a>
              <img
                src='/image/init_confirmed_file.png'
                style={{ width: '22px', height: '25px' }}
              />
            </a>
          ) : (
            <a>
              <img
                src='/image/init_file.png'
                style={{ width: '22px', height: '25px' }}
              />
            </a>
          ),
        onCell: record => ({
          onClick: () => {
            Mixpanel.track(
              'clicked',
              'Task List',
              "on Tomorrow's" + ' visit page',
              {}
            );
            if (record.confirm) {
              return this.setShowTaskModal(
                Object.assign({ refetchVisitList: refetch }, record)
              );
            } else {
              return;
            }
          }
        })
      },
      {
        width: '5%',
        key: 'edit',
        render: (value, doc) => (
          <div onClick={() => this.onReschedule(doc)}>
            <a>
              <img
                src='/image/edit.png'
                style={{ width: '22px', height: '25px' }}
              />
            </a>
          </div>
        )
      },
      {
        width: '9%',
        key: 'delete',
        render: (value, doc) => {
          const { id } = doc;
          return (
            <a>
              <span
                style={deleteText}
                onClick={() => {
                  props.showConfirmForCancel(id,true);
                }}
              >
                Delete
              </span>
            </a>
          );
        }
      }
    ];
    const columnAll = [
        {
            title: 'Note',
            key: 'note',
            dataIndex: 'reason',
            render: (value, doc) => {
                let text = '';
                if (doc.conditionsList != null) {
                    const keyPairArr = _.toPairs(doc.conditionsList);
                    for (let i = 0; i < keyPairArr.length - 1; i++) {
                        if (keyPairArr[i][1] != null) {
                            text += keyPairArr[i][0] + ' ';
                        }
                    }
                }
                if (value == null || value.trim().length == 0) {
                    return text === '' ? '-' : text;
                } else if (value.length > 0 && value.length <= 40) {
                    return (
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                            {value + '\n' + text}
                        </div>
                    );
                } else if (value.length > 40) {
                    return (
                        <Popover
                            style={{ whiteSpace: 'pre-wrap', width:'unset !important' }}
                            content={
                                <div>
                                    {value}
                                    <br />
                                    {text}
                                </div>
                            }
                        >
                            <div>{value.substring(0, 16) + '...'}</div>
                        </Popover>
                    );
                }
            }
        },
        {
            title: 'Task',
            width: '6%',
            key: 'task',
            dataIndex: 'workingListStatus',
            render: (value, record) => {
                let href;
                switch (value) {
                    case 'TODO':
                        href = '/image/init_confirmed_file.png';
                        return (
                            <a>
                                <img src={href} style={{ width: '22px', height: '25px' }} />
                            </a>
                        );
                    case 'PENDING':
                        href = '/image/pending_file.png';
                        return (
                            <a>
                                <img src={href} style={{ width: '25px', height: '27px' }} />
                            </a>
                        );
                    case 'COMPLETED':
                        href = '/image/done_file.png';
                        return (
                            <a>
                                <img src={href} style={{ width: '25px', height: '27px' }} />
                            </a>
                        );
                    default:
                        href = '/image/init_confirmed_file.png';
                        return (
                            <a>
                                <img src={href} style={{ width: '22px', height: '25px' }} />
                            </a>
                        );
                }
            },
            onCell: (record, rowIndex) => ({
                onClick: () => {
                    Mixpanel.track(
                        'clicked',
                        'Task List',
                        "on All" + ' visit page',
                        {}
                    );
                    return this.setShowTaskModal(
                        Object.assign({ refetchVisitList: refetch }, record)
                    );
                }
            })
        },
        {
            width: '7%',
            key: 'edit',
            render: (value, doc) => (
                <div 
                  onClick={
                    Mixpanel.track(
                        'clicked',
                        'Edit',
                        "on All" + ' visit page',
                        {}
                    ),
                    () => this.onReschedule(doc)
                  }>
                    <a>
                        <img
                            src='/image/edit.png'
                            style={{ width: '22px', height: '25px' }}
                        />
                    </a>
                </div>
            )
        },
    ]
    const parseDateSource = curTab === 'Today' ? _.filter(data, d=> d.confirm) : data;
    const tableProps = {
      columns:
        curTab === 'Today' ? columns.concat(columnsToday) : curTab=='Tomorrow'
          ? columns.concat(columnsTomorrow): columns.concat(columnAll).slice(1),
      dataSource: parseDateSource,
      rowKey: (row, index) => index,
      // onChange:
      //   curTab === 'Today'
      //     ? null
      //     : (a, b, c) => this.props.onTableChange(a, b, c),
      loading: { spinning: !!loading, indicator: <IHLoading /> }
    };

    const noDataElem = (
      <div>
        <p style={{ fontSize: '14px' }}>No appointments scheduled</p>
      </div>
    );

    const rowClassName = record => {
      const { checkinAt, checkoutAt } = record;
      return !checkinAt && !checkoutAt
        ? `hideInfo appointmentListRow`
        : 'appointmentListRow';
    };

    return (
      <div>
        {this.state.showReschedule ? (
          <EditVisitInDashboardContainer
            refetch={this.props.refetch}
            visitInfo={this.state.showReschedule}
            onCancel={this.onReschedule}
          />
        ) : (
          ''
        )}
        {this.renderTaskModal()}

        <Table
          className='appointmentList'
          noDataElem={noDataElem}
          {...tableProps}
          pagination={false}
          rowClassName={record => rowClassName(record)}
        />
        { curTab=='Today' ? this.renderPendingConfirmVisits() :'' }
      </div>
    );
  }
};

const mapToDispatch = dispatch => {
  return {
    openErrorModal: error => dispatch(openErrorModal(error)),
    openConfirmModal: props => dispatch(actions.confirm(props))
  };
};
export default connect(null, mapToDispatch)(AppointmentListComponent);
