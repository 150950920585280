import React from 'react';
import { Table,Radio,Icon } from 'antd';
import BGMAP from '../constants/BGMap';
import { convertBG_mmolTomgdl } from '../../../lib/utils/convertUnits';
const ResultStatisticsComponent  = class extends React.Component {
      renderLowBSRisk(){
          const  { stat={ } } = this.props;
          const source =[];
          const { total } = stat;
          Object.entries(stat).forEach(e=>
          {
              const countKey = _.get(BGMAP,`count.${e[0]}`);
              const valueKey = _.get(BGMAP,`value.${e[0]}`);
              const value = e[1];

              if(countKey){
                  source.push({
                      value,
                      key:countKey,
                      countKey
                  })
              }else if(valueKey){
                  source.push({
                      value,
                      key:valueKey,
                      valueKey
                  })
              }

          });
          const columns=[
              {
                  title:'',
                  key:'key',
                  dataIndex:'key'
              },
              {
                  title:'',
                  render:(_,{ countKey,value,valueKey })=> countKey ? `#${value}` : valueKey ?`${convertBG_mmolTomgdl(value)} mg/dL`:'',
                      // `${doc} (${ Number(
                      // (doc||0)/(total!=0 ? total: 1)*100).toFixed(1) }%)` : ``,
                  key:'value',
                  dataIndex:'value'
              }
          ];

          return <Table dataSource={source}
                        rowKey={(record,index) => index}
                        columns={columns}
                        showHeader={false}
                        pagination={false}
                        bordered={false}
                        title={()=><span>Blood Glucose Summary</span>}
                        rowClassName='statisticsTableRow'
                        className='statisticsTable'
          />


      }

      // renderPairedBGMeasure(){
      //     const  { stat } = this.props;
      //     console.log(stat);
      //     const { pairCount,deltaHighCount,deltaAverage,deltaMin,deltaMax } = stat ? stat.pairedMeasurement : { };
      //     const source = [{pairCount},{deltaHighCount},{deltaAverage},{deltaMin},{deltaMax}];
      //     const columns=[
      //         {
      //             title:'',
      //             render:(text,record,index)=>map[Object.keys(record)[0]]||{},
      //             key:1
      //         },
      //         {
      //             title:'',
      //             render:(text,record,index)=>{
      //                 const key = Object.keys(record)[0];
      //                 const value = record[key];
      //                 if(key=='deltaAverage'||key=='deltaMin'||key=='deltaMax'){
      //                     return convertBG_mmolTomgdl(value||0);
      //                 }
      //                 return value;
      //             },
      //             key:2
      //         }
      //     ]
      //
      //     return <Table dataSource={source}
      //                   rowKey={(record,index) => index}
      //                   columns={columns}
      //                   showHeader={false}
      //                   pagination={false}
      //                   bordered={false}
      //                   title={()=><span>Paired Blood Glucose Measurement</span>}
      //                   rowClassName='statisticsTableRow'
      //                   className='statisticsTable'
      //     />
      //
      //
      // }

      render(){
          const { loading} = this.props;
          if(loading){
              return <div>Loading</div>
          }
          return<div>
                  { this.renderLowBSRisk() }
                    {/*<div className='tableDivider'></div>*/}
                  {/*{ this.renderPairedBGMeasure() }*/}
                </div>
      }
}


export default  ResultStatisticsComponent;
