import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { compose, graphql } from 'react-apollo'
import _ from 'lodash'
import uniqBy from 'lodash/uniqBy'
import { TASK_TYPE_MAP } from 'modulesAll/utils/constants/task'

//files
import ProviderAlertList from '../components/AlertTableComponent';
import { onAddClick } from '../actions/AlertTableActions'
import createTableAction from 'libModule/table/TableActions';
import InterventionNotes from '../../../alerts/InterventionNotes/containers/InterventionNotes'
import { Modal } from 'antd';
//queries
import resultList from 'modulesAll/graphql/resultList'
import UserClass from 'graphqlModule/class/User'

const tableActions = createTableAction('patient_alert_list', {
  sortFieldMap: { date: 'DATE' }
});

const vitalFilters = (resultList) => {
  return uniqBy(resultList || [], 'type').map( (d) => {
    return {
      value: _.get(d, 'type'),
      text: TASK_TYPE_MAP[_.get(d, 'type')]
    }
  })
}

const Container = class extends React.Component {
  static displayName = 'PatientAlertTableContainer'

  constructor(p) {
    super(p)
    this.adminProgramFilter = UserClass.getAdminProgramFilter()
    this.state = {
        showAlertModal: false,
        alert:{ }
    }
  }
  
  componentWillMount() {
    this.props.onLoadParams(null, true);
  }

  setShowAlertModal= (flag)=>{
    this.setState({
        showAlertModal: flag
    })
  }

  setAlertData = (alert)=>{
      this.setState({
          alert
      })
  }

  renderAlertModal=()=>{
     const { showAlertModal,alert } = this.state;
     if(showAlertModal){
         return <Modal visible={true} className='addAlertModal' width={1000} style={{marginTop:'-50px'}} footer={ null }
                       onCancel={()=>this.setState({showAlertModal:false})}
                       maskClosable={false}
                >
                    <InterventionNotes row={alert} noteType='alert_note'  setCategoryToAlerts={true} closeModal={()=>this.setState({showAlertModal:false})} />
                </Modal>
     }
  }

  render() {
    const props = {
      ...this.props,
      adminProgramFilter: this.adminProgramFilter
    }
    const { showAlertModal } = this.state;
    return <div>
                { this.renderAlertModal() }
                <ProviderAlertList {...props} variables = { getVariables(this.props) }
                                   showAlertModal={ showAlertModal }
                                   setAlertData = { this.setAlertData }
                                   setShowAlertModal = { this.setShowAlertModal }

                />
           </div>
  }
}

const getVariables = (ownProps)=>{
  let variables =  {
      filters: {
          severity: ['MISSED', 'RISK', 'CRITICAL'],
          'enrolledProgramId': ownProps.enrolledProgramId,
          'type': _.get(ownProps, 'filter.type'),
          'adminProgramId': _.get(ownProps, 'filter.programId'),
          'careStatus': _.get(ownProps, 'filter.careStatus'),
          alertsOnly: true
      },
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      sort: { field: 'DATE', direction: 'DESC'}
  }
    const sortField = _.get(ownProps, 'sort.fieldGraph', null);
    if (sortField) {
        variables.sort = {
            field: sortField,
            direction: _.get(ownProps, 'sort.direction', 'DESC'),
        };
    }
  return variables;
}

const withData = graphql( resultList, {
  options: (ownProps) => {
    let variables = getVariables(ownProps);
    // const  variables = {
    //   filters: {
    //     severity: ['MISSED', 'RISK', 'CRITICAL'],
    //     'enrolledProgramId': ownProps.enrolledProgramId,
    //     'type': _.get(ownProps, 'filter.type'),
    //     'adminProgramId': _.get(ownProps, 'filter.programId'),
    //     'careStatus': _.get(ownProps, 'filter.careStatus'),
    //     alertsOnly: true
    //   },
    //   page: _.get(ownProps, 'page.current', 1),
    //   count: 10,
    //   sort: { field: 'DATE', direction: 'DESC'}
    // }
    // const sortField = _.get(ownProps, 'sort.fieldGraph', null);
    // if (sortField) {
    //   variables.sort = {
    //     field: sortField,
    //     direction: _.get(ownProps, 'sort.direction', 'DESC'),
    //   };
    // }

    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    };
  },
  
  props: ({ownProps, data}) => {
    const { loading, refetch } = data
    return {
      alertList: _.get(data, 'resultList'),
      vitalFilters: vitalFilters((_.get(data, 'resultList.data') || [])),
      loading,
      refetch,
    }
  }
})

const mapState = ({ globalTables }, ownProps) => globalTables.patient_alert_list
const mapDispatch = (dispatch) => ({
  ...tableActions,
  onAddClick: (row,setCategoryToAlerts) => dispatch(onAddClick(row,setCategoryToAlerts))
})

Container.propTypes = {
  sorter: PropTypes.object,
  pagination: PropTypes.object,
  currentPage: PropTypes.number,
  refetch: PropTypes.func,
}

export default compose(
  connect(mapState, mapDispatch),
  withData
)(Container)
