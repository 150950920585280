/**
 * Created by zizhangai on 1/25/17.
 */
import { constants } from 'libModule/utils';

export default constants('Alters', 'Interventions', [
  'ADD_NOTE',
  'OPEN_INPUT',
  'CLOSE_INPUT',
  'ADD_REASON',
  'DISABLE_BUTTON',
  'ENABLE_BUTTON',
  'CLEAR_STATE',
  'DELETE_STATE',
  'SWITCH_VIEW'
])
