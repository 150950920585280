import React from 'react';
import { Form,Row,Col, } from 'antd';
import map from "../constant/workListConstants";
import singleVisit from "../../graphql/signleVisit";
const Item = Form.Item;
import Client from 'libModule/gqlClient';
import updateVisitWorkingList from '../../graphql/mutation/updateVisitWorkingList';
const leaveAndSaveWorkTask = (MAWorkingListKey,RDWorkingListKey,props)=>{
    const { visit ,refetch } = props;
    const { id } = visit;
    const MAWorkingList = _.get(map,'init.MAWorkingList');
    const RDWorkingList = _.get(map,'init.RDWorkingList');
    let { maWorkingList={},rdWorkingList={} } = visit;
    let parsedMaWorkingList = {};
    let parsedRDWorkingList = {};

    for (let [index, key] of Object.entries(MAWorkingList)) {
        parsedMaWorkingList[key]  = key===MAWorkingListKey ? 'PENDING' : !_.get(maWorkingList,key) ? 'TODO': _.get(maWorkingList,key) ;
    }

    for (let [index, key] of Object.entries(RDWorkingList)) {
        parsedRDWorkingList[key]  = key===RDWorkingListKey ? 'PENDING' : !_.get(rdWorkingList,key) ? 'TODO': _.get(rdWorkingList,key);
    }
    return Client.mutate({
        mutation: updateVisitWorkingList,
        variables:{
            id,
            maWorkingList: _.pick(parsedMaWorkingList,MAWorkingList),
            rdWorkingList: _.pick(parsedRDWorkingList,RDWorkingList)
        },
        refetchQueries:[{
            query:singleVisit,
            variables:{
                id
            },
            awaitRefetchQueries:true,
            fetchPolicy:'network-only'
        }]
    })
}
const createForm = (localThis,config,formClass, handleCheck, openModal, phoneInfo) =>{
    const { getFieldDecorator,loading } = localThis.props.form;
    return(
        <Form layout='vertical' className={formClass}>
            {  _.map(config,(c,i)=>
                <Row key={i} style={{ display:'flex' }} gutter={16}>
                {
                _.map(c,(arrayItem,index)=> {
                    const { render,key,span,colStyle,wrapperCol,label,labelCol,col,itemStyle,className,renderOnly,offset,initialData,path,valuePropName,type,rules,disabled,changeToBoolean,firstPath } = arrayItem;
                    let data =  firstPath ? _.get(localThis,`props.${firstPath}`,_.get(localThis,`props.${path}`)): _.get(localThis,`props.${path}`) ;
                    //console.log(`data ${key}`, data);
                    //console.log(`initial ${key}`, initialData);
                    if(changeToBoolean){
                        data = changeToBoolean(data);
                    }
                    const action = {
                        trackSet: e => handleCheck(key, e),
                        iPhone: e => handleCheck(key, e),
                        editTrackSet: () => openModal('trackSet'),
                        appVersion: phoneInfo.version,
                        unassignIPhone: () => openModal('iPhone'),
                    }
                    return(
                             <Col span={span} key={index} style={colStyle} offset={offset}>
                                 {
                                   typeof renderOnly=='function' ? renderOnly() :
                                       <Item layout={'vertical'} key={key} label={label || ''}
                                             style={itemStyle} className={className}
                                       >

                                         {
                                             getFieldDecorator(key,{
                                              rules,
                                              initialValue: data || initialData,
                                              valuePropName: valuePropName || 'value',
                                              disabled
                                            })(render(action[key]))
                                         }
                                     </Item>
                                 }
                            </Col>
                    )})
                }
                </Row>
            )}
        </Form>
    )
}


export default {
    createForm,
    leaveAndSaveWorkTask,
}
