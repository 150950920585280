
import {
  React, ND,
} from '../IHComponents/util';

import { compose, withHandlers } from 'recompose'
import * as R from 'ramda';

import FaMoon from 'react-icons/lib/fa/moon-o';
import FaBed from 'react-icons/lib/fa/bed';

const { Icon } = ND;

const timeNames = [
  'before_breakfast',
  'after_breakfast',
  'before_lunch',
  'after_lunch',
  'before_dinner',
  'after_dinner',
  'bedtime',
  'overnight',
];

const days = [
  'mon',
  'tues',
  'wed',
  'thurs',
  'fri',
  'sat',
  'sun',
];

const WeeklyMealScheduleInputComponent = ({ value, onClick }) => {
  return (
    <table className='weeklymeal-schedule'>
    <thead>
      <tr style={{ height: 36 }} >
        <th style={{ width: 45 }} rowSpan={2}/>
        <th colSpan={2}>Breakfast</th>
        <th colSpan={2}>Lunch</th>
        <th colSpan={2}>Dinner</th>
        <th rowSpan={2}><FaBed style={{ fontSize: 22 }} /></th>
        <th rowSpan={2}><FaMoon style={{ fontSize: 22 }} /></th>
      </tr>
      <tr style={{ height: 37 }} >
        <th>B</th>
        <th>A</th>
        <th>B</th>
        <th>A</th>
        <th>B</th>
        <th>A</th>
      </tr>
    </thead>
    <tbody>
      { R.addIndex(R.map)((day, i)=>{
        const d = R.toUpper(R.take(1, day));
        return (
          <tr key={i} >
            <th>{d}</th>
            { R.addIndex(R.map)((timeName, i)=>{
              const dataPath = [day, timeName];
              const dataValue = R.path(dataPath, value);
              // console.log('WeeklyMealScheduleInputComponent dataPath', dataPath, dataValue, value);
              return dataValue 
                ? <td onClick={()=>onClick(dataPath, !dataValue)} key={i} style={{ backgroundColor: '#67afe2' }} ><Icon type="check" style={{ color: 'white', fontSize: 26 }} /></td>
                : <td onClick={()=>onClick(dataPath, !dataValue)} key={i} style={{ backgroundColor: 'white' }} />
            }, timeNames)}
          </tr>
        )}, days) }
    </tbody>
  </table>
  );
};

const enhance = compose(
  withHandlers({
    onClick: props => (dataPath, value) => {
      !props.allowEdit ? null : props.onChange(R.assocPath(dataPath, value, props.value))
    },
  })
);

export const WeeklyMealScheduleInputGrid = enhance(WeeklyMealScheduleInputComponent);