import CreateNewTaskComponent from '../components/CreateNewTaskComponent';
import React from "react";
import { graphql, withApollo, compose } from 'react-apollo';
import getTaskAssignmentsByPatientId from '../query/getTaskAssignmentsByPatientId';
import enrolledProgram from "graphqlModule/enrolledProgram"

let CreateNewTaskContainer = class extends React.Component {

    render(){
        const { props } = this;
        return <CreateNewTaskComponent {...props}/>
    }
}

const withData = graphql(getTaskAssignmentsByPatientId,{
    options:(props)=>{
        const memberId  = _.get(props,'memberId',_.get(props,'modalData.memberId'));
        return{
            variables:{
                memberId
            },
            fetchPolicy:'network-only'
        }
    },
    props:({ data })=>{
        const { getTaskAssignmentsByPatientId, loading,refetch } = data;
        if(loading) {
            return {
                loading
            }
        }
        return {
            taskList:_.get(getTaskAssignmentsByPatientId,'taskAssignments',[]),
            refetch
        }
    }
});
const team = graphql(enrolledProgram,{
    options:(props)=>{
        const { currentProgramId } = props
        return{
            variables:{
                id:currentProgramId
            }
        }
    },
    props:({data})=>{
        const { loading } = data;
        if(loading) {
            return {
                loading
            }
        }
        return {
            team:_.get(data,'enrolledProgram.team')
        }
    }

})

export  default compose(withData,team)(CreateNewTaskContainer);
