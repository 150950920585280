import React from 'react';
import { Form, Select, Row, Col, DatePicker, Button, Input, Icon } from 'antd';
import VISIT_TYPE_ENUM from '../../visit/constants/map';
import { helpers } from '../../visit/helpers';
import I18N from 'modulesAll/I18N';
import visitMap from '../constant/visitConstMap';
import { API } from '../../visit/query/index';
import moment from 'moment';
const teamRoles = I18N.get('teamRoles');
const FormItem = Form.Item;
const { minMap, hourMap } = visitMap;
const { Option } = Select;
const { createVisit, rescheduleVisit } = API;
import { compose, graphql } from 'react-apollo/index';
import visitList from 'modulesAll/graphql/visitList';

import workListHelpers from '../helpers/';
import { message } from '../../../../../package/IHComponent';
const { leaveAndSaveWorkTask } = workListHelpers;
const disabledDate = current => {
  // Can not select days before today and today
  //console.log(current.isoWeekday());
 //  console.log(current.weekday());
  return current && current < moment().startOf('day') || current.isoWeekday() === 6 || current.isoWeekday() === 7;
};
const checkVisitRoles = (rule, value, callback) => {
  if (value.length === 1 && value[0] === 'MD') {
    callback("You can't schedule a visit only for MD.");
  }
  callback('');
};
let FollowUpVisitComponent = class extends React.Component {
  renderForm = () => {
    const followUpVisit = _.get(this, 'props.visit.associatedVisit', {});
    const { getFieldDecorator } = this.props.form;
    const doctorOptions = helpers.filterDoctors();
    const itemList = [
      [
        {
          key: 'type',
          label: 'Type',
          type: 'radioButton',
          className: 'radio-button add_medicine_header',
          required: true,
          rules: [{ required: true }],
          initialValue: 'FOLLOW_UP',
          render: () => (
            <Select>
              {_.map(VISIT_TYPE_ENUM, (type, val) => {
                return (
                  <Option value={val} key={val}>
                    {type}
                  </Option>
                );
              })}
            </Select>
          ),
          span: 6
        },
        {
          key: 'provider',
          label: 'Provider',
          render: () => (
            <Select filterOption={false}>
              {_.map(doctorOptions, program => {
                return (
                  <Option value={program.id} key={program.id}>
                    {program.fullName}
                  </Option>
                );
              })}
            </Select>
          ),
          rules: [{ required: true }],
          initialValue: _.get(followUpVisit, 'providers.0.id'),
          span: 6
        },
        {
          key: 'visitRoles',
          label: 'Visit',
          render: () => (
            <Select mode='multiple'>
              {_.map(teamRoles, (type, val) => {
                return (
                  <Option value={val} key={val}>
                    {type === 'MA' ? 'CA' : type}
                  </Option>
                );
              })}
            </Select>
          ),
          rules: [
            // {
            //     validator:(rule,value,callback)=>{
            //         console.log(value);
            //         if(!value){
            //             console.log('value');
            //             callback('Visit Roles is Required');
            //         }
            //         if(value.length === 1 && value[0] ==='MD'){
            //             callback("You can't schedule a visit only for MD.");
            //         }
            //             callback();
            //         }
            //     },
            { required: true, message: 'Visit Roles is required' }
          ],
          initialValue: _.get(followUpVisit, 'visitRoles'),
          span: 6
        }
      ],
      [
        {
          key: 'date',
          label: 'Select a Date',
          render: () => <DatePicker disabledDate={disabledDate} />,
          rules: [{ required: true }],
          message: 'Select a Date',
          initialValue: moment(_.get(followUpVisit, 'appointmentAt')),
          span: 6
        },
        {
          key: 'hour',
          label: 'Hour',
          render: () => (
            <Select>
              {_.map(hourMap, (h, i) => (
                <Option value={h} key={i}>{h}</Option>
              ))}
            </Select>
          ),
          rules: [{ required: true }],
          initialValue: _.get(followUpVisit, 'appointmentAt')
            ? moment(_.get(followUpVisit, 'appointmentAt')).format('HH')
            : null,
          span: 3
        },
        {
          key: 'min',
          label: 'Min',
          render: () => (
            <Select>
              {_.map(minMap, (m, i) => (
                <Option value={m} key={i}>{m}</Option>
              ))}
            </Select>
          ),
          initialValue: _.get(followUpVisit, 'appointmentAt')
            ? moment(_.get(followUpVisit, 'appointmentAt')).format('mm')
            : minMap[0],
          rules: [{ required: true }],
          span: 3
        },
        {
          key: 'notes',
          label: 'Notes',
          span: 6,
          // rules: [{ required: false }],
          initialValue: _.get(followUpVisit, 'reason')
        }
      ]
    ];
    return _.map(itemList, (arr, i) => (
      <Row key={i} style={{ display: 'flex' }} gutter={20}>
        {_.map(arr, (value, index) => {
          return (
            <Col
              key={i * 10 + index}
              span={value.span || 8}
              style={{ display: value.display }}
            >
              <FormItem label={value.label} key={value.key}>
                {getFieldDecorator(value.key, {
                  rules: value.rules,
                  initialValue: value.initialValue,
                  valuePropName: value.valuePropName || 'value'
                })(value.render ? value.render(value) : <Input />)}
              </FormItem>
            </Col>
          );
        })}
      </Row>
    ));
  };

  combineDateHourAndMin = (date, hour, min) => {
    return moment(date)
      .hour(hour)
      .minute(min);
  };

  saveEdit = (flag, setEditMode, msg) => {
    const { toNextStep, visit } = this.props;
    const { getFieldsValue } = this.props.form;
    const { userId } = this.props;
    const visitId = _.get(visit, 'id');
    const hasFollowUpVisit = _.get(this, 'props.visit.associatedVisit');
    let {
      date,
      min,
      hour,
      notes,
      visitRoles,
      type,
      provider
    } = getFieldsValue();
    let variables = {
      memberId: userId,
      type: type,
      appointmentAt: this.combineDateHourAndMin(
        date,
        parseInt(hour),
        parseInt(min)
      ),
      appointmentTo: this.combineDateHourAndMin(
        date,
        parseInt(hour),
        parseInt(min + 30)
      ),
      providers: [provider],
      reason: notes,
      visitRoles: visitRoles
    };
    const thenFn = () => {
      // add or update followup visit (in case if happening in the same day)
      if(this.props.refetchVisitList) this.props.refetchVisitList();
      flag ? toNextStep('FollowUpVisit', null, msg) : message.success(msg);
      setEditMode ? setIsEditMode(false) : '';
    };
    if (!hasFollowUpVisit) {
      createVisit(variables).then(res => {
        const associatedVisitId = _.get(res, 'data.createVisit.id');
        // associate new visit to current visit
        rescheduleVisit({
          id: visitId,
          associatedVisitId
        }).then(res => {
          thenFn();
          message.success('Follow Visit is Scheduled');
        }).catch(err => console.log('has no followup', err));
      });
    } else {
      variables.id = hasFollowUpVisit.id;
      rescheduleVisit(variables).then(res => {
        thenFn();
        message.success('Follow Visit is Rescheduled');
      }).catch(err => console.log('has followup', err));
    }
  };

  handleClick = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll((error, res) => {
      if (!error) {
        this.saveEdit(true, false, 'Follow Up Visit is Saved');
      }
    });
  };

  render() {
    const { props } = this;
    const { visit } = props;
    const hasFollowUp = _.get(visit, 'associatedVisit.id');
    return (
      <div>
        <Form>
          {this.renderForm()}
          {/*<Button onClick={()=>this.handleClick()}>{ hasFollowUp ? 'Save Edit':'Create Visit'}</Button>*/}
        </Form>
        <div style={{ float: 'right', paddingTop: 10 }}>
          <Button
            onClick={() => this.handleClick()}
            type={'primary'}
            style={{ width: 130, marginRight: 10 }}
          >
            Complete
          </Button>
        </div>
      </div>
    );
  }
};
// const followUp = graphql(visitList,{
//     options:(ownProps)=>{
//         const { userId } = ownProps;
//         let variables = {
//             memberId:userId,
//             type:['FOLLOW_UP'],
//             count:1,
//             sort:{
//                 field:'CREATED_AT',
//                 direction:'DESC'
//             }
//         }
//         return {
//             variables,
//             fetchPolicy:'network-only'
//         }
//     },
//     props:({data})=>{
//         const { loading,visitList,refetch } = data;
//         if(loading){
//             return {
//                 loading
//             }
//         }
//         return {
//             followUpVisit: _.first(_.get(visitList,'data',[]),'createdAt'),
//             refetch
//         }
//     }
// })
FollowUpVisitComponent = Form.create({})(FollowUpVisitComponent);

// export default compose(followUp)(FollowUpVisitComponent);
export default FollowUpVisitComponent;
