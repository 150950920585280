import gql from 'graphql-tag'
import {EnrolledProgram} from 'graphqlModule/schema/EnrolledProgram'

export default gql`
    mutation enrollMember(
        $id:EID!,
        $memberId:EID!,
        $teamId:[EID]!,
        $managerId:[EID]
        $startDate:Date!,
        $endDate:Date
    ){
        enrollMember(
            adminProgramId: $id,
            memberId: $memberId,
            teamId: $teamId,
            managerId: $managerId,
            startDate: $startDate,
            endDate: $endDate
        ){
            ${EnrolledProgram}
        }

    }
`
