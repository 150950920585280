export default {
  program: 'program',
  programs: 'programs',
  Program: 'Program',
  Programs: 'Programs',
  PROGRAM: 'PROGRAM',
  SelectProgramTemplate:'Select Program Template:',
  assessment: 'assessment',
  assessments: 'assessments',
  Assessment: 'Assessment',
  Assessments: 'Assessments',
  'NRIC/FIN': 'Medical Record Id',
  'ProviderNRIC/FIN': 'Employee Id',
  'CaregiverNRIC/FIN': 'Login Id',
  'N/A': 'N/A',
  'PHONE':'Phone Number'
};
