import I18N from 'modulesAll/I18N'
import { convertUnits } from 'libModule/utils/convertUnits'

const VSM_US_units = convertUnits('VSM_US');

// vital alert form constants
export const MAX_ALERT_TAB_COUNT = 100
export const MAX_ASSESSMENT_ALERT_TAB_COUNT = 100

export const DEFAULT_ALERT_MISSED_READING = 1

export const MISSED_TYPE_VALUE = 'Missed Reading'
export const THRESHOLD_TYPE_VALUE = 'Threshold'

export const ALERT_TRIGGERS_TYPE = [ 'LOW-HIGH', 'CRITICAL_LOW-CRITICAL_HIGH' ]

export const ALERT_TRIGGERS_OPTIONS = [
  { name: 'Low and High', value: 'LOW-HIGH' },
  { name: 'Critically Low and Critically High', value: 'CRITICAL_LOW-CRITICAL_HIGH' }
]

export const ALERT_SEND_VIA_TYPE_MAP = {
  SMS: 'SMS',
  EMAIL: 'Email'
}
export const SEND_VIA_TYPES = Object.keys(ALERT_SEND_VIA_TYPE_MAP)
export const SEND_VIA_OPTIONS = SEND_VIA_TYPES.map(key => ({name: ALERT_SEND_VIA_TYPE_MAP[key], value: key}))

export const ALERT_SEND_TO_TYPE_MAP = {
  DASHBOARD: 'Dashboard',
  CARE_TEAM: 'Care Team(s)',
  MEMBER: 'Patient',
}
export const SEND_TO_TYPES = Object.keys(ALERT_SEND_TO_TYPE_MAP)
export const SEND_TO_OPTIONS = SEND_TO_TYPES.map(key => {
  const result = {name: ALERT_SEND_TO_TYPE_MAP[key], value: key}
  if (key === 'DASHBOARD') {
    result.disabled = true
    result.selected = true
  }
  return result
})

// SYSTOLIC
// DIASTOLIC
// SYSTOLIC_OR_DIASTOLIC
// HEART_RATE
// BEFORE_MEAL
// AFTER_MEAL
// BEFORE_OR_AFTER_MEAL
// WEIGHT
// WEIGHT_CHANGE
// WEIGHT_CHANGE_BASELINE
// BLOOD_OXYGEN
// PERFUSION_INDEX
export const ALERT_FORMULA_MAP = {
  'BP': 'SYSTOLIC',
  'BG': 'BEFORE_OR_AFTER_MEAL',
  'HS': 'WEIGHT_CHANGE',
  'WEIGHT_CHANGE_BASELINE': 'WEIGHT_CHANGE_BASELINE',
  'HR': 'HEART_RATE',
  'TM': 'BODY_TEMPERATURE',
  'PO': 'BLOOD_OXYGEN',
}

const alertTmpl = I18N.get('Program.alertTmpl.tmplBody')
export const ALERT_MESSAGE_MAP = {
  [MISSED_TYPE_VALUE]: alertTmpl.missed.message,
  [THRESHOLD_TYPE_VALUE]: alertTmpl.threshold.message
}

export const ALERT_MESSAGE_MAP_RAW = {
  [MISSED_TYPE_VALUE]: alertTmpl.missed.raw,
  [THRESHOLD_TYPE_VALUE]: alertTmpl.threshold.raw
}

export const DEFAULT_ALERT_MESSAGE = alertTmpl.missed.message

// end of vital alert form constants

export const VITAL_TYPE_MAP = {
  'Blood Pressure': 'BP',
  'Blood Glucose': 'BG',
  'Weight': 'HS',
  'Heart Rate': 'HR',
  'Temperature': 'TM',
  'Blood Oxygen': 'PO',
}

export const VITAL_TYPE_MAP_FROM_SHORT = {
  'BP': 'Blood Pressure',
  'BG': 'Blood Glucose',
  'HS': 'Weight',
  'HR': 'Heart Rate',
  'TM': 'Temperature',
  'PO': 'Blood Oxygen',
}

export const TASK_TYPE_MAP = {
  ...VITAL_TYPE_MAP_FROM_SHORT,
  'ASSESSMENT': I18N.get('keywords.Assessment'),
  'SURVEY': 'Survey'
}
// export const VITAL_TYPES_LONG = Object.keys(VITAL_TYPE_MAP)
export const VITAL_TYPES_LONG = Object.keys(VITAL_TYPE_MAP).filter( (type) => type !== 'Heart Rate' );
export const VITAL_TYPES_SHORT = Object.keys(VITAL_TYPE_MAP_FROM_SHORT)


export const BG_MEAL_TYPES = [
  'BEFORE_BREAKFAST',
  'AFTER_BREAKFAST',
  'BEFORE_LUNCH',
  'AFTER_LUNCH',
  'BEFORE_DINNER',
  'AFTER_DINNER',
  'BEFORE_SUPPER',
  'AFTER_SUPPER'
]

export const HS_FULL_NAME = VITAL_TYPE_MAP_FROM_SHORT['HS']
export const HR_FULL_NAME = VITAL_TYPE_MAP_FROM_SHORT['HR']
export const BG_FULL_NAME = VITAL_TYPE_MAP_FROM_SHORT['BG']
export const BP_FULL_NAME = VITAL_TYPE_MAP_FROM_SHORT['BP']
export const TM_FULL_NAME = VITAL_TYPE_MAP_FROM_SHORT['TM']
export const PO_FULL_NAME = VITAL_TYPE_MAP_FROM_SHORT['PO']

// vitals that only have one type, e.g. HEART_RATE, as opposed to vitals that have two types, e.g. BEFORE_MEAL and AFTER_MEAL
export const VITALS_WITH_SINGLE_TYPE = [HS_FULL_NAME, HR_FULL_NAME, TM_FULL_NAME, PO_FULL_NAME];

export const VITAL_UNIT_MAP = {
  'Blood Pressure': 'mmHg',
  'Blood Glucose': 'mmol/L',
  'Weight': 'kg',
  'Heart Rate': 'beats/min',
  'Temperature': 'C',
  'Blood Oxygen': '%',
}

export const VITAL_DISPLAY_UNIT_MAP = {
  'Blood Pressure': 'mmHg',
  'Blood Glucose': I18N.get('measure.units_BG_US_standard'),
  'Weight': I18N.get('measure.units_weight_lbs'),
  'Heart Rate': 'beats/min',
  'Temperature': 'C',
  'Blood Oxygen': '%',
}

export const VITAL_THRESHOLD_UNIT_MAP = {
  systolic: 'mmHg',
  diastolic: 'mmHg',
  BGBeforeMeal: VSM_US_units.BG.unit,
  BGAfterMeal: VSM_US_units.BG.unit,
  // BGBeforeMeal: 'mmol/L',
  // BGAfterMeal: 'mmol/L',
  // HS: 'kg',
  HS: VSM_US_units.HS.unit,
  HR: 'beats/min',
  TM: 'C',
  PO: '%',
}

export const HR_UNIT = VITAL_UNIT_MAP['Heart Rate']
export const BP_UNIT = VITAL_UNIT_MAP['Blood Pressure']
export const WEIGHT_UNIT = VITAL_UNIT_MAP['Weight']
export const TM_UNIT = VITAL_UNIT_MAP['Temperature']
export const BG_UNIT = VITAL_UNIT_MAP['Blood Glucose'];
export const TEMP_UNIT = VITAL_UNIT_MAP['Temperature'];
export const PO_UNIT = VITAL_UNIT_MAP['Blood Oxygen'];

export const HR_DISPLAY_UNIT = VITAL_DISPLAY_UNIT_MAP['Heart Rate']
export const BP_DISPLAY_UNIT = VITAL_DISPLAY_UNIT_MAP['Blood Pressure']
export const WEIGHT_DISPLAY_UNIT = VITAL_DISPLAY_UNIT_MAP['Weight']
export const TM_DISPLAY_UNIT = VITAL_DISPLAY_UNIT_MAP['Temperature']
export const BG_DISPLAY_UNIT = VITAL_DISPLAY_UNIT_MAP['Blood Glucose'];
export const TEMP_DISPLAY_UNIT = VITAL_DISPLAY_UNIT_MAP['Temperature'];
export const PO_DISPLAY_UNIT = VITAL_DISPLAY_UNIT_MAP['Blood Oxygen'];

export const VITAL_THRESHOLD_MAP = {
  SYSTOLIC: 'systolic',
  DIASTOLIC: 'diastolic',
  BEFORE_MEAL: 'BGBeforeMeal',
  AFTER_MEAL: 'BGAfterMeal',
  WEIGHT_CHANGE: 'HS',
  WEIGHT: 'HS',
  WEIGHT_CHANGE_BASELINE: 'HS',
  WEIGHT_BASELINE: 'HS',
  HEART_RATE: 'HR',
  BODY_TEMPERATURE: 'TM',
  BLOOD_OXYGEN: 'PO',
}

export const VITAL_THRESHOLD_MAP_DEFAULT_VALUE = {
  systolic: [null, 90, 110, null],
  diastolic: [null, 70, 80, null],
  BGBeforeMeal: [null, 90, 110, null],
  BGAfterMeal: [null, 70, 80, null],
  weightBasedOn: 'Baseline',
  HS: [null, 90, 110, null],
  HR: [null, 60, 80, null],
  PO: [null, 60, 80, null],
}

export const VITAL_THRESHOLD_KEY_MAP_ONE = {
  'Blood Pressure': 'systolic',
  'Blood Glucose': 'BGBeforeMeal',
  'Weight': 'HS',
  'Heart Rate': 'HR',
  'Temperature': 'TM',
  'Blood Oxygen': 'PO',
}
export const VITAL_THRESHOLD_KEY_MAP_TWO = {
  'Blood Pressure': 'diastolic',
  'Blood Glucose': 'BGAfterMeal',
}
export const VITAL_THRESHOLD_LABEL_MAP = {
  systolic: 'Systolic',
  diastolic: 'Diastolic',
  BGBeforeMeal: 'Before Meal',
  BGAfterMeal: 'After Meal',
  HS: '',
  HR: '',
  TM: '',
  PO: '',
}

export const VITAL_LABEL_MAP = {
  systolic: 'Systolic(mmHg)',
  diastolic: 'Diastolic(mmHg)',
  // BGBeforeMeal: 'Before Meal (mmol/L)',
  // BGAfterMeal: 'After Meal (mmol/L)',
  BGBeforeMeal: `Before Meal (${VSM_US_units.BG.unit})`,
  BGAfterMeal: `After Meal (${VSM_US_units.BG.unit})`,
  HS: `Weight Change (${VSM_US_units.HS.unit}/day)`,
  TM: 'Body Temperature',
  PO: 'Blood Oxygen',
}


export const DATE_UNIT_MAP = {
  DAILY: 'Day',
  WEEKLY: 'Week',
  MONTHLY: 'Month'
}

export const VITAL_WEIGHT_TYPE = {
  Baseline: 'Baseline',
  LastMeasurement: 'Last Measurement',
}


export const DEVICE_VITAL_TYPE_MAP = {
  'Blood Pressure': 'BP',
  'Heart Rate': 'HR',
  'Blood Glucose': 'BG',
  'Weight': 'HS',
  'Temperature': 'TM',
  'Blood Oxygen': 'PO',
}

export const DEFAULT_DEVICE_FOR_VITAL = {
  'BP': 'BP5',
  'BG': 'BG5',
  'HS': 'HS4S',
  'HR': 'BP5',
  'TM': 'TM3',
  'PO': 'PO3',
}

export const DEFAULT_NOTE_PRESET = [
  {
    "category": "GENERAL",
    "display": "General Notes",
    "subCategories": []
  },
  {
    "category": "NURSING",
    "display": "Nursing Notes",
    "subCategories": []
  },
  {
    "category": "MED_RECONCILIATION",
    "display": "Medication Reconciliation",
    "subCategories": []
  },
  {
    "category": "CALL_LOG",
    "display": "Call Log",
    "subCategories": []
  }
]
