import {
  React, IHLoading, message, IHButton
} from 'ihcomponent'
import Detail from '../components/Detail'
import {createContainer, getRouterParam, formatErrorMessage, goPath} from 'libModule/utils'
import resendOnboardEmail from 'modulesAll/graphql/resendOnboardEmail'
import userGQL from '../../../graphql/user'
import { graphql } from 'react-apollo'
import get from 'lodash/get'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import PatientStarModal from 'modulesAll/common/containers/PatientStarModal'
import {modalAction} from 'modulesAll/common/actions'
import { openEditEmailModal, openEditHomePhoneModal, openEditMobilePhoneModal, openEditCareGiverModal, openAddCareGiverModal } from 'modulesAll/patient/profileEdit/actions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import actions from '../../../chat/actions';
import MobilePhoneContainer from '../containers/MobilePhoneContainer';
import HealthConditionEditContainer from '../../profileEdit/containers/HealthConditionEditContainer';
import TimerContainer from '../../../timer/containers/TimerContainer';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const Component = class extends React.Component{
  static displayName = 'patient/profileDetail/containers/Detail'

  editPatientEmail = () => {
    const { user } = this.props.data
    this.props.openEditEmailModal(user)
  }
  editPatientHomePhone = () => {
    const { user } = this.props.data
    this.props.openEditHomePhoneModal(user)
  }
  editPatientMobilePhone = () => {
    const { user } = this.props.data
    this.props.openEditMobilePhoneModal(user)
  }
  editPatientCareGiver = (index) => {
    const { user } = this.props.data
    const refetch = this.props.data.refetch
    this.props.openEditCareGiverModal(user, index, refetch)
  }
  addPatientCareGiver = () => {
    const { user } = this.props.data
    const refetch = this.props.data.refetch
    this.props.openAddCareGiverModal(user, refetch)
  }

  resendActivationEmail = async() => {
    const id = get(this.props, 'data.user.id')
    const patientNRIC = get(this.props, 'data.user.identification[0].value')
    const patientName = get(this.props, 'data.user.profile.fullName')
    const variables = { id }

    try {
      const res = await this.props.mutate({ variables })
      // console.log('got data', res.data);
      createAuditLog({
        action: I18N.get('auditLog.provider.patient.resendActivationEmail'),
        patientNRIC,
        patientName,
        request: variables,
        response: res
      })
      message.success('Successfully sent activation email.')
    }
    catch (err) {
      console.log('there was an error sending the query', err);
      createAuditLog({
        action: I18N.get('auditLog.provider.patient.resendActivationEmail'),
        patientNRIC,
        patientName,
        request: variables,
        response: err,
        success: false
      })
      message.error(formatErrorMessage(err))
    }
  }
  componentDidMount() {
    const data = this.props.data
    // seems the data is already ready and there's no loading state. Directly set state
    if (data.user) {
      this.props.setBreadCrumb(`Patients / ${data.user.profile.fullName} / Demographics`)
    }
  }
  componentWillReceiveProps(nextProps) {
    // this is to counter browser refresh
    if (this.props.data.loading && !nextProps.data.loading) {
      const data = nextProps.data
      this.props.setBreadCrumb(`Patients / ${data.user.profile.fullName} / Demographics`)
    }
  }
  render(){
    const { user,route } = this.props.data;
    const { patientId } = this.props;
    let displayName = this.constructor.displayName;
    const viewingDetails ={
        component : displayName,
        path:window.location.pathname
    };
    const keepEventListener = false;

    return (
      <div className="vsm-main">
          <TimerContainer displayName={displayName} category='PATIENT_DETAIL' action='REVIEW'
                          viewingDetails={viewingDetails} patientId={patientId}
                          keepEventListener={keepEventListener}
          />

        {this.renderTopButton()}
        {this.renderBody()}
        {this.renderHealthCondtionEditor()}
        {user && <PatientStarModal user refetch={this.props.data.refetch} />}
      </div>
    )
  }

  renderTopButton(){
    const { curPage, handleChangePage } = this.props;
    const b1 = {
      label : '< Back to Patient Profile',
      size : 'large',
      type : 'link',
      onClick : ()=>{
        Mixpanel.track('clicked', 'back', 'patient detail', {PATIENT_ID: this.props.patientId});
        Mixpanel.calculateDuration('patient_detail');
        goPath('/patients/'+this.props.patientId+'/enrolledprogram/default');
        Mixpanel.time_event('patient_profile');
        handleChangePage('patient_profile')
      }
    }

    return (
      <div className="v-top">
        <IHButton {...b1} />
      </div>
    )
  }

  renderBody(){
    const data = this.props.data
    if(!data.user){
      return <IHLoading />
    }

    const childProps = {
      data: data.user,
      resendActivationEmail: this.resendActivationEmail,
      patientId: this.props.patientId,
      editPatientEmail: this.editPatientEmail,
      editPatientHomePhone: this.editPatientHomePhone,
      editPatientMobilePhone: this.editPatientMobilePhone,
      editPatientCareGiver: this.editPatientCareGiver,
      addPatientCareGiver: this.addPatientCareGiver,
      showPatientStarModal: this.props.showPatientStarModal,
      showReimportModal : ()=>{
        const opts = {
          title : 'Reimport?',
          content : (
            <div>
              <p>If any changes in patient information is detected, you will be asked to confirm acceptance of these changes. </p>
              <br/>
              <p>
                Do you want to continue to reimport?
              </p>
            </div>
          ),
          Yes : {
            label : 'Yes',
            closeModal : true,
            handler : ()=>{
              goPath(`/patients/profile/${this.props.patientId}/reimport`)
            }
          },
          No : {
            label : 'No'
          }
        }
        this.props.openConfirmModal(opts)
      }
    }
    return <Detail {...childProps} route={this.props.route}/>
  }

  renderHealthCondtionEditor(){
    const data = this.props.data
    if(!data.user){
      return <IHLoading />
    }
    //return <HealthConditionEditContainer userData={data.user}/>

  }
}

const withGql = graphql(userGQL, {
    options: (ownProps)=>{
        const id = ownProps.patientId
        return {
            variables : {
                id
            },
            notifyOnNetworkStatusChange: true,
        }
    }
})

export default createContainer(withGql(graphql(resendOnboardEmail)(Component)), (state)=>{
  const patientId = getRouterParam(state, 'patientId')

  return {
    patientId
  }
}, (dispatch)=>{
  return {
    addToUserMap: userObj => dispatch(actions.addToUserMap(userObj)),
    setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
    openEditEmailModal: (user) => dispatch(openEditEmailModal(user)),
    openEditHomePhoneModal: (user) => dispatch(openEditHomePhoneModal(user)),
    openEditMobilePhoneModal: (user) => dispatch(openEditMobilePhoneModal(user)),
    openEditCareGiverModal: (user, index, refetch) => dispatch(openEditCareGiverModal(user, index, refetch)),
    openAddCareGiverModal: (user, refetch) => dispatch(openAddCareGiverModal(user, refetch)),
    openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
    showPatientStarModal: (show, type, user) => dispatch(modalAction.showPatientStarModal(show, type, user))
  }
})
