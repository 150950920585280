// Imports
import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash'
import { Router, browserHistory, applyRouterMiddleware } from 'react-router';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux'

// Log
import 'libModule/utils/Log'

// // Performance  Lizzy 6/29/2018  As of React 16, react-addons-perf is not supported. Please use your browser’s profiling tools to get insight into which components re-render.
// import Perf from 'react-addons-perf';
// window.Perf = Perf;

// i18n
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';

// Lib
import Store from 'libModule/reduxStore';
import Client from 'libModule/gqlClient';
import './lib/styles.scss';
import { RouterHelper } from './lib/utils';
import routes from './routes';


const middleware = {
  renderRouterContext(render, props) {
    // for this specific scenario, we just always get params from redux,
    // but not subscribe event from redux (react-router did that)
    // so I think mutate redux state directly may be feasible
    Store.getState().routing = {
      location: props.location,
      params: props.params,
    }

    let f = false
    _.each(props.routes, (l)=>{
      if(l.triggerRouter){
        f = true
        return false
      }
    })

    if(f){
      _.delay(()=>{
        Store.dispatch({
          type : 'STORE/ROUTING',
          location: props.location,
          params: props.params

        })
      }, 100)
    }

    return render
  }
};

ReactDOM.render(
  <LocaleProvider locale={enUS}>
    <ApolloProvider  client={Client}>
    <Provider store={Store} >
      <Router history={browserHistory} render={applyRouterMiddleware(middleware)} onUpdate={RouterHelper.updatePage.init} routes={routes} />
    </Provider>
    </ApolloProvider>
  </LocaleProvider>,
  document.getElementById('root'),
);
