
const MiniEnrolledProgram = `
  id
  name
  description
  startDate
  endDate
  status
  tasks{
    type
  }
  team{
    id
    name
    members{
      user{
        id
        profile{
          fullName
        }
      }
    }
    
  }
  organization{
    id
    name
  }
  taskAssignmentCount
`

export {
  MiniEnrolledProgram
}
