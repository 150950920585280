import React from 'react';
import { renderForm} from "../helpers";
import HealthConditionPicker from '../Components/HealthConditionPickerComponent/HealthConditionPicker';
import { Table,Button,Icon,Checkbox } from 'antd';

class RenderFormComponent extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    addHealthCondition=(value)=>{
        const { healthConditions } = this.props.form.getFieldsValue();
        if(healthConditions.indexOf(value)==-1) {
            this.props.form.setFieldsValue({
                'healthConditions':healthConditions.concat(value)
            })
        }
    }

    copyBillableHealthConditions(array){
        const copiedArray = array.slice();

        return copiedArray.map(i=>({
            description:i.description,
            code:i.code
        }));
    }

    removeHealthCondition=(index)=> {
        const { healthConditions,billableHealthConditions } = this.props.form.getFieldsValue();
        const copyHealthConditions = healthConditions.slice();
        const copyBillableHealthConditions = Array.isArray(billableHealthConditions) ? this.copyBillableHealthConditions(billableHealthConditions) :[];
        const value = _.get(healthConditions[index].split('::'),'1',null);
        this.props.form.setFieldsValue({
            healthConditions: _.remove(copyHealthConditions, function (_, i) {
                return i != index;
            }),
            billableHealthConditions: value ? _.remove(copyBillableHealthConditions, function (o) {
                return o.code!=value;
            }) : copyBillableHealthConditions
        })


        this.props.handleEnableEditMode(true)
    }


    onChange(v){
        const { billableHealthConditions } = this.props.form.getFieldsValue();
        const copyBillableHealthConditions = Array.isArray(billableHealthConditions) ? this.copyBillableHealthConditions(billableHealthConditions) :[];
        const code = _.get(v,'code','');
        if(copyBillableHealthConditions.findIndex((i)=>code==i.code)!=-1){
            this.props.form.setFieldsValue({
                billableHealthConditions: _.remove(copyBillableHealthConditions, function (o) {
                   return o.code!=code;
                })
            })
        }
        else{
            copyBillableHealthConditions.push({
                description:v.name,
                code:code
            });
            this.props.form.setFieldsValue({
                billableHealthConditions: copyBillableHealthConditions
            })
        }
    }

    renderHealthConditionPicker=()=>{
        return <div className='ant-col-24'>
            <div><h4>Health Conditions:</h4></div>
            <div style={{width:'20%'}}>
                <label style={{fontSize: 12}}>ICD Code Search</label>
                <HealthConditionPicker  addHealthCondition={ this.addHealthCondition }/>
            </div>
        </div>
    }

    renderHealthConditionTable() {
        const { healthConditions } = this.props.form.getFieldsValue();
        const healthConditionsSource = _.map(healthConditions, (value) => (
            {
                name: value.split('::')[0],
                code: value.split('::')[1],
                value: value
            }))
        const tableColumn = [
            {
                key: 'code',
                title: 'Code',
                width:'15%',
                dataIndex: 'code',
            },
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name'
            },
            {
                key:'billableHealthConditions',
                title:'Billable',
                width:'15%',
                render:(v,doc)=>{
                    const { billableHealthConditions } = this.props.form.getFieldsValue();
                    const checked = !Array.isArray(billableHealthConditions) ? false : billableHealthConditions.findIndex((i)=>v.code==i.code)!=-1;

                    return v.code ? <Checkbox onChange={()=>this.onChange(v,doc)} checked={checked}/>:'';
                }

            },
            {
                key: 'remove',
                width:'15%',
                title:'Action',
                render: (text, record, index) => <Button onClick={() => this.removeHealthCondition(index)}><Icon
                type="close"/></Button>
            }
        ]

        return <div style={{padding: '20px 10px 0 10px'}} className='ant-col-24'>
            <Table dataSource={ healthConditionsSource }
                   columns={ tableColumn }
                   rowKey={(key) => key.code}
                   pagination={false}
            />
        </div>
    }

    render(){
        const { config } = this.props;

        return renderForm(this,config)
    }

}

export default RenderFormComponent;

