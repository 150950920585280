import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import get from 'lodash/get'
import { React, IHSelect, IHButton, _, IHDatePicker } from 'ihcomponent'
import { createContainer,getTimeFromProgram, RequestCache, getRouterParam, goPath, GQLHelper, SuperContainer, RouterHelper} from '../../../../lib/utils'
import actions from '../actions/main'
import {setExtendStep} from '../../enrolledProgramInfo/actions/EnrolledProgramInfoActions';
import { convertDBToVitalList } from 'modulesAll/utils/helpers/task'
import { DEVICE_VITAL_TYPE_MAP } from 'modulesAll/utils/constants/task'

import ProgramTable from './ProgramTable'
import tabAction from '../../main/actions/Tab'
import DescArea from './DescArea'
import enrollMemberGQL from 'modulesAll/graphql/enrollMember'
import setDateForEnrolledProgram from 'modulesAll/graphql/mutation/setDateForEnrolledProgram'
import manageTeamForEnrolledProgramGQL from 'modulesAll/graphql/manageTeamForEnrolledProgram'
import EmrolledProgramClass from 'modulesAll/graphql/class/EnrolledProgram'
import UserClass from 'graphqlModule/class/User'
import {modalAction} from 'modulesAll/common/actions'
import moment from 'moment';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import VitalList from 'patientModule/enrolledProgramInfo/Careplan/containers/VitalListContainer'
import setDevicesForEnrolledProgramGQL from 'modulesAll/graphql/setDevicesForEnrolledProgram'

import { browserHistory } from 'react-router';
import { Modal, Button } from 'antd';
import { API } from '../../../visit/query/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

//queries
import adminProgramList from 'graphqlModule/adminProgramList'
const temporaryDeviceAddress = 'XXXXXXXXXXXX';

const Component = class extends SuperContainer{
    static displayName = 'patient/programTab/containers/main'

    constructor(p) {
      super(p);
      
      this.state = {
        visible: false,
        confirmLoading: false
      }

      this.changeStartDate = this.changeStartDate.bind(this);
      this.onChangeDropdown = this.onChangeDropdown.bind(this);
      this.onClickNext = this.onClickNext.bind(this);
      this.onClickBack = this.onClickBack.bind(this);
      this.showModal = this.showModal.bind(this);
      this.handleGoBack = this.handleGoBack.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
    }

    showModal = () => {
      const curProgram = this.props.program.selectedProgram ? this.props.program.selectedProgram.name : '';
      Mixpanel.track('Showed', 'enroll success popup', '', {PROGRAM_NAME: curProgram});
      this.setState({
        visible: true,
      });
    };

    handleGoBack = () => {
      Mixpanel.track('clicked', 'go back and finish the task button', '', {});
      const curUrl = browserHistory.getCurrentLocation().pathname;
      const urlArr = _.split(curUrl, '/');
      const url = urlArr[urlArr.length - 1];
      const visitId = url.substring(0, url.length - 1);
      this.setState({
        confirmLoading: true
      });
      setTimeout(() => {
        this.setState({
          visible: false,
          confirmLoading: false,
        });
      goPath(`/dashboard` + '?' + visitId);
      }, 2000);
    };

    handleCancel = () => {
      this.setState({
        visible: false,
        confirmLoading: false
      });
      RequestCache.get('refetch-enrolledProgramList').call();
    };

    componentWillMount() {
      const startDate = _.get(this.props, 'program.startDate') || _.get(this.props, 'currentProgram.startDate')
      if (startDate) {
        this.changeStartDate(moment(startDate))
      }
    }

    componentWillUnmount(){
        this.props.dispatch(actions.resetState());
    }

    componentWillReceiveProps(np) {
      if(np.templateProgramId && this.props.templateProgramId !== np.templateProgramId){
        const programme = this.props.programList || []
        const id = np.templateProgramId
        const selectedProgram = programme.filter(prog => { return prog.id === id})
        this.props.dispatch(actions.changeSelect(selectedProgram[0]))
      }
    }

    renderMain(){
      const _currentPatient = RequestCache.get('nurse/currentPatient')
      const name = _currentPatient.profile.fullName;
      const curProgram = this.props.program.selectedProgram ? this.props.program.selectedProgram.name : '';
      const curUrl = browserHistory.getCurrentLocation().pathname;
      return (
          <div className="v-box vsm-patient-programme-tab">
              {this.renderDropdown()}
              {/*<DescArea currentProgram={this.props.currentProgram} extendStep={this.props.enrolledProgramInfo.EnrolledProgramInfo.extendStep}/>*/}
              {this.props.templateProgramId && this.renderDateSelector()}
              {this.props.templateProgramId && this.renderTable()}
              {this.props.haveCareTeam ? '' : this.renderNoCareTeamMessage()}
              {this.props.templateProgramId && this.renderVitalList()}
              {this.props.templateProgramId && this.renderBottomButton()}
              <Modal
                title="Congratulations!"
                visible={this.state.visible}
                onOk={this.handleGoBack}
                onCancel={this.handleCancel}
                centered='true'
                confirmLoading={this.state.confirmLoading}
                okText="Go back and finish the task">
                <p>{name} has been successfully enrolled in <a style={{fontWeight:"bold", color:'#000000'}}>{curProgram}</a> Program.<br/>Please go back to the dashboard to finish up the task</p>
              </Modal>
          </div>
      )
    }

    changeStartDate(date) {
      this.props.dispatch(actions.changeStartDate(date ? date.toDate(): moment().toDate() )); //when press x on the select date, default today date
    }

    disabledPreviousDate(currentDate) {
      // can not select days before today and today
      return currentDate && currentDate.valueOf() < moment().add(-1,'day').toDate();
    }

     setDevicesForEP =  async ( program ) => {
        const { setDevicesForEnrolledProgram } = this.props;
        const currentProgram = program;
        const enrolledProgramId = program.id;
        const currentUserId = sessionStorage.getItem('currentUserId')
        const prefixString = 'SUPER-SECRET-TOKEN-NOBODY-WILL-EVER-GUESS:action:ep_finish_enrollment'
        const userIdPair = `signee:${currentUserId}`
        const enrolledProgramIdPair = `enrolledProgramId:${enrolledProgramId}`
        const startDate = new Date().getTime()
        const { length, calendar } = _.get(currentProgram, 'duration')
        const endDate = startDate + getTimeFromProgram(length, calendar)
        const token = `${prefixString}:${userIdPair}:${enrolledProgramIdPair}`
        const variables = {
            token,
            startDate,
            endDate,
        }

        const deviceVariables = {
            forms: [
                {
                  type: 'LOAN_FORM',
                  uri: 'https://www.google.com',
                }
            ]
        }
        const VitalListData = convertDBToVitalList(currentProgram);
        VitalListData.forEach(vital => {
            const deviceType = DEVICE_VITAL_TYPE_MAP[vital.vitalType]

            if (!deviceVariables[deviceType]) {
                deviceVariables[deviceType] = {
                    // address: deviceType + '123',
                    address: temporaryDeviceAddress,
                    status: 'VENDOR_LOAN'
                }
            }
        })
        deviceVariables.tablet = {
            //address: 'tablet321',
            address: temporaryDeviceAddress,
            status: 'VENDOR_LOAN'
        }
        deviceVariables.id = currentProgram.id;
        const curUrl = browserHistory.getCurrentLocation().pathname;
        try {
            await setDevicesForEnrolledProgram({ variables: deviceVariables });
            if(!_.endsWith(curUrl, '_')) {
              window.location.reload();
            } 
            // else {
            //   const visitId = this.props.eid.substring(0, this.props.eid.length - 1);
            //   await API.updateVisit(visitId, 'checkIn');
            // }
        } catch(err) {
            console.log(err)
            this.props.openErrorModal(GQLHelper.formatError(err))
            return;
        } 
    }

    renderDateSelector(){
      const { program }=this.props;
      const startDate = _.get(program, 'startDate')
      return (
          <div className="h-flex v-table" style={{ marginTop:20 }}>
              <p className="title v-tlt" style={{ display:'flex',alignItems:'center' }}>Select Start Date:</p>
              <IHDatePicker format="MMM-DD-YYYY" value={moment(startDate)} onChange={this.changeStartDate}  disabledDate={this.disabledPreviousDate}/>
          </div>
      )
    }

    renderNoCareTeamMessage() {
      return (
        <span style={{color:'#e85c6a'}}>Please select at least one care team</span>
      )
    }

    renderVitalList(){
        const program  = _.get(this,'props.program.selectedProgram');
        const patientId = _.get(this,'props.patientId');
        return (
            <div>
                <p className="v-tlt" style={{ font:'#474747',fontSize:15,marginBottom:20 }}>{`Vital Signs & Thresholds:`}</p>
                <VitalList program={program} isFromEnrollProgram={ true } patientId={patientId}/>
            </div>
        )
    }

    renderDropdown(){
        const programme = _.orderBy(this.props.programList, [programme => programme.name.toLowerCase()], ['asc']) || []
        const enrolledProgramList = _.orderBy(this.props.enrolledProgramList, [programme => programme.name.toLowerCase()], ['asc']).filter( (ep) => ep.status !== 'DISCHARGED' )

        const disabledOption = enrolledProgramList.filter( ep => ep.status !== 'RETURNED' ).map((p)=>{
            const po = new EmrolledProgramClass(p)
            const status = po.checkStatus()
            const isIncomplete = ['create', 'pending'].indexOf(status) !== -1
            const displayedStatus = isIncomplete ? 'Incomplete' : 'Enrolled'
            // else if(status === 'discharge') {
            //   rs.name = `${po._get('adminProgram.name')} (Previously enrolled)`
            //   const relatedEPs = enrolledProgramList.filter( (ep) => (ep.adminProgram.id === p.adminProgram.id && ep.status !== 'DISCHARGED'))
            //   if(relatedEPs.length) {
            //      rs = null
            //   }
            // }
            // else{
            //   rs.disabled = true
            // }
            const rs = {
              name: `${po._get('adminProgram.name')} (${displayedStatus})`,
              value : po._get('adminProgram.id'),
              disabled: true
            }
            return rs
        }).filter( (option) => option )

        const healthConditionList = UserClass.getHealthConditionList()

        //only show admin programs that have the health conditions that match the ones from the nurse’s teams
        //on May 25/2018, Remove this restriction . Lizzy
        const lastOptions = programme
          .filter(p => !_.find(enrolledProgramList,
            // can enroll program again after RETURNED device
            enroll => enroll.status !== 'RETURNED' && _.get(enroll, 'adminProgram.id') === p.id
          )
          //&& healthConditionList.indexOf(p.healthCondition) !== -1
          )
          .map(p => ({ name: p.name,value: p.id, }));

        // not supported by IHSelect yet
        // if (lastOptions.length && disabledOption.length) {
        //     disabledOption[disabledOption.length - 1].style = 'solid 1px rgba(204,204,204,0.28)';
        // }

        const buttonDisabled  = this.props.eid !== 'new' && enrolledProgramList.length

        const p = {
            option : lastOptions.concat(disabledOption),
            placeholder : `Select ${I18N.get('keywords.Program')}`,
            size : 'large',
            value : this.props.templateProgramId,
            disabled: !!buttonDisabled,
            onChange : this.onChangeDropdown.bind(this, programme),
            style:{
                width:300
            }
        }
        return (
            <div className="v-drop" style={{display:'flex',width:'unset'}}>
                <div className="v-tlt " style={{ marginRight:20,
                                                 display:'flex',
                                                 alignItems: 'center',
                                                 fontSize:15,
                                                 color:'#474747'
                                               }}
                >{I18N.get('keywords.SelectProgramTemplate')}
                </div>
                <IHSelect {...p} />
            </div>
        )
    }

    onChangeDropdown(programme, id) {
      const selectedProgram = programme.filter(prog => { return prog.id === id})
      this.props.dispatch(actions.changeSelect(selectedProgram[0]))
      this.props.dispatch(actions.setHaveCareTeam(true))
      //this.props.dispatch(actions.initVitalList(selectedProgram[0]))
      this.changeStartDate( (this.props.currentProgram && this.props.currentProgram.startDate) ? moment(this.props.currentProgram.startDate): moment());
      this.props.setExtendStep(2)
    }

    runOnceAfterDataLoading(){
      if(this.props.templateProgramId){
        const programme = this.props.programList || []
        const id = this.props.templateProgramId
        const selectedProgram = programme.filter(prog => { return prog.id === id})
        this.props.dispatch(actions.changeSelect(selectedProgram[0]))
      }

      RouterHelper.updatePage.register('Patients_Current_Programmes_New', ()=>{
        if(this.props.eid === 'new'){
            this.props.dispatch(actions.initialState())
        }
      })
    }

    renderTable(){
        return (
            <div className="v-table" style={{ marginBottom: 20,marginTop: 20 }}>
                <p className="v-tlt" style={{ display:'flex',alignItems:'center',marginBottom:20 }}>{`Select Care Team:`}</p>
                <ProgramTable currentProgram={this.props.currentProgram} />
            </div>
        )
    }

    async enrollMember(variables, close){
      const _currentPatient = RequestCache.get('nurse/currentPatient')
      const patientNRIC = _currentPatient.identification && _currentPatient.identification.length > 0 ? _currentPatient.identification[0].value : [];
      // const patientNRIC = _currentPatient.identification[0].value
      const patientName = _currentPatient.profile.fullName
      const auditLogDetails = {
        programName: this.props.program.selectedProgram.name,
        careTeams: this.props.program.selectCareteam.teamInfo,
        startDate: moment(variables.startDate).format('MMM-DD-YYYY'),
        endDate: moment(variables.endDate).format('MMM-DD-YYYY')
      }
      const curUrl = browserHistory.getCurrentLocation().pathname;
      try {
        const res = await this.props.enrollMember({variables})
        createAuditLog({
          action:I18N.get('auditLog.provider.patient.enrollInNewProgram.save_program_tab'),
          details: auditLogDetails,
          request: variables,
          response: res,
          patientNRIC,
          patientName
        })
        await UserClass.saveEPsForProvider()
        if(!_.endsWith(curUrl, '_')) {
          const promise = RequestCache.get('refetch-enrolledProgramList').call();
          promise.then(()=>{
            this.setDevicesForEP(res.data.enrollMember);
          })
        } else {
          this.setDevicesForEP(res.data.enrollMember);
        }

        //   // goPath(`/patients/${this.props.patientId}/enrolledprogram/${res.data.enrollMember.id}?tab=careplan`)
        //   // this.props.dispatch(tabAction.setTabsStep('careplan'))
        //   //
        //   // _.delay(close, 1000)
        // })
      } catch(error) {
        createAuditLog({
          action:I18N.get('auditLog.provider.patient.enrollInNewProgram.save_program_tab'),
          details: auditLogDetails,
          request: variables,
          response: error,
          patientNRIC,
          patientName,
          success: false
        })
        this.props.dispatch(modalAction.openErrorModal(GQLHelper.formatError(error)))
        close()
        return;
      }
      if(_.endsWith(curUrl, '_')) {
        const visitId = this.props.eid.substring(0, this.props.eid.length - 1);
        try {
          await API.updateVisit(visitId, 'checkIn')
        } catch(err) {
          console.log(err);
          this.props.openErrorModal(GQLHelper.formatError(err))
          return;
        }
        this.showModal();
      }
    }

    async editCurrentEnrolledProgram(callback, modifiedStartDate, modifiedEndDate){
        const currentUserId = sessionStorage.getItem('currentUserId')
        const _currentPatient = RequestCache.get('nurse/currentPatient')
        const patientNRIC = _currentPatient.identification && _currentPatient.identification.length > 0 ? _currentPatient.identification[0].value : [];
        const patientName = _currentPatient.profile.fullName
        const variables = {
          id : this.props.currentProgram.id,
          teamId : this.props.program.selectCareteam.id,
          managerId: [currentUserId]
        }
        const auditLogDetails = {
          programName: this.props.program.selectedProgram.name,
          careTeams: this.props.program.selectCareteam.teamInfo
        }

        if (this.props.currentProgram.startDate !== (modifiedStartDate && modifiedStartDate.getTime())) {
            const setDateEPVariables= {id: this.props.currentProgram.id, startDate: modifiedStartDate, endDate: modifiedEndDate};
            const auditLogSetDateDetails = {
              programName: this.props.program.selectedProgram.name,
              startDate: moment(modifiedStartDate).format('MMM-DD-YYYY'),
              endDate: moment(modifiedEndDate).format('MMM-DD-YYYY')
            }
            try {
                const res = await this.props.setDateForEnrolledProgram({ variables: setDateEPVariables})
                createAuditLog({
                  action:I18N.get('auditLog.provider.patient.enrollInNewProgram.edit_enrolled_program_startDate'),
                  details: auditLogSetDateDetails,
                  request: setDateEPVariables,
                  response: res,
                  patientNRIC,
                  patientName
                })
            } catch( error ){
                createAuditLog({
                  action:I18N.get('auditLog.provider.patient.enrollInNewProgram.edit_enrolled_program_startDate'),
                  details: auditLogSetDateDetails,
                  request: setDateEPVariables,
                  response: error,
                  patientNRIC,
                  patientName,
                  success: false
                })
                return;
            }
        }

        try{
            const res = await this.props.manageTeamForEnrolledProgram({variables})
            createAuditLog({
              action: I18N.get('auditLog.provider.patient.enrollInNewProgram.save_program_tab'),
              details: auditLogDetails,
              request: variables,
              response: res,
              patientNRIC,
              patientName
            })

            const promise = RequestCache.get('refetch-enrolledProgramList').call()
            promise.then(()=>{
                this.props.dispatch(tabAction.setTabsStep('careplan'))
                _.delay(callback, 1000)
            })

        } catch(error) {
            this.props.dispatch(modalAction.openErrorModal(GQLHelper.formatError(error)))
            createAuditLog({
              action: I18N.get('auditLog.provider.patient.enrollInNewProgram.save_program_tab'),
              details: auditLogDetails,
              request: variables,
              response: error,
              success: false,
              patientNRIC,
              patientName
            })
            callback()
        } finally {

        }
    }

    renderBottomButton(){
        const p1 = {
            type : 'primary',
            label : 'Enroll and Start Program',
            ref : 'btn_next',
            loading : 'Loading...',
            onClick : this.onClickNext,
            className : 'v-nb'
        }

        const p2 = {
            label : 'Cancel',
            className : 'v-bb',
            onClick : this.onClickBack
        }

        return (
            <div className="v-bottom-btn" style={{ float:'right',marginBottom:20 }}>
                <IHButton style={{ marginRight:20,marginTop:20 }} {...p2} />
                <IHButton {...p1} style={{ marginTop:20 }}/>
            </div>
        )
    }

    onClickBack() {
        //reset select
        this.props.dispatch(actions.resetState());
    }

    setButtonLoadingStatus(status=true) {
      const button = _.get(this, 'refs.btn_next')
      button && button.loading(status)
    }

    async onClickNext() {
        const dispatch = this.props.dispatch
        const selectedAdminProgram = (this.props.programList || []).filter( (p) => p.id === this.props.program.current )
        const startDate = this.props.program.startDate;
        const duration = _.get(selectedAdminProgram, '0.duration')
        const calendar = _.get(duration, 'calendar')
        const multiplier = calendar === 'DAY'
                            ? 1 : calendar === 'WEEK'
                            ? 7 : calendar === 'MONTH'
                            ? 30 : 0
        const endDate = moment(startDate).add(_.get(duration, 'length') * multiplier-1, 'd').toDate();

        const variables = {
            id : this.props.templateProgramId,
            memberId : this.props.patientId,
            teamId : this.props.program.selectCareteam.id,
            startDate,
            endDate,
        }

        // Do NOT proceed if no care team is selected, show error message instead
        if (variables.teamId.length === 0) {
          dispatch(actions.setHaveCareTeam(false))
          return
        }
        this.setButtonLoadingStatus(true)
        const close = ()=>{
          this.setButtonLoadingStatus(false)
        }
        
        //TO Diff from dash or from patient here 
        const curUrl = browserHistory.getCurrentLocation().pathname;
        if(!this.props.currentProgram) {
          try {
            await this.enrollMember(variables, close);
          } catch(err) {
            if(_.endsWith(curUrl, '_')) {
              return;
            }
          };
        } else {
          this.editCurrentEnrolledProgram(() => {
            close()
          }, startDate, endDate)
        }
    }
}

const withData = graphql( adminProgramList, {
  options: {
    variables: {
      "page": 1,
      "count": 999, //need to get all of the admin programs, there's no pagination here
      "options": {},
      "sort": {
        "field": "CREATED_AT",
        "direction": "ASC"
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  },
  props: ({ownProps, data}) => {
    const { loading, refetch, adminProgramList } = data
    return {
      programList: get(adminProgramList, 'data'),
      loading,
      refetch
    }
  }
})

const enrollMemberWrapper = graphql(enrollMemberGQL
  , {
    name : 'enrollMember'
})

const manageTeamForEnrolledProgramGQLWrapper = graphql(manageTeamForEnrolledProgramGQL, {
    name : 'manageTeamForEnrolledProgram'
})

const setDateForEnrolledProgramWrapper = graphql(setDateForEnrolledProgram, {
  name: 'setDateForEnrolledProgram'
})
const setDevicesForEnrolledProgram = graphql(setDevicesForEnrolledProgramGQL, {
        name : 'setDevicesForEnrolledProgram'
    });
const WrapperContainer = GQLHelper.wrapper([withData, enrollMemberWrapper, manageTeamForEnrolledProgramGQLWrapper, setDateForEnrolledProgramWrapper,setDevicesForEnrolledProgram], Component)
export default createContainer(WrapperContainer, (state, ownProps)=>{
    const cache = RequestCache.get('enrolledProgramList')
    const eid = getRouterParam(state, 'enrolledProgramId')
    const templateId = (ownProps, 'currentProgram.id')
    const templateProgramId = get(ownProps, 'currentProgram.adminProgram.id')
                           || get(state, 'patient.program.current')
    const enrolledProgramList = cache
    const patientId = getRouterParam(state, 'patientId')
    const haveCareTeam = get(state, 'patient.program.haveCareTeam')

    return {
        templateId,
        templateProgramId,
        enrolledProgramList,
        patientId,
        eid,
        haveCareTeam,
        ...state.patient
    }
},
{
  setExtendStep
})