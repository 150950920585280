// Imports
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Reducers
import Client from './gqlClient';
import reducers from './reducer';
import tables from './table/TableReducers';
import layoutConstants from 'modulesAll/layout/constants/Nav';
const { RESET_STATE } = layoutConstants;

const appReducer = combineReducers({
  ...reducers,
  globalTables: tables,
  routing: (state={}, action)=>{
    if(action.type === 'STORE/ROUTING'){
      return {
        ...state,
        location : action.location,
        params : action.params
      }
    }
    return state
  },
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({})),
    window.devToolsExtension ? window.devToolsExtension() : f => f,
  ),
);
