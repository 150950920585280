import { nationOptions, langOptions, raceOptions, countryOptions, docTypeOptions, HEALTH_CONDITION_DISPLAY,HEALTH_CONDITION_ICDCODE,DoctorNamesOptions } from 'libModule/constants'
import { emailValidator2, firstAndLastNameValidator, dobValidator, postCodeValidator, patientWeightRegiterValidator, patientHeightInputValidator, patientHeightInchesInputValidator,yearOfDiagnoseValidator } from 'libModule/validator';
import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
const FORMLABELS = I18N.get('patientlist.manualInputForm');
import { convertInchesToFeet, getRemainderAfterConvertingToFeet, convertUnits } from 'libModule/utils/convertUnits'
import { mapPropsToFields } from 'libModule/utils'
import I18N from 'modulesAll/I18N';
import {goPath} from 'libModule/utils'
import '../../css/index.scss';
import '../../../enrolledProgramInfo/css/index.scss';
import { MOTIVATION_ENUM } from '../../constants/profileFieldMap';
const VSM_US_units = convertUnits('VSM_US');
export const schema = {
  nricType: {
    initialValue: '',
    required: false
  },
  nric: {
    initialValue: '',
    required: true
  },
  DOB: {
    initialValue: '',
    required: true
  },
  firstName: {
    initialValue: '',
    required: true
  },
  lastName: {
    initialValue: '',
    required: true
  },
  nickname: {
    initialValue: '',
    required: false
  },
  email: {
    initialValue: '',
    required: false
  },
  gender: {
    initialValue: '',
    required: true
  },
  height: {
    initialValue: '',
    required: false
  },
  height_inches: {
    initialValue: '',
    required: false
  },
  weight: {
    initialValue: '',
    required: false
  },
  // nationality: {
  //   initialValue: 'US',
  //   required: false
  // },
  nationtype: {
    initialValue: 'SC',
    required: false
  },
  streetNumber: {
    initialValue: '',
    required: false
  },
  streetName: {
    initialValue: '',
    required: false
  },
  unit: {
    initialValue: '',
    required: false
  },
  floor: {
    initialValue: '',
    required: false
  },
  city: {
    initialValue: 'New York',
    required: false
  },
  state: {
    required: false
  },
  country: {
    initialValue: 'US',
    required: false
  },
  postCode: {
    initialValue: '',
    required: false
  },
  race: {
    initialValue: 'CN',
    required: false
  },
  language: {
    initialValue: 'MN',
    required: false
  },
  insuranceProvider: {
    initialValue:'',
    required: false
  },
  username: {
    initialValue: '',
    required: true
  },
  homeCountryCode: {
    initialValue: '+1',
    required: false
  },
  homePhone: {
    initialValue: '',
    required: false
  },
  mobileCountryCode: {
    initialValue: '+1',
    required: false
  },
  mobilePhone: {
    initialValue: '',
    required: false
  },
  BPYearOfDiagnose: {
      required:false
  },
  BGYearOfDiagnose: {
      required:false
  },
  doctorName:{
      required:true
  },
  motivation:{
      required: true,
  },
  techLevel:{
      required: true
  }

}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

const formatFieldLabels = (schemaKey, i18key) => {
  if (schema[schemaKey].required) return `${FORMLABELS[i18key]} *`
  else return FORMLABELS[i18key];
}

const getNewOptions = user => {
  // 07/18/18 - Lizzy Refactored. Directly save the full description into db, no need to transfer when display
  //08/20/18 - Lizzy Refactored. To support ICD-10 Code. Health_condition_description::icd_code

  let staticOptions = Object.keys(HEALTH_CONDITION_DISPLAY).map(key => ({label:HEALTH_CONDITION_DISPLAY[key],
    value:`${HEALTH_CONDITION_DISPLAY[key]}::${HEALTH_CONDITION_ICDCODE[key]}`}));

  user.profile.healthConditions.forEach( value => {
    // 07/18/18 - Lizzy Refactored. Directly save the full display (value) into db, hence filtered by values here.
    //08/20/18 - Lizzy Refactored. To support ICD-10 Code. value = Health_condition_display::icd_code
    const titleCode = value.split('::');
    const display = titleCode[0] + (titleCode.length > 1 ? `(ICD-10 ${titleCode[1]})`: '');

    //not predefined or no ICD-10 code
    if (Object.values(HEALTH_CONDITION_DISPLAY).indexOf(display) === -1 || titleCode.length < 2) {
      staticOptions.push({ label: display, value });
    }
  });

  return staticOptions;
};
const getDiagnoseDate = (val,type) => {
  const { value } = val.yearOfDiagnose;
  const filteredRes  = _.filter(value,(k)=>k.condition==type);
  const diagnosedDate  = filteredRes.length > 0 ? filteredRes[0].diagnosedDate : null;
  return  diagnosedDate;
}


export const getFormProps = ({ fieldsValue, onFieldsChange, user,props }) => {
  const { nricEnable, usernameEnable } = props.state;
  const itemList = [
    [
      {
        key: 'nricEnableButton',
        showButton:true,
        type:'button',
        col: { span: 24, offset: 12 },
        btnLabel: nricEnable ? 'Done' : 'Edit',
        onClick:()=> props.changeNRICState(),
        className: nricEnable ? 'ih-exsmall nricButton nricEnableClass' : 'ih-exsmall nricButton nricDisableClass'
      }
    ],
    [{
        key: 'basicInfo',
        label: FORMLABELS.basicInfo,
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'nric',
        label: formatFieldLabels('nric','nric'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'S1234567E',
        placeholder: '',
        initialValue: initData['nric'],
        col: { span: 10, offset: 0 },
        rules : [
          // {required : isFieldsRequired.nric, message : `${I18N.get('keywords.NRIC/FIN')} is required`}
        ],
        disabled: !nricEnable,
      },
      {
        key: 'DOB',
        label: formatFieldLabels('DOB','dob'),
        type: 'date',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: 'MMM-DD-YYYY',
        initialValue: initData['DOB'],
        col: { span: 10, offset: 0 },
        format: 'MMM-DD-YYYY',
        rules : [
          {required : isFieldsRequired.DOB, message : 'DOB is required'},
          { validator: (rule, value, callback) => callback(dobValidator(value)) }
        ],
        //disabled: true
      },
      {
        key:'doctorId',
        label:'Doctor Name',
        type:'select',
        labelCol:{ span:24 },
        wrapperCol:{ span:24 },
        col:{ span:20,offset:4 },
        rules:[ { required: isFieldsRequired.doctorName,message:'Doctor Name is required' } ],
        option: _.map(JSON.parse(sessionStorage.getItem('doctorName')),({id,fullName})=>({name:fullName.trim(),value:id,key:id})),
        showSearch:true
      }
    ],
    [
      {
        key: 'firstName',
        label: formatFieldLabels('firstName','firstName'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'First Name',
        placeholder: '',
        initialValue: initData['firstName'],
        col: { span: 10, offset: 4 },
        rules : [
          {required : isFieldsRequired.firstName, message : 'First Name is required'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}
        ],
        //disabled: true
      },
      {
        key: 'lastName',
        label: formatFieldLabels('lastName','lastName'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'Last Name',
        placeholder: '',
        initialValue: initData['lastName'],
        col: { span: 10, offset: 0 },
        rules : [
          {required : isFieldsRequired.lastName, message : 'Last Name is required'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName'))}
        ],
        //disabled: true,
      }
    ],
    [
      {
        key: 'nickname',
        label: formatFieldLabels('nickname','nickname'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['nickname'],
        col: { span: 10, offset: 4 },
        rules : [
          //{required : isFieldsRequired.firstName, message : 'Nickname is required'},
          //{validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'nickname'))}
        ],
      },
      {
        key: 'gender',
        label: formatFieldLabels('gender','gender'),
        type: 'radioButton',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['gender'],
        col: { span: 10, offset: 0 },
        option: [
          { name: 'Male', value: 'Male' },
          { name: 'Female', value: 'Female' }
        ],
        className: 'radio-button-2-option',
        rules : [
          {required : isFieldsRequired.gender, message : 'Gender is required'}
        ],
        //disabled: true,
      },
      /*{
        key: 'height',
        label: formatFieldLabels('height','height'),
        type: 'inputNumber',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: '175',
        placeholder: '',
        disabled: false,
        initialValue: initData['height'],
        col: {
          span: 3,
          offset: 0
        },
        rules : [
          { required : isFieldsRequired.height, message : 'Height is required' },
          {validator: (rule, value, callback) => callback(patientHeightInputValidator(value, 'height'))}
        ]
      },
      {
        key: 'height_inches',
        label: formatFieldLabels('height_inches','height_inches'),
        labelCol: { span: 24},
        wrapperCol: { span: 24 },
        placeholder: 'inches',
        disabled: false,
        initialValue: initData['height_inches'],
        col: {
          span: 3,
          offset: 0
        },
        rules : [
          { required : isFieldsRequired.height, message : 'Height is required' },
          {validator: (rule, value, callback) => callback(patientHeightInchesInputValidator(value, 'height'))}
        ]
      },
      {
        key: 'weight',
        label: formatFieldLabels('weight','weight'),
        type: 'inputNumber',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: '75',
        placeholder: '',
        disabled: false,
        initialValue: initData['weight'],
        col: {
          span: 4,
          offset: 0
        },
        rules : [
          { required : isFieldsRequired.weight, message : 'Weight is required' },
          {validator: (rule, value, callback) => callback(patientWeightRegiterValidator(value, 'weight'))}
        ]
      }*/
    ],
    [
      {
        key:'techLevel',
        label:'Tech Level *',
        type:'select',
        labelCol:{ span:24 },
        wrapperCol:{ span:24 },
        col:{ span:10,offset:4 },
        rules:[ { required: isFieldsRequired.techLevel,message:'Tech Level is required' } ],
        option: [{value:'HIGH',name:'High'},{value:'NORMAL',name:'Normal'},{value:'LOW',name:'Low'}],
      },
      {
        key:'motivation',
        label:'Motivation *',
        type:'select',
        labelCol:{ span:24 },
        wrapperCol:{ span:24 },
        col:{ span:10,offset:0 },
        rules:[ { required: isFieldsRequired.motivation,message:'Motivation is required' } ],
        option: _.map(MOTIVATION_ENUM,(i)=>({
            value:i,
            name:_.upperFirst(i.toLowerCase())
        })),
      }
    ],
    [
      {
          key: 'usernameEnableButton',
          showButton:true,
          type:'button',
          col: { span: 24, offset: 12 },
          btnLabel: usernameEnable ? 'Done' : 'Edit',
          onClick:()=>props.changeUsernameState(),
          className: usernameEnable ? 'ih-exsmall nricButton nricEnableClass' : 'ih-exsmall nricButton nricDisableClass'
      }
    ],
    [
      {
        key: 'loginInfo',
        label: FORMLABELS.loginInfo,
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'username',
        label: formatFieldLabels('username','username'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['username'],
        disabled: !usernameEnable,
        col: { span: 10, offset: 0 },
        rules : [
          {required : isFieldsRequired.username, message : `${I18N.get('keywords.username')} is required`}
        ],
      },
      {
        key: 'changePasswordBtn',
        label: ' ', // Leave empty space so proper top padding applies
        btnLabel: I18N.get('auth.password.changePw'),
        type: 'button',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        col: { span: 10, offset: 0 },
        showButton: user.hasPassword,
        onClick: () => goPath(`patients/${user.id}/change-password`),
        className: 'change-password-btn'
      }
      // {
      //   key: 'nationality',
      //   label: FORMLABELS.nationality,
      //   type: 'select',
      //   labelCol: { span: 24 },
      //   wrapperCol: { span: 24 },
      //   placeholder: '',
      //   disabled: false,
      //   initialValue: initData['nationality'],
      //   col: { span: 10, offset: 0 },
      //   option: nationOptions,
      //   showSearch: true,
      //   filterOption: (inputValue, option) => {
      //     return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      //   }
      // }
      // {
      //   key: 'nationtype',
      //   label: 'Type',
      //   type: 'select',
      //   labelCol: { span: 24 },
      //   wrapperCol: { span: 24 },
      //   placeholder: '',
      //   disabled: false,
      //   initialValue: initData['nationtype'],
      //   col: { span: 10, offset: 0 },
      //   option: docTypeOptions,
      //   showSearch: true,
      //   filterOption: (inputValue, option) => {
      //     return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      //   }
      // }
    ],
    /*[
      {
        key: 'contactInfo1',
        label: FORMLABELS.contactDetails,
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'homeCountryCode',
        label: formatFieldLabels('homeCountryCode','homeCountryCode'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'homePhone',
        label: formatFieldLabels('homePhone','homePhone'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        col: { span: 6, offset: 0 }
      },
      {
        key: 'mobileCountryCode',
        label: formatFieldLabels('mobileCountryCode','mobileCountryCode'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'mobilePhone',
        label: formatFieldLabels('mobilePhone','mobilePhone'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        col: { span: 6, offset: 0 }
      }
    ],
    [
      {
        key: 'contactInfo2',
        label: '',
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'email',
        label: formatFieldLabels('email','emailAddress'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        col: { span: 20, offset: 0 },
        rules : [
          {required : isFieldsRequired.email, message : 'Email is required'},
          {validator: (rule, value, callback) => callback(emailValidator2(value))}
        ]
      }
    ],
    /*[
      {
        key: 'address',
        label: FORMLABELS.address,
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'streetNumber',
        label: formatFieldLabels('streetNumber','streetNumber'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: '398',
        placeholder: '',
        initialValue: initData['streetNumber'],
        col: { span: 3, offset: 0 },
        rules : [
          {required : isFieldsRequired.streetNumber, floor : 'Value is required'}
        ]
      },
      {
        key: 'streetName',
        label: formatFieldLabels('streetName','streetName'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'Serangoon North Avenue 5',
        placeholder: '',
        initialValue: initData['streetName'],
        col: { span: 17, offset: 0 },
        rules : [
          { required : isFieldsRequired.streetName, message : 'Street Name is required' },
          { min: 2 , message: 'Need to more than 2 characters' }
        ]
      }
      // {
      //   key: 'floor',
      //   label: 'Floor #',
      //   type: 'text',
      //   labelCol: { span: 24 },
      //   wrapperCol: { span: 24 },
      //   // placeholder: '02',
      //   placeholder: '',
      //   initialValue: initData['floor'],
      //   col: { span: 3, offset: 0 },
      //   rules : [
      //     {required : isFieldsRequired.floor, floor : 'Value is required'}
      //   ]
      // },
      // {
      //   key: 'unit',
      //   label: 'Unit #',
      //   type: 'text',
      //   labelCol: { span: 24 },
      //   wrapperCol: { span: 24 },
      //   // placeholder: '01',
      //   placeholder: '',
      //   initialValue: initData['unit'],
      //   col: { span: 3, offset: 0 },
      //   rules : [
      //     {required : isFieldsRequired.unit, floor : 'Value is required'}
      //   ]
      // }
    ],
    [
      {
        key: 'city',
        label: formatFieldLabels('city','city'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['city'],
        col: { span: 6, offset: 4 },
        rules : [
          {required : isFieldsRequired.city, message : 'City is required'}
        ]
      },
      {
        key: 'state',
        label: formatFieldLabels('state','state'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['state'],
        col: { span: 3, offset: 0 },
        rules : [
          {required : isFieldsRequired.state, message : 'State is required'}
        ]
      },
      {
        key: 'postCode',
        label: formatFieldLabels('postCode','postCode'),
        type: 'text',
        maxLength: 6,
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: '554910',
        placeholder: '',
        initialValue: initData['postCode'],
        col: { span: 4, offset: 0 },
        rules : [
          {required : isFieldsRequired.postCode, message : 'Value is required'},
          {validator: (rule, value, callback) => callback(postCodeValidator(value))}
        ]
      },
      {
        key: 'country',
        label: formatFieldLabels('country','country'),
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['country'],
        col: { span: 7, offset: 0 },
        rules : [
          {required : isFieldsRequired.country, message : 'Country is required'}
        ],
        option: countryOptions,
        showSearch: true,
        filterOption: (inputValue, option) => {
          return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      }
    ],
    /*[
      {
        key: 'Patient_Details',
        label: 'Patient Details',
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'race',
        label: formatFieldLabels('race','race'),
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        disabled: false,
        initialValue: 'CN',
        col: { span: 10, offset: 0 },
        option: raceOptions,
        showSearch: true,
        filterOption: (inputValue, option) => {
          return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      },
      {
        key: 'language',
        label: formatFieldLabels('language','language'),
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        disabled: false,
        initialValue: 'MN',
        col: { span: 10, offset: 0 },
        option: langOptions,
        showSearch: true,
        filterOption: (inputValue, option) => {
          return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      },
      {
          key: 'insuranceProvider',
          label: formatFieldLabels('insuranceProvider','insuranceProvider'),
          type:'text',
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
          initialValue: initData['insuranceProvider'],
          col: { span: 12, offset: 4 },
          rules : [
              {required : isFieldsRequired.insuranceProvider, message : 'Insurance Provider is required'}
          ],
      },
      {
          key: 'BPYearOfDiagnose',
          label: formatFieldLabels('BPYearOfDiagnose','BPYearOfDiagnose'),
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
          initialValue: getDiagnoseDate(fieldsValue,'HYPERTENSION'),
          col: { span: 6, offset: 4 },
          rules:[{validator: (rule, value, callback) => callback(yearOfDiagnoseValidator(value))}]

      },
      {
          key: 'BGYearOfDiagnose',
          label: formatFieldLabels('BGYearOfDiagnose','BGYearOfDiagnose'),
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
          initialValue: getDiagnoseDate(fieldsValue,'DIABETES'),
          col: { span: 6, offset: 4 },
          rules:[{validator: (rule, value, callback) => callback(yearOfDiagnoseValidator(value))}]

      }
    ],*/
    // [
    //   {
    //     key: 'divider',
    //     type: 'divider',
    //     col: { span: 50, offset: 4 }
    //   }
    // ],
      // [
      // {
      //   key: 'Health_Conditions_Label',
      //   label: 'Health Conditions',
      //   type: 'label',
      //   col: { span: 4, offset: 0 }
      // },
      // {
      //   key: 'healthConditions',
      //   type: 'checkboxGroup',
      //   onFieldsChange,
      //   //placeholder: 'Type new health condition...',
      //   option: getNewOptions(user)
      // }
      //     ]
  ]

  return {
    vertical: true,
    fieldsValue,
    onFieldsChange,
    mapPropsToFields,
    itemList,
    nricEnable,
    usernameEnable,
  }
}
