import { React, _ } from 'ihcomponent';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import createTableAction from 'libModule/table/TableActions';
import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList';
import ListComponent from '../components/ListComponent';
import actions from '../actions/ListActions';

const tableActions = createTableAction('my_assessment_list', {
  sortFieldMap: { createdAt: 'DATE' },
});

const withData = graphql(resultList, {
  options: (ownProps) => {
    const variables = {
      filters: {
        type: ['ASSESSMENT', 'SURVEY']
      },
      count: 99999,
      sort: null,
    }

    const sortField = _.get(ownProps, 'sort.fieldGraph', null);
    if (sortField) {
      variables.sort = {
        field: sortField,
        direction: _.get(ownProps, 'sort.direction', 'ASC'),
      };
    }

    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    };
  },
});

const mapStateToProps = ({ myAssessmentAndSurvey, globalTables }, ownProps) => ({
  ...myAssessmentAndSurvey,
  ...globalTables.my_assessment_list,
});

const mapDispatchToProps = () => tableActions;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withData
)(ListComponent);
