import React from 'react';
import {
  formatFieldLabels,
  renderForm
} from '../../patient/AdditionalInfo/helpers';
import convertor from '@ihealth/convertor/index';
import { IHLoading, message } from 'ihcomponent';

import {
  Form,
  Icon,
  Col,
  Radio,
  Row,
  Button,
  Select,
  Checkbox,
  InputNumber,
  Input
} from 'antd';
import RenderFormComponent from '../../patient/AdditionalInfo/Components/RenderFormComponent';
import {
  dobValidator,
  emailValidator2,
  postCodeValidator,
  blockValidator,
  floorValidator,
  unitValidator,
  firstAndLastNameValidator,
  NRICValidator,
  patientWeightRegiterValidator,
  patientHeightInputValidator,
  patientHeightInchesInputValidator,
  mobileValidator,
  homePhoneValidator,
  yearOfDiagnoseValidator,
  patientDiastolicValueInputValidator,
  patientSystolicValueInputValidator
} from 'libModule/validator';
import editUser from 'modulesAll/graphql/mutation/editUser';
import i18nMap from '../../I18N';
import userGQL from '../../graphql/user';
import Client from 'libModule/gqlClient';
import helpers from '../helpers/';
import editVisit from 'graphqlModule/mutation/editVisit';

const { leaveAndSaveWorkTask } = helpers;
import {
  convertFeetToInches,
  convertInchesToFeet,
  getRemainderAfterConvertingToFeet
} from 'libModule/utils/convertUnits';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';
import { getMutateVariableMedicalHistory } from '../../patient/AdditionalInfo/helpers/getMutateValueHelper';
const Item = Form.Item;
const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;
const fieldNames = [
  'height',
  'height_inches',
  'insuranceProvider',
  'weight',
  'healthConditions'
];

const parseValue = props => {
  const { userId, toNextStep, setIsEditMode } = props;
  const mutatedValue = getMutateVariableMedicalHistory(props);
  let variables = DATE_HELPERS.trimAllValues(mutatedValue, '', mutatedValue);
  variables.id = userId;
  // 20200306 Thong - save BP measurements
  parseHeartBeatValue(props);
  return variables;
};

const parseHeartBeatValue = async props => {
  const { form, visit } = props;
  const { id } = visit;
  const { systolic, diastolic, heartBeat } = form.getFieldsValue();
  return Client.mutate({
    mutation: editVisit,
    variables: {
      id,
      bpMeasurement: {
        systolic_blood_pressure: {
          value: systolic || null,
          unit: 'mmHg'
        },
        diastolic_blood_pressure: {
          value: diastolic || null,
          unit: 'mmHg'
        },
        heart_rate: {
          value: heartBeat || 0,
          unit: 'beats/min'
        }
      }
    }
  });
};
const medicalHistoryConfig = props => [
  [
    {
      key: 'height',
      label: 'Height (ft)*',
      path: 'profile.height.value',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { marginRight: 5 },
      span: 2,
      min: 0,
      max: 9,
      rules: [
        {
          validator: (rule, value, callback) =>
            callback(patientHeightInputValidator(value, 'height'))
        },
        { required: true }
      ],
      inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
      formatValue: (height, format) => {
        const heightInInches = convertor.height(height, 'cm', 'inch', 0);
        return heightInInches
          ? convertInchesToFeet(heightInInches, 'cm', 'inch', 0)
          : '';
      }
    },
    {
      key: 'height_inches',
      label: `(in)`,
      labelCol: { span: 24 },
      wrapperCol: { span: 16 },
      path: 'profile.height.value',
      span: 3,
      min: 0,
      max: 11,
      rules: [
        {
          validator: (rule, value, callback) =>
            callback(patientHeightInchesInputValidator(value, 'height'))
        }
      ],
      inputContent: ({ disabled }) => <InputNumber />,
      formatValue: height => {
        const heightInInches = convertor.height(height, 'cm', 'inch', 0);

        return height ? getRemainderAfterConvertingToFeet(heightInInches) : '';
      }
    },
    {
      key: 'weight',
      label: 'Weight (lbs)*',
      labelCol: { span: 24 },
      wrapperCol: { span: 5 },
      path: 'profile.weight.value',
      span: 8,
      rowStyle: { marginLeft: 10 },
      rules: [
        {
          validator: (rule, value, callback) =>
            callback(patientWeightRegiterValidator(value, 'weight'))
        },
        { required: true }
      ],
      inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
      formatValue: weight =>
        weight ? convertor.HS(weight, 'kg', 'lbs', 0) : ''
    },
    {
      key: 'systolic',
      label: 'BP (mmHg)*',
      span: 2,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { marginRight: 5 },
      path: 'details.bpMeasurement.systolic_blood_pressure.value',
      formatValue: () =>
        _.get(
          props,
          'visit.details.bpMeasurement.systolic_blood_pressure.value'
        ),
      rules: [{
        validator: (rule, value, callback) => {
          callback(patientSystolicValueInputValidator(value, 'systolic'));
        }
      },
      { required: true}],
      inputContent: () => <InputNumber />
    },
    {
      key: 'none',
      label: '',
      span: 1,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { paddingTop: 30 },
      inputContent: () => <div>/</div>
    },
    {
      key: 'diastolic',
      label: `  `,
      labelCol: { span: 24 },
      wrapperCol: { span: 16 },
      colStyle: { marginLeft: '-25px' },
      span: 3,
      path: 'details.bpMeasurement.diastolic.value',
      rules: [
        {
          validator: (rule, value, callback) => {
            callback(patientDiastolicValueInputValidator(value, 'diastolic'));
          }
        },
        { required: true}],
      inputContent: () => <InputNumber />,
      formatValue: () =>
        _.get(
          props,
          'visit.details.bpMeasurement.diastolic_blood_pressure.value'
        )
    },
    {
      key: 'heartBeat',
      label: `Heart Rate(BPM)`,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      span: 4,
      path: 'details.bpMeasurement.heart_rate.value',
      formatValue: () =>
        _.get(props, 'visit.details.bpMeasurement.heart_rate.value') == 0
          ? null
          : _.get(props, 'visit.details.bpMeasurement.heart_rate.value'),
      inputContent: () => <InputNumber />
    }
  ],
  [
    {
      key: 'insuranceProvider',
      label: 'Insurance*',
      labelCol: { span: 24 },
      wrapperCol: { span: 8 },
      span: 24,
      rules: [
        {
          validator: (rule, value, callback) => {
            if (typeof value !== 'string' || !value.trim() || value.trim() === 'null') {
              callback('Insurance Provider is invalid');
            }

            if (!value) {
              callback('Insurance Provider is Required');
            }

            callback();
          }
        },
        { required: true}],
      path: 'profile.insuranceProvider',
      inputContent: ({ disabled }) => <Input disabled={disabled} />
    }
  ],
  [
    {
      key: 'healthConditions',
      path: 'profile.healthConditions',
      span: 12,
      rules: [{ required: true }],
      inputContent: props => (
        <Input style={{ display: 'none' }} className='ant-col-12' />
      )
    },
    {
      key: 'billableHealthConditions',
      path: 'profile.billableHealthConditions',
      span: 12,
      inputContent: props => (
        <Input style={{ display: 'none' }} className='ant-col-12' />
      )
    }
  ]
];
let VitalsAndBillingComponent = class extends React.Component {
  saveEdit = async (flag, setEditMode, msg) => {
    const { userId, toNextStep, setIsEditMode, form } = this.props;
    const res = await parseHeartBeatValue(this.props);
    if (res) {
      Client.mutate({
        mutation: editUser,
        variables: parseValue(this.props),
        refetchQueries: [
          {
            query: userGQL,
            variables: { id: userId },
            fetchPolicy: 'network-only'
          }
        ]
      }).then(res => {
        flag ? toNextStep('VisitBilling', null, msg) : message.success(msg);
        setIsEditMode(false);
      });
    }
  };

  handleOnClickSaveDraft = () => {
    // const { form } = this.props;
    // form.validateFieldsAndScroll(
    //   [
    //     'height',
    //     'height_inches',
    //     'weight',
    //     'diastolic',
    //     'systolic',
    //     'heartBeat'
    //   ],
    //   (error, fields) => {
    //     if (!error) {
          this.saveEdit(false, true, 'Vitals And Billing draft is Saved');
    //     }
    //   }
    // );
  };
  handleOnClick() {
    const { form } = this.props;
    const parsedValue = getMutateVariableMedicalHistory(this.props);
    form.validateFieldsAndScroll((error, fields) => {
      if (!error) {
        this.saveEdit(true, true, 'Vitals And Billing is Saved');
      }
    });
  }
  componentWillUnmount() {
    const { props } = this;
    const { setIsEditMode, isEditMode } = props;
    if (isEditMode) {
      setIsEditMode(false);
    }
  }
  render() {
    const { props, saveEdit, handleOnClickSaveDraft } = this;
    const { form, user, isEditMode } = props;

    return (
      <div>
        <Row>
          <RenderFormComponent
            user={user}
            config={medicalHistoryConfig(props)}
            renderPicker={true}
            form={form}
            handleEnableEditMode={() => {}}
          />
        </Row>
        <Row style={{ float: 'right', paddingTop: 10 }}>
          {isEditMode ? (
            <Button
              onClick={() => handleOnClickSaveDraft()}
              style={{ width: 130, marginRight: 10 }}
            >
              Save Draft
            </Button>
          ) : (
            ''
          )}
          <Button
            onClick={() => this.handleOnClick()}
            type={'primary'}
            style={{ width: 130 }}
          >
            Complete
          </Button>
        </Row>
      </div>
    );
  }
};
VitalsAndBillingComponent = Form.create({
  onFieldsChange(props, fields) {
    const isCompleted =
      _.get(props, 'visit.maWorkingList.VisitBilling') == 'COMPLETED';
    const { childProps, setChildProps, isEditMode, user } = props;
    const userId = _.get(user, 'id');

    if (!childProps) {
      const refetchQueries = [
        {
          query: userGQL,
          variables: { id: userId },
          fetchPolicy: 'network-only'
        }
      ];
      setChildProps(
        props,
        parseValue,
        editUser,
        refetchQueries,
        'Vitals And Billing'
      );
    }
    if (!isEditMode) {
      props.setIsEditMode(true);
    }
    if (isCompleted) {
      leaveAndSaveWorkTask('VisitBilling', null, props);
    }
  }
})(VitalsAndBillingComponent);

export default VitalsAndBillingComponent;
