import React from "react";
import {compose, graphql} from "react-apollo/index";
import { Form,Select,AutoComplete,Button,Switch,Icon,Tooltip } from 'antd';
import {connect} from "react-redux";
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'libModule/utils';
import { message } from 'ihcomponent';
import loanDeviceList from '../query/loanDeviceList';
import assignLoanDevice from '../mutate/assignLoanDevice';
import Client from 'libModule/gqlClient';
import getLoanDevice from "../query/getLoanDevice";

const Item = Form.Item;
const Option = AutoComplete.Option;


let AddNewLoanDevice =  class extends React.Component {
    constructor(){
        super();
    }

    getOptions(loanDeviceList) {
        const options = loanDeviceList.map(({deviceId}) => {
            return <Option key={deviceId} value={deviceId}>{deviceId}</Option>
        });

        return options;
    }

    renderAutoComplete(loanDeviceList) {
        const children = [...this.getOptions(loanDeviceList)];
        const { disabled }  = _.get(this,'props.state.loanDeviceInfo');

        return (
                <Select
                    dropdownMatchSelectWidth={true}
                    dataSource={children}
                    style={{ width: 200 }}
                    allowClear={true}
                    showSearch
                    disabled={disabled}
                    notFoundContent='Device Number not Found'
                >
                    { children }
                </Select>
                )
    }


    onSubmit = (e)=>{
        e.preventDefault();
        const { form,openErrorModal,setHasDevice,refetchLoanDevice,setInputFieldDisabled, setAssignment } = this.props;
        const memberId = _.get(this.props,'data.user.id',_.get(this,'props.userId'));
        const { validateFields } = form;

        validateFields(['deviceId'],(errors, { deviceId }) => {
            if(!errors){
                let variables = {
                    deviceId,
                    memberId,
                };
                Client.mutate({
                    mutation:assignLoanDevice,
                    variables,
                    refetchQueries:[{
                        query:getLoanDevice,
                        variables:{
                            memberId
                        },
                        fetchPolicy:'network-only',
                        notifyOnNetworkStatusChange: true
                    }]
                })
                .then(res => {
                    if(setInputFieldDisabled) setInputFieldDisabled('loanDeviceInfo');
                    message.success('Device Assigned');
                    if(setAssignment) {
                        setAssignment(true, res.data.assignLoanDevice);
                    }
                })
                .catch(e=>{
                    console.log(e);
                    openErrorModal(GQLHelper.formatError(e))
                });
                //     .catch(e=>openErrorModal(GQLHelper.formatError(e)));
                // updateIoTDeviceMemberAPI(variables,memberId)
                //     .then(res=>
                //         editIoTDeviceConfigAPI({ deviceId,language,volume: volume ? 50 : 0 },memberId)
                //             .then(res=>{
                //                 message.success('Device Assigned');
                //                 setInputFieldDisabled('clearInfo');
                //             })
                //             .catch(e=>openErrorModal(GQLHelper.formatError(e)))
                //     )
                //     .catch(e=>openErrorModal(GQLHelper.formatError(e)));

            }
        })
    }



    renderForm=()=>{
        const { form,loanDeviceList } = this.props;
        const { getFieldDecorator,getFieldValue } = form;
        const { disabled }  = _.get(this,'props.state.loanDeviceInfo');

        return <Form className='' layout={'vertical'} disabled={disabled}>
                    <Item label="Device Id" required={true}>
                        {getFieldDecorator('deviceId',{rules:[{
                                required:true
                            }]})(this.renderAutoComplete(loanDeviceList||[]))}
                    </Item>
                    <Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Button type="primary" htmlType="submit" onClick={(e)=>this.onSubmit(e)} disabled={disabled}>
                            Assign Device
                        </Button>
                    </Item>
                </Form>
    }

    render(){
        return this.renderForm();
    }
}
const withData = graphql(loanDeviceList,{
    options:(ownProps)=>{
        return {
            variables:{ status:'AVAILABLE' },
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
        const loanDeviceList  = _.get(data,'loanDeviceList.data',[]);
        const loading = _.get(data,'loading');
        if(loading){
            return{
                loading
            }
        }
        return{
            loanDeviceList
        }
    }
});

AddNewLoanDevice = Form.create({
    onFieldsChange(props){
        props.handleOnFocus('loanDeviceInfo')
    }
})(AddNewLoanDevice);
const mapToDispatch = (dispatch)=>{
    return{
        openErrorModal: err => dispatch(openErrorModal(err)),
    }
}
export  default compose(connect(null,mapToDispatch),withData)(AddNewLoanDevice);
