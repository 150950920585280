import { constants } from '../../../lib/utils';

export default constants('AWSConnect', 'main', [
  'CONTACT_STATUS',
  'CALL_STATUS',
  'LAST_CALL_PATIENT_ID',
  'LAST_CALL_PATIENT_NAME',
  'LAST_CALL_START_TIME',
  'LAST_CALL_END_TIME',
]);
