import {  _ } from 'ihcomponent';
import {moment} from "../../../../../package/IHComponent";
import get from "lodash/get";


const filterDoctors =  () => {
    const teams = JSON.parse(sessionStorage.getItem('currentUser'))&&JSON.parse(sessionStorage.getItem('currentUser')).team;
      let doctors =[];
      for(let index in teams){
          const members = teams[index].members;
          for(let memberId in members){
                const member = members[memberId];
                const allRoles = _.get(member,'user.allRoles',[]);
                const hasDoctorRole = _.filter(allRoles,a=>a.name=='Doctor').length!=0;
                const { profile,id }  = member.user;
                if(hasDoctorRole){
                      const doctor = {
                            id:id,
                            fullName:profile.fullName,
                            primary:true
                      }
                      if(!_.find(doctors,{id:id}))
                        doctors.push(doctor);
                }
          }
      }

      return doctors;
}

const combineDateAndTime =(date,time)=>{

    const startOfDate = moment(date).startOf('day').toDate().getTime();
    const startOfTime = moment(time).startOf('day').toDate().getTime();
    const timeInMs = moment(time).toDate().getTime() - startOfTime;
    const combinedTime = (startOfDate + timeInMs);
    return combinedTime;
}
const setFromAndToDate = (variables,defaultStartDay,defaultEnDay,sortByConfirm)=>{
    variables.appointmentFrom  = Number(defaultStartDay);
    variables.appointmentTo = Number(defaultEnDay);
    variables.count = 99999;
    const { provider } = { provider:_.reduce(helpers.filterDoctors(),(res,d)=>{
                res.push(d.id)
                return res;
            },[]
        )};
    variables.providerIds= provider;
    variables.sort = {};
    variables.sortByConfirm = sortByConfirm||false;
    return variables;
}

export const  helpers = {
    filterDoctors,
    combineDateAndTime,
    setFromAndToDate
}
