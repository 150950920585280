import gql from 'graphql-tag';
import { UserMini } from './schema/User';
import { Phone, Email } from './schema/common';

export default gql`
  query teamList($page:Int, $count:Int, $filters:TeamListOpts, $sort:TeamListSort, $search:TeamSearchInput){
    teamList(page:$page, count:$count, filters:$filters, sort:$sort, search:$search){
      data{
        id
        createdAt
        updatedAt
        modifiedAt
        createdBy{
          ${UserMini}
        }
        modifiedBy{
          ${UserMini}
        }
        name
        description
        phone {
          ${Phone}
        }
        email {
          ${Email}
        }
        healthCondition
        contactName
        members{
          role {
            category
            name
          }
          primary
          user{
            ${UserMini}
          }
        }
      }
      pageInfo{
        total
        lastPage
      }
    }
  }
`;
