import gql from 'graphql-tag'

export default gql`
mutation createOrganization(
  $identifier:String, 
  $name:String!,
  $parentId:EID!, 
  $phone:InputPhone,
  $leaf:Boolean, 
  $accessTmpl:AccessTmpl!,
  $description:String, 
  $address:InputAddress,
  $timezone:String,
  ) {
  	createOrganization(
      identifier: $identifier,
      name: $name,
      parentId: $parentId,
      phone: $phone,
      leaf: $leaf,
      accessTmpl: $accessTmpl,
      description: $description,
      address: $address,
      timezone: $timezone
    )
    {
      id
      name
    }
  }
`
