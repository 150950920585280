import { React } from 'ihcomponent'
import { graphql } from 'react-apollo'
import PatientAssessmentList from '../components/PatientAssessmentListComponent'
import { createContainer } from 'libModule/utils'
import { modalAction }  from 'modulesAll/common/actions'
import get from 'lodash/get'
import { assessmentSurveyResultList as resultList } from 'modulesAll/graphql/resultList'
import { onAddClick } from '../actions/PatientAssessmentListActions'

const Component = (props) => {
  return (
      <div className="patient-program-assessment-table">
        <div className="v-table">
          <PatientAssessmentList {...props} />
        </div>
      </div>
  )
}

Component.displayName = 'patient/asssessmentList/containers/PatientAssessmentListContainer';

// const resultsFromTodayWithData = graphql(resultsFromToday, {
//   name: 'resultsFromTodayData',
//   options: (ownProps) => {
//     return {
//       variables: {
//         "options": {
//           memberId: ownProps.currentProgram.user.id
//         }
//       },
//     }
//   },
//   props: ({ownProps, resultsFromTodayData}) => {
//     const { resultListData } = ownProps
//
//     if (resultListData.loading || resultsFromTodayData.loading) {
//       return {
//         ...ownProps,
//         loading: true
//       }
//     }
//     const resultsFromTodayArray = resultsFromTodayData.resultsFromToday || []
//     const ret = resultsFromTodayArray.filter(item => {
//       return item.type === 'ASSESSMENT' || item.type === 'SURVEY'
//     })
//
//     return {
//       ...ownProps,
//       loading: false,
//       data: [
//         ...ret,
//         ...(get(resultListData, 'resultList.data') || [])
//       ]
//     }
//   }
// })(Component);

const resultListWithData = graphql(resultList, {
  name: 'resultListData',
  options: (ownProps) => {
    return {
      variables: {
        filters: {
          type: ['ASSESSMENT', 'SURVEY'],
          enrolledProgramId: ownProps.currentProgram.id
        },
        count: 999,
        page: 1,
        sort: {
          field: 'DATE',
          direction: 'DESC'
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ resultListData }) => {
      return {
        loading: false,
        data: (get(resultListData, 'resultList.data') || [])
      }
  }

})(Component)

const mapState = ({ }, ownProps) => {
  return {
    ...ownProps
  }
}

const mapDispatch = (dispatch, ownProps) => {
  return {
    onAddClick: (row) => dispatch(onAddClick(row)),
    openModal: (content, modalProps) => dispatch(modalAction.openModal(content, modalProps)),
  }
}

export default createContainer(resultListWithData, mapState, mapDispatch)
