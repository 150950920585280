import gql from 'graphql-tag'
import {User} from 'graphqlModule/schema/User'

export default gql `
    mutation editUserSettings (
      $userSettings: InputUserSettings!,
    ) {
      editUserSettings (
        userSettings: $userSettings,
      ) {
        ${User}
      }
    }
`
