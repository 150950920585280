import React from 'react';
import { Select,Form,Switch,Button,Row,Col,Icon,Tooltip } from 'antd';
import { message } from 'ihcomponent';
import { GQLHelper } from 'libModule/utils';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import '../style/index.scss';
import { language,languageConfig } from '../constant/';
const { Item } = Form;
// const language = [ {key:'en',value:'English'},{key:'de',value:'Germany'},
//                    {key:'es',value:'Spanish'},{key:'el',value:'Greek'},{key:'fr',value:'French'},{key:'it',value:'Italian'},
//                    {key:'nl',value:'Dutch'},{key:'pt',value:'Portuguese'},{key:'zh',value:'Chinese'}
//                  ]
let DeviceConfigUpdateComponent = class extends React.Component{
    constructor(){
        super();
    }
    getOptions() {
        const options = language.map(({key,value}) => {
            return <Select.Option key={key} value={key}>{value}</Select.Option>
        });
        const { disabled }  = _.get(this,'props.state.clearInfo');

        return <Select
                dataSource={options}
                style={{ width: 200 }}
                onChange={()=>this.setVolume()}
                disabled={disabled}
               >
                {options}
               </Select>


    }

    onSubmit = (e)=>{
        e.preventDefault();
        const { form,openErrorModal,editIoTDeviceConfigAPI,deviceId,setInputFieldDisabled, updateTrackSetConfig } = this.props;
        console.log(deviceId);
        const { validateFields } = form;

        validateFields(['language', 'volume'], (errors, { language, volume} ) => {
            if(!errors){
                let variables = {
                    language,
                    deviceId,
                    volume: volume ? 50 : 0
                }
                editIoTDeviceConfigAPI(variables).then(res=>{
                    message.success('Device Configuration Updated');
                    if(setInputFieldDisabled) setInputFieldDisabled('clearInfo');
                    if(updateTrackSetConfig) updateTrackSetConfig();
                })
                .catch(e=>openErrorModal(GQLHelper.formatError(e)));;
            }
        })
    }

    setVolume(){
        const { form } = this.props;
        const { resetFields,setFieldsValue } = form;
        setFieldsValue({volume:false});
    }

    renderForm(){
        const { form,deviceObj, forModal } = this.props;
        const { getFieldDecorator,getFieldValue } = form;
        const config = _.get(deviceObj,'config')||{ };
        const { language,volume } = config
        const selectedLanguage = getFieldValue('language');
        const { disabled }  = _.get(this,'props.state.clearInfo');

        return <Form layout={'vertical'} className='addDeviceForm'>
                    <Item label="Device Language">
                        {getFieldDecorator('language',{ initialValue:language||'en' })(this.getOptions())}
                    </Item>
                    <Item label={
                        <div>
                            Text to Speech
                            <Tooltip title={`Enable speech output on the blood pressure monitor to read out results.Currently only available for English and French`} >
                                <Icon type='info-circle' style={{ marginLeft: 10 }}/>
                            </Tooltip>
                        </div>
                    }>
                        {getFieldDecorator('volume', { valuePropName: 'checked',initialValue:volume===50 })(<Switch disabled={disabled||(!_.get(languageConfig,`${selectedLanguage||language}.volume`,false))} />)}
                    </Item>
                    {
                        forModal ?
                            <Row>
                                <Col style={{ marginTop: 25 }}>
                                    <Button
                                        type="primary"
                                        onClick={e => this.onSubmit(e)}
                                    >
                                        Done
                                    </Button>
                                    <this.props.UnassignButton />
                                </Col>
                            </Row>
                        :
                            <Row>
                                <Item wrapperCol={{ span: 12, offset: 6 }}>
                                    <Button type="primary" htmlType="submit" onClick={(e)=>this.onSubmit(e)} disabled={disabled}>
                                        Submit
                                    </Button>
                                </Item>
                            </Row>
                    }
               </Form>
    }

    render(){
        return this.renderForm();
    }

};

DeviceConfigUpdateComponent = Form.create({onFieldsChange:function(props){
        props.handleOnFocus('clearInfo')
    }})(DeviceConfigUpdateComponent);

export default DeviceConfigUpdateComponent;
