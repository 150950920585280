import {_} from 'ihcomponent'
import type from '../constants'
import {getRefIDWithRoleCategory} from 'libModule/utils'
const initialState = {
  variables: {
    page : 1,
    count : 100,
    options: {
      role: [ getRefIDWithRoleCategory('PROVIDER:Nurse'),getRefIDWithRoleCategory('PROVIDER:Doctor'),getRefIDWithRoleCategory('PROVIDER:Medical Assistant') ]
    },
    sort : {
      field : 'BIRTHDAY',
      direction : 'ASC'
    }
  },
  modal : {
    show : false,
    select : null
  }
}

export default (state=initialState, action)=>{
  if(action.type === type.SET_VARIABLES){
    const variables = _.merge({}, state.variables, action.variables)
    return {
      ...state,
      variables
    }
  }

  if(action.type === type.RESET){
    return {
      ...state,
      modal: {
        show : false,
        select : null
      }
    }
  }

  return state
}
