import { IHButton, React, _ } from 'ihcomponent'
import { browserHistory } from 'react-router';
import I18N from 'modulesAll/I18N'
import { convertFieldsValue, convertToFieldsValue } from 'libModule/helpers/reducer-helpers'
import moment from 'moment'
import get from 'lodash/get'
import {
  VITAL_THRESHOLD_MAP,
  VITAL_UNIT_MAP,
  VITAL_TYPE_MAP,
  VITAL_THRESHOLD_MAP_DEFAULT_VALUE,
  VITAL_TYPE_MAP_FROM_SHORT,
  MISSED_TYPE_VALUE,
  THRESHOLD_TYPE_VALUE,
  VITAL_TYPES_SHORT,
  ALERT_FORMULA_MAP,
  ALERT_TRIGGERS_TYPE,
  DEFAULT_ALERT_MISSED_READING,
  ALERT_MESSAGE_MAP_RAW
} from '../../utils/constants/task'
import { getInitDataFromSchema } from 'libModule/utils'
import { schema as alertSchema } from 'commonModule/components/careplan/formData/AssessmentAlertForm'
import { RequestCache} from 'libModule/utils'
import { formatSingleAssSurSchedule } from 'libModule/helpers/component-helpers'
import Store from 'libModule/reduxStore';
import { convertBG_mgdlTommol, convertBG_mmolTomgdl } from 'libModule/utils/convertUnits';
import { TASK_TYPE_MAP } from 'modulesAll/utils/constants/task'
import { Icon } from 'antd';
import * as R from 'ramda';
import { HSColor } from 'modulesAll/patientView/vitalList/constants/VitalListConstants';

import { emptyWeeklyScheduleData } from './emptyWeeklyScheduleData';
import { removeTypename, removeCurrentLabel } from '../../../../../package/IHComponent/ShareCare/addWeeklyBGScheduleTemplateAPI';
import convertor from '@ihealth/convertor';
import {BMIRes} from "../constants/BMIMap";

const debug = true;
const traceF = (label) => R.tap((x)=>console.log(label, x));
const trace = debug ? traceF : () => R.identity;


const alertTmpl = I18N.get('Program.alertTmpl')

export const hasElement = (array, element) => {
  return Array.isArray(array) && array.indexOf(element) !== -1
}

const dayToWeekBinary = {
  sun:  '0000001',
  mon:  '0000010',
  tues: '0000100',
  wed:  '0001000',
  thurs:'0010000',
  fri:  '0100000',
  sat:  '1000000',
};

const beforeMealDict = {
  before_breakfast: {
    beforeMeal: 'BEFORE_MEAL',
    mealType: 'BREAKFAST',
  },
  after_breakfast: {
    beforeMeal: 'AFTER_MEAL',
    mealType: 'BREAKFAST',
  },
  before_lunch: {
    beforeMeal: 'BEFORE_MEAL',
    mealType: 'LUNCH',
  },
  after_lunch: {
    beforeMeal: 'AFTER_MEAL',
    mealType: 'LUNCH',
  },
  before_dinner: {
    beforeMeal: 'BEFORE_MEAL',
    mealType: 'DINNER',
  },
  after_dinner: {
    beforeMeal: 'AFTER_MEAL',
    mealType: 'DINNER',
  },
  bedtime: {
    beforeMeal: 'BEFORE_MEAL',
    mealType: 'BEDTIME',
  },
  overnight: {
    beforeMeal: 'BEFORE_MEAL',
    mealType: 'OVERNIGHT',
  }
};

// const convertDailyScheduleToArrayToSlots = ([day, dailySchedule]) => R.compose(
//   R.map(([mealTime, _]) =>
//     R.merge({
//       calendar: parseInt(dayToWeekBinary[day],2) },
//       beforeMealDict[mealTime]
//     )
//   ),
//   R.filter(v => R.nth(1,v) === true),
//   R.toPairs
// )(dailySchedule);

// const convertWeeklyMealScheduleToTimeSlots = R.compose(
//   R.unnest,
//   R.map(convertDailyScheduleToArrayToSlots),
//   R.toPairs
// );
const convertWeeklyMealScheduleToTimeSlots = (schedule) => {
  const schedulePairs = R.toPairs(schedule);
  const ret =  R.unnest(schedulePairs.map(([day, dailySchedule])=>{
    const dailyPairs = R.toPairs(dailySchedule).filter(v => v[1]===true);
    return dailyPairs.filter(v => v.length > 0).map(([mealTime, _]) => {
              return  R.merge({
                        calendar: parseInt(dayToWeekBinary[day],2) },
                        beforeMealDict[mealTime]
                      );
            })
  }));
  //console.log ('RET',ret );
  return ret;
}
const convertArraySlotsToDailySchedule = ([beforeMeal, mealType]) => {
  switch(mealType) {
    case 'BREAKFAST':
      switch(beforeMeal) {
        case 'BEFORE_MEAL':
          return 'before_breakfast';
        case 'AFTER_MEAL':
          return 'after_breakfast';
      }
    case 'LUNCH':
      switch(beforeMeal) {
        case 'BEFORE_MEAL':
          return 'before_lunch';
        case 'AFTER_MEAL':
          return 'after_lunch';
      }
    case 'DINNER':
      switch(beforeMeal) {
        case 'BEFORE_MEAL':
          return 'before_dinner';
        case 'AFTER_MEAL':
          return 'after_dinner';
      }
    case 'BEDTIME':
      return 'bedtime';
    case 'OVERNIGHT':
      return 'overnight';
    default:
      return 'notime';
  };
};

const shortWeekdayDict = {
  sun:  'SUNDAY',
  mon:  'MONDAY',
  tues: 'TUESDAY',
  wed:  'WEDNESDAY',
  thurs:'THURSDAY',
  fri:  'FRIDAY',
  sat:  'SATURDAY',
};

const convertArraySlotsToWeeklySchedule = (acc, { calendar, beforeMeal, mealType }) => {
  const dailySchedule = convertArraySlotsToDailySchedule([beforeMeal, mealType]);
  const days = R.map(longWeekday => R.invertObj(shortWeekdayDict)[longWeekday], calendar ? calendar : 127);
  const weeklySchedule = R.mergeDeepRight(acc, R.zipObj(days, R.repeat({ [dailySchedule]: true }, R.length(days))));
  return weeklySchedule;
};

export const convertArraySlotsToMergedWeeklySchedule = R.reduce(convertArraySlotsToWeeklySchedule, emptyWeeklyScheduleData.schedule);

// createProgram
const generateScheduleForDB = (vital) => {
  const { schedule, vitalType } = vital
  // TODO
  if (!schedule) return []
  const { frequency, frequencyValue, timeSlotValue, timeSlotValueForMeal } = schedule
  let result = []
  if (vitalType === VITAL_TYPE_MAP_FROM_SHORT['BG']) {
    if (frequency === 'weeklyMeal') {
      result = convertWeeklyMealScheduleToTimeSlots(schedule.weeklyMealSchedule.schedule);
    } else if (frequency === 'frequency') {
      if (frequencyValue.times) {
        result.push({
          target: frequencyValue.times,
          beforeMeal: 'ANY_TIME'
        })
      }
    } else {
      // timeSlotValueForMeal: ["BEFORE_LUNCH", "AFTER_LUNCH", "BEFORE_DINNER"]
      timeSlotValueForMeal.forEach(value => {
        const mealArray = value.split('_')
        const beforeMeal = mealArray[0] === 'BEFORE' ? "BEFORE_MEAL" : "AFTER_MEAL"
        const mealType = mealArray[1] === 'SUPPER' ? 'SNACK' : mealArray[1]
        result.push({
          calendar: 127, // from MONDAY to SUNDAY
          beforeMeal,
          mealType
        })
      })
    }
  } else {
    // BP and Weight and Heart Rate
    if (frequency === 'frequency') {
      if (frequencyValue.times) {
        result.push({
          target: frequencyValue.times,
          start: '0000',
          end: '2400'
        })
      }
    } else if (frequency === 'slot') {
      timeSlotValue.forEach(timeObject => {
        result.push({
          calendar: 127, // from MONDAY to SUNDAY
          target: 1,
          start: timeObject.value[0].format('HHmm'),
          end: timeObject.value[1].format('HHmm')
        })
      })
    }
  }
  return result
}

const generateWeightType = (vitalData) => {
  const weightBasedOn = _.get(vitalData, 'weightBasedOn') || 'Baseline'
  return weightBasedOn === 'Baseline' ? 'WEIGHT_CHANGE_BASELINE' : 'WEIGHT_CHANGE'
}
const generateThresholdForDB = (vitalData, fieldsValue) => {
  const { vitalType } = vitalData
  const thresholdTypeMap = {
    'Blood Pressure': {systolic: 'SYSTOLIC', diastolic: 'DIASTOLIC'},
    'Blood Glucose': {BGBeforeMeal: 'BEFORE_MEAL', BGAfterMeal: 'AFTER_MEAL'},
    'Weight': { HS: generateWeightType(vitalData) },
    'Heart Rate': { HR: 'HEART_RATE' },
    'Temperature': { TM: 'BODY_TEMPERATURE' },
    'Blood Oxygen': { PO: 'BLOOD_OXYGEN' },
  }
  const threshold = thresholdTypeMap[vitalType]
  return Object.keys(threshold).map(key => { // ['BGBeforeMeal', 'BGAfterMeal']
    const range = vitalData[key].slice(0, 4)
    const convertedRange = range.map( val => {
      if (vitalType === 'Blood Glucose' && val && fieldsValue[key].touched) { //only do the conversion for BG when the input is touched (has changed)
        return convertBG_mgdlTommol(val);
      }
      return val;
    });
    const result = {
      measure: threshold[key],
      range: convertedRange,
      unit: VITAL_UNIT_MAP[vitalType],
    }
    if (vitalType === 'Weight') {
      result.baseline = _.get(vitalData[key], '4')
    }
    return result
  })
}

const getRepeatFromVital = (schedule) => {
  if (schedule.frequency === 'weeklyMeal') {
    return 'SCHEDULED'
  } else if (schedule.frequency !== 'frequency') {
    return 'SCHEDULED'
  } else {
    return _.get(schedule, 'frequencyValue.unit')
  }
}

const addTemplateDetails = (schedule, taskObject) => {
  if (schedule.frequency === 'weeklyMeal') {
    const templateDetails = removeCurrentLabel(removeTypename(schedule.weeklyMealSchedule));
    return R.merge(taskObject, { scheduleType: 'weeklyMeal', templateDetails });
  } else return taskObject;
};

// create notification/alert
const convertAlertToNotificationSettings = (panesFieldsValue, taskType, { MISSED_TYPE = 'Missed Reading', THRESHOLD_TYPE = 'Threshold', isBaselineWeight }) => {
  if (Object.keys(panesFieldsValue).length === 0) { return [] }
  const notification = []
  Object.keys(panesFieldsValue).filter((key) => {
    const fieldsValue = panesFieldsValue[key]
    // const type = _.get(fieldsValue, 'type.value')
    // const person = _.get(fieldsValue, 'person.value')
    const fieldsData = convertFieldsValue(fieldsValue)
    const alertType = _.get(fieldsData, 'alertType')
    return alertType
      // && Array.isArray(type) && type.length > 0
      // && Array.isArray(person) && person.length > 0

  }).forEach(key => {
    const fieldsValue = panesFieldsValue[key]
    const type = _.get(fieldsValue, 'type.value') || []
    const fieldsData = convertFieldsValue(fieldsValue)
    const alertType = _.get(fieldsData, 'alertType')
    const person = _.get(fieldsValue, 'person.value', []).filter(p => p !== 'DASHBOARD')

    const tmpl = person.filter(psn => psn).map(psn => {
      return {
        subject: alertTmpl.subject,
        body: ALERT_MESSAGE_MAP_RAW[alertType],
        person: psn
      }
    })
    const result = {
      type,
      tmpl
    }
    const trigger = fieldsData.trigger ? fieldsData.trigger : ALERT_TRIGGERS_TYPE
    if (alertType === THRESHOLD_TYPE) {
      result.trigger = trigger.join('-').split('-')
      result.formula = ALERT_FORMULA_MAP[isBaselineWeight ? 'WEIGHT_CHANGE_BASELINE' : taskType]
    } else if (alertType === MISSED_TYPE) {
      result.chance = fieldsData.chance || DEFAULT_ALERT_MISSED_READING
    }
    notification.push(result)
  })
  return notification
}

export const CareplanHelper = {
  convertTaskToAlertFormData: (task) => {
    if (!task) return
    const result = _.pick(task, [
      'schedule', 'repeat'
    ])
    const calendar = task.schedule.reduce((pre, cal) => {
      return pre.concat(cal.calendar);
    }, [])
    delete result.schedule
    return {
      ...result,
      calendar
    };
  },
  convertOldToNewDBTasks: (tasks, taskType) => {
    if (!tasks || !tasks.length) {return []}
    tasks.forEach(task => {
      if (task.hasOwnProperty('__typename')) { delete task.__typename }
      if (Array.isArray(task.frequency)) {
        task.frequency = task.frequency.join(',')
      }
      if (task.type === 'ASSESSMENT' || task.type === 'SURVEY') {
        task.assessment = { nameKey: _.get(task.assessment, 'nameKey')}
      }
      if (taskType === 'editEnrolledProgram') {
        if (task.hasOwnProperty('description')) { delete task.description }
        if (task.hasOwnProperty('type')) { delete task.type }
      }
    })
    return tasks
  },
  convertAssessmentFormDataToDBTask: (assessmentData, assessmentType='ASSESSMENT') => {
    // WEEKLY task do not support frequency field
    const { id, assessment, repeat, calendar } = assessmentData
    const schedule = [{
      calendar: calendar.length > 0 ? calendar.join(',') : '',
      target: 1,
      start: '0000',
      end: '2400'
    }]
    const result = {
      assessment: { nameKey: assessment.nameKey },
      type: assessmentType,
      repeat,
      schedule
    }
    if (id) {
      result.id = id
    }
    return result
  },
  convertAssessmentToDBTask: (assessmentTasksInProgram, assessment, assessmentType='ASSESSMENT') => {
    // WEEKLY task do not support frequency field
    const alertFormData = getInitDataFromSchema(alertSchema)
    const position = _.findIndex(assessmentTasksInProgram,
      (task => _.get(task, 'assessment.nameKey') === assessment.nameKey))
    const isExisted = position > -1
    if (!isExisted) {
      const assessmentData = {
        ...alertFormData,
        assessment
      }
      return CareplanHelper.convertAssessmentFormDataToDBTask(assessmentData, assessmentType)
    }
    // use existed assessment
    const currentAssessment = assessmentTasksInProgram[position]
    const { repeat, schedule, notification } = currentAssessment
    const newSchedule = _.pick(schedule[0], ['target', 'start', 'end'])
    newSchedule.calendar = schedule.reduce(
      (pre, s) => {return pre.concat(s.calendar)}, []
    ).join(',')
    const result = {
      id: currentAssessment.id,
      assessment: { nameKey: assessment.nameKey },
      type: assessmentType,
      repeat,
      schedule: [newSchedule],
      notification
    }
    return result
  },
  combineAssessmentTasks: (programTasks=[], formData, assessment, { assessmentType='ASSESSMENT', taskType='editProgram',  panesFieldsValue }) => {
    const tasks = (programTasks || []).filter(task => assessmentType === task.type)
    const assessmentData = {
      ...formData,
      assessment
    }
    // we only need nameKey from assessment
    const assessmentTask = CareplanHelper.convertAssessmentFormDataToDBTask(assessmentData, assessmentType)
    const position = _.findIndex(tasks, (task => _.get(task, 'assessment.nameKey') === assessment.nameKey))
    const isExisted = position > -1
    if (isExisted) {
        assessmentTask.id = _.get(tasks[position], 'id')
    }
    const notification = convertAlertToNotificationSettings(panesFieldsValue, assessmentType, {})
    if (notification) {
      assessmentTask.notification = notification
    }
    return assessmentTask
  },
  convertAssessmentListToDBTasks({ assessmentListData, AlertForm, program, selectedIds, assessmentType='ASSESSMENT' }) {
    const selectedAssessments = _.map(selectedIds, _id => assessmentListData.find(i => i._id === _id)).filter(data=> !!data);
    const programTasks = program.tasks || []
    const assessmentTasksInProgram = programTasks.filter(task => assessmentType === task.type)
    const selectedAssessmentsForDB = selectedAssessments.map(assessment => {
      return CareplanHelper.convertAssessmentToDBTask(assessmentTasksInProgram, assessment, assessmentType)
    });
    const dbAssessmentNameKeys = selectedAssessmentsForDB
      .filter(assessment => assessment.hasOwnProperty('id'))
      .map(assessment => _.get(assessment, 'assessment.nameKey'))
    const removedTasksId = assessmentTasksInProgram
      .filter(assessmentTask => !_.includes(dbAssessmentNameKeys, _.get(assessmentTask, 'assessment.nameKey')))
      .map(assessmentTask => assessmentTask.id)
    return {
      tasks: selectedAssessmentsForDB,
      removedTasksId
    }
  },
  // convert Vital Data To DBTask For AdminProgram
  convertVitalToDBTask: (fieldsValue, panesFieldsValue) => {
    const vitalData = convertFieldsValue(fieldsValue)
    const { vitalType, description } = vitalData
    const type = VITAL_TYPE_MAP[vitalType]
    const repeat = getRepeatFromVital(vitalData.schedule);
    const schedule = generateScheduleForDB(vitalData);

    const threshold = generateThresholdForDB(vitalData, fieldsValue)
    const isBaselineWeight = _.get(threshold, '0.measure') === 'WEIGHT_CHANGE_BASELINE'
    const notification = convertAlertToNotificationSettings(panesFieldsValue, type, {isBaselineWeight})
    const task = addTemplateDetails(vitalData.schedule, {
      type,
      repeat,
      schedule,
      threshold,
      description
    });
    if (notification) {
      task.notification = notification
    }
    if (vitalData.id) {
      task.id = vitalData.id
    }
    return task
  },
  // convert Vital Data To DBTask For EnrolledProgram
  convertVitalToDBTaskForEP: (fieldsValue, panesFieldsValue) => {
    const MISSED_TYPE = 'Missed Reading'
    const THRESHOLD_TYPE = 'Threshold'
    const vitalData = convertFieldsValue(fieldsValue)
    const repeat = getRepeatFromVital(vitalData.schedule)

    const { vitalType, description } = vitalData
    const type = VITAL_TYPE_MAP[vitalType]
    const schedule = generateScheduleForDB(vitalData)
    const threshold = generateThresholdForDB(vitalData, fieldsValue)
    const task = addTemplateDetails(vitalData.schedule, {
      id: vitalData.id,
      repeat,
      schedule,
      threshold,
      description,
      type
    });
    const isBaselineWeight = _.get(threshold, '0.measure') === 'WEIGHT_CHANGE_BASELINE'
    const notification = convertAlertToNotificationSettings(panesFieldsValue, type, {MISSED_TYPE, THRESHOLD_TYPE, isBaselineWeight})
    if (notification) {
      task.notification = notification
    }
    if (vitalType === 'Weight') {
      if (_.get(task.threshold, '0.range')) {
        task.threshold[0].range = task.threshold[0].range.slice(0, 4)
      }
      if (isBaselineWeight) {
        task.threshold[0].baseline = vitalData['HS'][4]
      } else {
        task.threshold[0].baseline = null
      }
    }
    return task
  }
}


// editProgram, convert data from database to vitalList
const getMealType = (type) => type === 'SNACK' ? 'SUPPER' : type
const convertBGScheduleFromDB = (schedule) => {
  // target timeSlotValueForMeal: ["BEFORE_LUNCH", "AFTER_LUNCH", "BEFORE_DINNER"]
  const result = []
  schedule.forEach(scheduleTime => {
    const prefixString = scheduleTime.beforeMeal === 'BEFORE_MEAL' ? 'BEFORE_' : 'AFTER_'
    result.push(`${prefixString}${getMealType(scheduleTime.mealType)}`)
  })
  return result
}

const convertScheduleTimeSlotValue = (schedule) => {
  return schedule.map(scheduleTime => {
    return {
      value: [
        moment(scheduleTime.start,'HHmm'),
        moment(scheduleTime.end,'HHmm')
      ]
    }
  })
}

const getFrequencyType = (task) => {
  return task.repeat === 'SCHEDULED' ? 'slot' : 'frequency'
}
const convertDBToSchedule = (task) => {
  const { schedule, repeat } = task
  if (!schedule) return
  const frequency = getFrequencyType(task)
  const resultSchedule = {
    frequency
  }
  if (frequency === 'frequency') {
    resultSchedule.frequencyValue = {
      every : 1,
      times : schedule[0].target,
      unit : repeat
    }
  } else {
    if (task.type === 'BG') {
      resultSchedule.timeSlotValueForMeal = convertBGScheduleFromDB(schedule)
      // resultSchedule.timeSlotValueForMeal = R.uniq(R.unnest(R.pluck('calendar', schedule)));
      resultSchedule.weeklyMealSchedule = task.templateDetails;
    } else {
      resultSchedule.timeSlotValue = convertScheduleTimeSlotValue(schedule)
    }
  }
  return resultSchedule
}
export const convertDBToThreshold = (thresholds, userRole) => {
  const result = VITAL_THRESHOLD_MAP_DEFAULT_VALUE
  if (thresholds.length === 0) return result
  thresholds.forEach(threshold => {
    const type = VITAL_THRESHOLD_MAP[threshold.measure]
    if (type !== 'HS') {
      result[type] = threshold.range
    } else {
      const isBaselineWeight = _.get(threshold, 'measure') === 'WEIGHT_CHANGE_BASELINE'
      const currentPatient = RequestCache.get('nurse/currentPatient')
      let baselineValue = _.get(currentPatient, 'profile.weight.value')
      if (!isBaselineWeight) {
        result.weightBasedOn = 'Last Measurement'
        result[type] = threshold.range
      } else {
        // need 5 elements in array
        result.weightBasedOn = 'Baseline'
        baselineValue = _.get(threshold, 'baseline') || baselineValue
      }
      result[type] = threshold.range.concat([baselineValue])
    }
  })
  return result
}

// edit notification/alert, render form
const generateNotificationSettingFromDB = (notification) => {
  const { type, trigger: triggerFromDB, chance, tmpl } = notification
  const personArray = tmpl && Array.isArray(tmpl) ? tmpl.map(t => t.person) : []
  personArray.push('DASHBOARD')
  const trigger = []
  if (hasElement(triggerFromDB, 'LOW')) {
    trigger[0] = ('LOW-HIGH')
  }
  if (hasElement(triggerFromDB, 'CRITICAL_LOW')) {
    const position = trigger[0] ? 1 : 0
    trigger[position] = ('CRITICAL_LOW-CRITICAL_HIGH')
  }
  const alertFormData = {
    type,
    person: personArray,
  }
  if (chance) {
    alertFormData.alertType = MISSED_TYPE_VALUE
    alertFormData.chance = chance
  } else if (trigger && trigger.length > 0) {
    alertFormData.alertType = THRESHOLD_TYPE_VALUE
    alertFormData.trigger = trigger
  }
  return alertFormData
}

export const getAlertFormData = (task) => {
  const { notification } = task
  if (!notification || !Array.isArray(notification) || notification.length === 0) return
  const result = notification.map(noti => generateNotificationSettingFromDB(noti))
  return result
}
export const convertDBToVitalList = (program, userRole='ADMIN') => {
  const tasks = _.get(program, 'tasks')
  const result = []
  if (!tasks) { return result }
  const taskTypeMap = VITAL_TYPE_MAP_FROM_SHORT
  tasks.forEach(task => {
    const isVital = _.findIndex(VITAL_TYPES_SHORT, value => value === task.type) > -1
    if (isVital) {
      const alertFormData = getAlertFormData(task)
      const vitalItem = {
        vitalType: taskTypeMap[task.type],
        schedule: convertDBToSchedule(task),
        ...convertDBToThreshold(task.threshold, userRole),
        id: task.id,
        description: task.description,
        isBaselineExist: !!_.get(task, 'threshold.0.baseline')
      }
      if (alertFormData) {
        vitalItem.alertFormData = alertFormData
      }
      result.push(vitalItem)
    }
  })
  return result
}

export const addVitalDataToList = (state, fieldsValue) => {
  const isVitalTypeExisted = _.findIndex(state.data, data => {
    return data.vitalType === _.get(fieldsValue, 'vitalType.value')
  })
  // update current vital item
  if (isVitalTypeExisted > -1) {
    return {
      ...state,
      data: state.data.map((item, index) => {
        if (index === isVitalTypeExisted) {
          return convertFieldsValue(fieldsValue)
        } else {
          return item
        }
      })
    }
  }
  // add new vital item
  return {
    ...state,
    data: [
      ...state.data,
      convertFieldsValue(fieldsValue)
    ]
  }
}

const isPast = (time) => {
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();
  // const hour = (time.substr(0,2) === '00') ? 24 : parseInt(time.substr(0,2))
  const hour = parseInt(time.substr(0, 2));
  return currentHour > hour ||
    (currentHour === hour) && currentMinute >= parseInt(time.substr(2, 4));
};

export const getStatus = (row) => {
  const { schedule, measure, type } = row
  const { start, end } = schedule || {}
  if (measure) {
    return 'Completed';
  }
  else if (type === 'BG') {
    return 'Available'
  }
  else if (!start || !end) {
    return 'Error';
  }
  else if (isPast(start)) {
    if (isPast(end)) {
      return <span className="miss-black">Missed</span>;
    } else {
      return 'Available';
    }
  }
  else {
    return 'Upcoming';
  }
};

const formatStartEndString = timeString => timeString ? `${timeString.substr(0, 2)}:${timeString.substr(2, 4)}` : ''

const mealMap = {
  'BREAKFAST': 'Breakfast',
  'LUNCH': 'Lunch',
  'DINNER': 'Dinner',
  'SNACK': 'Supper',
  'BEDTIME': 'Bedtime',
  'OVERNIGHT': 'Overnight',
  'OTHER': 'Other',
}

export const formatSchedule = (row) => {
  const beforeMeal = get(row, 'schedule.beforeMeal')

  // there will be value in absoluteStart for one-time task. normal tasks will be null or undefined
  const absoluteStart = get(row, 'schedule.absoluteStart')
  if(absoluteStart) {
    return 'One-time Task'
  }

  if(row.type === 'BG' && beforeMeal !== 'ANY_TIME') {
    const mealType = get(row, 'schedule.mealType');
    let str = ''; //mealType
    if(mealType && !_.includes(['OVERNIGHT','OTHER','BEDTIME'],mealType)){ //need "before/after"
      str = beforeMeal === 'BEFORE_MEAL' ? 'Before ' : beforeMeal === 'AFTER_MEAL' ? 'After ' : ''
    }

    str += mealType ? mealMap[get(row, 'schedule.mealType')] : ''
    return str
  }
  else if(row.type === 'ASSESSMENT' || row.type === 'SURVEY') {
    return formatSingleAssSurSchedule(row)
  }
  else {
    //if it's BG and by frequency, or other vital that is for the whole day
    if (beforeMeal === 'ANY_TIME' || (row.schedule.start === '0000' && row.schedule.end === '2359')) {
      const { target } = row.schedule
      const taskTarget = !target? '--' : (target === 1? 'Once' : target === 2? 'Twice' : `${target} Times`)
      const { repeat } = row
      let taskRepeat = ''
      if (repeat === 'DAILY') {
        taskRepeat = ' a Day'
      } else if (repeat === 'WEEKLY') {
        taskRepeat = ' a Week'
      } else if (repeat === 'MONTHLY'){
        taskRepeat = ' a Month'
      }

      const taskTargetWithRepeat = taskTarget + taskRepeat
      return <span>{taskTargetWithRepeat}</span>
    }
    return `${formatStartEndString(row.schedule.start)} - ${formatStartEndString(row.schedule.end)}`
  }
}

export const formatTime = item => item ? moment(item).format('hh:mm a') : '';

export const formatReading = (measure) => {
  let reading = '';
  if (measure.systolic_blood_pressure) {
    reading = `${measure.systolic_blood_pressure.value}/${measure.diastolic_blood_pressure.value} ${measure.systolic_blood_pressure.unit}
                , ${measure.heart_rate} ${measure.heart_rate.unit}`;
  } else if (measure.body_weight) {
    reading = `${measure.body_weight.value} ${measure.body_weight.unit}`;
  } else if (measure.blood_glucose) {
    reading = `${measure.blood_glucose.value} ${measure.blood_glucose.unit}`;
  }
  // To Fix: use real alert level to determine the background color
  const alert_level = Math.random() < 0.33 ? 'reading-normal' : Math.random() > 0.66 ? 'reading-critical' : 'reading-outOfRange';

  return (
    <div className="reading">
      <span className={alert_level}>{reading}</span>
      {measure.manual && <em>M</em>}
    </div>);
};

export const formatReadingFromRow = (row,isInChat) => {
  let reading = '';
  let heartRateString = '';
  let breakLine = '';
  let BMI = '';
  if (row && row.type === 'BP' && row.systolic_blood_pressure) {
    const heartRateUnit = 'bpm';
    const heartRateValue = _.get(row,'heart_rate.value',0);
    heartRateString = `${heartRateValue} ${heartRateUnit}`;
    reading = `${row.systolic_blood_pressure.value}/${row.diastolic_blood_pressure.value} ${row.systolic_blood_pressure.unit}`;
    breakLine = isInChat ? <br/> : breakLine;

  } else if (row.type === 'HS' && row.body_weight) {
    // const isBaselineWeight = _.get(row.threshold, '0.measure') === 'WEIGHT_CHANGE_BASELINE'
    const weightInKg = _.get(row, 'body_weight.value', NaN);
    const bodyWeightValue = convertor.HS(weightInKg,'kg','lbs').toFixed(1);
    const bodyWeightUnit = I18N.get('measure.units_weight_lbs');
        // _.get(row, 'body_weight.unit');
    const heightInM = _.get(row,'user.profile.height.value',0)/100;
    BMI = heightInM&&weightInKg ? (weightInKg/heightInM/heightInM).toFixed(1) : 0;
      // const bodyWeightChangeBaselineValue = _.get(row, 'body_weight_change_baseline.value', NaN).toFixed(1);
    // const bodyWeightChangeBaselineUnit = _.get(row, 'body_weight_change_baseline.unit');
    // const bodyWeightChangeValue = _.get(row, 'body_weight_change.value', NaN).toFixed(1);
    // const bodyWeightChangeUnit = _.get(row, 'body_weight_change.unit');
    reading = `${bodyWeightValue}  ${bodyWeightUnit}`;
    // reading = `${bodyWeightValue}\u00A0${bodyWeightUnit}\u00A0` +
    //   (isBaselineWeight ? `(${bodyWeightChangeBaselineValue}\u00A0${bodyWeightChangeBaselineUnit})`
    //                     : `(${bodyWeightChangeValue}\u00A0${bodyWeightChangeUnit})`)
  } else if (row.type === 'BG' && row.blood_glucose) {
    if (row.blood_glucose.unit === I18N.get('measure.units_BG_UK_standard')) {
      const bgValue = convertBG_mmolTomgdl(row.blood_glucose.value); // convert to US format, mg/dl
      const bgUnit = row.blood_glucose.unit === I18N.get('measure.units_BG_US_standard') ? row.blood_glucose.unit : I18N.get('measure.units_BG_US_standard')
      reading = `${bgValue} ${bgUnit}`;
    } else {
      reading = `${row.blood_glucose.value} ${row.blood_glucose.unit}`;
    }
  }
  else if (row.type === 'HR' && row.heart_rate) {
    reading = `${row.heart_rate.value} ${row.heart_rate.unit}`;
  }
  else if (row.type === 'TM' && row.body_temperature) {
    reading = `${row.body_temperature.value} ${row.body_temperature.unit}`;
  }
  else if (row.type === 'PO' && row.blood_oxygen) {
    reading = `${row.blood_oxygen.value} ${row.blood_oxygen.unit}`;
  }
  // To Fix: use real alert level to determine the background color
  const alert_level = row.severity === 'NORMAL' ? 'reading-normal' : row.severity === 'CRITICAL' ? 'reading-critical' : 'reading-outOfRange';
  //To Fix: wait for the manual to be supported from backend
  const showManual = row.manualInput;
  const bmiIndicator = BMI!=0 ? BMIRes(BMI): { text : 'UNDEFINED' };
  const bmiColor = row.type=='HS'&&bmiIndicator ? HSColor[bmiIndicator.text] : '';

  return (
    <div className="reading">
        <span className={alert_level} style={{backgroundColor:bmiColor}}>{reading}</span>{showManual ? <em style={{paddingRight:2,paddingLeft:2}}>(M)</em> : ''}
        { breakLine }
      <span style={{color:'#575757'}}>
        { heartRateString }
        { row.arrhythmia ? <img style={{ marginLeft:10,height:10 }} src="/image/arrythmia-hr-icon.svg"/>:'' }
      </span>
    </div>);
};

export const renderViewAssessmentButton = ({ status, type, assessment, assessmentFilled, refId, id, user, ref }) => {
  const isView = (status !== 'UPCOMING' && status !== 'INCOMPLETE');
  const actionData = { color: '#258ce6', display: 'View >' }

  const hasTaken = !!assessmentFilled;
  const isSurvey = type === 'SURVEY';

  const TUPLE = [
    {
      condition: isSurvey && isView && hasTaken,
      path: `/preview_survey_filled/${_.get(assessmentFilled, '_id')}?backPath=myTasks`,
    },
    {
      condition: !isSurvey && isView && hasTaken,
      path: `/preview_assessment_filled/${_.get(assessmentFilled, '_id')}?backPath=myTasks${ ref ==='nurse' ? '?patientId='+_.get(user, 'id') : ''}`,
    },
  ];

  const path = _.get(_.find(TUPLE, 'condition'), 'path');

  return (
    <IHButton
      label={actionData.display}
      bsStyle="primary"
      className="ih-exsmall"
      // style={{ backgroundColor: actionData.color }}
      type="button"
      onClick={e => browserHistory.push(path)}
    />
  );
}

export const hasMeasurement = (row) => {
  if (row.type === 'ASSESSMENT' || row.type === 'SURVEY') {
    return false
  }
  return !!row.systolic_blood_pressure || !!row.body_weight || !!row.blood_glucose || !!row.heart_rate || !!row.body_temperature || !!row.blood_oxygen;
}

export const hasMissedAlert = (row) => {
  return row.taskStatus === 'MISSED'
}

export const statusSortOrder = ['COMPLETED', 'UPCOMING', 'MISSED']

export const formatDate = (date) => {
  return date ? moment(date).format('MMM-DD-YYYY hh:mm a') : ''
}

export const formatDateMM24Date = (dataObj) =>{
  return dataObj ? moment(dataObj).format('MM/DD/YYYY HH:mm') :'';
}

export const formatDateMM = (dataObj) =>{
    return dataObj ? moment(dataObj).format('MM/DD/YYYY') :'';
}

export const renderRemoveAlertBtn = (REMOVE_TAB, RESET_FORM, dispatch) => {
  const style = {
    alignSelf: 'flex-end',
    position: 'relative',
    top: -5,
    textAlign: 'right'
  }
  const alertBtnProp = {
    size: 'small',
    onClick : ()=>{
      dispatch({ type: REMOVE_TAB })
      dispatch({ type: RESET_FORM })
    },
    className: "btn-ghost ghost-danger",
  }
  const alertElem = (
    <div style={style}>
      <IHButton {...alertBtnProp} label={'Delete'} />
    </div>
  )
  return alertElem
}

export const getInitAlertFormData = (rowData, AlertFormContainer, REMOVE_TAB, RESET_FORM, dispatch) => {
  const removeBtn = REMOVE_TAB && RESET_FORM ? renderRemoveAlertBtn(REMOVE_TAB, RESET_FORM, dispatch) : ''
  const activeKey = 'key_1'
  const formRefPrefix = `baseForm_`
  const alertFormData = _.get(rowData, 'alertFormData')
  if (!alertFormData || (Array.isArray(alertFormData) && alertFormData.length === 0)) return
  const panesFieldsValue = {}
  const panes = alertFormData.map((formData, index) => {
    const key = `key_${index+1}`
    panesFieldsValue[key] = convertToFieldsValue(formData)
    const content = (
      <div key={index}>
        <AlertFormContainer
          key={key}
          // ref={refNode => { this && this[formRef] = refNode }}
          formRef={`${formRefPrefix}${index+1}`}
        />
        {removeBtn}
      </div>
    )
    return {
      content,
      key
    }
  })

  let initFormData
  if (alertFormData) {
    initFormData = {
      activeKey,
      panesFieldsValue,
      isShowAlertForm: true,
      panes
    }
  }
  return initFormData
}

export const formatScheduleValue = (item, type) => {
  switch (type) {
    case 'BP':
    case 'HR':
    case 'HS': {
      // check if it is by frequency or time slot
      if (item.schedule[0].start === '0000' && item.schedule[0].end === '2359') {
        const { target } = item.schedule[0]
        const freq = !target? '--' : ((target === 1? 'Once' : target === 2? 'Twice' : `${target} Times`))

        let taskRepeat = ''
        if (item.repeat === 'DAILY') {
          taskRepeat = ' a Day'
        } else if (item.repeat === 'WEEKLY') {
          taskRepeat = ' a Week'
        } else if (item.repeat === 'MONTHLY'){
          taskRepeat = ' a Month'
        }

        return freq + ' ' + taskRepeat
      } else {
        const timeslots = item.schedule.map( (timeslot) => {
          const start = timeslot.start.slice(0, 2)+':'+timeslot.end.slice(2)
          const end = timeslot.end.slice(0, 2)+':'+ timeslot.end.slice(2)
          return `${start} - ${end}`
        })
        return timeslots.join(', ')
      }
    }
    case 'BG': {
      // check if it is by frequency or time slot
      if (item.schedule[0].beforeMeal === 'ANY_TIME' || (item.schedule[0].start === '0000' && item.schedule[0].end === '2359')) {
        const { target } = item.schedule[0]
        const freq = !target? '--' : ((target === 1? 'Once' : target === 2? 'Twice' : `${target} Times`))

        let taskRepeat = ''
        if (item.repeat === 'DAILY') {
          taskRepeat = ' a Day'
        } else if (item.repeat === 'WEEKLY') {
          taskRepeat = ' a Week'
        } else if (item.repeat === 'MONTHLY'){
          taskRepeat = ' a Month'
        }

        return freq + ' ' + taskRepeat
      } else {
        const timeslots = item.schedule.map( (timeslot) => {
          const beforeMeal = timeslot.beforeMeal
          const mealType = timeslot.mealType
          const freq = beforeMeal === 'BEFORE_MEAL' ? (`Before ${mealMap[mealType]}`) : (`After ${mealMap[mealType]}`)
          return freq
        })
        return timeslots.join(', ')
      }
    }
    default:
      return null
  }
}

export const renderVitalType = (item, row) => {
  const classNames = [];
  if (item === 'Blood Glucose' || item === 'BG') {
    classNames.push('icon-glucose');
  } else if (item === 'Blood Pressure' || item === 'BP') {
    classNames.push('icon-heart');
  } else if (item === 'Blood Rate' || item === 'HR') {
    classNames.push('icon-heart-pulse');
  } else if (item === 'Temperature' || item === 'TM') {
    classNames.push('icon-thermometer');
  } else if (item === 'Blood Oxygen' || item === 'PO') {
    classNames.push('icon-oxygen');
  } else if (item === 'Weight' || item === 'HS') {
    classNames.push('icon-weight');
  } else {
    classNames.push('icon-assessment');
  }

  if (!hasMeasurement(row)) {
    classNames.push('available');
  }

  return <div className={classNames.join(' ')}>{TASK_TYPE_MAP[item]}</div>;
};

export const formatWeightChange = (obj)=>{
  const weightValue = obj;
  const weightUnit = I18N.get('measure.units_weight_lbs');
  let url = weightValue > 0 ?  '/image/weight-gain-arrow.svg' : weightValue < 0 ? '/image/weight-loss-arrow.svg': '/image/weight-same-arrow.svg';
  const arrow  =  <div style={{display:'flex',marginRight:5}}><img src={url}/></div>;
  const content = <div style={{display:'flex'}}>{ arrow }{ Math.abs(weightValue) }&nbsp;&nbsp;{ weightUnit }</div>;
  return content;
}
