import { connect } from 'react-redux'
import _ from 'lodash';
import get from 'lodash/get'
import actions from '../actions';
import { moment} from "../../../../../package/IHComponent";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup,ListGroupItem,Panel } from 'react-bootstrap';
import { API } from '../actions/api';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';
import I18N from 'modulesAll/I18N'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
const timeFormatString = I18N.get('dates.LTS'); // 'h:mm A'
const dateFormat = I18N.get('dates.L'); // 'MM/DD/YYYY'

class ChannelListComponent extends Component {
    static displayName = 'chat/components/ChannelListComponent';

    constructor(props) {
        super(props);
        this.sortChannels = this.sortChannels.bind(this);
        this.renderChannels = this.renderChannels.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.getAvatar = this.getAvatar.bind(this);
        this.renderLastMsgTime = this.renderLastMsgTime.bind(this);
        this.renderLastMsg = this.renderLastMsg.bind(this);
        this.onAvatarError = this.onAvatarError.bind(this);
    }

    onAvatarError(id) {
      const encodedId = btoa(`accounts:${id}`);
      API.getUserAvatar(encodedId)
        .then( res => {
          const userProfile = get(res.data.user, 'profile') || {};
          const currentUserId = atob(get(res.data.user, 'id')).split(':')[1];
          const userRole = res.data.user.allRoles.length > 0 ? res.data.user.allRoles[0].name : '';

          const newUser = {
            uuid: currentUserId,
            firstName: userProfile.firstName ||'unknown',
            lastName: userProfile.lastName || 'unknown',
            profileType: userRole,
            avatar: _.isNil(userProfile.avatar) ? '/image/default_avator.png' : userProfile.avatar.link ? userProfile.avatar.link : '/image/default_avator.png'
          };

          this.props.addToUserMap(newUser);
        })
        .catch(err => console.log('onAvatarError, err: ', err));
    }

    getAvatar(patientId,counter) {
      if (this.props.userMap[patientId] && this.props.userMap[patientId].avatar) {
        return <img src={ this.props.userMap[patientId].avatar } className='avatar' onError={ () => this.onAvatarError(patientId) } />
      } else {
        return <img src='/image/default_avator.png' className='avatar' onError={ () => this.onAvatarError(patientId) } />
      }
    }

    renderUnreadCounter(counter, patientId){
        if (counter>0){
            if(counter>9){
                counter ='9+';
            }
            return (
                <div className='col-lg-2 channelAvatarContainer colUpdate v-badge' data-content={counter}>
                  { this.getAvatar(patientId,counter) }
                </div>
            )
        }
        else {
            return (
                <div className='col-lg-2 channelAvatarContainer channelAvatarContainerWithoutCounter colUpdate'>
                  { this.getAvatar(patientId) }
                </div>
            )
        }

    }
    sendMessage(selectedChannel, message) {
      API.getPubNub().publish({
        channel: selectedChannel,
        message: message,
      }, (status, resp) => {
        if (status.error) {
          console.log('Error publishing message: ', status);
        }
      });
    }
    sendAckHandler(leaveChannel){

      // if there is a previous channel you are leaving
      if (leaveChannel) {

        const teamId = `team-${leaveChannel.split('-')[0]}`;

        const prevChannel = this.props.channels[leaveChannel];
        const prevChLastMsgIndex = prevChannel.history.length - 1;
        const lastMsg = prevChannel.history[prevChLastMsgIndex]; // last msg of previous channel

        const ackMessage = {
          publisher:teamId,
          type:"ACK"
        };
        // console.log('leaveChannel: ', leaveChannel, ' lastMsg: ', lastMsg);
        if(lastMsg&&((lastMsg.entry.type!='ACK')||(lastMsg.entry.publisher.split('-')[0]!='team'))) {
          this.sendMessage(leaveChannel,ackMessage);
        }
      }
    }
    handleClickEvent(curChannel){
       const { fetchHistory, selectedChannel, channels,moveToBottom } = this.props;
        // if current channel is same as selected channel, do not send ACK messages
       if(curChannel===selectedChannel){
           return;
       }

       // for user's initial channel selection, previous channel not same as current channel, send ACK
       if (selectedChannel.length === 0 && curChannel !== selectedChannel) {
         // console.log('initial channel select');
         this.sendAckHandler(curChannel);
       } else {
         this.sendAckHandler(selectedChannel);
       }

       this.props.setSelectedChannel(curChannel);
       this.props.updateUnreadCounter(curChannel);
        //this code will move move to bottom
       // if(_.get(channels,`${curChannel}.history.length`)===0){
        Mixpanel.track('clicked','chanel','messages',{ CHANELID : curChannel})

        fetchHistory(curChannel,channels[curChannel].patientId, _.get(channels,`${curChannel}.lastMessageTimestamp`),false,true);
       // }
       //will not move to bottom
    }
    /*
    getSenderOfLastMsg(history) {
        const lengthOfHistory = history.length;
        let senderOfLastMsg = '';

        if (history && history.length > 0) {
          // newest message is at end of array
          for (let i = lengthOfHistory-1; i >= 0; i--) {
            let lastMsgType = history[i].entry.type;
            if (lastMsgType === 'text' || lastMsgType === 'foodReview' || lastMsgType === 'foodUpload') {
              senderOfLastMsg = history[i].entry.displayName;
              break;
            }
          }
        }
        return senderOfLastMsg;
    }*/

    renderLastMsgTime(lastMsgTime) {
      const dateOfLastMsgTime = moment(lastMsgTime/1e4).format(dateFormat);

      if (DATE_HELPERS.isToday(moment(dateOfLastMsgTime, dateFormat))) {
        return moment(lastMsgTime/1e4).format(timeFormatString);
      }

      else { // default


          if (lastMsgTime > 0) {
            return dateOfLastMsgTime;
          } else {
            return '';
          }
      }
    }


    renderLastMsg(lastMsgText) {
      if (lastMsgText.trim().length > 25) {
        return lastMsgText.substring(0, 25) + '...';
      } else {
        return lastMsgText;
      }
    }
    /*
    getLastMsg(history,val) {
        let lastMessage = {};

        for (let i = history.length-1; i>=0; i--) {
            if(history[i]&&history[i].entry) {
                // if (history[i].entry.type === 'text' || history[i].entry.type === 'foodUpload' || history[i].entry.type === 'foodReview') {
                //     lastMessage = history[i];
                //     break;
                // }
                if(history[i].entry.type!='ACK'){
                    lastMessage = history[i];
                    break;
                }
            }
        }

        return lastMessage;
    }
    */
    renderChannelList(val,key){
        const { props } = this;
        const channels = props.channels;
        const userMap = props.userMap;
        const curChannel = channels[val];
        const curHistory = curChannel.history;
        const patientId = curChannel.patientId;
        const curUserInfo = userMap&&userMap[patientId];
        const { lastMsgSender,lastMsgText,lastMsgTime } = curChannel;
        const lastMsgTimeFormatted = this.renderLastMsgTime(lastMsgTime);
        // const lastMsgSender = _.get(lastMessage,'entry.displayName','');
        const lastMessageText = this.renderLastMsg(lastMsgText||'');
        const unreadCount = curChannel.counter;
        return (
            <ListGroupItem key={key} className={ props.selectedChannel === val ? 'channelItemContainer selected-channelItemContainer' : 'channelItemContainer' }
                           onClick={()=>this.handleClickEvent(val)}
                           channel = { val }
            >
                <div className='row channelItem rowUpdate channel-list-container'>
                    {this.renderUnreadCounter(unreadCount, patientId)}
                    <div className='col-lg-10 colUpdate channelTextContainer'>
                        <div className='row rowUpdate channelTextTop'>
                            <div className='col-lg-8 colUpdate name'>
                                {curUserInfo ? (curUserInfo.firstName  + ' ' + curUserInfo.lastName) :''}
                            </div>
                            <div className='col-lg-4 colUpdate time'>
                                { lastMsgTimeFormatted }
                            </div>
                        </div>
                        <div className='row rowUpdate'>
                            <div className='col-lg-11 colUpdate channelTextBottom message'>
                              <span>
                                {`${lastMsgSender||''}:${lastMessageText} `}
                              </span>
                            </div>
                            <div className='col-lg-1 colUpdate'>

                            </div>
                        </div>
                    </div>
                </div>
            </ListGroupItem>
        );
    }

    sortChannels(filteredChs) {
      const { props } = this;

      // put object key inside ea object
      const reformatted = filteredChs.map( val => {
        let newObj = _.clone(props.channels[val]);
        newObj.id = val;
        return newObj;
      });
      const sortedCh = _.orderBy(reformatted, ['counter','lastMsgTime'], ['desc','desc']);
      return sortedCh;
    }

    filterChannels() {
      const channelsArr = Object.keys(this.props.channels);

      // render only channels that belong to user's team (of selected organization)
      const curUser = JSON.parse(sessionStorage.getItem('currentUser'));
      const teamIds = curUser.team.map(val => atob(val.id).split(':')[1]);

      const teamIdMap = {};
      teamIds.forEach(val => teamIdMap[val] = true);

      return channelsArr.filter(val => teamIdMap[val.split('-')[0]]);
    }
    renderChannels() {
      const { props } = this;
      const channelsArr = Object.keys(props.channels);
      if (props.channels && channelsArr.length > 0) {
          const filtered = this.filterChannels();
          const sortedCh = this.sortChannels(filtered);

          return sortedCh.map((val, key) => {
             return this.renderChannelList(val.id, key)
          })
      }
      else
          {
              return null; // nothing in props.channels!
          }

    }
    render() {
      const { props } = this;

      return (
        <div ref='channelList' className='people-list col-lg-6 colUpdate' id='people-list'>
            <ListGroup className='channelListGroup'>
              { props.channels ? this.renderChannels() : 'Loading' }
            </ListGroup>
        </div>
        );
    }
}

const mapState = ({ chat }, ownProps) => {
  return {
    selectedChannel: chat.main.selectedChannel,
    channels: chat.main.channels,
    userMap: chat.main.userMap,
  }
};

const mapDispatch = (dispatch) => {
    return {
        updateUnreadCounter : (channel)=>dispatch(actions.updateUnreadCounter(channel)),
        initUnreadCounter:(channel,unreadMsgCount)=>dispatch(actions.initUnreadCounter(channel,unreadMsgCount)),
    }
}
ChannelListComponent.propTypes = {
  userMap: PropTypes.object,
  channels: PropTypes.object,
  selectedChannel: PropTypes.string
};

export default connect(mapState,mapDispatch)(ChannelListComponent);
