import {
  React, IHLoading, IHButton, message, _
} from 'ihcomponent';
import { Modal, Button } from 'antd';

import { graphql } from 'react-apollo';
import Client from 'libModule/gqlClient';
import I18N from 'modulesAll/I18N';
import { lightEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList';
import {modalAction} from 'modulesAll/common/actions'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'

import { createContainer, GQLHelper, getRouterParam, RequestCache } from 'libModule/utils';
import { scrollToBottom } from 'libModule/helpers/scrollTo';

import DischargeConfirmContainer from '../../enrolledProgramInfo/containers/DischargeConfirmContainer';
import SuspendConfirmContainer from 'patientModule/main/containers/SuspendConfirmContainer';
import { openModal, closeModal, openErrorModal } from 'layoutModule/actions/MainModal';
import ReportModal from 'modulesAll/report/containers/ReportModalContentContainer'
import {setResuming, setExtendStep, resetExtendValueType} from 'patientModule/enrolledProgramInfo/actions/EnrolledProgramInfoActions';
import { createAuditLog } from 'libModule/utils/auditLog'
import { setTab } from 'patientModule/enrolledProgramInfo/actions/ProgramInfoTab';
import moment from 'moment';
import getDeviceHub from '../../AdditionalInfo/query/getDeviceHub';
import getIoTDevice from '../../DevicesAssign/query/getIoTDevice'
import ReactToPrint from 'react-to-print';
import CarePlanPrint from 'modulesAll/patient/Print/component/carePlanPrint';

export const RenderTopOne  = class extends React.Component {

  static displayName = 'patient/enrolledProgramInfo/RenderTopOne'

  scrollToBottom() {
    scrollToBottom(600, window);
  }

  programInfoBtnOnClick = () => {
    this.props.updateTabSelected('programDetails');
    this.scrollToBottom();
  }

  suspendBtnOnClick = () => {
    const modal = <SuspendConfirmContainer
      refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
      currentProgram ={this.props.programInfo}
    />;

    this.props.openModal(modal, {
      title: 'Suspend Patient ?',
      className: 'discharge-modal',
    });
  }
  onResumeClick = async () => {
    const currentProgram = this.props.programInfo;
    const variables = {
      id : _.get(currentProgram, 'id')
    }
    console.log("Clicked",variables, this.props);
    const auditLogDetails = {
      programName : _.get(currentProgram, 'name'),
      programDescription: _.get(currentProgram, 'description'),
      healthCondition: _.get(currentProgram, 'healthCondition')
    }
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    try {
      this.props.setResuming(true);
      const res = await this.props.resumeEnrolledProgram({
        variables});
      createAuditLog({
        action: I18N.get('auditLog.provider.patient.resumeProgramme'),
        patientNRIC,
        patientName,
        details: auditLogDetails,
        request: variables,
        response: res   //temporily remove due to res too huge
      })
      message.success(`${I18N.get('keywords.Program')} resume success!`);
      //this.props.refetchEnrolledProgramList();
    } catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.provider.patient.resumeProgramme'),
        patientNRIC,
        patientName,
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false
      })
      console.error(GQLHelper.formatError(err));
      openErrorModal(GQLHelper.formatError(err));
    }

    this.props.setResuming(false);
  }
  dischargeBtnOnClick = async () => {
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const { id: memberId } = currentPatient;

    let hubResult;
    try {
      hubResult = await Client.query({
        query: getDeviceHub,
        variables: { memberId },
        fetchPolicy: 'network-only'
      });
    } catch (err) {
      console.error(GQLHelper.formatError(err));
      openErrorModal(GQLHelper.formatError(err))
    }
    let deviceResult;
    try {
      deviceResult = await Client.query({
        query: getIoTDevice,
        variables: { memberId },
        fetchPolicy: 'network-only'
      });
    } catch (err) {
      console.error(GQLHelper.formatError(err));
      openErrorModal(GQLHelper.formatError(err))
    }

    const deviceHub = _.get(hubResult, 'data.getDeviceHub');
    const iotDevice = _.get(deviceResult, 'data.getIoTDevice');
    if (deviceHub) {
      return Modal.info({
        title: 'Device Hub Assigned',
        content: (
          <div>
            <p>There is a hub assigned to this patient.</p>
            <p>Please unassign the hub before discharging.</p>
          </div>
        ),
        onOk() { },
      });
    } else if (!_.isEmpty(iotDevice)) {
      return Modal.info({
        title: 'Blood Glucose Device Assigned',
        content: (
          <div>
            <p>There is a Blood Pressure device assigned to this patient.</p>
            <p>Please unassign the device before discharging.</p>
          </div>
        ),
        onOk() { },
      });
    }

    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    createAuditLog({
      action: I18N.get('auditLog.provider.patient.dischargeProgramme'),
      patientNRIC,
      patientName
    })

    const dischargeConfirm = (
      <DischargeConfirmContainer
        openMadal={ this.props.openModal}
        closeModal={this.props.closeModal}
        dischargeProgram={this.props.dischargeEnrolledProgram}
        currentPatient={RequestCache.get('nurse/currentPatient')}
        refetchEnrolledProgramList={this.props.lightEnrolledProgramListRefetch}
        currentProgram ={this.props.programInfo}
        location = {this.props.location}
        openErrorModal = { openErrorModal }
      />
    );

    this.props.openModal(dischargeConfirm, {
      title: 'Discharge Patient ?',
      className: 'discharge-modal',
    });
  }

  genNEHRBtnOnClick = () => {
    const p = {
      // title: 'Generate NEHR Report',
      title: 'Generate Report',
      showModal: true,
      showHeaderCloseButton: false,
      body: <ReportModal startDate={ this.props.programInfo.startDate } reason="Condition change"
                         enrolledProgramId={this.props.programInfo.id} enrolledProgramName={this.props.programInfo.name} /> ,
    }
    return this.props.openModal(p.body, p)
  }

  genFullReportBtnOnClick = () => {
    const p = {
      title: 'Generate Full Report',
      showModal: true,
      showHeaderCloseButton: false,
      body: <ReportModal startDate={ this.props.programInfo.startDate }
                         isFullReport
                         enrolledProgramId={ this.props.programInfo.id }
                         enrolledProgramName={ this.props.programInfo.name }/>
    };
    this.props.openModal(p.body, p)
  }


  replaceDeviceBtnOnClick = () => {
    this.scrollToBottom();
    this.props.setTab('device');
  }

  returnDeviceBtnOnClick = () => {
    this.scrollToBottom();
    this.props.setTab('device');
  }

  viewDischargedBtnOnClick = () => {
    // console.log(123);
  }

  render() {

    const programInfoBtn = {
      key: 'programInfoBtn',
      label: `${I18N.get('keywords.Program')} Info`,
      size: 'small',
      onClick: this.programInfoBtnOnClick,
    };
    const suspendBtn = {
      key: 'suspendBtn',
      label: 'Suspend',
      size: 'small',
      onClick: this.suspendBtnOnClick,
    };
    const resumeBtn = {
      key: 'resumeBtn',
      label: this.props.resuming ? 'Resuming...' : 'Resume',
      disabled: this.props.resuming,
      size: 'small',
      onClick: this.onResumeClick
    };
    const dischargeBtn = {
      key: 'dischargeBtn',
      label: 'Discharge from Program',
      size: 'small',
      onClick: this.dischargeBtnOnClick,
    };
    const genNEHRBtn = {
      key: 'genNEHRBtn',
      // label: 'Generate NEHR Report',
      label: 'Generate Report',
      size: 'small',
      onClick: this.genNEHRBtnOnClick
    };
    const genFullReportBtn = {
      key: 'genFullReportBtn',
      label: 'Generate Full Report',
      size: 'small',
      onClick: this.genFullReportBtnOnClick
    };
    const replaceDeviceBtn = {
      key: 'replaceDeviceBtn',
      label: 'Replace Device',
      size: 'small',
      onClick: this.replaceDeviceBtnOnClick
    };
    const returnDeviceBtn = {
      key: 'returnDeviceBtn',
      label: 'Return Device',
      size: 'small',
      onClick: this.returnDeviceBtnOnClick
    };
    const viewDischargedBtn = {
      key: 'viewDischargedBtn',
      label: 'View Discharged Program',
      size: 'small',
      onClick: this.viewDischargedBtnOnClick
    }

    const showPrintCarePlanButton = ()=>{
      let props = this.props.programInfo
      return  <div>
                <ReactToPrint
                  trigger={()=><IHButton style={{ margin: 5, fontSize: 13 }} className='ih-small'>Print Care Plan</IHButton>}
                  content={()=>this.componentRef}
                />
                <CarePlanPrint ref={el => (this.componentRef = el)} {...props}/>
             </div>
    }
    const showHideButtonByProgramStatus = (b) => {
      const buttonAndProgramStatusRelation = {
        'programInfoBtn': ['STARTED','SUSPENDED','DISCHARGED','RETURNED','REPLACING'],
        'suspendBtn': ['STARTED'],
        'resumeBtn': ['SUSPENDED'],
        'dischargeBtn': ['STARTED','SUSPENDED'],
        'genFullReportBtn': ['STARTED','SUSPENDED','DISCHARGED','RETURNED','REPLACING'],
        'genNEHRBtn': ['STARTED','SUSPENDED','DISCHARGED','RETURNED','REPLACING'],
        'replaceDeviceBtn': ['STARTED','SUSPENDED','REPLACING'],
        'returnDeviceBtn': ['DISCHARGED']
      };

      let des = '';
      for(const name in this.props){
        des += name + ':' + this.props[name];
      }

      const rightProgramStatus = _.get(this.props,'programInfo.status');

      //console.log('===>', b.label,rightProgramStatus,buttonAndProgramStatusRelation[b.label].includes(rightProgramStatus))
      if (buttonAndProgramStatusRelation[b.key].includes(rightProgramStatus)) {
        return <IHButton style={{ margin: 5, fontSize: 13 }} {...b} />
      }
      else {
        return null
      }
    }
    // console.log(moment(this.props.programInfo.suspendedAt).format('LL'))

        // <div className="c-left">
        //  { this.props.programInfo && (this.props.programInfo.status === 'STARTED' || this.props.programInfo.status === 'SUSPENDED') && <span className="l-c" onClick={this.clickExtendButton} style={{ marginLeft: '6px' }}>Extend</span> }
        //   <br />
        // </div>

    return (
      <div className="v-topinfo">
       <div className="c-right" style={{display:'flex',flexDirection:'row'}}>
          {/*{ showHideButtonByProgramStatus(programInfoBtn) }*/}
          {/*{ showHideButtonByProgramStatus(suspendBtn) }*/}
          { showPrintCarePlanButton() }
          { showHideButtonByProgramStatus(dischargeBtn) }
          { /*showHideButtonByProgramStatus(genNEHRBtn) */ }

          {/*{ showHideButtonByProgramStatus(genFullReportBtn) }*/}
        </div>
      </div>
    );
  }
}
