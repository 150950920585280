/**
 * Created by zizhangai on 1/4/17.
 */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { graphql, compose, withApollo } from 'react-apollo'
import { connect } from 'react-redux'

import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'libModule/utils';
//components
import { ND, moment, _ } from 'ihcomponent'
//mutation
import handleAlert, { updateQueries as updateQueryHandleAlert } from 'modulesAll/graphql/handleAlert'
import closeAlert, { updateQueries as updateQueryCloseAlert } from 'modulesAll/graphql/closeAlert'
import { resultListMinimal as resultListMinimal } from 'graphqlModule/resultList'
import UserClass from 'graphqlModule/class/User'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import resultList from 'modulesAll/graphql/resultList'
import {API} from "../../../timer/query";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const Button = ND.Button
const ButtonGroup = Button.Group

class StatusDropDown extends Component {
  static displayName = 'StatusDropDownContainer'

  state = {
    status: this.props.row.careStatus
  }

  componentWillReceiveProps(nextProps) {
    this.setState({status: nextProps.row.careStatus})
  }

  render() {
    const { status } = this.state;
    const { isInChat } = this.props;

    return (
        <div className={`alert-btn-gp ${isInChat ? 'isInChat' : ''}`}>
            <ButtonGroup>
                <Button disabled={ status !== 'NEW' } onClick={this._handleAlert.bind(this)}>Review</Button>
                { isInChat ? <span>/</span>: '' }
                <Button disabled={ status === 'CLOSED' } onClick={this._closeAlert.bind(this)}>Close</Button>
            </ButtonGroup>
        </div>
    )
  }

  _handleAlert(e) {
    e.stopPropagation();
    const prevState = this.state.status;
    const { uuid } = this.props;
    const patientNRIC = _.get(this.props,'row.user.identification[0].value',null);
    const patientName = this.props.row.user.profile.fullName;
    const variables = {id : this.props.row.id};
    const auditLogDetails = {
      dateTime: moment(this.props.row.date).format('MMM-DD-YYYY hh:mm a'),
      programName: this.props.row.enrolledProgram.name,
      type: this.props.row.type,
      reading: _.omit(this.props.row.measure, ['id', 'type', 'beforeMeal', 'date', 'imageUrl', 'mealType', 'user_notes'])
    }
    const event = 'HANDLE_ALERT';
    const category = 'ALERT';
    const action = 'UPDATE';
    const userId = _.get(this.props,'row.user.id');
    const timeTrackerEvent = API.startTimeTracker(event,category,action,userId);

    Mixpanel.track('clicked','review','alert', {PATIENT_ID : userId});

    this.setState({status: 'HANDLED'})
    this.props.handle()
      .then(res => {
        createAuditLog({
          action: I18N.get('auditLog.provider.alerts.alert_review'),
          details: auditLogDetails,
          request: variables,
          response: res,
          patientNRIC,
          patientName
        })
      const path = window.location.pathname;
      const canRestUUid = path.includes('/alerts');

      API.createTimeTracker(event,timeTrackerEvent,canRestUUid,uuid);

      }, err => {
        this.setState({status: prevState})
        createAuditLog({
          action: I18N.get('auditLog.provider.alerts.alert_review'),
          details: auditLogDetails,
          request: variables,
          response: err,
          success: false,
          patientNRIC,
          patientName
        })
        this.props.openErrorModal(GQLHelper.formatError(err))
      })
  }

  _closeAlert(e) {
    e.stopPropagation();
    const patientNRIC = _.get(this.props,'row.user.identification[0].value',null);
    const patientName = this.props.row.user.profile.fullName
    const variables = {id : this.props.row.id}
    const prevState = this.state.status
    const { uuid } = this.props;
    const auditLogDetails = {
      dateTime: moment(this.props.row.date).format('MMM-DD-YYYY hh:mm a'),
      programName: this.props.row.enrolledProgram.name,
      type: this.props.row.type,
      reading: _.omit(this.props.row.measure, ['id', 'type', 'beforeMeal', 'date', 'imageUrl', 'mealType', 'user_notes'])
    }
    const event = 'HANDLE_ALERT';
    const category = 'ALERT';
    const action = 'UPDATE';
    const userId = _.get(this.props,'row.user.id');
    const timeTrackerEvent = API.startTimeTracker(event,category,action,userId);

    Mixpanel.track('clicked','close','alert', {PATIENT_ID : userId});

    this.setState({status: 'CLOSED'})
    this.props.close()
      .then(res => {
        createAuditLog({
          action: I18N.get('auditLog.provider.alerts.alert_close'),
          details: auditLogDetails,
          request: variables,
          response: res,
          patientNRIC,
          patientName
        });
      const path = window.location.pathname;
      const canRestUUid = path.includes('/alerts');
      API.createTimeTracker(event,timeTrackerEvent,canRestUUid,uuid);
      }, err => {
        this.setState({status: prevState})
        createAuditLog({
          action:I18N.get('auditLog.provider.alerts.alert_close'),
          details: auditLogDetails,
          request: variables,
          response: err,
          success: false,
          patientNRIC,
          patientName
        })
        this.props.openErrorModal(GQLHelper.formatError(err))
      })
  }
}

StatusDropDown.propTypes = {
  status: PropTypes.string,
  row: PropTypes.object
}
const minalVariables =  {
    count: 1,
    filters: {
        get enrolledProgramId() {
            return UserClass.getEnrolledProgramActiveIdList()
        },
        severity: ['CRITICAL'],
        careStatus: "NEW",
        alertsOnly: true,
        fromDate : moment().subtract(7,'days').startOf('day').valueOf()
    },
}
const getVariables = (ownProps)=>{
    const variables = ownProps.variables;
    const fromDate = ownProps.fromDate || (moment(new Date()).subtract(7,'days').endOf('day')).toDate();
    _.set(variables,'filters.fromDate',fromDate);
    return variables;
}

const mapState = (state) => {
    const uuid = _.get(state,'timer.main.uuid',null);
    return {
        ...state.globalTables.provider_alert_list,
        uuid
    }
};
const closeRetchQueries = (ownProps)=>{
    const { enrolledProgramId } = ownProps;
    const refetchQueries =  [{
                                query:resultList,
                                variables:getVariables(ownProps),
                                fetchPolicy:'network-first'
                             },
                            {
                                query:resultListMinimal,
                                variables:minalVariables,
                                fetchPolicy:'network-first'
                            }];
    if(enrolledProgramId){
        const variables = {
            count: 9999,
            filters: {
                severity: ['RISK', 'CRITICAL'],
                careStatus: ["NEW", "HANDLED"],
                alertsOnly: true,
                enrolledProgramId
            }
        };
        refetchQueries.push({
            query:resultListMinimal,
            variables,
            fetchPolicy:'network-only'
        })
    }

    return refetchQueries;
}
export default compose(
  connect(mapState, { openErrorModal }),
  withApollo,
  graphql(handleAlert, { name: 'handle', options: (ownProps) => (
                                          {
                                              variables: { "id": ownProps.row.id },
                                              refetchQueries:[{
                                                  query:resultList,
                                                  variables:getVariables(ownProps),
                                                  fetchPolicy:'network-first'
                                              },
                                              {
                                                  query:resultListMinimal,
                                                  variables:minalVariables,
                                                  fetchPolicy:'network-first'
                                              }
                                              ]
                                          })}),
  graphql(closeAlert, { name: 'close', options: (ownProps) => (
                                        {
                                            variables: { "id": ownProps.row.id },
                                            refetchQueries:closeRetchQueries(ownProps)
                                        })}
  )
)(StatusDropDown)