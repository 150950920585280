const map = {
    init:{
        MAWorkingList:[
            'onBoard','LabResults','VisitBilling','Medication','FollowUpVisit'
        ],
        RDWorkingList:[
            'Assessment','GoalStatement','Charting'
        ]
    }
}

export default  map;
