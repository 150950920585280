import moment from 'moment';
import React from 'react';
import { goPath } from "../../../lib/utils";
import { helpers } from '../../visit/helpers/index';

const dateString = 'MM/DD/YYYY';
const taskTypes = [
                   { type:'ASSIGNED',title:'My tasks',tooltip:'Task someone assigned to you'},
                   { type:'ASSIGNTO',title:'Tracking tasks',tooltip:'Task you assigned to others'},
                   { type:'ALL',title:'All tasks',tooltip:'All tasks'}
                  ];
const priorityMap = {
     'URGENT':'Urgent' ,
     'MEDIUM':'Medium' ,
     'LOW':'Low'
}
const statusMap = {
    'OPEN':'Open',
    'RESOLVED':'Resolved',
}
const tableColumnsByPatient =(filter,sort)=> [{
        id:'createdAt',
        dataIndex:'createdAt',
        title:'Date tagged',
        render:(d,doc)=>{
            return moment(d).format(dateString)
        },
        sorter:(a,b)=>a.createdAt-b.createdAt
    },
    {
        id:'createdBy',
        dataIndex:'createdBy',
        title:'Created By',
        render:(d)=>{
            return _.get(d,'profile.fullName');
        },
    },
    {
        id:'assignedTo',
        dataIndex:'assignedTo',
        title:'Assigned To',
        render:(d)=>{
            return _.get(d,'profile.fullName');
        }
    },

    {
        id: 'reason',
        title: 'Reason',
        dataIndex:'reason',
        render:(d)=>{
          return d;
        }
    },
    {
        id:'priorityLevel',
        dataIndex:'priorityLevel',
        title:'Priority level',
        render:(d,doc)=>{
            const isUrgent = (d=='URGENT');
            return <p style={{ color:isUrgent ? 'red':null }}>{ priorityMap[d] }</p>
        },
        filters: _.map(priorityMap,(k,v)=> ({ text:k,value:v })),
        onFilter: (value, record) => record.priorityLevel == value

    },
    {
        id:'dueDate',
        dataIndex:'dueDate',
        title:'Due in',
        render:(d)=>{
            const now = new Date();
            return d ? Date.daysBetween(now.getTime(), d) :'---'
        },
        sorter:(a,b)=>a.dueDate-b.dueDate

    },
    {
        id:'status',
        dataIndex:'status',
        title:'Status',
        render:(d,doc)=>{
            return  statusMap[d];
        },
        filters: _.map(statusMap,(k,v)=> ({ text:k,value:v })),
        onFilter: (value, record) => record.status == value

    }

]
const expandedRowColumn =(arr)=>[
    {
        id:'dummy',
        width: '180px',
    },
    {
        id:'taggedDate',
        dataIndex:'createdAt',
        title:'Date tagged',
        width: '120px',
        render:(d)=>{
            return moment(d).format(dateString)
        }
    },
    {
        id: 'doctorId',
        title: 'Doctor',
        dataIndex: 'doctor',
        width: '120px',
        render: (d) => {
            const doctorName = _.get(d, 'profile.fullName');
            return doctorName;
        },
    },
    ...arr,
    // {
    //     id:'assignedTo',
    //     dataIndex:'assignedTo',
    //     title:'Assigner',
    //     render:(d)=>{
    //         return _.get(d,'profile.fullName');
    //     }
    // },
    {
        id: 'reason',
        title: 'Reason',
        dataIndex:'reason',
    },
    {
        id:'priorityLevel',
        dataIndex:'priorityLevel',
        title:'Priority level',
        width: '140px',
        render:(d,doc)=>{
            const isUrgent = (d=='URGENT');
            return <p style={{ color:isUrgent ? 'red':null }}>{ priorityMap[d] }</p>
        }
    },
    {
        id:'dueDate',
        dataIndex:'dueDate',
        title:'Due in',
        width: '120px',
        render:(d)=>{
            const now = new Date();
            return d ? Date.daysBetween(now.getTime(), d) :'---'

        }
    },
    {
        id:'status',
        dataIndex:'status',
        title:'Status',
        width: '120px',
        render:(d,doc)=>{
            return <p>{ statusMap[d] }</p>

        }
    }
]
const filterDoctors = helpers.filterDoctors ();
const tableMap = ({sort,filter},ar) =>[
        {
            id:'member',
            title:'Patient name',
            dataIndex:'member',
            width: '180px',
            render:(d,{member})=>{
                const { id } = member;
                const { fullName } = member.profile
                return <a onClick={()=>goPath(`/patients/${id}/enrolledprogram/default`)}>{fullName}</a>
                // return _.get(member,'profile.fullName');
            },

        },
        {
            id:'taggedDate',
            title:'Date tagged',
            dataIndex:'createdAt',
            width: '120px',
            render:(d,{taskAssignments})=>{
                const firstTask = _.first(taskAssignments);
                const { createdAt } = firstTask
                return moment(createdAt).format(dateString)
            },
            sorter:true,
            sortOrder: _.get(sort, 'field', null) === 'createdAt' ? _.get(sort, 'order', false) : null,
        },
        {
            id:'doctorId',
            title:'Doctor',
            dataIndex:'doctor',
            width: '120px',
            render:(d,{taskAssignments})=>{
                // const  = _.first(taskAssignments);
                const doctorName = _.get(d,'profile.fullName');
                return doctorName;
            },
            filters:_.map(filterDoctors,({id,fullName})=>({ text:fullName,value:id }))
        },
        ...ar,
        // {
        //     id:key,
        //     title:title,
        //     render:(d,{taskAssignments})=>{
        //         const firstTask = _.first(taskAssignments);
        //         const profile = _.get(firstTask,key);
        //         return _.get(profile,'profile.fullName');
        //     }
        // },
        {
            id: 'reason',
            title: 'Reason',
            render:(d,{taskAssignments})=>{
                const firstTask = _.first(taskAssignments);
                const { reason } = firstTask;
                return reason;
            }
        },
        {
            id:'priorityLevel',
            dataIndex:'priorityLevel',
            title:'Priority level',
            width: '140px',
            render:(d,{taskAssignments})=>{
                const firstTask = _.first(taskAssignments);
                const { priorityLevel } = firstTask;
                const isUrgent = (priorityLevel=='URGENT');
                return <p style={{ color:isUrgent ? 'red':null }}>{ priorityMap[priorityLevel] }</p>
            },
            filters: _.map(priorityMap,(k,v)=> ({ text:k,value:v })),
            filteredValue: _.get(filter, 'priorityLevel',null),
        },
        {
            id:'dueDate',
            title:'Due in',
            width: '120px',
            render:(d,{taskAssignments})=>{
                const firstTask = _.first(taskAssignments);
                const { dueDate } = firstTask;
                const now = new Date();
                return dueDate ? Date.daysBetween(now.getTime(), dueDate) :'---'

            },
            sorter:true,
            sortOrder: _.get(sort, 'field', null) === 'dueDate' ? _.get(sort, 'order', false) : null,
        },
        {
            id:'status',
            dataIndex:'status',
            title:'Status',
            width: '120px',
            render:(d,{taskAssignments})=>{
                const firstTask = _.first(taskAssignments);
                const { status } = firstTask;
                return <p>{ statusMap[status] }</p>
            },
            filters: _.map(statusMap,(k,v)=> ({ text:k,value:v })),
            filteredValue: _.get(filter, 'status',null),

        },
        ];

const patientTaskHistoryColumn = [
    {
        id:'taggedDate',
        dataIndex:'createdAt',
        title:'Date Tagged',
        width: '120px',
        render:(d)=>{
            return moment(d).format(dateString)
        }
    },
    {
        id:'createdBy',
        dataIndex:'createdBy',
        title:'Created By',
        width: '120px',
        render:(d)=>{
            return _.get(d,'profile.fullName');
        }

    },
    {
        id:'assignedTo',
        dataIndex:'assignedTo',
        title:'Assigned To',
        width: '120px',
        render:(d)=>{
            return _.get(d,'profile.fullName');
        }
    },
    {
        id: 'reason',
        title: 'Reason',
        dataIndex:'reason',
    },
    {
        id:'priorityLevel',
        dataIndex:'priorityLevel',
        title:'Priority level',
        width: '140px',
        render:(d)=>{
            const isUrgent = (d=='URGENT');

            return <p style={{ color:isUrgent ? 'red':null }}>{ priorityMap[d] }</p>
        }
    },
    {
        id:'dueDate',
        dataIndex:'dueDate',
        title:'Due in',
        width: '120px',
        render:(d)=>{
            const now = new Date();
            return d ? Date.daysBetween(now.getTime(), d) :'---'
        }
    }]
export default {
    tableMap,
    taskTypes,
    priorityMap,
    statusMap,
    expandedRowColumn,
    patientTaskHistoryColumn,
    filterDoctors,
    tableColumnsByPatient
};

Date.daysBetween = function( date1, date2 ) {
    if(date1 == undefined || date2 == undefined) return;
    //Get 1 day in milliseconds
    var one_day=1000*60*60*24;

    // Convert both dates to milliseconds
    var date1_ms = date1;
    var date2_ms = date2;

    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;
    //take out milliseconds
    difference_ms = difference_ms/1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms/60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms/60;
    var hours = Math.floor(difference_ms % 24);
    var days = Math.floor(difference_ms/24);
    if (days > 30 || days < 0) {
        return "Overdue"
    }
    if (days == 0) {
        return 'Today'
    }
    return days + ' days'
    return days + ' days'// + hours + ' hours, ' + minutes + ' minutes, and ' + seconds + ' seconds';
  }
