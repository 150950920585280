
import { Modal, Button } from 'antd';
import moment from 'moment';
import { decryptRole } from 'libModule/utils';
import { ROLES } from 'modulesAll/utils/constants/role';

let env = process.env.CONFIG_ENV;//window.VSM_CONFIG.NODE_ENV;
const devCCPUrl = 'https://unifiedcare-test.awsapps.com/connect/ccp'
const prodCCPUrl = 'https://unifiedcare-prod.awsapps.com/connect/ccp'
// default is dev ccp
let ccpUrl = devCCPUrl;
let env_check = VSM_CONFIG.MIXPANEL_ENV_CHECK || true;
switch (env) {
    case 'development':
        ccpUrl = devCCPUrl;
        break;
    case 'test':
        ccpUrl = devCCPUrl;
        break;
    case 'production':
        ccpUrl = prodCCPUrl;
        break;
    default:
        env_check = false;
}

var callingPhoneNumber = '';
var callingPhoneNumberTime = null;
var onConnected = null;
var onEnded = null;
var makeACallError = null;
let subscriptionFromContainer = null;

export const clicktoDial = function handleClicktoDial(phoneNumber, funcOnConnected, funcOnEnded, funcMakeACallError) {
    onConnected = funcOnConnected
    onEnded = funcOnEnded
    makeACallError = funcMakeACallError
    if (phoneNumber == null) {
        Modal.error({
            title: "Error",
            content: "Phone Number is invalid"
        })
        return
    }

    callingPhoneNumber = phoneNumber.replace(/\s+/g, '')

    makeACall()
}

export const loadConnectStreams = function loadConnectStreams( sub ) {
    const currentUserRole = decryptRole();
    // Only show Call Center for MA + CDE(nurse) 
    if (currentUserRole === ROLES.nurse || currentUserRole === ROLES.medicalAssistant) {
        subscriptionFromContainer = sub
        var connectStreams = document.getElementById('connect-streams');
        if (connectStreams == null) {
            const script = document.createElement("script");
            script.src = "connect-streams.js";
            script.async = true;
            script.id = 'connect-streams';
            document.body.appendChild(script);
            script.onload =  () => subscriptions();
        } else {
            subscriptions()
        }
    }
}

function subscriptions() {
    addCCP();
    subscriptionFromContainer()
}

/*// no more terminate
export const terminateCCP = function terminate() {
    window.awsCCP = {};
    if (window.connect && window.connect.core.initialized) {
       window.connect.core.terminate()
    }
}
*/

function makeACall() {
    const endpoint = global.connect.Endpoint.byPhoneNumber(callingPhoneNumber);
    console.log('--------make a call', endpoint);
    console.log('--------window.awsCCP', window.awsCCP);
    console.log('--------window.awsCCP.agent', window.awsCCP.agent);
    window.awsCCP.agent.connect(endpoint, {
        success: (argument) => { // connectionId available here as an argument
          console.log('Make call success!', argument);
        },
        failure: (err) => {
            console.log('Make call failed!', err);
            var obj = JSON.parse(err)
            makeACallError(obj)
        }
    });
}

function addCCP() {
    var containerDiv = document.getElementById('callBody');
    if (containerDiv && containerDiv.getElementsByTagName('iframe').length == 0) {
        let loginPopup = true
        if (window.awsCCP && window.awsCCP.loginPopup == true) {
            loginPopup = false;
        }
        window.connect.core.initCCP(containerDiv, {
            ccpUrl: ccpUrl,
            loginPopup: loginPopup,
            softphone: {
                allowFramedSoftphone: true
            }
        });
    } else {
        console.log('already added CCP');
    }
}



//first time load script; then add ccp and dial a number
function awsConnectScriptLoaded() {
    console.log('awsConnectScriptLoaded')
    addCCP()
    window.awsCCP = window.awsCCP || {};
    window.connect.agent((agent) => {
        window.awsCCP.agent = agent;
        makeACall()
        /*
        //Event subscriptions link your app into the heartbeat of Amazon Connect
        //by allowing your code to be called when new agent information is available.
        console.log("Subscribing to events for agent " + agent.getName());
        console.log("Agent is currently in status of " + agent.getStatus().name);
        agent.onRefresh(function handleAgentRefresh(agent) {
            console.log("[agent.onRefresh] Agent data refreshed. Agent status is ", agent.getStatus().name);
        });
        agent.onRoutable(function handleAgentRoutable(agent) {
            console.log("[agent.onRoutable] Agent data refreshed. Agent status is ", agent.getStatus().name);
        });
        agent.onNotRoutable(function handleAgentNotRoutable(agent) {
            console.log("[agent.onNotRoutable] Agent data refreshed. Agent status is ", agent.getStatus().name);
        });
        agent.onOffline(function handleAgentOffline(agent) {
            console.log("[agent.onOffline] Agent data refreshed. Agent status is ", agent.getStatus().name);
        });
        */
    });

    window.connect.contact((contact) => {
        window.awsCCP.contact = contact;
        /*
        if (contact.getActiveInitialConnection()
            && contact.getActiveInitialConnection().getEndpoint()) {
            console.log("New contact is from " + contact.getActiveInitialConnection().getEndpoint().phoneNumber);
        } else {
            console.log("This is an existing contact for this agent");
        }
        console.log("Contact is from queue " + contact.getQueue().name);
        console.log("Contact attributes are " + JSON.stringify(contact.getAttributes()));

        if (contact.getActiveInitialConnection()
            && contact.getActiveInitialConnection().getEndpoint()) {
            console.log("New contact is from " + contact.getActiveInitialConnection().getEndpoint().phoneNumber);
        } else {
            console.log("This is an existing contact for this agent");
        }
        contact.onIncoming(function handleContactIncoming(agent) {
            if (contact) {
                console.log("[contact.onIncoming] Contact is incoming. Contact state is " + contact.getStatus().type);
            } else {
                console.log("[contact.onIncoming] Contact is incoming. Null contact passed to event handler");
            }
        });
        contact.onAccepted(function handleContactAccepted(contact) {
            if (contact) {
                console.log("[contact.onAccepted] Contact accepted by agent. Contact state is " + contact.getStatus().type);
            } else {
                console.log("[contact.onAccepted] Contact accepted by agent. Null contact passed to event handler");
            }
        });
        */
        contact.onConnected(function handleContactConnected(contact) {
            if (contact) {
                 console.log("-------------------[contact.onConnected] Contact connected to agent. Contact is " + contact.getContactId(), "status:" + contact.getStatus().type);
                //mark down date
                //error do not mark down date
                if (contact.getStatus().type == 'connected'){
                    callingPhoneNumberTime = new Date();
                    onConnected(callingPhoneNumberTime)
                }
            } else {
                // console.log("[contact.onConnected] Contact connected to agent. Null contact passed to event handler");
            }
        });
        contact.onEnded(function handleContactEnded(contact) {
            if (contact) {
                 console.log("[contact.onEnded] Contact has ended. Contact state is " + contact.getStatus().type);
                //callingPhoneNumberTime == NULL, LOST THE ONCONNECTED TIME
                if (callingPhoneNumberTime == null) {
                    onEnded(null, null, null)//'[error]onConnect time is null'
                } else {
                    // var today= new Date();
                    // //displays "Days from Wed Jan 01 0110 00:00:00 GMT-0500 (Eastern Standard Time)
                    // //to Tue Dec 27 2011 12:14:02 GMT-0500 (Eastern Standard Time): 694686 days, 12 hours, 14 minutes, and 2 seconds"
                    // var diff = Date.daysBetween(callingPhoneNumberTime, today)
                    onEnded(callingPhoneNumberTime, null, null)
                    callingPhoneNumberTime = null;
                }
            } else {
                // console.log("[contact.onEnded] Contact has ended. Null contact passed to event handler");
            }
        });
    });
}

Date.daysBetween = function( date1, date2 ) {
    if(date1 == undefined || date2 == undefined) return;
    date1 = typeof date1=='number' ? moment(date1) : date1;
    date2 = typeof date2=='number' ? moment(date2) : date2;
    //Get 1 day in milliseconds
    var one_day=1000*60*60*24;

    // Convert both dates to milliseconds
    var date1_ms = date1.toDate().getTime();
    var date2_ms = date2.toDate().getTime();

    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;
    //take out milliseconds
    difference_ms = difference_ms/1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms/60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms/60;
    var hours = Math.floor(difference_ms % 24);
    var days = Math.floor(difference_ms/24);

    //return days + ' days, ' + hours + ' hours, ' + minutes + ' minutes, and ' + seconds + ' seconds';
    return minutes + ' min, ' + seconds + ' sec';
  }
