import {
    React
} from 'ihcomponent'
import {createContainer} from '../../../../lib/utils'
import { graphql } from 'react-apollo';
import MemberDetail from '../components/MemberDetail'
import userData from '../../../fake/user'
import userGQL from '../../../graphql/user'


const ComponentWithGql = graphql(userGQL, {
    options : (ownProps)=> {
      const id = ownProps.patientId
      return {
        variables: {
          id
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    }
})(MemberDetail)


const Container = createContainer(ComponentWithGql, (state, ownProps)=>{

    return {
        patientId : ownProps.patientId,
        fakeUser : userData
    }
})

Container.displayName = 'patient/main/containers/MemberDetail';

export default Container;
