import React from 'react';
import { openErrorModal,openModal } from 'layoutModule/actions/MainModal'
import { goPath, FormHelper, GQLHelper, setAsyncRouteLeaveHook, submittingStyle } from 'libModule/utils'
import { Tabs,Row,Col,Icon,Button,Modal } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import map from '../constants/map';
import { IHLoading } from 'ihcomponent'
import PatientVisitCardComponent from '../components/PatientVisitCardComponent';
import appointmentTypeColorCode from '../constants/appointmentTypeColorCode';
import $ from 'jquery';
import {compose,graphql} from "react-apollo/index";
import {helpers} from "../helpers";
import PostItList from "../../graphql/postItList";
import {message} from "../../../../../package/IHComponent";
import deleteVisitQuery from 'graphqlModule/mutation/deleteVisit';
import visitList from 'modulesAll/graphql/visitList';
import Client from 'libModule/gqlClient';
import {connect} from "react-redux";
import actions from "../actions";
import CreateAppointmentModal from '../components/CreateAppointmentModal';
import CreateVisitInPatientDetailContainer from "../containers/CreateVisitInPatientDetailContainer";

const TabPane = Tabs.TabPane;


const additionalFollow_Up_Types = {
    'FOLLOW_UP1':'1st Follow Up',
    'FOLLOW_UP2':'2nd Follow Up',
    'FOLLOW_UP3':'3rd Follow Up',
    'ANNUAL': 'Annual Visit',
}

const PatientAppointmentComponent = class extends React.Component {
    constructor(){
        super();
        this.state = {
            activeKey :-1,
            showCreateAppointmentModal:false
        }
        this.ref = React.createRef();

    }

    setActiveKey = (activeKey)=>{
        this.setState({
            activeKey
        })
    }

    // componentWillReceiveProps(nextProps){
    //     if(!this.props.visitList&&nextProps.visitList){
    //         const activeKey = _.findIndex(nextProps.visitList,(v)=> moment(v.appointmentAt).isAfter(moment().startOf('day')));
    //         this.setState({
    //             activeKey
    //         })
    //     }
    //
    // }

    renderNavButton=(year)=>{
        const setYear = _.get(this,'props.setYear');
        return <Row style={{fontSize:18}}>
            <Col span={12}>
                <Icon type="left-square" onClick={()=>{ this.setState({ activeKey:-1 }); setYear(-1)}}/>
            </Col>
            <Col span={12}>
                <Icon type="right-square" onClick={()=>{ this.setState({ activeKey:-1 }); setYear(1)}}/>
            </Col>
        </Row>
    }

    renderCreateAppointmentModal=()=>{
        const { showCreateAppointmentModal } = this.state;
        const { props } = this;
        if(showCreateAppointmentModal){
            return (<CreateVisitInPatientDetailContainer {...props} onCancel={this.closeCreateAppointmentModal}/>)
            // return <Modal visible={true} maskClosable={false} footer={null}
            //               // width={'fit-content'}
            //               width={600}
            //               onCancel={()=>this.closeCreateAppointmentModal()}
            //               closable={true}
            //               title={'Create an appointment'}
            //        >
            //           <CreateVisitInPatientDetailContainer {...props} fromDetail={true} closeModal={this.closeCreateAppointmentModal}/>
            //           {/*<CreateAppointmentModal {...props} closeModal={this.closeCreateAppointmentModal}/>*/}
            //        </Modal>
        }
    }

    handleOnClickCreateAppointment=()=>{
        this.setState({
            showCreateAppointmentModal:true
        })
        // openModal(<CreateAppointmentModal {...props}/>,{className:'createVisitModal'});
    }

    closeCreateAppointmentModal = () =>{
        this.setState({
            showCreateAppointmentModal: false
        })
    }
    renderCreateAppointmentButton = (patientId,fullName,patientBirthday)=>{
        // const parsedFullName = btoa(fullName);

        return <Button
            // onClick={()=>goPath(`/appointments/create/${patientId}/${parsedFullName}/${patientBirthday}`)}
            type='primary'
            size='small'
            onClick={()=>this.handleOnClickCreateAppointment(patientId)}
            style={{ float:'right',marginRight: 20,backgroundColor:'#4278c3',border:'unset' }}
        >+</Button>
    }

    renderHeader=(year,patientId,fullName,patientBirthday)=>{
        return <Row style={{ padding: '10px 0 10px 20px' }}>
            <Col span={5}>
                <span style={{ fontSize:18,fontWeight:'bold' }}>Appointments</span>
            </Col>
            <Col span={2}>
                <span style={{ fontSize:18,fontWeight:'bold' }}>{year}</span>
            </Col>
            <Col span={2}>
                { this.renderNavButton(year) }
            </Col>
            <Col span={15}>
                { this.renderCreateAppointmentModal() }
                {this.renderCreateAppointmentButton(patientId,fullName,patientBirthday)}
                {/*{this.props.isInChat ? '' : this.renderCreateAppointmentButton(patientId,fullName,patientBirthday)}*/}
            </Col>
        </Row>
    }

    deleteVisit = (id)=>{
        const { patientId,filter,year,refetch } = this.props;
        const { provider } = filter&&filter.provider ? filter : { provider:_.reduce(helpers.filterDoctors(),(res,d)=>{
                    res.push(d.id)
                    return res;
                },[]
            )};
        let variables = { };
        const startDate = moment({year}).startOf('year');
        const endDate = moment({year}).endOf('year');

        variables.memberId = patientId;
        variables.providerIds = provider;

        Client.mutate({
            mutation: deleteVisitQuery,
            notifyOnNetworkStatusChange: true,
            variables: {id:id}
        }).then(() => {
           return refetch();
        }).then(()=>{
            message.success('Patient Appointment canceled');
        }).catch((error)=>{
            this.props.openErrorModal(GQLHelper.formatError(error));
        })

        // Client.mutate({
        //     mutation: deleteVisitQuery,
        //     notifyOnNetworkStatusChange: true,
        //     variables: {id:id},
        //     refetchQueries:[{
        //         query: visitList,
        //         variables: helpers.setFromAndToDate(variables,startDate,endDate),
        //         fetchPolicy:'network-only'
        //     }]
        // }).then(()=>{
        //     message.success('Patient Appointment canceled');
        // }).catch((error)=>{
        //     this.props.openErrorModal(GQLHelper.formatError(error));
        // })
    }

    findNextAppointment = (visitList)=>{
        return _.findIndex(visitList,(v)=> moment(v.appointmentAt).isAfter(moment().startOf('day')));
    }
    getBorderColor = (visitList,activeKey)=>{
        const selectedVisit = _.get(visitList,activeKey,{ });
        const selectedVisitType = _.get(selectedVisit,'type');
        const borderColor = appointmentTypeColorCode[selectedVisitType].borderColor;
        return borderColor;
    }

    onTabChange=(visitList,activeKey)=>{
        const borderColor = this.getBorderColor(visitList,activeKey);
        const selectedItem = $('body').find('.patientAppointmentsTab').find('.ant-tabs-ink-bar.ant-tabs-ink-bar-animated');
        selectedItem.css({ 'background-color':borderColor });
        this.setActiveKey(activeKey)
    }

    render(){
        const { visitList,loading,patientId,patientName,year,nextVisit={},refetch} = this.props;
        let { activeKey } = this.state;

        if(loading){
            return <Row style={{ height: 200,display:'flex',justifyContent:'center',alignItems:'center' }}>
                    <IHLoading/>
                   </Row>
        }

        let nextAppKey = -1
        _.map(visitList,(v,i)=>{
            if(v.id==nextVisit.id){
                nextAppKey = i;
            }
        });
        const defaultKey = nextAppKey >=0 ? (nextAppKey+'' ) : '';
        const selectedVisit = _.get(visitList,defaultKey,{ });
        const selectedVisitType = _.get(selectedVisit,'type',' ');
        const activeStyle = (v,i)=>({
                    color:(activeKey==i) ? appointmentTypeColorCode[`${v.type}`].borderColor :'',
                    fontWeight:(activeKey==i) ? 'bold': 'normal'
        });


        return<div className='patientAppointmentsContainer'>
                {this.renderHeader(year,patientId,patientName)}
            {
                loading ?
                        <Row style={{ height: 200,display:'flex',justifyContent:'center',alignItems:'center' }}>
                            <IHLoading/>
                        </Row>
                        :
                <Tabs
                      tabPosition={'left'}
                      className={'patientAppointmentsTab ' + selectedVisitType}
                      type={'line'}
                      onChange={(activeKey) => this.onTabChange(visitList, activeKey)}
                      activeKey={(defaultKey>=0&&activeKey==-1 ? defaultKey : activeKey)+''}
                >

                    {
                        _.map(visitList, (v, i) => {
                                return <TabPane
                                    tab={<span style={(() => activeStyle(v, i))()}
                                               className={ v.id == nextVisit.id ? 'isNextVisit' : ''}>{map[`${v.type}`]||additionalFollow_Up_Types[`${v.type}`]}</span>}
                                    key={i + ''} style={{height: '100%'}} forceRender={true}
                                >
                                    <PatientVisitCardComponent visit={v} deleteVisit={this.deleteVisit} refetch={refetch}
                                                               router={this.props.router} route={this.props.route}
                                                               isInChat = {this.props.isInChat}
                                    />
                                </TabPane>
                            }
                        )

                    }
                </Tabs>
            }
            </div>
    }
}

const withData = graphql(visitList, {
    options: (ownProps) => {
        const { filter,sort,year,patientId } = ownProps;
        const  variables = {
            count:99999,
        }
        const { provider } = filter&&filter.provider ? filter : { provider:_.reduce(helpers.filterDoctors(),(res,d)=>{
                    res.push(d.id)
                    return res;
                },[]
            )};
        let startDate = moment({year}).startOf('year');
        let endDate = moment({year}).endOf('year');
        variables.appointmentFrom = Number(startDate);
        variables.appointmentTo = Number(endDate);
        variables.sort = _.isEmpty(sort) ? { } : { field: fieldMap[sort.field],direction: sort.direction };
        variables.providerIds = provider;
        variables.memberId = patientId;

        return {
            variables,
            notifyOnNetworkStatusChange: false,
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        };
    },
    props: ({ownProps, data}) => {
        const { loading,visitList, refetch } = data;

        if(loading){
            return {
                loading:loading
            };
        }
        if(visitList&&visitList.data) {
            return {
                visitList:visitList.data,
                refetch
            }
        }
    }
})

const mapToDispatch = (dispatch)=>{
    return {
        setYear:(offset)=>dispatch(actions.setYear(offset)),
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(modalActions.confirm(props)),
        openModal:(content,props)=>dispatch(openModal(content,props)),
    }
};

const mapToProps = (props)=>{
    return{
        year:props.visit.main.year,

    }
};

export default compose(connect(mapToProps,mapToDispatch),withData)(PatientAppointmentComponent);
