import  React  from 'react';
import { compose } from "react-apollo/index";
import PatientSmartAlertComponent from '../component/PatientSmartAlertComponent';
import closeSmartAlert from '../query/closeSmartAlert';
import getPatientSmartAlert from '../query/getPatientSmartAlert';
import { connect } from 'react-redux'
import { GQLHelper } from 'libModule/utils'
import { modalAction } from 'modulesAll/common/actions'
import Client from 'libModule/gqlClient';
import { message,Modal,Row,Col,Tag,Form,Input,Button,Checkbox } from 'antd';
import {API} from "../../timer/query";
import createProviderNote from 'modulesAll/graphql/createProviderNote'
import constant from "../constant";
import providerNoteList from 'graphqlModule/providerNoteList'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from "../../timer/actions";
import patientSmartAlertList from 'modulesAll/smartAlert/query/patientSmartAlertList.js'

const { alertLevelColorMap } = constant;
const { stopTimer,updateInterventionDetails }= timerActions.default;

let PatientSmartAlertTasksContainer = class extends React.Component {
    static  displayName = 'PatientSmartAlertTasksContainer';

    constructor(){
        super();
        this.state = {
            showSmartPNModal: false,
            alert:{ }
        }
    }

    changeSmartPNModalStatus = (alert) =>{
        const { showSmartPNModal } = this.state;
        const { memberId } = this.props;
        const { taskType } = alert;
        this.setState({
            showSmartPNModal: !showSmartPNModal,
            alert
        },()=>{
            Mixpanel.track('clicked',taskType,'patient_profile',{ PATIENT_ID:memberId });
        })
    }

    renderPatientInfo= () =>{
        const { identification, fullName } = this.props;
        return <Row>
                <Col span={24}>
                    <span style={{ fontSize:18,fontWeight:'bold',color:'#000000' }}>{`${fullName} (${identification})`}</span>
                </Col>
               </Row>
    }

    renderSmartAlert = ()=>{
        const { alert } = this.state;
        const style ={
            tagStyle:{
                display: 'flex',
                height: 26,
                alignItems: 'center',
                border: 'solid 1px',
                borderRadius: 15,
                marginBottom: 15,
                marginTop:15,
                width:'fit-content'
            }
        };
        const {color, backgroundColor} = alertLevelColorMap[alert.alertLevel];

        return <Tag style={{color: color, backgroundColor: backgroundColor,...style.tagStyle}}>
                    <p style={{fontWeight:'bold',cursor:'point'}}>{alert.display}</p>
               </Tag>
    }
    createProviderNote = async (variables)=>{
        const { props } = this;
        const refetchVariable = (() => {
            const variables = {
                'filters': {
                    'enrolledProgramId': props.currentProgramId,
                    ...props.filter,
                },
                page: _.get(props, 'page.current', 1),
                count: 10,
                sort: {'field': 'CREATED_AT', 'direction': 'DESC'}
            }
            const sortField = _.get(props, 'sort.fieldGraph', null);
            if (sortField) {
                variables.sort = {
                    field: sortField,
                    direction: _.get(props, 'sort.direction', 'DESC'),
                };
            }
            return variables;
        })();
        return Client.mutate({
            mutation:createProviderNote,
            variables,
            refetchQueries: [{
                query: providerNoteList,
                variables: refetchVariable,
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
            }]
        });
    }

    handleSubmit = async (e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });

        const { form, memberId, openErrorModal, smartAlertTableProps } = this.props;
        const { alert } = this.state;
        const { taskType } = alert;
        const { getFieldsValue } = form;
        const { notes,dismissAlert } = getFieldsValue();
        if(notes&&notes.trim().length!=0){
            let variables = {
                memberId,
                smartAlertId:alert.id,
                category:'SMART_ALERT',
                content:notes
            }
            try {
                const res = await this.createProviderNote(variables);
                const apiName = Object.keys(res.data)[0];
                const docId = res.data[apiName].id;
                const summary = res.data[apiName].content;
                const resource = atob(docId).split(':')[0];
                let displayName = this.constructor.displayName;
                displayName = `${displayName}:${memberId}`;
                this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
                this.props.stopTimer(displayName, 'saveModal');
                if(dismissAlert){
                    this.handleCloseAlert(alert.id,taskType);
                } else {
                    Client.query({
                        query: patientSmartAlertList,
                        variables:{ filters: {memberId, alertLevel: smartAlertTableProps.filter.alertLevel}, page: smartAlertTableProps.page.current},
                        fetchPolicy:'network-only'
                    });
                }
                this.setState({
                    showSmartPNModal:false,
                    alert:{ }
                })
                Mixpanel.track('clicked','submit_on_alert','PATIENT_PROFILE',{ PATIENT_ID:memberId })
            }
            catch(e){
                console.log(e);
                openErrorModal(GQLHelper.formatError(e))

            }
        }
    }

    renderForm = ()=>{
        const { TextArea } = Input;
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return <Form layout="inline" style={{ width:'100%',paddingRight:20 }}>
                 <Form.Item style={{ width:'inherit' }} className='smartAlertPNForm'>
                     {getFieldDecorator('notes', {
                         rules: [{ required: true, message: 'Please Enter Provider Notes' },{ whitespace:true,message: 'Please Enter Provider Notes' }],
                     })(
                         <TextArea rows={6}/>
                     )}
                 </Form.Item>
                 <Form.Item>
                    {getFieldDecorator('dismissAlert', {
                        rules: [{ required: false }],
                        initialValue: true,
                        valuePropName: 'checked'
                    })(
                        <Checkbox>Dismiss the alert</Checkbox>
                    )}
                 </Form.Item>
                     <Button type="primary" htmlType='submit' onClick={this.handleSubmit} style={{ float:'right',marginTop:20 }}>
                         Submit
                     </Button>
               </Form>
    }

    renderModal = ()=>{
        const { showSmartPNModal } = this.state;
        let displayName = this.constructor.displayName;
        const { memberId } = this.props

        const viewingDetails = {
            component : displayName,
            path:window.location.pathname
        }
        const keepEventListener = true;

        return showSmartPNModal ?
                <Modal visible={showSmartPNModal} onCancel={ ()=>this.setState({ showSmartPNModal: false })} width={940}
                      maskClosable={false} footer={null}>
                <TimerContainer displayName={'PatientSmartAlertTasksContainer'} category='ALERT' action='UPDATE'
                                viewingDetails={viewingDetails} patientId={memberId}
                                keepEventListener={keepEventListener}
                />
                 <Row>
                     <Col span={8}>
                         { this.renderPatientInfo() }
                         { this.renderSmartAlert() }
                     </Col>
                     <Col span={16}>
                        { this.renderForm() }
                     </Col>
                 </Row>
                </Modal> :''
    }

    handleCloseAlert = (taskId,taskType)=>{
        const { openErrorModal,memberId,uuid, smartAlertTableProps } = this.props
        const event = 'HANDLE_SMART_ALERT';
        const category = 'ALERT';
        const action = 'UPDATE';
        const timeTrackerEvent = API.startTimeTracker(event,category,action,memberId);
        Client.mutate({
            mutation: closeSmartAlert,
            variables:{taskId},
            fetchPolicy: 'no-cache',
            refetchQueries:[
                {
                    query:getPatientSmartAlert,
                    variables:{ memberId },
                    fetchPolicy:'network-only'
                },
                {
                    query:patientSmartAlertList,
                    variables:{ filters: {memberId, alertLevel: smartAlertTableProps.filter.alertLevel}, page: smartAlertTableProps.page.current},
                    fetchPolicy:'network-only'
                }
            ]
        }).catch(e=>{
            openErrorModal(GQLHelper.formatError(e))
        }).then(_=> {
            const canRestUUid = false;
            API.createTimeTracker(event,timeTrackerEvent,canRestUUid,uuid);
            Mixpanel.track('Closed',taskType,'PATIENT_PROFILE',{ PATIENT_ID:memberId });
            message.success('Alert Closed')
        });

    }

    render(){
        const { props,changeSmartPNModalStatus } = this;
        const { showSmartPNModal } = this.state;
        const { smartAlertTableProps } = this.props;
        return <div>
                    <PatientSmartAlertComponent {...props} showConfirmForCloseAlert={this.handleCloseAlert}
                                                showSmartPNModal={ showSmartPNModal }
                                                changeSmartPNModalStatus={changeSmartPNModalStatus}
                    />
                    { this.renderModal() }
               </div>
    }
}
/*
const getVariables = (ownProps) => {
    const { page, count } = ownProps;
    return {
        page: _.get(ownProps, 'page.current', 1),
        count: 10
    };
};

const withData = graphql(tempPatients, {
    options: (ownProps) => {
        const variables = getVariables(ownProps);

        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ ownProps, data }) => {
        const { loading, tempEnrolledPatientsList } = data;
        if (tempEnrolledPatientsList && tempEnrolledPatientsList.data.length) {
            tempEnrolledPatientsList.data.forEach(t => t.name = t.firstName + ' ' + t.lastName);
        }
        return {
            loading,
            list: tempEnrolledPatientsList
        }
    }
});
*/
const mapState = (state) => {
    const uuid = _.get(state,'timer.main.uuid',null);
    return {
        // ...state.globalTables.provider_alert_list,
        ...state.globalTables.allNotes,
        smartAlertTableProps:state.globalTables.patient_smart_alert_table,
        uuid,
    }
};
const mapDispatch = (dispatch) => {
    return {
        openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
        openErrorModal: msg => dispatch(modalAction.openErrorModal(msg)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}
PatientSmartAlertTasksContainer = Form.create({})(PatientSmartAlertTasksContainer);

export default compose(connect(mapState,mapDispatch))(PatientSmartAlertTasksContainer);
