import React from "react";
import AppointmentCalenderContainer from './AppointmentCalenderContainer'
import moment from "moment-timezone";
import debounce from 'lodash/debounce';
import { goPath, FormHelper, GQLHelper, setAsyncRouteLeaveHook, submittingStyle } from 'libModule/utils'
import {connect} from "react-redux";
import actions  from 'modulesAll/common/actions/modal'
const style = {
    mainHeaderDiv:{
        height: 45,
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    mainHeader:{
        marginLeft:40,
        fontSize:14,
        fontWeight:'bold',
        color:'black'
    }
}
const today = new Date();
const selectedDay = today;
const tz = moment.tz.guess();
const localTime = moment(selectedDay).tz(tz);
const endOfWeek = moment(localTime).endOf('week').subtract(1,'day');
const startOfWeek = moment(localTime).startOf('week').add(1,'day');

const initialState =  {
    year: today.getFullYear(),
    month: today.getMonth()+1,
    selectedDay,
    startOfWeek,
    endOfWeek,
    selectedVisit:{ },
    originalVisit:{ },
    isEdit:true,
    searchName:'',
    isCreate:true
}


const AppointmentCalenderWrapperContainer = class extends React.Component {
    constructor(){
        super();


        this.state = initialState;
        this.setSearchName = debounce(this.setSearchName,1000);
        this.setAppointMonthAndYear = this.setAppointMonthAndYear.bind(this);
    }

    componentWillMount() {
      //  setAsyncRouteLeaveHook(this.props.router, this.props.route, this.routerWillLeave.bind(this))
    }


    componentWillReceiveProps(nextProps){

        const currentLocation = _.get(this,'props.location.pathname');
        const nextLocation = _.get(nextProps,'props.location.pathname');

        if(currentLocation!=nextLocation) {
            this.setState({
                initialState
            })
        }
    }

    routerWillLeave() {
        const { openConfirmModal } = this.props;
        const { isEdit,isCreate } = this.state;
        return new Promise((resolve, reject) => {
            if (!isEdit&&!isCreate){
                resolve(true)
                return;
            }
            const Yes = {
                label: 'Confirm',
                handler: () => {
                    this.setSelectedVisit({});
                    this.enableEdit(false);
                    resolve(true)
                },
                closeModal: true
            }
            const No = {
                label: 'Cancel',
                handler: () => {
                    resolve(false)
                }
            }
            const modalProps = {
                Yes,
                No,
                title: 'Leave This Page?',
                content: 'Please confirm if you would like to leave this page without saving your changes'
            }
            openConfirmModal(modalProps)
        })
    }

    preMonth=( )=> {
        const { selectedDay } = this.state;
        const date = moment(selectedDay).subtract(1, 'weeks');
        this.setAppointMonthAndYear(date);
    }

    nextMoth=( )=> {
        const {selectedDay} = this.state;
        const date = moment(selectedDay).add(1, 'weeks');
        this.setAppointMonthAndYear(date);
    }

    setSelectedVisit = (selectedVisit)=>{
        this.setState({
            selectedVisit
        })
    }

    setOriginalVisit = (originalVisit)=>{
        const ovt = this.state.originalVisit;

        if(_.isEmpty(originalVisit)&&!_.isEmpty(ovt)){
            this.setState({
                originalVisit
            })
        }

        if(_.isEmpty(ovt)&&!_.isEmpty(originalVisit)) {
            this.setState({
                originalVisit
            })
        }
    }

    setSearchName = (searchName)=>{
        this.setState({
            searchName
        })
    }

    setIsCreate = (isCreate)=>{
        this.setState({
            isCreate
        })
    }
    setAppointMonthAndYear(date){
        const year = date.year();
        const month = date.month()+1;
        const selectedDay = date;
        const tz = moment.tz.guess();
        const localTime = moment(selectedDay).tz(tz);
        const endOfWeek = moment(localTime).endOf('week').subtract(1,'day');
        const startOfWeek = moment(localTime).startOf('week').add(1,'day');
        this.setState({
            year,month,selectedDay,endOfWeek,startOfWeek,
        })
    }

    enableEdit = (flag)=>{
        this.setState({
            isEdit:flag
        })
    }

    render(){

        const { year,month,selectedDay,endOfWeek,startOfWeek,selectedVisit,isEdit,searchName,originalVisit,isCreate } = this.state;
        const props = this.props;
        return <AppointmentCalenderContainer setAppointMonthAndYear={this.setAppointMonthAndYear}
                                             year={year} month={month} selectedDay = {selectedDay}
                                             startOfWeek = { startOfWeek }
                                             endOfWeek = { endOfWeek }
                                             preMonth = { this.preMonth }
                                             nextMoth = {this.nextMoth }
                                             setSelectedVisit = { this.setSelectedVisit }
                                             selectedVisit = { selectedVisit }
                                             isEdit = { isEdit }
                                             enableEdit = { this.enableEdit }
                                             saveChanges = { this.saveChanges }
                                             setSearchName = {this.setSearchName }
                                             searchName = { searchName }
                                             setIsCreate = { this.setIsCreate }
                                             originalVisit = { originalVisit }
                                             isCreate = { isCreate }
                                             setOriginalVisit= {this.setOriginalVisit}
                                             {...props}
                />
    }
}
const mapToDispatch = (dispatch)=> {
    return {
        // openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(actions.confirm(props)),
    }
}

export default connect(null,mapToDispatch)(AppointmentCalenderWrapperContainer);

