import {React, IHMultiSelect, _} from 'ihcomponent'
import {SuperContainer, createContainer, setPagePath} from 'libModule/utils'
import SelectAlerts from './SelectAlerts'
import TodoListTable from './ToDoListContainer'
import TaggedPatientListTable from './TaggedPatientListContainer'
import AppointmentContainer from '../../../visit/containers/AppointmentContainer';
import ProgramSelect from './ProgramSelect'
import EnrolledPatientStats from './EnrolledPatientStatsContainer';
import ProgramMeasureTrendsContainer from './ProgramMeasureTrendsContainer';
import actions from '../actions';
import moment from 'moment';
import { Tabs, Button,DatePicker,Col,Row,Tooltip,Icon } from 'antd';
const { RangePicker } = DatePicker;
import CreateVisitInDashboardContainer from "../../../visitNewWorkFlow/containers/CreateVisitInDashboardContainer";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import Client from 'libModule/gqlClient';
import visitList from 'modulesAll/graphql/visitList';
import { browserHistory } from 'react-router';

const { TabPane } = Tabs;
const keyToHeader = {
    1: `Tomorrow's`,
    0: `Today's`,
    2: `All`
};

const Component = class extends SuperContainer {
  static displayName = 'dashboard/containers/main'

  constructor(props) {
    super(props);
    this.state = {
      currentPrograms: [],
      defaultValue: [],
      optionRaw: [],
      defaultStartDay: moment(new Date()).startOf('day'),
      defaultEndDay: moment(new Date()).endOf('day'),
      curKey: '0',
      createContainer: false
    };
    this.handleProgramChange = this.handleProgramChange.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.showCreate = this.showCreate.bind(this);
    this.cancelCreate = this.cancelCreate.bind(this);
  }

  componentWillMount() {
    // get program options
    this.adminPrograms = JSON.parse(sessionStorage.getItem('adminProgramList')) || [];
    //Lizzy: Program that has a name starts with _ is testing program, don't show graph for them.
    _.remove(this.adminPrograms,(p)=>_.startsWith(p.name,'_'))
    //console.log("AP______",this.adminPrograms)
    const optionRaw = _.map(JSON.parse(sessionStorage.getItem('adminProgramList')) || [], (prog) => ({
      name : prog.name,
      value : prog.id
    }));

    // set default value
    const defaultValue = optionRaw && optionRaw.length > 0 ? [ optionRaw[0].value ] : [];
    this.setState({ defaultValue, optionRaw, currentPrograms: defaultValue }, () => this.props.selectCurrentProgram(this.state.defaultValue));
    const curUrl = browserHistory.getCurrentLocation();
    if(_.get(curUrl, 'search') === '' && sessionStorage.getItem('backToCurVisit')) {
      sessionStorage.removeItem('backToCurVisit');
    }
  }

  handleProgramChange(val) {
    this.setState({ currentPrograms: val }, () => this.props.selectCurrentProgram(this.state.currentPrograms));
  }

  handleChangeTab(key) {
    let curDate = new Date();
    let nextWeekDayStartOfDay = moment(new Date().setDate(curDate.getDate() + 1)).startOf('day');
    let nextWeekDayEndOfDay = moment(new Date().setDate(curDate.getDate() + 1)).endOf('day');
    while(nextWeekDayStartOfDay.day()==0||nextWeekDayStartOfDay.day()==6){
        nextWeekDayStartOfDay.add(1,'day').startOf('day');
        nextWeekDayEndOfDay.add(1,'day').endOf('day');
    }
      this.setState({
      defaultStartDay: key === '1' ? nextWeekDayStartOfDay : (key=='0' ? moment(new Date()).startOf('day'): moment().subtract(3,'day').startOf('day')),
      defaultEndDay: key === '1' ? nextWeekDayEndOfDay :(key=='0' ? moment(new Date()).endOf('day'): moment().endOf('day')),
      curKey: key
    });
    let text = keyToHeader[key];
    Mixpanel.track('clicked', text + 'visit tab', 'on dashboard', {});
  }

  showCreate(){
    this.setState({
      createContainer: true
    });
    Mixpanel.track('clicked', 'create a new visit', 'on Tomorrow\'s visit page', {});
  }

  cancelCreate(refetch){
    this.setState({
      createContainer: false
    });

    if(refetch){
      const { defaultStartDay, defaultEndDay, curKey } = this.state;
      const curTab = curKey === '0' ? 'Today' : 'Tomorrow';
      const variables = {
        count:99999,
        sortByConfirm: curTab === 'Today' ? false : true,
      }
      const { provider } =  { provider:_.reduce(JSON.parse(sessionStorage.getItem('doctorName')),(res,d) => {
              res.push(d.id)
              return res;
            },[]
        )};
      variables.appointmentFrom = Number(defaultStartDay);
      variables.appointmentTo = Number(defaultEndDay);
      variables.sort = {};
      variables.providerIds= provider;

      Client.query({
        query: visitList,
        variables,
        fetchPolicy: 'network-only'
      })
          .then(res=>{
          })
          .catch(e=>{
            console.log(e);
          })
    }
  }
  renderRangePicker = ()=>{
    const { dateRangeToFilter,defaultStartDay,defaultEndDay } = this.state;
    let startDate = _.get(dateRangeToFilter, '0', defaultStartDay);
    let endDate = _.get(dateRangeToFilter, '1', defaultEndDay);
    return <RangePicker style={{ marginRight:20 }}
                        defaultValue={[startDate, endDate]}
                        onChange={(dateRangeToFilter)=>this.setState({
        dateRangeToFilter
    })}/>
  }
  renderMain(){
    let programLength = this.state.currentPrograms.length;
    const { renderRangePicker } = this;
    const { route, router, routes, curPage, handleChangePage } = this.props;
    const { defaultStartDay, defaultEndDay,curKey,dateRangeToFilter } = this.state;
    const operations = <Row style={{ float:'right',display:'flex' }}>
                        {
                            curKey==2 ?
                            <Col>
                              { renderRangePicker() }
                            </Col>:''
                        }
                        <Col>
                          <Button style={{marginRight:'25px',float:'right'}} type='primary' onClick={this.showCreate}>Create a New Visit</Button>
                        </Col>
                       </Row>;
    const curTab = curKey === '0' ? 'Today' :curKey=='1'?'Tomorrow' :'All';
    const dateString = defaultStartDay.format('MM/DD/YYYY');
    const tomorrowVisitTab = <div>Tomorrow's Visit
                                { curKey ==1 ? <Tooltip title={dateString}><Icon type="info-circle" /></Tooltip>:'' }
                             </div>

    if(programLength) {
      return (
        <div className="vsm-dashboard-page">
          <div className="dashboard-row" style={{flexDirection:'column'}}>
            {this.state.createContainer ? <CreateVisitInDashboardContainer onCancel={this.cancelCreate} curTab={curTab} /> : ''}
            <Tabs defaultActiveKey = '0' tabBarExtraContent={operations} onChange={this.handleChangeTab}>
              <TabPane tab="Today's visit" key="0">
                <AppointmentContainer route={route} router={router} routes={routes}
                                      defaultStartDay={defaultStartDay} defaultEndDay={defaultEndDay}
                                      curPage={curPage} handleChangePage={handleChangePage} curTab={'Today'}/>
              </TabPane>
              <TabPane tab={tomorrowVisitTab} key="1">
                <AppointmentContainer route={route} router={router} routes={routes}
                                      defaultStartDay={defaultStartDay} defaultEndDay={defaultEndDay}
                                      curPage={curPage} handleChangePage={handleChangePage} curTab={'Tomorrow'}/>
              </TabPane>
                <TabPane tab="All Visit" key="2">
                  <AppointmentContainer route={route} router={router} routes={routes}
                                        defaultStartDay={defaultStartDay} defaultEndDay={defaultEndDay}
                                        dateRangeToFilter={dateRangeToFilter}
                                        curPage={curPage} handleChangePage={handleChangePage} curTab={'All'}/>
                </TabPane>
            </Tabs>
          </div>
        </div>
      )
    }
    return (
      <div className="vsm-dashboard-page">
        <div className="dashboard-row">
          {/* <Tabs defaultActiveKey = '0' tabBarExtraContent={operations} onChange={this.handleChangeTab}>
            <TabPane tab="Today's visit" key="0">
              <AppointmentContainer route={route} router={router} routes={routes}
                                    defaultStartDay={defaultStartDay} defaultEndDay={defaultEndDay}
                                    curPage={curPage} handleChangePage={handleChangePage}/>
            </TabPane>
            <TabPane tab="Tomorrow's visit" key="1">
              <AppointmentContainer route={route} router={router} routes={routes}
                                        defaultStartDay={defaultStartDay} defaultEndDay={defaultEndDay}
                                        curPage={curPage} handleChangePage={handleChangePage}/>
            </TabPane>

                                defaultEndDay={defaultEndDay} curPage={curPage} handleChangePage={handleChangePage}/> */}
          {/*<div className="dashboard-column-left">*/}
              {/*<h1>DASHBOARD</h1>*/}
          {/*</div>*/}
          <div className="dashboard-column-right">
            <IHMultiSelect option={this.state.optionRaw}
                           defaultValue={this.state.defaultValue}
                           onChange={ val => this.handleProgramChange(val) } />
              {/*<ProgramSelect />*/}
          </div>
        </div>
        <div className='chartsContainer containerCenterlize noProgramSelContainer'>
           No Program Selected
        </div>
      </div>)
  }

  renderEnrolledStats(programIds, title, className) {
    //program = this.state.currentPrograms;
    return <EnrolledPatientStats currentPrograms={programIds} title={title} className={className} />
  }
  renderProgramMeasureTrend(){
    return <ProgramMeasureTrendsContainer currentPrograms={this.state.currentPrograms} />
  }
  // 12/28/17 - Deprecated for Barbardos
  renderSelectAlerts(){
    return <SelectAlerts />
  }

  renderTodoTable(){
    return (
      <div className="v-left">
        <TodoListTable />
      </div>
    )
  }

  renderTaggedPatientTable() {
    return (
      <div className="v-left">
        <TaggedPatientListTable />
      </div>
    )
  }

  // 12/28/17 - JV - Removed 'Dashboard' breadcrumb for Barbados
  runOnceAfterDataLoading(){
    setPagePath('')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectCurrentProgram: (val) => dispatch(actions.selectCurrentProgram(val))
  }
}

export default createContainer(Component, (state)=>{
  return {
    loading : false,
    ...state.dashboard.main.Todo,
    ...state.dashboard.main.currentProgram
  }
}, mapDispatchToProps);
