import { createContainer, GQLHelper } from 'libModule/utils';
import { scrollToBottom } from 'libModule/helpers/scrollTo';
import {
    React, IHLoading, IHButton, message, _,
} from 'ihcomponent';
import { WeeklyMealSchedule } from 'ihcomponent/ShareCare/WeeklyMealSchedule';
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import dischargeEnrolledProgram from 'modulesAll/graphql/dischargeEnrolledProgram';
import resumeEnrolledProgram from 'modulesAll/graphql/resumeEnrolledProgram';
import resultListMinimal from 'graphqlModule/resultList'
import foodLogUnreadList from "graphqlModule/foodLogUnreadList";

import { graphql } from 'react-apollo';
import EnrolledProgramClass from 'modulesAll/graphql/class/EnrolledProgram';
import Threshold from 'modulesAll/common/components/Threshold';
import PatientProgramTaskListContainer from './PatientProgramTaskListContainer';
import ProgramInfoTab from './ProgramInfoTab';
import DischargeConfirmContainer from './DischargeConfirmContainer';
import SuspendConfirmContainer from '../../main/containers/SuspendConfirmContainer';
import { RequestCache } from 'libModule/utils'
import PatientAlertTableContainer from '../../alertTable/containers/PatientAlertTableContainer'
import PatientVitals from '../../vitalList/containers/main'
import Assessments from '../../assessmentList/containers/PatientAssessmentListContainer'
import AllNotes from '../../../../modules/allNotes/List/containers/ListContainer'
import { openModal, closeModal, openErrorModal } from 'layoutModule/actions/MainModal';
import ReportModal from '../../../report/containers/ReportModalContentContainer'
import { setResuming, setExtendStep, resetExtendValueType } from '../actions/EnrolledProgramInfoActions';
import { providerNotesModalIsVisibleAction, lastCallPatientIdAction } from '../../../call/actions';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { setTab } from '../actions/ProgramInfoTab';
import moment from 'moment';
import { setAsyncRouteLeaveHook } from 'libModule/utils';
import { Tabs, Badge, Modal, Button } from 'antd';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ReactDOM from 'react-dom';

import { LabResults } from 'patientModule/main/containers/LabResults';
import FoodLog from '../../../foodLog/containers/FoodLogContainerWrapper';
import LogBook from '../../../logBook/cotainers/LogBookContainerWrapper';
import MedicineLog from 'modulesAll/medicine/containers/MedicineContainer';
import $ from 'jquery';
import foodLogHelper from '../../../foodLog/helper';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

// import CareplanTab from '../Careplan/containers/CommonContainer';
import VitalList from '../Careplan/containers/VitalListContainer'
// import VitalForm from 'modules/program/ProgramEdit/Careplan/reducers/VitalFormReducer'
// import VitalFormContainer from '../Careplan/containers/VitalFormContainer';
import { convertArraySlotsToMergedWeeklySchedule } from 'modulesAll/utils/helpers/task';

import { compose } from 'recompose';
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import CreateNote from '../../../alerts/InterventionNotes/containers/AddInterventionNoteFormComponentNoReading'
import { goPath } from "../../../../lib/utils";
import { routerWillLeaveHelper, deleteDraft } from '../helper/helpers';
import HSAreaChartContainer from '../../../charts/containers/HSAreaChartContainer';
import BGLineChartContainer from '../../../charts/containers/BGLineChartContainer';
import BPLinChartContainer from '../../../charts/containers/BPLineChartContainer';
import PatientSmartAlertTableContainer from '../../alertTable/containers/PatientSmartAlertTableContainer';
import TaskAssignmentByPatientContainer from '../../../taskAssignment/containers/TaskAssignmentByPatientContainer';

import LabAddAndListContainer from '../../../visitWorkList/container/LabAddAndListContainer';

const TabPane = Tabs.TabPane;

const EnrolledProgramInfoComponent = class extends React.Component {

    static displayName = 'patient/enrolledProgramInfo/containers/EnrolledProgramInfoComponent'
    static propTypes = {
        resuming: PropTypes.bool,
        data: PropTypes.object,
        dischargeEnrolledProgram: PropTypes.func,
        currentProgram: PropTypes.object,
        enrolledProgramId: PropTypes.string,
        refetchEnrolledProgramList: PropTypes.func,
        setResuming: PropTypes.func,
        openModal: PropTypes.func,
        closeModal: PropTypes.func,
        openErrorModal: PropTypes.func,
        resumeEnrolledProgram: PropTypes.func,
        initDevicesStatusTableInfo: PropTypes.func,
        setExtendStep: PropTypes.func,
        resetExtendValueType: PropTypes.func,
        callActions: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            careplanIsDisabled: false,
            labResultIsDisabled: false,
            activeKey: '0',
            enabledField: '',
            // showAddPNModal: false
        }
        this.routerWillLeaveClickOK = this.routerWillLeaveClickOK.bind(this);
        this.index = null
    }

    componentWillMount() {
        this.props.setExtendStep(1);//reset 'extend component' src/modules/patient/programTab/components/DescArea.js
        this.props.resetExtendValueType();// reset input number for days and drop down for week,month
    }

    setCarePlanStatus = (careplanIsDisabled, enabledField) => {
        this.setState({
            careplanIsDisabled,
            enabledField
        })
    }

    setLabResStatus = (labResultIsDisabled)=>{
        this.setState({
            labResultIsDisabled
        })
    }

    componentWillUpdate(nextProps, nextState) {

        if (nextProps.currentProgram && (nextProps.currentProgram.status !== this.props.currentProgram.status)) {
            RequestCache.set('refetch-enrolledProgramList', this.props.refetch());
        }

        if (nextProps.enrolledProgramId !== this.props.enrolledProgramId) {
            this.props.setExtendStep(1); //reset 'extend component' src/modules/patient/programTab/components/DescArea.js
            this.props.resetExtendValueType(); // reset input number for days and drop down for week,month
        }
    }

    routerWillLeaveClickOK() {
        const { updateTabSelected } = this.props;
        this.setState((preState) => ({
            careplanIsDisabled: preState.careplanIsDisabled ? !preState.careplanIsDisabled : preState.careplanIsDisabled,
            labResultIsDisabled: preState.labResultIsDisabled  ? !preState.labResultIsDisabled : preState.labResultIsDisabled
        }), () => updateTabSelected(this.index))

        //leave without saving. delete draft
        const localStorageKey = 'carePlanDraft' + this.props.data.enrolledProgram.id;
        deleteDraft(localStorageKey)
    }

    onChangeTab(index) {
        this.index = index
        const { tabSelected, updateTabSelected } = this.props
        if(this.props.isInChat) {
            Mixpanel.track('clicked',`${tabSelected}_redirect_tag_page`,'message', {PATIENT_ID : this.props.patientId});
        }
        if (this.state.careplanIsDisabled){
            routerWillLeaveHelper(this.routerWillLeaveClickOK)
        }
        else if(this.state.labResultIsDisabled) {
            routerWillLeaveHelper(this.routerWillLeaveClickOK,false,'Lab Result');
        } else {
            updateTabSelected(index);
        }
    }
    mkTabPane = ({ key, name, content }) => {
        const { tabSelected } = this.props;
        const patientProfileWidth = $('.vsm-patient-member-detail').width();
        const style = {
            background: 'white',
            padding: patientProfileWidth <= 800 ? '20px 20px' : '20px 40px',
            marginBottom: '120px'
        }
        return <TabPane tab={name} key={key} style={style} forceRender={true}>
            {key == tabSelected ? content : ''}
        </TabPane>
    };

    renderModal() {
        const ownProps = this.props;
        // const { showAddPNModal } = this.state;
        //lastCallPatientId exit, means modal pop up from Amazon Connect
        //otherwise, Form clicking of button "Add Note"
        //pass lastCallPatientId : ownProps.patientId -> CreateNote
        const { providerNotesModalIsVisible,lastCallPatientName, lastCallPatientId, lastCallStartTime, lastCallEndTime } = this.props;
        var lastCallStartTimeTemp = null;
        var lastCallEndTimeTemp = null;
        var lastCallPatientNameTemp = null;
        if (lastCallPatientId != undefined) {
            lastCallStartTimeTemp = lastCallStartTime;
            lastCallEndTimeTemp = lastCallEndTime;
            lastCallPatientNameTemp = lastCallPatientName;
        }

        const dateFormat = 'MM-DD-YYYY HH:mm';
        return <Modal visible={providerNotesModalIsVisible}
                      className='AddNoteModal'
                      onCancel={() => {
                          this.props.providerNotesModalIsVisibleAction(false);
                          this.props.lastCallPatientIdAction(null);
                          // this.setState({ showAddPNModal: false })
                      }}
                      footer={null}
                      destroyOnClose={true}
                      maskClosable={false}
                >
                    <CreateNote
                        noteType='provider_note'
                        patientId={ lastCallPatientId ? lastCallPatientId : ownProps.patientId}
                        programId={ownProps.currentProgram.id}
                        programName={ownProps.currentProgram.name}
                        canRestUUid={ownProps.canRestUUid}
                        closeModal={() => {
                            this.props.providerNotesModalIsVisibleAction(false);
                            // this.setState({ showAddPNModal: false })
                        }}
                        defaultCategory={ lastCallPatientId == undefined ? null : "CALL_LOG"}
                        patientNameInCall={ lastCallPatientNameTemp}
                        callLogStartTime={ lastCallStartTimeTemp }
                        callLogEndTime={ lastCallEndTimeTemp }
                    />
                </Modal>
    };

    renderNotAuthorizedConfirm() {
        const routeToPatientList = () => goPath('/patients');
        const button = <Button onClick={() => routeToPatientList()}>OK</Button>

        return <Modal visible={true} title={'Not Authorized'} closable={false} footer={button}>
            You are not authorized to view this patient's program, and will route back to patients list
        </Modal>

    }

    render() {
        const { tabSelected, updateTabSelected, patientName, unratedAlerts, unratedFoodLogs, viewerId, patientId, route, setBreadCrumb, openAlertModal, setInitDeviceData, params, isInChat } = this.props;
        const userId = patientId ? R.last(R.split(':', atob(patientId))) : null;
        const { careplanIsDisabled,labResultIsDisabled } = this.state;
        const { props } = this;
        const careName = props.viewerInfo.profile.fullName;
        const currentPatient = RequestCache.get('nurse/currentPatient')
        const identification = _.get(currentPatient, 'identification[0].value')
        if (this.props.data.loading) {
            return <IHLoading className="vsm-patient-enrolledprogram-main" />;
        }
        const displayDate = (ts) => {
            const m = moment(ts);
            if (m.format() === 'Invalid date') return 'N/A';
            return moment(ts).format("MMM Do YYYY");
        }

        const displayDuration = (duration) => {
            const len = _.get(duration, 'length');
            const cal = _.get(duration, 'calendar');
            if (!len || !cal) return 'N/A';
            return `${len} ${cal.toLowerCase()}${len > 1 ? 's' : ''}`;
        }
        // 1/24/18 - JV - For Barbabos project, do not need Patient Daily Tasks component
        const currentProgram = this.props.data.enrolledProgram
        const programObject = new EnrolledProgramClass(currentProgram);
        const { Common, VitalForm, changePage } = this.props
        const program = this.props.data.enrolledProgram
        const commonProps = {
            changePage,
            program
        }

        if (!currentProgram) {
            return <div>
                {this.renderNotAuthorizedConfirm()}
            </div>;
        };
        const shouldHaveBG = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'BG').length != 0;
        const shouldHaveBP = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'BP').length != 0;
        const hasHS = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'HS').length != 0;
        const tabs = [
            {   
                key: 'vitals',
                name: <div>Vitals<Badge count={unratedAlerts} showZero={false} /></div>,
                content: (
                    <Tabs defaultActiveKey = '0'>
                        <TabPane  tab="Log Book View" key="0">
                            <div style={{ backgroundColor: 'white' }} >
                                { shouldHaveBG ? <LogBook patientId={this.props.patientId || this.props.params.patientId} currentProgram={this.props.data.enrolledProgram} type='BG' isInChat={isInChat || false}/> :'' }
                                { shouldHaveBP ? <LogBook patientId={this.props.patientId || this.props.params.patientId} currentProgram={this.props.data.enrolledProgram} type='BP' isInChat={isInChat || false}/> :'' }
                                <PatientSmartAlertTableContainer enrolledProgramId={this.props.enrolledProgramId} patientId={patientId} patientName={patientName} isInChat={isInChat || false}
                                                                careName={careName} identification={identification}/>
                                <PatientAlertTableContainer enrolledProgramId={this.props.enrolledProgramId} patientId={patientId} patientName={patientName} isInChat={isInChat || false} />
                            </div>
                        </TabPane>
                        <TabPane  tab="Chart View" key="1">
                            <div className="vsm-patient-enrolledprogram-main vitalContainer">
                                { shouldHaveBG ?  <BGLineChartContainer enrolledProgramId={this.props.enrolledProgramId} patientId={patientId} isInChat={isInChat || false}/> :'' }
                                { shouldHaveBP ? <BPLinChartContainer enrolledProgramId={this.props.enrolledProgramId} patientId={patientId}  isInChat={isInChat || false}/> :'' }
                                <HSAreaChartContainer currentProgram={this.props.data.enrolledProgram} patientId={this.props.patientId || this.props.params.patientId} hasHS={hasHS}/>
                                {/*<PatientVitals currentProgram={this.props.data.enrolledProgram} patientName={patientName} isInChat={isInChat || false} />*/}
                            </div>
                        </TabPane>
                    </Tabs>
                )
            },
            {
                key: 'programDetails',
                name: 'Care Plan',
                content: (
                    <div className="vsm-patient-enrolledprogram-main">
                        {
                            currentProgram
                                ? <ProgramInfoTab layoutType='vertical'
                                                  data={currentProgram}
                                                  currentProgram={this.props.data.enrolledProgram}
                                                  refreshProgram={this.props.data.refetch}
                                                  refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
                                                  route={this.props.route}
                                                  openModal={this.props.openModal}
                                                  closeModal={this.props.closeModal}
                                                  dischargeEnrolledProgram={this.props.dischargeEnrolledProgram}
                                                  lightEnrolledProgramListRefetch={this.props.lightEnrolledProgramListRefetch}
                                                  location={this.props.location}
                                                  ICD10Code={true}
                                                  manualInput={true}
                                                  setCarePlanStatus={this.setCarePlanStatus}
                                                  careplanIsDisabled={careplanIsDisabled}
                                                  isInChat={isInChat}
                                />
                                : ' no program info'
                        }
                        {/*<Threshold title='Vital Signs & Thresholds' list={programObject.format('thresholdComponentProps')} />*/}
                        {/*<PatientAlertTableContainer enrolledProgramId={ this.props.enrolledProgramId } />*/}
                    </div>
                )
            },
            {
                key: 'providerNotes',
                name: 'Provider Notes',
                content: <div>
                    <AllNotes currentProgram={currentProgram} patientId={patientId} isInChat={isInChat || false} canRestUUid={false}
                              handlePNModal={(flag) => {
                                  this.props.providerNotesModalIsVisibleAction(flag);
                                  // this.setState({ showAddPNModal: flag })
                              }
                              } />
                    <TaskAssignmentByPatientContainer patientId={patientId} isInChat={isInChat || false}/>
                    {/*<MedicineLog patientId = {this.props.patientId||this.props.params.patientId}/>*/}
                </div>
            },
            {
                key: 'foodlog',
                // name: 'Food Diary',
                name: <div>Food Diary<Badge count={unratedFoodLogs} showZero={false} style={{ marginLeft: 5 }} /></div>,
                content: (
                    <div style={{ backgroundColor: 'white' }} className='patientFoodLogTable'>
                        <FoodLog userId={patientId} isInChat={isInChat || false} keepEventListener={true} />
                    </div>
                )
            },
            {
                key: 'labresults',
                name: 'Lab Results',
                content: (
                    <div>
                        {/* <LabResults updateTabSelected={updateTabSelected}
                                    route={this.props.route}
                                    viewerId={viewerId} userId={userId}
                                    labResultIsDisabled={labResultIsDisabled}
                                    setLabResStatus={this.setLabResStatus}
                                    location={this.props.location}
                                    {...props}
                        /> */}
                        <LabAddAndListContainer 
                            userId={userId}
                            route={this.props.route}
                            viewerId={viewerId}
                            labResultIsDisabled={labResultIsDisabled}
                            setLabResStatus={this.setLabResStatus}
                            location={this.props.location}
                            {...props}
                        />

                    </div>
                )
            },
            {
                key: 'medicine',
                name: 'Medication',
                content: (
                    <div>
                        <MedicineLog patientId={this.props.patientId || this.props.params.patientId} />
                    </div>
                )
            }
        ];

        return (
            <div>
                <Tabs activeKey={tabSelected}
                      animated={false}
                      style={{ paddingTop: 20 }}
                    // onTabClick={(i)=>console.log(i)}
                      type="card"
                      onChange={(index) => this.onChangeTab(index)}
                      forceRender={true}
                      className='patient-profile-tabs'
                      ref={(node) => this.ref = node}>
                    {R.map(this.mkTabPane, tabs)}
                </Tabs>
                {this.renderModal()}
            </div>
        )
      }

  clickExtendButton = () => {
    this.props.setExtendStep(2);
    this.props.setTab('programme');
    this.scrollToBottom();
  };

  scrollToBottom() {
    scrollToBottom(600, window);
  }

  renderPatientDailyTask() {
    return (<div><br /><br />
      <PatientProgramTaskListContainer {...this.props} />
    </div>);
  }

  programInfoBtnOnClick = () => {
    this.scrollToBottom();
    this.props.setTab('programme');
  }

  suspendBtnOnClick = () => {
    const modal = <SuspendConfirmContainer
      refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
      currentProgram={this.props.data.enrolledProgram}
    />;

    this.props.openModal(modal, {
      title: 'Suspend Patient ?',
      className: 'discharge-modal',
    });
  }

clickExtendButton = () => {
    this.props.setExtendStep(2);
    this.props.setTab('programme');
    this.scrollToBottom();
};

scrollToBottom() {
    scrollToBottom(600, window);
}

renderPatientDailyTask() {
    return (<div><br /><br />
        <PatientProgramTaskListContainer {...this.props} />
    </div>);
}

programInfoBtnOnClick = () => {
    this.scrollToBottom();
    this.props.setTab('programme');
}

suspendBtnOnClick = () => {
    const modal = <SuspendConfirmContainer
        refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
        currentProgram={this.props.data.enrolledProgram}
    />;

    this.props.openModal(modal, {
        title: 'Suspend Patient ?',
        className: 'discharge-modal',
    });
}

dischargeBtnOnClick = () => {
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    createAuditLog({
        action: I18N.get('auditLog.provider.patient.dischargeProgramme'),
        patientNRIC,
        patientName
    })

    const dischargeConfirm = (
        <DischargeConfirmContainer
            openMadal={this.props.openModal}
            closeModal={this.props.closeModal}
            dischargeProgram={this.props.dischargeEnrolledProgram}
            currentPatient={RequestCache.get('nurse/currentPatient')}
            refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
            currentProgram={this.props.data.enrolledProgram}
            location={this.props.location}
        />
    );

    this.props.openModal(dischargeConfirm, {
        title: 'Discharge Patient ?',
        className: 'discharge-modal',
    });
}

    genNEHRBtnOnClick = () => {
        const p = {
            // title: 'Generate NEHR Report',
            title: 'Generate Report',
            showModal: true,
            showHeaderCloseButton: false,
            body: <ReportModal startDate={this.props.data.enrolledProgram.startDate} reason="Condition change"
                               enrolledProgramId={this.props.data.enrolledProgram.id} enrolledProgramName={this.props.data.enrolledProgram.name} />
            ,
        }
        return this.props.openModal(p.body, p)
    }

    genFullReportBtnOnClick = () => {
        const p = {
            title: 'Generate Full Report',
            showModal: true,
            showHeaderCloseButton: false,
            body: <ReportModal startDate={this.props.data.enrolledProgram.startDate}
                               isFullReport
                               enrolledProgramId={this.props.data.enrolledProgram.id}
                               enrolledProgramName={this.props.data.enrolledProgram.name} />
        };
        this.props.openModal(p.body, p)
    }

    replaceDeviceBtnOnClick = () => {
        this.scrollToBottom();
        this.props.setTab('device');
    }

    returnDeviceBtnOnClick = () => {
        this.scrollToBottom();
        this.props.setTab('device');
    }
};

const addResumeEnrolledProgram = graphql(resumeEnrolledProgram, {
    name: 'resumeEnrolledProgram',
    options: ownProps => ({
        variables: {
            id: ownProps.currentProgram.id
        },
        refetchQueries: ['lightResultsFromToday']
    })
});

const addDischargeEnrolledProgram = graphql(dischargeEnrolledProgram, {
    name: 'dischargeEnrolledProgram',
    options: ownProps => ({
        variables: {
            id: ownProps.enrolledProgramId
        }
    })
});

const addEnrolledProgramGQL = graphql(enrolledProgramGQL, {
    options: ownProps => ({
        variables: {
            id: ownProps.enrolledProgramId,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data }) => { return { data, refetch: data.refetch } },
});

const getAlertVariables = (props) => {
    const { enrolledProgramId } = props;

    return {
        count: 9999,
        filters: {
            severity: ['RISK', 'CRITICAL'],
            careStatus: ["NEW", "HANDLED"],
            alertsOnly: true,
            enrolledProgramId
        }
    }
};

const unratedAlerts = graphql(resultListMinimal, {
    options: ownProps => ({
        variables: getAlertVariables(ownProps),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data }) => {
        return {
            unratedAlerts: _.get(data, 'resultList.pageInfo.total', 0)
        }
    }
});

const getFoodVariables = (props) => {
    const memberId = _.get(props, 'currentProgram.user.id');
    const v = foodLogHelper.getFoodLogVariables({
        count: 9999,
        userId: memberId,
        filter: {
            reviewed: false,
            memberId: memberId
        }
    });
    return v;
}

const unratedFoodLogs = graphql(foodLogUnreadList, {
    options: ownProps => ({
        variables: getFoodVariables(ownProps),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data }) => {
        return {
            unratedFoodLogs: _.get(data, 'foodLogList.pageInfo.total', 0)
        }
    }
});

const mapState = (state, ownProps) => ({
    enrolledProgramId: ownProps.currentProgram.id,
    resuming: state.patient.enrolledProgramInfo.EnrolledProgramInfo.resuming,
    providerNotesModalIsVisible: state.AWSConnect.providerNotesModalIsVisible,
    lastCallPatientId: state.AWSConnect.lastCallPatientId,
    lastCallPatientName: state.AWSConnect.lastCallPatientName,
    lastCallEndTime: state.AWSConnect.lastCallEndTime,
    lastCallStartTime: state.AWSConnect.lastCallStartTime
});

const mapDispatch = {
    setResuming,
    openModal,
    closeModal,
    openErrorModal,
    setTab,
    setExtendStep,
    resetExtendValueType,
    providerNotesModalIsVisibleAction,
    lastCallPatientIdAction,
};

const enhance = compose(
    connect(mapState, mapDispatch),
    addResumeEnrolledProgram,
    addDischargeEnrolledProgram,
    addEnrolledProgramGQL,
    unratedAlerts,
    unratedFoodLogs
);


const EnrolledProgramInfo = enhance(withRouter(EnrolledProgramInfoComponent));

export default EnrolledProgramInfo;
