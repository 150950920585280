import type from '../constants'

export default{
  setSelectAlert : (list, selected)=>{
    const sa = {}
    if(list){
      sa.data = list
    }
    if(selected){
      sa.selected = selected
    }
    return {
      type : type.SET_SELECTALERT_LIST,
      SelectAlerts : sa
    }
  },

  selectCurrentProgram : (select)=>{
    return {
      type : type.SET_CURRENT_PROGRAM,
      select,
    }
  },
  selectCurrentPeriod:(select)=>{
    return {
      type: type.SET_CURRENT_PEROID,
      select,
    }
  },
  reset : ()=>{
    return {
      type : type.RESET,
    }
  }
}
