import type from '../constants'

export default {
    contact: (flag) => {
        return {
            type: type.CONTACT_STATUS,
            payload: flag
        }
    },
    callStatus: (flag) => {
        return {
            type: type.CALL_STATUS,
            payload: flag
        }
    },
    lastCallPatientId: (flag) => {
        return {
            type: type.LAST_CALL_PATIENT_ID,
            payload: flag
        }
    },
    lastCallPatientName: (flag) => {
        return {
            type: type.LAST_CALL_PATIENT_NAME,
            payload: flag
        }
    },
    lastCallStartTime: (flag) => {
        return {
            type: type.LAST_CALL_START_TIME,
            payload: flag
        }
    },
    lastCallEndTime: (flag) => {
        return {
            type: type.LAST_CALL_END_TIME,
            payload: flag
        }
    },

}

export const providerNotesModalIsVisibleAction = (flag)=>({
    type : type.CALL_STATUS,
    payload: flag
});

export const lastCallPatientIdAction = (flag)=>({
    type : type.LAST_CALL_PATIENT_ID,
    payload: flag
});