import React from "react";
import moment from 'moment';
import { Table,Button,Icon,Spin } from 'antd';
import SingleVisitComponent from '../components/SingleVisitComponent';
import types from "../constants/appointmentTypes";
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';
import { IHLoading } from 'ihcomponent'


const weekDayMap = [
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI'
];

const circleStyle = (color)=>({
    width:15,
    height:15,
    backgroundColor:color,
    borderRadius:20,
    marginRight: 15,
})

const style = {
    weekCalenderStyle:{
        paddingLeft:15,
        paddingRight:15,
        display:'flex',
        flexDirection:'column',
        width:'65%'
    },

    mainContainer:{ marginLeft:'auto',marginRight:'auto',marginTop:20 },
    calenderContainer:{ border: '1px solid #d9d9d9', borderRadius: 4, },
    dateStyle:{
        fontSize: 20,
        fontWeight: 600,
        flex:6
    },
    headerStyle:{
        marginTop:10,
        marginBottom:10,
        // marginLeft:25,
        display:'flex',
        flexDirection:'row',
        flex:1
    },
    todayButtonStyle:{
        float: 'right',
        flex:1
    },
    preNextButtonStyle:{
        flex: 1,
        marginLeft:20,
        fontSize: 24
    }
};
const timeSpotCount = 20;
const startTime = 8;
const timeSpotRange = 30;
const timeFormatHalfHourAndAMPM = 'HH:mm';
const timeHeaderRange = (props)=>{
    const start = Number(moment(startTime,'HH').toDate());
    const timeSpots = {};
    for(let i=0;i<timeSpotCount;i++){
           timeSpots[`${moment(start+timeSpotRange*i*60*1000).format(timeFormatHalfHourAndAMPM)}`] = new Array(5);
    }

    return timeSpots;
}
const groupByHelper = (d)=>{
    const timeInHours = moment(d.appointmentAt).minute() < 30 ?
                        moment(d.appointmentAt).millisecond(0).seconds(0).minutes(0).format(timeFormatHalfHourAndAMPM)
                        : moment(d.appointmentAt).millisecond(0).seconds(0).minutes(30).format(timeFormatHalfHourAndAMPM);
    return timeInHours
}


const AppointmentWeekCalenderComponent = class extends React.Component {
    constructor() {
        super();
    }

    renderPreNextButton = ()=>{
        return<div  style={style.preNextButtonStyle}>
                <Icon type="left-square" onClick={()=>{
                    Mixpanel.track('clicked','backward','appointment');
                    this.props.preMonth()}}/>
                <Icon type="right-square" onClick={()=>{
                    Mixpanel.track('clicked','forward','appointment');
                    this.props.nextMoth()
                }}/>
              </div>
    }

    renderTodayButton = ()=> {
        const { setAppointMonthAndYear } = this.props;
        const clickAndSetToToday =()=> {
            Mixpanel.track('clicked','today','appointment')
            setAppointMonthAndYear(moment());
        };

        return <div style={style.todayButtonStyle}>
                <Button onClick = {clickAndSetToToday}>
                Today
                </Button>
               </div>
    }

    renderCreateAppointment = ()=>{
        const { setIsCreate,isEdit,isCreate } = this.props;
        const disabled = isEdit||isCreate;

        return <Button type='primary' onClick={()=>{ Mixpanel.track('clicked','create','appointment'); setIsCreate(true);}}
                       className='scheduleVisitButton'
                       disabled={disabled} style={{backgroundColor:'#4278c3',border:'unset'}}>
                 Create an Appointment
               </Button>
    }

    renderDate(){
        const { props } = this;

        const month = _.get(props,'month','')-1;
        const year = _.get(props,'year','');
        const date = moment([year,month]).format('MMMM/YYYY');
        const parsedMonth = date.split('/')[0];
        const parseYear = date.split('/')[1];

        return <div style={style.dateStyle}>
                { `${parsedMonth} ${parseYear}` }
               </div>
    }

    renderHeader(){

        return <div style={style.headerStyle} className='calenderHeader'>
                { this.renderTodayButton() }
                { this.renderPreNextButton() }
                { this.renderDate() }
                {/*{ this.renderCreateAppointment() }*/}
               </div>

    }

    //parse visit split into each cell with appointmentAt.
    //Base on real visit and create 'fake' visit in cell with appointmentAt time adding 30 mins
    parseVisitList = (list)=>{
        let parsedVisitArray = [];
        for(let v in list){
            const { appointmentAt,appointmentTo } = list[v];
            const visitLength = appointmentTo  ? (appointmentTo-appointmentAt)/1000/60 : 60;

            parsedVisitArray.push({...list[v],index:0,visitStartTime:appointmentAt,last: 0==(Number(visitLength/30)-1)});
            for(let i=1;i<Number(visitLength/30);i++){
                const parsedVisit = {
                    ...list[v],
                    appointmentAt:Number(moment(appointmentAt).add(i*30,'minutes').toDate()),
                    visitStartTime:appointmentAt,
                    index: i,
                    last: i==(Number(visitLength/30)-1)
                }
                parsedVisitArray.push(parsedVisit);
            }
        }
        return parsedVisitArray;
    }

    createDataSourceByVisitList = (dataSource)=>{
        const { visitList,startOfWeek }  = this.props;
        const timeSpots = timeHeaderRange();
        const parsedVisitList = this.parseVisitList([..._.get(visitList,'data',[])]);
        const groupedVisits = _.groupBy(parsedVisitList,groupByHelper);

        for(let v in groupedVisits){
            for(let i in groupedVisits[v]) {
                const vt = groupedVisits[v][i];
                const index = (new Date(vt.appointmentAt)).getDay()-1;
                if(timeSpots[v]) {
                    if(!Array.isArray(timeSpots[v][index])) {
                        timeSpots[v][index] = [];
                    }
                    timeSpots[v][index].push(groupedVisits[v][i]);
                }
            }
        }
        const keys = _.keys(timeSpots);

        for(let i=0;i<keys.length;i++){
            dataSource[i] = {};
            dataSource[i].appointmentAt = keys[i];
            dataSource[i].visitStartTime = keys[i];
            for(let j=0;j<=4;j++){
                dataSource[i][`${weekDayMap[j]}`] = timeSpots[keys[i]][j];
            }
        }

        for(let i=0;i<dataSource.length;i++){
            const { appointmentAt } = dataSource[i];
            for(let key in dataSource[i]){
                if(!dataSource[i][key]){
                    const hourAndMin = appointmentAt.split(':');
                    const aHour = Number(hourAndMin[0]);
                    const aMin = Number(hourAndMin[1]);
                    const dayOffSet = weekDayMap.indexOf(key.toString());
                    dataSource[i][key] = moment(startOfWeek).add(dayOffSet,'day')
                                                            .hours(aHour)
                                                            .minutes(aMin)
                                                            .milliseconds(0).format('MM/DD/YYYY HH:mm');
                }
            }
        }
        //add seq to make UI look better
        let mapIdIndex = {};
        for(let i=0;i<dataSource.length;i++){
            _.forEach(weekDayMap,(w)=> {
                if (Array.isArray(dataSource[i][w])) {
                    const len = dataSource[i][w].length;
                    let preIndex = 0;
                    for (let j = 0; j < len; j++) {
                        const visit = dataSource[i][w][j];
                        if (!mapIdIndex[visit.id]) {
                            dataSource[i][w][j] = _.extend(dataSource[i][w][j], {seq: preIndex});
                            mapIdIndex[visit.id] = preIndex;
                            preIndex++;
                        } else {
                            const visitSeq = mapIdIndex[visit.id];
                            dataSource[i][w][j] = _.extend(dataSource[i][w][j], {seq: visitSeq});
                        }
                    }
                }
            })
        }
        //
        //sort seq
        for(let i=0;i<dataSource.length;i++){
            _.forEach(weekDayMap,w=>{
                if (Array.isArray(dataSource[i][w])) {
                    dataSource[i][w].sort((o1,o2)=>o1.seq-o2.seq);
                }
            })
        }
    }

    createColumn = ()=>{
        const { props } = this;
        const { startOfWeek,setSelectedVisit,selectedVisit } = props;
        const columns = [
            {
                dataIndex:'visitStartTime',
                key:'visitStartTime',
                width:'75px',
                className:'timeSpotClass',
                render:(row)=>{
                    const isFullTime = row.split(':')[1]=='00';
                    if(isFullTime){
                        return row;
                    }
                }
            }
        ]

        _.each(weekDayMap,(w,i)=>{
            const dayOfWeek = moment(startOfWeek).add(i,'day');
            const weekDayInMonth = dayOfWeek.date();
            const className = 'weekdayTitle'+(moment(dayOfWeek).isSame(new Date(),'day') ?
                ' isToday' : moment(dayOfWeek).isBefore(new Date(),'d') ? ' beforeToday' :'');
            columns.push({
                dataIndex:w,
                key:w,
                title:<div className={className}><p>{w}</p><div className='dayOfMonth'>{weekDayInMonth}</div></div>,
                width:'20%',
                className:'colClass',
                rowClassName:'rowClass',
                render:(row,data)=>{
                    if(Array.isArray(row)){
                        return <div style={{ display:'flex',height:27 }}>
                                {_.map(row,(v,i)=><SingleVisitComponent style={{ width:`${(100/row.length)}%` }}
                                                                        visit={v}
                                                                        key={i}
                                                                        setSelectedVisit={setSelectedVisit}
                                                                        selectedVisit={selectedVisit}
                                                                        {...props}
                                                  />)
                                }
                               </div>
                    }else{
                        return <div style={{ display:'flex',height:27 }}>
                                <SingleVisitComponent visit={row} setSelectedVisit={setSelectedVisit}
                                                      selectedVisit={selectedVisit}
                                                      {...props}
                                />
                               </div>
                    }
                }
            })
        })
        return columns;
    }
    renderAppointmentTypes = ()=>{
        return <div style={{display:'flex',flexDirection:'column',padding:'20px 40px'}}>
            <div style={{marginBottom:10,fontSize:16,color:'#717b7d'}}>
                <span>Appointment Types</span>
            </div>
            {
                _.map(types,(k,i)=>
                    <div style={{ display:'flex',alignItems:'center' }} key={i}>
                        <div style={circleStyle(k.color)}></div>
                        <span style={{fontSize:16}}>{ k.text }</span>
                    </div>
                )
            }
        </div>
    }
    renderWeekCalender(){
        const { loading } = this.props;
        const dataSource = new Array(timeSpotCount);
        this.createDataSourceByVisitList(dataSource);
        const columns = this.createColumn(dataSource);

        return <div>
                { this.renderHeader() }
                {
                    <Table dataSource={dataSource} rowKey='appointmentAt'
                        columns={columns} pagination={false}
                        bordered={true}
                        loading={{spinning: loading, indicator: <IHLoading/>}}
                        className='weekVisitTable'
                    />
                }
                { this.renderAppointmentTypes() }
                </div>

    }
    render(){
        return <div>
                 { this.renderWeekCalender() }
               </div>
    }
}

export default  AppointmentWeekCalenderComponent;
