import { graphql } from 'react-apollo';
import moment from 'moment';
import { inventoryReportGQL } from 'modulesAll/graphql/inventory';

export default graphql(inventoryReportGQL, {
  options: ownProps => {
    const { organizationId, viewOption, dateRange: { fromDate, toDate } } = ownProps;
    return {
      variables: {
        fromDate,
        toDate,
        organizationId,
        groupBy: viewOption || 'DAY',
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  },
  props: ({ 
    ownProps: { 
      setDataSource, 
      dateRange, setDateRange,
     }, 
    data: { error, loading, inventoryReport, getInventory, inventoryTransactionList, refetch, fetchMore } 
  }) => {
    // let inventoryError, getInventoryError, inventoryTransactionListError; // to handle error when there is no doc for inventory
    // if (error) { 
    //   if(error.graphQLErrors[0].code === '2000018' 
    //     || !getInventory 
    //     || (error.graphQLErrors[0].code === '0000001'  && _.includes(error.graphQLErrors[0].path, "getInventory"))) { // code: 2000018 - no doc for inventory
    //       inventoryError = [];
    //       getInventoryError = {};
    //       inventoryTransactionListError = {};
    //   } else {
    //     console.log(error);
    //     return null;
    //   }
    // }

    return {
      error,
      loading,
      inventoryReport: inventoryReport || [],
      getInventory: getInventory || {},
      inventoryTransactionList: inventoryTransactionList || {},
      fetchMoreInventoryData: fetchMore,
      refetchInventoryData: (giveOuts, isAfterMutation) => {
        // if(giveOuts) {
        //   const newForecastAmounts = _.assign({}, forecastAmounts);
        //   _.map(giveOuts, giveOut => {
        //     const newAmount = _.get(giveOut, 'quantity');
        //     if(newAmount > 0) {
        //       const productType = _.findKey(mappingDevices, value => value === _.get(giveOut, 'productType'));
        //       const oldAmount = _.get(newForecastAmounts, productType);
        //       _.set(newForecastAmounts, productType, _.sum([oldAmount, newAmount]));
        //     }
        //   })
        //   setForecastAmounts(newForecastAmounts); // when refetch called from Restock and GiveOut actions
        // }
        if(isAfterMutation) setDateRange({...dateRange, toDate: moment().endOf('day').valueOf()}); // prevent not updating the latest after Restock or GiveOut
        setDataSource(undefined);
        refetch().catch(_ => {}); // doing nothing when the error is 'no doc for inventory'
      }
    };
  }
});