import React from "react";
import MyTasksModalComponent from "./MyTasksModalComponent";
import TrackingTaskModalComponent from './TrackingTaskModalComponent';
import { IHSearchTable,IHTable } from 'ihcomponent';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';


let TaskAssignmentTableComponent = class extends React.Component {
    constructor(){
        super();
        this.state = {
            expandIndex:[],

        }
    }
    onRow = (record, rowIndex)=>{
        const { expandIndex } = this.state;
        let res = [];
        Mixpanel.track('clicked', 'task', 'patient task list popup');
        if(!expandIndex.includes(rowIndex)){
            res.push(rowIndex);
        }
        this.setState({
            expandIndex:res
        })
    };

    renderTable = (tasksList,renderBody,columns)=>{
        const { expandIndex } = this.state;
        const currentUserId = sessionStorage.getItem('currentUserId');
        const { currentProgramId,team,setModalData,refetchUser,refetchUserList } = this.props;

        return <IHTable columns = {columns} dataSource={tasksList}
                        onRow={ (record,index)=>({ onClick:()=>this.onRow(record,index) }) }
                        expandedRowKeys={ expandIndex || null }
                        className={`${renderBody ? 'TaskAssignmentTableComponent inPatientTaskTable' :'TaskAssignmentTableComponent'}`}
                        expandedRowRender={(doc)=>{
                            const { createdBy } = doc;
                            const assignerId = _.get(createdBy,'id');
                            const fromTaskList = 'TaskList';
                            if(assignerId==currentUserId){
                                return <TrackingTaskModalComponent modalData={doc} renderBody={renderBody} refetchUser={refetchUser} refetchUserList={refetchUserList}
                                                                   currentProgramId={currentProgramId} team={team} setModalData={setModalData}/>;
                            }

                            return <MyTasksModalComponent modalData={doc} renderBody={renderBody} refetchUser={refetchUser} refetchUserList={refetchUserList}

                                                              currentProgramId={currentProgramId} team={team} setModalData={setModalData}/>;

                            // return assigneeId===currentUserId ?  <MyTasksModalComponent {...props}/>:<TrackingTaskModalComponent { ...props}/> ;
                            // return currentUserId!=id ? <MyTasksModalComponent modalData={doc} renderBody={renderBody} refetchUser={refetchUser}
                            //                                                   currentProgramId={currentProgramId} team={team} setModalData={setModalData}/>
                            //     : <TrackingTaskModalComponent modalData={doc} renderBody={renderBody} refetchUser={refetchUser}
                            //                                   currentProgramId={currentProgramId} team={team} setModalData={setModalData}/>
                        }}
        />
    }

    render(){
        const { props } = this;
        const { taskList,renderBody,columns,enrolledPrograms } = props;

        return this.renderTable(taskList,renderBody,columns,enrolledPrograms);
    }
}

export  default TaskAssignmentTableComponent;
