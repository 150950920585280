import {
  React, IHSearchTable, _, IHCheckbox
} from 'ihcomponent'
import {getRefIDWithRoleCategory} from 'libModule/utils'
import { graphql,compose } from 'react-apollo'
import userListGQL from 'modulesAll/graphql/userList'
import UserClass from 'modulesAll/graphql/class/User'
import CareTeamClass from 'modulesAll/graphql/class/CareTeam'
import actions from '../actions/NewCareTeamActions'
import { connect } from 'react-redux';
import createTableAction from 'libModule/table/TableActions';

const Component = class extends React.Component {
  static displayName = 'careteam/createCareTeam/containers/ManagerTable'

  componentWillUnmount() {
    this.props.onLoadParams(null);
  }

  render(){
    const p = this.getTableProps()
    return (
      <div className="v-table">
        <p>Select Care Managers</p>
        <IHSearchTable {...p} />
      </div>
    )
  }

  changeSelect(id, type, checked){
    const {managers, p_managers} = this.props
    const modified_managers = Object.assign([],managers)
    const modified_p_managers = Object.assign([],p_managers)
    if(checked){
      if(type === 'member'){
        _.remove(modified_managers, (v)=>v===id)
      }
      _.remove(modified_p_managers, (v)=>v===id)
    }
    else{
      if(type === 'primary'){
        modified_p_managers.push(id)
      }
      modified_managers.push(id)
    }

    this.props.changeManager(modified_managers, modified_p_managers)
  }


  getTableProps(){
    const { onTableChange, onTableSearch, onSearchEnter, data, filter, sort, page, search = '' } = this.props;
    const { loading = true, userList } = data;
    return {
      title : `Care Managers (${_.get(userList, 'pageInfo.total', 0)})`,
      columns : [
        {
          title : ' ',
          key : 'row_s',
          width : '40px',
          render : (t, doc)=>{
            const checked = _.includes(this.props.managers, doc._get('id'))
            return <IHCheckbox checked={checked} onChange={this.changeSelect.bind(this, doc._get('id'), 'member', checked)} />
          },
          // comment out reason because we are using paging, each time we only retrieve portion of userList from backend and thus below is not possible to do sort in memory
          // sorter: (a, b) => {
          //   return (_.includes(this.props.managers, a._get('id')) ? 1 : 0) - (_.includes(this.props.managers, b._get('id')) ? 1 : 0)
          // },
          // sortOrder: 'descend'
        },
        {
          title : 'Name',
          key : 'Name',
          render : (t, doc)=>{
            return doc._get('profile.fullName')
          }
        },
        {
          title : 'Specialty',
          key : 'Specialty',
          render(t, doc){
            return doc.format('specialty')
          }
        },
        {
          title : 'Contact Number',
          key : 'Contact Number',
          render(t, doc){
            return doc.format('mobilePhone')
          }
        },
        {
          title : 'Mark as Primary',
          key : 'Mark as Primary',
          render : (t, doc)=>{
            const checked = _.includes(this.props.p_managers, doc._get('id'))
            return <IHCheckbox checked={checked} onChange={this.changeSelect.bind(this, doc._get('id'), 'primary', checked)} />
          }
        }
      ],
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize',5),
        total: _.get(userList, 'pageInfo.total', 0),
      },
      dataSource: _.map(_.get(userList, 'data', []) , (u) => new UserClass(u)),
      inputPlaceholder: 'Search by Name',
      autoFocus: false,
      onSearch: onTableSearch,
      onChange: onTableChange,
      onSearchEnter,
      initSearchValue : search,
      loading
    }
  }

  runOnceAfterDataLoading(np){
    const { teamData } = this.props

    if(teamData){
      const team = new CareTeamClass(teamData)
      const members = team._get('members')

      const managers = []
      _.each(members, (u)=>{
        if(u.role.category === 'PROVIDER' && (u.role.name === 'Nurse' || u.role.name === 'Medical Assistant'))
          managers.push(u.user.id)
      })

      const pm = team.format('primaryManagers')
      const p_managers = _.map(pm, (p)=>p._get('id'))
      np.changeManager(managers, p_managers)
    }
  }

  componentDidMount(){
    this.runOnceAfterDataLoading(this.props);
  }

}

const tableActions = createTableAction('admin_careteam_manager_list');

const withData = graphql(userListGQL, {
  withRef : 'component',
  options : (ownProps)=>{
    return {
      variables : {
        page : _.get(ownProps, 'page.current', 1),
        count : 5,
        filters : {
          roles : [ getRefIDWithRoleCategory('PROVIDER:Nurse'), getRefIDWithRoleCategory('PROVIDER:Medical Assistant')],
          search: {
            fields: ['NAME'],
            match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
          }
        },
        sort : {}
      }
    }
  }
})

const mapDispatchToProp = (dispatch) => {
  return {
    ...tableActions,
    changeManager: (managers, p_managers) => dispatch(actions.changeManager(managers, p_managers)),
  }
}


export default compose(
  connect(
    (state)=>{
      const param = state.Careteam.CareTeamNew
      return {
        ...param,
        ...state.globalTables.admin_careteam_manager_list,
      }
    }
    , mapDispatchToProp),
  withData,
)(Component);
