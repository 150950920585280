import gql from 'graphql-tag';

export default gql`
	query organization($id:EID!) {
		organization(id: $id) {
			id
            name
            identifier
            leaf
            active
            description
            timezone
            parent{
                id
                name
            }
            phone{
                countryCode
                number
            }
			address{
				type
        		streetName
        		streetNumber
        		floor
        		unit
        		city
        		state
        		postCode
        		country{
          			description
          			code
        		}
			}
		}
	}
	
`;