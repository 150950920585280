import React from 'react';
import ProgramUpdateHistoryComponent from '../component/ProgramUpdateHistoryComponent';
import { compose } from 'react-apollo'
import epChangeLogList from '../query/index';
import {graphql} from "react-apollo/index";
import { connect } from 'react-redux'
import createTableAction from 'libModule/table/TableActions';


const eploglistActions = createTableAction('ep_change_log_list');

class ProgramUpdateHistoryContainer extends React.Component{

       render(){
           return <div>
                    <ProgramUpdateHistoryComponent  {...this.props}/>
                  </div>
       }


}
const mapToDispatch = (dispatch)=> {
    return {
        ...eploglistActions
    }
}

const mapToProps = (state,props)=>{
    return {
        ...state.globalTables.ep_change_log_list

    }
}
const withData =  graphql(epChangeLogList,{
    options:(ownProps)=>{
        const { enrolledProgramId } = ownProps;
        const  current  = _.get(ownProps,'page.current',1);
        const docId = btoa(atob(enrolledProgramId).split(':')[1]);

        const variables = {
            filter:{
                enrolledProgramId: docId
            },
            page:current
        }

        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props:({ownProps,data})=>{
        const { loading,epChangeLogList } = data;

        if(loading){
            return {
                loading
            };
        }

        return {
            epChangeLogList,
        }
    }
})

export default compose(connect(mapToProps,mapToDispatch),withData)(ProgramUpdateHistoryContainer);
