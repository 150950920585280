import React from 'react';
import {helpers} from "../helpers";
import VISIT_TYPE_ENUM from "../constants/map";
import moment from "moment/moment";
import {Form} from "antd/lib/index";
import { Button,DatePicker,TimePicker,Input,Select,Checkbox } from 'antd';
import {message} from "../../../../../package/IHComponent";
import { closeModal } from 'layoutModule/actions/MainModal'
import {compose} from "react-apollo/index";
import {connect} from "react-redux";
import createVisitQuery from 'graphqlModule/mutation/createVisit';
import getPatientSmartAlert from 'modulesAll/smartAlert/query/getPatientSmartAlert.js'
import visitList from 'modulesAll/graphql/visitList';
import Client from 'libModule/gqlClient';
import TimerContainer from '../../timer/containers/TimerContainer';
import {API} from "../../timer/query";
import * as timerActions from '../../timer/actions/index';
import I18N from 'modulesAll/I18N';

const { stopTimer,updateInterventionDetails } = timerActions.default;

const FormItem = Form.Item;
const dateFormat = 'MM/DD/YYYY';
const TextArea = Input.TextArea;
const Option = Select.Option;

let CreateAppointmentModal  = class extends  React.Component{
    static displayName = 'createAppointmentModal';

    constructor(){
        super();
        this.state = {
            visitType: null
        }
    }

    onSubmit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((error)=>{
            if(!error){
                this.createVisit();
            }
        })
    }

    changeVisitType(visitType){
        this.setState({ visitType });
    }

    createVisit = ()=>{
        const { patientId,year,currentProgram } = this.props;
        const formVariables  = this.props.form.getFieldsValue();

        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const organizationId = _.get(currentUser,'selectedRole.organization.id');

        const date = formVariables.Date;
        const startTime = formVariables.startTime;
        const endTime = formVariables.endTime;

        const startDate = moment({year}).startOf('year');
        const endDate = moment({year}).endOf('year');

        const combinedStartTime = helpers.combineDateAndTime(date,startTime);
        const combinedEndTime = helpers.combineDateAndTime(date,endTime);

        const variables = {
                            memberId: patientId,
                            type: formVariables.Type,
                            appointmentAt: combinedStartTime,
                            appointmentTo: combinedEndTime,
                            providers: [formVariables.Doctor],
                            organizationId: organizationId,
                            reason: formVariables.Reason,
                            shouldSeeDoctor:formVariables.shouldSeeDoctor,
                            visitRoles: formVariables.visitRoles
                          }
        // if(currentProgram){
        //     variables.programIds = [ currentProgram.id ];
        // }
        Client.mutate({
            mutation: createVisitQuery,
            variables: variables,
            fetchPolicy: 'no-cache',
            refetchQueries:[{
                    query: visitList,
                    variables: helpers.setFromAndToDate({ memberId:patientId,sort:{} }, startDate,endDate),
                    fetchPolicy: 'network-only'
                },
                {
                    query:getPatientSmartAlert,
                    variables:{
                        memberId:patientId
                    },
                    fetchPolicy: 'network-policy',
                }]
        }).then((res)=>{
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'CreateVisit');
            this.props.closeModal();
            message.success('Appointment Scheduled');

        }).catch((error)=>{
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    renderDropDown = ()=>{
        const { getFieldDecorator } = this.props.form;
        const doctorOptions = helpers.filterDoctors();
        const { patientId,patientName,doctor } = this.props;
        const { visitType } = this.state;
        const isInit = (visitType==='INIT');
        let displayName = this.constructor.displayName;
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const doctorIdBySelectedPatient = doctor
        const initDoctorOption = doctorIdBySelectedPatient.id ? doctorIdBySelectedPatient : _.get(_.filter(doctorOptions,d=>d.primary),'0');

        const teamRoles = I18N.get('teamRoles');
        const keepEventListener = false;
        return (<Form className="rescheduleAndCancel">
                <div style={{ color:'#303639' }}>
                    <label>Create Appointment</label>
                    <TimerContainer displayName={displayName} category='VISIT' action='ENGAGE'
                                    viewingDetails={viewingDetails} patientId={patientId}
                                    keepEventListener={keepEventListener}
                    />
                </div>
                <div className='firstRow'>
                    <FormItem label="Patient Name" style={{marginRight:0}}>
                        {getFieldDecorator('Patient',{
                            initialValue:patientId
                        })
                        (
                            <Select disabled={true}>
                                <Option value={ patientId }>{ patientName }</Option>
                            </Select>
                        )}
                    </FormItem>
                </div>

                <div className='modalRow'>
                    <FormItem
                        label="Type"
                    >
                        {getFieldDecorator('Type', {
                            rules: [
                                { required: true, message: 'Please Select Appointment Type'},
                            ],
                        })(
                            <Select onChange={(e)=>this.changeVisitType(e)}>
                                {_.map(VISIT_TYPE_ENUM,(type,val)=>{
                                    return (
                                        <Option value={val} key={val}>{type}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                    {/*<FormItem*/}
                        {/*label="Doctor"*/}
                    {/*>*/}
                        {/*{getFieldDecorator('Doctor')*/}
                        {/*(*/}
                            {/*<Select>*/}
                                {/*{_.map(doctorOptions,(program)=>{*/}
                                    {/*return (*/}
                                        {/*<Option value={program.id} key={program.id}>{program.fullName}</Option>*/}
                                    {/*)*/}
                                {/*})}*/}
                            {/*</Select>*/}
                        {/*)}*/}
                    {/*</FormItem>*/}
                </div>
                <div className='secondRow'>
                    <FormItem label='Clinic Provider' style={{ marginRight:'0px' }} >
                        {getFieldDecorator('Doctor',{initialValue:initDoctorOption.id ||null,rules:[{required:true}]})
                        (
                            <Select filterOption={false}>
                                {_.map(doctorOptions,(program)=>{
                                    return (
                                        <Option value={program.id} key={program.id}>{program.fullName}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem className='seeDoctorCheck' style={{display:'flex',flexDirection:'column',justifyContent:'flex-end',marginLeft:0,marginRight:0}}>
                        {
                            getFieldDecorator('shouldSeeDoctor', {
                                valuePropName: 'checked',
                                initialValue:true
                            })(
                                <Checkbox>See Provider</Checkbox>,
                            )}
                    </FormItem>
                </div>
                <div className='modalRow'>
                    <FormItem label="Care Team">
                        {getFieldDecorator('visitRoles',{
                            rules:[{required:isInit,message:'Care Team is Required'}]
                        })(
                            <Select mode="multiple">
                                {_.map(teamRoles,(type,val)=>{
                                    return (
                                        <Option value={val} key={val}>{type}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                </div>
                <div className='thirdRow'>
                    <FormItem
                        label="Date"
                    >
                        {getFieldDecorator('Date', {
                            rules: [
                                { required: true, message: 'Please Select Date'},
                            ],
                        })(
                            <DatePicker
                                format={dateFormat}
                                placeholder="Select Date"
                            />
                        )}
                    </FormItem>
                    <FormItem
                        label="Start Time"
                    >
                        {getFieldDecorator('startTime', {
                            rules: [
                                { required: true, message: 'Please Select Time'}
                            ],
                        })(
                            <TimePicker
                                format="HH:mm"
                                placeholder="Select Time"
                                use12Hours={false}
                                minuteStep = {30}
                                onChange = {(mom,str)=>{
                                    const { form } = this.props;
                                    const endTime = moment(mom).add(1,'hours');
                                    form.setFieldsValue({
                                        endTime
                                    })
                                }}
                            />
                        )}
                    </FormItem>
                    <FormItem
                        label="End Time"
                    >
                        {getFieldDecorator('endTime', {
                            rules: [
                                { required: true, message: 'Please Select Time'},
                                { validator:(rule,value,callback)=>{
                                        const formVariables  = this.props.form.getFieldsValue();
                                        const { startTime } = formVariables;
                                        if(value && (moment(value).isBefore(startTime)||moment(value).isSame(startTime))){
                                            callback('End time must be latter than start Time');
                                        }
                                        callback();
                                    }
                                }
                            ],
                        })(
                            <TimePicker
                                format="HH:mm"
                                placeholder="Select Time"
                                use12Hours={false}
                                minuteStep = {30}
                                allowEmpty={false}
                            />
                        )}
                    </FormItem>
                </div>
                <div className='forthRow'>
                    <FormItem
                        label="Description (Optional)"
                    >
                        {getFieldDecorator('Reason', {
                            rules: [
                                { required: false },
                            ],
                        })(
                            <TextArea />
                        )}
                    </FormItem>
                    <FormItem>
                        {/*<Button onClick={(e)=>this.onSubmit(e)}>Submit</Button>*/}
                        { this.renderFooter() }
                    </FormItem>
                </div>


            </Form>
        )
    }
    renderFooter = ()=>{

        const baseStyle = {
            margin:5
        }

        let buttonList =   [
            {
                key: 'cancel',
                label: "Cancel",
                type: 'default',
                size:'default',
                style:baseStyle,
                onClick:()=>{
                    this.props.closeModal();
                }
            },
            {
                key: 'submit',
                label: "Save",
                type: 'primary',
                style:baseStyle,
                onClick:(e)=>{
                    this.onSubmit(e)
                }
            }
        ]


        const buttons =   buttonList.map((value,index)=>{
            return (
                <FormItem key={value.label}>
                    <Button className={value.className || ''}  onClick={(e)=>value.onClick(e)}
                            type={value.type} style={value.style}>
                        {value.label}
                    </Button>
                </FormItem>
            )
        });

        return (
            <div className='createButtons'>{buttons}</div>
        )
    }
    render(){
        return <div className='rescheduleAndCancel'>
                {this.renderDropDown()}
               </div>
    }
}
const mapToDispatch = (dispatch)=> {
    return {
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}
CreateAppointmentModal = Form.create()(CreateAppointmentModal);
export default compose(connect(null,mapToDispatch))(CreateAppointmentModal);

