import React from 'react';
import {
  formatFieldLabels,
  renderRadioButton,
  renderDropDown
} from '../../patient/AdditionalInfo/helpers';
import moment from 'moment/moment';
import { programCategoriesMap } from '../../patient/AdditionalInfo/constants/map';
import RenderFormComponent from '../../patient/AdditionalInfo/Components/RenderFormComponent';
import { Form, DatePicker, Checkbox, Row, Button, Input } from 'antd/lib/index';
import { MOTIVATION_ENUM } from '../../patient/profileEdit/constants/profileFieldMap';
import helpers from '../helpers/';
import $ from 'jquery';
import Client from 'libModule/gqlClient';
import userGQL from '../../graphql/user';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';
import { getMutateVariableInfo } from '../../patient/AdditionalInfo/helpers/getMutateValueHelper';
import editUser from 'modulesAll/graphql/mutation/editUser';
import { IHLoading, message } from 'ihcomponent';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import singleVisit from '../../graphql/signleVisit';

const { leaveAndSaveWorkTask } = helpers;
const getProgramCategories = (val, type) => {
  const filteredRes = _.filter(val, k => k.name == programCategoriesMap[type]);
  const enrolledDate = _.get(filteredRes, '[0].enrolledDate', null);
  const programCategory = {
    enrolled: _.get(filteredRes, '[0].enrolled'),
    enrolledDate: enrolledDate ? moment(enrolledDate) : enrolledDate
  };
  return programCategory;
};
const radioMap = {
  motivation: _.map(MOTIVATION_ENUM, i => ({
    value: i,
    name: _.upperFirst(i.toLowerCase())
  })),
  CCMEnrolled: [
    { value: true, name: 'YES' },
    { value: false, name: 'NO' }
  ],
  RPMEnrolled: [
    { value: true, name: 'YES' },
    { value: false, name: 'NO' }
  ]
};
const fieldName = [
  'RPMEnrolled.enrolledDate',
  'CCMEnrolled.enrolledDate',
  'CCMEnrolled.enrolled',
  'RPMEnrolled.enrolled',
  'motivation',
  'ChartA1C',
  'CCM',
  'EMR_Charting'
];
const parseValue = props => {
  const { userId } = props;
  const mutatedValue = getMutateVariableInfo(props);
  let variables = DATE_HELPERS.trimAllValues(mutatedValue, '', mutatedValue);
  variables.id = userId;
  return variables;
};
let ChartingComponent = class extends React.Component {
  renderForm = () => {
    const columnList = () => [
      [
        {
          key: 'CCMEnrolled.enrolled',
          label: formatFieldLabels(
            'CCMEnrolled.enrolled',
            'CCMEnrolled.enrolled'
          ),
          type: 'radioButton',
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
          span: 6,
          path: 'profile.programCategories',
          className: 'radio-button-2-option',
          inputContent: props =>
            renderRadioButton(radioMap['CCMEnrolled'], props),
          onChange: (value, localThis) => {
            const { form } = localThis.props;
            const fieldValue = form.getFieldValue('CCMEnrolled.enrolledDate');
            if (value && !fieldValue) {
              form.setFieldsValue({
                'CCMEnrolled.enrolledDate': moment(new Date())
              });
            }
            if (fieldValue && !value) {
              form.setFieldsValue({
                'CCMEnrolled.enrolledDate': null
              });
            }
            if (!fieldValue && !value) {
              if (
                _.get(
                  form.getFieldError('CCMEnrolled.enrolledDate'),
                  'length',
                  0
                ) > 0
              ) {
                form.setFields({
                  'CCMEnrolled.enrolledDate': {
                    value: null,
                    errors: []
                  }
                });
                $('body')
                  .find('.ccmEnrolledDate')
                  .parent()
                  .parent()
                  .removeClass('has-error');
              }
            }
          },
          formatValue: (data, format) => {
            const res = getProgramCategories(data, 'CCMEnrolled')['enrolled'];
            if (typeof res == 'boolean') {
              return res;
            }
            return true;
          }
        },
        {
          key: 'CCMEnrolled.enrolledDate',
          label: formatFieldLabels(
            'CCMEnrolled.enrolledDate',
            'CCMEnrolled.enrolledDate'
          ),
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
          span: 6,
          placeholder: 'MM/DD/YYYY',
          format: 'MM/DD/YYYY',
          colStyle: { height: 87 },
          className: 'ccmEnrolledDate',
          rules: form => [
            {
              required: form.getFieldValue('CCMEnrolled.enrolled'),
              message: 'CCM Enrolled Date is required'
            }
          ],
          path: 'profile.programCategories',
          inputContent: ({ format, form, className }) => {
            const ccmDateRequired = form.getFieldValue('CCMEnrolled.enrolled');
            return (
              <DatePicker
                disabled={!ccmDateRequired}
                format={format}
                className={className}
              />
            );
          },
          formatValue: (data, format) => {
            const ccmRes = getProgramCategories(data, 'CCMEnrolled')[
              'enrolled'
            ];
            if (typeof ccmRes == 'boolean') {
              return getProgramCategories(data, 'CCMEnrolled')['enrolledDate'];
            }
            return moment();
          }
        },
        {
          key: 'motivation',
          label: 'Motivation',
          type: 'select',
          labelCol: { span: 24 },
          span: 6,
          wrapperCol: { span: 24 },
          col: { span: 24, offset: 0 },
          rules: [{ required: true, message: 'Motivation is required' }],
          inputContent: props => renderDropDown(radioMap['motivation'], props),
          formatValue: (data, format) => data || 'PREPARATION',
          path: 'profile.motivation'
        }
      ],
      [
        {
          key: 'RPMEnrolled.enrolled',
          label: formatFieldLabels(
            'RPMEnrolled.enrolled',
            'RPMEnrolled.enrolled'
          ),
          type: 'radioButton',
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
          span: 6,
          path: 'profile.programCategories',
          className: 'radio-button-2-option',
          inputContent: props =>
            renderRadioButton(radioMap['RPMEnrolled'], props),
          formatValue: (data, format) => {
            const res = getProgramCategories(data, 'RPMEnrolled')['enrolled'];
            if (typeof res == 'boolean') {
              return res;
            }
            return true;
          },
          onChange: (value, localThis, e) => {
            const { form } = localThis.props;
            const fieldValue = form.getFieldValue('RPMEnrolled.enrolledDate');
            if (value && !fieldValue) {
              form.setFieldsValue({
                'RPMEnrolled.enrolledDate': moment(new Date())
              });
            }
            if (fieldValue && !value) {
              form.setFieldsValue({
                'RPMEnrolled.enrolledDate': null
              });
            }

            if (!fieldValue && !value) {
              if (
                _.get(
                  form.getFieldError('RPMEnrolled.enrolledDate'),
                  'length',
                  0
                ) > 0
              ) {
                form.setFields({
                  'RPMEnrolled.enrolledDate': {
                    value: null,
                    errors: []
                  }
                });
                $('body')
                  .find('.rpmEnrolledDate')
                  .parent()
                  .parent()
                  .removeClass('has-error');
              }
            }
          }
        },
        {
          key: 'RPMEnrolled.enrolledDate',
          label: formatFieldLabels(
            'RPMEnrolled.enrolledDate',
            'RPMEnrolled.enrolledDate'
          ),
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
          span: 18,
          colStyle: { height: 87 },
          placeholder: 'MM/DD/YYYY',
          format: 'MM/DD/YYYY',
          className: 'rpmEnrolledDate',
          rules: form => [
            {
              required: form.getFieldValue('RPMEnrolled.enrolled'),
              message: 'RPM Enrolled Date is required'
            }
          ],
          path: 'profile.programCategories',
          inputContent: ({ format, form, className }) => {
            const rpmDateRequired = form.getFieldValue('RPMEnrolled.enrolled');
            return (
              <DatePicker
                disabled={!rpmDateRequired}
                format={format}
                className={className}
              />
            );
          },
          formatValue: (data, format) => {
            const rpmRes = getProgramCategories(data, 'RPMEnrolled')[
              'enrolled'
            ];
            if (typeof rpmRes == 'boolean') {
              return getProgramCategories(data, 'RPMEnrolled')['enrolledDate'];
            }
            return moment();
          }
        }
      ],
      [
        {
          key: 'EMR_Charting',
          label: '',
          inputContent: () => (
            <Checkbox>EMR Charting & Billing Done *</Checkbox>
          ),
          rules: [
            {
              validator: (rule, value, callback) =>
                value ? callback() : callback('Need to check to finish task.')
            }
          ],
          itemStyle: { display: 'flex', flexDirection: 'row' },
          span: 24,
          valuePropName: 'checked',
          path: 'profile.emrList.EMR_Charting'
        },
        {
          key: 'ChartA1C',
          label: '',
          inputContent: value => <Checkbox>Chart A1C in EMR</Checkbox>,
          itemStyle: { display: 'flex', flexDirection: 'row' },
          // rules: [
          //   {
          //     validator: (rule, value, callback) =>
          //       value ? callback() : callback('Need to check to finish task.')
          //   }
          // ],
          span: 24,
          valuePropName: 'checked',
          path: 'profile.emrList.ChartA1C'
        },
        {
          key: 'CCM',
          label: '',
          inputContent: value => <Checkbox>CCM waive copay</Checkbox>,
          itemStyle: { display: 'flex', flexDirection: 'row' },
          span: 24,
          valuePropName: 'checked',
          path: 'profile.emrList.CCM'
        },
        {
          key: 'DOB',
          path: 'profile.birthday',
          inputContent: ({ format }) => (
            <DatePicker format={format} style={{ display: 'none' }} />
          ),
          formatValue: (data, format) => {
            const tz = moment.tz.guess();
            return moment(data).tz(tz);
          }
        },
        {
          key: 'identification',
          path: 'identification[0].value',
          inputContent: props => (
            <Input disabled={true} style={{ display: 'none' }} />
          )
        }
      ]
    ];
    return columnList();
  };

  saveEdit = (flag, setEditMode, msg) => {
    const { userId, toNextStep, setIsEditMode, resetDraftState } = this.props;
    const id = _.get(this, 'props.visit.id');
    const mutatedValue = getMutateVariableInfo(this.props);
    let variables = DATE_HELPERS.trimAllValues(mutatedValue, '', mutatedValue);
    variables.id = userId;
    let refetchQueries = [{ query: userGQL, variables: { id: userId } }];
    if (setEditMode) {
      refetchQueries.push({
        query: singleVisit,
        variables: {
          id
        },
        awaitRefetchQueries: true,
        fetchPolicy: 'network-only'
      });
    }
    Client.mutate({
      mutation: editUser,
      variables: parseValue(this.props),
      refetchQueries
    })
      .then(res => {
        flag ? toNextStep(null, 'Charting', msg) : message.success(msg);
        setIsEditMode(false);
        resetDraftState();
      })
      .catch(e => console.log(e));
  };

  handleOnClick() {
    const { form } = this.props;
    const rpmDateRequired = form.getFieldValue('RPMEnrolled.enrolled');
    const cceRequired = form.getFieldValue('CCMEnrolled.enrolled');
    // console.log('Done!!!', rpmDateRequired, cceRequired)
    Mixpanel.track('clicked', 'done', 'Charting step of RD working step', {
      CCM: cceRequired,
      RPM: rpmDateRequired
    });
    form.validateFieldsAndScroll(fieldName, (error, fields) => {
      if (!error) {
        this.saveEdit(true, false, 'Charting is Saved');
      }
    });
  }

  handleOnClickSaveDraft = () => {
    const { saveEdit } = this;
    saveEdit(false, true, 'Charting Draft is Saved');
  };

  componentWillUnmount() {
    const { props } = this;
    const { setIsEditMode, isEditMode } = props;
    if (isEditMode) {
      setIsEditMode(false);
    }
  }

  render() {
    const { props, renderForm, handleOnClickSaveDraft } = this;
    const { form, user, isEditMode, visit } = props;
    return (
      <div>
        <Row>
          <RenderFormComponent
            user={user}
            form={form}
            config={renderForm()}
            handleEnableEditMode={() => {}}
          />
        </Row>
        <Row style={{ float: 'right' }}>
          {isEditMode ? (
              <Button
                onClick={() => handleOnClickSaveDraft()}
                style={{ width: 130, marginRight: 10 }}
              >
                Save Draft
              </Button>
            ) : (
              ''
          )}
          <Button
            onClick={() => this.handleOnClick()}
            type={'primary'}
            style={{ width: 130 }}
          >
            Complete
          </Button>
        </Row>
      </div>
    );
  }
};
ChartingComponent = Form.create({
  onFieldsChange(props, fields) {
    const isCompleted =
      _.get(props, 'visit.rdWorkingList.Charting') == 'COMPLETED';
    const { userId } = props;
    const id = _.get(props, 'visit.id');

    let refetchQueries = [
      { query: userGQL, variables: { id: userId } },
      {
        query: singleVisit,
        variables: {
          id
        },
        awaitRefetchQueries: true,
        fetchPolicy: 'network-only'
      }
    ];
    const { childProps, setChildProps, isEditMode } = props;
    if (!childProps) {
      setChildProps(props, parseValue, editUser, refetchQueries, 'Charting');
    }
    if (!isEditMode) {
      props.setIsEditMode(true);
    }
    if (isCompleted) {
      leaveAndSaveWorkTask(null, 'Charting', props);
    }
  }
})(ChartingComponent);

export default ChartingComponent;
