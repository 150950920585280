import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { i18nPanel } from 'libModule/helpers/component-helpers'
//files
import BG from './BGContainer'
import HS from './HSContainer'
import BP from './BPContainer'
import HR from './HRContainer'
import TM from './TMContainer'
import PO from './POContainer'
import { changeProgram, changeRange } from '../actions/VitalListWrapperActions'
import { changeRange as changeRangeHS } from '../actions/VitalListHSActions'
import { changeRange as changeRangeBG } from '../actions/VitalListBGActions'
import { changeRange as changeRangeBP } from '../actions/VitalListBPActions'
import { changeRange as changeRangeHR } from '../actions/VitalListHRActions'
import { changeRange as changeRangeTM } from '../actions/VitalListTMActions'
import { changeRange as changeRangePO } from '../actions/VitalListPOActions'
import { graphql, compose } from 'react-apollo'
import { getUserEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList'
import { IHSelect, IHLoading, IHPanel, VSMDateRange } from 'ihcomponent'

const style = {
  panel: {
    margin : '20px',
    borderRadius: '4px'
  },
  select: {
    margin: '20px',
    width: '33%'
  }
}

const withData = graphql(getUserEnrolledProgramList, {
  options: (ownProps) => {
    return {
      variables: {
        page: 1,
        count: 999, //need to get all since there's no pagination
        filters: {},
        sort: {
          field: 'START_DATE',
          direction: 'ASC'
        }
      },
      notifyOnNetworkStatusChange: true,
    }
  },
  props: ({ownProps, data: { refetch, loading, enrolledProgramList }}) => {
    return {
     enrolledProgramList: {data: (_.get(enrolledProgramList, 'data') || []).filter(p => p.status !== 'CREATED')},
     // enrolledProgramList,
     refetch,
     loading
    }
  }

})

const renderDropdown = (list, currProg, onChange) => {
  const pSelect = {
    option: list.data.filter(d => !!d.id)
      .map(d => ({
        name: `${d.name} ${d.status === 'DISCHARGED' ? '(Discharged)' : ''}`,
        value: d.id
      })),
    value: `${currProg.name} ${currProg.status === 'DISCHARGED' ? '(Discharged)' : ''}`,
    onChange,
    size: "large"
  }
  return (
    <div style={style.select}>
      <IHSelect {...pSelect} />
    </div>
  )
}

const renderVitalsPanel = (currProg, dateRangeOnChange, dateRangeValue) => {
  const allTasks = currProg.tasks.reduce((tasks, d) => {
    tasks[d.type] = d.id
    tasks[d.type + '_thresholds'] = d.threshold
    return tasks
  }, {})
  const props = {
    ...allTasks,
    enrolledProgramId: currProg.id,
    startDate: currProg.startDate,
    endDate: currProg.endDate
  }
  // console.log('props ', props);
  const pPanel = {
    header : {
      title: 'Vitals',
      right: <VSMDateRange onChange={dateRangeOnChange} value={dateRangeValue} />
    },
    body: (
      <div>
        { (!!props.BP)? <BP {...props} /> : null }
        { (!!props.BG)? <BG {...props} /> : null }
        { (!!props.HS)? <HS {...props} /> : null }
        { (!!props.HR)? <HR {...props} /> : null }
        { (!!props.TM)? <TM {...props} /> : null }
        { (!!props.PO)? <PO {...props} /> : null }
        {/*<BGDemo {...props} />*/}
      </div>
    )
  }
  return (
    <div style={style.panel}>
      <IHPanel {...i18nPanel(pPanel, 'vitalsPanelAndButtons')} />
    </div>
  )
}

const Main = class extends React.Component {
  static displayName = 'patientView/vitalList/containers/main'

  _onChange = (value) => {
    const data = this.props.enrolledProgramList.data.filter(d => !!d.id)
    this.props.changeProgram(_.findIndex(data, d => d.id === value))
  }
  _changeRangesAll = (range) => {
    this.props.changeRange(range)
    this.props.changeRangeHS(range)
    this.props.changeRangeHR(range)
    this.props.changeRangeBG(range)
    this.props.changeRangeBP(range)
    this.props.changeRangeTM(range)
    this.props.changeRangePO(range)
  }
  componentWillReceiveProps(nextProps) {
    const { data } = nextProps.enrolledProgramList || {}
    if (!!data && data.length > 0) {
      if ((this.props.loading && !nextProps.loading) || (this.props.currProgIdx !== nextProps.currProgIdx)) {
        const currProg = data[nextProps.currProgIdx]
        const startDate = (currProg.startDate) ? moment(currProg.startDate) : null
        let endDate = (currProg.endDate) ? moment(currProg.endDate) : null
        if (!!endDate && new Date() < endDate.toDate()) {
          endDate = moment()
        }
        this._changeRangesAll([startDate, endDate])
      }
    }
  }
  componentWillUnmount() {
    this.props.changeProgram(0)
    const { data } = this.props.enrolledProgramList || {}
    if (!!data && data.length > 0) {
      const currProg = data[0]
      const startDate = (currProg.startDate) ? moment(currProg.startDate) : null
      let endDate = (currProg.endDate) ? moment(currProg.endDate) : null
      if (!!endDate && new Date() < endDate.toDate()) {
        endDate = moment()
      }
      this._changeRangesAll([startDate, endDate])
    }
  }
  render() {
    const { loading, enrolledProgramList } = this.props
    if (loading) {
      return <IHLoading />
    } else {
      if (!enrolledProgramList || enrolledProgramList.data.length === 0)
        return null;
      const currProg = enrolledProgramList.data[this.props.currProgIdx]
      const dateRangeOnChange = (v) => this._changeRangesAll([moment(v[0]), moment(v[1])])
      return (
        <div>
          {renderDropdown(enrolledProgramList, currProg, this._onChange)}
          {renderVitalsPanel(currProg, dateRangeOnChange, this.props.range)}
        </div>
      )
    }
  }
}
const mapState = ({ VitalList: { Wrapper: { range, currProgIdx } } }) => ({
  range,
  currProgIdx
})

Main.propTypes = {
  currProgIdx: PropTypes.number,
  memberId: PropTypes.string,
  changeProgram: PropTypes.func,
  changeRange: PropTypes.func,
  changeRangeHS: PropTypes.func,
  changeRangeHR: PropTypes.func,
  changeRangeBG: PropTypes.func,
  changeRangeBP: PropTypes.func,
  changeRangeTM: PropTypes.func,
  changeRangePO: PropTypes.func,
  loading: PropTypes.bool,
  enrolledProgramList: PropTypes.object,
  range: PropTypes.any
}

const MainWithData = compose(withData,
  connect(mapState, { changeProgram, changeRange, changeRangeHS, changeRangeBG, changeRangeBP, changeRangeHR, changeRangeTM, changeRangePO }))(Main)
export default MainWithData
