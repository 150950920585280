import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { i18nPanel } from 'libModule/helpers/component-helpers'

import moment from 'moment'
//files
import BG from './BGContainer'
import HS from './HSContainer'
import BP from './BPContainer'
import HR from './HRContainer'
import TM from './TMContainer'
import PO from './POContainer'
import { changeProgram, changeRange } from '../actions/VitalListWrapperActions'
import { changeRange as changeRangeHS } from '../actions/VitalListHSActions'
import { changeRange as changeRangeBG } from '../actions/VitalListBGActions'
import { changeRange as changeRangeBP } from '../actions/VitalListBPActions'
import { changeRange as changeRangeHR } from '../actions/VitalListHRActions'
import { changeRange as changeRangeTM } from '../actions/VitalListTMActions'
import { changeRange as changeRangePO } from '../actions/VitalListPOActions'
import { IHPanel, VSMDateRange } from 'ihcomponent'
import $ from 'jquery';
class Main extends React.Component {
  static displayName = 'patient/vitalList/containers/main'
  constructor(){
    super();

  }
  componentWillMount() {
    const currProg = this.props.currentProgram
    const startDate = (currProg.startDate)? moment(currProg.startDate) : null
    let endDate = (currProg.endDate)? moment(currProg.endDate) : null
    if (!!endDate && new Date() < endDate.toDate()) {
      endDate = moment()
    }
    this.props.changeRange([startDate, endDate])
    this.props.changeRangeHS([startDate, endDate])
    this.props.changeRangeBG([startDate, endDate])
    this.props.changeRangeBP([startDate, endDate])
    this.props.changeRangeHR([startDate, endDate])
    this.props.changeRangeTM([startDate, endDate])
    this.props.changeRangePO([startDate, endDate])
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentProgram.id !== nextProps.currentProgram.id) {
      const currProg = nextProps.currentProgram
      const startDate = (currProg.startDate)? moment(currProg.startDate) : null
      let endDate = (currProg.endDate)? moment(currProg.endDate) : null
      if (!!endDate && new Date() < endDate.toDate()) {
        endDate = moment()
      }
      nextProps.changeRange([startDate, endDate])
      nextProps.changeRangeHS([startDate, endDate])
      nextProps.changeRangeBG([startDate, endDate])
      nextProps.changeRangeBP([startDate, endDate])
      nextProps.changeRangeHR([startDate, endDate])
    }
  }
  // componentDidUpdate() {
  //   console.log(this.props.vitalsRange)
  // }
  render() {
    const currProg = this.props.currentProgram;
    const patientName = this.props.patientName;
    const { isInChat }= this.props;
    const allTasks = currProg.tasks.reduce((tasks, d) => {
      tasks[d.type] = d.id
      tasks[d.type + '_thresholds'] = d.threshold
      return tasks
    }, {});
    const profile = _.get(currProg,'user.profile',{});
    const patientId = this.props.currentProgram && this.props.currentProgram.user.id;
    const props = {
      ...allTasks,
      enrolledProgramId: currProg.id,
      startDate: currProg.startDate,
      endDate: currProg.endDate,
      patientId:patientId,
      patientName:patientName,
      isInChat,
      profile
    }
    const pPanel = {
      header: {
        title: 'Vitals',
        right: <VSMDateRange onChange={v => {
          this.props.changeRange([moment(v[0]), moment(v[1])])
          this.props.changeRangeHS([moment(v[0]), moment(v[1])])
          this.props.changeRangeBG([moment(v[0]), moment(v[1])])
          this.props.changeRangeBP([moment(v[0]), moment(v[1])])
          this.props.changeRangeHR([moment(v[0]), moment(v[1])])
        }}
                             value={this.props.range}/>
      },
      body: (<div>
        { (!!props.BP) ? <BP {...props} /> : null }
        { (!!props.BG) ? <BG {...props} /> : null }
        { (!!props.HS) ? <HS {...props} /> : null }
        { (!!props.HR) ? <HR {...props} /> : null }
        { (!!props.TM) ? <TM {...props} /> : null }
        { (!!props.PO) ? <PO {...props} /> : null }
      </div>)
    }
    //   const patientProfileWidth = $('.vsm-patient-member-detail').width();
    // console.log(patientProfileWidth);
      const style = {
          // margin : patientProfileWidth&&(patientProfileWidth<=800) ? '0px':'20px',
          margin:'0px',
          borderRadius: '4px'
      }
    return (
      <div>
        <div style={ style }>
          <IHPanel {...i18nPanel(pPanel, 'vitalsPanelAndButtons')} />
        </div>
      </div>
    )
  }
}
const mapState = ({ VitalListForProvider: { Wrapper: { range } }}) => ({
  range
})

Main.propTypes = {
  currProgIdx: PropTypes.number,
  memberId: PropTypes.string,
  changeProgram: PropTypes.func,
  changeRange: PropTypes.func,
  changeRangeHS: PropTypes.func,
  changeRangeBG: PropTypes.func,
  changeRangeBP: PropTypes.func,
  changeRangeHR: PropTypes.func,
  changeRangeTM: PropTypes.func,
  changeRangePO: PropTypes.func,
  loading: PropTypes.bool,
  enrolledProgramList: PropTypes.object,
  range: PropTypes.any
}

const MainWithData = connect(mapState, { changeProgram, changeRange, changeRangeHS, changeRangeBG, changeRangeBP, changeRangeHR, changeRangeTM, changeRangePO })(Main)
export default MainWithData
