import React from 'react';
import { Upload,Icon } from 'antd';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

export default class extends React.Component {
    constructor(){
        super();
        this.state = {
            focused :false
        }
    }


    renderUpload(){
        const { beforeUpload,removeImg,bottom } = this.props;

        const props = {
            onRemove: () => {
                removeImg();
            },
            onChange: ({file}) => {
                beforeUpload(file.originFileObj);
                return false;
            },

            accept:".jpg,.png",
            fileList:[]
        };

        return <div style={{ position:'absolute',bottom }} className='uploadImg' >
               <Upload {...props}>
                    <span onClick={()=>Mixpanel.track('clicked','attach_image_button','message')}><img src="image/attach.png" width={ 14 }  /></span>
               </Upload>
              {/*{ file ? <Icon type="upload" onClick={ this.handleUpload }/> : '' }*/}
        </div>

    }

    render(){
        return this.renderUpload();
    }
}
