import { React, BaseComponent, _ } from 'ihcomponent';
import PropTypes from 'prop-types';
import I18N from 'modulesAll/I18N';
import { renderRangeValueResult } from 'libModule/helpers/component-helpers';
import { HR_UNIT, BP_UNIT, WEIGHT_UNIT, BG_DISPLAY_UNIT,VITAL_DISPLAY_UNIT_MAP } from 'modulesAll/utils/constants/task';
import { convertBG_mmolTomgdl, convertBG_mgdlTommol } from 'libModule/utils/convertUnits';

const Component = class extends BaseComponent{
    static displayName = 'Threshold'

    render() {
        return (
            <div className="vsm-common-threshold">
                {this.renderTitle()}
                <div className="v-body v-thld">
                    {this.renderBody()}
                </div>
            </div>
        )
    }

    renderTitle() {
        const title = this.props.title
        if(!title) return null
        return (
          <div className="v-header">
              <p className="v-tlt">{title}</p>
          </div>
        )
    }

    renderBody() {
        //check if have data[1]
        let f = false
        console.log('threshold props list', this.props.list)
        if(this.props.list.length < 2 && this.props.list.length > 0 && !this.props.list[0].data[1]){
            f = true
        }

        return (
            <div>
                {this.renderDataHeader(f)}
                {this.renderDataBody()}
            </div>
        )
    }

    renderDataHeader(flag) {
        const hh = (
          <div className="v-h-long">
                <span>Critically Low</span>
                <span>Low</span>
                <span>Normal</span>
                <span>High</span>
                <span>Critically High</span>
            </div>
        )

        return (
            <div className="v-d-header v-d-margin">
                <div className="v-h v-h1"></div>
                <div className="v-h v-h2"></div>
                <div className="v-h v-h3">{hh}</div>
                {flag || <div className="v-h v-h4"></div>}
                {flag || <div className="v-h v-h5">{hh}</div>}
            </div>
        )
    }

    renderDataBody() {
        const data = this.props.list
        return (
            <div className="v-d-body">
                {
                    _.map(data, (item, index)=>{
                        return this.renderEachData(item, index)
                    })
                }
            </div>
        )
    }

    renderEachData(item, index) {
        if(!item.data.length) { //do not display if it's assessment
          return ''
        }
        let miu = 0.1
        let isWeight = false
        let baselineWeight
        let isBaseLine = item.isBaseline
        let convertedRange1
        let convertedRange2

      if(item.unit === BP_UNIT || item.unit === HR_UNIT) {
          miu = 1
        } else if (item.unit === WEIGHT_UNIT) {
          miu = 0
          isWeight = true
          baselineWeight = item.baselineWeight
        }

        // if BG item unit is different from display unit
        if (item.name === 'Blood Glucose' && item.data.length > 0 && item.unit.toLowerCase() !== BG_DISPLAY_UNIT.toLowerCase()) {
          convertedRange1 = item.data[0].range ? item.data[0].range.map( val => {
            return BG_DISPLAY_UNIT ===  I18N.get('measure.units_BG_UK_standard')? convertBG_mgdlTommol(val) : convertBG_mmolTomgdl(val);
          }) : [];
          convertedRange2 = item.data[1].range ? item.data[1].range.map( val => {
            return BG_DISPLAY_UNIT ===  I18N.get('measure.units_BG_UK_standard')? convertBG_mgdlTommol(val) : convertBG_mmolTomgdl(val);
          }) : [];
        }
        else {
          convertedRange1 = _.get(item, 'data.0.range') ? item.data[0].range : [];
          convertedRange2 = _.get(item, 'data.1.range') ? item.data[1].range : [];
        }

        return (
            <div key={index} className="v-d-each">
                <div className="v-h v-h1">
                    <b>{(isWeight && !isBaseLine)? 'Weight Change' : item.name}</b>
                    <br/>
                    <span>
                      ({
                      VITAL_DISPLAY_UNIT_MAP[item.name]
                      })
                    </span>
                </div>
                <div className="v-h v-h2">
                    {item.data[0].name}
                </div>
                <div className="v-h v-h3">
                    {renderRangeValueResult(convertedRange1, miu, isWeight, baselineWeight, isBaseLine)}
                </div>
                {item.data[1]?
                <div className="v-h v-h4">
                    {item.data[1].name}
                </div> : null }
                {item.data[1]?
                <div className="v-h v-h5">
                    {renderRangeValueResult(convertedRange2, miu)}
                </div> : null }
            </div>
        )
    }

    renderNumber(num) {
      return Math.round(num * 100) / 100
    }
}

Component.propTypes = {
    /*
    {
        name : 'Blood Glucose',
        unit : 'mmol/L',
        data : [
            {
                name : 'Before Meal',
                range : [
                    3.11, 3.89, 5, 6.89
                ]
            },
            {
                name : 'After Meal',
                range : [
                    3.11, 3.89, 5, 6.89
                ]
            }
        ]
    }
     */
    list : PropTypes.array.isRequired,
    title : PropTypes.string
}

export default Component
