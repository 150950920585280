import gql from "graphql-tag";

export default gql `
    query User($id: EID!) {
        user(id: $id) {
          id
          team{
            members{
                user{
                    id
                    allRoles {
                    organization {
                      id
                      notePreset {
                        category
                        display
                        subCategories
                      }
                     }
                    }
                    profile{
                       firstName
                       lastName
                       avatar{
                        link
                      }
                    }
                }
            }
          }
        }
    }
`;
