import {
  React, IHSearchTable, IHButton, _
} from 'ihcomponent'
import { renderStatus } from '../../../patientView/myAssessmentAndSurvey/utils'
import { formatSchedule } from 'modulesAll/utils/helpers/task'
import { TASK_TYPE_MAP } from 'modulesAll/utils/constants/task'
import moment from 'moment'
import { Icon } from 'antd'
import I18N from 'modulesAll/I18N'
import PreviewInnerContainer from '../../../patientView/myAssessmentAndSurvey/preview/containers/PreviewInnerContainer'

const Component = class extends React.Component{
  static displayName = 'patient/asssessmentList/components/PatientAssessmentListTableComponent'

  // componentDidUpdate() {
  //     const data = this.props.userList
  //       this.props.dispatch(modalAction.openErrorModal(data.error.message))
  //     }
  // }
  componentWillUnmount() {
    // this.props.setSearchVariables(null)
    // this.props.resetTable()
  }
  render(){
    return (
      <div>
        {this.renderTable()}
      </div>
    )
  }
  onRowClick=(row) =>{
    const p = {
      title: row.type.toTitleCase(),
      showModal: true,
      showHeaderCloseButton: true,
      size: 'lg',
      body: <div className="assessments-nurse-preview">
        <PreviewInnerContainer id={_.get(row, 'assessmentFilled._id') || _.get(row,'assessment._id')}
                               isAssessment={!_.get(row, 'assessmentFilled._id')}
        />
      </div>
    };
    return {
      onClick: () => {
        this.props.openModal(p.body, p)
      }
    }
  }
  getTableProps(){
    const { sort, pagination, onTableChange, variables, data = [], loading, onAddClick } = this.props
    return {
      title : `${I18N.get('keywords.Assessments')} & Surveys (${data.length})`,
      inputPlaceholder : 'Search by Name',
      initSearchValue : '',
      onRow: this.onRowClick,
      // dataSource : _.map(list, (l)=>new UserClass(l)),
      dataSource : data,
      pagination,
      loading,
      rowKey: 'id',
      columns : [
        {
          title: 'Date / Time',
          key: 'date',
          dataIndex: 'date',
          sorter: (a, b) => a.date - b.date,
          render: (item, row) => moment(item).format('MMM-DD-YYYY hh:mm a')
        },
        {
          title: 'Status',
          key: 'taskStatus',
          dataIndex: 'taskStatus',
          render: renderStatus,
          filters: [
            { value: 'MISSED', text: 'Missed' },
            { value: 'COMPLETED', text: 'Completed' }
          ],
          onFilter : (a, doc)=>{
            return a === doc.taskStatus
          }
        },
        {
          title: 'Type',
          key: 'type',
          dataIndex: 'type',
          // render: renderType,
          filters: [
            { value: 'ASSESSMENT', text: I18N.get('keywords.Assessment') },
            { value: 'SURVEY', text: 'Survey' }
          ],
          onFilter : (a, doc)=>{
            return a === doc.type
          },
          render: (item, row) => {
            const classNames = [];
            classNames.push('icon-assessment');
            classNames.push('available');
            return <div className={classNames.join(' ')}>{TASK_TYPE_MAP[item]}</div>;
          },
        },
        {
          title : 'Name',
          key : 'assessment.name',
          dataIndex: 'assessment.name',
          render: (name) => name,
          //sorter: (a, b) => sortStringAlphabetical(a.name, b.name),
        },
        {
          title: 'Schedule',
          dataIndex: 'schedule',
          key: 'schedule',
          render: (a, row) => formatSchedule(row),
        },
        {
          title: 'Notes',
          key: 'notes',
          dataIndex: 'notes',
          width: '8%',
          render: (notes, row) => {
            if (notes && notes.length > 0) {
              return <div onClick={(e)=>{
                e.stopPropagation()
                onAddClick(row)
              }} style={{cursor: 'pointer'}}><Icon type="file-text"/></div>
            }
            const buttonProps = {
              label: 'Add >',
              size: 'small',
              bsStyle: 'primary',
              onClick: (e) => {
                e.stopPropagation()
                onAddClick(row)
              },
              className: 'table-action-button'
            }
            return <IHButton {...buttonProps} />
          }
        }

      ],
    }
  }

  renderTable(){
    const p = this.getTableProps()

    return <IHSearchTable {...p} />
  }

}


export default Component
