const deleteConfirmContent = (vitalType,fullName)=>
`This is an irreversible action. Are you sure you want to delete ${vitalType.value} for patient ${fullName}?`;

export default {
    deleteVital:{
        confirm:{
            content:deleteConfirmContent,
            title:'Delete Vital?'
        },
        success:'Vital deleted successfully.'
    }
}
