import { combineReducers } from 'redux'
import type from '../constants'
import {_} from 'ihcomponent'


const initialState = {
  SelectAlerts : {
    data : [],
    selected : ['a','a1','a2','a3','a4']
  },
  Todo : {
    modal : {
      show : false,
      select : null
    }
  },
  currentProgram : null,
  currentPeriod: 'MONTH'
}

const reducer = (state=initialState, action)=>{
  switch(action.type){
    case type.SET_SELECTALERT_LIST:
      const sa = _.merge({}, state.SelectAlerts, action.SelectAlerts)
      return {
        ...state,
        SelectAlerts : sa
      }
    case type.SET_CURRENT_PROGRAM:
      return {
        ...state,
        currentProgram : action.select
      }
      case type.SET_CURRENT_PEROID:
        return{
            ...state,
            currentPeriod:action.select,
      }
      case type.GET_MEASURE_DATA:
        return {
            ...state,
            programData: getMeasureData(action.peroid)
        }

      case type.RESET:
        return {
        ...state,
        Todo : {
          modal : {
            show : false,
            seelct : null
          }
        }
      }
    default:
      return state
  }
}

export default{
  dashboard : combineReducers({
    main : reducer
  })
}
