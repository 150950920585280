// provider
// 9/11/17 - JV - AlertBadge deprecated per UX direction (Ted)
// import AlertBadge from 'modulesAll/alerts/Badge/containers/AlertBadgeContainer'
import { React } from 'ihcomponent';
import I18N from 'modulesAll/I18N';
import * as R from 'ramda';
import FaFlask from 'react-icons/lib/fa/flask';
import { Icon } from 'antd';

const iconStyle = { fontSize: 24, color: 'white', marginRight: 6 };
const menus = {
  dashboard: {
    id: 'dashboard',
    title: 'Dashboard',
    // icon: 'dashboard',
    icon: 'bar-chart',
    href: '/dashboard',
    class: 'dashboard'
  },
  Appointments: {
    id: 'appointments',
    title: 'Appointments',
    src: 'image/appointments.svg',
    href: '/appointments',
    class: 'appointments'
  },
  Inventory: {
    id: 'inventory',
    title: 'Inventory',
    src: 'image/inventory.png',
    href: '/inventory',
    class: 'inventory'
  },
  MyTasks_Provider: {
    id: 'tasks',
    title: 'My Tasks',
    // icon: 'mytasks',
    icon: 'tasks',
    href: '/tasks',
    class: 'tasks'
  },
  SmartAlerts: {
    id: 'smartalert',
    title: 'CDE Working List',
    href: '/smartalert',
    src: '/image/nav_cdelist.png',
    class: 'smartAlert'
  },
  Chat: {
    id: 'messages',
    title: 'Messages',
    href: '/messages',
    src: '/image/chat-icon.svg',
    class: 'messages'
  },
  Food_Log: {
    id: 'foodlog',
    title: 'Food Diary',
    src: 'image/food-diary-icon.svg',
    href: '/foodlog',
    class: 'foodlog'
  },
  TempEnrolledPatients: {
    id: 'tempenrolledpatients',
    title: 'MA Working List',
    href: '/tempenrolledpatients',
    src: '/image/nav_malist.png',
    class: 'tempenrolledpatients'
  },
  Patients: {
    id: 'patients',
    title: 'Patients',
    // icon: 'patients',
    // icon: 'person',
    src: '/image/patients-nav-icon.svg',
    href: '/patients',
    class: 'patients'
  },
  TaggedPatients_Provider: {
    id: 'tagged_patients',
    title: 'Tagged Patients',
    //icon: 'taggedpatients',
    // icon: 'star',
    src: 'image/taggedpat-nav-icon.svg',
    href: '/tagged_patients',
    class: 'tagged_patients'
  },
  InactivePatients: {
    id: 'inactive_patients',
    title: 'Inactive Patients',
    href: '/inactive_patients',
    src: '/image/inactivepat-nav-icon.svg',
    class: 'inactive_patients'
    // src: '/image/inactive-patient-icon2.svg'
  },
  NurseAssessment: {
    id: 'nurse_assessments',
    title: I18N.get('keywords.Assessments'),
    // icon: 'assessments',
    icon: 'assessment',
    href: '/nurse_assessments',
    class: 'nurse_assessments'
  },
  NurseSurvey: {
    id: 'nurse_surveys',
    title: 'Surveys',
    // icon: 'surveys',
    icon: 'survey',
    href: '/nurse_surveys',
    class: 'nurse_surveys'
  },
  Programmes: {
    id: 'programmes',
    title: `${I18N.get('keywords.Programs')}`,
    src: '/image/programs-nav-icon.svg',
    // icon: <Icon type="solution" style={R.merge(iconStyle, { marginRight: 3 })} />,
    href: '/programmes',
    class: 'programmes'
  },
  Alerts: {
    id: 'alerts',
    title: 'Alerts',
    // icon: 'alerts',
    icon: 'bell',
    href: '/alerts',
    class: 'alerts'
    // Badge: <AlertBadge />
  },
  TaskAssignments: {
    id: 'TaskAssignments',
    title: 'Task List',
    href: '/taskAssignments',
    src: 'image/taggedpat-nav-icon.svg'
  }
};
const nurseArray = [
  'dashboard',
  'Appointments',
  'Inventory',
  'TaskAssignments',
  'SmartAlerts',
  'Chat',
  'Food_Log',
  'Patients',
  'InactivePatients',
  'Alerts',
  'TempEnrolledPatients'
];

const maArray = [
  'dashboard',
  'Appointments',
  'Inventory',
  'TaskAssignments',
  'TempEnrolledPatients',
  'Chat',
  'Food_Log',
  'Patients',
  'InactivePatients',
  'Alerts',
  'SmartAlerts'
];

const doctorArray = [
  'dashboard',
  'Chat',
  'Alerts',
  'Patients',
  'TaskAssignments'
  // 'TaggedPatients_Provider',
];

const nurseMenusArray = R.map(R.flip(R.prop)(menus), nurseArray);
const doctorMenusArray = R.map(R.flip(R.prop)(menus), doctorArray);
const maMenusArray = R.map(R.flip(R.prop)(menus), maArray);
const menusArray = {
  doctor: doctorMenusArray,
  nurse: nurseMenusArray,
  medicalAssistant: maMenusArray
};
export default menusArray;
export const HOMEPAGE = '/dashboard';
