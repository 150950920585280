import React from "react";
import Main from './main';


class mainWrapper extends React.Component {
    render(){
        const { props } = this;
        return <Main {...props}/>
    }
}

export default mainWrapper;
