import React from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { openModal, closeModal } from 'layoutModule/actions/MainModal';
import createTableAction from 'libModule/table/TableActions';
import { graphql } from 'react-apollo/index';
import medicationList from 'modulesAll/graphql/medicationList';
import editMedication from 'graphqlModule/mutation/editMedication';
import Client from 'libModule/gqlClient';
import getVariables from '../../medicine/query/variables';
import moment from 'moment/moment';
import { IHSearchTable, IHLoading, IHButton, _, IHInput } from 'ihcomponent';
import { Button, Icon, message, Modal } from 'antd';
const medicinelistActions = createTableAction('medicine_list');
const enumToTyep = {
  ORAL: 'Oral Medication',
  INJECTABLE: 'Injectable',
  EXTERNAL: 'External',
  INHALED: 'Inhaled'
};

const statusEnumToType = {
  NOT_TAKING: 'Not Taking',
  NOT_SURE: 'Not Sure',
  TAKING: 'Taking'
};
const MedicineListComponent = class extends React.Component {
  static displayName = 'FoodLogContainer';

  removeMed = (medId) => {
    let variables = {
        id: medId,
        memberId: _.get(this, 'props.patientId'),
        active:false
    }

    Client.mutate({
      mutation: editMedication,
      variables: variables,
    }).then(res => {
      console.log(res);
      message.success('Medication Removed');
      this.props.refetchMedicationList();
    }).catch(err => {
      console.log(err);
      message.error('Failed to remove medication');
    })
  }

  renderCurrentMedList = (tableProps, noDataElem) => {
    let { dataSource } = tableProps;
    dataSource = _.filter(
      dataSource,
      d => !d.endDate || d.endDate >= new Date().getTime()
    );
    tableProps.columns = tableProps.getColumns();
    tableProps.columns.push({
      title: '',
      render: (_, record) => 
        <Icon 
          style={{fontSize: 16}} 
          type='close-circle' 
          onClick={() => {
            Modal.confirm({
              title: 'Are you sure you want to delete this medication?',
              content:"Once it’s deleted, this medication will not show in the medication history.",
              okText: 'Yes',
              okType: 'danger',
              cancelText: 'No',
              zIndex:2000,
              mask:true,
              className:'deleteMedModule',
              maskClosable:true,
              onOk:()=> this.removeMed(record.id)
            });
          }}
        />,
    });
    tableProps.dataSource = dataSource;
    tableProps.title = 'Current Medications';
    return <IHSearchTable {...tableProps} noDataElem={noDataElem} />;
  };

  renderPreMedList = (tableProps, noDataElem) => {
    let { dataSource } = tableProps;
    dataSource = _.filter(
      dataSource,
      d => d.endDate && d.endDate < new Date().getTime()
    );
    tableProps.dataSource = dataSource;
    tableProps.title = 'Previous Medications';
    tableProps.actions = null;
    tableProps.columns = tableProps.getColumns();
    tableProps.columns.pop();
    tableProps.columns.push({
      title: 'End Date',
      dataIndex: 'endDate',
      render: v => (v ? moment(v).format('MM/DD/YYYY') : ''),
      sorter: (a, b) => a.startDate - b.startDate
    });
    return dataSource.length > 0 ? (
      <IHSearchTable {...tableProps} noDataElem={noDataElem} />
    ) : (
      ''
    );
  };

  renderMedList = () => {
    const {
      loading,
      page,
      medicationList,
      setShowMedModal,
      setEditMedicine
    } = this.props;
    const data = _.get(medicationList, 'data') || [];
    const getColumns = () => [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '15%'
      },
      {
        title: 'Type',
        width: '15%',
        dataIndex: 'type',
        filters: [
          { text: 'Oral Medication', value: 'ORAL' },
          { text: 'Injectable', value: 'INJECTABLE' },
          { text: 'External', value: 'EXTERNAL' },
          { text: 'Inhaled', value: 'INHALED' }
        ],
        onFilter: (value, row) => {
          return row.type == value;
        },
        render: value => {
          return enumToTyep[value];
        }
      },
      {
        title: 'Status',
        width: '10%',
        dataIndex: 'status',
        filters: [
          { text: 'Taking', value: 'TAKING' },
          { text: 'Not Sure', value: 'NOT_SURE' },
          { text: 'Not Taking', value: 'NOT_TAKING' }
        ],
        onFilter: (value, row) => {
          return row.status == value;
        },
        render: value => {
          return statusEnumToType[value];
        }
      },
      {
        title: 'Dosage',
        width: '10%',
        dataIndex: 'dosage'
      },
      {
        title: 'Instruction',
        width: '25%',
        dataIndex: 'instruction'
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        width: '12%',
        render: v => (v ? moment(v).format('MM/DD/YYYY') : ''),
        sorter: (a, b) => a.startDate - b.startDate
      },
      {
        title: '',
        width: '10%',
        render: (curMed, row) => {
          const buttonProps = {
            label: 'Edit',
            size: 'small',
            bsStyle: 'primary',
            onClick: e => {
              setEditMedicine(curMed);
            },
            className: 'table-action-button'
          };
          return <IHButton {...buttonProps} />;
        }
      }
    ];

    let tableProps = {
      getColumns,
      rowKey: 'id',
      loading: { spinning: loading, indicator: <IHLoading /> },
      dataSource: _.isEmpty(data) ? [] : data,
      optionsBox: true
    };

    const noDataElem = (
      <div
        style={{
          paddingTop: '2em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'HelveticaNeue',
          color: '#c5c5c5'
        }}
      >
        <div>
          <img
            src='/image/no-medication-graphic.svg'
            className='no-medication-graphic'
            width='116.6px'
            height='56.1px'
          />
        </div>
        <div style={{ fontSize: '26px', marginTop: 20, marginBottom: 10 }}>
          No Medications
        </div>
        <div style={{ fontSize: '16px', maxWidth: '405px' }}>
          There are no medications recorded for this patient. Click on Add
          Medication to start recording
        </div>
      </div>
    );
    return (
      <div>
        {this.renderCurrentMedList(Object.assign({}, tableProps), noDataElem)}
        <div style={{ height: 20 }}></div>
        {this.renderPreMedList(Object.assign({}, tableProps), noDataElem)}
      </div>
    );
  };
  render() {
    const { handleOnClickSaveDraft } = this;
    const { toNextStep, medicationList, loading, isEditMode } = this.props;
    if (loading) {
      return <IHLoading />;
    }
    const hasCurMed =
      _.filter(
        medicationList.data,
        d => !d.endDate || d.endDate >= new Date().getTime()
      ).length > 0;
    return (
      <div>
        {this.renderMedList()}
        <div style={{ float: 'right', paddingTop: 10 }}>
          {isEditMode ? (
            <Button onClick={() => {}} style={{ width: 130, marginRight: 10 }}>
              Save Draft
            </Button>
          ) : (
            ''
          )}
          <Button
            onClick={() =>
              toNextStep('Medication', null, 'Medication is Saved.')
            }
            type={'primary'}
            style={{ width: 130 }}
          >
            Complete
          </Button>
        </div>
      </div>
    );
  }
};

const withData = graphql(medicationList, {
  options: ownProps => {
    const variables = getVariables(ownProps);
    return {
      variables,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    };
  },
  props: ({ ownProps, data }) => {
    const { loading, medicationList, refetch: refetchMedicationList } = data;
    if (medicationList && medicationList.data) {
      return {
        medicationList,
        refetchMedicationList,
        uniqueMedNames: _.map(
          _.uniqBy(medicationList.data, 'name'),
          m => m.name
        ),
        loading
      };
    }

    return {
      loading
    };
  }
});

const mapToProps = (state, props) => {
  return {
    ...medicinelistActions,
    ...state.globalTables.medicine_list,
    modal: state.MainModal,
    status: state.medicineList.main.status
  };
};

const mapToDispatch = dispatch => {
  return {
    openModal: (content, row) => dispatch(openModal(content, row)),
    closeModal: () => dispatch(closeModal())
  };
};

MedicineListComponent.displayName = 'MedicineListComponent';
export default compose(
  connect(mapToProps, mapToDispatch),
  withData
)(MedicineListComponent);
