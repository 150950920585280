import React from 'react';
import { Input,Row,Col,Form,Button } from 'antd';
import moment from 'moment';
import { validator } from "../helper/validator";
import TimerContainer from '../../timer/containers/TimerContainer';

const { TextArea } = Input;
const style = {
    fullNameStyle:{
        fontSize:16,
        fontWeight: 'bold'
    },
    timeStampStyle:{
        color: '#aaaaaa'
    }

}
let PostItEditComponent = class extends React.Component{
    constructor(){
        super();
    }

    renderHeader=(createdAt)=>{
        const createdAtString = moment(createdAt).format('MM/DD/YYYY, HH:mm')
        const header = 'Edit Post-It';
        return<Row style={{ margin:10 }}>
            <Col span={12}><span style={style.fullNameStyle}>{header}</span></Col>
            <Col span={24}><span style={style.timeStampStyle}>{createdAtString}</span></Col>
        </Row>
    }

    handleDoneButton = async (note,id)=>{
        const { editPostId,form,setNote,setPost } = this.props;
        const res = await editPostId({note,id});
        if(res) {
            setNote('');
            setPost({ });
            form.resetFields();
        }
    }

    handleCancelButton = ()=>{
        const { setShowEditComponent,setNote,setPost,form } = this.props;
        setShowEditComponent(false);
        setNote('');
        setPost({});
        form.resetFields();
    }

    renderButton=()=>{
        const { note,post } = this.props;
        const id = _.get(post,'id');
        const disabled = note.length==0 || note.length>250;

        return<Row style={{alignSelf:'flex-end'}}>
            <Button onClick={()=>this.handleCancelButton( ) } style={{marginRight:10}}>Cancel</Button>
            <Button type='primary' onClick={()=>this.handleDoneButton(note,id)} disabled={disabled}>Done</Button>
        </Row>

    }

    render(){
        const { setNote,form,note,post,patientId } = this.props;
        const { getFieldDecorator } = form;
        const createdAt = _.get(post,'createdAt');
        const noteSize = _.get(note,'length',0);
        const remainChar = 250-noteSize < 0 ? 0 : 250-noteSize;

        let displayName = this.constructor.displayName;
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const keepEventListener = true;

        return <div>
                <div>{ this.renderHeader(createdAt) }</div>
                <TimerContainer displayName={'PostItContainer'} category='POSTIT' action='UPDATE'
                                viewingDetails={viewingDetails} patientId={patientId}
                                keepEventListener={keepEventListener}
                />
                <Form>
                    <Form.Item style={{ margin:0 }}>
                        {getFieldDecorator('note', {
                            rules: [{ required: true, message: 'Please Enter Post-It' },
                                    { validator: validator } ],
                            initialValue:post.note.trim(),
                            value:note
                        })(
                            <TextArea style={{ height: 160 }} onChange={(e)=>setNote(e.target.value.trim())} />
                        )}
                    </Form.Item>
                </Form>
                <div style={{display: 'flex',
                    flexDirection: 'column',
                    width: '100%'}}>
                    <span style={{ alignSelf:'flex-end',color:'#aaaaaa',marginTop:'5px 0px',}}>{ remainChar } characters</span>
                    { this.renderButton() }
                </div>
              </div>
        }
    }

PostItEditComponent = Form.create({})(PostItEditComponent);
export default  PostItEditComponent;
