import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IHButton } from 'ihcomponent'
import {createContainer} from 'libModule/utils'
import config from 'libModule/config'

// container
class VersionContainer extends Component {
  static displayName = 'VersionContainer'

  constructor(p){
    super(p)
    this.state = {
      //web : '',
      server : ''
    }
  }
  render() {
    const version = this.props.version
    const p = {
        label : `Version Number: ${version}`,
        bsStyle : 'primary',
        style: {
          padding: 10
        }
    }
    const p1 = {
      style : {
        marginTop : '10px',
        padding: 10
      },
      label : `Server Version Number: ${this.state.server}`,
      bsStyle : 'primary',
    }
    return (
      <div>
        <div style={p.style}>{p.label}</div>
        <hr/>
        <div style={p1.style}>{p1.label}</div>
      </div>
    )
  }
  componentDidMount(){
    const x = new XMLHttpRequest()
    x.open('GET', config.SERVER_URL+'/version')
    x.send()
    x.onload = ()=>{
      this.setState({
        server : x.responseText
      })
    }
  }
}

export default createContainer(VersionContainer, (state)=>{
  return {
    version :  process.env.VERSION + '.' + process.env.BUILD_NUMBER
  }
})
