import React from 'react';
import { Form, Row, Col, Input, Button } from 'antd';
import placeholder from '../constant/InputPlaceHolder';
const { TextArea } = Input;
const { goalPlaceHolder } = placeholder;
const FormItem = Form.Item;
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram';
import Client from 'libModule/gqlClient';
import enrolledProgram from 'modulesAll/graphql/enrolledProgram';
import moment from 'moment';
import { IHSearchTable, IHLoading, IHButton, _, IHInput, message } from 'ihcomponent';
import helpers from '../helpers/';
import singleVisit from '../../graphql/signleVisit';
import { Modal } from 'antd/lib/index';
import { goPath } from '../../../lib/utils';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { browserHistory } from 'react-router';

const { leaveAndSaveWorkTask } = helpers;
const todayStr = moment().format('MM/DD/YYYY');
const timestampTemplateStr = `========${todayStr}========`;

const parseValue = props => {
  const { form, enrolledProgramId, enrolledProgram } = props;
  const { getFieldsValue } = form;
  let { goals } = getFieldsValue();
  // DONE - TODO: add timestamp of today here
  // 20200304 Thong
  // const formattedGoals = timestampTemplateStr + '\n' + goals;
  // console.log(formattedGoals);
  return {
    id: enrolledProgramId,
    goals //: formattedGoals
  };
};

let GoalComponent = class extends React.Component {
  saveEdit = (flag, setEditMode, msg) => {
    const { props } = this;
    const { toNextStep, form, setIsEditMode, resetDraftState } = props;
    const { getFieldsValue } = form;
    Client.mutate({
      mutation: editEnrolledProgram,
      variables: parseValue(props)
    })
      .then(res => {
        flag ? toNextStep(null, 'GoalStatement', msg) : message.success(msg);
        setIsEditMode(false);
        resetDraftState();
      })
      .catch(e => {
        Modal.error({
          content: e.message
        });
      });
  };

  handleOnClick = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll((error, fields) => {
      if (!error) {
        this.saveEdit(true, false, 'GoalStatement is Saved');
      }
    });
  };

  handleOnClickSaveDraft = () => {
    const { saveEdit } = this;
    const { form } = this.props;

    form.validateFieldsAndScroll((error, fields) => {
      if (!error) {
        saveEdit(false, true, 'GoalStatement Draft is Saved');
      }
    });
  };

  componentWillUnmount() {
    const { props } = this;
    const { setIsEditMode, isEditMode } = props;
    if (isEditMode) {
      setIsEditMode(false);
    }
  }

  renderOverlayDiv = props => {
    const visitId = _.get(props, 'visit.id');
    const memberId = _.get(props, 'member.id');
    const path = `/patients/${memberId}/enrolledprogram/${visitId}_`;
    return (
      <div
        style={{
          width: '100%',
          position: 'absolute',
          height: '100%',
          top: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button 
          onClick={() => {
            Mixpanel.track('clicked', 'enroll patients to the program', "on goal statement step", {});
            const curUrl = browserHistory.getCurrentLocation().pathname;
            if(_.endsWith(curUrl, 'dashboard')) {
              sessionStorage.setItem('backToCurVisit', JSON.stringify(props.visit));
              goPath(path);
            } else {
              props.onCloseModal();
            }
          }} 
          type={'primary'}>
          Enroll Patient to Program
        </Button>
      </div>
    );
  };

  renderForm = isFollowUp => {
    const { props } = this;
    const { form, enrolledProgramId } = this.props;
    const enrolledProgram = _.get(props, 'enrolledProgram', {});
    const { getFieldDecorator } = form;
    const { goals } = enrolledProgram;
    // DONE - TODO: check last timestamp for goal, if applicable
    // 1. if it is of today, don't add timestamp
    // 2. if it is in the past, add some spaces before with timestamp for today
    // 20200304 Thong
    // console.log(goals);
    const isSameDay = _.includes(goals, timestampTemplateStr); // check if update on the same day or missing timestamp for current date
    // console.log(isSameDay);
    // console.log(goals ? 'true' : 'false');
    let notes = goals ? (isSameDay ? goals : `${timestampTemplateStr}\n\n${goals}`) : `${timestampTemplateStr}\n${goalPlaceHolder}`;
    // console.log(notes);
    const itemList = [
      [
        {
          key: 'goals',
          label: 'Set up goal statement ',
          span: 24,
          rows: 30,
          initialValue: notes,
          required: true
        }
      ]
    ];
    return _.map(itemList, (arr, i) => (
      <Row key={i} gutter={12} style={{ opacity: !enrolledProgramId ? 0.2 : 1 }}>
        {_.map(arr, (value, index) => {
          return (
            <Col span={value.span} key={index}>
              <FormItem label={value.label} key={value.key}>
                {getFieldDecorator(value.key, {
                  rules: [
                    {
                      required: value.required,
                      message: value.message || null
                    },
                    {
                      whitespace: true
                    }
                  ],
                  initialValue: value.initialValue,
                  valuePropName: value.valuePropName || 'value'
                })(value.render ? value.render(value) : <TextArea rows={value.rows || 1} cols={value.col || 55} />)}
              </FormItem>
            </Col>
          );
        })}
      </Row>
    ));
  };

  render() {
    const { props, handleOnClickSaveDraft, handleOnClick } = this;
    const { loading, isEditMode, enrolledProgramId } = props;
    const visitType = _.get(props, 'visit.type');
    const goals = _.get(props, 'enrolledProgram.goals');
    const isFollowUp = visitType == 'FOLLOW_UP';
    if (loading) return <IHLoading />;
    return (
      <div className='goals'>
        {this.renderForm(isFollowUp)}
        {!enrolledProgramId ? this.renderOverlayDiv(props) : ''}
        {/*{isFollowUp ? this.renderPreNotes(goals) : ''}*/}
        <div style={{ float: 'right' }}>
          {isEditMode ? (
            <Button onClick={() => handleOnClickSaveDraft()} style={{ width: 130, marginRight: 10 }}>
              Save Draft
            </Button>
          ) : (
            ''
          )}
          {enrolledProgramId ? (
            <Button onClick={() => handleOnClick()} type={'primary'} style={{ width: 130 }}>
              Complete
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
};
GoalComponent = Form.create({
  onFieldsChange(props, fields) {
    const isCompleted = _.get(props, 'visit.rdWorkingList.GoalStatement') == 'COMPLETED';
    const { childProps, setChildProps, isEditMode } = props;
    if (!childProps) {
      setChildProps(props, parseValue, editEnrolledProgram, null, 'Goal Statement');
    }
    if (!isEditMode) {
      props.setIsEditMode(true);
    }
    if (isCompleted) {
      leaveAndSaveWorkTask(null, 'GoalStatement', props);
    }
  }
})(GoalComponent);
export default GoalComponent;
