import React from 'react';
import { IHButton, _ } from 'ihcomponent'
import FoodLogListComponent from '../components/FoodLogListComponent';
import FoodDiaryLogBook from '../components/FoodDairyLogBook';
import { compose } from 'react-apollo'
import { connect } from 'react-redux'
import { openModal,closeModal } from 'layoutModule/actions/MainModal'
import Client from 'libModule/gqlClient';
import createTableAction from 'libModule/table/TableActions';
import {graphql} from "react-apollo/index";
import foodLogList from 'modulesAll/graphql/foodLogList';
import foodLogUnreadList from "graphqlModule/foodLogUnreadList";
import actions from "../actions";
import moment from "moment/moment";
import helper from "../helper";

const foodloglistActions = createTableAction('food_log_list');
const FoodLogContainer = class extends React.Component {
    static  displayName = 'FoodLogContainer';

    // componentDidMount(){
    //     this.props.initialState();
    // }
    constructor(){
        super();
        this.state = {
            showAddFoodLogModal: false
        }
    }
    render() {
        const props = this.props;
        const { userId } = props;
        if(userId){
            return <FoodDiaryLogBook {...props}/>
        }
        return (<div className=" ">
            <div className="vsm-main-page">
                <div className="v-table">
                    <FoodLogListComponent  openModal={this.props.openModal} {...props}/>
                </div>
            </div>
        </div>)
    }
};

const getVariables = (ownProps)=>{
    const { filter,value,year } = ownProps;
    const  variables = {
        // page: _.get(ownProps,'page.current',1),
        count:9999
            // ownProps.userId ? 99999 : 10,
    }

    const defaultFilter = ownProps.userId ? '0' : 'false';
    const rating = _.get(filter,'rating[0]',defaultFilter);
    const memberId = _.get(ownProps,'userId');
    if (rating== '0') {
        variables.filters = {};
    }
    else
        variables.filters = {
            reviewed: (rating === 'true')
    }

    const sortField = 'CREATED_AT';
    variables.filters.memberId = memberId;

    // if(variables.filters.memberId) {
    //     Object.assign(variables.filters, {fromDate, toDate});
    // }
    if(ownProps.userId) {
        const fromDate = moment([year,value]).startOf('month');
        const toDate = moment(fromDate).endOf('month');

        Object.assign(variables.filters,{ fromDate,toDate });
    }else{
      // 30 days for the foodlog list when no patient is given.
      const days  = 30;
      const toDate = undefined;

      // Set start to 30 days ago
      const fromDate=moment().subtract(days,'days').startOf('day').valueOf();
      Object.assign(variables.filters,{ fromDate: fromDate,toDate});

      //old set date code that is not good
      //Object.assign(variables.filters,{ fromDate: new Date((new Date()).getTime() -  days* 24 * 3600000),toDate});

    }
    variables.sort = {
        field: sortField,
        direction: ownProps.sort.direction || 'DESC'
    }

    return variables;
}
const withData = graphql(foodLogList, {
    options: (ownProps) => {
        //const variables = getVariables(ownProps);
        const variables=helper.getFoodLogVariables(ownProps);
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ownProps, data}) => {
        const { loading,foodLogList } = data;
        return {
            foodLogList,
            loading,
        }
    }
})

const mapToProps = (state,props)=>{
    return {
        ...state.globalTables.food_log_list,
        modal:state.MainModal,
        // value:state.foodlog.main.value,
        // year:state.foodlog.main.year
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        ...foodloglistActions,
        openModal: (content, row) => dispatch(openModal(content, row)),
        closeModal:()=>dispatch(closeModal()),
        // initialState:()=>dispatch(actions.initialState())
        // initFoodLogList:(data) => dispatch(actions.initFoodLogList(data)),
        // resetInitList:()=>dispatch(actions.resetInitList()),
    }
}

FoodLogContainer.displayName = 'FoodLogContainer';

export default compose(connect(mapToProps,mapToDispatch),withData)(FoodLogContainer);
