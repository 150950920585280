import PropTypes from 'prop-types';
import React from 'react';
import { IHTable, IHButton, _ } from 'ihcomponent'
import { Icon } from 'antd'
import { formatSchedule, formatReadingFromRow, formatDate ,formatDateMM24Date,formatWeightChange } from 'modulesAll/utils/helpers/task';
import { noDataElem } from 'libModule/helpers/component-helpers'
import { Popover } from 'antd';
import $ from 'jquery';
import convertor from '@ihealth/convertor';

import Client from 'libModule/gqlClient';
import gql from 'graphql-tag';

const vitalTagMap = {
  'BG': {
          'hadMedicine':{
            url:"/image/took-meds-icon.svg",
            hoverText:'Took oral meds'
          },
          'hadInsulin':{
              url:"/image/took-insulin-icon.svg",
              hoverText:'Took Insulin'
          },
          'hadActivity':{
            url:"/image/exercised-icon.svg",
            hoverText:"Exercised"
          },
          'moodTags':{
            url:"/image/mood-icon.svg",
            hoverText:'Mood and symptoms'
          }
        },
  'BP':{
        'hadMedicine':{
                  url:"/image/took-meds-icon.svg",
                  hoverText:'Took Oral Meds'

      }}
}

const calWeightVariation = (props,item,index)=>{
    const data = _.get(props,'vitalList.data',[]);
    const currentWeightChange = _.get(data[index],'body_weight_change.value',0);
    const currentWeightChangeLbs = convertor.HS(currentWeightChange,'kg','lbs').toFixed(1);
    return currentWeightChangeLbs
}

const HSColumns=({patientProfileTabWidth,isInChat,profile,props})=> [
    {
        title: (patientProfileTabWidth <= 800) ? 'Date':'Date / Time',
        key: 'date',
        dataIndex: 'date',
        width: '15%',
        // sorter: (a, b) => a.date - b.date,
        render: (item) => formatDateMM24Date(item)
    },
    {
        title: <p>Reading</p>,
        key: 'measure',
        dataIndex: 'measure',
        width: isInChat ? '20%' :'',
        render: (item, row) => (row.taskStatus === 'COMPLETED'||"EXTRA")? formatReadingFromRow(row,isInChat) :
            (row.taskStatus === 'MISSED')?<span className="miss-black">Missed</span> : null
    },
    {
        title: <p>Weight Variation</p>,
        dataIndex:'body_weight_change',
        render:(item,row,index)=> {
          return  (row.taskStatus === 'COMPLETED' || "EXTRA") ? formatWeightChange(calWeightVariation(props,item,index)) :
                (row.taskStatus === 'MISSED') ? <span className="miss-black">Missed</span> : null
        }
    },
    {
        title: <p>BMI</p>,
        dataIndex:'body_weight',
        width:'10%',
        render:(item,row)=>{
            const weightInKg = item.value;
            const heightInM = _.get(profile,'height.value',0)/100;
            if(heightInM&&weightInKg){
                const BMI = (weightInKg/heightInM/heightInM).toFixed(1);
                return BMI;
            }
            return '--'
        }
    },
    {
        title: 'Patient Notes',
        key: 'measure-notes',
        dataIndex: 'measure',
        width:isInChat ? '15%' :'',
        render: (measure, row) => {
            const renderPic = (!!measure && !!measure.imageUrl)? <img src={measure.imageUrl} />
                : null
            const noteDiv = <div>{measure? (measure.user_notes? measure.user_notes : '---') : '---'}</div>
            return (<div className="patient-note-wrapper">
                {renderPic}
                {noteDiv}
            </div>)
        }
    },
    {
        title: 'Notes',
        key: 'notes',
        dataIndex: 'notes',
        width: '15%',
        render: (notes, row) => {
            const { patientName } = props;
            if (notes && notes.length > 0) {
                return <div onClick={() => onRowClick(row)} style={{cursor: 'pointer'}}><Icon type="file-text"/></div>
            }
            const { patientId } = props;
            const extendedObj = Object.assign({ patientName },row);
            extendedObj.patientId = patientId;

            const buttonProps = {
                label: 'Add >',
                size: 'small',
                bsStyle: 'primary',
                onClick: () => props.openPatientVitalModal(extendedObj),
                className: 'table-action-button'
            }
            return <IHButton {...buttonProps} />
        }
    }
]
const renderPopover = (content,text,index)=>{
        return <Popover content={text} placement="top" mouseLeaveDelay={0} key={index} overlayStyle={{width:'fit-content'}}>{content}</Popover>
}

const VitalListTable = (props) => {
  const { loading, onTableChange, pagination, openPatientVitalModal,isInChat,type,profile } = props;
  const patientProfileTabWidth = $('.patient-profile-tabs').width();
    const columns = [
  {
    title: (patientProfileTabWidth <= 800) ? 'Date':'Date / Time',
    key: 'date',
    dataIndex: 'date',
    width: '15%',
    // sorter: (a, b) => a.date - b.date,
    render: (item) => formatDateMM24Date(item)
  },
  {
    title: <p>Reading </p>,
        // <em style={{fontSize: '11px'}}> (M = Manual)</em></p>,
    key: 'measure',
    dataIndex: 'measure',
    width: '20%',
    render: (item, row) => (row.taskStatus === 'COMPLETED'||"EXTRA")? formatReadingFromRow(row,isInChat) :
      (row.taskStatus === 'MISSED')?<span className="miss-black">Missed</span> : null
  },
  {
    title: 'Schedule',
    key: 'schedule',
    dataIndex: 'schedule',
    width: '15%',
    render: (item, row) => formatSchedule(row),
  },
  {
    title:<p>Tags</p>,
    render:(item,doc)=>{
        const vitalObj = vitalTagMap[doc.type];
        if(!vitalObj){
            return;
        };
        const keys = Object.keys(vitalObj);
        let isEmpty = true;

        _.forEach(keys,(k)=>{
          if(Array.isArray(doc[k])){
              isEmpty&=!(!!doc[k].length)
          }else{
              isEmpty&=!(doc[k]);
          }
        });

        if(isEmpty){
          return '_';
        }
        return keys.map((k,i)=>{
           const content = doc[`${k}`] ? <img src={vitalObj[k].url} style={{marginRight:15,width:16}} key={i}/>:'';
             return renderPopover(content,vitalObj[k].hoverText,i);
           return content;
        })
    }
  },
  {
    title: 'Patient Notes',
    key: 'measure-notes',
    dataIndex: 'measure',
    width:isInChat ? '15%' :'',
    render: (measure, row) => {
      // const renderPic = (!!measure && !!measure.imageUrl)? <GoThumbImageComponent filename={_.split(measure.imageUrl, '/files/')[1]} />
      const renderPic = (!!measure && !!measure.imageUrl)? <img src={measure.imageUrl} />
       : null
      const noteDiv = <div>{measure? (measure.user_notes? measure.user_notes : '---') : '---'}</div>
      return (<div className="patient-note-wrapper">
                {renderPic}
                {noteDiv}
        </div>)
    }
  },
  {
    title: 'Notes',
    key: 'notes',
    dataIndex: 'notes',
    width: '15%',
    render: (notes, row) => {
      const { patientName }= props;
      if (notes && notes.length > 0) {
        return <div onClick={() => onRowClick(row)} style={{cursor: 'pointer'}}><Icon type="file-text"/></div>
      }
      const { patientId } = props;
      const extendedObj = Object.assign({ patientName },row);
      extendedObj.patientId = patientId;

      const buttonProps = {
        label: 'Add >',
        size: 'small',
        bsStyle: 'primary',
        onClick: () => openPatientVitalModal(extendedObj),
        className: 'table-action-button'
      }
      return <IHButton {...buttonProps} />
    }
  }
  ]
  const tableProps = {
    // onRowClick: (row) => openPatientVitalModal(row, type),
    onChange: onTableChange,
    dataSource: props.data,
    pagination: {
      current: _.get(pagination, 'current', 1),
      pageSize: 5,
      total: _.get(props.vitalList, 'pageInfo.total', 0),
      // showTotal:(tota,range)=>`${range[0]}-${range[1]} of ${total}`
    },
    columns:type=='HS' ? HSColumns({patientProfileTabWidth,isInChat,profile,props}) : columns,
    rowKey: 'id',
    loading
  }

  return (
    <div className="patient-vital-list-table">
      <IHTable {...tableProps} noDataElem={noDataElem} />
               {/*// style={{height: '24em'}}/>*/}
    </div>
  )
}

VitalListTable.propTypes = {
  patientTaskList: PropTypes.array,
  openPatientVitalModal: PropTypes.func,
  onTableChange: PropTypes.func
}



export default VitalListTable
