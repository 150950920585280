import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import IdleTimer from 'react-idle-timer'
import { compose } from 'react-apollo'
import actions from '../../chat/actions';
import { API } from '../../chat/actions/api';

import { measureDom } from '../actions/MainLayout'
import { autoLogout } from 'authModule/actions/CurrentUser'
import MainLayout from '../components/MainLayoutComponent'
import { checkPermission } from './checkPermission'
import I18N from 'modulesAll/I18N'
import { createAuditLog } from 'libModule/utils/auditLog'
import RequestCache from 'libModule/requestCache'
import { _, message, moment } from 'ihcomponent'
import { decryptRole } from 'libModule/utils';
import { ROLES } from 'modulesAll/utils/constants/role'
import { COMMON_HELPERS } from 'libModule/helpers/common-helpers'
import { closeModal, openModal, ErrorMessageDiv } from 'layoutModule/actions/MainModal'
import { IHButton } from 'ihcomponent';
import * as R from 'ramda';
import UserClass from 'modulesAll/graphql/class/User';
import { browserHistory } from 'react-router';
import { setInstitution } from '../../layout/actions/Nav'
import getOrgTeams from 'modulesAll/graphql/getOrgTeams';
import Client from 'libModule/gqlClient';
import userWithOnlyTeamInfo from 'modulesAll/graphql/userWithOnlyTeamInfo';
import CallWindow from 'modulesAll/call/containers/callContainer';
import { loadConnectStreams } from 'modulesAll/call/helper/helpers';
import callActions from '../../call/actions';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import {Modal, Button, Row, Col} from 'antd';

const idleTimeout = 900000; //auto-logout time in milliseconds (15 minutes)
const idleIntervalLength = 30000; //check every 30 second
const MAX_MSG_PER_CHANNEL = 20;
const MAX_CHANNELS_PER_BATCH = 299;
// 199;
import mesNotificationType from '../../chat/constants/messageTypes';
import { goPath } from "../../../lib/utils";

// const idleTimeout = 5000; //auto-logout time in milliseconds (5 seconds)
// const idleIntervalLength = 1000; //check every 1 second
function difference(object, base) {
    function changes(object, base) {
        return _.transform(object, function (result, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}

let list = [];

class Container extends Component {
    static displayName = 'MainLayoutContainer'
    static contextTypes = {
        dom: PropTypes.object
    };
    static childContextTypes = {
        dom: PropTypes.object
    };

    constructor(props) {
        super(props);
        // this.isDupeMsg = this.isDupeMsg.bind(this);
        this.subscribeToChannels = this.subscribeToChannels.bind(this);
        this.setChannels = this.setChannels.bind(this);
        this.getTimestampOfLastTextMsg = this.getTimestampOfLastTextMsg.bind(this);
        this.handleUnreadAndLastMsg = this.handleUnreadAndLastMsg.bind(this);
        this.apiFetchBatchHistory = this.apiFetchBatchHistory.bind(this);
        this.handleBatchHistory = this.handleBatchHistory.bind(this);
        this.setUserMap = this.setUserMap.bind(this);
        this.beforeUnload = this.beforeUnload.bind(this);
        this.addTeamMemberAvatars = this.addTeamMemberAvatars.bind(this);
        this.handleChangeOrg = this.handleChangeOrg.bind(this);
        this.setOrgTeamMap = this.setOrgTeamMap.bind(this);
        this.channels = [];
        let initArr = _.split(props.location.pathname, '/');
        let initPage = initArr[initArr.length - 1];
        if(initPage === 'default') {
            initPage = 'patient_profile'
        }
        if(_.startsWith(initPage, 'YW')) {
            initPage = 'patient_detail'
        }

        this.state = {
            //CCP
            showChat: null,
            channelName: '',
            callPhoneNumber: '',
            callUserName: '',
            callStatus: 7,
            callDate: null,
            callDuration: 0,
            curPage: initPage,
            showChromeSuggestion: false
        }
        this.subscriptions = this.subscriptions.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        //   this.onConnected = this.onConnected.bind(this);
        //   this.onEnded = this.onEnded.bind(this);
    }

    getChildContext() {
        return {
            dom: this.props.dom
        };
    }

    handleChangePage(page) {
        this.setState({
            curPage: page
        })
    }

    // componentWillMount() {
    //   window.addEventListener('resize', this.props.measureDom)
    // }
    // componentWillUnmount() {
    //   window.removeEventListener('resize', this.props.measureDom)
    // }

    componentWillMount() {
    }

    componentWillUnmount() {
        if (API.getPubNub()) {
            API.getPubNub().unsubscribeAll();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            checkPermission(nextProps.location.pathname);
        }

        if (nextProps.breadCrumb && (nextProps.breadCrumb !== this.props.breadCrumb || nextProps.institution !== this.props.institution)) {

            // To have patientNRIC and patientName when navigation inside a specific patient
            // There should not be patientNRIC and patientName during import and manual registration
            // This is not a good way to handle all scenario but due to time constraint
            if (_.includes(nextProps.breadCrumb, 'Patients /')) {
                const currentPatient = RequestCache.get('nurse/currentPatient')
                const patientNRIC = _.get(currentPatient, 'identification[0].value')
                const patientName = _.get(currentPatient, 'profile.fullName')

                createAuditLog({
                    patientNRIC,
                    patientName,
                    source: nextProps.breadCrumb,
                    action: `${I18N.get('auditLog.Navigation.prefix')} ${nextProps.breadCrumb}`
                })
            }
            // To have patientNRIC and patientName for navigation using Patient Role Login
            else if (decryptRole() === 'MEMBER:Member') {
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
                const patientNRIC = _.get(currentUser, 'identification[0].value')
                const patientName = _.get(currentUser, 'profile.fullName')

                createAuditLog({
                    patientNRIC,
                    patientName,
                    source: nextProps.breadCrumb,
                    action: `${I18N.get('auditLog.Navigation.prefix')} ${nextProps.breadCrumb}`
                })
            }
            else {
                createAuditLog({
                    source: nextProps.breadCrumb,
                    action: `${I18N.get('auditLog.Navigation.prefix')} ${nextProps.breadCrumb}`
                })
            }
        }
        return true;
    }

    beforeUnload() {
        if (API.getPubNub()) {
            API.getPubNub().unsubscribeAll();
        }
    }

    findUniqCount = (channels) => {
        list = list.concat(channels);
        // console.log('uniq channel Ids', _.uniq(list).length);
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.PNStatus.category === 'PNConnectedCategory' && this.props.PNStatus.category !== 'PNConnectedCategory') {
            //const channelsArr1 = Object.keys(this.props.channels);
            const channelsArr = this.channels.map(ch => ch.name);
            //console.log("this.channels:",this.channels,channelsArr,"this.props.channels:", channelsArr1);
            if (channelsArr) {
                for (let i = 0; i < channelsArr.length; i += MAX_CHANNELS_PER_BATCH) {
                    try {
                        const slicedChannelsArray = channelsArr.slice(i, i + MAX_CHANNELS_PER_BATCH);
                        const resp = await this.apiFetchBatchHistory(slicedChannelsArray);
                        this.handleBatchHistory(resp, slicedChannelsArray);
                        this.findUniqCount(Object.keys(resp.channels));
                    } catch (e) {
                        // console.log(e);
                    }
                }
                this.props.setIsFullyLoaded(true);
            }
        }
    }

    componentDidMount() {
        checkPermission(this.props.location.pathname);
        this.idleInterval = setInterval(() => {
            if (!this.refs.idleTimer) {
                clearInterval(this.idleInterval);
            } else {
                //console.log('this.refs.idleTimer.getRemainingTime() ', this.refs.idleTimer.getRemainingTime())
                if (this.refs.idleTimer.getRemainingTime() === 0) {
                    this.props.autoLogout('AUTO_LOG_OUT');
                    clearInterval(this.idleInterval);
                }
            }
        }, idleIntervalLength)

        document.title = window.VSM_CONFIG.DEMO ? window.VSM_CONFIG.DEMO : 'VSM';

        window.addEventListener('beforeunload', this.beforeUnload);

        const currentUserRole = decryptRole();

        // Only show Chat for providers (not admins)
        if (currentUserRole === ROLES.doctor || currentUserRole === ROLES.nurse || currentUserRole === ROLES.medicalAssistant) {
            API.apiGetUserChatInfo()
                .then(async res => {
                    const { props } = this;
                    const setUserMap = this.setUserMap;
                    const setChannels = this.setChannels;
                    const addTeamMemberAvatars = this.addTeamMemberAvatars;

                    // for initializing pubnub
                    const userId = atob(sessionStorage.getItem('currentUserId')).split(':')[1];
                    const publishKey = res.data.getUserChatInfo.publishKey;
                    const subscribeKey = res.data.getUserChatInfo.subscribeKey;
                    const authKey = res.data.getUserChatInfo.authKey;
                    const channelGroupsArr = res.data.getUserChatInfo.channelGroup ? [res.data.getUserChatInfo.channelGroup.name] : [];

                    await this.setOrgTeamMap();
                    API.apiInitializePubNub(publishKey, subscribeKey, authKey, userId);

                    if (res.data.getUserChatInfo.channelGroup) {
                        setUserMap(res.data.getUserChatInfo);
                        await addTeamMemberAvatars();
                        const channelsArr = res.data.getUserChatInfo.channelGroup.channels;

                        setChannels(channelsArr, channelGroupsArr);
                        // this.subscribeToChannels(channelGroupsArr);

                    }

                    // add listener before subscribing to channels, per pubnub documentation
                    API.getPubNub().addListener({
                        status: status => {
                            console.log('Pubnub Status', status);
                            this.props.updatePNStatus({ category: status.category, operation: status.operation });

                            if (status.category === 'PNNetworkUpCategory' || status.category === 'PNNetworkIssuesCategory') {
                                API.getPubNub().reconnect();
                            }

                            if (status.error) {

                                // get new authKey & re-subscribe to channels if expired
                                if (status.category === 'PNAccessDeniedCategory' && status.operation === 'PNSubscribeOperation') {

                                    API.apiGetUserChatInfo()
                                        .then(res => {

                                            API.getPubNub().setAuthKey(res.data.getUserChatInfo.authKey);
                                            this.subscribeToChannels(channelGroupsArr); // need to re-subscribe after auth key reset
                                        })
                                        .catch(err => console.log('Error getUserChatInfo, ', err));
                                }

                                // (IH-649, IH-618) Throw error when user tries to subscribe to over 10 channel groups
                                if (status.category === 'PNBadRequestCategory' && status.operation === 'PNSubscribeOperation') {
                                    const errMsg = JSON.parse(status.errorData.response.text);

                                    if (errMsg.message === I18N.get('chat.max_channels')) {
                                        props.openErrorModal(I18N.get('chat.max_channels') + '. ' + I18N.get('chat.contact_tech_support'));
                                    }
                                }
                            }
                        },
                        message: msg => {
                            // console.log('msg: ', msg);
                            // if (!this.isDupeMsg(msg)) {
                            const msgObj = {
                                timetoken: Number(msg.timetoken),
                                entry: msg.message,
                                isListening: true, // flag to increment unread counter, only if msg comes from listener
                                lastMsgText: msg.message.text,
                                lastMsgTime: msg.timetoken,
                                lastMsgSender: msg.message.displayName,
                                type: msg.message.type,
                                publisher: msg.message.publisher
                            };
                            // const lastMsgObj = {
                            //   isListening: true, // flag to increment unread counter, only if msg comes from listener
                            //   lastMsgText:msg.message.text,
                            //   lastMsgTime:msg.timetoken,
                            //   lastMsgSender:msg.message.displayName,
                            //   type:msg.message.type,
                            //   publisher : msg.message.publisher
                            // }

                            const publisher = msg.message.publisher;
                            // const userObj = {
                            //     uuid: publisher
                            // }
                            // props.addToUserMap(userObj);

                            // check if new message is from patient or care team
                            if (publisher.split('-')[0] !== 'team') {

                                // if patient sent chat message, check to see if they are in userMap
                                try {

                                    if (typeof COMMON_HELPERS.checkIfEncoded(publisher) === 'boolean' && COMMON_HELPERS.checkIfEncoded(publisher)) {

                                        const decodedPatientId = atob(publisher).split(':')[1];

                                        // if patient is not in userMap
                                        if (!props.userMap[decodedPatientId]) {
                                            API.getUserProfile(publisher)
                                                .then(res => {
                                                    const userObj = {
                                                        uuid: publisher,
                                                        firstName: res.data.user ? res.data.user.profile.firstName : '',
                                                        lastName: res.data.user ? res.data.user.profile.lastName : '',
                                                        avatar: res.data.user.profile.avatar && res.data.user.profile.avatar.link ? res.data.user.profile.avatar.link : '/image/default_avator.png',
                                                        profileType: res.data.user && res.data.user.allRoles && res.data.user.allRoles.length > 0 ? res.data.user.allRoles[0].name : 'UnknownType',
                                                    };
                                                    props.addToUserMap(userObj);
                                                })
                                                .catch(err => console.log('Error fetching user profiles: ', err));
                                        }
                                    } else {
                                        if (!props.userMap[publisher]) {

                                            const encodedPatientId = btoa(`accounts:${publisher}`);

                                            API.getUserProfile(encodedPatientId)
                                                .then(res => {
                                                    const userObj = {
                                                        uuid: publisher,
                                                        firstName: res.data.user ? res.data.user.profile.firstName : '',
                                                        lastName: res.data.user ? res.data.user.profile.lastName : '',
                                                        avatar: res.data.user.profile.avatar && res.data.user.profile.avatar.link ? res.data.user.profile.avatar.link : '/image/default_avator.png',
                                                        profileType: res.data.user && res.data.user.allRoles && res.data.user.allRoles.length > 0 ? res.data.user.allRoles[0].name : 'UnknownType',
                                                    };
                                                    props.addToUserMap(userObj);
                                                })
                                                .catch(err => console.log('Error fetching user profiles: ', err));
                                        }
                                    }
                                } catch (err) {
                                    console.log('Err: ', err, ' Publisher on message not valid: ', publisher);
                                }
                            }
                            props.addMessage(msg.channel, msgObj);
                            // props.updateChannelInfo(msg.channel,lastMsgObj);
                        }
                    });

                    //subscribe to channels after add lister
                    this.subscribeToChannels(channelGroupsArr);
                })
                .catch(err => console.log('Error getUserChatInfo, ', err));
        }

        loadConnectStreams(this.subscriptions)

        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        if(!isChrome){
            this.setState({
                showChromeSuggestion: true
            });
        }

    }

    chromeSuggestionModal(){
        return (
            <Modal
                title="Please Confirm"
                visible={this.state.showChromeSuggestion}
                closable={false}
                maskClosable={false}
                footer={null}
            >
                <p>We strongly recommend using chrome to visit our portal, it will have a better user experience when you access our portal. So please switch chrome and visit us again. Thanks!</p>
                <Row gutter={16} style={{marginTop: 20}}>
                    <Col span={4} offset={16}>
                        <Button type="primary" onClick={this.handleOk.bind(this)}>Ok, Got it.</Button>
                    </Col>
                </Row>
            </Modal>
        )
    }

    handleOk(){
        this.setState({
            showChromeSuggestion: false
        });
    }


    async setOrgTeamMap() {
        try {
            const res = await Client.query({
                query: getOrgTeams,
                fetchPolicy: 'network-only'
            })
            if (_.get(res, 'data.getOrgTeams')) {
                const orgTeamMap = {};
                res.data.getOrgTeams.forEach(val => orgTeamMap[val.organizationId] = val.teamIds);
                this.props.setOrgTeamMap(orgTeamMap);
            }
        }
        catch (e) {
            console.log('getOrgTeams err: ', e);
        }
        // .then(res => {
        //   const orgTeamMap = {};
        //   res.data.getOrgTeams.forEach(val => orgTeamMap[val.organizationId] = val.teamIds);
        //   this.props.setOrgTeamMap(orgTeamMap);
        // })
        // .catch(err => console.log('getOrgTeams err: ', err));
        // (res => {

    }

    async handleChangeOrg(data, selectedOrg) {
        const { sessionToken, id, role, appSettings } = data.chooseLoginOrganization || {};

        if (sessionToken) {
            await UserClass.saveUserInfo({ sessionToken, id, role, appSettings });
            this.props.setInstitution(role.refId);
            message.success('Organization changed');
            window.location.href = '/dashboard';
            if (role.name === 'Admin') {
                window.location.href = '/providers';
            }
            else {
                window.location.href = '/dashboard';
            }
            // window.location.reload();
        }
    }
    setUserMap(chatInfo) {
        const userMap = {};
        const { props } = this;

        chatInfo.channelGroup.channels.forEach(ch => {
            if (ch.member && ch.member.id) {
                const decodedId = atob(ch.member.id).split(':')[1];

                userMap[decodedId] = {
                    firstName: ch.member.profile.firstName,
                    lastName: ch.member.profile.lastName,
                    profileType: ch.member.allRoles && ch.member.allRoles.length > 0 ? ch.member.allRoles[0].name : 'UnknownType',
                    avatar: R.isNil(ch.member.profile.avatar) ? '/image/default_avator.png' : ch.member.profile.avatar.link
                }
            }
        });

        props.setUserMap(userMap);
    }

    setChannels(combinedChannels, channelGroupsArr) {
        const { props } = this;
        const channelsObj = {};
        this.channels = combinedChannels;

        combinedChannels.forEach(ch => {
            const patientId = ch.name.split('-')[1]; // TODO: Make helper function
            const newChannel = {
                patientId,
                lastMessageTimestamp: new Date().getTime() * 1e4, // get messages starting today
                history: [],
                counter: 0
            };
            channelsObj[ch.name] = newChannel;
        });

        this.props.addChannels(channelsObj);
    }

    async addTeamMemberAvatars() {
        const userMap = {};
        const { props } = this;
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const id = _.get(currentUser, 'id');
        const res = await Client.query({ query: userWithOnlyTeamInfo, variables: { id: id } });
        const teams = _.get(res, 'data.user.team', []);

        teams.forEach(team => {
            if (team.members) {
                team.members.forEach(member => {
                    const decodedId = atob(member.user.id).split(':')[1];
                    userMap[decodedId] = {
                        firstName: member.user.profile.firstName,
                        lastName: member.user.profile.lastName,
                        profileType: member.user.allRoles && member.user.allRoles.length > 0 ? member.user.allRoles[0].name : 'UnknownType',
                        avatar: _.isNil(member.user.profile.avatar) ? '/image/default_avator.png' : member.user.profile.avatar.link
                    }
                })
            }
        });

        props.setUserMap(userMap);
    }

    subscribeToChannels(channelGroupsArr) {
        if (API.getPubNub()) {
            API.getPubNub().subscribe({
                channelGroups: channelGroupsArr,
                triggerEvents: true
            });
        }
    }

    async apiFetchBatchHistory(channelsArr) {
        // batch history API only returns channels with messages; if a channel does not have messages, it is not incl. in response
        return new Promise((resolve, reject) => {
            API.getPubNub().fetchMessages({
                channels: channelsArr,
                count: MAX_MSG_PER_CHANNEL,
            }, (status, resp) => {

                // if expired authKey, fetch & set new authKey
                if (status.error) {
                    console.log('Error fetch batch history: ', status);
                    if (status.category === 'PNAccessDeniedCategory') {
                        API.apiGetUserChatInfo()
                            .then(res => {

                                API.getPubNub().setAuthKey(res.data.getUserChatInfo.authKey);

                                const channelGroupsArr = res.data.getUserChatInfo.channelGroup ? [res.data.getUserChatInfo.channelGroup.name] : [];
                                this.subscribeToChannels(channelGroupsArr); // need to re-subscribe after auth key reset

                                API.getPubNub().fetchMessages({
                                    channels: channelsArr,
                                    count: MAX_MSG_PER_CHANNEL // max
                                }, (innerStatus, innerResp) => {

                                    if (innerStatus.error) {
                                        reject(innerStatus.error);
                                        console.log('Error fetch batch history, after authKey reset, ', innerStatus);
                                    }
                                    if (innerResp) {
                                        // console.log('innerResp, fetch batch history: ', innerResp);
                                        this.handleBatchHistory(innerResp, channelsArr);
                                    }
                                })

                            })
                            .catch(err => console.log('error getUserChatInfo: ', err));
                    } else {
                        console.log('Error fetch batch history: ', status);
                        reject(status);
                    }
                }

                if (resp) {
                    // this.handleBatchHistory(resp, channelsArr);
                    resolve(resp);
                }
            });
        })
    }


    handleBatchHistory(resp, channelsArr) {
        const { props } = this;
        //add channel messages by chunk
        let batchChatObject = {};
        let batchMsgObj = {};
        let isBatch = true;
        if (resp && Object.keys(resp.channels).length > 0) {
            channelsArr.forEach(ch => {

                // add chat history for ea channel
                const patientId = ch.split('-')[1]; // TODO: Make helper function
                let messages;

                if (resp.channels[ch]) {

                    messages = resp.channels[ch].filter(val => {
                        // console.log('mainLayout:', val);
                        if (typeof val.message === 'object' && val.message.type != 'ACK') {//val.message.type !== 'ACK') {
                          return val;
                        } else {
                            // if (typeof val.message === 'object') {
                            //     console.log('message:', val.message.type, val.message.text);
                            // }
                            // if (typeof val.message === 'object' && val.message.type !== 'text') {
                            //     console.log('ACK message format: ', val);
                            // } else {
                            //     console.log('Invalid message format: ', val);
                            // }

                        }
                    }).map(val => {
                        return {
                            timetoken: val.timetoken,
                            entry: val.message
                        };
                    });

                    let lastMsgTS;

                    if (resp.channels[ch] && resp.channels[ch].length > 0) {
                        lastMsgTS = this.getTimestampOfLastTextMsg(resp.channels[ch]);
                    } else {
                        lastMsgTS = 0;
                    }
                    const messageCount = resp.channels[ch].length;
                    // props.addChatHistory(ch, patientId, messages, lastMsgTS,messageCount,isBatch);
                    batchChatObject[ch] = { patientId, messages, lastMsgTS, messageCount };
                    let msgObj = this.handleUnreadAndLastMsg(messages, ch);
                    msgObj.isListening = false;
                    batchMsgObj[ch] = msgObj;
                    // props.updateChannelInfo(ch, msgObj,isBatch);
                }

                if (!resp.channels[ch]) {
                    const messages = [];

                    // props.addChatHistory(ch, patientId, messages, 0,isBatch);
                    let msgObj = this.handleUnreadAndLastMsg(messages);
                    batchChatObject[ch] = { patientId, messages, messageCount: 0 };
                    // flag to not increment counter channel if msg from fetchHistory
                    msgObj.isListening = false;
                    batchMsgObj[ch] = msgObj
                    // props.updateChannelInfo(ch, msgObj,isBatch);
                }

            });
        }
        props.addChatHistory(batchChatObject, {}, {}, {}, {}, isBatch);
        props.updateChannelInfo({}, batchMsgObj, isBatch);
        //add message by chunk
    }

    getTimestampOfLastTextMsg(messages) {
        let lastMessageTimestamp = 1524175294358 * 10000; // 1970 default
        return messages.length > 0 ? messages[0].timetoken : lastMessageTimestamp;
    }

    handleUnreadAndLastMsg(messages, ch) {
        const curHistory = messages;
        const historyLength = curHistory.length;
        let lastIndex = historyLength - 1;
        let lastUnreadAckIndex = historyLength - 1;
        let unreadCountFromOffLine = 0;
        let lastMsgText = (function () {
            if (historyLength > 0 && lastIndex >= 0 && lastUnreadAckIndex >= 0) {
                // find index of last text message
                while (!(mesNotificationType[curHistory[lastIndex].entry.type]) &&
                    lastIndex > 0) {
                    lastIndex--;
                }
                while (lastUnreadAckIndex > 0) {
                    const curMsgEntry = curHistory[lastUnreadAckIndex];
                    const isTeam = _.get(curMsgEntry, 'entry.publisher', '').split('-')[0] === 'team';
                    // check for encoded/decoded publishers
                    // also, catch 'push-notification-tester' publisher coming from mobile
                    try {

                        if (typeof curMsgEntry === 'objcet' && (curMsgEntry.entry && (curMsgEntry.entry.publisher || curMsgEntry.entry.uuid)) &&
                            typeof COMMON_HELPERS.checkIfEncoded(curMsgEntry.entry.publisher || curMsgEntry.entry.uuid) === 'boolean' &&
                            COMMON_HELPERS.checkIfEncoded(curMsgEntry.entry.publisher || curMsgEntry.entry.uuid)) {
                            //check if current msg is a ack from team breaks
                            if (curMsgEntry.entry.type == 'ACK' && isTeam) {
                                break;
                            }
                        } else {

                            if (curMsgEntry.entry.type == 'ACK' && isTeam) {
                                break;
                            }
                        }

                        // if (curMsgEntry.entry.type != 'text' && (curMsgEntry.publisher === decodedUserId || curMsgEntry.uuid === decodedUserId)) {
                        //   break;
                        // }
                    } catch (err) {

                        console.log('Err: ', err, ' Publisher on message not valid: ', curMsgEntry.entry || curMsgEntry.entry);
                    }

                    if (mesNotificationType[`${curMsgEntry.entry.type}`]) {
                        unreadCountFromOffLine++;
                    }
                    lastUnreadAckIndex--;
                }

                return lastIndex >= 0 ? curHistory[lastIndex].entry.text : '';
            }
        }())
        const lastMsgTime = historyLength > 0 ? _.get(curHistory[lastIndex], `timetoken`) : '';
        const lastMsgSender = historyLength > 0 ? _.get(curHistory[lastIndex], `entry.displayName`, 'Notification') : '';
        const msgObj = {
            unreadCountFromOffLine: unreadCountFromOffLine,
            lastMsgText: lastMsgText,
            lastMsgTime: lastMsgTime,
            lastMsgSender: lastMsgSender
        }
        return msgObj;
    }

    render() {
        const { nav, children } = this.props;
        const curPath = this.props.location.pathname;
        return (
            <IdleTimer ref="idleTimer" timeout={idleTimeout}>
                <MainLayout id='MainLayout' {...{ nav, curPath, children}}
                            curPage = {this.state.curPage}
                            handleChangeOrg={this.handleChangeOrg}
                            handleChangePage={this.handleChangePage}/>
                {this.renderCallWindow()}
                {this.chromeSuggestionModal()}
            </IdleTimer>
        )
    }

    /**
  * close Call Box
  */
    closeCallBox = () => {
        this.setState({ callStatus: 7 });
    }

    renderCallWindow() {
        const { showChat, callPhoneNumber, callUserName, callStatus, callDuration, callDate } = this.state;
        const { contactStatus,lastCallPatientId, lastCallPatientName,lastCallEndTime,lastCallStartTime } = this.props;
        var status = 7
        if (contactStatus == 'onConnected') {
            status = 1
        } else if (contactStatus == 'onConnecting') {
            status = 0
        } else if (contactStatus == 'onEnded') {
            status = 2
        } else if (contactStatus == 'onClosed') {
            status = 7
        }
        var timeDiff = Date.daysBetween(lastCallStartTime, lastCallEndTime)
        const date = lastCallEndTime ? lastCallEndTime.format("YYYY-MM-DD HH:mm:ss") : ''
        const callArgs = {
            showChat: true,
            phoneNumber: callPhoneNumber,
            username: lastCallPatientName,
            status: status,
            duration: timeDiff,
            date: date
        }
        return <CallWindow callArgs={callArgs} close={() => { this.props.setContact('onClosed') }} />;
    }

    //first time load script; then add ccp and dial a number
    subscriptions() {
        var that = this
        window.awsCCP = window.awsCCP || {};
        window.connect.agent((agent) => {
            window.awsCCP.agent = agent;
            //*
            //Event subscriptions link your app into the heartbeat of Amazon Connect
            //by allowing your code to be called when new agent information is available.
            // console.log("Subscribing to events for agent " + agent.getName());
            // console.log("Agent is currently in status of " + agent.getStatus().name);
            agent.onRefresh(function handleAgentRefresh(agent) {
                // console.log("[agent.onRefresh] Agent data refreshed. Agent status is ", agent.getStatus().name);
            });
            agent.onRoutable(function handleAgentRoutable(agent) {
                // console.log("[agent.onRoutable] Agent data refreshed. Agent status is ", agent.getStatus().name);
            });
            agent.onNotRoutable(function handleAgentNotRoutable(agent) {
                // console.log("[agent.onNotRoutable] Agent data refreshed. Agent status is ", agent.getStatus().name);
            });
            agent.onOffline(function handleAgentOffline(agent) {
                // console.log("[agent.onOffline] Agent data refreshed. Agent status is ", agent.getStatus().name);
            })
            //*/
        });

        window.connect.contact((contact) => {
            window.awsCCP.contact = contact;
            contact.onConnecting(function handleContactConnected(contact) {
                if (contact) {
                    // console.log("XXXXXXXXXXXXXXXXXXXXXX[contact.onConnecting] Contact connected to agent. Contact is " + contact.getContactId(), "status:" + contact.getStatus().type);
                    //mark down date
                    //error do not mark down date
                    that.props.setContact('onConnecting')
                } else {
                    // console.log("[contact.onConnecting] Contact connected to agent. Null contact passed to event handler");
                }
            });
            contact.onConnected(function handleContactConnected(contact) {
                if (contact) {

                    that.props.setContact('onConnected')

                    const dateFormat = 'MM-DD-YYYY HH:mm';
                    var startMoment = moment(new Date(), dateFormat)
                    that.props.setLastCallStartTime(startMoment);

                    const { lastCallPatientId } = that.props;
                    Mixpanel.track('receive', 'phone', 'onConnected', { "PATIENT_ID": lastCallPatientId, "CONTACT_ID": contact.getContactId()  });

                    // console.log("XXXXXXXXXXXXXXXXXXXXXX[contact.onConnected] Contact connected to agent. Contact is " + contact.getContactId(), "status:" + contact.getStatus().type);

                } else {
                    // console.log("[contact.onConnected] Contact connected to agent. Null contact passed to event handler");
                }
            });
            contact.onEnded(function handleContactEnded(contact) {
                // if last state was onConnected then pop up
                    // console.log('that.props.concat', that.props.contactStatus)
                if ( that.props.contactStatus == 'onConnected'){
                    const dateFormat = 'MM-DD-YYYY HH:mm';
                    var endMoment = moment(new Date(), dateFormat)
                    that.props.setLastCallEndTime(endMoment);

                    //pop up call log
                    that.props.providerNotesModalIsVisible(true);

                    const { lastCallPatientId } = that.props;
                    Mixpanel.track('receive', 'phone', 'onEnded', { "PATIENT_ID": lastCallPatientId, "CONTACT_ID": contact.getContactId() });

                }
                that.props.setContact('onEnded')
            });
        });
    }
}

const mapState = ({ MainLayout, Nav, chat, AWSConnect }, ownProps) => {
    return {
        ...MainLayout,
        ...Nav,
        userMap: chat.main.userMap,
        // channels: chat.main.channels,
        selectedChannel: chat.main.selectedChannel,
        PNStatus: chat.main.PNStatus,
        institution: Nav.institution,
        contactStatus: AWSConnect.contact,
        lastCallPatientId: AWSConnect.lastCallPatientId,
        lastCallPatientName: AWSConnect.lastCallPatientName,
        lastCallEndTime: AWSConnect.lastCallEndTime,
        lastCallStartTime: AWSConnect.lastCallStartTime,
    }
};

const mapDispatch = (dispatch) => {
    return {
        openErrorModal: (errorMessage) => {
            const closeModalHandler = () => dispatch(closeModal())
            const modalProps = {
                size: 'sm',
                title: 'Warning',
                showHeaderCloseButton: false,
                footer: <IHButton key="ok" size="large" style={{ width: '82px' }} label="Ok" bsStyle="primary" onClick={closeModalHandler} />
            }
            dispatch(openModal(<ErrorMessageDiv errorMessage={errorMessage} />, modalProps))
        },
        addMessage: (channel, msg) => dispatch(actions.addMsg(channel, msg)),
        updateChannelInfo: (channel, msgObj, isbatch) => dispatch(actions.updateChannelInfo(channel, msgObj, isbatch)),
        addToUserMap: newUser => dispatch(actions.addToUserMap(newUser)),
        setUserMap: userMap => dispatch(actions.setUserMap(userMap)),
        addChatHistory: (channel, patientId, messages, timestamp, messageCount, isbatch) => dispatch(actions.addChatHistory(channel, patientId, messages, timestamp, messageCount, isbatch)),
        addChannels: (channelsObj) => dispatch(actions.addChannels(channelsObj)),
        measureDom: () => dispatch(measureDom(window.innerWidth, window.innerHeight)),
        autoLogout: (event) => dispatch(autoLogout(event)),
        updatePNStatus: status => dispatch(actions.updatePNStatus(status)),
        setInstitution: inst => dispatch(setInstitution(inst)),
        setOrgTeamMap: map => dispatch(actions.setOrgTeamMap(map)),
        setIsFullyLoaded: flag => dispatch(actions.setIsFullyLoaded(flag)),
        setContact: flag => dispatch(callActions.contact(flag)),
        providerNotesModalIsVisible: flag => dispatch(callActions.callStatus(flag)),
        setLastCallPatientId: flag => dispatch(callActions.lastCallPatientId(flag)),
        setLastCallPatientName: flag => dispatch(callActions.lastCallPatientName(flag)),
        setLastCallEndTime: flag => dispatch(callActions.lastCallEndTime(flag)),
        setLastCallStartTime: flag => dispatch(callActions.lastCallStartTime(flag))
    }
};

export default compose(
    connect(mapState, mapDispatch)
)(Container);
