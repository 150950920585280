import _ from 'lodash';
import types from './TableConstants';
import { updateHistory, loadFromUrl } from './_utils';
import update from 'immutability-helper';

export const initState = {
  // for each tableName there will be a reducer
  //
  // tableName: { sort: {}, filter: {}, page: {}, search: '', selectedRowKeys: [] },
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.RESET_TABLE_PROPS:{
        const { tableName,setting } = action;
        const { reducerNext } = setting;
        const newState = {
            ...state[tableName],
            filter: { },
            sort: { },
            page: { },
        };
        return {
            ...state,
            [tableName]: reducerNext(newState),
        };
    }

    case types.ON_TABLE_CHANGE: {
      const { tableName, setting, page, filter, sort } = action;
      const { reducerNext, shouldUpdateHistory, sortFieldMap } = setting;

      const newState = {
        ...state[tableName],
        filter: _.omitBy(filter, v => !v || !v.length),
        sort: _.pick(sort, ['field', 'order']),
        page: {
          pageSize: _.get(page, 'pageSize', 10),
          current: _.get(page, 'current', 1),
        },
      };

      const sortField = newState.sort.field;
      const sortOrder = newState.sort.order;
      if (sortField) {
        newState.sort.fieldGraph = _.get(sortFieldMap, sortField, sortField);
        newState.sort.direction = sortOrder === 'ascend' ? 'ASC' : 'DESC';
      }

      if (shouldUpdateHistory) {
        updateHistory(action.routing, newState);
      }

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.ON_TABLE_SELECT_ROW: {
      const { tableName, setting, selectedRowKeys } = action;
      const { reducerNext } = setting;

      const newState = {
        ...state[tableName],
        selectedRowKeys: selectedRowKeys || [],
      };

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.ON_TABLE_SEARCH: {
      const { tableName, setting, search } = action;
      const { reducerNext, shouldUpdateHistory } = setting;

      const newState = {
        ...state[tableName],
        search: search || '',
        canSearch: false,
      };

      if (shouldUpdateHistory) {
        updateHistory(action.routing, newState);
      }

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.ON_TABLE_SEARCH_ENTER: {
      const { tableName, setting } = action;
      const { reducerNext, shouldUpdateHistory } = setting;

      const newState = {
        ...state[tableName],
        canSearch: true,
        prevSearch: state[tableName].search,
        /* HACK: Need to set page to 1, otherwise will try to fetch page 'page.current' which may yield no data */
        page: update(_.get(state[tableName], 'page', {}), { current: { $set : 1 } })
      };

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.FILTER_TABLE_BY_DATE_RANGE: {
      const { tableName, setting, dateRangeToFilter } = action;
      const { reducerNext, shouldUpdateHistory } = setting;

      const newState = {
        ...state[tableName],
        dateRangeToFilter,
        /* HACK: Need to set page to 1, otherwise will try to fetch page 'page.current' which may yield no data */
        page: update(_.get(state[tableName], 'page', {}), { current: { $set : 1 } })
      };

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.SHOW_TASKS_BY_STATUS: {
      const { tableName, setting, table_select } = action;
      const { reducerNext, shouldUpdateHistory } = setting;

      const newState = {
        ...state[tableName],
        table_select
      };

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.ON_LOAD_PARAMS: {
      const { tableName, setting, params, isLoadFromUrl } = action;
      const { reducerNext, shouldUpdateHistory } = setting;

      let newParams = params || setting.initState;
      if (shouldUpdateHistory && isLoadFromUrl && !params) {
        newParams = loadFromUrl(action.routing);
      }

      const newState = {
        ...state[tableName],
        ...newParams
      };
      // for alert table, when unload, updateHistory is unnecessary
      if (shouldUpdateHistory && isLoadFromUrl) {
        updateHistory(action.routing, newState);
      }

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    default: {
      return state;
    }
  }
};
