import Assessment from './Assessment'

const Result = `
  ... on BPResult {
    systolic_blood_pressure
    diastolic_blood_pressure
    heart_rate
  }
  ... on BGResult {
    blood_glucose
    beforeMeal
    mealType
  }
  ... on HSResult {
    body_weight,
    body_weight_change,
    body_weight_change_baseline
  }
  ... on HRResult {
    heart_rate
  }
  ... on TMResult {
    body_temperature
  }
  ... on POResult {
    blood_oxygen
  }
  ... on ASSESSMENTResult {
    assessment {
      ${Assessment}
    }
    completed
  }
  manualInput
`
export default Result
