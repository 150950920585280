import React from 'react';
import { Divider } from 'antd';
import { AddLabResults } from '../../patient/main/containers/AddLabResults';
import { LabResults } from '../../patient/main/containers/LabResults';
import { Button } from 'antd';
// import helpers from '../helpers/';
// import {graphql} from "react-apollo/index";
// import { compose } from 'react-apollo'
// import gql from "graphql-tag";
// const { leaveAndSaveWorkTask } = helpers;

let LabAddAndListContainer = class extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   newlyAddedDate: undefined
    // };
  }

  // setNewlyAddedDate = date => this.setState({ newlyAddedDate: date });

  renderAddLabRes = props => {
    let { userId } = props;
    // 20200220 [Thong] Failed to decode userId
    // let isdecoded = true
    // try{
    //     atob(userId);
    // }
    // catch (e){
    //     isdecoded = false;
    // }
    // if(isdecoded){
    //     userId = atob(userId).split(':')[1];
    // }
    return (
      <AddLabResults
        //setNewlyAddedDate={this.setNewlyAddedDate}
        {...props}
        userId={userId}
        hideCancel={true}
        routeParams={{ userId }}
      />
    );
  };

  // render() {
  //     const {props, handleClick} = this;
  //     const {hasLabRes} = props;
  //     return <div>
  //         {this.renderAddLabRes(props)}
  //         {this.renderLabResults(props)}
  //         <Button onClick={handleClick} disabled={!hasLabRes}>Done</Button>
  //     </div>
  // }
  renderLabResults = props => {
    return (
      <LabResults
        //newlyAddedDate={this.state.newlyAddedDate}
        {...props}
        userId={props.userId}
        setEditEnabled={() => {}}
        setLabResStatus={() => {}}
      />
    );
  };

  render() {
    const { props } = this;
    return (
      <div>
        {this.renderAddLabRes(props)}
        <Divider />
        {this.renderLabResults(props)}
      </div>
    );
  }
};

export default LabAddAndListContainer;
