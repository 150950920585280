import {_} from 'ihcomponent'
import Base from './Base'
import UserClass from './User'
import I18N from 'modulesAll/I18N'

export default class extends Base{
  format(type, param=false){
    switch(type){
      case 'member_list':
        return this._get_member_list()
      case 'healthCondition':
        return this._getHealthCondition()
      case 'primaryDoctor':
        return this._getPrimaryByRole('PROVIDER:Doctor')
      case 'Doctors':
        return this._getMembersByRole('PROVIDER:Doctor')
      case 'primaryDoctors':
        return this._getPrimaryListByRole('PROVIDER:Doctor')
      case 'primaryManager':
        return this._getPrimaryByRole('PROVIDER:Nurse')
      case 'primaryManagers':
        return this._getPrimaryListByRole('PROVIDER:Nurse')
      case 'Managers':
        return this._getMembersByRole('PROVIDER:Nurse')
      case 'otherMembers':
        return this._getOtherMemebers()
      case 'createdBy':
        return this._getCreatedBy()
      case 'modifiedBy':
        return this._getModifiedBy()
      case 'contactName':
        return this._getContactName()

    }

    return super.format(type, param)
  }

  _get_member_list(){
    const {members} = this.variables

    return _.map(members, (u)=>{
      return new UserClass(u.user)
    })
  }
  _getContactName(){
    const {contactName} = this.variables;
    return contactName;
  }
  _getHealthCondition(){
    const {healthCondition} = this.variables
    return I18N.get(healthCondition)
  }

  _getPrimaryByRole(role){
    const {members} = this.variables
    const doc = _.find(members, (u)=>{
      const roleObject = _.get(u, 'user.allRoles.0.category') || {};
      return `${roleObject.category}:${roleObject.name}` === role && u.primary === true
    })
    if(doc){
      return new UserClass(doc.user)
    }

    return null
      //Did refactor allRoles[0] to currentUser as this function is never called;
  }

  _getMembersByRole(role){
    const member_list = []
    const {members} = this.variables;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    const currentOrgId =  _.get(currentUser,'selectedRole.organization.id','');
    members.forEach((u) => {
        const memberAllRoles = u.user.allRoles;
        const roleObject = _.find(memberAllRoles,(d)=>d.organization.id === currentOrgId) || {};
      // const roleObject = _.get(u, 'user.allRoles.0.category') || {};
        if(`${roleObject.category}:${roleObject.name}` === role && u.primary === false){
          member_list.push(new UserClass(u.user))
        }
    })

    return member_list
  }

  _getPrimaryListByRole(role){
    const {members} = this.variables
    const rs = []
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    const currentOrgId =  _.get(currentUser,'selectedRole.organization.id','');
      _.each(members, (u)=>{
        const memberAllRoles = u.user.allRoles;
        const roleObject = _.find(memberAllRoles,(d)=>d.organization.id === currentOrgId) || {};
        if(`${roleObject.category}:${roleObject.name}` === role && u.primary === true){
        rs.push(new UserClass(u.user))
      }
    })

    return rs
  }
  _getOtherMemebers() {
    const doctors = []
    const nurses = []
    const { members } = this.variables

    members.forEach((u) => {
      if (!u.primary) {
        (u.role == 'DOCTOR' ? doctors : nurses).push(new UserClass(u.user))
      }
    })

    return doctors.concat(nurses)
  }

  _getCreatedBy() {
    const { createdBy } = this.variables
    return createdBy && createdBy.profile.fullName
  }

  _getModifiedBy() {
    const { modifiedBy } = this.variables
    return modifiedBy && modifiedBy.profile.fullName
  }
}
