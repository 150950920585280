import {
    React, IHTable, _
} from 'ihcomponent'
import {createContainer, SuperContainer, DataHelper} from '../../../../lib/utils'
import CareTeamClass from 'modulesAll/graphql/class/CareTeam'
import action from '../actions/main'
import UserClass from '../../../graphql/class/User'

const Component = class extends SuperContainer{
    static displayName = 'patient/programTab/containers/ProgramTable'

    renderMain(){
        const p = this.getProps()
        return <IHTable {...p} />
    }

    getProps(){
        const {program, dispatch} = this.props
        const dataSource = _.map(this.props.list, (item)=>(new CareTeamClass(item)))

        return {
            rowKey : doc=>doc.key,
            columns : [
                {
                    title : 'Name',
                    key : 'name',
                    render : (t, doc)=>{
                        return doc._get('name')
                    }
                },
                {
                    title : 'Members',
                    key : 'Members',
                    render : (t, doc)=>{
                        return _.map(_.get(doc,'variables.members'), (u, i)=>{
                          const eachUser = new UserClass(u.user);
                          return <div key={i}>{eachUser._get('profile.fullName')} ({u.primary && 'Primary '}{eachUser._getRole()})</div>
                        })
                    }
                },
                //remove based on SC-1552
                // {
                //     title : 'Health Condition',
                //     key : 'Health Condition',
                //     render : (t, doc)=>{
                //         return doc.format('healthCondition')
                //     }
                // }
            ],
            dataSource,
            rowSelection : {
                type : 'radio',
                selectedRowKeys : program.selectCareteam.index,
                onChange : (key, row)=>{
                    let teamNameArray = []
                    let teamInfo = []
                    if(row.length > 0) {
                      teamNameArray = _.map(row, 'variables.name')
                      teamInfo = _.map(row, 'variables')
                    }
                    dispatch(action.setSelectCareteam(key, key, teamNameArray, teamInfo))
                }
            }
        }
    }

    runOnceAfterDataLoading(){
        if(this.props.currentProgram){
            const team = this.props.currentProgram.team || []
            const tid = _.map(team, (t)=>t.id)
            const tname = _.map(team, (t)=>t.name)
            const teamInfo = _.map(team, (t)=> t)

            _.delay(()=>{
                this.props.dispatch(action.setSelectCareteam(tid, tid, tname, teamInfo))
            }, 200)

        }
    }

    shouldComponentUpdate(np, nextState){
        if(np.currentProgram && (!this.props.currentProgram || np.currentProgram.id !== this.props.currentProgram.id)){
            const team = np.currentProgram.team || []
            const tid = _.map(team, (t)=>t.id)
            const tname = _.map(team, (t)=>t.name)
            const teamInfo = _.map(team, (t)=> t)

            _.delay(()=>{
                this.props.dispatch(action.setSelectCareteam(tid, tid, tname, teamInfo))
            }, 200)

        }
        return true
    }
}

export default createContainer((Component), ({patient}, ownProps)=>{
    const currentUser = DataHelper.getCurrentUser()
    const program = ownProps.currentProgram || patient.program.selectedProgram
    let team = currentUser.team || []
    // don't filter teams with healthCondition
    // if(program){
    //     team = _.filter(team, (t)=>t.healthCondition === program.healthCondition)
    // }

    return {
        list : team,
        loading : !program,
        ...patient
    }
})
