import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { convertBG_mmolTomgdl } from 'libModule/utils/convertUnits';
import { graphql, compose } from 'react-apollo'
import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList';
import moment from 'moment';
import { IHSearchTable, IHLoading, IHButton, _,IHInput,message } from 'ihcomponent';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';

const BGLineChartComponent = class extends React.Component {
    groupData = (array,mealTypes=[],beforeMeal)=>{
        array.sort((a1,a2)=>a1.date-a2.date);
        const filteredArray = _.filter(array,(a)=> (typeof beforeMeal =='boolean' ? (a.beforeMeal == beforeMeal) : true )&&(mealTypes.length==0 ? true : _.includes(mealTypes,a.mealType) ));
        const groupedByDate = _.groupBy(filteredArray,a=>moment(a.date).format('MM/DD/YYYY'));
        const data  = [];
        for (let [k, v] of Object.entries(groupedByDate)) {
            const a = groupedByDate[k];
            const len = v.length;
            const sum = _.reduce(a, (sum,n)=>{ return sum + _.get(n,'blood_glucose.value',0); },0);

            data.push({
                x:moment(k).valueOf(),
                y:Number(convertBG_mmolTomgdl(sum/(len||1))) });
        }

        return data;
    }

    generateSeriesInfo = (filterOutMissed,fromDate)=>{
     const seriesMap = {
             'AVE_BEFORE_MEAL':{
                 mealTypes:[],
                 beforeMeal:true,
                 name:'Average Before Meal',
                 type:'line',
                 color:'#7bd002',
                 marker: {
                     enabled: false,
                 },
             },
             'AVE_AFTER_MEAL':{
                 mealTypes:[],
                 beforeMeal:false,
                 name:'Average After Meal',
                 type:'line',
                 color:'#0091ff',
                 marker: {
                     enabled: false,
                 },
             },
             'AVE_BEFORE_BREAKFAST':{
                 mealTypes:['BREAKFAST'],
                 beforeMeal:true,
                 name:'Average Before Breakfast',
                 type:'line',
                 color:'#f095bd',
                 marker: {
                     enabled: false,
                 },
             },
             'AVE_OVERNIGHT_BEDTIME':{
                 mealTypes:['OVERNIGHT','BEDTIME'],
                 beforeMeal:undefined,
                 name:'Average Overnight/Bedtime',
                 type:'line',
                 color:'#6236ff',
                 marker: {
                     enabled: false,
                 },
             }
        }
        const seriesConfig = [];
        for (let [k, v] of Object.entries(seriesMap)) {
            const { mealTypes,beforeMeal,type,name,color,marker={} } = v;
            seriesConfig.push({
                data: this.groupData(filterOutMissed, mealTypes, beforeMeal),
                type,
                name,
                color,
                marker
                // tooltip:{
                //     headerFormat:`${moment(point.x).format('MM/DD/YYYY')}`
                // }
            });
        }
        return seriesConfig;
    }

    renderBGChart = (isInChat)=>{
        const { props,loading } = this;
        const { resultList,fromDate,toDate } = props;
        const { patientId } = props;

        const filterOutMissed = _.filter(resultList,(a)=>a.severity!='MISSED');
        const pointMap = {
            'MISSED':{
                fillColor:'gray'
            },
            'CRITICAL':{
                fillColor:'red'
            },
            'RISK': {
                fillColor:'yellow'
            },
            'BEFORE_MEAL':{
                symbol:'circle',
                color:'gray'
            },
            'AFTER_MEAL': {
                symbol:'circle',
                color: 'white'
            }
        };

        const seriesConfig = this.generateSeriesInfo(filterOutMissed,fromDate);
        const options =  {
            chart: {
                type: 'line'
            },
            title:null,
            plotBackgroundColor:'#0099FF',
            yAxis: {
                title: {
                    text: 'mg/dL'
                },
                min:0,
                max:600,
                gridLineColor: 'transparent',
                plotBands: [{
                    from: 60,
                    to: 180,
                    color: 'rgb(119,119,119,0.05)',
                }]
            },
            xAxis: {
                    type: 'datetime',
                    tickWidth:0,
                    tickInterval: 24 * 3600 * 1000,
                    min:fromDate.valueOf(),
                    max:toDate.valueOf(),
                    labels:{
                        formatter:(value)=>{
                            const xValue = value.value;
                            return moment(xValue).format('MM/DD/YYYY');
                        }
                    }
            },
            legend: {
                align: 'left',
                verticalAlign: 'top',
            },
            tooltip: {
                shared: true,
                crosshairs: true,
                dateTimeLabelFormats: {
                    day: "%m/%e/%Y",
                }
            },
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: function () {
                            const { name } = this;
                            Mixpanel.track('clicked',name+'_BG',null,{ PATIENT_ID:patientId })
                        }
                    }
                }
            },
            time:{
                useUTC:false,
                timezone:moment.tz.guess()
            },
            credits: {
                enabled: false
            },
            series:seriesConfig
        }
        return <HighchartsReact  highcharts={Highcharts} options={options} />

    }
    render(){
        const { props } = this;
        const { loading,isInChat } = props;
        const style = loading ? { minHeight:400,justifyContent:'center',alignItems:'center',display:'flex' } : {};

        return <div style={style}>
                    { loading? <IHLoading/> : this.renderBGChart(isInChat) }
               </div>
    }
}
const withResData = graphql( resultList, {
    options: (ownProps) => {
        const { fromDate,toDate } = ownProps;
        return {
            variables: {
                "filters": {
                    "enrolledProgramId": ownProps.enrolledProgramId,
                    "type": "BG",
                    fromDate,
                    toDate,
                },
                "count": 999999, // retrieve all at once; intended behavior
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ownProps, data}) => {
        const { loading, resultList } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            resultList: _.get(resultList,'data',[]),
            loading,
        }
    }
})

export default compose(withResData)(BGLineChartComponent);
