const SERVER_URL = process.env.SERVER_URL || window.VSM_CONFIG.SERVER_URL || `http://${location.hostname}:4000`
// const UPLOAD_URL = `${window.VSM_CONFIG.SERVER_URL}/gothumb/uploadBase64`
// const SIGNED_URL = `${window.VSM_CONFIG.SERVER_URL}/gothumb/file`

// const UPLOAD_URL = `http://35.165.217.6:8888/uploadBase64`
// const SIGNED_URL = `http://35.165.217.6:8888/file`

const gothumbServerUrl = process.env.GOTHUMB_SERVER_URL || window.VSM_CONFIG.GOTHUMB_SERVER_URL || 'http://localhost:3000';

const UPLOAD_URL = `${gothumbServerUrl}/uploadBase64`
const SIGNED_URL = `${gothumbServerUrl}/gothumb/file`

const BRAND = process.env.BRAND;

console.log(UPLOAD_URL, SIGNED_URL);

const config = {
  SERVER_URL,
  uploadFileUrl : UPLOAD_URL,
  signFileURL : SIGNED_URL,
  SECRET_KEY : 'WdXg91IKo8tj1sl3uQDP',
  BRAND
}

export default config
