import React from 'react';
import HealthConditionPicker from '../../../patient/AdditionalInfo/Components/HealthConditionPickerComponent/HealthConditionPicker'
import { Table,Button,Icon,Checkbox,Form,Input } from 'antd';
import {compose, graphql} from "react-apollo/index";
import userGQL from "../../../graphql/user";
import {connect} from "react-redux";
import {DATE_HELPERS} from "../../../../lib/helpers/date-helpers";
import editUser from 'modulesAll/graphql/mutation/editUser';
import Client from 'libModule/gqlClient';
import {getMemberProfile} from "../../AdditionalInfo/helpers";
import { message,IHButton } from 'ihcomponent';
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram'
import enrolledProgram from 'modulesAll/graphql/enrolledProgram';
import newCarePlanActions from '../Careplan/actions/NewCarePlanActions';

class ICDEditComponent extends React.Component {
    addHealthCondition = (value) => {
        const {healthConditions} = this.props.form.getFieldsValue();
        if (healthConditions.indexOf(value) == -1) {
            this.props.form.setFieldsValue({
                'healthConditions': healthConditions.concat(value)
            })
        }
    }

    renderHealthConditionPicker = () => {
        const { disabled,isBtnDisabled }  = _.get(this,'props.state.ICD10Code');
        const { setInputFieldDisabled } = this.props;

        return <div className='ant-col-24'>
            <div><p className='care-plan-fields'>
                  <b>Health Conditions</b>
                 </p>
            </div>
            <div style={{display:'flex',border:'1px solid #e8e8e8',borderBottom:'unset',padding:5}}>
                <label style={{fontSize: 12,margin:'0px 10px 0px 10px',alignSelf:'center'}}>ICD Code Search</label>
                <div style={{width:200}}>
                    <HealthConditionPicker addHealthCondition={this.addHealthCondition} disabled={disabled}/>
                </div>
                <div style={{ alignSelf:'center',marginLeft:'auto' }}>
                    <IHButton onClick={()=>disabled ? setInputFieldDisabled('ICD10Code') : this.saveEdit() }
                              className={ disabled ? 'care-plan-edit-btn' : 'care-plan-done-btn' }
                              disabled={isBtnDisabled}
                              size='exsmall'
                    >
                        { disabled ? 'Edit' : 'Done' }
                    </IHButton>
                </div>
            </div>
        </div>
    }

    copyBillableHealthConditions(array){
        const copiedArray = array.slice();

        return copiedArray.map(i=>({
            description:i.description,
            code:i.code
        }));
    }

    removeHealthCondition=(index)=> {
        const { healthConditions,billableHealthConditions } = this.props.form.getFieldsValue();
        const copyHealthConditions = healthConditions.slice();
        const copyBillableHealthConditions = Array.isArray(billableHealthConditions) ? this.copyBillableHealthConditions(billableHealthConditions) :[];
        const value = _.get(healthConditions[index].split('::'),'1',null);
        this.props.form.setFieldsValue({
            healthConditions: _.remove(copyHealthConditions, function (_, i) {
                return i != index;
            }),
            billableHealthConditions: value ? _.remove(copyBillableHealthConditions, function (o) {
                return o.code!=value;
            }) : copyBillableHealthConditions
        })


    }

    onChange(v){
        const { billableHealthConditions } = this.props.form.getFieldsValue();
        const copyBillableHealthConditions = Array.isArray(billableHealthConditions) ? this.copyBillableHealthConditions(billableHealthConditions) :[];
        const code = _.get(v,'code','');
        if(copyBillableHealthConditions.findIndex((i)=>code==i.code)!=-1){
            this.props.form.setFieldsValue({
                billableHealthConditions: _.remove(copyBillableHealthConditions, function (o) {
                    return o.code!=code;
                })
            })
        }
        else{
            copyBillableHealthConditions.push({
                description:v.name,
                code:code
            });
            this.props.form.setFieldsValue({
                billableHealthConditions: copyBillableHealthConditions
            })
        }
    }

    renderHealthConditionTable() {

        const {healthConditions} = this.props.form.getFieldsValue();
        const disabled  = _.get(this,'props.state.ICD10Code.disabled');
        const healthConditionsSource = _.map(healthConditions, (value) => (
            {
                name: value.split('::')[0],
                code: value.split('::')[1],
                value: value
            }))
        const tableColumn = [
            {
                key: 'code',
                title: 'Code',
                width: '15%',
                dataIndex: 'code',
            },
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name'
            },
            {
                key: 'billableHealthConditions',
                title: 'Billable',
                width: '15%',
                render: (v, doc) => {
                    const {billableHealthConditions} = this.props.form.getFieldsValue();
                    const checked = !Array.isArray(billableHealthConditions) ? false : billableHealthConditions.findIndex((i) => v.code == i.code) != -1;

                    return v.code ? <Checkbox onChange={() => this.onChange(v, doc)} checked={checked} disabled={disabled}/> : '';
                }

            },
            {
                key: 'remove',
                width: '15%',
                title: 'Action',
                render: (text, record, index) => <Button onClick={() => this.removeHealthCondition(index)} disabled={disabled}><Icon
                    type="close"/></Button>
            }
        ]
        return <div style={{ border:'solid 1px #e8e8e8' ,borderBottom:'unset'}} className='ant-col-24'>
                    <Table dataSource={healthConditionsSource}
                           columns={tableColumn}
                           rowKey={(key) => key.code}
                           pagination={false}
                    />
                </div>
    }

    saveEdit = async (mutatedValue,stopResetEditMode)=>{
        const user = _.get(this,'props.user');
        const userId  = _.get(this.props,'user.id');
        const currentProgram = _.get(this,'props.currentProgram');
        const epId = currentProgram.id;
        let memberProfile = getMemberProfile(user.profile,['language','race','maritalStatus','birthday'])
        let formValues = this.props.form.getFieldsValue();
        memberProfile.healthConditions = formValues.healthConditions;
        memberProfile.billableHealthConditions = formValues.billableHealthConditions;
        let variables = { id:userId };
        let roleId;
        if (user&&user.allRoles.length > 0) {
            const role = user.allRoles.find( val => { return val.category === 'MEMBER'; });
            roleId = role.refId;
        }
        variables = Object.assign(variables,{memberProfile});
        variables.role = roleId;

        try{
            const res = await Client.mutate({ mutation:editUser,
                variables:variables,
                refetchQueries:[{ query:userGQL, variables: { id:userId },
                    fetchPolicy:'network-only'}]
            });
            /*
            if(res){
                const problemListStr = formValues.billableHealthConditions.map(i=>i.description).join('\n');
                const epVariables = {
                    id:epId,
                    problemList:problemListStr
                };
                Client.mutate({mutation:editEnrolledProgram,
                    variables:epVariables,
                    refetchQueries:[{ query:enrolledProgram,variables:{ id:epId },
                    fetchPolicy:'network-only'}]
                }).then(res=>{
                    this.props.updateField('problemList',res.data.editEnrolledProgram.problemList,true);
                });
            }
            */
            if(!res.error) {
                const { setInputFieldDisabled } = this.props;
                setInputFieldDisabled('ICD10Code');
                message.success('All Changes Saved');
            }
        }
        catch(error){
            console.log(error);
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const healthConditions = _.get(this,'props.user.profile.healthConditions',[]);
        const billableHealthConditions = _.get(this,'props.user.profile.billableHealthConditions',[]);
        const disabled  = _.get(this,'props.state.ICD10Code.disabled');
        return <Form style={{display:'flex',flexDirection:'column',marginTop:30}} disabled={disabled}>
                    <Form.Item key='healthConditions' style={{display:'none'}}>
                        {getFieldDecorator('healthConditions', { initialValue: healthConditions||[]})(<Input style={{display:'none'}}/>)}
                    </Form.Item>
                    <Form.Item key='billableHealthConditions' style={{display:'none'}}>
                        {getFieldDecorator('billableHealthConditions',{ initialValue: this.copyBillableHealthConditions(billableHealthConditions) || []})(<Input style={{display:'none'}}/>)}
                    </Form.Item>

                    {this.renderHealthConditionPicker()}
                    {this.renderHealthConditionTable()}

                    <div style={{margin: '20px 0px'}}>

                    </div>
              </Form>

    }
}

const withData = graphql(userGQL, {
    options: (ownProps)=>{
        const userId  = _.get(ownProps,'currentProgram.user.id');
        return {
            variables: {
                id: userId
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props:({data})=>{

        const { loading,user } = data;
        if(loading){
            return  {
                loading:loading
            }
        }
        if(user) {
            return {
                user
            };
        }
    }
});

const mapDispatch = (dispatch) => {
    return{
        updateField: (field,str,isUpdated) => dispatch(newCarePlanActions.updateField(field,str,isUpdated)),
    }
}

ICDEditComponent = Form.create()(ICDEditComponent);

export default compose(connect(null,mapDispatch),withData)(ICDEditComponent);

