import gql from 'graphql-tag'
import {User} from './schema/User'

export default gql `
  query searchPatient(
    $id:String!,
    $firstName : String,
    $lastName : String,
    $dob : String!
  ){
    searchPatient(
      identification:$id,
      firstName:$firstName,
      lastName:$lastName,
      dateOfBirth:$dob
    ){
      ${User}
    }
  }
`
