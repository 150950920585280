import React, { Component } from 'react';
import {Col, Row, Form, Checkbox} from "antd";

import {helpers as dataHelper} from '../helper'
const {Item} = Form;

class VisitInputCheckbox extends Component{

    render() {
        const { getFieldDecorator } = this.props.form;

        const {visitInfo, editStatus} = this.props;
        let initArray = null;
        if(editStatus && visitInfo.conditionsList){
            initArray = dataHelper.conditionsListToCheckbox(visitInfo.conditionsList);
        }

        return (
            <Item >
                {getFieldDecorator('conditionsList', {
                    initialValue: initArray
                })(
                    <Checkbox.Group style={{ width: '100%' }}>
                        <Row>
                            {dataHelper.conditionsList.map((value, index) => {
                                return (
                                    <Col span={index === 4 ? 4 : 5} key={value}>
                                        <Checkbox value={value}>{value}</Checkbox>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Checkbox.Group>
                )}
            </Item>
        )
    }
}

export default VisitInputCheckbox;
