import React, { Component } from 'react';
import '../style.scss';
import { Modal, Radio } from 'antd';
import { IHLoading } from 'ihcomponent';
import moment from 'moment';
import { symbols } from '../constant/constants';
import InventoryNoteComponent from '../component/InventoryNoteComponent';
import InventoryListComponent from '../component/InventoryListComponent';

export class InventoryListContainer extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalVisible: false,
      notes: null, // { date, notes }
    }
  }

  openNoteModal = notes => this.setState({ modalVisible: true, notes });
  
  closeNoteModal = () => this.setState({ modalVisible: false, notes: null });

  render() {
    const { loading, filter, setFilter, hasTodayRef, onClickToday } = this.props;
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 16,
            padding: '35px 10px 10px 10px',
          }}
        >
          <div>
            <h4>Inventory Track</h4>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Radio.Group disabled={loading} value={filter} onChange={setFilter}>
              <Radio value='Removed'>Give out {symbols.Removed}</Radio>
              <Radio value='Added'>Restock {symbols.Added}</Radio>
              <Radio value='AddedAndRemoved'>Both {symbols.Added}{symbols.Removed}</Radio>
            </Radio.Group>
            <span>
              <a 
                disabled={!_.get(this, 'props.hasTodayRef')} 
                onClick={onClickToday} 
              >
                Today
              </a>
            </span>
          </div>
        </div>
        <div>
          {
            this.props.dataSource && this.props.totalAmounts && this.props.forecastAmounts ? 
              <InventoryListComponent 
                {...this.props} 
                openNoteModal={this.openNoteModal} 
              />
              :
              <IHLoading />
          }
          <Modal
            visible={this.state.modalVisible}
            title={this.state.notes ? `Notes for ${moment(_.get(this, 'state.notes.date')).format('MM/DD/YYYY')}` : ''}
            onCancel={this.closeNoteModal}
            destroyOnClose={true}
            footer={null}
            width={620}
          >
            <InventoryNoteComponent notes={_.get(this, 'state.notes')}/>
          </Modal>
        </div>
      </div>
    )
  }
}

export default InventoryListContainer;
