import {
  IHLoading, message, IHButton
} from 'ihcomponent'
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProviderEditRole from '../component/providerEditRoleComponent'
import OrganizationList from '../component/roleListForSelectComponent'
import { createContainer, getRouterParam } from 'libModule/utils'
import resendOnboardEmail from 'modulesAll/graphql/resendOnboardEmail'
import { userWithoutTeam as userGQL } from '../../../graphql/user'
import organizationListGQL from '../../organization/query/organizationList';
import { graphql, compose } from 'react-apollo'
import get from 'lodash/get'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import { formatErrorMessage, goPath } from 'libModule/utils'
import { openEditEmailModal, openEditPhoneModal } from 'modulesAll/provider/ProviderEdit/ProviderEditDuck'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { connect } from 'react-redux'
import createTableAction from 'libModule/table/TableActions'
import Client from 'libModule/gqlClient';
import editUserGQL from 'modulesAll/graphql/mutation/editUser';

class Container extends Component {
  static displayName = 'provider/ProviderShow/containers/Detail'

  render() {
    return (
      <div className="vsm-main" style={{ minWidth: 'auto', padding: '0 20px' }}>
        {this.renderTopButton()}
        {this.renderBody()}
        {this.renderSelectRoleList()}
      </div>
    )
  }

  renderTopButton() {
    const b1 = {
      label: '< Back to Providers List',
      size: 'large',
      type: 'link',
      onClick: () => {
        goPath('/superadmin/providers')
      }
    }

    return (
      <div className="v-top">
        <IHButton style={{ color: '#ff8932' }} {...b1} />
      </div>
    )
  }

  renderBody() {
    const data = this.props.userData;
    if (!data.user) {
      return <IHLoading />
    }

    const childProps = {
      data: data.user
    }

    return <ProviderEditRole onRemoveRole={this.removeRole} {...childProps} />
  }

  renderSelectRoleList() {
    const props = this.props;
    const organizationList = this.props.organizationList;
    const user = this.props.userData;

    if (!organizationList) {
      return <IHLoading />
    }
    return <OrganizationList organizationList={organizationList} onAddRole={this.addRole}  {...props} />;
  }

  addRole(user, roleRefId) {
    let roles = user.allRoles;
    let role = [];
    roles.forEach(r => {
      role.push(r.refId);
    });
    role.push(roleRefId);
    let userVariables = {
      id: user.id,
      role
    };
    changeRole(userVariables,this.props);
  }

  removeRole(user, roleRefId) {
    let roles = user.allRoles;
    let role = [];
    roles.forEach(r => {
      if (r.refId != roleRefId) {
        role.push(r.refId);
      }
    });
    let userVariables = {
      id: user.id,
      role
    };
    changeRole(userVariables,this.props);
  }
}

const changeRole = (userVariables,props) => {
  Client.mutate({
    mutation: editUserGQL,
    variables: userVariables,
    refetchQueries: [
      {
        query: userGQL, variables: { id: userVariables.id },
        fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true
      },
      {
        query: organizationListGQL, variables: getOrganizationListVariables(props),
        fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true
      }
    ]
  });
}

Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const userData = graphql(userGQL, {
  options: (ownProps) => {
    const id = ownProps.id
    return {
      variables: {
        id
      },
      notifyOnNetworkStatusChange: true,
    }
  },
  props: ({ data }) => {
    return {
      userData: data
    }
  }
})
const tableActions = createTableAction('organization_list', {
  shouldUpdateHistory: false,
  sortFieldMap: {
    createdAt: 'CREATED_AT',
    modifiedAt: 'MODIFIED_AT',
    careTeam: 'NAME'
  },
})

const getOrganizationListVariables = (ownProps) => {
  const variables = {
    page: _.get(ownProps, 'page.current', 1),
    count: 10,
    filters: {
      search: {
        fields: ['NAME'],
        match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
      }, active: true
    },
    search: {
      fields: ['NAME'],
      match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
    },
    sort: null,
  }

  const sortField = _.get(ownProps, 'sort.fieldGraph', null)
  if (sortField) {
    variables.sort = {
      field: sortField,
      direction: _.get(ownProps, 'sort.direction', 'ASC'),
    }
  }
  return variables;
};

const organizationListData = graphql(organizationListGQL, {
  options: (ownProps) => {
    const variables = getOrganizationListVariables(ownProps);
    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ data }) => {
    return {
      organizationList: data.organizationList,
      loading: data.loading,
      refetch: data.refetch
    }
  }
})

const mapState = (state, ownProps) => {
  const id = get(state.routing, 'params.providerId')
  return {
    ...state.globalTables.organization_list,
    ...ownProps,
    id
  }
}

export default compose(connect(
  mapState,
  (dispatch) => {
    return {
      ...tableActions
    }
  }
), userData, organizationListData)(Container);
