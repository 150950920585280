import adminMenu from 'modulesAll/utils/constants/menuPath/admin'
import patientMenu from 'modulesAll/utils/constants/menuPath/patient'
import providerMenu from 'modulesAll/utils/constants/menuPath/provider'
import superAdminMenu from 'modulesAll/utils/constants/menuPath/superAdmin'
import vendorMenu from 'modulesAll/utils/constants/menuPath/vendor'
import { ROLES } from 'modulesAll/utils/constants/role'
import { HOMEPAGE as adminHomePage } from 'modulesAll/utils/constants/menuPath/admin'
import { HOMEPAGE as patientHomePage } from 'modulesAll/utils/constants/menuPath/patient'
import { HOMEPAGE as providerHomePage } from 'modulesAll/utils/constants/menuPath/provider'
import { HOMEPAGE as superAdminHomePage } from 'modulesAll/utils/constants/menuPath/superAdmin'
import { HOMEPAGE as vendorHomePage } from 'modulesAll/utils/constants/menuPath/vendor'

export const MENUS = {
  [ROLES.admin]: adminMenu,
  [ROLES.patient]: patientMenu,
  [ROLES.doctor]: providerMenu.doctor,
  [ROLES.nurse]: providerMenu.nurse,
  [ROLES.medicalAssistant]: providerMenu.medicalAssistant,
  [ROLES.superAdmin]: superAdminMenu,
  [ROLES.vendor]: vendorMenu,
}

export const HOMEPAGES = {
  [ROLES.admin]: adminHomePage,
  [ROLES.patient]: patientHomePage,
  [ROLES.doctor]: providerHomePage,
  [ROLES.nurse]: providerHomePage,
  [ROLES.medicalAssistant]: providerHomePage,
  [ROLES.superAdmin]: superAdminHomePage,
  [ROLES.vendor]: vendorHomePage,
}
