import Client from 'libModule/gqlClient';
import { userForChat, userForAvatar } from 'graphqlModule/user';
import getUserChatInfo from 'graphqlModule/getUserChatInfo';
import PubNubReact from 'pubnub-react';
import { COMMON_HELPERS } from 'libModule/helpers/common-helpers'


/****** Our API ******/

const getUserProfile = (uuid) => {
  const variables = { id: uuid };
  return Client.query({
    query: userForChat,
    variables: {...variables},
    fetchPolicy: 'network-only'
  });
};

const apiGetUserChatInfo = () => {
  const userId = sessionStorage.getItem('currentUserId');
  return Client.query({
    query: getUserChatInfo,
    variables: { userId },
    fetchPolicy: 'network-only'
  })
};

const getUserAvatar = id => {
  const variables = { id };

  return Client.query({
    query: userForAvatar,
    variables: { ...variables },
    fetchPolicy: 'network-only'
  });
};

const checkAndAddUser =  (publisher,newUserMap,avatarError)  =>{
        if (publisher.split('-')[0] !== 'team') {

            // if patient sent chat message, check to see if they are in userMap
            try {

                if (typeof COMMON_HELPERS.checkIfEncoded(publisher) === 'boolean' && COMMON_HELPERS.checkIfEncoded(publisher)) {

                    const decodedPatientId = atob(publisher).split(':')[1];

                    // if patient is not in userMap
                    if (!newUserMap[decodedPatientId]) {
                      return  API.getUserProfile(publisher)
                            .then(res => {
                                const userObj = {
                                    firstName: res.data.user ? res.data.user.profile.firstName : '',
                                    lastName: res.data.user ? res.data.user.profile.lastName : '',
                                    avatar: res.data.user.profile.avatar && res.data.user.profile.avatar.link ? res.data.user.profile.avatar.link : '/image/default_avator.png',
                                    profileType: res.data.user && res.data.user.allRoles && res.data.user.allRoles.length > 0 ? res.data.user.allRoles[0].name : 'UnknownType',
                                };
                                newUserMap[decodedPatientId] = userObj;
                            })
                            .catch(err => console.log('Error fetching user profiles: ', err));
                    }
                } else if (!newUserMap[publisher]) {

                   const encodedPatientId = btoa(`accounts:${publisher}`);
                   return  API.getUserProfile(encodedPatientId)
                        .then(res => {
                            const userObj = {
                                firstName: res.data.user ? res.data.user.profile.firstName : '',
                                lastName: res.data.user ? res.data.user.profile.lastName : '',
                                avatar: res.data.user.profile.avatar && res.data.user.profile.avatar.link ? res.data.user.profile.avatar.link : '/image/default_avator.png',
                                profileType: res.data.user && res.data.user.allRoles && res.data.user.allRoles.length > 0 ? res.data.user.allRoles[0].name : 'UnknownType',
                            };
                            newUserMap[publisher] = userObj;
                        })
                        .catch(err => console.log('Error fetching user profiles: ', err));
                }
                else if (newUserMap[publisher] && avatarError) {
                    const encodedPatientId = btoa(`accounts:${publisher}`);
                    return API.getUserProfile(encodedPatientId)
                        .then(res => {
                            const userObj = {
                                firstName: res.data.user ? res.data.user.profile.firstName : '',
                                lastName: res.data.user ? res.data.user.profile.lastName : '',
                                avatar: res.data.user.profile.avatar && res.data.user.profile.avatar.link ? res.data.user.profile.avatar.link : '/image/default_avator.png',
                                profileType: res.data.user && res.data.user.allRoles && res.data.user.allRoles.length > 0 ? res.data.user.allRoles[0].name : 'UnknownType',
                            };
                            newUserMap[publisher] = userObj;
                        })
                        .catch(err => console.log('Error fetching user profiles: ', err));
                }
            } catch (err) {
                console.log('Err: ', err, ' Publisher on message not valid: ', publisher);
            }
        }
}

/****** PubNub API ******/

let pubNub = null;

const getPubNub = () => {
  return pubNub;
}
 
const apiInitializePubNub = (publishKey, subscribeKey, authKey, userId) => {
    pubNub = new PubNubReact({
      publishKey: publishKey,
      subscribeKey: subscribeKey,
      authKey: authKey,
      logVerbosity: false, // use for debugging
      ssl: true,
      uuid: userId,
      presenceTimeout:300, // used to suppress presence heartbeats
      heartbeatInterval:0,
      restore: true // prevent missing messages
    });
  }


export const API = {
  getUserProfile,
  getUserAvatar,
  apiGetUserChatInfo,
  apiInitializePubNub,
  getPubNub,
  checkAndAddUser
};
