import React from 'react';
import { Icon } from 'antd';
import {goPath, RequestCache} from 'libModule/utils'
import { compose } from 'recompose';
import {graphql} from "react-apollo/index";
import { getMonthlyTimeSpent } from '../query/';
import moment from 'moment';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

class TimeSpentComponent extends React.Component{

    routeToTimeSpent(patientId){
        Mixpanel.track('clicked','billable_time','patient_profile',{ PATIENT_ID:patientId })
        goPath(`/patients/${patientId}/timespent/`)
    }

    render(){
        const { monthlyTimeSpent,userId } = this.props;
        const  totalTime  = _.get(monthlyTimeSpent,'totalTime',0);
        const timeInMinutes = !totalTime ? 0 : Math.round(totalTime/60);
        let min = timeInMinutes > 1 ? 'mins' : 'min';
        return(
            <div className='timeSpent' onClick={()=>this.routeToTimeSpent(userId)}>
                <div style={{padding:10}}>
                    <div>
                        <header style={{fontWeight:900}}>Total Billable time</header>
                    </div>
                    <div>
                        <span style={{fontSize:'26px',color:'#67afe2',letterSpacing:-0.3}}>{timeInMinutes}</span>
                        <span>{min}</span>
                        <Icon type="right" style={{fontSize:'16px',color:'#4278c3'}}/>
                    </div>
                </div>
            </div>
        )
    }
}

const withData = graphql(getMonthlyTimeSpent,{
    options:(ownProps)=>{
        const monthOfYear = Number(moment().format('YYYYMM'));
        return {
            variables:{
                memberId:ownProps.userId,
                monthOfYear
            },
            fetchPolicy:'network-only'
        }
    },
    props:({data:{getMonthlyTimeSpent: monthlyTimeSpent,loading:loading }})=>{
        if(loading){
            return {
                loading
            }
        }
        return {
            monthlyTimeSpent
        }
    }
});
export default compose(withData)(TimeSpentComponent);
