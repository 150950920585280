import { IHTab, message, IHLoading,  _ } from 'ihcomponent'
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import EnrolledProgramClass from 'modulesAll/graphql/class/EnrolledProgram';
import deleteEnrollProgram from 'graphqlModule/deleteEnrollProgram';
import UserClass from 'graphqlModule/class/User'
import { TabWrapper, goPath, GQLHelper } from 'libModule/utils'
import actions from '../actions/Tab'
import actionProgramTab from '../../programTab/actions/main'
import ProgramTab from '../../programTab/containers/main'
import CareplanTab from 'patientModule/Careplan/Vital/containers/CommonContainer'
import DeviceTab from '../../deviceTab/containers/main'
//import TemporaryButton from './TemporaryButton'
import { createAuditLog } from 'libModule/utils/auditLog'
import RequestCache from 'libModule/requestCache'
import I18N from 'modulesAll/I18N'
import moment from 'moment';
import { IHButton } from 'ihcomponent';
import { modalAction as commonAction } from 'modulesAll/common/actions';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { browserHistory } from 'react-router';

const Container = class extends React.Component {
    static displayName = 'patient/main/containers/Tab'

    constructor(p) {
      super(p);
      this.confirmDelete = this.confirmDelete.bind(this);
    }

    initVar() {
      if(this.props.data) {
        this.programInfo = this.props.data.enrolledProgram;
        this.programObject = new EnrolledProgramClass(this.programInfo);
      }
    }

    componentWillMount() {
      const currentProgram = this.props.currentProgram
      this.props.dispatch(actions.setTabsStep('programme'));
      if (currentProgram && currentProgram.id && currentProgram.startDate) {
        this.props.dispatch(actionProgramTab.changeStartDate( moment(currentProgram.startDate).toDate()));
      }
    }

    componentWillReceiveProps(nextProps, nextState) {
      const currentProgram = this.props.currentProgram
      const nextProgram = nextProps.currentProgram
      const currentStartDate = _.get(this.props.currentProgram, 'startDate')
      const nextStartDate = _.get(nextProps.currentProgram, 'startDate')
      if(
          (
            (nextProps.currentProgram  && nextProgram.id ) &&
            (currentProgram  && currentProgram.id ) &&
            (nextProgram.id  !== currentProgram.id)
          )
          || (!currentProgram && nextProgram && nextProgram.id)
          || ( nextStartDate && currentStartDate !== nextStartDate)

        ){
          this.props.dispatch(actionProgramTab.changeStartDate( moment(nextStartDate).toDate() ));
      }

    }

    render(){
        const { loading, enrolledProgram } = this.props.data || {};
        const curUrl = browserHistory.getCurrentLocation().pathname;
        if (loading) {
          return <IHLoading className="vsm-patient-enrolledprogram-main" />;
        }

        this.initVar();
        const currentPatient = RequestCache.get('nurse/currentPatient')
        const patientNRIC = _.get(currentPatient, 'identification[0].value')
        const patientName = _.get(currentPatient, 'profile.fullName')
        const copyCurrentProgram = this.props.currentProgram
        let currentProgram = _.clone(copyCurrentProgram)
        enrolledProgram && Object.assign(currentProgram, enrolledProgram)
        // const { currentProgram } = this.props

        const prop = {
            onSelect : (id)=>{
              createAuditLog({ action:I18N.get('auditLog.provider.patient.enrollInNewProgram.' + `select_${id}_tab`), patientNRIC, patientName })
              this.props.dispatch(actions.setTabsStep(id))
            },
            active : this.props.tab,
            itemList :
            [
                {
                    id : 'programme',
                    title : 'Program Enrollment',
                        // I18N.get('keywords.PROGRAM'),
                    content : (_.endsWith(curUrl, '_')) ? <TabWrapper component={<ProgramTab {...this.props} currentProgram={currentProgram} />}
                                          style={{display:'flex',padding:'unset'}}
                              /> : ""
                },
            ] 
        }

        let deleteButton = null;
        if (currentProgram) {
          deleteButton = this.renderDeleteProgramme(currentProgram);
        }
        if (window.VSM_CONFIG.DEBUG) {
          return (
              <div className="c-50" style={{
                  // width:'1040px', margin:'0 auto'
                  width: '100%'
                }}>
                <IHTab {...prop} />
                <div>
                  {
                    //<TemporaryButton {...this.props} currentProgram={currentProgram} />
                  }
                  {/*{ deleteButton }*/}
                  {/*SC-1552*/}
                </div>
              </div>
          )
        } else {
          return (
              <div className="c-50" style={{
                  //width:'1040px', margin:'0 auto'
                  width: '100%'
                }}>
                <IHTab {...prop} />
                { deleteButton }
                {/*sc-1552*/}

              </div>
          )
        }
    }

    renderDeleteProgramme(program) {
      const {id} = program;
      return (
        <IHButton
          className="btn-delete"
          label={`Delete ${I18N.get('keywords.Program')}`}
          onClick={this.confirmDelete.bind(this, id)}
          style={{ marginTop: '20px' }}
        />
      )
    }

    confirmDelete(id){
      const { closeModal, openModal } = this.props;
      const p1 = {
        type : 'primary',
        label : 'Confirm',
        size : 'large',
        onClick : this.deleteProgram.bind(this, id)
      };
      const p2 = {
        label : 'Close',
        size : 'large',
        style : {
          marginLeft : '10px'
        },
        onClick : closeModal
      };
      const footer = (
        <div>
          <IHButton {...p1} />
          <IHButton {...p2} />
        </div>
      );
      const param = {
        size : 'sm',
        title : `Confirm Delete ${I18N.get('keywords.Program')}`,
        content : `Are you sure you want to delete this ${I18N.get('keywords.program')} template?`,
        footer
      };
      openModal(param.content, param);
    }

    deleteProgram = async(id) => {
      const { deleteEnrollProgram, openErrorModal, closeModal, currentProgram } = this.props;
      const variables = {
        id
      };
      const currentPatient = RequestCache.get('nurse/currentPatient')
      const patientName = _.get(this.props.row, 'user.profile.fullName') || _.get(currentPatient, 'profile.fullName')
      const patientNRIC = _.get(this.props.row, 'user.identification.0.value') || _.get(currentPatient, 'identification.0.value')

      try {
        const res = await deleteEnrollProgram({ variables })
        createAuditLog({
          action:I18N.get('auditLog.provider.patient.enrollInNewProgram.delete_enrolled_program'),
          details: { programName: currentProgram.name },
          request: variables,
          response: res,
          patientName,
          patientNRIC
        })
        await UserClass.saveEPsForProvider()
        message.success(`${I18N.get('keywords.Program')} deleted successfully`)

      } catch (err) {
        openErrorModal(GQLHelper.formatError(err));
        createAuditLog({
          action:I18N.get('auditLog.provider.patient.enrollInNewProgram.delete_enrolled_program'),
          details: { programName: currentProgram.name },
          request: variables,
          response: err,
          patientName,
          patientNRIC,
          success: false
        })
      } finally {
        closeModal();
        window.location.reload();
        //sc-1552 reload page after restart
        // goPath('/patients');
      }
    }
};

// PropTypes validation
Container.propTypes = {
  openErrorModal: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  enrolledProgramId: PropTypes.string,
};

// connect apollo data with container



const wrapGQLData = graphql(enrolledProgramGQL, {
  options: ownProps => ({
    variables: {
      id: _.get(ownProps, 'currentProgram.id',''),
    },
    notifyOnNetworkStatusChange: true,
    skip: !_.get(ownProps, 'currentProgram.id') || _.get(ownProps, 'currentProgram.tasks') //do not fetch if enroll in new program or already has the "tasks" field
  }),
  props: ({ data }) => {
    return { data }
  }
});
const deleteEnrollProgramGQL = graphql(deleteEnrollProgram, {name : 'deleteEnrollProgram'})
const ContainerWithData = GQLHelper.wrapper([deleteEnrollProgramGQL, wrapGQLData], Container)

// const ContainerWithData = wrapGQLData(Container);

// mapState
const mapState = (state, ownProps) => {
  return {
    tab: state.patient.main.Tab.step,
    main : state.patient.main
  }
};

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    closeModal: () => dispatch(commonAction.closeModal()),
    openModal: (content, modalProps) => dispatch(commonAction.openModal(content, modalProps)),
    dispatch
  }
};

export default connect(
  mapState, mapDispatch
)(ContainerWithData);
