import gql from 'graphql-tag';

export default gql`
	query organizationList($page:Int, $count:Int,$filters: OrganizationFilter,$search:OrganizationSearch,$sort:OrganizationSort) {
		organizationList(page: $page, count: $count,filters: $filters,search:$search, sort: $sort) {
			data {
				id,
				name,
				roles{
					refId,
					name
				  }
                identifier,
                leaf,
                active,
                description,
				timezone,
				address{
					type
        			streetName
        			streetNumber
        			floor
        			unit
        			city
        			state
        			postCode
        			country{
          				description
          				code
        			}
				}
			}
			pageInfo{
				total
				lastPage
			}
		}
	}
`;