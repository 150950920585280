import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as d3 from 'd3'
import './css/lineChartComponent.scss'

class LineChart extends Component {
  functionRef = null
  generateChartFunc(drawLineChart, props) {
    const { data, missed, value, lineColor, setFillColor, formatTooltip, range, thresholds } = props
    // draw line chart
    const margin = {top: 50, right: 120, bottom: 100, left: 120}
    const lineChart= drawLineChart()
      .data(data)
      .range(range)
      .width(this._container.offsetWidth)
      .height(350)
      .margin(margin)
      .missed(missed)
      .value(value)
      .lineColor(lineColor)
      .setFillColor(setFillColor)
      // .thresholds(thresholds)
      .formatTooltip(formatTooltip)

    // 1/29/18 - JV - Fix for when there is no thresholds
    if (thresholds && Object.keys(thresholds).length > 0) {
      lineChart.thresholds(thresholds)
    }

    return lineChart
  }
  redrawChart() {
    d3.select(this._container).selectAll('*').remove()
    const lineChart = this.generateChartFunc(this.drawLineChart, this.props).bind(this)
    d3.select(this._container)
      .call(lineChart);
  }
  componentDidMount() {
    this.redrawChart();
    this.functionRef = this.redrawChart.bind(this)
    window.addEventListener('resize', this.functionRef)
  }
  componentDidUpdate() {
    this.redrawChart();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.functionRef)
  }
  drawLineChart() {
    var width, height, margin;
    var x, y, xAxis, yAxis;
    var data, missed, value, lineColor, setFillColor, formatTooltip, range, thresholds;
    var svg;

    const drawChart = function (selection) {
      width = width - margin.left - margin.right;
      height = height - margin.top - margin.bottom;
      x = d3.scaleTime().domain([range[0], d3.timeDay.ceil(range[1])]).range([0, width]);
      y = d3.scaleLinear().range([height, 0]);
      xAxis = d3.axisBottom().scale(x).ticks(d3.timeDay.every(1)).tickFormat(d3.timeFormat('%b %-d'));
      yAxis = d3.axisLeft();
      svg = selection.append('svg')
        .attr('height', height + margin.top + margin.bottom)
        .attr('width', width + margin.left + margin.right)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);
      svg.selectAll('*').remove();

      const threshold_low = d3.min(d3.values(thresholds).map(d => d[0]))
      const thresholds_high = d3.max(d3.values(thresholds).map(d => d[1]))
      const yUpperBound = d3.max(value.map(key => d3.max(data, d => d[key])).concat([threshold_low, thresholds_high]));
      y.domain([0, yUpperBound]).nice();
      // step 2: draw axis
      svg.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', `translate(0, ${height})`)
        .call(xAxis.scale(x).tickSize(-height).tickPadding(7));
      svg.append('g')
        .attr('class', 'axis axis--y')
        .call(yAxis.scale(y).tickSize(-width).ticks(10).tickPadding(7));
      // VS-941 remove cluttered labels
      const labelWidth = svg.select('g.axis--x').select('.tick text').node().getBBox().width
      const totalLabels = svg.select('g.axis--x').selectAll('.tick text').size()
      if (labelWidth > width/totalLabels/1.5) {
        svg.select('g.axis').selectAll('.tick text').filter((dom, i) => (i!== 0) && (i%7 !== 0)).style('display', 'none')
      }
      // step 3: draw thresholds
      const line = d3.line()
        .x((d) => x(d.date))

      // 1/29/18 - JV - Fix for when there is no thresholds
      if (thresholds && Object.keys(thresholds).length > 0) {
        const thresholdRanges = svg.append('g')
          .attr('class', 'thresholds')
          .selectAll('g')
          .data(value)
          .enter().append('g')
          .attr('class', d => d.split(' ').join(''))

        thresholdRanges.append('rect')
          .attr('x', 0)
          .attr('y', d => y(thresholds[d][1]))
          .attr('height', d => y(thresholds[d][0]) - y(thresholds[d][1]))
          .attr('width', width)
          .style('fill', (d, i) => lineColor[i])
          .style('opacity', 0.2)
      }

      // step 4: draw tooltip; should draw first, or the vertical line will block the dots
      const tooltip = svg.append('g')
        .attr('class','tooltip tooltip-inactive');
      tooltip.append('path')
      tooltip.append('rect')
        .attr('x', 3)
        .attr('y', -16)
        .attr('height', 16);
      tooltip.append('text')
        .attr('class', 'indicator')
        // .style('fill', 'white')
        .attr('x', 3*2)
        .attr('dy', '-.35em');
      tooltip.append('text')
        .attr('class', 'time')
        .attr('dy', '-.35em');
      tooltip.append('line');


      const dataGroups = svg.append('g')
        .attr('class', 'data-groups')
        .selectAll('g')
        .data(value)
        .enter().append('g')
          .attr('class', d => `${d.split(' ').join('')}`)

      dataGroups.append('g')
        .attr('class', 'line')
        .append('path')
          .datum(d => data.filter(item => item.key === d))
          .attr('class', 'line-value')
          .attr('stroke', (d, i) => lineColor[i])
          .attr('stroke-width', '2px')
          .attr('fill', 'none')
          // .transition(enterTransition)
          .attr('d', line.y(item =>y(item[item.key])));

      // step 4: draw dots of data points
      const dots = dataGroups.append('g')
        .attr('class', 'dots')
        .style('stroke', (d, i) => lineColor[i])
        .style('stroke-width', '2px')
        .selectAll('circle')
          .data(d => data.filter(item => item.key === d))
          .enter().append('circle')
            .attr('cx', line.x())
            .attr('r', 3)
            .style('fill', 'white')
            .attr('cy', line.y());

      // step 5: draw missed data triangles
      const triangles = svg.append('g')
        .attr('class', 'MissedMeasurement')
        .selectAll('path')
        .data(missed)
        .enter().append('path')
        .attr('d', d3.symbol().type(d3.symbolTriangle).size(30))
        .attr('transform', d => `translate(${line.x()(d)}, ${height})`)
        .style('fill', '#e95c69')

      // step 5: draw legend
      const legendData = value.concat('Missed Measurement')
      const legendColors = lineColor.concat('#e95c69')
      const legend = svg.append('g')
        .attr('class', 'legend')
        .attr('transform', `translate(0, ${height + 50})`)
        .selectAll('g')
        .data(legendData)
        .enter().append('g')
        .attr('class', d =>d.split(' ').join(''))

      legend.append('path')
        .attr('d', d => (d === 'Missed Measurement')?
          d3.symbol().type(d3.symbolTriangle).size(30)() : d3.symbol().type(d3.symbolCircle).size(30)()
        )
        .style('fill', (d, i) => legendColors[i]);

      legend.append('text')
        .text(d => d)
        .attr('x', 10)
        .attr('y', 0)
        .attr('dy', '.34em')
        .style('fill', '#363b4e')
        .style('font-family', 'Arial, Helvetica, sans-serif')
        .style('font-size', '12px');
      let lengthArray = [];
      const delta = 40;
      legend.nodes().map(d => lengthArray.push(d.getBBox().width));
      const totalLegendLength = d3.sum(lengthArray, d => d) + (lengthArray.length - 1) * delta;
      const initShift = (width - totalLegendLength)/2;
      lengthArray = lengthArray.reduce((a, b) => a.concat(b+a[a.length - 1]+delta), [initShift])
      legend.attr('transform', (d, i) => `translate(${lengthArray[i]}, 0)`);

      dots.on('mouseover', function(d) {
        tooltip.classed('tooltip-inactive', false)
        setTooltip(d)
        const datumID = d.id;
        d3.select(this).raise().transition().duration(100).attr('r', 6);
        dots.filter(d => d.id === datumID).transition().duration(100).attr('r', 6);
        d3.select(this.parentNode.parentNode).raise();
      }).on('mouseout', function() {
        dots.transition().duration(100).attr('r', 3)
        tooltip.classed('tooltip-inactive', true)
      })

      legend.on('mouseover', function(d) {
        const classes = value.map(d => '.'+d.split(' ').join('')).join(',')
        svg.selectAll(classes).classed('class-inactive', true)
        svg.selectAll('.MissedMeasurement').classed('class-inactive', true)
        svg.selectAll(`.${d.split(' ').join('')}`).classed('class-inactive', false)
      }).on('mouseout', function(d) {
        const classes = value.map(d => '.'+d.split(' ').join('')).join(',')
        svg.selectAll(classes).classed('class-inactive', false)
        svg.selectAll('.MissedMeasurement').classed('class-inactive', false)
      })

      triangles.on('mouseover', function(d) {
        tooltip.classed('tooltip-inactive', false)
        setTooltip(d)
        d3.select(this).attr('d', d3.symbol().type(d3.symbolTriangle).size(80))
      }).on('mouseout', function () {
        d3.select(this).attr('d', d3.symbol().type(d3.symbolTriangle).size(30))
        tooltip.classed('tooltip-inactive', true)
      })
      // r = 3, padding = 3, h = 20, x = 5, y = 10


      function setTooltip(d) {
        const t1 = formatTooltip(d);
        const t2 = `${d3.timeFormat('%I:%M %p, %b %-d')(d.date)}`
        tooltip.select('text.indicator').text(t1)
        tooltip.select('text.time').text(t2)
        const t1Length = tooltip.select('text.indicator').node().getComputedTextLength();
        const t2Length = tooltip.select('text.time').node().getComputedTextLength();
        tooltip.select('text.indicator').style('fill', () => (d.datum && d.datum.taskStatus === 'MISSED')? '#363b4e': 'white')
        tooltip.select('rect').attr('width', t1Length + 3 * 2)
          .style('fill', () => setFillColor(d));
        tooltip.select('text.time').attr('x', t1Length + 3 * 4);
        const l1 = (t1Length + t2Length - 10 + 3*3)/2
        tooltip.select('path').attr('d', drawPath(3, 16, l1, l1, 5, 10));
        tooltip.select('line')
          .attr('x1', l1 + 5 + 3)
          .attr('x2', l1 + 5 + 3)
          .attr('y1', height + 3 + 10)
          .attr('y2', 3 + 10);


        tooltip.transition().duration(100)
          .attr('transform', `translate(${x(d.date) - (l1 + 5 + 3)}, ${-3 - 10})`);

      };
      function drawPath(r, h, l1, l2, x, y) {
        const arc = `a${r}, ${r} 0 0, 0`;
        return `M0, 0
          ${arc} ${r}, ${r} l${l1}, 0 l${x}, ${y} l${x}, -${y}
          l${l2} 0
          ${arc} ${r} -${r}
          l0 -${h}
          ${arc} -${r} -${r}
          l-${l1 + l2 + x + x}, 0
          ${arc} -${r} ${r}
          z`;
      }
    }

    drawChart.data = function (newData) {
      data = newData;
      return drawChart;
    };
    drawChart.value = function (newValue) {
      value = newValue;
      return drawChart;
    };
    drawChart.lineColor = function (newCol) {
      lineColor = newCol;
      return drawChart;
    };
    drawChart.width = function (newWidth) {
      width = newWidth;
      return drawChart;
    };
    drawChart.height = function (newHeight) {
      height = newHeight;
      return drawChart;
    };
    drawChart.margin = function (newMargin) {
      margin = newMargin;
      return drawChart;
    }
    drawChart.setFillColor = function(newFunc) {
      setFillColor = newFunc;
      return drawChart;
    };
    drawChart.formatTooltip = function(newFunc) {
      formatTooltip = newFunc;
      return drawChart;
    };
    drawChart.missed = function(newMissed) {
      missed = newMissed;
      return drawChart;
    };
    drawChart.range = function(newRange) {
      range = newRange;
      return drawChart;
    };
    drawChart.thresholds = function(newThresholds) {
      thresholds = newThresholds;
      return drawChart;
    };
    return drawChart;
  }
  render() {
    return (
      <div ref={node => {
        this._container = node
      }} style={{"margin": "auto"}} >
      </div>)
  }

}
LineChart.propTypes = {
  data: PropTypes.array,
  missed: PropTypes.array,
  value: PropTypes.array,
  lineColor: PropTypes.array,
  setFillColor: PropTypes.func,
  formatTooltip: PropTypes.func,
  range: PropTypes.array
}
export default LineChart
