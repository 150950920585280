
import { combineReducers } from 'redux';
import types from '../constants'

const INITIAL_STATE = {
  contact: null,
  providerNotesModalIsVisible: false,
  lastCallPatientId: null,
  lastCallPatientName: null,
  lastCallStartTime: null,
  lastCallEndTime: null,
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.CONTACT_STATUS:
      return {
        ...state,
        contact: action.payload
      }
    case types.CALL_STATUS:
      return {
        ...state,
        providerNotesModalIsVisible: action.payload
      }
    case types.LAST_CALL_PATIENT_ID:
      return {
        ...state,
        lastCallPatientId: action.payload
      }
    case types.LAST_CALL_PATIENT_NAME:
      return {
        ...state,
        lastCallPatientName: action.payload
      }
    case types.LAST_CALL_START_TIME:
      return {
        ...state,
        lastCallStartTime: action.payload
      }
    case types.LAST_CALL_END_TIME:
      return {
        ...state,
        lastCallEndTime: action.payload
      }
    default:
      return state;
  }
}

export default {
  AWSConnect: reducer,
};
