import { IHTable, IHButton,WeeklyMealScheduleInputGrid,emptyWeeklyScheduleData } from 'ihcomponent'
import PropTypes from 'prop-types';
import React from 'react';
import { createContainer } from 'libModule/utils'
import { convertDBToVitalList } from 'modulesAll/utils/helpers/task'
import actions from '../actions/VitalListActions'
import { renderThresholdsTitle, renderThresholds, renderSchedule } from 'libModule/helpers/component-helpers'
import { browserHistory } from 'react-router';

import { openModal, closeModal, openErrorModal } from 'layoutModule/actions/MainModal';

class Component extends React.Component {
  static displayName = 'patient/enrolledProgramInfo/Careplan/containers/VitalListContainer';

  render() {
    const dataSource = convertDBToVitalList(this.props.program);
    const { isFromEnrollProgram } = this.props;
    let p = {
      rowKey: 'id',
      columns: [
        // {
        //   title: '',
        //   dataIndex: 'hidden',
        //   key: 'hidden',
        //   width: '49',
        // },
        {
          title: 'Vitals',
          dataIndex: 'vitalType',
          key: 'vitalType',
          width: '15%',
          render: (vital, row, index) => {
            if (vital === 'Weight' && row.weightBasedOn === 'Last Measurement') return 'Weight Change'
            return vital
          }
        },
        {
          title: renderThresholdsTitle(),
          dataIndex: 'thresholds',
          key: 'thresholds',
          width: '50%',
          render: (value, row, index) => renderThresholds(value, row, index)
        },
        {
          title: 'Schedule',
          dataIndex: 'schedule',
          key: 'schedule',
          width: '20%',
          render: (value, row, index) => renderSchedule(value, row, index,
            {render:this.props.openModal, closer:this.props.closeModal})

        },
        {
          key: 'Actions',
          title: 'Actions',
          width: '15%',
          render: (value, row, index) => {
            const { changePage, updateForm,isFromEnrollProgram ,patientId} = this.props;
            const onClick = () => {
              const patientId =  isFromEnrollProgram ? patientId : this.props.program.user.id;
              updateForm(row, true);
              // browserHistory.push('/patients/' + patientId + '/patientschedule_edit');
              changePage();

            };
            return (
              <div>
                <IHButton bsStyle='primary' size='exsmall' onClick={onClick} disabled={this.props.isVitalEditBtnDisabled ? this.props.isVitalEditBtnDisabled : false} >
                  Edit
                  <i className="fa fa-angle-right ih-right"></i>
                </IHButton>
              </div>
            )
          }
        }
      ],
      dataSource,
      noDataElem: this.renderAddVitalElem('Add Vital Sign', 'flex_center', {height:'200px'})
    }
    const url = window.location.href;
    const inMessagePage  = (url.indexOf('messages')!=-1);
    if(inMessagePage||isFromEnrollProgram)
      {
        p.columns = p.columns.slice(0,3);
      }
    const vitalListLength = this.props.data.length;
    const addAnotherVitalBtn = !isFromEnrollProgram&&vitalListLength > 0 && vitalListLength < 5 ? this.renderAddVitalElem('Add Another Vital', '', {margin: '20px 0 30px'}) : '';

    return (
      <div>
        <IHTable {...p} />
         {addAnotherVitalBtn}
      </div>
    )
  }
  renderAddVitalElem(label, className, style) {
    const { changePage, resetForm,isVitalEditBtnDisabled } = this.props
    const pb = {
      bsStyle: 'primary',
      label,
      disabled:isVitalEditBtnDisabled,
      size: 'small',
      onClick: () => {
        changePage()
        resetForm()
      }
    }

    return (
      <div className={className} style={style}>
        <IHButton {...pb} />
      </div>
    )
  }
}

const mapState = ({ patient }) => {
  return {
    data: patient.enrolledProgramInfo.Careplan.VitalList.data
  }
}

const mapDispatch = (dispatch) => {
  return {
    updateForm: (rowData, isEditMode) => dispatch(actions.updateForm(rowData, isEditMode)),
    resetForm: () => dispatch(actions.resetForm()),
    changePage: () => dispatch(actions.goToCareplanVitalForm()),
    closeModal: () => {dispatch(closeModal())},
    openModal: (content, modalProps) => dispatch(openModal(content, modalProps))  }
}

Component.propTypes = {
  program: PropTypes.object,
  data: PropTypes.array,
  updateForm: PropTypes.func,
  resetForm: PropTypes.func,
  changePage: PropTypes.func
}

export default createContainer(Component, mapState, mapDispatch)

export { Component };
