import {
  React, IHSearchTable, _, IHButton, moment
} from 'ihcomponent'
import PropTypes from 'prop-types';
import CareTeamClass from 'modulesAll/graphql/class/CareTeam'
import { browserHistory } from 'react-router';
import { programConditionMap } from 'libModule/utils'
import Store from 'libModule/reduxStore'

const Component = class extends React.Component{
  static displayName = 'careteam/list/containers/ListTable'

  componentWillUnmount() {
    if (!new RegExp(/^(\/careteams)/).test(Store.getState().routing.location.pathname)) {
      this.props.onLoadParams(null)
    }
  }

  render(){
    const p = this.getTableProps()
    return <IHSearchTable {...p} />
  }

  getTableProps(){
    const { list, loading, search, onTableSearch, onSearchEnter, sort={}, onTableChange, page, filter } = this.props
    const dataSource = _.map(_.get(list, 'data') || [], (item)=>(new CareTeamClass(item)))
    const filters = _.get(list, 'filters', []);
    return {
      title : `Care Team (${_.get(list, 'pageInfo.total', 0)})`,
      dataSource,
      rowKey : (doc)=>{
        return doc._get('id')
      },
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize',10),
        total: _.get(list, 'pageInfo.total', 0),
      },
      onChange: onTableChange,
      showSearchInput: true,
      searchValue: search,
      onSearch: onTableSearch,
      onSearchEnter,
      inputPlaceholder : 'Search by Name',
      loading,
      columns : [
        {
          title : 'Name',
          key : 'careTeam',
          dataIndex: 'careTeam',
          render(t, doc){
            return doc._get('name')
          },
          sorter: true,
          sortOrder: sort.field === 'careTeam' && sort.order
        },
        // {
        //   title : 'Health Condition',
        //   key : 'healthCondition',
        //   render : (t, doc)=>{
        //     return doc.format('healthCondition')
        //   },
        //   filters: filters.map(key => ({text: programConditionMap[key], value: key})),
        //   filteredValue: _.get(filter, 'healthCondition', []),
        //   onFilter: (value, record) => record.variables['healthCondition'].includes(value),
        // },
        {
          title:'Contact Name',
          key: 'contactName',
          render:(row,doc)=>{
            return doc.format('contactName');
          }
        },
        {
          title : <div>Primary<br /> Doctor(s)</div>,
          key : 'Primary Doctor',
          width : '10%',
          render(t, doc){
            return _.map(doc.format('primaryDoctors'), (u, i)=>{
              return <div key={i}>{u._get('profile.fullName')}</div>
            })
          }
        },
        {
          title : <div>Primary <br />Care Manager(s)</div>,
          key : 'Primary Care Manager',
          width : '12%',
          render(t, doc){
            return _.map(doc.format('primaryManagers'), (u, i)=>{
              return <div key={i}>{u._get('profile.fullName')}</div>
            })
          }
        },
        {
          title : 'Member(s)',
          key : 'Members',
          width : '15%',
          render : (t, doc)=>{
            return _.map(doc.format('member_list'), (u, i)=>{
              return <div key={i}>{u._get('profile.fullName')}</div>
            })
          }
        },
        // {
        //   title: 'Created By',
        //   dataIndex: 'createdBy',
        //   key: 'createdBy',
        //   render: (t, doc)=>{
        //     return doc.format('createdBy')
        //   }
        // },
        // {
        //   title: 'Created On',
        //   dataIndex: 'createdAt',
        //   key : 'createdAt',
        //   sorter: true,
        //   sortOrder: sort.field === 'createdAt' && sort.order,
        //   render : (t, doc)=>{
        //     return doc._get('createdAt') ? moment(doc._get('createdAt')).format('MMM-DD-YYYY hh:mm a') : ''
        //   }
        // },
        // {
        //   title: <span>Last <br /> Modified By</span>,
        //   dataIndex: 'modifiedBy',
        //   key: 'modifiedBy',
        //   render: (t, doc)=>{
        //     return doc.format('modifiedBy')
        //   }
        // },
        // {
        //   title: <span>Last <br /> Modified On</span>,
        //   dataIndex: 'modifiedAt',
        //   key: 'modifiedAt',
        //   sorter: true,
        //   sortOrder: sort.field === 'modifiedAt' && sort.order,
        //   render(t, doc){
        //     return doc._get('modifiedAt') ? moment(doc._get('modifiedAt')).format('MMM-DD-YYYY hh:mm a') : ''
        //   }
        // },
        {
          title : 'Actions',
          key : 'Actions',
          render : (t, doc)=>{
            const bp = {
              size: 'exsmall',
              bsStyle: 'primary',
              onClick: ()=>{
                browserHistory.push(`/careteams/edit/${doc._get('id')}`)
              },
              className: 'table-action-button'
            }
            const label = 'Edit'
            return (
              <IHButton {...bp}>
                {label}
                <i className="fa fa-angle-right ih-right"></i>
              </IHButton>
            )
          }
        }
      ]
    }
  }
}

Component.propTypes = {
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  list: PropTypes.object,
  onSearchEnter: PropTypes.func,
  onTableSearch: PropTypes.func,
  search: PropTypes.string,
}

export default Component
