import {
    React, moment, _
} from 'ihcomponent'
import {createContainer, getRouterParam} from '../../../../lib/utils'
import DescArea from '../components/DescArea'
import actions from '../actions/main'

const Container = createContainer(DescArea, (state, ownProps)=>{
  const program = state.patient.program.selectedProgram
  const {currentProgram} = ownProps
  const enrolledProgramId = getRouterParam(state, 'enrolledProgramId')
  const description = currentProgram ? currentProgram.description : (program?program.description:'');
  const initialValue = currentProgram ? [_.get(currentProgram, 'duration.length'), _.get(currentProgram, 'duration.calendar')||'DAY'] : ((program?[_.get(program, 'duration.length'), _.get(program, 'duration.calendar')||'DAY']:null) || _.get(state, 'patient.program.duration'));
  if(!initialValue) {
    return {}
  }
  const startDate = moment(_.get(state, 'patient.program.startDate'));
  const multiplier = initialValue[1] === 'DAY'
                      ? 1 : initialValue[1] === 'WEEK'
                      ? 7 : 30
  const endDate = moment(startDate).add(initialValue[0] * multiplier-1, 'd');

  //console.log(getRouterParam(state))
    return {
      //hide : !program,
      readonly : true,
      description,
      initialValue,
      startDate,
      endDate,
      extendStep: ownProps.extendStep
    }
}, (dispatch)=>{
  return {
    onChange : (v)=>{
      dispatch(actions.changeDuration(v))
    }
  }
});

Container.displayName = 'patient/programTab/containers/DescArea';

export default Container;
