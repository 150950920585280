import gql from 'graphql-tag'

export default gql`
mutation doCreateUser($role:[Ref]!, $address:[InputAddress],
  $email:[EmailAddress], $phone:[InputPhone],
  $commonProfile:InputCommonProfile, $memberProfile:InputMemberProfile,
  $employeeProfile:InputEmployeeProfile,
  $residency:InputMemberResidency, $username:String,
  $identification:InputIdentification,
  ) {
  	createUser(
      role: $role,
      address: $address,
      email: $email,
      phone: $phone,
      commonProfile: $commonProfile,
      memberProfile: $memberProfile,
      employeeProfile: $employeeProfile,
      residency: $residency,
      username: $username,
      identification: $identification,
      )
    {
      id
      profile {
        fullName
      }
    }
  }
`
