import gql from 'graphql-tag'

export default gql`
  mutation createTeam($name:String!, $description:String, $memberId:[EID]!,$forwardingPhone:InputPhone, $phone: InputPhone, $email: EmailAddress,$contactName:String,$primary:[EID]){
    createTeam(name:$name, description:$description, providerId:$memberId, phone: $phone, email: $email, contactName: $contactName, primary:$primary,forwardingPhone:$forwardingPhone){
      id
      name
      description
    }
  }
`
