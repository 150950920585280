import React from 'react';
import PatientAssessmentListTable from './PatientAssessmentListTableComponent';

const ProviderAlertList = props => (
  <div style={{margin: '20px 0 10px 0'}}>
    <PatientAssessmentListTable {...props} />
  </div>
);

export default ProviderAlertList;
