import type from '../constants/EnrolledProgramInfoConstants'

// Default State
const initState = {
  resuming: false,

  extendStep: 1,   //for input Number of days in /Provider/src/modules/patient/programTab/components/DescArea.js
  extendValue: 1,  //for dropdown select week,month in /Provider/src/modules/patient/programTab/components/DescArea.js
  extendType: 'WEEK',

};

export default (state = initState, action) => {

  switch(action.type) {

      case type.SET_EXTEND_STEP:
      return {
        ...state,
        extendStep : action.extendStep
      }

      case type.SET_EXTEND_VALUE:
      return {
        ...state,
        extendValue : action.extendValue
      }

      case type.RESET_EXTEND_VALUE_TYPE:
      return {
        ...state,
        extendType : 'WEEK',
        extendValue: 1
      }

      case type.SET_EXTEND_TYPE:
      return {
        ...state,
        extendType : action.extendType
      }

      case type.SET_RESUMING:
        return {
          ...state,
          resuming : action.resuming
        }


    default:
      return state
  }


}
