import { graphql, withApollo, compose } from 'react-apollo'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'antd';
import ChangePwComponent from '../components/ChangePwComponent'
import setUserPassword from 'modulesAll/graphql/setUserPassword'
import actions from '../actions/ChangePw'
import { browserHistory } from 'react-router'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import get from 'lodash/get'
import { GQLHelper } from 'libModule/utils'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { API } from '../../../chat/actions/api';

class ChangePwContainer extends Component {
  static displayName = 'ChangePwContainer'

  constructor(props) {
    super(props);
    this.state = { selectedUser: null }
  }
  componentWillMount() {
    API.getUserProfile(this.props.params.patientId)
      .then( res => {
        this.setState({ selectedUser: res.data.user });
      })
      .catch( err => console.log('Error fetching user profile: ', err));
  }
  submit = async ({newPass}) => {
    const { location, mutate, setSubmitting, openErrorModal } = this.props
    const id = this.props.params.patientId;
    const variables = {
      newPass,
      id
    }
    console.log('variables: ', variables);

    try {
      setSubmitting(true);
      const res = await mutate({ variables })
      createAuditLog({
        action: I18N.get('auditLog.login.resetpassword'), // TODO: change to create password
        loginId: variables.identification,
        response: res
      })
      Modal.success({
        title: 'Success',
        content: 'Password created successfully.'
      });
      browserHistory.push(`/patients/profile/${id}/edit`);
    }
    catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.login.resetpassword'),
        loginId: variables.identification,
        response: err,
        success: false,
      })
      Modal.error({
        title: 'Error',
        content: `An error occurred while saving your password. Please try again. ${GQLHelper.formatError(err)}`
      });
      // openErrorModal(GQLHelper.formatError(err))
    }
    finally {
      setSubmitting(false);
    }
  }

  render() {
    const props = {
      ...this.props,
      submit: this.submit
    }

    return (
      <ChangePwComponent selectedUser={this.state.selectedUser} {...props} />
    )
  }
}

const mapState = ({ ResetPassword }, ownProps) => {
  return {
    ...ResetPassword
  }
}

const mapDispatch = {
  ...actions,
  openErrorModal
}

ChangePwContainer.propTypes = {
  submit: PropTypes.func,
  handleFieldsChange: PropTypes.func,
  mutate: PropTypes.func,
  fieldsValue: PropTypes.object,
  username: PropTypes.string,
  password: PropTypes.string
}

export default compose(
  withApollo,
  graphql(setUserPassword),
  connect(mapState, mapDispatch),
)(ChangePwContainer)
