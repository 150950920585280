import gql from 'graphql-tag'



export default gql`mutation updateVisitWorkingList($id:EID!,$maWorkingList:VisitMAWorkingListInput
$rdWorkingList:VisitRDWorkingListInput
){
    updateVisitWorkingList(id:$id,maWorkingList:$maWorkingList,rdWorkingList:$rdWorkingList){
        id
        rdWorkingList{
            Assessment
            Charting
            GoalStatement
        }
        maWorkingList{
            onBoard
            LabResults
            VisitBilling
            Medication
            FollowUpVisit
        }
    }
}`
