import gql from 'graphql-tag'

export default gql`
  mutation resendOnboardEmail(
    $id: EID!
  ){
    resendOnboardEmail(
      id: $id,
    )
  }
`
