import React from 'react';
import types from '../constants/VitalListHRConstants';
import { commonActions } from 'libModule/helpers/action-helpers';
import { openModal } from 'layoutModule/actions/MainModal';
// files
import InterventionNotes from '../../../alerts/InterventionNotes/containers/InterventionNotes'


export const changeRange = (range) => ({
  type: types.CHANGE_RANGE,
  range
})

const changeView = (view) => ({
  type: types.CHANGE_VIEW,
  view
})

const openPatientVitalModal = (row, type) => {
  // const taskId = get(row, 'task.id') 
  // const programId = get(row, 'program.id') 
  // const modalProps = { 
  //   taskId, 
  //   programId 
  // } 
  return (dispatch) => {
    const props = {
      showModal: true,
      showHeaderCloseButton: true,
      // title: `Reading on ${moment(row.date).format("MMM-DD-YYYY")}`,
      className: 'vsm-provider-modal',
      size: 'lg'
    };
    // dispatch(openModal(<PatientVitalModal row={row} type={type} />, props))
    dispatch(openModal(<InterventionNotes row={row} noteType={'hr_note'} />, props))

  }
}

export default Object.assign({
  changeRange,
  changeView,
  openPatientVitalModal
}, commonActions(types, ['onTableChange']))
