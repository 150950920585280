import React from 'react';
import moment from "moment/moment";
import { closeModal } from 'layoutModule/actions/MainModal'
import { Button } from 'antd';
import {compose} from "react-apollo/index";
import {connect} from "react-redux";
import { graphql } from "react-apollo/index";
import { Tabs } from 'antd';
import { foodLogList } from "../query";
import SingleMeasureComponent from './SingleMeasureComponent';
const TabPane = Tabs.TabPane;

const MultiMeasureComponent = class extends React.Component {


    render(){
        const { data, title, handleData,beforeMeal,patientId,type,shouldRenderFoodLog } = this.props;
        const singleMeasureComponents = _.map(_.reject(data,(d)=>d.severity=='MISSED'),(d,key)=>{
            const { mgdlValue,unit,className,readingValue } = handleData([d]);
            const tab = <div className={className.concat(' readingContainer')}>
                            <span>{ mgdlValue ? mgdlValue+' '+unit : `${readingValue.systolic_blood_pressure_value}/${readingValue.diastolic_blood_pressure_value} ${readingValue.heart_rate_value} ${unit}` }</span>
                            {/*<span>{ unit }</span>*/}
                         </div>
            return <TabPane tab={tab} key={key} forceRender={true} className='logbookMulti'>
                        <SingleMeasureComponent title={ title}
                                                handleData={handleData}
                                                beforeMeal={beforeMeal}
                                                patientId={patientId}
                                                date={d.date}
                                                data={[data[key]]}
                                                type={type}
                                                showSingleReading = { false }
                                                shouldRenderFoodLog={shouldRenderFoodLog}
                        />
                  </TabPane>
        })

        return<div className='row'>
                <div className='col-lg-12'
                     style={{backgroundColor:'#f7f7f7',fontWeight:'550',
                             textAlign:'start',paddingLeft:35,height:40,
                             display:'flex',alignItems:'center',paddingTop:25
                     }}>
                    <span>{title}</span>
                </div>
                <div className='col-lg-12' style={{textAlign:'start',padding:0}}>
                    <Tabs type="card" className='logBookMulti' >
                        {singleMeasureComponents}
                    </Tabs>
                </div>
                <div className='col-lg-12' style={{ textAlign:'start',paddingLeft: 35,paddingBottom:15,paddingRight: 35 }}>
                    <Button  onClick={()=>this.props.closeModal()} type="primary"
                             className='logBookCloseButton'
                    >
                        Close
                    </Button>
                </div>
              </div>
    }

}

// const withData = graphql(foodLogList, {
//     options: (ownProps) => {
//         const { day,patientId,title } = ownProps.date;
//         const fromDate = moment(day).startOf('day');
//         const toDate = moment(day).endOf('day');
//         const mealType = title&&title.split(' ')[1].toUpperCase();
//         const  variables = { };
//         variables.filters = {
//             memberId:patientId,
//             fromDate,
//             toDate,
//             mealType
//         }
//
//         return {
//             variables,
//             notifyOnNetworkStatusChange: true,
//             fetchPolicy: 'network-only'
//         };
//     },
//     props: ({ownProps, data}) => {
//         const { loading,foodLogList } = data;
//         if(foodLogList&&foodLogList.data) {
//             return {
//                 foodLogList:foodLogList.data,
//                 loading,
//             }
//         }
//         return {
//             loading
//         }
//     }
// })

const mapToDispatch = (dispatch)=> {
    return {
        closeModal:()=>dispatch(closeModal()),
    }
}

export default compose(connect(null,mapToDispatch))(MultiMeasureComponent);

