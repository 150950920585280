import types from '../constants/MainConstants'

const setSearchDatabase = (searchDatabase) => {
  return {
    type: types.SET_SEARCH_DATABASE,
    searchDatabase
  }
}

const setStatusFilterClicked = ()=>{
  return {
    type : types.SET_STATUS_FILTERED_CLICKED
  }
}

export default {
  setSearchDatabase,
  setStatusFilterClicked
}
