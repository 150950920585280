import { combineReducers } from 'redux';
import type from '../constants';

const INITIAL_STATE = {
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    let newTimerObj = {};
    let { displayName,event,apiName,resource,docId,summary,uuid,canRestUUid,cooldown,patientId } = action;
    let TimeTrackerClientEventActionEnum = action.type.split('/')[2];
    // displayName = `${displayName}:${patientId}`;
    if(displayName&&!state[displayName]){
        const obj =  {clientEvents:[],startTime:null,interventionDetails:[],isPaused:false,chatStarted:null,isFocused:true};
        newTimerObj = Object.assign(newTimerObj,obj)

    }else {
        newTimerObj = Object.assign({}, state[displayName]);
    }
    // const clientEvents = displayName&&timerMap[displayName].clientEvents;
        switch (action.type) {
            //set uuid
            case type.SET_UUID:
             return {
                 ...state,
                 uuid:uuid
             };

            //start timer
            case type.START_TIMER:
                let startTime = new Date();
                let clientEvents = new Array();
                clientEvents.push({
                    event:event,
                    action:TimeTrackerClientEventActionEnum,
                    ts:new Date(),
                });

            newTimerObj = Object.assign(newTimerObj,{
                    startTime,
                    clientEvents
                })

            return {
                ...state,
                [displayName]:newTimerObj
            };

            //reset cool down
            case type.RESET_COOLDOWN:
                newTimerObj.cooldown = cooldown;
            return {
                ...state,
                [displayName]:newTimerObj
            };
            //pause timer
            case type.PAUSE_TIMER:
                if(state[displayName].clientEvents.length==0){
                    return state;
                }
                if(event=='timeoutExpire') {
                    if(state[displayName].isFocused) {
                        newTimerObj.clientEvents.push({
                            event: event,
                            action: TimeTrackerClientEventActionEnum,
                            ts: new Date(),
                        });
                    }else{
                        return {
                            ...state,
                            [displayName]:newTimerObj
                        };
                    }
                }else{
                    newTimerObj.clientEvents.push({
                        event: event,
                        action: TimeTrackerClientEventActionEnum,
                        ts: new Date(),
                    });
                }
            return {
                ...state,
                [displayName]:newTimerObj
            };

            case type.RESUME_TIMER:

                if(state[displayName].isFocused){
                    newTimerObj.clientEvents.push({
                            event: event,
                            action: TimeTrackerClientEventActionEnum,
                            ts: new Date(),
                        });
                }

            return {
                ...state,
                [displayName]:newTimerObj
            };

            case type.STOP_TIMER:
                if(state[displayName].clientEvents.length==0){
                    return state;
                }
                newTimerObj.stopTime = new Date();
                newTimerObj.clientEvents.push({
                    event:event,
                    action:TimeTrackerClientEventActionEnum,
                    ts:new Date(),
                });
            return {
                ...state,
                [displayName]:newTimerObj
            };

            case type.ABORT:
                if(state[displayName].stopTime){
                    return state;
                }

                if(state[displayName].clientEvents.length==0){
                    return state;
                }
                newTimerObj.clientEvents.push({
                    event:event,
                    action:TimeTrackerClientEventActionEnum,
                    ts:new Date(),
                });
            return {
                ...state,
                [display]:newTimerObj
            };

            case type.UPDATE_INTERVENTION_DETAILS:
                newTimerObj.interventionDetails.push({
                        apiName, resource, docId, summary
                    }
                )
            return {
                ...state,
                [displayName]:newTimerObj
            };

            case type.RESET_TIMER:
                let newUUid = state.uuid;
                if(state[displayName]){
                    newTimerObj = Object.assign(state[displayName],
                        {clientEvents:[],startTime:null,interventionDetails:[],isPaused:false,chatStarted:null,isFocused:true,stopTime:null}
                    )
                }
                // if(state.uuid&&canRestUUid){
                //     newUUid = null;
                // }
            return {
                ...state,
                [displayName]:newTimerObj,
                uuid : newUUid
            };

            case type.TIMEOUT_PAUSE:
                if(!state[displayName].isPaused) {
                    newTimerObj.isPaused = true;

                }
                return {
                    ...state,
                    [displayName]:newTimerObj
                };

            case type.TIMEOUT_RESUME:
                newTimerObj.isPaused = false;

                return {
                    ...state,
                    [displayName]:newTimerObj
                };


            case type.START_CHAT:
                newTimerObj.chatStarted = true;

                return {
                    ...state,
                    [displayName]:newTimerObj
                };

            case type.FOCUS_PAGE:
                newTimerObj.isFocused = true;

                return {
                    ...state,
                    [displayName]:newTimerObj
                };

            case type.BLUR_PAGE:
                newTimerObj.isFocused = false;

                return {
                    ...state,
                    [displayName]:newTimerObj
                };

        default:
            return state;
    }
}

export default {
    timer: combineReducers({main:reducer})
};
