import {
    React,
    BaseComponent,
    _
} from 'ihcomponent';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Tooltip } from 'antd';
import { browserHistory } from 'react-router';
//import Client from 'libModule/gqlClient';
import UserClass from 'graphqlModule/class/User'
import { userForSessionStorage } from 'modulesAll/graphql/user';
import resultListMinimal  from 'graphqlModule/resultList'
import foodLogUnreadList from "graphqlModule/foodLogUnreadList";
import taskAssignmentUnreadList from '../../taskAssignment/query/taskAssignmentUnreadList';
import {compose,withApollo,graphql} from "react-apollo/index";
import moment from 'moment';
import foodLogHelper from '../../foodLog/helper'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { changeMenuStats } from '../../layout/actions/Nav';
import { lifecycle,withState } from 'recompose';
const POLL_INTERVAL = 30000; //30 s
const DAYS_RANGE = 30;

const Component = class extends BaseComponent{
  static displayName = 'common/components/VSMLeftNav'
    init(){
        this.toggleExpand = this.toggleExpand.bind(this);
        this.throttledResize = this.throttledResize.bind(this);
        this.resizeHandler = this.resizeHandler.bind(this);
        //this.gqlAlertsQuery = this.gqlAlertsQuery.bind(this);
        // this.gqlFoodLogQuery = this.gqlFoodLogQuery.bind(this);
        this.checkNewUnreadMsgs = this.checkNewUnreadMsgs.bind(this);
        this.timerId = null;
        this.state = {
            msgCount: 0,
            select : null,
            menu: [
              ...this.props.menu,
              {
                 id: 'expandToggle',
                 icon: 'right',
                 class: 'expand',
                 onClick: this.toggleExpand
              }
            ]
        }
    }

    componentWillMount() {
        // if (window.syncTimer) {
        //     clearInterval(window.syncTimer);
        // }
        // // const interval = _.get(window, 'VSM_CONFIG.UPDATE_USER_TIMER') || 5000;
        // const interval = _.get(window, 'VSM_CONFIG.UPDATE_USER_TIMER') || 500000;
        //
        // window.syncTimer = setInterval(() => {
        //     const id = sessionStorage.getItem('currentUserId')
        //     if (!id) {
        //         clearInterval(window.syncTimer);
        //         return;
        //     }
        //     Client.query({
        //         query: userForSessionStorage,
        //         variables: { id },
        //         fetchPolicy: 'network-only'
        //     }).then(resUser => {
        //         sessionStorage.setItem('currentUser', JSON.stringify(resUser.data.user))
        //     })
        // },interval)
    }

    componentDidMount() {
      window.addEventListener("resize", this.throttledResize, false);
      this.toggleExpand();
      // this.gqlAlertsQuery(); // run query for alerts
      // this.gqlFoodLogQuery();
      //this.timerId = setInterval(this.gqlAlertsQuery, 300000); // check for new alerts
    }

    componentWillUnmount() {
      clearInterval(this.timerId);
    }

    throttledResize() {
      if (!this.resizeTimeout) {
        this.resizeTimeout = setTimeout(function() {
           this.resizeTimeout = null;
           this.resizeHandler();
         }.bind(this), 66);
       }
    }

    resizeHandler() {
      if (window.innerWidth < 1250) {
        if (document.querySelector('.VSM-LeftNav').classList.contains('VSM-LeftNav-expanded')) {
          this.toggleExpand();
        }
      }
    }

    render(){
      if(this.props.current){
            this.state.select = this.props.current
        }
        return (
            <div className="VSM-LeftNav">
                {this.renderLogo()}
                {this.renderMenus()}
            </div>
        )
    }

    renderLogo(){
        const logo = this.props.logo
        return (
            <div className="v-logo logo-hidden">
                <a href={logo.href}>
                    <img src={logo.src} style={{'width':'30px','height':'30px'}} />
                    <span style={{'fontSize':'20px','marginLeft':'15px'}} className="'logoHeader">
                        {/*ShareCare*/}
                        UnifiedCare
                    </span>
                </a>
            </div>
        )
    }

    renderBadge(Badge) {
      return (
        <div className="v-badge">
            {Badge}
        </div>
      )
    }

    toggleExpand() {
      document.querySelector('.VSM-LeftNav').classList.toggle('VSM-LeftNav-expanded');
      document.querySelector('#master').classList.toggle('pushed');
      document.querySelector('.vsm-top-nav').classList.toggle('pushed');
      document.querySelector('.v-logo').classList.toggle('logo-hidden');
      const menu = this.state.menu;
      const newMenu = menu.map( (m) => {
        if (m.id === 'expandToggle') {
          const newClass = m.class === 'expand' ? 'collapse' : 'expand';
          const newIcon = m.icon === 'right' ? 'left' : 'right';
          return {
            ...m,
            class: newClass,
            icon: newIcon
          }
        }
        return m;
      });
      this.setState({ menu: newMenu });
      const isToggled = document.querySelector('.VSM-LeftNav-expanded');
      this.props.changeMenuStats(!!isToggled)
    }

    renderMenus(){
        return (
            <div className="v-menu">
                {
                    _.map(this.state.menu, (item)=>{
                        return this.renderEachMenu(item)
                    })
                }

            </div>
        )
    }

    checkNewUnreadMsgs() {
        const { selectedRole } = JSON.parse(sessionStorage.getItem('currentUser'));
        const id  = _.get(selectedRole,'organization.id');
        const { OrgHasUnread } = this.props;

        return _.get(OrgHasUnread,`${btoa(atob(id).split(':')[1])}`,0);
    }

    renderEachMenu(item){
        const select = item.id===this.state.select?'active':''
        const collapseFlag = this.state.menu[this.state.menu.length - 1].class; // if left nav is collapsed
        let alertCount = _.get(this.props,'alertUnratedCount.resultList.pageInfo.total');
        let alertsFlag = alertCount>0 ; // display red dot on alerts, if there are alerts
        const foodLogCount = _.get(this.props,'foodlogUnreadCount.foodLogList.pageInfo.total');
        const taskCount = _.get(this.props,'taskAssignmentUnreadList.taskAssignmentList.pageInfo.total');
        let foodlogUnreadFlag = foodLogCount>0;
        let taskUnreadFlag = taskCount >0;
        // try{
        //     resultList  = Client.readQuery({query:resultListMinimal,variables: {
        //             count: 1,
        //             filters: {
        //                 get enrolledProgramId() {
        //                     return UserClass.getEnrolledProgramActiveIdList()
        //                 },
        //                 severity: ['MISSED', 'RISK', 'CRITICAL'],
        //                 careStatus: "NEW",
        //                 alertsOnly: true
        //             }
        //         }});
        //     // if(!_.isEqual(resultList,this.state.alertCount)) {
        //     //     this.setState({
        //     //         alertCount: resultList
        //     //     })
        //     // }
        //     alertCount = resultList.resultList.pageInfo.total;
        //     alertsFlag = alertCount>0;
        // }
        // catch(e){
        // }
        //
        // try{
        //     foodLogCount = Client.readQuery({query:foodLogUnreadList});
        //     foodlogUnreadCount = foodLogCount.foodLogList.pageInfo.total>0;
        // }
        // catch(e){
        //     console.log(e);
        // }

        const newChatMsgFlag = this.checkNewUnreadMsgs();
        const title = item.title === 'Alerts' ? `Alerts: ${alertCount}` : item.title;
        return (
            <nav onClick={this.handleOnClick.bind(this, item)} className={`v-item ${select} ${item.class}`} key={item.id}>
                <div className='icon-container'>
                  {
                    item.class === 'expand' || item.class === 'collapse' ?
                      <Tooltip title={item.class === 'expand' ? 'Expand' : ''} placement='right'>
                        <Icon type={item.icon} className='leftnav-icon' />
                      </Tooltip>
                    :
                      <Tooltip title={collapseFlag === 'expand' ? title : ''} placement='right'>
                        <div className={item.id === 'alerts' && alertsFlag ? 'red-circle' : ''}></div>
                        <div className={item.id === 'messages' && newChatMsgFlag ? 'red-circle-chat' : ''}></div>
                        <div className={item.id === 'foodlog' && foodlogUnreadFlag ? 'red-circle-chat' : ''}></div>
                        <div className={item.id ==='TaskAssignments'&&taskUnreadFlag ? 'red-circle-chat':''}></div>
                          {
                          item.src ? <img className='inactive-patient-icon' src={item.src} id={item.title}/> :
                          (
                            React.isValidElement(item.icon)
                              ? item.icon
                              : <i className={`vsm-icon icon-${item.icon}`} />
                          )
                        }
                      </Tooltip>
                  }
                </div>
                <div className='icon-container collapse_icon'>
                    {
                        item.class === 'expand' || item.class === 'collapse' ?
                            <Tooltip title={item.class === 'expand' ? 'Expand' : ''} placement='right'>
                                <Icon type={item.icon} className='leftnav-icon' />
                            </Tooltip>
                            :
                            <Tooltip title={collapseFlag === 'expand' ? title : ''} placement='right'>
                                <div className={item.id === 'alerts' && alertsFlag ? 'red-circle' : ''}></div>
                                <div className={item.id === 'messages' && newChatMsgFlag ? 'red-circle-chat' : ''}></div>
                                <div className={item.id === 'foodlog' && foodlogUnreadFlag ? 'red-circle-chat' : ''}></div>
                                <div className={item.id ==='TaskAssignments'&&taskUnreadFlag ? 'red-circle-chat':''}></div>

                                {
                                    item.src ? <img className='inactive-patient-icon' src={item.src} id={item.title}/> :
                                        (
                                            React.isValidElement(item.icon)
                                                ? item.icon
                                                : <i className={`vsm-icon icon-${item.icon}`} />
                                        )
                                }
                            </Tooltip>
                    }
                </div>
                <b>{item.title}</b>
                {this.renderBadge(item.Badge)}
            </nav>
        )
    }

    handleOnClick(item){
      const {handleChangePage, curPage} = this.props;
      if(item.isLink && item.href) {
        window.open(item.href)
      }
      else if(item.href){
          browserHistory.push(item.href)
      }
      else if(item.onClick){
          item.onClick(item)
      }
      if(curPage == null) {
        handleChangePage('dashboard');
        Mixpanel.time_event('dashboard');
      }

      if(curPage != item.id) {
        Mixpanel.calculateDuration(curPage);
        handleChangePage(item.id);
        Mixpanel.time_event(item.id);
      }
      Mixpanel.track('clicked',item.id,'menu');
    }

    // 9/11/17 - JV - Check if there are new alerts
    // gqlAlertsQuery() {
    //   const enrolledPrograms = UserClass.getEnrolledProgramActiveIdList();
    //   // const teamList = UserClass.getTeamList();
    //   if(!enrolledPrograms) return;
    //
    //   Client.query({
    //     query: resultListMinimal,
    //     variables: {
    //       count: 1,
    //       filters: {
    //         get enrolledProgramId() {
    //           return UserClass.getEnrolledProgramActiveIdList()
    //         },
    //         severity: ['MISSED', 'RISK', 'CRITICAL'],
    //         careStatus: "NEW",
    //         alertsOnly: true
    //       }
    //     },
    //     fetchPolicy: 'network-and-cache'
    //   })
    //       .then(data => {
    //     this.setState(prev => ({
    //       alertCount: _.get(data, 'data.resultList.pageInfo.total', prev.alertCount)
    //     }));
    //   });
    // }

    // gqlFoodLogQuery() {
    //     Client.query({
    //         query: foodLogUnreadList,
    //         fetchPolicy: 'network-first'
    //     })
    // }
}

Component.propTypes = {
    /*
     * {id, title, icon, href||onClick}
     */
    menu : PropTypes.array,

    /*
     * {src, href}
     */
    logo : PropTypes.object,
    //channels: PropTypes.object
}

const mapState = ({ chat }, ownProps) => {
  return {
    // channels: chat.main.channels,
      OrgHasUnread: chat.main.OrgHasUnread
  }
};

const mapDispatch = dispatch => {
    return {
        changeMenuStats: (menuOpened) => dispatch(changeMenuStats(menuOpened))
    }
};

const alertVariables = { count: 1,
  filters:{
    fromDate : moment().subtract(7,'days').startOf('day').valueOf(),
        // new Date((new Date()).getTime() - 7 * 24 * 3600000),
    get enrolledProgramId() {
      return UserClass.getEnrolledProgramActiveIdList()
    },
    // severity: ['MISSED', 'RISK', 'CRITICAL'],
    severity: ['CRITICAL'],
    careStatus: "NEW",
    alertsOnly: true
  } };

const foodlogUnreadVariables =foodLogHelper.getFoodLogVariables( {
  count: 1,
  filter:{
    fromDate : new Date((new Date()).getTime() - DAYS_RANGE * 24 * 3600000),
    reviewed:false
  } });

const taskAssignmentUnreadVariables = ()=>{
    const currentUserId = sessionStorage.getItem('currentUserId');
    return {
                count: 1,
                filters: {
                    assignedTo:[currentUserId],
                }
           }
}
//check for new foodLog and alerts, auto refresh every POLL_INTERVAL ms
export default compose(withApollo,
    withState("pollInterval", "setPollInterval", POLL_INTERVAL),
    lifecycle({
            componentDidMount(){
                const { setPollInterval } = this.props;
                setPollInterval(POLL_INTERVAL);
            }
    }),
  graphql(foodLogUnreadList,{
    name:'foodlogUnreadCount',
    options:{
      variables:foodlogUnreadVariables,
      fetchPolicy:'network-only',
      pollInterval: POLL_INTERVAL
    }
  }),
  graphql(taskAssignmentUnreadList,{
     name:'taskAssignmentUnreadList',
     options:props=>({
         variables:{
             count: 1,
             filters: {
                 assignedTo:[sessionStorage.getItem('currentUserId')],
             }
         },
         fetchPolicy:'network-only',
         pollInterval: props.pollInterval || 0
     })
  }),
  graphql(resultListMinimal,{
    name:'alertUnratedCount',
    options:{
      variables:alertVariables,
      fetchPolicy:'network-only',
      pollInterval: POLL_INTERVAL
    }
  })
  ,connect(mapState, mapDispatch))(Component);
