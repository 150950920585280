import Base from './Base'
import { _, moment } from 'ihcomponent'
import { FormatHelper, RequestCache } from 'libModule/utils'
import I18N from 'modulesAll/I18N'
import convertor from '@ihealth/convertor';
import i18nMap from '../../I18N/index';
import Client from 'libModule/gqlClient';
import { GQLHelper, encryptText, decryptRole } from 'libModule/utils';
import Store from 'libModule/reduxStore';
import { userForSessionStorage } from 'modulesAll/graphql/user'
import { enrolledProgramListMinimal as enrolledProgramList } from 'modulesAll/graphql/enrolledProgramList'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { logout } from 'authModule/actions/CurrentUser'
import { DEFAULT_NOTE_PRESET } from 'modulesAll/utils/constants/task'
import { convertInchesToFeet, getRemainderAfterConvertingToFeet } from 'libModule/utils/convertUnits'
import BMIStatusMap from 'modulesAll/patient/main/constants/BMIStatusMap.js';
import React from 'react';

const filterDoctors = () => {
  const teams = JSON.parse(sessionStorage.getItem('currentUser')) && JSON.parse(sessionStorage.getItem('currentUser')).team;
  let doctors = [];
  for (let index in teams) {
    const members = teams[index].members;
    for (let memberId in members) {
      const member = members[memberId];
      const allRoles = _.get(member, 'user.allRoles', []);
      const hasDoctorRole = _.filter(allRoles, a => a.name == 'Doctor').length != 0;
      const { profile, id } = member.user;
      if (hasDoctorRole) {
        const doctor = {
          id: id,
          fullName: profile.fullName,
          primary: true
        }
        if (!_.find(doctors, { id: id }))
          doctors.push(doctor);
      }
    }
  }
  return doctors;
}

export default class extends Base {
  format(type, param = false) {
    switch (type) {
      case 'nric':
      case 'NRIC':
        return this._getNRIC()
      case 'PRN':
        return this._getPRN()
      case 'DL':
      case 'dl':
        return this._getDL()
      case 'avator':
        return this._getAvator()
      case 'age':
        return this._getAge()
      case 'tag':
        return this._getTag()
      case 'healthConditions':
        return this._getHealthConditions()
      case 'address':
        return this._getAddress()
      case 'role':
        return this._getRole()
      case 'department':
        return this._getDepartment()
      case 'institution':
        return this._getInstitution()
      case 'specialty':
        return this._getSpecialty()
      case 'employeeId':
        return this._getEmployeeId()
      case 'registeredAt':
        return this._getRegisteredAt()
      case 'mobilePhone':
        const mobile = _.find(this._get('phone'), (item) => item.type === 'MOBILE')
        return this._getPhone(mobile)
      case 'homePhone':
        const home = _.find(this._get('phone'), (item) => item.type === 'HOME')
        return this._getPhone(home)

      case 'email':
        if (!param) {
          return this._get('email.0.address')
        }
        const emails = _.map(this._get('email'), (email) => email.address)
        return emails.join(' | ')

      case 'gender':
        return this._getGender(param)
      case 'techLevel':
        return this._getTechLevel(param)
      case 'motivation':
        return this._getMotivation(param)
      case 'star':
        return this._getStar(param)

      case 'emailNotification':
        return this._getNotificationEmail(param)

      case 'smsNotification':
        return this._getNotificationSMS(param)
      case 'loginID':
        return this._getLoginID()
      case 'CCM/RPM':
        return this._getCCMRPM(param);
      case 'vitalType':
        return this._getVitalTypes(param);
      default:
        return super.format(type, param)
    }

  }

  static async saveUserInfo({ sessionToken, id, role, appSettings, allRoles,lastLogIn }) {
    const userRole = `${role.category}:${role.name}`;
    const encryptedUserRole = encryptText(sessionToken, userRole)

    sessionStorage.setItem('authToken', sessionToken)
    sessionStorage.setItem('currentUserId', id)
    sessionStorage.setItem('lastLogIn',_.get(lastLogIn,'createdAt',null));
    sessionStorage.setItem('currentUserRoles', encryptedUserRole)

    const appRolesKV = {};
    _.get(appSettings, 'role', []).map(o => {
      appRolesKV[`${o.category}:${o.name}`] = o.refId;
    });
    sessionStorage.setItem('appRoles', JSON.stringify(appRolesKV));

    const accessRole = _.get(role, 'access').map(o => { return `${o.action}:${o.rule}` })
    const encryptedAccessRole = encryptText(sessionToken, JSON.stringify([userRole].concat(accessRole)))
    sessionStorage.setItem('userAccess', accessRole ? encryptedAccessRole : encryptedUserRole)

    // IH-1051, only set when user logs in
    if (allRoles) {
      sessionStorage.setItem('authAllRoles', JSON.stringify(allRoles));
    }

    try {
      const resUser = await Client.query({
        query: userForSessionStorage,
        variables: { id },
        fetchPolicy: 'network-only'
      })

      const notePreset = resUser.data.user.allRoles.find(val => val.refId === role.refId).organization.notePreset || DEFAULT_NOTE_PRESET;

      const userWithSelectedRole = { // IH-1052 Use selectedRole instead of just getting first from allRoles
        ...resUser.data.user,
        selectedRole: role ? { ...role, organization: { ...role.organization, notePreset } } : null
      };
      sessionStorage.setItem('currentUser', JSON.stringify(userWithSelectedRole));
      const team = JSON.parse(sessionStorage.getItem('currentUser')).team;
      if (team) {
        sessionStorage.setItem('doctorName', JSON.stringify(filterDoctors(team)));
      }
      const presetNotes = _.get(userWithSelectedRole, 'selectedRole.organization.notePreset') || DEFAULT_NOTE_PRESET
      sessionStorage.setItem('presetNotes', JSON.stringify(presetNotes))
      await this.saveEPsForProvider()

      //TODO: need to store userRoles in session storage so that survive browser refresh


    }
    catch (err) {
      console.log(err)
      Store.dispatch(openErrorModal(GQLHelper.formatError(err)))
    }
  }

  static async checkRole({ role }) {
    return true
  }

  static async saveEPsForProvider() {
    const userId = sessionStorage.getItem('currentUserId') //plain text
    const currentUserRoles = decryptRole() //plain text

    //only get EPs if the logged in user is a provider
    if (currentUserRoles === 'PROVIDER:Nurse' || currentUserRoles === 'PROVIDER:Doctor' || currentUserRoles === 'PROVIDER:Medical Assistant') {
      try {
        const resEP = await Client.query({
          query: enrolledProgramList,
          variables: { filters: { managedBy: userId } },
          fetchPolicy: 'network-only' //for VS-1225
        });
        console.log('got data for enrolledProgramList ', resEP);
        const enrolledProgramListData = resEP.data.enrolledProgramList.data
        const enrolledProgramIdList = enrolledProgramListData.map((ep) => ep.id)
        const enrolledProgramActiveIdList = enrolledProgramListData.filter((ep) => {
          return ep.status !== 'DISCHARGED' && ep.status !== 'RETURNED'
        }).map((ep) => ep.id)
        sessionStorage.setItem('enrolledProgramIdList', JSON.stringify(enrolledProgramIdList))
        sessionStorage.setItem('enrolledProgramActiveIdList', JSON.stringify(enrolledProgramActiveIdList))
        const adminProgramList = _.uniqBy(enrolledProgramListData.map((ep) => ep.adminProgram), 'id')
        sessionStorage.setItem('adminProgramList', JSON.stringify(adminProgramList))
        const adminProgramMap = {}
        adminProgramList.map((ap) => {
          if (ap && ap.id) {
            adminProgramMap[ap.id] = enrolledProgramListData.filter((ep) => ep.adminProgram.id === ap.id).map((ep) => ep.id)
          }
        })
        sessionStorage.setItem('adminProgramMap', JSON.stringify(adminProgramMap))
      }
      catch (err) {
        console.log(err)
        Store.dispatch(openErrorModal(GQLHelper.formatError(err)))
      }
    }
  }

  static getAdminProgramList() {
    return JSON.parse(sessionStorage.getItem('adminProgramList')) || []
  }

  static getAdminProgramIds() {
    return this.getAdminProgramList().map((ap) => ap.id)
  }

  static getAdminProgramFilter() {
    return this.getAdminProgramList().map((ap) => { return { value: ap.id, text: ap.name } })
  }

  static getAdminProgramMap() {
    return JSON.parse(sessionStorage.getItem('adminProgramMap'))
  }

  static getEnrolledProgramIdList() {
    return JSON.parse(sessionStorage.getItem('enrolledProgramIdList'))
  }

  static getEnrolledProgramActiveIdList() {
    return JSON.parse(sessionStorage.getItem('enrolledProgramActiveIdList'))
  }

  static getTeamList() {
    return JSON.parse(sessionStorage.getItem('currentUser')).team || []
  }

  static getHealthConditionList() {
    return _.uniq(this.getTeamList().map((t) => t.healthCondition))
  }

  _getNRIC() {
    const { identification } = this.variables
    // const rs = _.find(identification, (item)=>(item.type==='NRIC'||item.type.description==='NRIC'))
    // if(rs){
    //   return rs.value
    // }
    const rs = _.get(identification, '0.value') || ''

    return rs

  }
  _getPRN() {
    const { identification } = this.variables
    const rs = _.find(identification, (item) => (item.type === 'PRN' || item.type.description === 'PRN'))
    if (rs) {
      return rs.value
    }

    return ''

  }

  _getRegisteredAt() {
    const { registeredAt } = this.variables;
    return moment(registeredAt).format('MM/DD/YYYY')
  }
  _getDL() {
    const { identification } = this.variables
    const rs = _.find(identification, (item) => (item.type.code === 'DL' || item.type.description === 'Driving License'))
    if (rs) {
      return rs.value
    }

    return ''

  }
  _getAvator() {
    const { profile } = this.variables
    return profile && profile.avatar && profile.avatar.link ? profile.avatar.link : '/image/default_avator.png';
  }
  _getAge() {
    const dob = this._get('profile.birthday')
    if (!dob) return ''

    return moment().diff(moment(dob, 'YYYY-MM-DD'), 'years')
  }
  _getTag() {
    const { profile } = this.variables
    const rs = []
    if (!profile) return rs
    if (profile.gender) {
      rs.push(this._getGender())
    }
    const dob = this._get('profile.birthday');
    if (dob) {
      rs.push(moment(dob).format('MM/DD/YYYY'));
    }
    const age = this._getAge()
    if (age) {
      rs.push(age + ' yrs')
    }
    if (profile.language) {
      rs.push(_.get(profile, 'language.description'))
    }
    if (profile.appLanguage) {
      rs.push(_.get(profile, 'appLanguage.description'))
    }

    /*remove height && weight && ethnicity SC-1446
    if(profile.height){
      const heightInInches = convertor.height(profile.height.value, 'cm', 'inch', 0);
      const heightInFeet = convertInchesToFeet(heightInInches);
      const heightRemainderInInches = getRemainderAfterConvertingToFeet(heightInInches);
      rs.push(`${heightInFeet} ${i18nMap.get('measure.units_height_ft')} ${heightRemainderInInches} ${i18nMap.get('measure.units_height_inches')}`);
      // rs.push(`${profile.height.value}${profile.height.unit}`)
    }
    if(profile.weight){
      const weightInLbs = convertor.HS(profile.weight.value, 'kg', 'lbs');
      rs.push(`${Math.round(weightInLbs)} ${i18nMap.get('measure.units_weight_lbs')}`)
    } else if(profile.latestWeight){
      rs.push(`${profile.latestWeight.value}${profile.latestWeight.unit}`)
    }/*
    //calculate BMI based on kg and meter
    const heightInM = _.get(profile,'height.value',0)/100;
    const weightInKg = _.get(profile,'weight.value',0);

    if(heightInM&&weightInKg){
      const BMI = weightInKg/heightInM/heightInM;
      rs.push(`BMI ${BMI.toFixed(1)}`);

    }
    if(profile.race){
      rs.push(_.get(profile, 'race.description'))
    }
    if(profile.nationality){
       rs.push(_.get(profile, 'nationality.description'))
     }
    */
    //calculate BMI based on kg and meter
    const heightInM = _.get(profile, 'height.value', 0) / 100;
    const weightInKg = _.get(profile, 'weight.value', 0);

    if (heightInM && weightInKg) {
      const BMIValue = (weightInKg / heightInM / heightInM).toFixed(1);
      const BMIStatus = this._getBMIStatus(BMIValue);
      rs.push(<span>BMI {BMIValue} (<span style={{ color: BMIStatus.color }}>{BMIStatus.displayName}</span>)</span>);
    }

    return rs
  }

  _getBMIStatus(value) {
    let status = '';
    switch (true) {
      case (value < 18.5):
        status = 'UNDERWEIGHT';
        break;
      case (value >= 18.5 && value <= 24.9):
        status = 'NORMAL';
        break;
      case (value >= 25 && value < 29.9):
        status = 'OVERWEIGHT';
        break;
      default:
        status = 'OBESITY';
        break;
    }
    return BMIStatusMap[status];
  }

  _getHealthConditions() {
    const { profile } = this.variables;
    let result = [];
    if (!profile) return result;
    if (profile.healthConditions) {
      result = profile.healthConditions;
    }
    return result.map(val => FormatHelper.healthConditions(val));
  }
  _getAddress() {
    const { addresses } = this.variables
    if (!addresses || !addresses[0]) return ''
    return FormatHelper.address(addresses[0])
  }
  _getPhone(o) {
    if (!o) return ''
    return FormatHelper.phone(o)
  }
  _getGender(param) {
    const gender = this._get('profile.gender')
    if (!gender) return ''
    if (param) return gender

    return I18N.get(`gender.${gender}`)
  }
  _getTechLevel(param) {
    const techLevel = this._get('profile.techLevel')
    if (!techLevel) return ''
    if (param) return techLevel

    return I18N.get(`techLevel.${techLevel}`)
  }
  _getMotivation(param) {
    const motivation = this._get('profile.motivation')
    if (!motivation) return ''
    if (param) return motivation

    return I18N.get(`motivation.${motivation}`)
  }
  _getRole() {
    // const {allRoles} = this.variables
    // if(!allRoles) return ''

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const selectedRole = _.get(currentUser, 'selectedRole');
    if (!selectedRole) return '';

    if (selectedRole) {
      switch (`${selectedRole.category}:${selectedRole.name}`) {
        case 'PROVIDER:Nurse':
          return I18N.get('userrole.nurse')
        case 'PROVIDER:Doctor':
          return I18N.get('userrole.doctor')
        case 'PROVIDER:Medical Assistant':
          return I18N.get('userrole.medicalAssistant')
        default:
          return selectedRole.name
      }
    }
    else {
      return '';
    }
  }
  _getEmployeeId() {
    const { profile } = this.variables
    return profile && profile.employeeId
  }

  _getDepartment() {
    const { profile } = this.variables
    return profile && profile.department
  }
  _getInstitution() {
    // const {allRoles} = this.variables
    // return allRoles && allRoles.length > 0 ? _.get(allRoles, '0.organization.name') : '';

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const selectedRole = _.get(currentUser, 'selectedRole');
    return selectedRole ? selectedRole.organization.name : '';
  }

  _getSpecialty() {
    const { profile } = this.variables
    return profile && profile.specialty
  }
  //
  // _getStar(){
  //   const {starred, starredReason} = this.variables
  //   if(!starred){
  //     return {
  //       status : false,
  //       reason : ''
  //     }
  //   }
  //
  //   return {status : true, reason : starredReason}
  // }

  _getCCMRPM() {
    const { profile } = this.variables;
    const programCategories = _.get(profile, 'programCategories', []) || [];
    const categories = programCategories.map(o => {
      return {
        name: o.name,
        enrolled: o.enrolled,
        enrolledDate: o.enrolledDate
      }
    })

    return categories;
  }

  _getStar() {
    const { hasFlag, flagDetails } = this.variables
    if (!hasFlag) {
      return {
        status: false,
        reason: ''
      }
    }
    const starredReason = _.get(flagDetails, '0.reason');
    return { status: true, reason: starredReason }
  }
  _getNotificationEmail() {
    const { userSettings } = this.variables
    if (userSettings) {
      return _.includes(userSettings.notification, 'EMAIL') ? 'viaEmailYes' : 'viaEmailNo'
    }
    return 'viaEmailNo'
  }

  _getNotificationSMS() {
    const { userSettings } = this.variables
    if (userSettings) {
      return _.includes(userSettings.notification, 'SMS') ? 'viaSMSYes' : 'viaSMSNo'
    }
    return 'viaSMSNo'
  }
  _getLoginID() {
    return _.get(this.variables, 'username')
  }
}
