export default {
    count: {
        criticalHighCount:'Critical High Count',
        criticalLowCount:'Critical Low Count',
        lowCount:'Low Count',
        highCount:'High Count',
        normalCount:'Normal Count',
        hypoglycemiaCount:'Hypoglycemia Count',
        bedtimeCount:'Bedtime Count',
        overnightCount:'Overnight Count',
    },
    value: {
        averageFasting: 'Average Fasting',
        averageBeforeMeal: 'Average Before Meal',
        averageAfterMeal: 'Average After Meal',
        // pairedMeasurement: 'Paired Measurement',
    }
}
