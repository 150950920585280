import React from 'react';
import TaskAssignmentListComponent from '../components/TaskAssignmentListComponent';
import { Tabs, Tooltip,Input,notification } from 'antd';
import {connect} from "react-redux";
import createTableAction from 'libModule/table/TableActions';
import map  from '../constants/tableMap';
import taskAssignmentList from '../query/taskAssignmentList';
import MyTasksModalComponent from '../components/MyTasksModalComponent';
import TrackingTaskModalComponent from '../components/TrackingTaskModalComponent';
import { graphql, compose } from 'react-apollo';
const { TextArea } = Input;
const { TabPane } = Tabs;
const { taskTypes } = map;
const tableActions = createTableAction('task_assignment_list',{ shouldUpdateHistory: false });
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

class TaskAssignmentListContainer extends React.Component {
    constructor(){
        super();
        this.state ={
            note:''
        }
    }

    handleClick = ()=>{
        const { setModalData } = this.props;
        setModalData({
            showModal:false,
            modalData:null
        })
    }

    componentDidMount(){
        notification.close('newTask');
        notification.close('dueToday');
        notification.close('overdue');
    }
    renderModal = () => {
        const { props } = this;
        const { modalData,taskType } = props;
        const currentUserId = sessionStorage.getItem('currentUserId');
        const { assignedTo,createdBy } = modalData;
        const assigneeId = _.get(assignedTo,'id');
        const assignerId = _.get(createdBy,'id');
        if(taskType=='2'&&(assigneeId!=currentUserId&&assignerId!=currentUserId)){
           return <MyTasksModalComponent {...props}/>;
        }
        if(taskType=='1'&&assignerId==currentUserId){
            return <TrackingTaskModalComponent { ...props}/>;
        }
        return assigneeId===currentUserId ?  <MyTasksModalComponent {...props}/>:<TrackingTaskModalComponent { ...props}/> ;
    };

    componentWillUnmount(){
        const { resetTableProps } = this.props;
        resetTableProps();
    };

    onChangeTab = (index)=>{
        const { setTaskType,resetTableProps } = this.props;
        setTaskType(index);
        let task = '';
        switch(index) {
            case('0'):
                task = 'My tasks';
                break;
            case('1'):
                task = 'Tracking tasks';
                break;
            case('2'):
                task = 'All tasks';
                break;
        }
        Mixpanel.track('clicked', task, 'task list page', '')
        resetTableProps();
    }

    render(){

       const { props,onChangeTab }= this;
       const { taskType,setTaskType,showModal } = this.props;
       const tabArray = _.map(taskTypes,({type,title,tooltip},i)=><TabPane tab={<Tooltip title={tooltip} key={i}>{title}</Tooltip>} key={i} forceRender={true}>
                                                                    <TaskAssignmentListComponent {...props}/>
                                                                  </TabPane>)
       return <div>
                    { showModal ? this.renderModal():'' }
                    <Tabs activeKey={taskType} style={{ padding:20 }} animated={false} tabBarStyle={{ height:50 }} onChange={ (index)=>onChangeTab(index) } className='taskAssignmentTabs' id='taskAssignmentTabs'>
                        { tabArray }
                    </Tabs>
               </div>
    }

}
const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    ...state.globalTables.task_assignment_list,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...tableActions
    };
}
const sortMap = {
    createdAt:'CREATED_AT',
    dueDate:'DUE_DATE'
}

const withData = graphql(taskAssignmentList,{
    options:(props)=>{
        const currentUserId = sessionStorage.getItem('currentUserId');
        const { taskType,page,sort,filter } = props;
        const { pageSize,current } = page;
        let variables = {};
        let filters = {};
        let role = null;
        if(taskType=='0'){
            role = 'assignedTo';
        }
        else if(taskType=='1'){
            role = 'createdBy';
        }
        if(role) {
            filters[`${role}`]= currentUserId
        }
        variables.count = pageSize;
        variables.page = current;
        variables.sort = {
            field:sortMap[_.get(sort,'field')],
            direction: _.get(sort,'direction')
        }
        filters = _.extend(filters,filter);
        variables.filters = filters;
        return{
            variables,
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
         const { loading, taskAssignmentList, refetch, page } = data;
         if(loading){
             return {
                 loading
             }
         }
        return {
            taskList:_.get(taskAssignmentList,'data'),
            pageInfo:_.get(taskAssignmentList,'pageInfo'),
            refetch
         }
    }
})
export default compose(connect(mapStateToProps, mapDispatchToProps),withData)(TaskAssignmentListContainer);
