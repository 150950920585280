import {
    React, BaseComponent, ND, moment, _
} from './util'
import IHInputNumber from './IHInputNumber'
import IHSelect from './IHSelect'
import IHTimePicker from './IHTimePicker'
import IHIcon from './IHIcon'

import { WeeklyMealSchedule } from '../ShareCare/WeeklyMealSchedule';
import PropTypes from 'prop-types';

const ERROR = {
    'NOT_FILLED' : 'Time slot needs to be filled or deleted',
    'GREATER' : 'End time should be later than Start time'
}
const getDefaultValue = ()=>{
    return {
        value : [null, null],
        error : null //ERROR.NOT_FILLED
    }
}

const Component = class extends BaseComponent{

    init(){
        this.simple = this.props.simple || false
        this.state = {
            error : null,
            frequency : this.props.frequency,
            slotType: null,
            time : {
                morning : {
                    time : '9:00am',
                    select : false
                },
                afternoon : {
                    time : '1:00pm',
                    select : false
                },
                evening : {
                    time : '6:00pm',
                    select : false
                },
                supper : {
                    time : '10:00pm',
                    select : false
                }
            },

            frequencyValue : {
                times : 1,
                every : 1,
                unit : 'DAILY'
            },

            timeSlotValue : [getDefaultValue()],
            timeSlotValueForMeal : [],

            weeklyMealSchedule: null,
        }

        if(this.props.initialValue && this.props.showInitialValue){
            this.state = _.merge(this.state, this.props.initialValue)
        }
    }

    defineBaseClassName(){
        return 'IH-TimePickerGroup'
    }

    render() {
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      const currentUserFirstRoleCategory = _.get(currentUser, 'allRoles.0.category');
      const classAndStyle = this.getClassAndStyleProp()
      const isDisabledFrequency = this.props.isDisabledFrequency || false

      let selfMonitoringSchedule;
      if (this.state.frequency === 'slot') selfMonitoringSchedule = this.renderTimePicker();
      else if (this.state.frequency === 'weeklyMeal') {
        selfMonitoringSchedule = < WeeklyMealSchedule
              directEdit={currentUserFirstRoleCategory !== 'ADMIN'}
              updateForm={(weeklyMealSchedule) => this.setState({weeklyMealSchedule}, this.handleOnChange)}
              updateFrequency={(frequency)=>this.setState({ frequency })}
              weeklyMealSchedule={this.state.weeklyMealSchedule}
            />
        }
        else if(this.state.frequency === 'frequency' && !isDisabledFrequency) selfMonitoringSchedule = this.renderSelectFrequency();
        return (
            <div {...classAndStyle}>
                {this.renderTitle()}
                {this.renderFrequency()}

                { selfMonitoringSchedule }
                {/* This error is not displayed, error is handled by antd fields */}
                {this.state.error && this.state.frequency === 'frequency' && (<div className="ih-error">{this.state.error}</div>)}
            </div>
        );
    }

    renderTitle(){
        if(!this.props.title){
            return null
        }

        return <p className="ih-tlt">{this.props.title}</p>
    }

    renderFrequency(){
        if(!this.props.frequency){
            return null
        }

        const isDisabledFrequency = this.props.isDisabledFrequency || false
        const onChange = (e)=>{
            const v = e.target.value
            this.props.clearErrors()
            this.setState({
                frequency : v
            }, this.handleOnChange)
        }
        const renderByWeekMealsOnly = this.props.vitalType === 'Blood Glucose';
        const radioGroup = isDisabledFrequency ? (
          <ND.Radio.Group onChange={onChange} value={this.state.frequency} className='child-round-border-radius'>
            <ND.Radio.Button value="slot">By Time Slot</ND.Radio.Button>
          </ND.Radio.Group>
        ) :
        (
            renderByWeekMealsOnly ?
            <ND.Radio.Group onChange={onChange} value={this.state.frequency} className='child-round-border-radius'>
                <ND.Radio.Button value="weeklyMeal">By Weekly Meals</ND.Radio.Button>
            </ND.Radio.Group> :
            <ND.Radio.Group onChange={onChange} value={this.state.frequency}>
                <ND.Radio.Button value="slot">By Time Slot</ND.Radio.Button>
                <ND.Radio.Button value="frequency">By Frequency</ND.Radio.Button>
            </ND.Radio.Group>
        )
        {/*<ND.Radio.Group onChange={onChange} value={this.state.frequency}>*/}
        {/*{ this.props.vitalType !== 'Blood Glucose' ? null : <ND.Radio.Button value="weeklyMeal">By Weekly Meals</ND.Radio.Button> }*/}
        {/*<ND.Radio.Button value="slot">By Time Slot</ND.Radio.Button>*/}
        {/*<ND.Radio.Button value="frequency">By Frequency</ND.Radio.Button>*/}
        {/*</ND.Radio.Group>*/}
        return (
          <div className="ih-fy">
            { radioGroup }
          </div>
        )
    }

    renderTimePicker(){
        if(this.props.slotType === 'time'){
            return this.renderSlotTypeByTime()
        }
        else if(this.props.slotType === 'meal'){
          return this.renderSlotTypeByMeal()
        }

        const list = [
            {
                dur : 'Morning',
                time : [
                    '9:00am',
                    '9:30am',
                    '10:00am',
                    '10:30am',
                    '11:00am',
                    '11:30am',
                    '12:00pm',
                    '12:30pm'
                ],
                help : '9am - 12:59pm'
            },
            {
                dur : 'Afternoon',
                time : [
                    '1:00pm',
                    '1:30pm',
                    '2:00pm',
                    '2:30pm',
                    '3:00pm',
                    '3:30pm',
                    '4:00pm',
                    '4:30pm',
                    '5:00pm',
                    '5:30pm'
                ],
                help : '1pm - 5:59pm'
            },
            {
                dur : 'Evening',
                time : [
                    '6:00pm',
                    '6:30pm',
                    '7:00pm',
                    '7:30pm',
                    '8:00pm',
                    '8:30pm',
                    '9:00pm',
                    '9:30pm'
                ],
                help : '6pm - 10:59pm'
            },
            {
                dur : 'Supper',
                time : [
                    '10:00pm',
                    '10:30pm',
                    '11:00pm',
                    '11:30pm',
                    '12:00am',
                    '12:30am',
                    '1:00am',
                    '1:30am',
                    '2:00am',
                    '2:30am',
                    '3:00am',
                    '3:30am',
                    '4:00am',
                    '4:30am',
                    '5:00am',
                    '5:30am',
                    '6:00am',
                    '6:30am',
                    '7:00am',
                    '7:30am',
                    '8:00am',
                    '8:30am',
                ],
                help : '10pm - 12am'
            }
        ]

        return (
            <div className="ih-tp">
                {
                    _.map(list, (item, index)=>{
                        return this.renderEachTimePicker(item, index)
                    })
                }
            </div>
        )
    }

    renderSlotTypeByMeal(){
        const s1 = [
            {
                name : 'Before Breakfast',
                value : 'BEFORE_BREAKFAST'
            },
            {
                name : 'Before Lunch',
                value : 'BEFORE_LUNCH'
            },
            {
                name : 'Before Dinner',
                value : 'BEFORE_DINNER'
            },
            {
                name : 'Before Supper',
                value : 'BEFORE_SUPPER'
            }
        ]
        const s2 = [
            {
                name : 'After Breakfast',
                value : 'AFTER_BREAKFAST'
            },
            {
                name : 'After Lunch',
                value : 'AFTER_LUNCH'
            },
            {
                name : 'After Dinner',
                value : 'AFTER_DINNER'
            },
            {
                name : 'After Supper',
                value : 'AFTER_SUPPER'
            }
        ]

        const onClick = (v)=>{
            const sm = this.state.timeSlotValueForMeal
            if(_.includes(sm, v)){
                _.remove(sm, (s)=>s===v)
            }
            else{
                sm.push(v)
            }
            this.setState({
                timeSlotValueForMeal : sm
            }, this.handleOnChange)
        }

        return (
            <div className="ih-sbm">
                <div className="v-sl">
                    {
                        _.map(s1, (item, index)=>{
                            let cn = ''
                            if(_.includes(this.state.timeSlotValueForMeal, item.value)){
                                cn = 'active'
                            }
                            return <dd className={cn} onClick={onClick.bind(this, item.value)} key={index}>{item.name}</dd>
                        })
                    }
                </div>
                <div className="v-sl">
                    {
                        _.map(s2, (item, index)=>{
                            let cn = ''
                            if(_.includes(this.state.timeSlotValueForMeal, item.value)){
                                cn = 'active'
                            }
                            return <dd className={cn} onClick={onClick.bind(this, item.value)} key={index}>{item.name}</dd>
                        })
                    }
                </div>
            </div>
        )
    }

    renderSlotTypeByTime(){
        let value = this.state.timeSlotValue

        const addNewEach = ()=>{
            if(this.state.error){
                return false
            }
            this.state.timeSlotValue.push(getDefaultValue())

            this.validateTimeSlotByTime(this.state.timeSlotValue)
        }

        return (
            <div className="ih-sbt">
                {
                    _.map(value, (item, index)=>this.renderEachSlotTypeByTime(item, index))
                }
                {this.state.error && (<div className="ih-error">{this.state.error}</div>)}

                <div className="sbt-add" onClick={addNewEach}>+Add Time Slot</div>
            </div>
        )
    }

    renderEachSlotTypeByTime(item, index){

        const tp1 = {
            disabledMinutes(){
                const range = _.range(0, 60)
                _.remove(range, (n)=>{
                    return _.includes([0, 15, 30, 45], n)
                })
                return range
            },
            placeholder : '[Select]',
            value : item.value[0],
            onChange : (m, v)=>{
                const sv = this.state.timeSlotValue
                const min = m.minute()

                if(!_.includes([0, 15, 30, 45], min)){
                    m.minute(0)
                }
                m.set({second:0,millisecond:0})
                sv[index].value[0] = m

                this.validateTimeSlotByTime(sv)
                this.handleOnChange(sv)

            },
            hideDisabledOptions:true,
            format : 'HH:mm'
        }
        const tp2 = {
            disabledMinutes(){
                const range = _.range(0, 60)
                _.remove(range, (n)=>{
                    return _.includes([0, 15, 30, 45], n)
                })
                return range
            },
            placeholder : '[Select]',
            onChange : (m, v)=>{
                const sv = this.state.timeSlotValue
                const min = m.minute()

                if(!_.includes([0, 15, 30, 45], min)){
                    m.minute(0)
                }
                m.set({second:0,millisecond:0})
                sv[index].value[1] = m

                this.validateTimeSlotByTime(sv)
                this.handleOnChange(sv)

            },
            value : item.value[1],
            hideDisabledOptions:true,
            format : 'HH:mm'
        }


        return (
            <div key={index} className="v-ea-sbt">
                <span className="s1">Time Slot {index+1}</span>
                <IHTimePicker {...tp1} />
                <span className="s1 s2">to</span>
                <IHTimePicker {...tp2} />

                <IHIcon onClick={this.removeEachSlotTypeByTime.bind(this, index)} type="close" />

                <span className="s3">{item.error}</span>
            </div>
        )
    }
    removeEachSlotTypeByTime(index){
        const v = this.state.timeSlotValue
        _.remove(v, (n, i)=>i===index)


        this.validateTimeSlotByTime(v)
    }

    validateTimeSlotByTime(sv){

         let ff = null

        //check time slot does not overlap, only check if there are at least 2 set of time slot in array
        let overlap =false;
        if (sv.length >= 2) {
          overlap = sv.some((v, i) => {
              const valueObjectTemp= [...sv.slice(0, i), ...sv.slice(i + 1)];
              return valueObjectTemp.some(
                e => {
                  if(!v.value[0] || !v.value[1] || !e.value[0] || !e.value[1]){
                    return false;
                  }
                  return moment.range(v.value[0].toDate(), v.value[1].toDate()).overlaps(moment.range(e.value[0].toDate(), e.value[1].toDate()));
                }
              )

          });
        }

        if(overlap){
          ff = 'Time slots cannot overlap'
        }
        this.setState({
            timeSlotValue : sv,
            error : ff
        }, this.handleOnChange)
    }

    renderEachTimePicker(item, index){

        const onChange = (v)=>{
            let time = _.clone(this.state.time)
            time[item.dur.toLowerCase()].time = v
            this.setState({
                time : time
            }, this.handleOnChange)
        }

        let onChangeBox = (e)=>{
            if(_.startsWith(e.target.className,'ih-each') || _.startsWith(e.target.className,'ih-ea')){
                let time = _.clone(this.state.time)
                time[item.dur.toLowerCase()].select = !time[item.dur.toLowerCase()].select
                this.setState({
                    time : time
                }, this.handleOnChange)
            }

            return false;
        }

        let cn = 'ih-each'
        if(this.state.time[item.dur.toLowerCase()].select){
            cn += ' active'
        }

        return (
            <div key={index} onClick={onChangeBox} className={cn}>
                <span className="ih-ea">{item.dur}</span>
                <ND.Select onChange={onChange} value={this.state.time[item.dur.toLowerCase()].time} dropdownClassName="IH-TimePickerGroup-Dropdown">
                    {
                        _.map(item.time, (t, i)=>{
                            return <ND.Select.Option key={i} value={t}>{t}</ND.Select.Option>
                        })
                    }
                </ND.Select>
                <span className="ih-help">{item.help}</span>
            </div>
        )
    }

    handleOnChange(){
        if(!this.props.onChange) return false

        _.delay(()=>{
            const result = this.getValue()

            this.props.onChange(result)
        }, 100)


    }

    getValue(){
        const result = {}
        if(this.props.frequency){
            result.frequency = this.state.frequency
        }
        if(result.frequency === 'slot'){
            result.time = this.state.time
        }
        else{
            result.frequencyValue = this.state.frequencyValue
        }

        if(this.props.slotType === 'time'){
            const sv = this.state.timeSlotValue
            if(this.state.error || _.find(sv, (item)=>item.error)){
                result.error = true
            }
            else{
                result.timeSlotValue = sv
            }
        }
        if(this.props.slotType === 'meal'){
            const sm = this.state.timeSlotValueForMeal
            result.timeSlotValueForMeal = sm
        }

        if(result.frequency === 'weeklyMeal'){
            const sm = this.state.weeklyMealSchedule
            result.weeklyMealSchedule = sm
        }

        return result
    }
    toNumber(num) {
      if (isNaN(num) || num === '') {
        return num;
      }
      return Math.abs(Number(num))
    }
    renderSelectFrequency(){
        const pn1 = {
            size : 'lg',
            className : 'c-ipn1',
            min: 1,
            value : this.toNumber(this.state.frequencyValue.times),
            onChange : (v)=>{
                const value = _.clone(this.state.frequencyValue)
                value.times = this.toNumber(v)
                this.setState({
                    frequencyValue : value
                }, this.handleOnChange)
            }
        }
        const pn2 = {
            size : 'lg',
            className : 'c-ipn1',
            value : this.state.frequencyValue.every,
            onChange : (v)=>{
                const value = _.clone(this.state.frequencyValue)
                value.every = v
                this.setState({
                    frequencyValue : value
                }, this.handleOnChange)
            }
        }
        const sp = {
            size : 'large',
            className : 'c-sp1',
            value : this.state.frequencyValue.unit,
            onChange : (v)=>{
                const value = _.clone(this.state.frequencyValue)
                value.unit = v
                this.setState({
                    frequencyValue : value
                }, this.handleOnChange)
            },
            option : [
                {
                    name : 'Day(s)',
                    value : 'DAILY'
                },
                {
                    name : 'Week(s)',
                    value : 'WEEKLY'
                },
                {
                    name : 'Month(s)',
                    value : 'MONTHLY'
                }
            ]
        }
        return (
            <div className="ih-th">
                <IHInputNumber {...pn1} />
                <span className="sp">times a</span>
                {this.simple?<span className="sp" style={{margin:0}}>day</span>:null}

                {this.simple?null:<IHSelect {...sp} />}
            </div>
        )
    }
}

Component.propsType = {
    vitalType : PropTypes.string,
    slotType : PropTypes.string,
    title : PropTypes.string,
    frequency : PropTypes.bool,
    onChange : PropTypes.func,

    simple : PropTypes.bool,

    /*
     * frequency,
     * during,
     * time
     */
    initialValue : PropTypes.object
}

export default Component
