import React, { Component } from 'react';
import {helpers as dataHelper} from "../helper";
import {Col, Row,Icon} from "antd";

class VisitPersonHeader extends Component{

    handleData(patient, visitInfo, editStatus){
        const data = {};
        let user = null;
        let typeAndStatus = null;
        if(editStatus){
            user = visitInfo.member;
            typeAndStatus = dataHelper.getTypeAndStatusFromVisit(visitInfo.vitalTypes, user.profile.lastMeasuredAt);
        } else {
            user = patient.user;
            typeAndStatus = dataHelper.getTypeAndStatus(patient.enrolledPrograms, patient.user.profile.lastMeasuredAt);
        }
        data.status = typeAndStatus.status;
        data.fullName = user.profile.fullName;
        data.birthday = user.profile.birthday;
        if(user.profile.gender){
            data.gender = user.profile.gender === 'M' || user.profile.gender === 'Male' ? 'Male' : 'Female';
        }else{
            data.gender = '-';
        }
        return data;
    }

    render() {
        const {patient, clickPatient, visitInfo, fromAppointments, editStatus,isFromVisitTable, fromDetail} = this.props;
        const data = this.handleData(patient, visitInfo, editStatus);
        return (
            <div className="inputVisitPatient"  style={{cursor: isFromVisitTable ? 'pointer':'default'}} onClick={ ()=>isFromVisitTable ? clickPatient() : null}>
                <Row>
                    <Col span={2} style={{textAlign:'center'}}><img src="/image/person-avatar.png"
                                       width={16}
                                       height={16}/>
                    </Col>
                    <Col span={5} style={{textOverflow:'ellipsis', overflow:'hidden'}}>{data.fullName}
                    </Col>
                    <Col span={5}>{data.birthday}
                    </Col>
                    <Col span={5}>{data.gender}
                    </Col>
                    <Col span={5}>{data.status}
                    </Col>
                    { editStatus||isFromVisitTable || fromDetail ? '': <Col span={2}><Icon type="close-circle" onClick={ ()=>(patient || fromAppointments) ? clickPatient() : null}/></Col>}
                </Row>
            </div>
        )
    }

}

export default VisitPersonHeader;
