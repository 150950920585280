import type from '../constants/EnrolledProgramInfoConstants'

export const setExtendStep = (extendStep)=>({
      type : type.SET_EXTEND_STEP,
      extendStep
});

export const setExtendValue = (extendValue)=>({
      type : type.SET_EXTEND_VALUE,
      extendValue
});

export const setExtendType = (extendType)=>({
      type : type.SET_EXTEND_TYPE,
      extendType
});

export const resetExtendValueType = () => ({
  type : type.RESET_EXTEND_VALUE_TYPE
});


export const setResuming = (resuming)=>({
      type : type.SET_RESUMING,
      resuming
});
