import _ from 'lodash';
import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo/index';
import { Spin } from 'antd';
import getSignedFileDownload from '../../../graphql/getSignedFileDownload';
import { IHLoading } from 'ihcomponent';
const ModalImageComponent = class extends Component {
  render() {
    const link = _.get( this.props, 'getSignedFileDownload.link', '/image/default_image.png');
    const { loading } = this.props;

    return (
        <div style={{ minHeight: 200,display:'flex',justifyContent:'center',alignItems:'center' }}>
            { loading ? <IHLoading/> : <img src={link} style={{maxWidth:'100%'}}/> }
        </div>
    );
  }
};

const withData = graphql(getSignedFileDownload, {
  options: ownProps => {
    const { fileKey, userId } = ownProps;
    return {
      variables: { fileKey, userId, type: 'ORIGINAL' },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ _, data }) => {
    const { loading, getSignedFileDownload } = data;
    if (getSignedFileDownload) {
      return {
        getSignedFileDownload,
        loading
      };
    }
    return {
      loading
    };
  }
});

export default compose(withData)(ModalImageComponent);
