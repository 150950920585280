import types from '../constants/Nav'

const NAV_THRESHOLD = 275;

const isOn = () => {
  const pos = window.pageYOffset || document.documentElement.scrollTop || 0
  return pos > NAV_THRESHOLD
}

// Default State
const initState = {
  isOn: isOn(),
  path : '',
  institution: '',
  menuOpened: true
}

export default (state = initState, action) => {

  switch (action.type) {
    case types.SCROLL:
      const curr = state.isOn, next = isOn()
      return next === curr ? state : {
        ...state, isOn: next
      }
    case types.SET_TOP_PATH:
      return {
        ...state,
        path : action.path
      }
    case types.SET_BREAD_CRUMB:
      return {
        ...state,
        breadCrumb : action.breadCrumb
      }
    case types.SET_INSTITUTION:
      return {
        ...state,
        institution: action.institution
      }
    case types.RESET_STATE:
      return initState
      case types.CHANGE_MENU_STATS:
      return{
          ...state,
          menuOpened: action.menuOpened
      }
      return
    default:
      return state
  }
}
