import {
    React, _, IHButton, IHLoading, IHTooltip, moment
} from 'ihcomponent'
import { connect } from 'react-redux'
import UserClass from 'modulesAll/graphql/class/User'
import { goPath, RequestCache } from 'libModule/utils'
import PatientStarModal from 'modulesAll/common/containers/PatientStarModal'
import { modalAction } from 'modulesAll/common/actions'
import I18N from 'modulesAll/I18N';
import { browserHistory } from "react-router";
import Client from 'libModule/gqlClient';
import getUserChatInfo from "modulesAll/graphql/getUserChatInfo";
import enrolledProgram from "graphqlModule/enrolledProgram"
import actions from '../../../chat/actions';
import get from 'lodash/get';
import patient from "../../../utils/constants/rolePath/patient";
import * as R from 'ramda';
import './bootstrap_white.css';
import { Menu, Dropdown, Icon, Modal,Tooltip } from 'antd';
import { API } from 'modulesAll/chat/actions/api';
import { DATE_HELPERS } from '../../../../lib/helpers/date-helpers';
import patientAction from '../actions';
import { compose } from 'recompose';
import { trace as traceF, mkDict, addItemsIndex, addItemsCount } from '../containers/utils';
import { graphql } from "react-apollo/index";
import gql from "graphql-tag";
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { TimeSpentComponent } from '../../TimeSpent/components/';
import yearOfDiagnoseMap from '../../../patientList/registerNew/constants/yearOfDiagnose';
import memberDetail from "../../../I18N/en/memberDetail";
import getPatientSmartAlert from 'modulesAll/smartAlert/query/getPatientSmartAlert.js'
import phoneListInfo from 'graphqlModule/phoneListInfo.js';
import getTempEnrolledPatient from 'modulesAll/graphql/getTempEnrolledPatient';
import PatientSmartAlertContainer from 'modulesAll/smartAlert/container/PatientSmartAlertTasksContainer.js';
import mesNotificationType from "../../../chat/constants/messageTypes";
import { COMMON_HELPERS } from 'libModule/helpers/common-helpers';
import NumberFormat from 'react-number-format';
import { clicktoDial } from '../../../call/helper/helpers';
import callActions from '../../../call/actions';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import CreateNewTaskComponent from '../../../taskAssignment/components/CreateNewTaskComponent';
import CreateNewTaskContainer from '../../../taskAssignment/containers/CreateNewTaskContainer';

const debug = false;
const trace = debug ? traceF : () => R.identity;
const dateformatString = I18N.get('dates.L');
const wrapper = class extends React.Component {
    static displayName = 'patient/main/components/MemberDetail'

    constructor(props) {
        super(props);
        this.onAvatarError = this.onAvatarError.bind(this);
        this.ref = React.createRef();

        this.state = {
            phoneNumber: '',
            showModal:false,
            modalData: null,
        }

        this.makeACallError = this.makeACallError.bind(this);
    }

    setModalData = ({showModal,modalData})=>{
        this.setState({
            showModal,
            modalData
        })
    }

    renderSmartAlertTags() {
        const { smartAlertsList, patientId, data, currentProgramId }  = this.props;

        const identification = _.get(data,'user.identification[0].value');
        const fullName = _.get(data,'user.profile.fullName');

        return <PatientSmartAlertContainer smartAlertsList={smartAlertsList}  currentProgramId={currentProgramId}
                                           memberId={patientId} identification={identification} fullName={fullName}/>
    }

    handleUnreadAndLastMsg(messages) {
        const curHistory = messages;
        const historyLength = curHistory.length;
        let lastIndex = historyLength - 1;
        let lastUnreadAckIndex = historyLength - 1;
        let unreadCountFromOffLine = 0;
        let lastMsgText = (() => {
            if (historyLength > 0 && lastIndex >= 0 && lastUnreadAckIndex >= 0) {
                // find index of last text message
                while (!(mesNotificationType[curHistory[lastIndex].entry.type]) &&
                    lastIndex > 0) {
                    lastIndex--;
                }
                while (lastUnreadAckIndex > 0) {
                    const curMsgEntry = curHistory[lastUnreadAckIndex];
                    const isTeam = _.get(curMsgEntry, 'entry.publisher', '').split('-')[0] === 'team';
                    // check for encoded/decoded publishers
                    // also, catch 'push-notification-tester' publisher coming from mobile
                    try {

                        if (typeof COMMON_HELPERS.checkIfEncoded(curMsgEntry.entry.publisher || curMsgEntry.entry.uuid) === 'boolean' &&
                            COMMON_HELPERS.checkIfEncoded(curMsgEntry.entry.publisher || curMsgEntry.entry.uuid)) {
                            //check if current msg is a ack from team breaks
                            if (curMsgEntry.entry.type == 'ACK' && isTeam) {
                                break;
                            }
                        } else {

                            if (curMsgEntry.entry.type == 'ACK' && isTeam) {
                                break;
                            }
                        }

                    } catch (err) {
                        console.log('Err: ', err, ' Publisher on message not valid: ', curMsgEntry.entry || curMsgEntry.entry);
                    }

                    if (mesNotificationType[`${curMsgEntry.entry.type}`]) {
                        unreadCountFromOffLine++;
                    }
                    lastUnreadAckIndex--;
                }

                return lastIndex >= 0 ? curHistory[lastIndex].entry.text : '';
            }
        })()
        const lastMsgTime = historyLength > 0 ? curHistory[lastIndex].timetoken : '';
        const lastMsgSender = historyLength > 0 ? _.get(curHistory[lastIndex], `entry.displayName`, 'Notification') : '';
        const msgObj = {
            unreadCountFromOffLine: unreadCountFromOffLine,
            lastMsgText: lastMsgText,
            lastMsgTime: lastMsgTime,
            lastMsgSender: lastMsgSender

        }
        return msgObj;
    }

    fetchHistory(channel, patientId, lastMsgTimestamp) {
        const channelId = channel;
        const { props } = this;
        return new Promise((resolve, reject) => {
            if (channelId) {
                API.getPubNub().history({
                    channel: channelId,
                    count: 20,
                    start: lastMsgTimestamp, // starting point for fetching messages
                }, (status, response) => {
                    let lastMsgTS;
                    // if there is history, query & add to userMap & add to chat history
                    if (response) {
                        // 'start' option does not always return msgs up to startTimeToken (exclusive) so,
                        // do not add duplicate messages to chat history
                        const messageCount = response.messages.length;
                        if (lastMsgTimestamp === response.endTimeToken) {
                            const lastElem = response.messages.pop();
                        }
                        if (response.messages && response.messages.length > 0) {

                            // if there are messages, return timestamp of last message
                            lastMsgTS = response.startTimeToken;
                            // _.get(_.first(response.messages),'timetoken');
                        }
                        // if start and end time token in response is same, there are no more history, do not add to redux
                        if (response.messages && ((response.startTimeToken !== response.endTimeToken) || (response.startTimeToken == 0 && response.endTimeToken == 0))) {
                            props.addChatHistory(channelId, patientId, response.messages, lastMsgTS, messageCount);
                            resolve(response.messages);
                        } else {

                        }

                        // const channelCount = Object.keys(props.channels);
                        //
                        // if(channelCount.length==0) {
                        //     let msgObj = this.handleUnreadAndLastMsg(response.messages);
                        //     msgObj.isListening = false;
                        //     props.updateChannelInfo(channelId, msgObj);
                        // }else{
                        //
                        //     let msgObj = this.handleUnreadAndLastMsg(props.channels[channelId].history);
                        //     msgObj.isListening = false;
                        //     props.updateChannelInfo(channelId,msgObj);
                        // }
                    }
                });
            }
        })
    }
    render() {
        if (this.props.data.loading) {
            return <div className="vsm-patient-member-detail"><IHLoading /></div>
        }
        const { setModalData } = this;
        const { showStickyHeader, menuOpened, showChat,refetchUser} = this.props;
        const { modalData,showModal } = this.state;
        RequestCache.set('nurse/currentPatient', this.props.data.user);
        const className = "vsm-patient-member-detail" + (showStickyHeader ? ' stickyHeader' : '') + (menuOpened ? ' menuOpened' : '');
        const memberId = _.get(this,'props.data.user.id');
        this.uc = new UserClass(this.props.data.user);
        let style = {};
        if (!menuOpened && !showChat) {
            style = { width: '100%', left: 60, top: 55 };
        }
        else if (!menuOpened && showChat) {
            style = { width: 'calc(75% - 5px)', left: 60, top: 55 };
        }
        else if (menuOpened && showChat) {
            style = { width: 'calc(60% + 60px)', left: 245, top: 55 }
        }
        else if (menuOpened && !showChat) {
            style = { width: '100%', left: 245, top: 55 };
        }
        else if (!menuOpened && showChat) {
            style = { width: '100%', left: 60, top: 55 };
        }
        // !menuOpened&&!showChat ? { width: '100%', left: 60, top:55 } : { width: 'calc(100% - 80px)', right: 10 };
        //updated for IH-1067
        // const userId = this.props.data.user.id;
        const stickyHeader = showStickyHeader ?
            <div className={className} id='sticky_profile_detail' ref={this.ref} style={style}>
                {this.renderAvator()}
                <div>
                    {this.renderName()}
                    {this.renderTags()}
                </div>
                {/*{<PatientStarModal refetch={this.props.data.refetch} />}*/}
            </div> : null;
        const userId = this.props.data && this.props.data.user && this.props.data.user.id;
        const { currentProgramId } = this.props
        return (
            <div className="vsm-patient-member-detail">
                {this.renderAvator(userId)}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {this.renderName()}
                    {this.renderTags()}
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                            <div>
                                {this.renderHealthConditions()}
                            </div>
                            <div className={"profile-keyvalue-box"}>
                                {this.renderYearOfDiagnose()}
                                {this.renderEnrollment()}
                                {this.renderTechLevel()}
                                {this.renderMotivation()}
                                { this.renderPhoneInfo() }
                                {this.renderHomePhones()}
                                {this.renderMobilePhones()}
                            </div>
                            {this.renderSmartAlertTags()}

                            {/*remove enrolled program SC-1446*/}
                            {/*{this.renderCurrentProgramNames()}*/}
                        </div>
                        <div style={{ width: '15%' }}>
                            {this.renderTimeSpend()}
                        </div>
                    </div>
                    {/*{this.renderCurrentProgramNames()}*/}
                    {this.renderPwChange()}
                    {this.renderCareContinuum()}
                </div>

                {this.renderRightButton()}
                { showModal ? <CreateNewTaskContainer setModalData={setModalData} modalData={modalData} refetchUser={refetchUser}
                                                      renderBody={true} memberId={memberId} currentProgramId={currentProgramId}/>:''}
                {stickyHeader}
            </div>
        )
    }
    renderPwChange() {
        //updated for IH-1067
        // const { patientId, data: { user: { hasPassword } } } = this.props;
        const { patientId, data } = this.props;
        const hasPassword = data && data.user && data.user.hasPassword;

        if (hasPassword) {
            return null;
        }
        else {
            return (
                <div className="change-password-patient">
                    <h4 style={{ display: 'flex', justifyContent: 'center' }}>Initial Patient Password Creation</h4>
                    <p style={{ display: 'flex', justifyContent: 'center' }}>The patient will need this password to log into their mobile app.</p>
                    <br />
                    <IHButton bsStyle="primary" label={I18N.get('auth.password.createPw')} style={{ display: 'flex', margin: '0 auto' }} onClick={() => goPath(`patients/${patientId}/new-password`)} />
                </div>
            )
        }
    }
    onAvatarError(userId) {
        API.getUserAvatar(userId)
            .then(res => {
                const userProfile = get(res.data.user, 'profile') || {};
                const currentUserId = atob(get(res.data.user, 'id')).split(':')[1];
                const userRole = res.data.user.allRoles.length > 0 ? res.data.user.allRoles[0].name : '';

                const newUser = {
                    uuid: currentUserId,
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName,
                    nickname: userProfile.nickname,
                    profileType: userRole,
                    avatar: R.isNil(userProfile.avatar) ? '/image/default_avator.png' : userProfile.avatar.link ? userProfile.avatar.link : '/image/default_avator.png'
                };

                this.props.addToUserMap(newUser);
            })
            .catch(err => console.log('onAvatarError, err: ', err));
    }
    renderAvator(userId) {
        return (
            <a href="javascript:void(0)" className="v-avator">
                <img src={this.uc.format('avator')} onError={() => this.onAvatarError(userId)} />
            </a>
        )
    }

    renderPhoneInfo(){
        const { phoneReportInfoList } = this.props;
        const added = phoneReportInfoList ==='added';
        const text =  added ? `Care team number has been added on patient’s phonebook.` :`Please remind patient to add Care team number into phonebook.`;

        return <Tooltip placement="top" title={text} arrowPointAtCenter={true}>
                    <img src={`/image/${added ? 'phoneBook.png' :'phoneBook_dot.png'}`} width={23} style={{marginRight:10,marginBottom:10 }}/>
                </Tooltip>
    }

    renderEnrollment() {
        const user = _.get(this, 'props.data.user');
        let programCategories = _.get(user, 'profile.programCategories', []);
        let categories = [];
        _.forEach(programCategories, (p) => {
            if (p.enrolled) {
                categories.push(p.name);
            }
        });
        if (categories.length > 0) {
            return <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span style={{ fontWeight: 'bold' }}>Enrollment: </span>
                {_.join(categories, ',')}
            </div>
        }
        else {
            return '';
        }
    }

    renderTechLevel() {
        const techLevel = this.uc.format('techLevel');
        if (techLevel) {
            return <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span style={{ fontWeight: 'bold' }}>Tech Level: </span>
                {I18N.get(techLevel)}
            </div>
        } else {
            return '';
        }
    }
    renderMotivation() {
        const motivation = this.uc.format('motivation');
        if (motivation) {
            return <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span style={{ fontWeight: 'bold' }}>Motivation: </span>
                {motivation}
            </div>
        } else {
            return '';
        }
    }

    renderMobilePhones() {
        const mobilePhone = this.uc.format('mobilePhone');
        let time;
        if (this.props.callDate) {
            let date = new Date(this.props.callDate);
            let duration = this.props.callDuration;
            time = <div style={{ width: '150px' }}><b>{`Last Call: `}</b> <br /> {moment(date).format('MM/DD/YY,HH:mm')} <br /> {duration}</div>;
        }
        else {
            time = 'No call'
        }
        let clickable = this.canClicktoDial()
        if (mobilePhone) {
            return <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span><img src={'/image/call-phone.png'} /></span>
                <span style={{ fontWeight: 'bold' }}>Mobile: </span>
                {
                    clickable ?
                        // <IHTooltip title={time} placement="bottom">
                            <span onClick={() => this.callPhone(mobilePhone)} ><NumberFormat className={'keyclick'} format="+# (###) ###-####" value={mobilePhone} displayType={'text'} /> </span>
                        // </IHTooltip>
                        :
                        <span><NumberFormat className={'keyclickWithoutCursor'} format="+# (###) ###-####" value={mobilePhone} displayType={'text'} /> </span>
                }
            </div>
        }
        else {
            return '';
        }
    }

    renderHomePhones() {
        const homePhone = this.uc.format('homePhone');
        let time;
        if (this.props.callDate) {
            let date = new Date(this.props.callDate);
            let duration = this.props.callDuration;
            time = <div style={{ width: '150px' }}><b>{`Last Call: `}</b> <br />  {moment(date).format('MM/DD/YY,HH:mm')} <br /> {duration}</div>;
        }
        else {
            time = 'No call'
        }
        let clickable = this.canClicktoDial()
        if (homePhone) {
            return <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span><img src={'/image/call-phone.png'} /></span>
                <span style={{ fontWeight: 'bold' }}>Home: </span>
                {
                    clickable ?
                        // <IHTooltip title={time} placement="bottom">
                            <span onClick={() => this.callPhone(homePhone)} ><NumberFormat className={'keyclick'} format="+# (###) ###-####" value={homePhone} displayType={'text'} /> </span>
                        // </IHTooltip>
                        :
                        <span><NumberFormat className={'keyclickWithoutCursor'} format="+# (###) ###-####" value={homePhone} displayType={'text'} /> </span>
                }
            </div>
        }
        else {
            return '';
        }
    }


    renderName() {
        const user = this.props.fakeUser;
        return (
            <div>
                <span className="v-name">
                    {this.uc._get('profile.fullNameWithNickname')}
                    <span>({this.uc.format('nric')})</span>
                </span>
                {/* <span className={`v-status v-${user.status}`}>{user.status}</span> */}
                {this.renderA1CTag()}
                {this.renderStar()}
            </div>
        )
    }

    renderStar() {
        const { data } = this.props;
        const patientName = _.get(data,'user.profile.fullName');
        const memberId = _.get(data,'user.id');
        const star = (_.get(data,'user.taskAssignmentCount')!=0);
        if (star) {
            const click = () => {
                Mixpanel.track('clicked', 'task tag', 'patient profile page');
                this.setModalData({ showModal:true,modalData:{ patientName,memberId,inPatientDetail: true }});
            }
            return (
                <IHTooltip overlayClassName="vsm-tooltip" title={star.reason ||'Click to check task list of this Patient'} placement="right">
                    <a onClick={click} className="v-star v-y"><i className="fa fa-star" /> Tasks</a>
                </IHTooltip>
            )
        }
        else {
            const click = () => {
                Mixpanel.track('clicked', 'task tag', 'patient profile page');
                this.setModalData({ showModal:true,modalData:{ patientName,memberId,inPatientDetail: true }});
            }
            return (
                <IHTooltip overlayClassName="vsm-tooltip" title={`Click to add task for this patient`} placement="right">
                    <span onClick={click} className="v-star v-n"><i className="fa fa-star-o" /></span>
                </IHTooltip>
            )
        }
    }

    renderTags() {
        const tags = this.uc.format('tag');
        const mobilePhone = this.uc.format('mobilePhone');
        const homePhone = this.uc.format('homePhone');
        const len = tags.length;
        return (
            <div style={{ paddingBottom: 10, display: 'flex' }}>
                {
                    _.map(tags, (tag, index) => {

                        if(index == 3){
                            return <span className="v-tag" key={index}>Spoken Language: {tag}</span>
                        }else if(index == 4 && typeof(tag) == 'string' ){
                            return <span className="v-tag" key={index}>App Language: {tag}</span>
                        }else{
                            return <span className="v-tag" key={index}>{tag}</span>
                        }
                    })
                }
            </div>
        )
    }

    renderA1CTag() {
        const tempPatient = this.props.tempPatient;

        if (tempPatient) {
            return (
                <span className="v-a1c">{tempPatient.category}</span>
            );
        }
    }

    canClicktoDial() {
        //all clinic
        return true;

        //hardcoded, only San Leandro have click to dial function
        //name: "Family Medical Group of San Leandro"
        var clickable = false;
        const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
        if (viewerInfo.selectedRole.organization.name.includes('San Leandro')
            || viewerInfo.selectedRole.organization.name.includes('Dr Zhang Clinic One')) {
            clickable = true;
        }
        return clickable;
    }

    componentWillMount() {
        this.props.lastCallPatientId(null);
        this.props.setContact(null)
    }

    // status    0:connecting    1:connected    2:ended     3:incoming    4:accepted    5:destroy    6:acw   7:close
    callPhone(phoneNumber) {
        var username = ''
        if (this.uc) {
            username = this.uc._get('profile.lastName') + ' ' + this.uc._get('profile.firstName')
        }
        this.props.callPhone(phoneNumber, username);

        //sync panel status
        this.props.setContact('onConnecting')

        //mark patient id
        const { patientId } = this.props;
        this.props.lastCallPatientId(patientId);
        this.props.lastCallPatientName(username);

        clicktoDial(phoneNumber, this.onConnected, this.onEnded, this.makeACallError);

        Mixpanel.track('clicked', 'phone', 'clickToDial', { "PATIENT_ID": patientId });
    }

    makeACallError(error){
        Modal.error({
            title: "Error",
            content: error.message
        })
        this.props.setContact('onEnded')
    }
    //move to MainLayoutContainer
/*
    onConnected(contactId) {
        // console.log('-------onConnected:', new Date() )
        this.props.updateCallStatus(1, null, null);

        //if lastCallPatientId != null, last call has not finished
        //do not update redux
        const { lastCallPatientIdStatus } = this.props;
        if ( lastCallPatientIdStatus == null) {
            var username = ''
            if (this.uc) {
                username = this.uc._get('profile.lastName') + ' ' + this.uc._get('profile.firstName')
            }
            this.props.lastCallPatientName(username);

            const { patientId } = this.props;
            this.props.lastCallPatientId(patientId);

            const dateFormat = 'MM-DD-YYYY HH:mm';
            var startMoment = moment(new Date(), dateFormat)
            this.props.lastCallStartTime(startMoment);
        }
        const { patientId} = this.props;
        Mixpanel.track('receive', 'phone', 'onConnected', { "PATIENT_ID": patientId, "CONTACT_ID": contactId  });

        console.log('-------onConnected')
        this.props.contact('onConnected')

    }

    onEnded(startDate, endDate, contactId) {
        var today= new Date();
        endDate = today
        //displays "Days from Wed Jan 01 0110 00:00:00 GMT-0500 (Eastern Standard Time)
        //to Tue Dec 27 2011 12:14:02 GMT-0500 (Eastern Standard Time): 694686 days, 12 hours, 14 minutes, and 2 seconds"
        var timeDiff = Date.daysBetween(startDate, today)
        this.props.updateCallStatus(2, timeDiff, startDate);
        const { patientId} = this.props;

        // console.log('-------timeDiff:', timeDiff,'startDate', startDate,'end', endDate, 'patientId', patientId)

        // start date == undefined, call is not connected
        if (startDate != undefined) {

            const dateFormat = 'MM-DD-YYYY HH:mm';
            var endMoment = moment(new Date(), dateFormat)
            this.props.lastCallEndTime(endMoment);

            //pop up call log
            this.props.providerNotesModalIsVisible(true);
        }
        Mixpanel.track('receive', 'phone', 'onEnded', { "PATIENT_ID": patientId, "CONTACT_ID": contactId });

    }
*/
    readHistory() {

    }

    renderTimeSpend() {
        const monthlyTimeSpent = _.get(this, 'props.data.user.monthlyTimeSpent');
        const id = _.get(this, 'props.data.user.id');
        const totalTime = _.get(monthlyTimeSpent, 'totalTime', 0);
        const timeInMinutes = !totalTime ? 0 : Math.round(totalTime / 60);

        return (
            <TimeSpentComponent timeInMinutes={timeInMinutes} userId={id} />
        )
    }

    renderYearOfDiagnose() {
        const { data } = this.props;
        const yearOfDiagnose = _.get(data, 'user.profile.yearOfDiagnose', []) || [];
        const keys = Object.values(yearOfDiagnoseMap);
        const yearOfDiagnoseList = yearOfDiagnose.filter((key) => keys.indexOf(key.condition) != -1);

        return (yearOfDiagnoseList.length > 0 ?
            <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
                <span style={{ fontWeight: 'bold' }}>Year Of Diagnosis: </span>
                {
                    _.map(yearOfDiagnoseList, (tag, index) => {
                        const now = new Date().getFullYear();
                        const { diagnosedDate, condition } = tag;
                        const diagnosedDateTillNow = now - diagnosedDate;
                        const yearString = diagnosedDateTillNow == 0 ? '<1 year'
                            : diagnosedDateTillNow == 1 ? '1 year' : `${diagnosedDateTillNow} years`;
                        return (
                            <span className="v-tag" key={index}>
                                {_.upperFirst(condition.toLowerCase())} ({yearString})
                                </span>
                        )
                    })
                }
            </div> : ''
        )
    }

    renderHealthConditions() {
        const healthConditionsArray = this.uc.format('healthConditions');
        return (
            <div style={{ lineHeight: 2 }} >
                <span style={{ fontWeight: 'bold' }}>Health Condition(s):   </span>
                {
                    healthConditionsArray.length > 0 ?
                        _.map(this.uc.format('healthConditions'), (tag, index) => {
                            return (
                                <span className="v-tag" key={index}>{tag}</span>
                            )
                        }) : I18N.get('keywords.N/A')
                }
            </div>
        )
    }
    /*remove based on SC-1446
    renderCurrentProgramNames(){
      const currentProgramNames = _.get(this.props,"currentProgramNames")||[];

      return (
        <div style={{lineHeight: 2}}>
          <span style={{fontWeight:'bold'}}>Active Enrolled Programs(s):   </span>
          {
            currentProgramNames.length > 0 ?
              currentProgramNames.map( (tag, index)=>{
                return (
                  <span className="v-tag" key={index}>{tag}</span>
                )
              }) : I18N.get('keywords.N/A')
          }
        </div>
      )
    }
    */
    renderMedicine() {
        const healthConditionsArray = this.uc.format('healthConditions');
        const medicine = ['Acarbose (Precose)'];
        return (
            <div style={{ lineHeight: 2 }}>
                <span>Medicine:   </span>
                {
                    medicine.length > 0 ?
                        _.map(medicine, (tag, index) => {
                            return (
                                <span className="v-tag" key={index}>{tag}</span>
                            )
                        }) : I18N.get('keywords.N/A')
                }
            </div>
        )
    }

    renderCareContinuum() {
        const yearRange = [
            // '2017 - 2018',
            '2018 - 2019'
        ];
        const selYear = this.props.year;
        let visitList = this.props.visitList;
        let labRes = this.props.labResultList;
        const dates = Object.keys(labRes);
        if (visitList.length == 0) {
            return (
                <div>
                </div>
            )
        }

        //return if no init visit is scheduled.
        let init = _.filter(visitList, (e) => e.type == 'INIT');


        const initVisitDate = init[0] && init[0].appointmentAt;
        let mainStages = [];
        // let addition = [];
        let timeLength;
        let startDate;
        let endDate;

        // if(initVisitDate) {
        const today = new Date();
        const yesterday = moment(today).subtract(1, 'days');
        const selYearToDate = new Date(initVisitDate);

        let followUp1 = _.filter(visitList, (e) => e.type == 'FOLLOW_UP1');
        let followUp2 = _.filter(visitList, (e) => e.type == 'FOLLOW_UP2');
        let followUp3 = _.filter(visitList, (e) => e.type == 'FOLLOW_UP3');
        let annual = _.filter(visitList, (e) => e.type == 'ANNUAL');
        annual = annual[0] || { appointmentAt: moment(selYearToDate).add(12, 'months'), mocked: true };
        //get each visit or mock up tentative
        init = init[0] || { appointmentAt: moment(new Date()), mocked: true };
        followUp1 = followUp1[0] || { appointmentAt: moment(selYearToDate).add(3, 'months'), mocked: true };
        followUp2 = followUp2[0] || { appointmentAt: moment(selYearToDate).add(6, 'months'), mocked: true };
        followUp3 = followUp3[0] || { appointmentAt: moment(selYearToDate).add(9, 'months'), mocked: true };

        // let annual = _.filter(visitList, (e) => e.type == 'ANNUAL');
        //annual = annual[0] ||  { appointmentAt: moment(selYearToDate).add(12, 'months'),mocked:true };
        let addition = _.filter(visitList, (e) => e.type == 'ADDITIONAL');

        let initHba1c = [];
        let followUp1Hba1c = [];
        let followUp2Hba1c = [];
        let followUp3Hba1c = [];
        let annualHba1c = [];
        let isInRange = (resDate, date, value, unit) => {
            const startDate = (moment(date).subtract(value, unit));
            const endDate = (moment(date).add(value, unit));
            return moment(resDate).isAfter(startDate) && moment(resDate).isBefore(endDate);
        }

        _.each(labRes, (e) => {
            const value = 1;
            const unit = 'month';
            const dateCollected = _.get(e, 'hemoa1c.dateCollected');
            const results = _.get(e, 'hemoa1c.results');
            if (isInRange(dateCollected, init.appointmentAt, value, unit)) {
                _.each(results, (res) => {
                    if (res.name == 'Hemoglobin A1c' && !isNaN(parseFloat(res.value))) {
                        initHba1c.push(parseFloat(res.value));
                    }
                })
            }

            if (isInRange(dateCollected, followUp1.appointmentAt, value, unit)) {
                _.each(results, (res) => {
                    if (res.name == 'Hemoglobin A1c' && !isNaN(parseFloat(res.value))) {
                        followUp1Hba1c.push(parseFloat(res.value));
                    }
                })
            }

            if (isInRange(dateCollected, followUp2.appointmentAt, value, unit)) {
                _.each(results, (res) => {
                    if (res.name == 'Hemoglobin A1c' && !isNaN(parseFloat(res.value))) {
                        followUp2Hba1c.push(parseFloat(res.value));
                    }
                })
            }

            if (isInRange(dateCollected, followUp3.appointmentAt, value, unit)) {
                _.each(results, (res) => {
                    if (res.name == 'Hemoglobin A1c' && !isNaN(parseFloat(res.value))) {
                        followUp3Hba1c.push(parseFloat(res.value));
                    }
                })
            }

            if (isInRange(dateCollected, annual.appointmentAt, value, unit)) {
                _.each(results, (res) => {
                    if (res.name == 'Hemoglobin A1c' && !isNaN(parseInt(res.value))) {
                        annualHba1c.push(parseInt(res.value));
                    }
                })
            }

        })

        const visitArray = [init, followUp1, followUp2, followUp3, annual];
        const labResArray = [initHba1c, followUp1Hba1c, followUp2Hba1c, followUp3Hba1c, annualHba1c];
        const typeArray = ['Initial Visit', '1s Follow Up', '2nd Follow Up', '3rd Follow Up', 'Annual Visit'];
        mainStages = _.map(visitArray, (visit, index) => {
            return {
                name: 'V' + (index + 1),
                t1: typeArray[index],
                v1: visit.appointmentAt ? DATE_HELPERS.formattedDate(visit.appointmentAt, dateformatString) : 'not scheduled',
                s1: visit.mocked ? "Tentative" : (!visit.mocked && visit.checkoutAt ? 'Completed' : (visit.appointmentAt < yesterday ? 'Missed' : 'Upcoming')),
                t2: 'HbA1c',
                v2: labResArray[index][labResArray[index].length - 1] || '--',
                l1: labResArray[index].length
            }

        });

        startDate = initVisitDate;
        endDate = annual.appointmentAt;
        timeLength = endDate - startDate;

        const styles = {
            outline: {
                display: 'flex',
                position: 'relative',
                width: 850,
                // background: 'red',
                justifyContent: 'space-between',
                fontSize: 21,
                color: 'white',
                margin: '43px 5px',
            },
            stagesBar: {
                marginTop: 24,
                marginLeft: 65,
                width: 730, height: 14, backgroundColor: 'rgb(66, 120, 195)', position: 'absolute'
            },
            mainStage: {

                borderTop: 20,
                borderRadius: 20,
                alignItems: "center",
                borderRadius: "61px",
                display: "flex",
                fontWeight: "300",
                height: "61px",
                justifyContent: "center",
                width: "61px",
            },
            interimStage: {
                background: 'pink',
            },
        };

        const additionVisits = _.map(addition, (visit, index) => {
            return {
                name: 'V' + (index + 1),
                t1: 'Additional Visit',
                v1: visit.appointmentAt ? DATE_HELPERS.formattedDate(visit.appointmentAt, dateformatString) : 'not scheduled',
                s1: visit.mocked ? "Tentative" : (!visit.mocked && visit.checkoutAt ? 'Completed' : (visit.appointmentAt < yesterday ? 'Missed' : 'Upcoming')),
            }
        });

        const mkToolTip = ({ t1, v1, s1, t2, v2, l1 }) => {
            let hba1cIndcator;
            if (l1 > 1) {
                hba1cIndcator = "hba1cIndcator";
            }
            return (
                <div style={{ display: 'flex' }}>
                    <div>
                        <div style={{ color: '#888' }} >{t1}</div>
                        <div style={{ fontSize: 16, marginTop: 3, fontWeight: '600' }} >{v1}</div>
                        <div style={{ lineHeight: 1.3 }} >{s1}</div>
                    </div>
                    <div style={{ borderRight: 'solid 1px #c5c5c5', marginTop: 2, marginLeft: 10, marginRight: 10, height: 61 }} />
                    <div>
                        <div style={{ color: '#888' }} className={hba1cIndcator} data={l1}>{t2}</div>
                        <div></div>
                        <div style={{ fontSize: 32 }} >
                            {v2}
                            <sup style={{ fontSize: 14, color: '#888', top: -12, marginLeft: 2 }} >{(t2 === 'HbA1c' ? '%' : '')}</sup>
                        </div>
                    </div>
                </div>)
        }

        const additionToolTip = (index) => {
            const { t1, v1, s1, t2, v2, l1 } = additionVisits[index];
            return (
                <div style={{ display: 'flex' }}>
                    <div>
                        <div style={{ color: '#888' }} >{t1}</div>
                        <div style={{ fontSize: 16, marginTop: 3, fontWeight: '600' }} >{v1}</div>
                        <div style={{ lineHeight: 1.3 }} >{s1}</div>
                    </div>
                    <div>
                        <div style={{ fontSize: 32 }} >
                            {v2}
                        </div>
                    </div>
                </div>)
        }

        const additionVisitIndicators = (visits, timelength) => {
            let visitDiv;
            const width = (new Date() - startDate) / timelength * 730 + 5 || 0;
            if (visits.length > 0 && timelength) {
                visitDiv = _.map(visits, (visit, index) => {
                    const marginLeft = (visit.appointmentAt - startDate) / (timelength) * 730;
                    return (
                        <div key={index}>
                            <Tooltip
                                key={name}
                                placement="top"
                                overlay={additionToolTip(index)}
                                overlayClassName='additionVisitIndicator'
                                // mouseEnterDelay='0.2'
                                // mouseLeaveDelay='0.2'
                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                            >
                                <img src='/image/visit_indicator.svg'
                                    style={{ 'height': '60px', 'marginTop': '-22px', 'position': 'absolute', 'marginLeft': marginLeft + 62 }}
                                />
                            </Tooltip>
                        </div>
                    )
                })
            }
            return (
                <div >
                    <div style={R.merge(styles.stagesBar, { backgroundColor: '#dbebff' })} />
                    <div style={R.merge(styles.stagesBar, { width: width })} />
                    {visitDiv}
                </div>
            )
        }

        const circle = ({ name, ...tooltipDetails }) => {
            const complete = tooltipDetails.s1 === 'Completed';
            const missed = tooltipDetails.s1 === 'Missed';
            const text = <span>Tooltip Text</span>;

            const styles2 = {
                display: 'table-cell',
                height: '60px',
                width: '80px',
                textAlign: 'center',
                background: '#f6f6f6',
                verticalAlign: 'middle',
                border: '5px solid white',
            };

            return (
                <Tooltip
                    key={name}
                    placement="top"
                    overlay={mkToolTip(tooltipDetails)}
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                    <div style={R.merge(styles.mainStage, {
                        backgroundColor: complete ? '#4278c3' : '#dbebff',
                        color: complete ? 'white' : (missed ? 'red' : '#4278c3'),
                    })}>{name}</div>
                </Tooltip>
            )
        };

        const mkMenuItem = (str) => (
            <Menu.Item key={str}>
                <a target="_blank" rel="noopener noreferrer">{str}</a>
            </Menu.Item>
        );

        const onClick = (val) => {
            let setYear = (val.key.split('-')[0]).split(' ')[0];
            this.props.careYearChange(setYear);
        }

        const menu = (
            <Menu onClick={onClick}>
                {R.map(mkMenuItem, yearRange)}
            </Menu>
        );
        return (
            <div>
                <div style={{
                    display: 'flex',
                    fontSize: 18,
                    marginTop: 18,
                    fontWeight: 600,
                }}>
                    <div style={{ fontWeight: 800, marginRight: 20 }} >Continuum of Care</div>
                    <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link">
                            {yearRange[0]} <Icon type="down" />
                        </a>
                    </Dropdown>
                </div>
                <div style={{ marginRight: 50 }}>
                    <div>
                        {additionVisitIndicators(addition, timeLength || 0)}
                        {/*<div style={R.merge(styles.stagesBar, { backgroundColor: '#dbebff' })} />*/}
                        {/*<div style={R.merge(styles.stagesBar, { width: 100 })} />*/}
                        {/*<div style={{ width: 12, height: 12, backgroundColor: '#ee7c81', position: 'absolute', marginTop: 0, marginLeft: 86, transform: 'rotate(45deg)' }}/>*/}
                        {/*<div style={{ width: 3, height: 35, backgroundColor: '#ee7c81', position: 'absolute', marginTop: 3, marginLeft: 90 }} />*/}
                        {/*<div style={{ width: 12, height: 12, backgroundColor: '#ee7c81', position: 'absolute', marginTop: 0, marginLeft: 126, transform: 'rotate(45deg)' }}/>*/}
                        {/*<div style={{ width: 3, height: 35, backgroundColor: '#ee7c81', position: 'absolute', marginTop: 3, marginLeft: 130 }} />*/}
                    </div>
                    <div style={styles.outline}>
                        {
                            R.map(circle, mainStages)
                        }
                    </div>
                </div>
            </div>
        )
    }

    fetchTeamId(currentProgramId) {
        return Client.query({
            query: enrolledProgram,
            variables: { id: currentProgramId }
        })
    }
    renderRightButton() {
        const { dischargedEPList, PNStatus } = this.props;
        const currentProgramId = this.props.currentProgramId;
        const { setSelectedChannel, patientId, verifyAndAddChannel, addToUserMap } = this.props;
        let parsedcurrentProgramId = currentProgramId && btoa(atob(currentProgramId).split(':')[1]);
        const currentProgram = this.props.currentProgram;
        const userProfile = currentProgram && currentProgram.user.profile;
        const shouldDisableStartChartBtn = _.isEmpty(PNStatus);
        let userObj;
        if (userProfile && userProfile.fullName) {
            userObj = {
                uuid: atob(patientId).split(':')[1],
                firstName: userProfile.fullName ? userProfile.fullName.split(' ')[0] : '',
                lastName: userProfile.fullName ? userProfile.fullName.split(' ')[1] : '',
                nickname: userProfile.nickname || '',
                profileType: 'UnknownType',
                gender: _.get(this, 'props.data.user.profile.gender')
            };
        }
        const createChatChannelVars = {
            patientId: patientId,
            enrolledProgramId: parsedcurrentProgramId
        }

        let responseObj;
        const { curPage, handleChangePage } = this.props;
        const onClick = () => {
            // Click patient details on patient profile, property: patient id
            Mixpanel.track('clicked','patient_details','patient_profile',{ PATIENT_ID: patientId });
            Mixpanel.calculateDuration('patient_profile');
            goPath('/patients/profile/' + patientId)
            Mixpanel.time_event('patient_detail')
            handleChangePage('patient_detail')
        }
        return (
            <div>
                {dischargedEPList ? <IHButton
                    onClick={()=> {
                        Mixpanel.track('clicked', 'discharge button', 'patient profile', {PATIENT_ID: patientId}),
                        goPath(`/patients/${patientId}/enrolledprogram/past`)
                    }}
                    label='Discharged History'
                    bsStyle="default" className="v-btn"
                    style={{ right: 65 }} /> :'' }
                <IHButton onClick={onClick.bind(this)} label={I18N.get('memberDetail.patientDetails')}
                          bsStyle="default" className="v-btn" style={{ right: dischargedEPList ? 255: 65 }}/>
            </div>
        )
    }
}

const addlabResultList = graphql(gql`
  query labResultList($filter: FilterFindManyLabResultsInput) {
    labResultMany(filter: $filter) {
      userId
      _id
      viewerId
      templateId
      templateName
      dateCollected
      deleted
      createdAt
      results {
        name
        unit
        value
        flag
        interval
      }
    }
  }
`, {
    options: ({ patientId }) => {
        const parsedPatientId = patientId ? atob(patientId).split(':')[1] : '';

        return {
            variables:
            {
                filter:
                {
                    userId: parsedPatientId,
                    results: [{ name: "Hemoglobin A1c" }],
                    deleted: true
                    //temporarily solution to exclusive deleted labRes
                }
            },
        }
    },
    props: ({ ownProps, data: { loading: labResultListLoading, labResultMany, refetch: labResultListRefetch } }) => {
        let labResultList = {}; // default value
        if (!labResultListLoading) {
            const r0a = R.reject(({ dateCollected, templateName }) => R.isNil(templateName) || R.isNil(dateCollected));
            const r0b = R.compose(R.reverse, R.sortBy(R.prop('dateCollected')));
            const r0 = R.map(R.over(R.lensProp('dateCollected'), dt => dt));
            const r1 = R.groupBy(R.prop('dateCollected'));
            const r2 = R.map(R.compose(R.map(R.mergeAll), R.groupBy(R.prop('templateId'))));
            const r3 = R.compose(trace('r2'), r2, r1, trace('r0'), r0, trace('r0b'), r0b, trace('r0a'), r0a, trace('r0a start'))(labResultMany);
            labResultList = r3;
        };
        return ({
            labResultList,
            labResultListLoading,
            labResultListRefetch,
        })
    },
});

const tempPatient = graphql(getTempEnrolledPatient, {
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        return {
            tempPatient: data.getTempEnrolledPatient
        }
    }
});
const smartAlerts = graphql(getPatientSmartAlert, {
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        return {
            smartAlertsList: _.get(data, 'getPatientSmartAlert.tasks', []),
        }
    }
})
const phoneInfo = graphql(phoneListInfo,{
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        const { loading } = data;
        if(loading) {
            return  {
                loading:loading
            }
        }
        return {
            phoneReportInfoList: _.get(data, 'phoneReportInfoList.data.0.addToContactsStatus', false),
        }
    }
})
const mapPropsToState = (state) => {
    return {
        selectedChannel: state.chat.main.selectedChannel,
        userMap: state.chat.main.userMap,
        visitList: state.visit.main.initVisitList,
        year: state.patient.main.Year,
        PNStatus: state.chat.main.PNStatus,
        lastCallPatientIdStatus: state.AWSConnect.lastCallPatientId,
        contact: state.AWSConnect.contact
    }
}
const mapDispatch = (dispatch) => {
    return {
        setSelectedChannel: channel => dispatch(actions.setSelectedChannel(channel)),
        verifyAndAddChannel: (channel, patientId) => dispatch(actions.verifyAndAddChannel(channel, patientId)),
        addToUserMap: userObj => dispatch(actions.addToUserMap(userObj)),
        careYearChange: sel => dispatch(patientAction.careYearChange(sel)),
        openErrorModal: msg => dispatch(openErrorModal(msg)),
        addChatHistory: (channel, patientId, messages, timestamp, messageCount) => dispatch(actions.addChatHistory(channel, patientId, messages, timestamp, messageCount)),
        updateChannelInfo: (channel, msgObj) => dispatch(actions.updateChannelInfo(channel, msgObj)),
        setContact: flag => dispatch(callActions.contact(flag)),
        providerNotesModalIsVisible: flag => dispatch(callActions.callStatus(flag)),
        lastCallPatientId: flag => dispatch(callActions.lastCallPatientId(flag)),
        lastCallPatientName: flag => dispatch(callActions.lastCallPatientName(flag)),
        lastCallEndTime: flag => dispatch(callActions.lastCallEndTime(flag)),
        lastCallStartTime: flag => dispatch(callActions.lastCallStartTime(flag))
    }
}

export default compose(connect(mapPropsToState, mapDispatch), addlabResultList, smartAlerts, tempPatient, phoneInfo)(wrapper);
