import gql from 'graphql-tag';

export default gql`
    query patientSmartAlertList($filters:PatientSmartAlertOpts, $page:Int, $count:Int){
        patientSmartAlertList(filters:$filters, page:$page, count:$count){
            data{
                id
                createdAt
                taskType
                alertLevel
                status
                providerNote
                display
                replyTime
                closeAt
                closeBy {
                    profile {
                        fullName
                    }
                }
            }
            pageInfo{
                total
                lastPage
            }
        }
    }`;