const VISIT_TYPE_ENUM = {
    'INIT': 'Initial Visit',
    'FOLLOW_UP': 'Follow up Visit',
    // 'FOLLOW_UP1':'1st Follow Up',
    // 'FOLLOW_UP2':'2nd Follow Up',
    // 'FOLLOW_UP3':'3rd Follow Up',
    // 'ANNUAL': 'Annual Visit',
    'ADDITIONAL': 'Additional Visit'
};

export default VISIT_TYPE_ENUM;
