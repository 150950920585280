import moment from 'moment';
import { NUM_OF_ROWS, getRowTemplate, checkHasToday } from '../constant/constants';
import { mappingDevices, processDataSource } from './inventoryContainerHelper';

const loadDataSource = props => {
  const {
    error, loading,
    inventoryReport, getInventory, inventoryTransactionList,
    dataSource, setDataSource, 
    setTotalAmounts,
    forecastAmounts, setForecastAmounts,
    viewOption, setHasTodayRef,
    setCanFetchMore
  } = props;

  if(!loading && !dataSource) {
    // inventoryReport - [{ date, products: {description, productType, totalAdded, totalRemoved} }, ...]
    // inventoryReport has data sorted ascending => reverse array after processed
    // getInventory - { products: [ { productType, quantity }, ...] }
    let unProcessedData = inventoryReport;

    // set totalAmounts
    let totalAmounts = getRowTemplate();
    for(let product of _.get(getInventory, 'products', [])){
      const productTypeName = _.findKey(mappingDevices, value => value === _.get(product, 'productType'));
      _.set(totalAmounts, productTypeName, _.get(product, 'quantity'));
    }
    setTotalAmounts(totalAmounts);
    
    if(!forecastAmounts){
      // need to set ForecastAmounts, set only one time
      let initialForecastAmounts = _.assign({}, getRowTemplate());
      _.map(unProcessedData, iData => {
        const products = _.get(iData, 'products');
        _.map(products, product => {
          const productTypeName = _.findKey(mappingDevices, value => value === _.get(product, 'productType'));
          // calculate forecast amount for each device
          _.set(initialForecastAmounts, productTypeName, _.get(initialForecastAmounts, productTypeName) + _.get(product, 'totalRemoved', 0));
        })
      });
      setForecastAmounts(initialForecastAmounts);
    }

    let initialDataSource = _.reverse(processDataSource(unProcessedData, viewOption));

    const groupNotesByDate = _.groupBy(_.get(inventoryTransactionList, 'data', []), e => moment(e.createdAt).format('MM/DD/YYYY'));
    // add note to dataSource
    initialDataSource = _.map(initialDataSource, data => {
      const hasNotes = _.get(groupNotesByDate, data.date.format('MM/DD/YYYY'));
      if(!hasNotes) return {...data};
      else {
        const notes = _.map(hasNotes, ({ createdBy, note, inventoryHistories }) => ({
          createdBy: _.get(createdBy, 'profile.fullName'),
          note,
          history: _.filter(inventoryHistories, el => _.get(el, 'product.quantity') !== 0),
        }));
        return {...data, notes};
      }
    });

    // check dataSource has today
    setHasTodayRef(_.some(initialDataSource, checkHasToday));


    //error from graphQL, no inventory doc found => no need to fetch more
    if((!error || initialDataSource.length > 0) && viewOption){ 
      // add ghost row for fetchmore effect
      initialDataSource.push({});
      setCanFetchMore(true);
    }
    // setCanFetchMore(needFetchMore);

    setDataSource(initialDataSource);
  }
}

export default loadDataSource;