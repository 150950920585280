import gql from 'graphql-tag';

// InventoryHistoryDateRangeEnum: [DAY, WEEK, MONTH, QUARTER]
// query inventoryReport will call to get total amount in stock and collection of inventory reports
// query 
export const inventoryReportGQL = gql`
  query inventoryReport(
    $fromDate: Date
    $toDate: Date
    $dateRange: InventoryHistoryDateRangeEnum
    $organizationId: EID!
    $groupBy: InventoryReportGroupByEnum
  ) {
    inventoryReport( 
      fromDate: $fromDate
      toDate: $toDate
      dateRange: $dateRange
      organizationId: $organizationId
      groupBy: $groupBy
    ) {
      date
      products {
        description
        productType
        totalAdded
        totalRemoved
      }
    }
    getInventory(
      organizationId: $organizationId
    ) {
      products {
        productType
        quantity
      }
    }
    inventoryTransactionList(
      filters: {
        fromDate: $fromDate
        toDate: $toDate
        organizationId: $organizationId
      }
    ) {
      data {
        note
        createdAt
        createdBy {
          profile {
            fullName
          }
        }
        inventoryHistories {
          product {
            productType
            quantity
            description
          }
        }
      }
    }
  }
`;
