import {
    React
} from 'ihcomponent'
import {createContainer} from '../../../../lib/utils'
import { graphql } from 'react-apollo';
import ViewOnlyMemberDetail from '../components/ViewOnlyMemberDetail';
import userData from '../../../fake/user'
import userGQL from '../../../graphql/user'


const ComponentWithGql = graphql(userGQL, {
    options : (ownProps)=>{
        const id = ownProps.patientId
        return {
            variables : {
                id
            },
            notifyOnNetworkStatusChange: true,
        }
    },
    props:({data})=>{
        const { loading,user,refetch } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            data:{
                user
            },
            refetchUser:refetch
        }
    }
})(ViewOnlyMemberDetail)


const Container = createContainer(ComponentWithGql, (state, ownProps)=>{
    return {
        patientId : ownProps.patientId,
        fakeUser : userData
    }
})

Container.displayName = 'patient/main/containers/ViewOnlyMemberDetail';

export default Container;
