import gql from 'graphql-tag';

export default gql`mutation doEditUser($id:EID!, $role:[Ref], $residency:InputMemberResidency,
  $address:[InputAddress], $phone:[InputPhone], $email:[EmailAddress],
  $userSettings:InputUserSettings, $commonProfile:InputCommonProfileEdit,
  $memberProfile:InputMemberProfile, $employeeProfile:InputEmployeeProfile,$identification:InputIdentification,$username:String) {
  editUser (
    id:$id,
    role:$role,
    residency:$residency,
    address:$address,
    phone:$phone,
    email:$email,
    userSettings:$userSettings,
    commonProfile:$commonProfile,
    memberProfile:$memberProfile,
    employeeProfile:$employeeProfile
    identification: $identification
    username: $username
  ) {
    id
    profile {
      firstName
      lastName
      nickname
      fullName
      fullNameWithNickname
    }
  }
}`
