import moment from 'moment';
import { NUM_OF_ROWS } from '../constant/constants';

// TODO: Check to update type of products
// 20200305 Ben updated per Claire confirmation on currently used types
export const mappingDevices = {
  ease: 'BP',
  align: 'BG',
  lina: 'HS',
  iphone: 'PHONE',
  clear: 'CLEAR',
  lancet: 'LANCET',
  strip: 'STRIP',
  a1c: 'A1C'
};

export const processDataSource = (unProcessedData, viewOption) => _.map(unProcessedData, iData => {
  const products = _.get(iData, 'products');
  let date = moment(iData.date, 'YYYY-M-DD');
  let week, month;
  switch(viewOption) {
    case 'WEEK':
      week = date.week(); // week number
      break;
    case 'MONTH':
      month = date.format('YYYY, MMM');
      break;
  }

  const devices = _.map(products, product => {
    const productTypeName = _.findKey(mappingDevices, value => value === _.get(product, 'productType'));
    return { [productTypeName] : product };
  })

  // populate row data
  return { date, week, month, ..._.assign({}, ...devices) };
});

export const getFromToDateByViewOption = (viewOption, currentDateInMs) => {
  let fromDate;
  switch(viewOption) {
    case 'DAY':
      fromDate = moment(currentDateInMs).subtract(1, 'week').startOf('week').startOf('day').valueOf();
      break;
    case 'WEEK':
      fromDate = moment(currentDateInMs).subtract(NUM_OF_ROWS -1, 'week').startOf('week').startOf('day').valueOf();
      break;
    case 'MONTH':
      fromDate = moment(currentDateInMs).subtract(NUM_OF_ROWS -1, 'month').startOf('month').startOf('day').valueOf();
      break;
    default: // range picker is selected, newViewOption = null
      break;
  };
  return [fromDate, currentDateInMs];
}