import React from 'react';
import { Button,Modal,Input,Form,message,DatePicker,Radio } from 'antd';
import moment from "moment/moment";
import map from '../constants/tableMap';
import helper from '../helper';
import enrolledProgramList from "graphqlModule/enrolledProgramList";
import editTask from '../query/editTask';
import deleteTask from '../query/deleteTaskAssignment';
import { graphql, compose } from 'react-apollo';
import Client from 'libModule/gqlClient';
import { IHLoading } from 'ihcomponent';
const { priorityMap } = map;
const { TextArea } = Input;
const { Item } = Form;
import '../style/index.scss'
import {connect} from "react-redux";
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from '../../timer/actions/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { from } from 'apollo-link';
import API from '../API/index';

const { stopTimer,updateInterventionDetails }= timerActions.default;
const style = {
    rowStyle:{
        display:'flex',
        flexDirection:'column'
    },
    buttonDivStyle:{
        margin:'10px 0px'
    },
    buttonStyle:{

    },
    labelStyle:{
        fontWeight:'bold'
    },
    reasonStyle:{
        paddingBottom: '10px',
        color: '#474747',
        opacity:0.7
    },
    assignerStyle:{
        color:'#555555',
        marginRight: 10,
        fontWeight:'bold'
    },
    resolveStyle:{
        color:'#555555',
        display:'flex',
        justifyContent:'flex-end'
    }

}

let TrackingTaskModalComponent = class extends React.Component {
    static displayName ='TrackingTaskModalComponent';

    constructor(){
        super();
        this.state = {
            note:''
        }

    }

    onChangeInput = (e)=>{
        const note = e.target.value;
        this.setState({
            note
        })
    }


    renderRadioGroup = (disabled)=>{
      return  <Radio.Group disabled={disabled}>
                { _.map(priorityMap,(value,key)=><Radio value={key} key={value}>{value}</Radio>) }
              </Radio.Group>
    }

    submit = ()=>{
        const { form,modalData } = this.props;
        const { id,refetch } = modalData;
        const memberId = _.get(modalData,'member.id');
        const { reason,assignedTo,dueDate,priorityLevel } = form.getFieldsValue();
        Mixpanel.track('clicked', 'submit', 'patient task list popup', {PRIORITY_LEVEL: priorityLevel});
        Client.mutate({
            mutation:editTask,
            variables:{
                id,reason,assignedTo,priorityLevel,
                dueDate:dueDate? moment(dueDate).endOf('day'): null,
            }
        }).then(res=>{
            if(refetch){
                refetch();
            };
            API.fetchTaskUnreadList();
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            const resource = atob(docId).split(':')[0];
            let displayName = this.constructor.displayName;
            const summary = res.data[apiName].id;

            displayName = `${displayName}:${memberId}`;
            this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
            this.props.stopTimer(displayName, 'saveModal');
            message.success('Task Updated');
            this.close();
        })
    }

    deleteTask = ()=>{
        const { modalData,refetch,refetchUser,refetchUserList } = this.props;
        const { id } = modalData;
        // const { priorityLevel } = form.getFieldsValue();
        // Mixpanel.track('clicked', 'delete task', 'patient task list popup', {PRIORITY_LEVEL: priorityLevel});
        return new Promise((resolve,reject)=> {
            Modal.confirm({
                title:  'Delete Task Assignment?',
                onOk: () => {
                    Client.mutate({
                        mutation: deleteTask,
                        variables: {
                            id
                        }
                    }).then(res => {
                        if (refetch) {
                            refetch();
                        }
                        if(refetchUser){
                            refetchUser();
                        }
                        if(refetchUserList){
                            refetchUserList()
                        }
                        API.fetchTaskUnreadList();
                        message.success('Task Deleted');
                        this.close();
                    }).catch(e=>{
                        Modal.error({ content : e.message });
                    })
                },
                okText: 'Delete',
                cancelText: `Cancel`,
                onCancel:()=>{
                    resolve(true);
                }
            })

        })
    }

    renderFooter = (resolvedNote)=>{
        return <div>
                    { !resolvedNote  ? <Button type='primary' style={{ marginRight:10 }} onClick={()=>this.submit()}>Submit</Button> :'' }
                    <Button onClick={ ()=>this.deleteTask() }>Delete Task</Button>
               </div>
    }

    close = ()=>{
        const { setModalData } = this.props;
        setModalData({showModal:false,modalData:null});
    }
    getFormProps = ()=>{
        const { getFieldDecorator } = this.props.form;
        const { modalData,renderBody,team,loading,enrolledPrograms } = this.props;
        const { reason,priorityLevel,assignedTo,dueDate,member,resolvedNote } = modalData||{};
        const  patientName  = _.get(member,'profile.fullName');
        const mrn = _.get(member,'identification.0.value');
        const assigneeId = _.get(assignedTo,'id');
        if(loading) return <IHLoading/>
        const resolvedNoteBy = _.get(resolvedNote,'resolvedBy.profile.fullName');
        const resolvedAt = _.get(resolvedNote,'resolvedAt');
        const resolvedNoteContent = _.get(resolvedNote,'note','');
        const resolvedNoteBody = <div>
                                    <div style={style.assignerStyle}>Resolved Note:
                                        <div style={style.reasonStyle}>{resolvedNoteContent}</div>
                                    </div>
                                    <div style={style.resolveStyle}>
                                        Resolved By:<div style={style.reasonStyle}>{resolvedNoteBy}</div>
                                        <div style={Object.assign({marginLeft:20},style.reasonStyle)}>{moment(resolvedAt).format('MM/DD/YYYY hh:mm')}</div>
                                    </div>
                                </div>
        const teamsFromLoginUser = JSON.parse(sessionStorage.getItem('currentUser')).team;

        return <div style={{ padding:10 }} >
                    <Form className={`trackingTaskModal ${renderBody ? 'inPatientProfile':''}`} >
                        <div className='firstDiv'>
                            <Item key='reason'>
                                <strong style={style.labelStyle}>{ renderBody? 'Reason' :`${patientName} (${mrn})` }</strong>
                                {getFieldDecorator('reason', { initialValue: reason })(<TextArea rows={6} disabled={resolvedNote}/>)}
                            </Item>
                        </div>
                        <div style={{display:'flex'}} className='secondDiv'>
                            <div className='assignAndDueDayDiv'>
                                <Item key='assignedTo' label='Assign To'>
                                    {getFieldDecorator('assignedTo',{ initialValue: assigneeId || null})(helper.assignedToDropDown(enrolledPrograms,(!team||team.length==0) ? teamsFromLoginUser : team,resolvedNote))}
                                </Item>
                                <Item key='dueDate' label="Due Date">
                                    {getFieldDecorator('dueDate',{ initialValue: dueDate ? moment(dueDate) : null})(<DatePicker format='MM/DD/YYYY' disabled={resolvedNote}/>)}
                                </Item>
                            </div>
                            <Item label='Priority Level'>
                                {getFieldDecorator('priorityLevel',{ initialValue: priorityLevel || null})(this.renderRadioGroup(resolvedNote))}
                            </Item>
                        </div>
                    </Form>
                    { resolvedNote ? resolvedNoteBody :'' }
                    { this.renderFooter(resolvedNote) }
               </div>

    }
    renderTimer(){
        const { modalData } = this.props;
        let displayName = this.constructor.displayName;
        const memberId = _.get(modalData,'member.id');
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const keepEventListener = true;
        return <TimerContainer displayName={displayName} category='TASK_ASSIGNMENT' action='UPDATE'
                               viewingDetails={viewingDetails}
                               patientId={memberId}
                               keepEventListener={keepEventListener}
        />
    }

    render(){
        const { props } = this;
        const { setModalData,renderBody,loading } = props;
        return <div>
                    { this.renderTimer()}
                    {
                        !renderBody ? <Modal visible={true} footer={null} maskClosable={false} onCancel={() => this.close()}
                                         destroyOnClose={true}>
                        {this.getFormProps()}
                        </Modal> : <div>{this.getFormProps()}</div>
                    }
                </div>
    }


}
const team = graphql(enrolledProgramList,{
    options:(props)=>{
        const { member,memberId } = props.modalData
        return{
            variables:{
                filters: {
                    memberId:memberId||member.id
                }
            }
        }
    },
    props:({data})=>{
        const { enrolledProgramList,loading } = data;
        if(loading) {
            return {
                loading
            };
        }
        const startedEP = _.filter(_.get(enrolledProgramList,'data',[]),(ep)=>ep.status==='STARTED');
        const team  = _.get(startedEP,'0.team',[]);
        return {
            team
        }
    }

})
const mapToProps = (state,props)=>{
    const uuid = _.get(state,'timer.main.uuid',null);
    return {
        uuid
    }
};
const mapToDispatch = (dispatch)=>{
    return {
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
};

TrackingTaskModalComponent = Form.create()(TrackingTaskModalComponent);
export default compose(team,connect(mapToProps,mapToDispatch))(TrackingTaskModalComponent);
