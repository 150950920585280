// Imports
import {_} from 'ihcomponent'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import chatActions from '../../chat/actions';

import { handleScroll, setInstitution, resetState } from '../../layout/actions/Nav'

// Modules
import Nav from '../components/NavComponent'

class Container extends Component {
  static displayName = 'NavContainer'

  componentWillMount() {
    //window.addEventListener('scroll', this.props.onScroll);
  }
  componentWillUnmount() {
    //window.removeEventListener('scroll', this.props.onScroll);
  }
  render() {
    return <Nav {...this.props} />
  }
}

const mapState = (state, ownProps) => {
    let path = state.Nav.path
    if(!path){
      path = _.map(ownProps.curPath.replace(/^\//, '').split('/'), (l)=>_.upperFirst(l)).join(' / ')
    }
    return {
        userMap: state.chat.main.userMap,
        path,
        breadCrumb: state.Nav.breadCrumb,
        institution: state.Nav.institution,
        OrgHasUnread: state.chat.main.OrgHasUnread,
        orgTeamMap: state.chat.main.orgTeamMap
    }
}
const mapDispatch = (dispatch) => ({
    dispatch,
    addToUserMap: user => dispatch(chatActions.addToUserMap(user)),
    onScroll: () => dispatch(handleScroll()),
    setInstitution: (institution) => dispatch(setInstitution(institution)),
    resetState: () => dispatch(resetState())
})

export default connect(
  mapState,
  mapDispatch
)(Container);
