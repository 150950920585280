
import React from "react";
import { Form,Icon,Col,Radio,Row,Button,Select,Checkbox } from 'antd';
import I18N from "../../../I18N";
import '../styles/index.scss';

const Item = Form.Item;
const FORMLABELS = I18N.get('patientlist.manualInputForm');

const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;

const renderEditButton=(localThis)=>{
    const { disabled } = localThis.state;
    const buttonText = disabled ? 'Enable Update' :'Disable Update';
    return <Button onClick={()=>localThis.setState({disabled:!localThis.state.disabled})}>{buttonText}</Button>
}


const formatFieldLabels = (schemaKey, i18key) => {
    return _.get(FORMLABELS,i18key);
}

const renderForm = (localThis,config)=>{
    const { getFieldDecorator,loading } = localThis.props.form;
    const { user } = localThis.props;
    return(
            <Form layout='vertical' className='additionInfoContentForm'>
                {
                    _.map(config,(arrayItem,index)=> {
                        return(
                            <Row key={index}>
                                {
                                    arrayItem.map(({key, className, rules, label, path, inputContent, wrapperCol, colStyle, offset, rowStyle, col, labelCol, required, formatValue, format, span, style, placeHolder, onChange,valuePropName}, index) => {
                                    let value = path ? (typeof path =='string') ? _.get(user, path, '') : _.get(user,path(localThis),''):'';
                                    if(valuePropName === 'checked' && typeof value === 'string') value = false; 
                                    const {form} = localThis.props;
                                    className = className ? className : '';
                                    return <Col span={span} key={index} style={colStyle} offset={offset}>
                                        <Row justify={'start'} style={rowStyle}>
                                            {
                                                placeHolder ? <Col span={span}>{placeHolder}</Col> :
                                                    <Item layout={'vertical'} key={key} label={label}
                                                          wrapperCol={wrapperCol}
                                                          col={col}
                                                          labelCol={labelCol}
                                                          style={style}>
                                                        {
                                                            getFieldDecorator(key, {
                                                                rules: typeof rules == 'function' ? rules(form,localThis) : rules,
                                                                initialValue: typeof formatValue == 'function' ? formatValue(value, format) : value,
                                                                valuePropName:valuePropName || 'value'
                                                            })(inputContent({
                                                                className,
                                                                format,
                                                                value,
                                                                onChange,
                                                                localThis,
                                                                key,
                                                                form
                                                            }, user, localThis))
                                                        }
                                                    </Item>
                                            }
                                        </Row>
                                    </Col>
                                })
                                }
                          </Row>
                        )
                    })
                }
                {
                    localThis.props.renderPicker ? localThis.renderHealthConditionPicker():''}
                {
                    localThis.props.renderPicker ? localThis.renderHealthConditionTable() :''}
            </Form>
    )
}

const loadingContainer = {
    height: 200,
    width: '100%',
    fontSize: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

const renderLoading= () =>{
    return <div className='loadingContainer' style={loadingContainer}>
        <Icon type={'loading'} />
    </div>
}
const getMemberProfile = (user,fieldsArray)=>{

    let memberProfile = _.pick(user,fieldsArray);
    const { language,maritalStatus,DOB,gender,
        occupation,economicStatus,race,firstName,lastName,nickname,email,
        streetNumber,streetName,state,city,country,postCode,yearOfDiagnose
    } = memberProfile;


    {
        memberProfile.language = _.get(language,'code','');
        if(maritalStatus){
            memberProfile.maritalStatus = _.get(maritalStatus,'code','');
        }else{
            memberProfile = _.omit(memberProfile,'maritalStatus');
        }
        if(race) {
            memberProfile.race = _.get(race, 'code', '');
        }else{
            memberProfile =_.omit(memberProfile,'race');
        }

    }

    return memberProfile;
}
const renderRadio = (options,props)=> <Radio.Group disabled={props.disabled}>
                                            <Row>
                                            {
                                                _.map(options, ({value, label},index) =>
                                                        <Col span={12} key={index}>
                                                            <Radio value={value} key={index}>{label}</Radio>
                                                        </Col>)
                                            }
                                            </Row>
                                      </Radio.Group>

const renderRadioWithExtraContent = (options,props,content)=> <Radio.Group disabled={props.disabled}>
                                                        <Row>
                                                            {
                                                                _.map(options, ({value, label},index) =>
                                                                    <Col span={12} key={index}>
                                                                        <Radio value={value} key={index}>{label}</Radio>
                                                                    </Col>)
                                                            }
                                                            <Col span={12}>{content}</Col>
                                                        </Row>

                                                    </Radio.Group>

const renderRadioOfDietHabits = (options,props,content)=> <Radio.Group disabled={props.disabled}>
                                                        <Row>
                                                            {
                                                                _.map(options, ({value, label},index) =>{
                                                                    if(index==options.length-1){
                                                                        return <Col span={3} key={index}>
                                                                            <Radio value={value}
                                                                                   key={index}>{label}</Radio>
                                                                        </Col>
                                                                    }
                                                                   else {
                                                                        return <Col span={12} key={index}>
                                                                            <Radio value={value}
                                                                                   key={index}>{label}</Radio>
                                                                        </Col>
                                                                    }
                                                                })
                                                            }
                                                            <Col span={9}>{content}</Col>
                                                        </Row>
                                                    </Radio.Group>
const renderDropDown = (options,props)=> <Select showSearch
                                                    disabled={props.disabled}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                _.map(options, ({value, name},index) => <Option value={value} key={index}>{name}</Option>)
                                            }
                                           </Select>


const renderRadioButton=(options,props)=> <Radio.Group disabled={props.disabled}
                                                       onChange={(e)=>props.onChange&&props.onChange(e.target.value,props.localThis,e)}>
                                            {
                                                _.map(options, ({value, name},index) =>
                                                    <Radio.Button value={value} key={index}>{name}</Radio.Button>)
                                            }
                                        </Radio.Group>


const renderCheckBoxGroup = (options,props)=><CheckboxGroup disabled={props.disabled}
                                                            onChange={(val)=> props.onChange&&props.onChange(val,props.key,props.localThis)}>
                                                <Row>
                                                {
                                                    options.map((option,index) => <Col span={12} key={index}><Checkbox value={option.value} key={index} checked={option.checked}>{option.value}</Checkbox></Col>
                                                )}
                                                </Row>
                                            </CheckboxGroup>
const renderCheckBoxGroupWithNoneSel = (options,props)=>{

   return <CheckboxGroup disabled={props.disabled}
                   onChange={(val)=> props.onChange&&props.onChange(val,props.key,props.localThis)}>
        {options.map((option,index) =>
                <Checkbox value={option.value} key={index} checked={option.checked}>{option.value}</Checkbox>
        )}
    </CheckboxGroup>
}

export {
    renderEditButton,
    renderForm,
    renderRadio,
    renderDropDown,
    formatFieldLabels,
    renderCheckBoxGroup,
    renderRadioButton,
    getMemberProfile,
    renderRadioWithExtraContent,
    renderCheckBoxGroupWithNoneSel,
    renderLoading,
    renderRadioOfDietHabits
}
