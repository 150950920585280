import React from 'react';
import LeftNav from '../components/LeftNavComponent';
import { connect } from 'react-redux';
import { decryptRole } from 'libModule/utils';
import { MENUS } from 'modulesAll/utils/constants/menuPath'

const logoUrl = window.VSM_CONFIG.DEMO ? '/logos/sharecare-logo-small.svg' : '/image/sharecare-logo-small.svg';
    // '/logos/logo-simple-white.png' : '/image/vsm/logo-simple-white.png'
const TestData = {
  logo: {
    href: '/',
    src: logoUrl,
  },
  menu: [],
};

const Container = (props) => {
  const { dispatch, curPath, orgTeamMap, institution, curPage, handleChangePage } = props;
  const currentUserRole = decryptRole();
  TestData.menu = MENUS[currentUserRole];
  const current = curPath.split('/')[1];

  return <LeftNav {...TestData} current={current} dispatch={dispatch} orgTeamMap={props.orgTeamMap} institution={props.institution}
                                curPage={curPage} handleChangePage={handleChangePage} />;
};

Container.displayName = 'LeftNavContainer';

const mapState = (state, ownProps) => ({
  institution: state.Nav.institution,
  orgTeamMap: state.chat.main.orgTeamMap
});

const mapDispatch = dispatch => ({
  dispatch,
});
export default connect(
    mapState, mapDispatch,
)(Container);
