import get from 'lodash/get'
import { hasElement } from 'modulesAll/utils/helpers/task'
import {
  MISSED_TYPE_VALUE,
  THRESHOLD_TYPE_VALUE,
  ALERT_TRIGGERS_OPTIONS,
  SEND_TO_OPTIONS,
  SEND_VIA_OPTIONS,
  DEFAULT_ALERT_MISSED_READING,
  ALERT_MESSAGE_MAP,
  DEFAULT_ALERT_MESSAGE
} from 'modulesAll/utils/constants/task'

const MISSED_TYPE = {
  name: 'Missed Reading',
  value: MISSED_TYPE_VALUE
}
const THRESHOLD_TYPE = {
  name: 'Threshold',
  value: THRESHOLD_TYPE_VALUE
}

export const schema = {
  alertType: {
    initialValue: '',
    required: true
  },
  // for Score alert type
  trigger: {
    initialValue: '',
    required: true
  },
  // for missed reading alert type
  chance: {
    initialValue: DEFAULT_ALERT_MISSED_READING,
    required: true
  },
  // notification type: ['SMS', 'EMAIL']
  type: {
    initialValue: [],
    required: true
  },
  person: {
    initialValue: [],
    required: true
  },
  messageForCareTeam: {
    initialValue: DEFAULT_ALERT_MESSAGE,
    required: false
  },
  messageForPatient: {
    initialValue: DEFAULT_ALERT_MESSAGE,
    required: false
  },
}

const itemLayout = {
  labelCol : {span : 24},
  wrapperCol : {span : 24}
}

const renderTriggerAfterTimes = (fieldsValue, onFieldsChange) => {
  const alertType = get(fieldsValue, 'alertType.value')
  const trigger = get(fieldsValue, 'trigger.value')
  const chance = get(fieldsValue, 'chance.value')
  let result = ''
  const thresholdElem = [
    {
      key: 'trigger',
      label: 'Include Alerts for',
      type: 'checkboxButtonGroup',
      checkType: 'checkbox',
      ...itemLayout,
      placeholder: '',
      initialValue: trigger ? trigger : schema['trigger'].initialValue,
      col: { span: 20, offset: 4 },
      option: ALERT_TRIGGERS_OPTIONS,
      onFieldsChange
    },
  ]
  const missedReadingElem = [
    {
      key: 'chance',
      label: 'Trigger after',
      type: 'inputNumber',
      ...itemLayout,
      placeholder: '',
      initialValue: chance ? chance : schema['chance'].initialValue,
      col: { span: 5, offset: 4 },
      className: 'vsm-form-input-number'
    },
    {
      key: 'chancesText',
      label: 'consecutive missed readings',
      type: 'label',
      col: { span: 15, offset: 0 },
      className: 'vsm-form-text-small'
    }
  ]
  if (alertType === THRESHOLD_TYPE.value) {
    result = thresholdElem
  } else if (alertType === MISSED_TYPE.value) {
    result = missedReadingElem
  }
  return result
}

const renderPerson = (person, onFieldsChange) => {
  const result = [
    {
      key: 'person',
      label: 'Send to',
      type: 'checkboxButtonGroup',
      checkType: 'checkbox',
      ...itemLayout,
      placeholder: '',
      initialValue: person ? person : schema['person'].initialValue,
      col: { span: 20, offset: 4 },
      option: SEND_TO_OPTIONS,
      onFieldsChange,
    }
  ]
  return result
}

const renderMessageTemplate = (person, alertType) => {
  let result = []
  const messageForCareTeam = [
    {
      key: 'messageForCareTeam',
      label: 'Message for Care Team(s)',
      type: 'label',
      col: { span: 20, offset: 4 },
      style: { textAlign: 'left' },
      className: 'label-title alert-label-title'
    },
    {
      key: 'messageForCareTeamPreview',
      label: ALERT_MESSAGE_MAP[alertType],
      type: 'label',
      col: { span: 20, offset: 4 },
      className: 'vsm-msg-preview'
    }
  ]
  const messageForPatient = [
    {
      key: 'messageForPatient',
      label: 'Message for Patient',
      type: 'label',
      col: { span: 20, offset: 4 },
      style: { textAlign: 'left' },
      className: 'label-title alert-label-title'
    },
    {
      key: 'messageForPatientPreview',
      label: ALERT_MESSAGE_MAP[alertType],
      type: 'label',
      col: { span: 20, offset: 4 },
      className: 'vsm-msg-preview'
    },
  ]
  if (hasElement(person, 'CARE_TEAM')) {
    result = messageForCareTeam
  }
  if (hasElement(person, 'MEMBER')) {
    result = result.length === 0 ? messageForPatient : result.concat(messageForPatient)
  }
  return result.length === 0 ? undefined : result
}

const renderSendVia = (fieldsValue, onFieldsChange) => {
  // const person = get(fieldsValue, 'person.value')
  const type = get(fieldsValue, 'type.value')
  // if (!person || person.length === 0 || (person.length === 1 && person[0] === 'DASHBOARD')) return []
  return [
    {
      key: 'type',
      label: 'Send via',
      type: 'checkboxButtonGroup',
      checkType: 'checkbox',
      ...itemLayout,
      placeholder: '',
      initialValue: type ? type : schema['type'].initialValue,
      col: { span: 20, offset: 4 },
      option: SEND_VIA_OPTIONS,
      onFieldsChange,
    }
  ]
}

export const getItemList = ({ onFieldsChange, fieldsValue }) => {
  const alertType = get(fieldsValue, 'alertType.value')
  const person = get(fieldsValue, 'person.value')
  const itemList = [
    [
      {
        key: 'alertDetails',
        label: 'Alert Details',
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'alertType',
        type: 'radioButton',
        label: 'Alert Type',
        onFieldsChange,
        ...itemLayout,
        placeholder: '',
        initialValue: alertType ? alertType : schema['alertType'].initialValue,
        col: { span: 20, offset: 0 },
        option: [ THRESHOLD_TYPE, MISSED_TYPE ]
      }
    ],
    renderTriggerAfterTimes(fieldsValue, onFieldsChange),
    // renderPerson(person, onFieldsChange),
    renderMessageTemplate(person, alertType),
    // renderSendVia(fieldsValue, onFieldsChange),
  ]
  return itemList
}
