import React, { Component } from 'react';
import '../css/index.scss';
import CallComponent from '../components/callComponent';


class CallContainer extends Component {
    constructor() {
        super();
        this.state = {
            isMinCall: false
        }
    }

    /**
     * callArgs
     * {
     *      phoneNumber
     *      username
     *      status    0:connecting    1:connected    2:ended     3:incoming    4:accepted    5:destroy    6:acw
     *      duration   min
     *      date
     *      showChat
     * }
     *  //7 is close the panel; 2 is show close icon
     */
    render() {
        const callArgs = this.props.callArgs;
        const phoneNumber = callArgs.phoneNumber;
        const username = callArgs.username;
        const duration = callArgs.duration;
        const showChat = callArgs.showChat;
        const status = callArgs.status;
        const date = callArgs.date;
        let isShowCall = true;
        const isMinCall = this.state.isMinCall;

        if (status == 7) {
            isShowCall = false;
        }

        return <div className={`callBox ${isMinCall ? 'minCall' : ''} ${isShowCall?'':'closeCall'} ${showChat ? 'showChat' : 'closeChat'}`}>
            <CallComponent close={() => { this.props.close() }} maxCall={() => { this.maxCall() }} minCall={() => { this.minCall() }} phoneNumber={phoneNumber} username={username} duration={duration} status={status} date={date} />
        </div>
    }

    minCall() {
        this.setState({ isMinCall: true })
    }

    maxCall() {
        this.setState({ isMinCall: false })
    }


}
export default CallContainer
