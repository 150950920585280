import {Modal} from 'antd';
import React, {Component} from 'react';
import SearchInputComponent from "../components/SearchInputComponent";
import InputVisitFormComponent from "../components/InputVisitFormComponent";
import CreatePatientFormComponent from "../components/CreatePatientFormComponent";
import '../CSS/style.scss';
import {message} from "../../../../../package/IHComponent";
import {connect} from "react-redux";
import { GQLHelper } from 'lib/utils';
import CURRENT_COMPONENT from "../constants/CurrentComponent";
import { helpers as dataHelper } from "../helper";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { API } from '../../visit/query/index';

class CreateVisitInDashboardContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: CURRENT_COMPONENT.SEARCH_COMPONENT,
            patient: null,
            visitInfo: null,
        };
        this.changeToInputVisit = this.changeToInputVisit.bind(this);
        this.changeToSearch = this.changeToSearch.bind(this);
        this.changeToCreatePatient = this.changeToCreatePatient.bind(this);
        this.createPatient = this.createPatient.bind(this);
        this.createVisit = this.createVisit.bind(this);
    }

    changeToInputVisit(patient){
            this.setState({
                value: CURRENT_COMPONENT.INPUT_VISIT_COMPONENT,
                patient
            });
    }

    changeToSearch(){
        this.setState({
            value: CURRENT_COMPONENT.SEARCH_COMPONENT,
            patient: null
        });
    }

    changeToCreatePatient(){
        this.setState({
            value: CURRENT_COMPONENT.CREATE_PATIENT_COMPONENT,
            patient: null
        });
        Mixpanel.track('clicked', 'Create a new patient', 'on create a visit popup', {});
    }

    createPatient(values){
        const mutatedValue = dataHelper.getMutateVariable(values);
        const variables = dataHelper.trimAllValues(mutatedValue,'',mutatedValue);
            API.createPatient(variables)
            .then(res => {
                let patient = {};
                const user = res.data.createUser;
                user.profile.birthday = values.DOB;
                user.profile.gender = values.gender;
                patient.enrolledPrograms = [];
                patient.user = user;

                this.setState({
                    value: CURRENT_COMPONENT.INPUT_VISIT_COMPONENT,
                    patient
                });
            })
            .catch(e => {
                this.props.openErrorModal(GQLHelper.formatError(e));
            })
    }

    createVisit(variables){
        API.createVisit(variables)
            .then(res => {
                message.success('Appointment Scheduled');
                // console.log(res);
                this.props.onCancel(true);
            })
            .catch(error => {
                this.props.openErrorModal(GQLHelper.formatError(error));
            })
    }

    renderSelectComponent(){

        if(this.state.value === CURRENT_COMPONENT.SEARCH_COMPONENT){
            return (<SearchInputComponent selectPatient={this.changeToInputVisit}
                                          onCreate={this.changeToCreatePatient}
                   />)
        }
        if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT){
            return (<InputVisitFormComponent patient={this.state.patient}
                                             backToSearch={this.changeToSearch}
                                             createVisit={this.createVisit}
                                             editStatus={false}
                                             curTab={this.props.curTab}
                    />)
        }
        if(this.state.value === CURRENT_COMPONENT.CREATE_PATIENT_COMPONENT){
            return (<CreatePatientFormComponent onCreatePatient={this.createPatient} />)
        }
    }

    getTitle(){
        if(this.state.value === CURRENT_COMPONENT.CREATE_PATIENT_COMPONENT){
            return "Create a Patient"
        } else {
            return "Create a visit for"
        }
    }

    render() {
        Mixpanel.track('showed', 'create a visit popup', '', {});
        return (
            <Modal width={550}
                   title={this.getTitle()}
                   visible={true}
                   footer={null}
                   onCancel={this.props.onCancel}
                   maskClosable={false}
            >
                {this.renderSelectComponent()}
            </Modal>
        );
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
    }
}

export default connect(null,mapToDispatch)(CreateVisitInDashboardContainer);
