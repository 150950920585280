import React from 'react';
import OnBoardComponent from '../component/OnBoardComponent';
import {compose, graphql} from "react-apollo/index";
import getLatestA1C from "../../graphql/getLatestA1C";
const OnBoardContainer = class extends React.Component{
    render(){
        const { props } = this;
        return <OnBoardComponent {...props}/>
    }
}


const withData = graphql(getLatestA1C,{
    options:(ownProps)=>{
        const { userId } = ownProps;
        return{
            variables: { memberId: userId },
            fetchPolicy: 'network-only'
        }
    },
    props:({ data })=>{
        const { loading,getLatestA1C } = data;
        if(loading){
            return{
                loading
            }
        }
        return {
            getLatestA1C
        }
    }
})
export  default compose(withData)(OnBoardContainer);
