const BGMeasureTypeMap = {
    'ONE_MONTH':{
        title:'1-Month Blood Sugar Data',
        path:'/image/1-month-icon.svg',
        logoPath:'/image/blood-drop-icon-2tone.svg'
    },
    'TWO_WEEK':{
        title:'2-Week Blood Sugar Data',
        path:'/image/2-weeks-icon.svg',
        logoPath:'/image/blood-drop-icon-2tone.svg'
    },
    'measurement':{
        title:'Single Blood Sugar Data.'
    },
}

const BPMeasureTypeMap = {
    'ONE_MONTH':{
        title:'1-Month Blood Pressure Data',
        path:'/image/1-month-icon.svg',
        logoPath:'/image/heart-icon-2tone.svg'


    },
    'TWO_WEEK':{
        title:'2-Week Blood Pressure Data',
        path:'/image/2-weeks-icon.svg',
        logoPath:'/image/heart-icon-2tone.svg'

    },
    'measurement':{
        title:'Single Blood Pressure Data.'
    }
}

const BGSeverityMap = {
    "CriticallyLow":{
        text:'CRITICAL',
        color:'rgb(255,100,100)'
    },
    'CriticallyHigh':{
        text:'CRITICAL',
        color:'rgb(255,100,100)'
    },
    'Normal':{
        text:'NORMAL',
        color:'rgb(54,204,141)'
    },
    'High':{
        text:'HIGH',
        color:'rgb(253,181,67)'
    },
    'Low':{
        text:'LOW',
        color:'rgb(253,181,67)'
    }
}
const BPSeverityMap = {
    "Hypertension (Stage 2)":{
        text:'HTN2',
        color:'rgb(239,69,69)'
    },
    "Hypertension (Stage 1)":{
        text:'HTN1',
        color:'rgb(255,134,46)'
    },
    'HYPERTENSION (2)':{
        text:'HTN2',
        color:'rgb(239,69,69)'
    },
    'HYPERTENSION (1)':{
        text:'HTN1',
        color:'rgb(255,134,46)'
    },
    'Hypertension Stage 1':{
        text:'HTN1',
        color:'rgb(255,134,46)'
    },
    'Hypertension Stage 2':{
        text:'HTN2',
        color:'rgb(239,69,69)'
    },
    'ELEVATED':{
        text:'ELEVATED',
        color:'rgb(255,181,67)'
    },
    'Elevated':{
        text:'ELEVATED',
        color:'rgb(255,181,67)'
    },
    'HYPERTENSIVE CRISIS':{
        text:'HIGH',
        color:'rgb(253,181,67)'
    },
    'Hypertensive Crisis':{
        text:'HIGH',
        color:'rgb(253,181,67)'
    },
    'NORMAL':{
        text:'Normal',
        color:'rgb(105,211,118)'
    },
    'Normal':{
        text:'Normal',
        color:'rgb(105,211,118)'
    }
}
const style = {
    container: {
        padding: '10px 20px',
        backgroundColor: 'white',
        border: 'solid 1px rgb(80,176,231)',
        borderRadius:'8px'
    },
    date:{
        fontWeight:'bold'
    },
    header:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        minWidth:'60px'
    },
    rangedHeader:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        minWidth:'60px'
    },
    rangedHeaderSub:{
        display:'flex',
        flexDirection:'column',
        marginLeft:15
    },
    message:{
        display:'flex',
        flexDirection:'row',
        paddingTop:'10px',
    },
    divider1:{
        margin:'0',
    },
    divider2:{
        height:'inherit'
    },
    textStyle:{
        width:'70%',
    },

}

export default {
    BGMeasureTypeMap,BGSeverityMap,style,BPMeasureTypeMap,BPSeverityMap
}
