import React from 'react';
import {graphql} from "react-apollo/index";
import Client from 'libModule/gqlClient';
import { GQLHelper } from 'lib/utils';
import SystemDynamicConfigListComponent from '../components/SystemDynamicConfigsListComponent';
import {Button, Input, Modal, message} from 'antd';
import { IHButton } from 'ihcomponent'
import createTableAction from 'libModule/table/TableActions';
import { getSystemDynamicConfigsList, createSystemDynamicConfig } from 'modulesAll/graphql/systemDynamicConfigs';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import {goPath} from 'libModule/utils';

const tableActions = createTableAction('system_dynamic_configs_list', { shouldUpdateHistory: true });

const getVariables = (ownProps) => {
	const { page, count } = ownProps;
	return {
		page: _.get(ownProps, 'page.current', 1),
		count: 10
	};
};

const withData = graphql(getSystemDynamicConfigsList, {
	options: (ownProps) => {
		const variables = getVariables(ownProps);

		return {
			variables,
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'network-only'
		};
  },

	props: ({ ownProps, data }) => {
		const { loading, getSystemDynamicConfigs } = data;
		return {
			loading,
			list: getSystemDynamicConfigs
		}
	}
});

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.globalTables.system_dynamic_configs_list,
});

const mapDispatchToProps = (dispatch) => {
  return {
  	...tableActions
  };
}

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateModal: false,
      createKey: '',
      createValue: '',
    };
  }

  renderCreateModal() {
    console.log('render create modal');
    const title = 'Create Config';
    return (
      <Modal
        title = {title}
        visible={true}
        onCancel={() => this.setState({ showCreateModal: false })}
        footer={[
          <Button onClick={() => this.setState({ showCreateModal: false })}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => this.handleSubmit()}>
            Done
          </Button>
        ]}>
        <Input placeholder="config key" onChange={e => this.setState({ createKey: e.target.value })} style={{marginBottom: '8px'}}/>
        <Input placeholder="config value" onChange={e => this.setState({ createValue: e.target.value })}/>
      </Modal>
    );
  }

  handleSubmit() {
    Client.mutate({
      mutation: createSystemDynamicConfig,
      variables: {
        key: this.state.createKey,
        value: this.state.createValue
      },
      refetchQueries: [{
        query: getSystemDynamicConfigsList,
        variables: {
          count: 10,
          page: _.get(this.props.page, 'current', 1)
        }
      }]
    }).then(() => {
      goPath('/superadmin/systemdynamicconfigs');
      message.success('Created successfully.');
      this.setState({ showCreateModal: false });
    }).catch(e => {
      console.log(e);
      this.props.openErrorModal(GQLHelper.formatError(e))
    });
  }

  render() {
    const buttonProps = {
      label: 'Create New Config',
      size: '',
      type: 'primary',
      onClick: () => {
        this.setState({ showCreateModal: true });
      },
      style: { marginRight: '23px' }
    };

    return (
      <div className="vsm-main-page vsm-patientList-main">
        <div className="vsm-main-990">
          <div className="v-rd">
            <IHButton {...buttonProps} />
          </div>
          <div className="v-table">
            <SystemDynamicConfigListComponent {...this.props} />
          </div>
          {this.state.showCreateModal && this.renderCreateModal()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withData,
)(Component);