import React from "react";
import { graphql, compose } from 'react-apollo'
import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList';
import visitsWithWeight from 'modulesAll/graphql/visitsWithWeight';
import moment from 'moment';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import convertor from '@ihealth/convertor';
import { IHSearchTable, IHLoading, IHButton, _,IHInput,message } from 'ihcomponent';

const  HSAreaChartComponent  = class extends React.Component {
    constructor(props){
        super(props);
    }

    /*
    componentDidUpdate(){
        if(this.props.loading==false){
            let { loading,vitalList1=[],vitalList2=[] } = this.props;

            if(loading){
                return<div>Loading</div>
            }
            let uniqueVitalListByDate = _.filter(_.uniqBy(vitalList1,(v)=>moment(v.date || v.appointmentAt).format('MM/DD/YYYY')),(v2)=>(v2.body_weight!=null)||v2.details);
            let joinedList = uniqueVitalListByDate.concat(_.filter(_.uniqBy(vitalList2,(v)=>moment(v.date || v.appointmentAt).format('MM/DD/YYYY')),(v2)=>(v2.body_weight!=null)||v2.details));
            let values = [1,2,3]
                // _.filter(_.map(joinedList,value=>_.get(value,'body_weight.value')),n=>!_.isNaN(Number(n)));
            console.log(values);
                /*(value)=>({y:Number(convertor.HS(_.get(value,'body_weight.value',_.get(value,'details.weight.value')),'kg','lbs').toFixed(1)),
                marker:{
                    symbol: value.details ? 'triangle' : 'circle',
                    fillColor:value.details  ? '#0099FF': '#5CBDFE',
                    radius: 6,
                },
            }));

            let timeSpots = _.map(joinedList,(v)=>moment(v.date || v.appointmentAt || new Date()).format('MM/DD/YYYY'));

            Highcharts.chart('container', {
                chart: {
                    type: 'line'
                },
                title: {
                    text: 'Weight',
                    align: 'left',

                },
                plotBackgroundColor:'#0099FF',
                // legend: {
                //     layout: 'vertical',
                //     align: 'left',
                //     verticalAlign: 'top',
                //     x: 100,
                //     y: 70,
                //     floating: true,
                //     borderWidth: 1,
                //     backgroundColor:
                //     Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
                // },
                xAxis: {
                    // categories: timeSpots,
                    // plotLines:_.map(timeSpots||[],(v,i)=>({ value:i, dashStyle: 'dash' }) )

                },
                yAxis: {
                    title: {
                        text: 'Pound'
                    },
                    min: 50

                },
                plotOptions: {
                    color: '#0099FF',
                    states:{
                        hover:{
                            marker:{
                                radius: 6
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Weight',
                    data: values,
                    pointPlacement: 'on',
                    marker:{
                        symbol:'triangle'
                    },
                    // events: {
                    //     legendItemClick: function() {
                    //         return false;
                    //     }
                    // },
                    // fillColor : {
                    //     linearGradient :{
                    //         x1: 0,
                    //         x2: 0,
                    //         y1: 0,
                    //         y2: 1
                    //     },
                    //     // stops: [
                    //     //     [0, '#98d6ff'],
                    //     //     [0.8, hexToRgba(Highcharts.getOptions().colors[0], 0)]
                    //     // ]
                    // }
                }]
            })
            Highcharts.chart('container', {

                title: {
                    text: 'Solar Employment Growth by Sector, 2010-2016'
                },

                subtitle: {
                    text: 'Source: thesolarfoundation.com'
                },

                yAxis: {
                    title: {
                        text: 'Number of Employees'
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },

                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false
                        },
                        pointStart: 2010
                    }
                },

                series: [{
                    name: 'Installation',
                    data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
                }],

            });
            }
    }*/
    render(){

        let { loading,vitalList1=[],vitalList2=[],fromDate,toDate } = this.props;
        let uniqueVitalListByDate = _.filter(vitalList1,
            (v2)=>(v2.body_weight!=null)||v2.details);
        let joinedList = uniqueVitalListByDate.concat(_.filter(vitalList2,(v2)=>(v2.body_weight!=null)||v2.details));
        joinedList.sort((v1,v2)=> (v1.date||v1.appointmentAt) - (v2.date||v2.appointmentAt));
        let values = _.map(joinedList,(value)=>({
                                                    y: Number(convertor.HS(_.get(value,'body_weight.value',_.get(value,'details.weight.value')),'kg','lbs').toFixed(1)),
                                                    x: moment(value.date|| value.appointmentAt).startOf('day').valueOf(),
                                                    variation:_.get(value,'body_weight_change.value') ? Number(convertor.HS(_.get(value,'body_weight_change.value'),'kg','lbs')).toFixed(1) :'--',
                                                    marker: {
                                                        symbol: value.details ? 'triangle' : 'circle',
                                                        halo:null,
                                                        radius: 6,
                                                        states: {
                                                            hover: {
                                                                fillColor: '#FCA642',
                                                                lineColor: 'white',
                                                                lineWidth: 0,
                                                                halo: null,
                                                                animation:{
                                                                    duration: 20
                                                                }
                                                            }
                                                        }
                                                    },
                                                    dataLabels:{
                                                        enabled: value.details,
                                                        color: '#0099FF',
                                                        format: '{y} lb',
                                                        style:{
                                                            fontSize: 12
                                                        }
                                                    },
                                                    type:value.details ? 'visit' : 'vital',
                                                    name: value.details ? 'Visit' : 'Vital'
                                                }));
        const options =  {
            chart: {
                type: 'line'
            },
            title: null,
            noData: "No Data",
            tooltip:{
                headerFormat:'',
                backgroundColor:'#FFFFFF',
                borderColor:'#D9D9D9',
                pointFormatter:function(){
                    const { y,category,variation } = this;
                    const date = moment(category).format('MM/DD/YYYY');
                    return `<br>
                             Date: <b>${date}</b><br/>
                              Weight: <b>${y} lb</b><br>
                              Weight Variation: <b>${variation} lb</b>`;
                },
                padding: 12,
                style:{
                    fontSize: 12
                },
                dateTimeLabelFormats: {
                    day: "%m/%e/%Y",
                }
            },
            plotBackgroundColor:'#0099FF',
            legend: {
                align: 'left',
                verticalAlign: 'top',
            },
            xAxis: {
                type: 'datetime',
                tickWidth:0,
                tickInterval: 24 * 3600 * 1000*7,
                min:fromDate.valueOf(),
                max:toDate.valueOf(),
                labels:{
                    formatter:(value)=>{
                        const xValue = value.value;
                        return moment(xValue).format('MM/DD/YYYY');
                    }
                },
                crosshair:{
                    width:"1",
                    color:'#00A3FF'
                }
            },
            yAxis: {
                title: {
                    text: 'Pound'
                },
                min: 100,
                gridLineDashStyle:'Dash'
            },
            credits: {
                enabled: false
            },
            time:{
                useUTC:false,
                timezone:moment.tz.guess()
            },
            series: [
                    {
                        data: values,
                        name:'Visit',
                        states:{
                            hover:{
                                halo: null
                            }
                        },
                        findNearestPointBy:'xy',
                        linkedTo:'visit'
                    },
                    {
                        data:[],
                        id: 'visit',
                        name:'Clinic Visit',
                        colorIndex:0,
                        marker: {
                            symbol: 'triangle'
                        },
                        events: {
                            legendItemClick: function() {
                                return false;
                            }
                        },
                    }
                    ],
        }
        const style = loading ? { minHeight:400,justifyContent:'center',alignItems:'center',display:'flex' } : {};

        return <div style={style}>
                {
                  loading ? <IHLoading/> : <HighchartsReact highcharts={Highcharts} options={options}/>
                }
               </div>
    }
}
const withData = graphql( visitsWithWeight, {
    options: (ownProps) => {
        const { fromDate,toDate, patientId,allVisit } = ownProps;
        return {
            variables: {
                    memberId: patientId,
                    appointmentFrom : !allVisit ? Number(fromDate) : null,
                    appointmentTo : !allVisit ? Number(toDate) : null,
                    "count": 999999, // retrieve all at once; intended behavior
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ownProps, data}) => {
        const { loading, visitList } = data;
        if(loading){
            return {
                loading
            }
        }
        const dataList = _.get(visitList,'data',[]);
        const visitsWithWeight = _.filter(dataList,(d)=>_.get(d,'details.weight')&&(_.get(d,'appointmentAt')||_.get(d,'checkinAt')));

        return {
            vitalList1: visitsWithWeight,
            loading,
        }
    }
});

const withResData = graphql( resultList, {
    options: (ownProps) => {
        const { fromDate,toDate, patientId,allVisit } = ownProps;
        return {
            variables: {
                "filters": {
                    "enrolledProgramId": ownProps.currentProgram.id,
                    "type": "HS",
                    fromDate:!allVisit ? Number(fromDate) : null,
                    toDate: !allVisit ? Number(toDate) : null,
                },
                "count": 999999, // retrieve all at once; intended behavior
                "sort": {field: 'DATE', direction: 'ASC'}
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ownProps, data}) => {
        const { loading, resultList } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            vitalList2: _.get(resultList,'data',[]),
            loading,
        }
    }
})
export default  compose(withData,withResData)(HSAreaChartComponent);

