import gql from "graphql-tag";

export  default gql`query visitList($organizationId:EID,$page:Int,$count:Int,$type:[VisitTypeEnum],$providerIds:[EID],$memberId:EID,$appointmentFrom:Date,$appointmentTo:Date,$sort:VisitListSort,$sortByConfirm:Boolean) {
    visitList(filters: {organizationId:$organizationId,providerIds:$providerIds,types:$type,memberId:$memberId,appointmentFrom:$appointmentFrom,appointmentTo:$appointmentTo},page:$page,count:$count,sort:$sort,sortByConfirm:$sortByConfirm) {
        pageInfo{
     	    total
            lastPage
        }
        data{    
          id
          member{
            id
            profile{
              fullName
              gender
              ...on MemberProfile{
                birthday
                lastMeasuredAt
              }
            }
          }
          type
          reason
          conditionsList{
            HLD
            HTN
            DM
            CKD
            Obesity
            PreDM
            COPD
          }
          appointmentAt
          appointmentTo
          checkinAt
          checkoutAt
          providers{
            id
            profile{
              fullName
            }
          }
          visitRoles
          shouldSeeDoctor  
          tasks{
            key
            value
          }
          details{
            doctorSession
            cdeSession
            technicalSetup
            eyeExam
            urineTest
            footExam
            height
            weight
            mpTest
            waistHipRatio
            a1cTest
            addToContactsStatus
            appIsUptoDate
            autoUpdateStatus
            EMRAttached
            bgMeasurement{
              blood_glucose
                beforeMeal
              mealType
            }
            bpMeasurement{
              systolic_blood_pressure
              diastolic_blood_pressure
              heart_rate
            }
          }
          confirm
          workingListStatus
          conditionsList{
            HLD
            HTN
            DM
            CKD
            Obesity
            PreDM
            COPD
          }
          labTests{
            id
            createdAt
            updatedAt
                  dateCollected
                  templateId
                  templateName
                  results{
                      name
                      value
                      unit
                      isInHouse
                  }
            }   
            vitalTypes 
            needInHouseA1C
            labResult{
                id
                createdAt
                updatedAt
                dateCollected
                templateId
                templateName
                results{
                      name
                      value
                      unit
                      isInHouse
                }
            }   
          }
     }

}`
