import { graphql, withApollo, compose } from 'react-apollo'
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram'
import { enrolledProgram as updateQueries } from 'graphqlModule/updateQueries/enrolledProgram'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import React, { Component } from 'react';
import { validateAntdFormWithFieldsValue } from 'libModule/utils'
import { getFormProps } from 'commonModule/components/careplan/formData/VitalForm'
import actions from 'patientModule/enrolledProgramInfo/Careplan/actions/VitalFormActions'
import AlertFormContainer from 'patientModule/enrolledProgramInfo/VitalAlert/containers/CommonContainer'
import get from 'lodash/get'
import { IHBaseFormWithRow, IHButton } from 'ihcomponent'
import { CareplanHelper } from 'modulesAll/utils/helpers/task'
import alertListActions from 'patientModule/enrolledProgramInfo/VitalAlert/actions/AlertListActions'
import RequestCache from 'libModule/requestCache'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withProps } from 'recompose';
import * as timerActions from '../../../../timer/actions/index';
import TimerContainer from '../../../../timer/containers/TimerContainer';
import DeleteTaskComponent from '../components/DeleteTaskComponent';
const { stopTimer,updateInterventionDetails }= timerActions.default;
import { message } from 'antd';
import { convertDBToVitalList, addVitalDataToList } from 'modulesAll/utils/helpers/task'
import enrolledProgram from 'modulesAll/graphql/enrolledProgram';

const style = {
  titleStyle:{
    margin:'unset',
    alignSelf:'flex-end'
  },
  titleAndDeleteContainer:{
      margin:'10px 0 6px',
      display:'flex'
  }

}
const defaultInputType = {
  'BG':'device',
  'BP':'device',
  'HS':'device'
}
class Container extends Component {
  static displayName = 'patient/enrolledProgramInfo/Careplan/containers/VitalFormContainer'

  componentWillUnmount () {
    this.props.reset()
  }
  render() {
    const title = this.renderTitle();
    const backLink = this.renderBackLink();
    const body = this.renderBody();
    const submitBtn = this.renderSubmitBtn();
    const alertForm = this.renderAlertForm();
    const deleteButton = this.renderDeleteButton();
    const displayName = this.constructor.displayName;
    const viewingDetails ={
        component : displayName,
        path:window.location.pathname
    }

    return (
      <div>
        <TimerContainer displayName={displayName} category='ENROLLED_PROGRAM' action='UPDATE' viewingDetails={viewingDetails} patientId = {this.props.patientId}/>
        {backLink}
        <div style={{padding: '0 80px'}}>
          <div style={style.titleAndDeleteContainer}>
           {title}
           {deleteButton}
          </div>
          {body}
          {alertForm}
          {submitBtn}
        </div>
      </div>
    )
  }
  renderAlertForm() {
    const vitalType =_.get(this,'props.fieldsValue.vitalType',{});
    const { AlertForm } = this.props
    const alertForm = <AlertFormContainer {...AlertForm} />
    return alertForm
  }

  renderDeleteButton(){
    const { changePage } = this.props;
    const enrolledProgramId = _.get(this,'props.program.id','');
    const taskId = _.get(this,'props.fieldsValue.id.value','');
    const vitalType =_.get(this,'props.fieldsValue.vitalType',{});
    const fullName = _.get(this,'props.program.user.profile.fullName','');

    return <DeleteTaskComponent changePage = { changePage }
            enrolledProgramId = { enrolledProgramId }
            taskId = { taskId }
            fullName = { fullName }
            vitalType = { vitalType }
           />
  }

  renderBody() {
    const { onFieldsChange, fieldsValue,/* VitalList,*/program, isEditMode } = this.props
    const VitalList = {data:convertDBToVitalList(program)};
    const formInfo = {
      onFieldsChange,
      fieldsValue,
      VitalList,
      className: 'vsm-form-body',
      isEditMode,
      userRole: 'PROVIDER'
    };
    const formProps = getFormProps(formInfo);

    const body = <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} className='careplanEditForm'/>
    return body
  }

  handleManualAndDeviceInputArray=(type)=>{
    let deviceInputEnabledTaskTypes = _.get(this,'props.program.deviceInputEnabledTaskTypes',[]);
    let manualInputEnabledTaskTypes = _.get(this,'props.program.manualInputEnabledTaskTypes',[]);
    //handle update if type is in manual or device
    if(!deviceInputEnabledTaskTypes){
      deviceInputEnabledTaskTypes = [];
    }
    if(!manualInputEnabledTaskTypes){
      manualInputEnabledTaskTypes = [];
    }


    if(_.indexOf(manualInputEnabledTaskTypes,type)!=-1||_.indexOf(deviceInputEnabledTaskTypes,type)!=-1){
        return {
            deviceInputEnabledTaskTypes,
            manualInputEnabledTaskTypes
        }
    }

    //handle add vital, add default
    if(defaultInputType[type]=='manual'){
      if(_.indexOf(manualInputEnabledTaskTypes,type)==-1) {
          manualInputEnabledTaskTypes.push(type);
      }
    }
    if(defaultInputType[type]=='device'){
      if(_.indexOf(deviceInputEnabledTaskTypes,type)==-1) {
          deviceInputEnabledTaskTypes.push(type);
      }
    }

    return {
      deviceInputEnabledTaskTypes,
      manualInputEnabledTaskTypes
    }
  }

  async submit() {
    const { fieldsValue, program, editEnrolledProgram, changePage, AlertList } = this.props
    const _currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(_currentPatient,"identification.0.value")
    const patientName = _currentPatient.profile.fullName
    // validate all fields before submission
    const userRole = 'PROVIDER'
    const isFormValid = await validateAntdFormWithFieldsValue(fieldsValue, this.baseForm, userRole)
    if (!isFormValid) return
    const panesFieldsValue = AlertList.panesFieldsValue;
    const task = CareplanHelper.convertVitalToDBTaskForEP(fieldsValue, panesFieldsValue);
    const tasks = [task];
    let variables = {
      id: program.id,
      tasks: tasks
    };

    Object.assign(variables,this.handleManualAndDeviceInputArray(task.type));

    const auditLogDetails = {
      programName: program.name,
      vitalType: fieldsValue.vitalType.value,
      tasks: [CareplanHelper.convertVitalToDBTaskForEP(fieldsValue, panesFieldsValue)]
    }

    try {
      const res = await editEnrolledProgram({ variables, updateQueries, refetchQueries: ['lightResultsFromToday',{
              query:enrolledProgram,
              variables:{
                  id:program.id
              }}]});
      const apiName = Object.keys(res.data)[0];
      const docId = res.data[apiName].id;
      const summary = 'update careplan';
      const resource = atob(docId).split(':')[0];
      let displayName = this.constructor.displayName;
      this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
      this.props.stopTimer(displayName,'saveModal');
      this.props.changeEditMode();

      // used to change disable submit button of ProgramInfoTab page (of new Care Plan)
      if (this.props.changeOnFirstLoadFlag) {
        this.props.changeOnFirstLoadFlag();
      }

        createAuditLog({
        action:I18N.get('auditLog.provider.patient.enrollInNewProgram.edit_enrolled_program_vital'),
        details: auditLogDetails,
        request: variables,
        response: res,
        patientNRIC,
        patientName
      })
      changePage()
    }
    catch (err) {
      message.error(err.message);
      console.error(err);
      createAuditLog({
        action:I18N.get('auditLog.provider.patient.enrollInNewProgram.edit_enrolled_program_vital'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false,
        patientNRIC,
        patientName })
    }
  }
  renderBackLink(){
    return (
      <div className="v-link" onClick={() => this.props.changePage()}>
        <div>
          <i className="fa fa-angle-left" />
          Back to Care Plan
        </div>
      </div>
    )
  }
  renderTitle() {
    return <h3 className='vsm-form-title' style={ style.titleStyle }>Add Vitals to Care Plan</h3>
  }
  renderSubmitBtn() {
    const styles = {
      footer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
      },
      btnRight: {
        alignSelf: 'flex-end'
      }
    }
    const addBtnProp = {
      onClick : (e)=>{
        this.submit()
      }
    }
    const btn = (
      <div style={styles.footer}>
        <div style={styles.btnRight}>
          <IHButton {...addBtnProp} bsStyle="primary" label="Save" />
        </div>
      </div>
    )
    return btn
  }
}

const mapState = ({ patient }, ownProps) => R.merge({
  AlertList: get(patient.enrolledProgramInfo, 'Careplan.VitalAlert.AlertList'),
  AlertForm: get(patient.enrolledProgramInfo, 'Careplan.AlertForm'),
  // VitalList: get(patient.enrolledProgramInfo, 'Careplan.VitalList'),
}, get(patient.enrolledProgramInfo, 'Careplan.VitalForm'));

const mapDispatch = (dispatch) => {
  const reset = alertListActions.reset
  return {
    ...bindActionCreators(actions, dispatch),
      stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
      updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
      reset: () => dispatch(reset()),
      changeEditMode: () => dispatch(actions.changeEditMode())
  }
}

Container.propTypes = {
  program: PropTypes.object,
  changePage: PropTypes.func,
  onFieldsChange: PropTypes.func,
  editEnrolledProgram: PropTypes.func,
  fieldsValue: PropTypes.object,
  AlertForm: PropTypes.object,
  VitalList: PropTypes.object,
  isEditMode: PropTypes.bool,
}

const forceSlotToWeeklyMeal = (props) => (
  R.pathEq(['fieldsValue', 'schedule', 'value', 'frequency'], 'slot', props) && R.pathEq(['fieldsValue', 'vitalType', 'value'], 'Blood Glucose', props)
    ? R.assocPath(['fieldsValue', 'schedule', 'value', 'frequency'], 'weeklyMeal', props)
    : props
  );

export default compose(
  withApollo,
  graphql(editEnrolledProgram, {name: 'editEnrolledProgram'}),
  connect(mapState, mapDispatch),
  withProps(forceSlotToWeeklyMeal),
)(Container);

export { Container };
