import gql from 'graphql-tag';

export default gql`
query smartAlertTasksList($filters:smartAlertOpts, $page:Int, $count:Int){
  smartAlertTasksList(filters:$filters, count:$count, page:$page){
    data{ 
        id
        firstName
        lastName
        memberId
        tasks {
                id
                createdAt
                taskType
                status
                alertLevel
                display       
                replyTime
               }     

        }
    pageInfo {
            lastPage 
            total
    }
  }
}`;
