import {React, IHModal, _, IHButton} from 'ihcomponent'
import {modalAction} from '../actions'
import {createContainer, GQLHelper} from 'libModule/utils'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo'
import starMember from 'modulesAll/graphql/starMember'
import unstarMember from 'modulesAll/graphql/unStarMember'
import { createAuditLog } from 'libModule/utils/auditLog'
import * as timerActions from '../../timer/actions/index';
const { stopTimer,updateInterventionDetails }= timerActions.default;
import moment from "moment";
import TimerContainer from '../../timer/containers/TimerContainer';
import {connect} from "react-redux";
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';

const TITLE_MAP = {
  add : 'Tag Patient',
  delete : 'Resolve Tag',
  edit: 'Edit Tag Reason'
}
const Component = class extends React.Component{
  static displayName = 'common/containers/PatientStarModal'
  constructor(props) {
    super(props);
    this.state = {
      disabled: true
    };
    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(e) {
    this.setState({
      disabled: !e.target.value
    })
  }

  render(){
    const {show} = this.props
    if(!show) return null
      let displayName = this.constructor.displayName;
      const memberId = _.get(this,'props.user.id');
      const viewingDetails ={
          component : displayName,
          path:window.location.pathname
      }
    const p = this.getModalProps();
    return <div>
              { show ? <IHModal {...p} /> :'' }
              <TimerContainer displayName={displayName} category='TAG_PATIENT' action='ENGAGE'
                              viewingDetails={viewingDetails} patientId={memberId}/>
             </div>
  }

  getModalProps(){
    const {show, type} = this.props
    return {
      showModal : show,
      showHeaderCloseButton : false,
      className : 'vsm-PatientStarModal',
      title : TITLE_MAP[type],
      body : this.renderBody(),
      onHide : ()=>{
        this.close(true)
      },
      footer : this.renderFooter()
    }
  };

  renderBody(){

    const TagInfo = ({name,createdAt,reason}) => {
      return (
        <div style={{textAlign:'left'}}>
            <div style={{display:'flex'}}>
            <b style={{ fontWeight:600,paddingRight:10,color: '#474747' }}>Patient Name:</b>
            <div>{name}</div>
          </div>
          <div style={{display:'flex', marginBottom:30}}>
            <b style={{ fontWeight:600,paddingRight:10,color: '#474747' }}>Date Tagged:</b>
            <div>{createdAt}</div>
          </div>
          <div style={{ display:'inline' }}>
                {/*<b style={{ fontWeight:600,paddingRight:10,color: '#474747' }}>Reason:</b>*/}
            <b style={{ fontWeight:600,paddingRight:10,color: '#474747' }}>Reason:</b>
                {reason}
          </div>
        </div>
      )
    };
    const {type} = this.props
    const name = _.get(this.props.user, 'profile.fullName')
    const createdAt = moment(_.get(this.props.user, 'flagDetails.0.createdAt')).format('MMM-DD-YYYY');
    const reason = _.get(this.props.user, 'flagDetails.0.reason');

    if(type === 'delete'){
      const tap = {
        placeholder : 'Enter solution here'
      }
      return (
        <div>
          <TagInfo name={name} createdAt={createdAt} reason={reason}/>
          <p>
            <textarea ref="note" className="v-ta" {...tap} onChange={this.handleInput} />
          </p>
        </div>
      )
    }else if (type === 'edit'){
      return (
        <p>
          Please state your reason for tagging {name}.
          <textarea ref="note" className="v-ta" defaultValue={reason} onChange={this.handleInput} />
        </p>
      )
    }
    else{
      const tap = {
        placeholder : 'Enter reason here'
      }
      return (
        <p>
          Please state your reason for tagging {name}.
          <textarea ref="note" className="v-ta" {...tap} onChange={this.handleInput}/>
        </p>
      )
    }
  }

   async addStar() {
    const {user, dispatch} = this.props
    const variables = {
      id : user.id,
      reason : this.refs.note.value || ''
    }

    try{
      const res = await this.props.starMember({variables});
      const apiName = Object.keys(res.data)[0];
      const docId = res.data[apiName].id;
      const summary = 'Tag Patient: '+(this.refs.note.value || '');
      const resource = atob(docId).split(':')[0];
      let displayName = this.constructor.displayName;
      displayName = `${displayName}:${user.id}`;
      this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
      this.props.stopTimer(displayName,'saveModal');
      // createAuditLog({
      //   action: I18N.get('auditLog.provider.taggedpatients.tagPatient'),
      //   patientNRIC: user.identification[0].value,
      //   patientName: user.profile.fullName,
      //   details: { tagReason: this.refs.note.value || '' },
      //   request: variables,
      //   response: res
      // }) 
      this.close(true)
    }catch(e){
      // createAuditLog({
      //   action: I18N.get('auditLog.provider.taggedpatients.tagPatient'),
      //   patientNRIC: user.identification[0].value,
      //   patientName: user.profile.fullName,
      //   details: { tagReason: this.refs.note.value || '' },
      //   request: variables,
      //   response: e,
      //   success: false
      // })
      dispatch(modalAction.openErrorModal(GQLHelper.formatError(e)))
    }
  }

  async removeStar() {
    const {user, dispatch} = this.props
    const variables = {
      id : user.id,
      reason : this.refs.note.value || ''
    }

    try{
      const res = await this.props.unstarMember({variables});
      const apiName = Object.keys(res.data)[0];
      const docId = res.data[apiName].id;
      const summary = 'Untag Patient: '+(this.refs.note.value || '');
      const resource = atob(docId).split(':')[0];
      let displayName = this.constructor.displayName;
      displayName = `${displayName}:${user.id}`;
      this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
      this.props.stopTimer(displayName,'saveModal');
      // createAuditLog({
      //   action: I18N.get('auditLog.provider.taggedpatients.untagPatient'),
      //   patientNRIC: user.identification[0].value,
      //   patientName: user.profile.fullName,
      //   request: variables,
      //   response: res
      // })
      this.close(true)
    }catch(e){
      // createAuditLog({
      //   action: I18N.get('auditLog.provider.taggedpatients.untagPatient'),
      //   patientNRIC: user.identification[0].value,
      //   patientName: user.profile.fullName,
      //   request: variables,
      //   response: e,
      //   success: false
      // })
      dispatch(modalAction.openErrorModal(GQLHelper.formatError(e)))
    }finally{

    }
  }

  close(flag=false){
      this.props.dispatch(modalAction.showPatientStarModal(false));
      flag && this.props.refetch && this.props.refetch()
    // this.props.dispatch(modalAction.showPatientStarModal(false))
  }
  renderFooter(){
    const {type} = this.props
    let b1, b2
    if('add' === type || 'edit' === type){
      b1 = {
        label : 'Tag',
        style : {width: '80px'},
        type : 'primary',
        disabled: this.state.disabled,
        onClick : () => {
          this.setState({
            disabled: true
          });
          this.addStar()
          Mixpanel.track('clicked', 'tag', 'Tag Patient Popup', {PATIENT_ID: _.get(this, 'props.user.id')});
        }
      }
      b2 = {
        label : 'Cancel',
        onClick : () => {
          this.setState({
            disabled: true
          });
          this.close(true);
          Mixpanel.track('clicked', 'cancel', 'Tag Patient Popup', {PATIENT_ID: _.get(this, 'props.user.id')});
        }
      }
    }
    else if(type === 'delete'){
      b1 = {
        type : 'primary',
        label : ' Confirm',
        disabled: this.state.disabled,
        onClick : ()=>{
          this.setState({
            disabled: true
          });
          this.removeStar()
          Mixpanel.track('clicked', 'untag', 'Resolve tag Patient Popup', {PATIENT_ID: _.get(this, 'props.user.id')});
        }
      }
      b2 = {
        label : 'Cancel',
        onClick : (e) => {
          this.setState({
            disabled: true
          });
          Mixpanel.track('clicked', 'untag', 'Resolve tag Patient Popup', {PATIENT_ID: _.get(this, 'props.user.id')});
          this.close(true)
        }
      }
    }

    return [
      <IHButton {...b1} key={1} />,
      <IHButton {...b2} key={2} />
    ]
  }
}

Component.propTypes = {
  refetch : PropTypes.func,
  show : PropTypes.bool,
  type : PropTypes.string,
  unstarMember : PropTypes.func,
  dispatch : PropTypes.func
}
const starMemberGQL = graphql(starMember, {name : 'starMember'})
const unstarMemberGQL = graphql(unstarMember, {name : 'unstarMember'})
const wrapperComponent = GQLHelper.wrapper([starMemberGQL, unstarMemberGQL], Component)

const mapToDispatch = (dispatch)=>{
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}

export default createContainer(connect(null,mapToDispatch)(wrapperComponent), (state)=>{
  const params = state.common.PatientStarModal
  return {
    ...params
  }
})
