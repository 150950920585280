import {
    React, IHButton
} from 'ihcomponent'
import ListTable from './ListTable'
import createTableAction from 'libModule/table/TableActions'
import teamListGQL from 'modulesAll/graphql/teamList'
import { graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import _ from 'lodash'


const tableActions = createTableAction('care_team_list', {
  shouldUpdateHistory: false,
  sortFieldMap: {
    createdAt: 'CREATED_AT',
    modifiedAt: 'MODIFIED_AT',
    careTeam: 'NAME'
  },
})

const Component = (props)=>{
    const buttonProps = {
        label : 'Create New Care Team',
        size : '',
        type : 'primary',
        onClick : ()=>{
          browserHistory.push(`/careteams/new`)
        }
    }

    return (
        <div className="vsm-main-page vsm-patientList-main">
            <div className="vsm-main-990">
                <div className="v-rd">
                    <IHButton {...buttonProps} />
                </div>
                <div className="v-table">
                    <ListTable {...props} />
                </div>
            </div>
        </div>
    )
}

Component.displayName = 'careTeam/list/containers/main'

const withData = graphql(teamListGQL, {
  options : (ownProps)=>{
    const variables = {
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      filters: {
        search: {
          fields: ['NAME'],
          match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
        }
      },
      search: {
        fields: ['NAME'],
        match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
      },
      sort: null,
    }

    const sortField = _.get(ownProps, 'sort.fieldGraph', null)
    if (sortField) {
      variables.sort = {
        field: sortField,
        direction: _.get(ownProps, 'sort.direction', 'ASC'),
      }
    }

    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props : ({data})=>{
    return {
      list : data.teamList,
      loading : data.loading,
      refetch : data.refetch
    }
  }
})

export default compose(
  connect(
    (state)=>{
        return {
          ...state.globalTables.care_team_list
        }
    },
    (dispatch)=>{
        return {
            ...tableActions
        }
      }
    ),
 withData
)(Component)
