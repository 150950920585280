
export const MapActionsConfiguration = {
    PROVIDER_NOTES:{
        startTimerAction:'openModal',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abortModal',
        stopTimerAction:'saveModal',
        summaryKey:'content',
        leaveTrigger:['unmount'],
        allowAbort:true,
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    CONVERSATION:{
        startTimerAction:'startChat',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        stopTimerAction:'leaveChat',
        leaveTrigger:['unmount','rerender'],
        allowAbort:false,
        coolDown:true,
        isChat:true,
        sessionId:false,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    ENROLLED_PROGRAM:{
        startTimerAction:'startUpdate',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abortUpdate',
        stopTimerAction:'saveUpdate',
        leaveTrigger:['unmount'],
        allowAbort:true,
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    VITALS:{
        startTimerAction:'openModal',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abortModal',
        stopTimerAction:'saveModal',
        summaryKey:'content',
        leaveTrigger:['unmount'],
        allowAbort:true,
        sessionId:(displayName)=>displayName.includes('/alerts'),
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    PATIENT_PROFILE:{
        startTimerAction:'enterPage',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abort',
        stopTimerAction:'leavePage',
        leaveTrigger:['rerender','unmount'],
        allowAbort:false,
        version:0,
        sessionId:true,
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    FOOD_LOG:{
        startTimerAction:'openModal',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abortModal',
        stopTimerAction:'saveModal',
        leaveTrigger:['unmount'],
        allowAbort:true,
        sessionId:(displayName)=>displayName.includes('/foodlog'),
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    TAG_PATIENT:{
        startTimerAction:'openModal',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abortModal',
        stopTimerAction:'saveModal',
        leaveTrigger:['unmount'],
        allowAbort:true,
        sessionId:(displayName)=>displayName.includes('/patients')||displayName.includes('/tagged_patients'),
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    MEDICINE:{
        startTimerAction:'openModal',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abortModal',
        stopTimerAction:'saveModal',
        summaryKey:'content',
        leaveTrigger:['unmount'],
        allowAbort:true,
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    POSTIT:{
        startTimerAction:'openModal',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abortModal',
        stopTimerAction:'saveModal',
        summaryKey:'content',
        leaveTrigger:['unmount'],
        allowAbort:true,
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    VISIT:{
        startTimerAction:'openModal',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abortModal',
        stopTimerAction:'saveModal',
        leaveTrigger:['unmount'],
        allowAbort:true,
        coolDown:true,
        sessionId:(displayName)=>displayName.includes('/appointments'),
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    PATIENT_DETAIL:{
        startTimerAction:'enterPage',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abort',
        stopTimerAction:'leavePage',
        leaveTrigger:['rerender','unmount'],
        allowAbort:false,
        version:0,
        sessionId:true,
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    ADDITIONALINFO:{
        startTimerAction:'startUpdate',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        stopTimerAction:'saveUpdate',
        abortTimerAction:'abortUpdate',
        leaveTrigger:['unmount'],
        allowAbort:true,
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    ALERT:{
        startTimerAction:'openModal',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abortModal',
        stopTimerAction:'saveModal',
        leaveTrigger:['unmount'],
        allowAbort:true,
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume'
    },
    TASK_ASSIGNMENT:{
        startTimerAction:'openModal',
        pauseTimerAction:'blur',
        resumeTimerAction:'focus',
        abortTimerAction:'abortModal',
        stopTimerAction:'saveModal',
        leaveTrigger:['unmount'],
        allowAbort:true,
        coolDown:true,
        coolDownExpireAction:'timeoutExpire',
        coolDownResumeAction:'timeoutResume',
        sessionId:(displayName)=>displayName.includes('/patients')||displayName.includes('/taskAssignments'),

    }

}

export const startActionMap = {
    START_TIMER:'START_TIMER',
    RESUME_TIMER:'RESUME_TIMER'
}

export const endActionMap = {
    PAUSE_TIMER:'PAUSE_TIMER',
    STOP_TIMER:'STOP_TIMER'

}
