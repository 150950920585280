import React, { Component } from 'react';
import {Col, Row, Select, Form} from "antd";

import {helpers} from "../../visit/helpers";
import {helpers as dataHelper} from "../helper";

const {Item} = Form;
const {Option} = Select;

class VisitInputTypeAndProvider extends Component{

    renderType(){

        const { getFieldDecorator } = this.props.form;
        const {initialType} = this.props;

        let typeValue = 'FOLLOW_UP';
        const {visitInfo, editStatus} = this.props;
        if(editStatus){
            typeValue = visitInfo.type;
        }

        if(initialType){
            return (
                <Item label="Type">
                    {getFieldDecorator('type', {
                        initialValue: 'INIT',
                        rules:[{required:true}]
                    })(
                        <Select>
                            {_.map(dataHelper.INITIAL_TYPE_ENUM,(type,val)=>{
                                return (
                                    <Option value={val} key={val}>{type}</Option>
                                )
                            })}
                        </Select>
                    )}
                </Item>
            )
        }else{
            return (
                <Item label="Type">
                    {getFieldDecorator('type', {
                        initialValue: typeValue,
                        rules:[{required:true}]
                    })(
                        <Select>
                            {_.map(dataHelper.NON_INITIAL_TYPE_ENUM,(type,val)=>{
                                return (
                                    <Option value={val} key={val}>{type}</Option>
                                )
                            })}
                        </Select>
                    )}
                </Item>
            )
        }
    }

    renderProvider(){

        const { getFieldDecorator } = this.props.form;

        const doctorOptions = helpers.filterDoctors();
        let initDoctorOption = _.get(_.filter(doctorOptions,d=>d.primary),'0');
        const {visitInfo, editStatus} = this.props;
        if(editStatus && visitInfo.providers){
            initDoctorOption = {id: visitInfo.providers[0].id}
        }

        return (
            <Item label="Provider" >
                {getFieldDecorator('doctor',{initialValue:initDoctorOption.id ||null,rules:[{required:true}]})
                (
                    <Select filterOption={false}>
                        {_.map(doctorOptions,(program)=>{
                            return (
                                <Option value={program.id} key={program.id}>{program.fullName}</Option>
                            )
                        })}
                    </Select>
                )}
            </Item>
        )
    }

    render() {

        return (
            <Row gutter={16}>
                <Col span={12}>
                    {this.renderType()}
                </Col>
                <Col span={12}>
                    {this.renderProvider()}
                </Col>
            </Row>)

    }

}

export default VisitInputTypeAndProvider;
