import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import createOrganization from '../query/createOrganization';
// import { providerList as updateQueries } from 'graphqlModule/updateQueries/providerList'
import { IHBaseFormWithRow, IHButton, message, IHLoading, _ } from 'ihcomponent'
import PropTypes from 'prop-types';
import { getFormProps } from '../component/formData/organizationCreateForm';
import * as actions from '../action/organizationCreateAction';
import { goPath, FormHelper, GQLHelper, setAsyncRouteLeaveHook, submittingStyle } from 'libModule/utils'
import { modalAction } from 'modulesAll/common/actions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { compose } from 'redux';
import organization from '../query/organization';
import organizationListGQL from '../query/organizationList'

class Container extends Component {
    static displayName = 'superadmin/organizationCreate';

    componentWillMount() {
        setAsyncRouteLeaveHook(this.context.router, this.props.route, this.routerWillLeave.bind(this))
    }

    routerWillLeave() {
        const { openConfirmModal } = this.props
        return new Promise((resolve, reject) => {
            if (this.props.isFormSaved) {
                resolve(true)
                return
            }
            const Yes = {
                label: 'Confirm',
                handler: () => {
                    resolve(true)
                },
                closeModal: true
            }
            const No = {
                label: 'Cancel',
                handler: () => {
                    resolve(false)
                }
            }
            const modalProps = {
                Yes,
                No,
                title: 'Leave This Page?',
                content: 'Please confirm if you would like to leave this page'
            }
            openConfirmModal(modalProps)
        })
    }
    componentWillUnmount() {
        this.props.resetForm()
    }
    render() {
        const body = this.renderBody()
        const footer = this.renderFooter()

        return (
            <div className='vsm-provider-new-page'>
                <div className='provider-new-form v-form-required'>
                    {body}
                    {footer}
                </div>
            </div>
        )
    }
    renderBody() {
        const { onFieldsChange, fieldsValue, organizationList, orgsLoading } = this.props;
        const formProps = getFormProps({ onFieldsChange, fieldsValue, organizationList });
        if (!orgsLoading) {
            return <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
        }
    }
    renderFooter() {
        const { isSubmitting } = this.props
        const styles = {
            footer: {
                display: 'flex',
                flexBasis: '100%',
                justifyContent: 'flex-end',
                width: '100%',
                paddingRight: 5,
                marginTop: 10
            }
        }
        const buttonProps = {
            cancel: {
                onClick: (e) => {
                    goPath('/superadmin/organizations')
                },
                bsStyle: 'default',
                disabled: false,
                label: 'Cancel'
            },
            done: {
                onClick: (e) => {
                    this.submit()
                },
                bsStyle: 'primary',
                disabled: isSubmitting,
                type: 'submit',
                style: {
                    marginRight: 10,
                    ...submittingStyle(isSubmitting)
                }
            }
        }

        const footer = (
            <div style={styles.footer}>
                <IHButton {...buttonProps.done} >
                    {isSubmitting ? <IHLoading /> : 'Create'}
                </IHButton>
                <IHButton {...buttonProps.cancel} />
            </div>
        )

        return footer
    }
    getMutateVariable(data) {
        // const result = determineEmployeeOrMemberProfile(data);

        let phone = null;
        if (data.phone&&data.phone.trim()) {
            phone = {
                countryCode: data.phoneCountryCode ? parseInt(data.phoneCountryCode, 10) : '',
                number: data.phone ? data.phone.trim() : '',
                type: 'WORK'
            };
        }

        const name = data.name ? data.name.trim() : '';
        const identifier = data.identifier ? data.identifier.trim() : name.replace(/\s+/g,'_');
        const parentId = data.parentId;
        const leaf = data.leaf;
        const accessTmpl = data.accessTmpl ? data.accessTmpl : (leaf ? 'LEAF' : 'GROUP');
        const description = data.description ? data.description.trim() : '';
        const timezone = data.timezone ? data.timezone.trim() : '';

        const streetNumber = data.streetNumber ? data.streetNumber.trim() : '';
        const streetName = data.streetName ? data.streetName.trim() : '';
        const state = data.state ? data.state.trim() : '';
        const city = data.city ? data.city.trim() : '';
        const country = data.country ? data.country.trim() : '';
        const postCode = data.postCode ? data.postCode.trim() : '';
        let address = null;
        if (streetNumber && streetName && state && city && country && postCode) {
            address = {
                type: 'MAIN',
                streetNumber,
                streetName,
                city,
                state,
                country,
                postCode
            };
        }

        const result = {
            identifier,
            name,
            parentId,
            leaf,
            accessTmpl,
            description,
            timezone,
            address,
            phone
        };

        return result;
    }

    async submit() {
        const { mutate, fieldsValue, handleFormSaved, resetForm, openSuccessModal, openErrorModal, setSubmitting } = this.props
        const formValue = FormHelper.getValue(fieldsValue)
        let isFormValid = true
        const callback = (result, value) => {
            if (!result) {
                isFormValid = false
            }
        }
        await this.baseForm.getFormData(callback)
        if (!isFormValid) {
            message.error('Oops, please check the errors below')
            return
        }
        const variables = this.getMutateVariable(formValue);

        try {
            setSubmitting(true)
            await handleFormSaved()
            // const res = await mutate({ variables, updateQueries })
            const res = await mutate({ variables });

            createAuditLog({
                action: I18N.get('auditLog.admin.organization.create'),
                details: formValue,
                request: variables,
                response: res
            });
            if (res.data.createOrganization) {
                resetForm()
                const modalTitle = 'Organization creation Successful'
                const modalContent = `Organization has been created!`
                openSuccessModal(modalTitle, modalContent)

                goPath('/superadmin/organizations');
            }
        } catch (err) {
            openErrorModal(GQLHelper.formatError(err))

            createAuditLog({
                action: I18N.get('auditLog.admin.organization.create'),
                details: formValue,
                request: variables,
                response: err,
                success: false
            })
        } finally {
            setSubmitting(false)
        }
    }
}

Container.contextTypes = {
    router: PropTypes.object.isRequired
}

const ContainerWithData = graphql(createOrganization)(Container)

const mapState = ({ organization }, ownProps) => {
    return {
        ...organization.OrganizationCreate
    }
}

const mapDispatch = (dispatch) => {
    return {
        onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
        handleFormSaved: () => dispatch(actions.handleFormSaved()),
        resetForm: () => dispatch(actions.resetForm()),
        openSuccessModal: (title, content) => dispatch(modalAction.openSuccessModal(title, content)),
        openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
        openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
        setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting))
    }
}

Container.propTypes = {
    onFieldsChange: PropTypes.func,
    resetForm: PropTypes.func,
    handleFormSaved: PropTypes.func,
    initData: PropTypes.object,
    route: PropTypes.object,
    mutate: PropTypes.func,
    fieldsValue: PropTypes.object,
    isFieldsRequired: PropTypes.object,
    isFormValid: PropTypes.bool,
    isFormSaved: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    openSuccessModal: PropTypes.func,
    openErrorModal: PropTypes.func,
    openConfirmModal: PropTypes.func
}

const organizationList = graphql(organizationListGQL, {
    options: (ownProps) => {
        const variables = {
            page: 1,
            count: 99999,
            filters: {
                leafOnly: false
            }
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ data }) => {
        return {
            organizationList: data.organizationList,
            orgsLoading: data.loading
        }
    }
})


export default compose(connect(
    mapState, mapDispatch
), organizationList)(ContainerWithData)
