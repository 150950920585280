import React from 'react';
import { Steps, Form, Row, Col, Modal } from 'antd';
import { IHLoading, message } from 'ihcomponent';
import Map from '../constant/stepsMap';
import formMap from '../constant/formMap';
const { MAWorkListMap, RDWorkingList } = Map;
const { onBoardFormMap } = formMap;
const { Step } = Steps;
import $ from 'jquery';
import '../../visitWorkList/style/index.scss';
import { compose } from 'redux';
import helpers from '../helpers/';
import LabResInWorkListContainer from '../container/LabResInWorkListContainer';
import MedicationAddAndListContainer from '../container/MedicationAddAndListContainer';
import VitalsAndBillingComponent from '../component/VitalsAndBillingComponent';
import FollowUpVisitComponent from '../component/FollowUpVisitComponent';
import OnBoardContainer from '../container/OnBoardContainer';
import NutritionComponent from '../component/NutritionComponent';
import GoalComponent from '../component/GoalComponent';
import ChartingComponent from '../component/ChartingComponent';
import { withRouter } from 'react-router';
import Client from 'libModule/gqlClient';
import updateVisitWorkingList from '../../graphql/mutation/updateVisitWorkingList';
import map from '../constant/workListConstants';
import singleVisit from '../../graphql/signleVisit';
import visitMap from '../constant/visitTypeMap';
import AllTaskFinishedComponent from '../component/AllTaskFinishedComponent';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const { createForm } = helpers;
const mapToIndex = {
  'On Board': 1,
  'Lab Results': 2,
  'Vitals&Billing': 3,
  Medication: 4,
  'Follow-up Visit': 5,
  Assessment: 7,
  'Goal statement': 8,
  Charting: 9
};
const IndexToMap = {
  1: 'On Board',
  2: 'Lab Results',
  3: 'Vitals&Billing',
  4: 'Medication',
  5: 'Follow-up Visit',
  7: 'Assessment',
  8: 'Goal statement',
  9: 'Charting'
};
const workListKeyMap = {
  'On Board': 'onBoard',
  'Lab Results': 'LabResults',
  'Vitals&Billing': 'VisitBilling',
  Medication: 'Medication',
  'Follow-up Visit': 'FollowUpVisit',
  Assessment: 'Assessment',
  'Goal statement': 'GoalStatement',
  Charting: 'Charting'
};
const renderMap = localThis => {
  const {
    props,
    setIsEditMode,
    setChildProps,
    resetAfterSaveDraft
  } = localThis;
  const { state } = localThis;
  const { isEditMode, childProps } = state;
  const user = _.get(props, 'visit.member',_.get(props,'member'));
  const userId = _.get(user, 'id');
  let parsedId = atob(userId).split(':')[1];
  const enrolledProgram = _.get(props, 'visit.enrolledProgram');
  const enrolledProgramId = _.get(enrolledProgram, 'id');

  return {
    1: (
      <OnBoardContainer
        toNextStep={localThis.toNextStep}
        resetDraftState={resetAfterSaveDraft}
        user={user}
        userId={userId}
        childProps={childProps}
        setChildProps={setChildProps}
        {...props}
        currentProgram={enrolledProgram}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
    ),
    2: (
      <LabResInWorkListContainer
        routeParams={{ userId: parsedId }}
        {...props}
        router={{ goBack: () => {}, setRouteLeaveHook: () => {} }}
        hideAddButton={true}
        userId={parsedId}
        toNextStep={localThis.toNextStep}
      />
    ),
    3: (
      <VitalsAndBillingComponent
        user={user}
        userId={userId}
        resetDraftState={resetAfterSaveDraft}
        toNextStep={localThis.toNextStep}
        {...props}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        setChildProps={setChildProps}
        childProps={childProps}
      />
    ),
    4: (
      <MedicationAddAndListContainer
        patientId={userId}
        toNextStep={localThis.toNextStep}
        {...props}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
    ),
    5: (
      <FollowUpVisitComponent
        user={user}
        userId={userId}
        toNextStep={localThis.toNextStep}
        {...props}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
    ),
    7: (
      <NutritionComponent
        {...props}
        toNextStep={localThis.toNextStep}
        resetDraftState={resetAfterSaveDraft}
        childProps={childProps}
        setChildProps={setChildProps}
        enrolledProgram={enrolledProgram}
        enrolledProgramId={enrolledProgramId}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
    ),
    8: (
      <GoalComponent
        {...props}
        toNextStep={localThis.toNextStep}
        resetDraftState={resetAfterSaveDraft}
        childProps={childProps}
        setChildProps={setChildProps}
        enrolledProgram={enrolledProgram}
        enrolledProgramId={enrolledProgramId}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
    ),
    9: (
      <ChartingComponent
        user={user}
        userId={userId}
        toNextStep={localThis.toNextStep}
        resetDraftState={resetAfterSaveDraft}
        {...props}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        childProps={childProps}
        setChildProps={setChildProps}
      />
    )
  };
};
let WorkListComponent = class extends React.Component {
  constructor(props) {
    const rdWorkingList = _.get(props,'visit.rdWorkingList');
    const maWorkingList = _.get(props,'visit.maWorkingList');
    const MAWorkingList = _.get(map, 'init.MAWorkingList');
    const RDWorkingList = _.get(map, 'init.RDWorkingList');
    const firstIncompleteIndex = () => {
      let firstIncompleteIndex = 1;
      if (!rdWorkingList) return firstIncompleteIndex;
      for (let [key, value] of Object.entries(
        _.pick(maWorkingList, MAWorkingList)
      )) {
        if (value != 'COMPLETED') {
          return firstIncompleteIndex;
        }
        firstIncompleteIndex++;
      }
      firstIncompleteIndex++;
      if (!maWorkingList) return firstIncompleteIndex;
      for (let [key, value] of Object.entries(
        _.pick(rdWorkingList, RDWorkingList)
      )) {
        if (value != 'COMPLETED') {
          return firstIncompleteIndex;
        }
        firstIncompleteIndex++;
      }
      firstIncompleteIndex++;
      return firstIncompleteIndex;
    };
    const firstIncompleteIndexVal = firstIncompleteIndex();
    super();
    this.state = {
      MACurrent: firstIncompleteIndexVal,
      currentStep: IndexToMap[firstIncompleteIndexVal],
      showMAWorkingList: true,
      showRDWorkingList: true,
      MADoneCount: 0,
      RDCurrent: 0,
      showMASteps: true,
      showRDSteps: false,
      showTaskFinished: false,
      isEditMode: false,
      childProps: false,
      parseValue: null,
      query: null,
      refetchQueries: null,
      draftType: null,
      maWorkingListStatus: maWorkingList,
      rdWorkingListStatus: rdWorkingList,
    };
  }

  setIsEditMode = flag => {
    if (!flag) {
      Mixpanel.track('clicked', 'done', this.state.currentStep + ' step', '');
    }
    this.setState({
      isEditMode: flag
    });
  };

  checkMAFinished = setState => {
    const maWorkingList = _.get(this, 'props.visit.maWorkingList', _.get(this, 'state.maWorkingListStatus'));
    let MATaskCount = 5;
    let MACount = 0;
    for (let [key, value] of Object.entries(maWorkingList || {})) {
      if (value == 'COMPLETED') {
        MACount++;
      }
    }
    if (MACount == MATaskCount) {
      if (setState) {
        this.setState({
          showTaskFinished: 'MA',
          MACurrent: null,
          currentStep: false
        });
      }
      Mixpanel.track('showed', 'MA working list', 'done page', '');
      return true;
    }
  };

  checkRDFinished = setState => {
    const rdWorkingList = _.get(this, 'props.visit.rdWorkingList', _.get(this, 'state.rdWorkingListStatus'));
    let RDTaskCount = 3;
    let RDCount = 0;
    for (let [key, value] of Object.entries(rdWorkingList || {})) {
      if (value == 'COMPLETED') {
        RDCount++;
      }
    }
    if (RDCount == RDTaskCount) {
      if (setState) {
        this.setState({
          showTaskFinished: 'RD',
          MACurrent: null,
          currentStep: false
        });
      }
      Mixpanel.track('showed', 'RD working list', 'done page', '');
      return true;
    }
  };

  checkAllTaskFinished = (MAWorkingListKey, RDWorkingListKey) => {
    const { MACurrent } = this.state;
    const maFinished = MAWorkingListKey ? this.checkMAFinished(true) : false;
    const rdFinished = RDWorkingListKey ? this.checkRDFinished(true) : false;
    if (!maFinished && !rdFinished) {
      this.setState({
        MACurrent: MACurrent + 1,
        currentStep: IndexToMap[MACurrent + 1]
      });
    }
    if (maFinished && rdFinished) {
      this.setState({
        MACurrent: null,
        currentStep: false
      });
    }
  };

  toNextStep = (MAWorkingListKey, RDWorkingListKey, msg) => {
    // const { MACurrent, MADoneCount } = this.state;
    const id = _.get(visit,'id',_.get(this,'props.id'));
    const { visit } = _.get(this, 'props');
    const MAWorkingList = _.get(map, 'init.MAWorkingList');
    const RDWorkingList = _.get(map, 'init.RDWorkingList');
    let maWorkingList = _.get(this, 'props.visit.maWorkingList', _.get(this, 'state.maWorkingListStatus', {}));
    let rdWorkingList = _.get(this, 'props.visit.rdWorkingList', _.get(this, 'state.rdWorkingListStatus', {}));
    let parsedMaWorkingList = {};
    let parsedRDWorkingList = {};

    for (let [index, key] of Object.entries(MAWorkingList)) {
      _.set(parsedMaWorkingList, key, (key === MAWorkingListKey) ? 'COMPLETED' : _.get(maWorkingList, key, 'TODO'));
    }

    for (let [index, key] of Object.entries(RDWorkingList)) {
      _.set(parsedRDWorkingList, key, (key === RDWorkingListKey) ? 'COMPLETED' : _.get(rdWorkingList, key, 'TODO'));
    }

    const updatedMaWorkingList = _.pick(parsedMaWorkingList, MAWorkingList);
    const updatedRdWorkingList = _.pick(parsedRDWorkingList, RDWorkingList);
    Client.mutate({
      mutation: updateVisitWorkingList,
      variables: {
        id,
        maWorkingList: updatedMaWorkingList,
        rdWorkingList: updatedRdWorkingList,
      },
      refetchQueries: [
        {
          query: singleVisit,
          variables: {
            id
          },
          awaitRefetchQueries: true,
          fetchPolicy: 'network-only'
        }
      ]
    })
      .then(res => {
        this.setState({ maWorkingListStatus: updatedMaWorkingList, rdWorkingListStatus: updatedRdWorkingList }, () => {
          message.success(msg);
          this.checkAllTaskFinished(MAWorkingListKey, RDWorkingListKey);
        })
      })
      .catch(e => { Modal.error({ content: e.message }); });
  };

  resetDraftState = (MACurrent, currentStep) => {
    this.setState({
      MACurrent,
      currentStep,
      isEditMode: false,
      childProps: false,
      parseValue: null,
      query: null,
      refetchQueries: null,
      draftType: null
    });
  };

  resetAfterSaveDraft = () => {
    this.setState({
      isEditMode: false,
      childProps: false,
      parseValue: null,
      query: null,
      refetchQueries: null,
      draftType: null
    });
  };

  saveDraft = (MACurrent, currentStep) => {
    const {
      childProps,
      query,
      refetchQueries = [],
      parseValue,
      draftType
    } = this.state;
    Client.mutate({
      mutation: query,
      variables: parseValue(childProps),
      refetchQueries
    })
      .then(res => {
        this.resetDraftState(MACurrent, currentStep);
        message.success(`${draftType} draft is saved.`);
      })
      .catch(e => {
        Modal.error({
          content: e.message
        });
      });
  };

  onChangeMAStep = MACurrent => {
    const index = $(MACurrent.target)[0].innerText;
    // <<<<<<< adam-sc-2499
    const { isEditMode, currentStep } = this.state;
    //        this.setState({ MACurrent: mapToIndex[index],currentStep:index });
    Mixpanel.track('clicked', index, 'initial visit pop up menu', '');
    //        const { isEditMode } = this.state;
    const { saveDraft } = this;
    if (currentStep == index) {
      return;
    }
    if (isEditMode) {
      Modal.confirm({
        title: 'Do you want to save the draft before you leave?',
        content: <div></div>,
        onOk() {
          saveDraft(mapToIndex[index], index);
        },
        onCancel() {}
      });
    } else {
      this.setState({ MACurrent: mapToIndex[index], currentStep: index });
    }
  };

  changeShowMAWorkingList = () => {
    const { showMAWorkingList } = this.state;
    this.setState({
      showMAWorkingList: !showMAWorkingList
    });
    Mixpanel.track('clicked', 'ma worklist', 'initial visit pop up menu', '');
  };

  setChildProps = (
    childProps,
    parseValue,
    query,
    refetchQueries,
    draftType
  ) => {
    this.setState({
      childProps,
      parseValue,
      query,
      refetchQueries,
      draftType
    });
  };
  changeShowRDWorkingList = () => {
    const { showRDWorkingList, childProps } = this.state;
    this.setState({
      showRDWorkingList: !showRDWorkingList
    });
    Mixpanel.track('clicked', 'rd worklist', 'initial visit pop up menu', '');
  };
  renderForm = () => {
    const { MACurrent } = this.state;
    return renderMap(this)[MACurrent];
  };

  renderMASteps = () => {
    try {
      const { MACurrent, showMAWorkingList, currentStep } = this.state;
      const maWorkingList = _.get(this, 'props.visit.maWorkingList', _.get(this, 'state.maWorkingListStatus', {}));
      const { steps, direction } = MAWorkListMap(this);
      const allTaskFinished = this.checkMAFinished();
      const className = allTaskFinished && showMAWorkingList ? 'allCompleted' : '';
      return (
        <Steps direction={direction} current={MACurrent} className={className}>
          {_.map(steps, ({ title, className, icon, key, onClick }, i) => {
            if (i == 0) {
              return (
                <Step
                  title={title}
                  key={key}
                  className={className}
                  icon={icon(allTaskFinished)}
                  onClick={e => onClick(e)}
                />
              );
            }
            const status = _.get(maWorkingList, workListKeyMap[key]);
            return (
              <Step
                title={title}
                key={key}
                className={
                  className + ` ${currentStep == title ? 'selected' : ''}`
                }
                icon={icon(status)}
                onClick={e => onClick(e)}
              />
            );
          })}
        </Steps>
      );
    } catch (e) {
      console.log(e);
    }
  };
  renderRDSteps = () => {
    const { MACurrent, showRDWorkingList, currentStep } = this.state;
    const rdWorkingList = _.get(this, 'props.visit.rdWorkingList', _.get(this, 'state.rdWorkingListStatus', {}));
    const { steps, direction } = RDWorkingList(this);
    const allTaskFinished = this.checkRDFinished();
    const className = allTaskFinished && showRDWorkingList ? 'allCompleted' : '';
    return (
      <Steps direction={direction} current={MACurrent} className={className}>
        {_.map(steps, ({ title, className, icon, key, onClick }, i) => {
          if (i == 0) {
            return (
              <Step
                title={title}
                key={key}
                className={className}
                icon={icon(allTaskFinished)}
                onClick={e => onClick(e)}
              />
            );
          }
          const status = _.get(rdWorkingList, workListKeyMap[key]);
          return (
            <Step
              title={title}
              key={key}
              className={
                className + ` ${currentStep == title ? 'selected' : ''}`
              }
              icon={icon(status)}
              onClick={e => onClick(e)}
            />
          );
        })}
      </Steps>
    );
  };

  renderHeader = props => {
    const { type, reason, member } = props;
    const fullName = _.get(member, 'profile.fullName');
    return (
      <Row>
        <Col span={24}>
          <h4>{`${visitMap[type]}: ${fullName}`}</h4>
        </Col>
        <Col span={24} style={{ paddingRight: 10 }}>
          <span>{`Notes: ${reason || ''}`}</span>
        </Col>
      </Row>
    );
  };
  renderConditionList = () => {
    const res = [];
    _.forEach(_.get(this, 'props.visit.conditionsList', []), (v, index) =>
      v === true ? res.push(index) : ''
    );
    const conditionStr = res.join(', ');
    return res.length != 0 ? (
      <div style={{ paddingTop: 10, paddingBottom: 10, paddingRight: 10 }}>
        <span
          style={{ marginTop: 10 }}
        >{`Condition List: ${conditionStr}`}</span>
      </div>
    ) : (
      ''
    );
  };
  setShowTaskFinished = flag => {
    this.setState({
      showTaskFinished: flag
    });
  };
  render() {
    const { props, state, setShowTaskFinished } = this;
    const { showTaskFinished, MACurrent } = state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Row>
          <Col span={6}>
            {this.renderHeader(props)}
            {this.renderConditionList()}
            {this.renderMASteps()}
            {this.renderRDSteps()}
          </Col>
          <Col span={18}>
            {showTaskFinished && !MACurrent ? (
              <AllTaskFinishedComponent
                type={showTaskFinished}
                setShowTaskFinished={setShowTaskFinished}
              />
            ) : (
              this.renderForm()
            )}
          </Col>
        </Row>
      </div>
    );
  }
};

// WorkListComponent = Form.create()(WorkListComponent);
export default withRouter(WorkListComponent);
