import React from 'react';
import _ from 'lodash'
import moment from 'moment'
import I18N from 'modulesAll/I18N'
import { convertBG_mmolTomgdl } from 'libModule/utils/convertUnits';
import {
  VITAL_THRESHOLD_KEY_MAP_ONE,
  VITAL_THRESHOLD_KEY_MAP_TWO,
  VITAL_THRESHOLD_LABEL_MAP,
  DATE_UNIT_MAP,
  HS_FULL_NAME,
  HR_FULL_NAME,
  BG_FULL_NAME,
  BP_FULL_NAME,
  VITALS_WITH_SINGLE_TYPE,
  VITAL_WEIGHT_TYPE,
  VITAL_THRESHOLD_UNIT_MAP,
  BG_MEAL_TYPES,
} from 'modulesAll/utils/constants/task'
import { WeeklyMealScheduleInputGrid,IHLoading } from 'ihcomponent';
import PropTypes from 'prop-types';
import * as R from 'ramda'

import { Spin } from 'antd';
import { branch, renderComponent } from 'recompose';

const debug = true;
const traceF = (label) => R.tap((x)=>console.log(label, x));
export const trace = debug ? traceF : () => R.identity;

const Spinner = () => <IHLoading/>
//     (
//   <div style={{"textAlign":"center","background":"rgba(0,0,0,0.05)","borderRadius":"4px","marginBottom":"20px","padding":"30px 50px","margin":"20px 0"}} >
//     <Spin />
//   </div>
// );

// samples usage in compose:
// spinnerWhileLoading(R.prop('labResultTemplateManyLoading'))
export const spinnerWhileLoading = isLoadingF =>
  branch(
    isLoadingF,
    renderComponent(Spinner)
  )

export const renderThresholdsTitle = () => {
  return (
    <div className='vital-threshold-title'>
      <span>Thresholds</span>
      <span className='critical low'>CL</span>
      <span className='over low'>L</span>
      <span className='normal'>Normal</span>
      <span className='over high'>H</span>
      <span className='critical high'>CH</span>
    </div>
  )
}

const renderNumber = (num) => Math.round(num * 100) / 100

const getCLowRange = ({vitalType, step, key, thresholdValue, isWeightBaseline}) => {
  const [TCLow, TLow, THigh, TCHigh, baselineValue] = thresholdValue
  const convertedTCLow = vitalType === 'Blood Glucose' ? convertBG_mmolTomgdl(TCLow) : TCLow;
  return _.isNumber(convertedTCLow)
    ? <div key={key+'clowRange'} className='critical low'>&le;{renderNumber(convertedTCLow+(isWeightBaseline? baselineValue || 0 : 0))}</div> : ''
}
const getLowRange = ({vitalType, step, key, thresholdValue, isWeightBaseline}) => {
  const [TCLow, TLow, THigh, TCHigh, baselineValue] = thresholdValue
  let lowRange = ''
  const convertedTCLow = vitalType === 'Blood Glucose' ? convertBG_mmolTomgdl(TCLow) : TCLow;
  const convertedTLow = vitalType === 'Blood Glucose' ? convertBG_mmolTomgdl(TLow) : TLow;
  const onlyTLowElem = <div key={key+'lowRange'} className='over low'>{convertedTLow - step -step != convertedTCLow && '\u2264'}{renderNumber(convertedTLow-step+(isWeightBaseline? baselineValue || 0 : 0))}</div>
  const rangeElem = <div key={key+'lowRange'} className='over low'>{`${renderNumber(convertedTCLow+step)}-${renderNumber(convertedTLow-step)}`}</div>
  if ((!_.isNumber(convertedTCLow) && _.isNumber(convertedTLow)) || vitalType === HS_FULL_NAME || convertedTLow - step -step === convertedTCLow) {
    lowRange = onlyTLowElem
  } else if (_.isNumber(convertedTCLow)) {
    lowRange = rangeElem
  }
  return lowRange
}
const getNormalRange = ({vitalType, step, key, thresholdValue, isWeightBaseline, isBaselineExist}) => {
  const [TCLow, TLow, THigh, TCHigh, baselineValue] = thresholdValue
  const unitMap = VITAL_THRESHOLD_UNIT_MAP
  let normalRange = ''
  const convertedTLow = vitalType === 'Blood Glucose' ? convertBG_mmolTomgdl(TLow) : TLow;
  const convertedTHigh = vitalType === 'Blood Glucose' ? convertBG_mmolTomgdl(THigh) : THigh;
  if (vitalType === HS_FULL_NAME) {
    const baselineValueFinal = isBaselineExist ? `(${baselineValue||''}lbs)` : ''
    normalRange = isWeightBaseline ? `${VITAL_WEIGHT_TYPE['Baseline']} ${baselineValueFinal}` : `${VITAL_WEIGHT_TYPE['LastMeasurement']} (lbs)`
  } else if (convertedTLow && convertedTHigh) {
    normalRange = `${convertedTLow}-${convertedTHigh} ${unitMap[key]}`
  }
  return (
    <div key={key+'normalRange'} className='normal'>
      {normalRange}
    </div>
  )
}
const getHighRange = ({vitalType, step, key, thresholdValue, isWeightBaseline}) => {
  const [TCLow, TLow, THigh, TCHigh, baselineValue] = thresholdValue
  let highRange = ''
  const convertedTHigh = vitalType === 'Blood Glucose' ? convertBG_mmolTomgdl(THigh) : THigh;
  const convertedTCHigh = vitalType === 'Blood Glucose' ? convertBG_mmolTomgdl(TCHigh) : TCHigh;
  const onlyTHighElem = <div key={key+'highRange'} className='over low'>{convertedTHigh + step + step != convertedTCHigh && '\u2265'}{renderNumber(convertedTHigh+step+(isWeightBaseline? baselineValue || 0 : 0))}</div>
  const rangeElem = <div key={key+'highRange'} className='over low'>{`${renderNumber(convertedTHigh+step)}-${renderNumber(convertedTCHigh-step)}`}</div>
  if ((!_.isNumber(convertedTCHigh) && _.isNumber(convertedTHigh)) || vitalType === HS_FULL_NAME || convertedTHigh + step + step === convertedTCHigh) {
    highRange = onlyTHighElem
  } else if (_.isNumber(convertedTCHigh)) {
    highRange = rangeElem
  }
  return highRange
}
const getCHighRange = ({vitalType, step, key, thresholdValue, isWeightBaseline}) => {
  const [TCLow, TLow, THigh, TCHigh, baselineValue] = thresholdValue
  const convertedTCHigh = vitalType === 'Blood Glucose' ? convertBG_mmolTomgdl(TCHigh) : TCHigh;
  return _.isNumber(convertedTCHigh)
    ? <div key={key + 'chighRange'} className='critical high'>&ge;{renderNumber(convertedTCHigh+(isWeightBaseline? baselineValue || 0 : 0))}</div> : ''
}

const getThresholdStep = (vitalType) => {
  let step = 0.1
  if (vitalType === BP_FULL_NAME || vitalType === HR_FULL_NAME) {
    step = 1
  } else if (vitalType === HS_FULL_NAME) {
    step = 0
  }
  return step
}

// VS-1949
const getKeys = (row, measure) => {
  const vitalType = _.get(row, 'vitalType')
  const keyMapOne = VITAL_THRESHOLD_KEY_MAP_ONE
  const keyMapTwo = VITAL_THRESHOLD_KEY_MAP_TWO
  // from measurement and BG
  if (measure && vitalType === BG_FULL_NAME) {
    return measure.beforeMeal ? [keyMapOne[vitalType]] : [keyMapTwo[vitalType]]
  }
  return VITALS_WITH_SINGLE_TYPE.indexOf(vitalType) !== -1
    ? [keyMapOne[vitalType]] : [keyMapOne[vitalType], keyMapTwo[vitalType]]
}

export const renderThresholds = (value, row, index, measure) => {
  const vitalType = _.get(row, 'vitalType')
  const isBaselineExist = _.get(row, 'isBaselineExist')
  const isWeightBaseline = vitalType === HS_FULL_NAME && _.get(row, 'weightBasedOn') === VITAL_WEIGHT_TYPE['Baseline']
  const labelMap = VITAL_THRESHOLD_LABEL_MAP
  const keys = getKeys(row, measure) // VS-1949
  const step = getThresholdStep(vitalType)
  return (<div>
      {
        keys.map(key => {
          const label = labelMap[key]
          const thresholdValue = row[key]
          const params = {vitalType, step, key, thresholdValue, isWeightBaseline, isBaselineExist}
          const clowRange = getCLowRange(params)
          const lowRange = getLowRange(params)
          const normalRange = getNormalRange(params)
          const highRange = getHighRange(params)
          const chighRange = getCHighRange(params)
          const elem = (
            <div key={key} className='vital-threshold-title'>
              {label}
              {clowRange}
              {lowRange}
              {normalRange}
              {highRange}
              {chighRange}
            </div>
          )
          return elem
      })
    }
  </div>)
}

export const renderSchedule = (value, row, index,detailRender) => {
  const result = {
    children: []
  }
  if (value.frequency === 'slot') {
    if (row.vitalType === BG_FULL_NAME) {
      const scheduleTemplate = _.get(value,'weeklyMealSchedule');
      if(scheduleTemplate){
        //console.log("BG SCHEDULE:",value, "\nROW:", row, "\nNAME:",value.weeklyMealSchedule.name,"Component:", WeeklyMealScheduleInputGrid);
        const schedule = _.get(scheduleTemplate,'schedule');
        const item = scheduleTemplate.name;
        const detail = schedule ? (<WeeklyMealScheduleInputGrid value={ schedule } allowEdit={false}/>)
                               :scheduleTemplate.description;
        result.children.push(<div key={index}
                                style={{width:'fit-content'}}  onMouseEnter={_.get(detailRender,'render')?()=>detailRender.render(detail):null}
                              >
                                    {item}
                              </div>)
      }else {
        const currentMealTypes = value.timeSlotValueForMeal
        BG_MEAL_TYPES.forEach((mealValue, index) => {
          if (currentMealTypes.indexOf(mealValue) !== -1) {
            const timeElem = <div key={index}>{mealValue.replace(/_/g, ' ').toTitleCase()}</div>
            return result.children.push(timeElem)
          }
        })
      }

    } else {
      value.timeSlotValue.forEach((scheduleTime, index) => {
        const timeElem = <div key={index}>Time Slot {index+1} ({scheduleTime.value[0].format('hh:mm a')} - {scheduleTime.value[1].format('hh:mm a')})</div>
        result.children.push(timeElem)
      })
    }
  } else {
    const val = value.frequencyValue.times
    const unitMap = DATE_UNIT_MAP
    const unit = unitMap[_.get(value, 'frequencyValue.unit')]
    const valStr = val === 1? `Once a ${unit}` : val === 2? `Twice a ${unit}`: `${val} times a ${unit}`
    result.children.push(valStr)
  }
  return result
}

/*
  Attach the schedule details to each of assessments or surveys
  if it is a scheduled program task, otherwise attach 'None'
  Params:
    - list: list of assessments or surveys
    - tasks: program.tasks
*/
export const formatSingleAssSurScheduleArray = task => Array.from((_.get(task, 'schedule[0].calendar', [])) || []).reverse().join(', ').toTitleCase()
export const formatSingleAssSurSchedule = task => Array.from((_.get(task, 'schedule.calendar', [])) || []).reverse().join(', ').toTitleCase()
export const attachScheduleDetails = (list, tasks) => {
  list = list || []
  tasks = tasks || []
  const assessmentOrSurveyTasks = tasks.filter(v => ["ASSESSMENT", "SURVEY"].includes(v.type.toUpperCase()))
  const scheduleById = {}
  assessmentOrSurveyTasks.forEach(v => scheduleById[_.get(v, 'assessment.id')] = formatSingleAssSurScheduleArray(v))
  return list.map(v => ({ ...v, schedule: _.get(scheduleById, _.get(v, 'id'), 'None') }))
}

export const formatSingleAssSurAlert = task => {
  const chances = _.get(task, 'chances')
  if (chances && typeof chances === 'number') {
    switch (chances) {
      case 0: return 'Never'
      case 1: return 'Alert when missed once'
      case 2: return 'Alert when missed twice'
      default: return `Alert when missed ${chances} times`
    }
  }
  return 'Never'
}
export const onChangeTable = (props) => (pagination, filters, sorter) => {
  const { currentPage, onChangeTest, changePage, sort } = props
  if(onChangeTest) {
    //console.log(pagination, filters, sorter)
    onChangeTest()
  }
  else {
    if(pagination && (pagination.current !== currentPage)) {
      changePage(pagination.current)
    }
    else {
      sort(sorter)
    }
  }
}

const sortedColumns = ({columns, sorter}) => {
  if(!sorter) { return columns }
  return columns.map( (c) => {
    if(sorter.field === c.dataIndex) {
      c['sortOrder'] = sorter.order
    }
    return c
  })
}

const i18nTitle = (child, key) => {
  // Recursively resolve children until all names are replaced under the dom tree
  if (child === null) return null
  if (typeof child === 'string') {
    return I18N.get(key)[child]
  } else if (child.props.children && child.props.children.constructor === Array) {
    return {...child, props: {...child.props, children: child.props.children.map(d =>i18nTitle(d, key))}}
  } else if (child.props.children && typeof child.props.children === 'string') {
    const title = child.props.children
    return {...child, props: {...child.props, children: I18N.get(key)[title]}}
  } else { // other types
    return child
  }
}

export const getTableProps = (props, {columns, title, keyField, inputPlaceholder}) => {
  const { dataSource, loading, sorter, pagination, onRow, onSearch } = props
  keyField = keyField || 'id'
  return {
    dataSource,
    pagination,
    loading,
    onRow,
    columns: sortedColumns({columns, sorter}),//.map(col => ({...col, title: i18nTitle(col.title, 'columnTitle')})),
    rowKey: record => record[keyField],
    onChange: onChangeTable(props),
    title: i18nTitle(title, 'tableTitle'),
    onSearch,
    inputPlaceholder,
  }
}

export const noDataElem = (
  <div style={{ height: '150px', paddingTop: '2em' }}>
    <p style={{ fontSize: '18px' }}>There is no data available</p>
  </div>
);

export const commonTablePropTypes = {
  dataSource: PropTypes.array,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  onRow: PropTypes.func,
}

export const i18nTableColumns = (tableProps) => {
  return {
    ...tableProps,
    columns: tableProps.columns.map(col => ({
      ...col,
      title: i18nTitle(col.title, 'columnTitle')
    }))
  }
}

export const i18nPanel = (props, key) => {
  props.header.title = i18nTitle(props.header.title, key)
  return props
}

export const i18nRadioButton = (props, key) => {
  props.option.forEach(op => op.name=i18nTitle(op.name, key))
  return props
}

export const formatDate = (dateObj) => moment(dateObj).format('MMM-DD-YYYY hh:mm a');
export const formatDateMM24Date = (dataObj) =>moment(dataObj).format('MM/DD/YYYY hh:mm');
/** Blood Glucose - Takes in 'mmol/l' format, renders in 'mg/dl' **/
export const renderRangeValueResultForBG = (range, miu) => {
  if(!range || !range.length) return null;
  function renderNumber(num) {
      return Math.round(convertBG_mmolTomgdl(num) * 100) / 100
  }

  const loop = (i)=>{
    let v, cn = ''
    if(i === 0){
      // critical low
      if(range[0] === null){
        cn = 'v-20-white'
      }
      else{
        v = `&#x2264; ${renderNumber(parseFloat(range[0], miu))}`
      }
    }
    else if(i === 1){
      // low
      if(range[0] === null){
        v = `&#x2264; ${renderNumber(parseFloat(range[1])).toFixed(1) - miu}`
        cn = 'v-40'
      }
      else{
          const lowRange = {
            lowerBound: (parseFloat(range[0])+miu).toFixed(miu == 0.1 ? 1 : 0),
            upperBound: (parseFloat(range[1])-miu).toFixed(miu == 0.1 ? 1 : 0)
          }
          // console.log('lowRange: ', lowRange);
          v =  lowRange.lowerBound === lowRange.upperBound ? `${renderNumber(lowRange.lowerBound)}` :
            `${renderNumber(lowRange.lowerBound)} - ${renderNumber(lowRange.upperBound)}`
        }
      }
    else if(i === 2){
      v = `${ renderNumber(parseFloat(range[1])) } - ${ renderNumber(parseFloat(range[2])) }`;
    }
    else if(i === 3){
      if(range[3] === null){
        v = `≥ ${+renderNumber(parseFloat(range[2])).toFixed(1) + miu}`
        cn = 'v-20-orange'
      }
      else{
        const highRange = {
          lowerBound: (parseFloat(range[2])+miu).toFixed(miu == 0.1 ? 1 : 0),
          upperBound: (parseFloat(range[3])-miu).toFixed(miu == 0.1 ? 1 : 0)
        }
        v =  highRange.lowerBound === highRange.upperBound ? `${renderNumber(highRange.lowerBound)}` :
          `${renderNumber(highRange.lowerBound)} - ${renderNumber(highRange.upperBound)}`
      }
    }
    else{
      if(range[3] === null){
        cn = 'v-20-white'
      }
      else{
        v = `≥ ${renderNumber(parseFloat(range[3])) + miu}`
      }
    }

    // console.log('v: ', v);
    return <span key={i} className={`v-c v-c${i+1} ${cn}`} dangerouslySetInnerHTML={{__html:v}}></span>
  }

  return (
    <div className="v-d-cv">
      {
        _.map(new Array(range.length + 1), (undef, i)=>{
          return loop(i)
        })
      }
    </div>
  )
}

export const renderRangeValueResult = (range, miu, isWeight, baselineWeight, isBaseline) => {
  if(!range || !range.length) return null
  function renderNumber(num) { return Math.round(num * 100) / 100 }
  const baselineWeightValue = isWeight && baselineWeight ? baselineWeight.value : 0
  const loop = (i)=>{
    let v, cn = ''
    if(i === 0){
      // critical low
      if(range[0] === null){
        cn = 'v-20-white'
      }
      else{
        v = isWeight ? `&#x2264; ${renderNumber(parseFloat(range[0])-miu+baselineWeightValue)}` : `&#x2264; ${renderNumber(parseFloat(range[0]))}`
      }
    }
    else if(i === 1){
      // low
      if(range[0] === null){
        const finalNum = renderNumber(parseFloat(range[1])) - miu;
        v = isWeight ? `&#x2264; ${renderNumber(parseFloat(range[1])-miu+baselineWeightValue)}` : `&#x2264; ${finalNum.toFixed(1)}`
        cn = 'v-40'
      }
      else{
        //v = `${range[0]} - ${range[1]}`
        if (isWeight) {
          v = `&#x2264; ${renderNumber(parseFloat(range[1])-miu+baselineWeightValue)}`
        } else {
          const lowRange = {
            lowerBound: (parseFloat(range[0])+miu).toFixed(miu == 0.1 ? 1 : 0),
            upperBound: (parseFloat(range[1])-miu).toFixed(miu == 0.1 ? 1 : 0)
          }
          v =  lowRange.lowerBound === lowRange.upperBound ? `${renderNumber(lowRange.lowerBound)}` :
                  `${renderNumber(lowRange.lowerBound)} - ${renderNumber(lowRange.upperBound)}`
        }
      }
    }
    else if(i === 2){
      if (isWeight) {
        v = isBaseline? (baselineWeight ? baselineWeightValue : 'Baseline') : 'Last Measurement'
      } else {
        v = `${parseFloat(range[1]).toFixed(1)} - ${parseFloat(range[2]).toFixed(1)}`
      }

    }
    else if(i === 3){
      if(range[3] === null){
          const num = renderNumber(parseFloat(range[2])) + miu
          v = isWeight ? `≥ ${renderNumber(parseFloat(range[2])+miu+baselineWeightValue)}` : `≥ ${ num.toFixed(1) }`
          cn = 'v-20-orange'
      }
      else{
        //v = v = `${range[2]} - ${range[3]}`
        if (isWeight) {
          v = `≥ ${renderNumber(parseFloat(range[2])+miu+baselineWeightValue)}`
        } else {
          const highRange = {
            lowerBound: (parseFloat(range[2])+miu).toFixed(miu == 0.1 ? 1 : 0),
            upperBound: (parseFloat(range[3])-miu).toFixed(miu == 0.1 ? 1 : 0)
          }
          v =  highRange.lowerBound === highRange.upperBound ? `${renderNumber(highRange.lowerBound)}` :
                   `${renderNumber(highRange.lowerBound)} - ${renderNumber(highRange.upperBound)}`
        }
      }
    }
    else{
      if(range[3] === null){
        cn = 'v-20-white'
      }
      else{
        v = isWeight ? `≥ ${renderNumber(parseFloat(range[3])+miu+baselineWeightValue)}` : `≥ ${renderNumber(parseFloat(range[3])).toFixed(1)}`
      }
    }


    return <span key={i} className={`v-c v-c${i+1} ${cn}`} dangerouslySetInnerHTML={{__html:v}}></span>
  }

  return (
    <div className="v-d-cv">
      {
        _.map(new Array(range.length + 1), (undef, i)=>{
          return loop(i)
        })
      }
    </div>
  )

}
