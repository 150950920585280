import { React } from 'ihcomponent';
import { DatePicker, TimePicker, Tooltip  } from 'antd';
import {
  Form, Select, InputNumber, Switch, Radio,
  Slider, Button, Upload, Icon,
  Row, Col,Checkbox
} from 'antd';
import { Table, Input, Popconfirm } from 'antd';
import moment from 'moment';
import * as R from 'ramda';

import './labresults.css';
import { compose,  withProps } from 'recompose';
import { trace } from './utils';
import Parser from 'html-react-parser';
import panelWithInHouse from '../constants/panelWithInHouse';
const debug = false;

const MonthPicker = DatePicker.MonthPicker;
const RangePicker = DatePicker.RangePicker;
const TextArea = Input.TextArea;
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class EditableCell extends React.Component {
  state = {
    value: this.props.value,
    editable: false,
  }
  handleChange = (e) => {
    const value = e.target.value;
    this.setState({ value });
  }
  check = () => {
    this.setState({ editable: false });
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  }
  edit = () => {
    this.setState({ editable: true });
  }
  render() {
    const { value, editable } = this.state;
    return (
      <div className="editable-cell" style={{ wordBreak: 'normal' }}>
        {
          editable ?
            <div className="editable-cell-input-wrapper">
              <Input
                value={value}
                onChange={this.handleChange}
                onPressEnter={this.check}
              />
              <Icon
                type="check"
                className="editable-cell-icon-check"
                onClick={this.check}
              />
            </div>
            :
            <div className="editable-cell-text-wrapper">
              {value || ' '}
              <Icon
                type="edit"
                className="editable-cell-icon"
                onClick={this.edit}
              />
            </div>
        }
      </div>
    );
  }
}

// show Input instead of EditableCell

const mkRender = ({ dataIndex, onCellChange, inputColumns },isCheckBox) => {

  return (text, record) => (
    R.contains(dataIndex, inputColumns)
    ? <Input onChange={ e => onCellChange(record.key, dataIndex)(e.target.value) } type={isCheckBox ? 'checkbox':''}/>
    : <EditableCell
        value={Parser(text)}
        onChange={onCellChange(record.key, dataIndex)}
      />
  )
};

const mkRenderEyeExam = ({ dataIndex, onCellChange, inputColumns })=>{
    return (text, record) => (
        R.contains(dataIndex, inputColumns)
            ? <TextArea onChange={ e => onCellChange(record.key, dataIndex)(e.target.value) } rows={6}/>
            : <EditableCell
                value={Parser(text)}
                onChange={onCellChange(record.key, dataIndex)}
            />
    )
};

const mkColumns = ({ templateId, deletePanel, onCellChange, onDelete, inputColumns }, isA1CTemplate) => {
  const main = [{
  title: 'Tests',
  dataIndex: 'name',
  width: isA1CTemplate ?'20%' :'30%',
  render: mkRender({ dataIndex: 'name', onCellChange, inputColumns }),
}, {
  title: 'Result',
  dataIndex: 'value',
  width: '10%',
  render: mkRender({ dataIndex: 'value', onCellChange, inputColumns }),
}, {
  title: 'Units',
  dataIndex: 'unit',
  render: mkRender({ dataIndex: 'unit', onCellChange, inputColumns }),
  }, {
  title: 'Flag',
  dataIndex: 'flag',
  width: '10%',
  render: mkRender({ dataIndex: 'flag', onCellChange, inputColumns }),
}, {
  title: 'Reference Interval',
  dataIndex: 'interval',
  width: '30%',
  render: mkRender({ dataIndex: 'interval', onCellChange, inputColumns })
},

  ]

    if(isA1CTemplate){
      main.push({
          title: 'In-house Test',
          dataIndex: 'isInHouse',
          render: mkRender({ dataIndex: 'isInHouse', onCellChange, inputColumns },true)
      })
    }

  
  // 20200213 Thong - add one more column for delete button
  main.push({
    title:
      <span style={{ cursor: 'pointer' }} onClick={ deletePanel.bind(null, templateId) }>
        <Tooltip placement='top' title='Delete Panel'>
          <Icon type="close-circle" theme='filled' style={{ fontSize: 23 }} />
        </Tooltip>
      </span>
    ,
    width: 25,
  });  

const addDeleteTestItem = onDelete
  ? R.append({
    render: (text, record) => (
      <Icon
        type="close-circle"
        onClick={e => onDelete(record.key) }
        style={{ color: '#999',
          fontSize: 22,
        }}
      />
    ),
  })
  : R.identity;
// const res = addDeleteTestItem(main);

return main;
};

const mkEyeExamColumns  = ({ templateId, deletePanel, onCellChange, onDelete, inputColumns })=>{
    const main = [{
        title: 'Tests',
          dataIndex: 'name',
          render: mkRenderEyeExam({ dataIndex: 'name', onCellChange, inputColumns }),
        },
        {
          title: 'Result',
          dataIndex: 'value',
          render: mkRenderEyeExam({ dataIndex: 'value', onCellChange, inputColumns }),
        }
    ];

    // 20200213 Thong - add one more column for delete button
    main.push({
      title:
        <span style={{ cursor: 'pointer' }} onClick={ deletePanel.bind(null, templateId) }>
          <Tooltip placement='top' title='Delete Panel'>
            <Icon type="close-circle" theme='filled' style={{ fontSize: 23 }} />
          </Tooltip>
        </span>
      ,
      width: 25,
    });  

    const addDeleteTestItem = onDelete
        ? R.append({
            render: (text, record) => (
                <Icon
                    type="close-circle"
                    onClick={e => onDelete(record.key) }
                    style={{ color: '#999',
                        fontSize: 22,
                    }}
                />
            ),
        })
        : R.identity;
    // const res = addDeleteTestItem(main);
    return main;
}

const EditableTable = ({ isCustomForm, panelTemplate, handleAdd, columns }) => (
  <div style={{ marginBottom: 5 }}>
    <Table pagination={false} dataSource={panelTemplate.items} columns={columns} style={{ border: 'solid 1.5px #aaaaaa', marginTop: 5, marginBottom: 5 }}
      footer={() => (
        !isCustomForm ? null :
        <Button style={{"fontSize":"13px","height":"28px","lineHeight":"1"}} className="editable-add-btn" type='primary' onClick={handleAdd}>+ Add Row</Button>
      )}
      />

  </div>
);

class TestPanelForm extends React.Component {
  render() {
    const { deletePanel, panelTemplate: { templateName, templateId }} = this.props;
    const styles = {};
    styles.button = {"fontSize":"13px","display":"flex","justifyContent":"center","lineHeight":"1"};
    const { onCellChange, onDelete, handleAdd, panelTemplate, updateTabSelected, labResultCreate }  = this.props;
    const isA1CTemplate = panelWithInHouse.indexOf(templateName)!=-1;
    const inputColumns = isA1CTemplate ? ['value', 'flag','isInHouse'] : ['value', 'flag'];
    const isEyeExamOrFootExam = templateId=='eye_exam' || templateId=='foot_exam';
    const mkFn = isEyeExamOrFootExam ? mkEyeExamColumns : mkColumns;

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
          {/* { R.isNil(templateName) ? 'no template name' : templateName } */}
          {/* <Button onClick={ deletePanel.bind(null, templateId) } type="danger" ghost style={styles.button}>
            Delete Panel
          </Button> */}
        </div>
        <EditableTable columns={mkFn({ templateId, deletePanel, onCellChange, inputColumns },isA1CTemplate)} {...{ onDelete, handleAdd, panelTemplate, updateTabSelected, labResultCreate }} />
      </div>
    );
  }
}

class CustomTestPanel extends React.Component {
  render() {
    const { deletePanel, deleteCustomPanel, panelTemplate: { templateName, templateId }} = this.props;
    const styles = {};
    styles.button = {"fontSize":"13px","display":"flex","justifyContent":"center","lineHeight":"1"};
    const inputColumns = ['name', 'value', 'unit', 'flag', 'interval'];
    const { onCellChange, onCellChangeRootPath, onDelete, handleAdd, panelTemplate, updateTabSelected, labResultCreate }  = this.props;

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
          <Input placeholder='Type custom panel name here' onChange={ e => onCellChangeRootPath([templateId, 'templateName'])(e.target.value) }
          style={{ width: 270, height: 36, fontSize: 14, paddingLeft: 17 }} />
        </div>
        <EditableTable isCustomForm columns={mkColumns({ templateId, deletePanel, onCellChange, onDelete, inputColumns })} {...{ onDelete, handleAdd, panelTemplate, updateTabSelected, labResultCreate }} />
      </div>
    );
  }
}

const enhance = compose(
  withProps(trace('TestPanelFormComponent props')),
);

export { TestPanelForm, CustomTestPanel };
