const alertLevel = {

    CDE:['LEVEL_ONE',
    'LEVEL_TWO',
    'LEVEL_THREE',
    'LEVEL_FOUR',
    'LEVEL_FIVE'],
    MA:['LEVEL_ONE',
        'LEVEL_TWO',
        'LEVEL_THREE',
        'LEVEL_FOUR']
}

const taskType = [ 'LEVEL_ONE_BG_CRITICAL_LOW',
    'LEVEL_ONE_BG_CONTINUE_HIGH',
    'LEVEL_ONE_BP_CRITICAL_LOW',
    'LEVEL_ONE_BP_CRITICAL_HIGH',
    'LEVEL_ONE_VISIT_DUE',
    'LEVEL_TWO_A1C_DUE',
    'LEVEL_TWO_BG_PAIR_READING',
    'LEVEL_THREE_VISIT_OVERDUE',
    'LEVEL_THREE_BG_PAIR_READING',
    'LEVEL_FOUR_A1C_OVERDUE',
    'LEVEL_FOUR_HIGH_FASTING_BG',
    'LEVEL_FOUR_HIGH_BG',
    'LEVEL_FOUR_HIGH_AVERAGE_BG',
    'LEVEL_FOUR_BG_TRENDING_HIGH',
    'LEVEL_FOUR_BG_TRENDING_DOWN',
    'LEVEL_FOUR_7_DAY_HYPERTENSION_STAGE_II',
    'LEVEL_FOUR_BP_TRENDING_HIGH',
    'LEVEL_FOUR_BP_TRENDING_DOWN',
    'LEVEL_FIVE_NO_PROVIDERNOTE_IN_SIX_WEEKS',
    'LEVEL_FIVE_BG_MISSING_PAIR',
    'LEVEL_FIVE_HUB_USER_MISSING_CALL_LOG'
];

const MATaskTypes = ['LEVEL_ONE_VISIT_DUE','LEVEL_TWO_A1C_DUE','LEVEL_THREE_VISIT_OVERDUE','LEVEL_FOUR_A1C_OVERDUE'];
const CDETypes = _.difference(taskType,MATaskTypes);

const filterType = {
    MA:MATaskTypes,
    CDE: CDETypes
}
const alertLevelColorMap = {
    'LEVEL_ONE':{
        color:'#FF0909',
        backgroundColor:'rgba(255, 9, 9,0.05)'
    },
    'LEVEL_TWO':{
        color:'#F73695',
        backgroundColor: 'rgba(247, 54, 149,0.05)'
    },
    'LEVEL_THREE':{
        color:'#FF7505',
        backgroundColor:'rgba(255, 117, 5,0.05)'
    },
    'LEVEL_FOUR':{
        color:'#7326DB',
        backgroundColor:'rgba(115, 38, 219,0.05)'
    },
    'LEVEL_FIVE':{
        color:'#26D3D2',
        backgroundColor:'rgba(38, 211, 210,0.05)'
    }
}

export default {
    alertLevel,
    alertLevelColorMap,
    taskType,
    filterType
}
