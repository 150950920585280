import {
    React, IHSearchTable, _, IHButton, moment
} from 'ihcomponent'
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { programConditionMap } from 'libModule/utils'
import Store from 'libModule/reduxStore'
import '../style/index.scss';

const Component = class extends React.Component {
    static displayName = 'organization/list'

    componentWillUnmount() {
        if (!new RegExp(/^(\/organization)/).test(Store.getState().routing.location.pathname)) {
            this.props.onLoadParams(null)
        }
    }

    render() {
        this.user = this.props.userData.user;
        if (this.user) {
            const p = this.getTableProps()
            return <IHSearchTable {...p} />
        }
        else {
            return '';
        }
    }

    getTableProps() {
        const { organizationList, loading, search, onTableSearch, onSearchEnter, sort = {}, onTableChange, page, filter } = this.props;
        const list = _.get(organizationList, 'data');
        const filters = _.get(organizationList, 'filters', []);
        const roleArray = this.user.allRoles;
        let dataSource = [];
        list.forEach(org => {
            let o = roleArray.find(r => r.organization.id == org.id);
            if (!o) {
                let rl = org.roles.find(r => ['Admin', 'Doctor', 'Nurse', 'Medical Assistant'].includes(r.name));
                if (rl) {
                    dataSource.push(org);
                }
            }
        });
        let roleIdArray = [];
        if (roleArray && roleArray.length > 0) {
            roleArray.forEach(r => {
                roleIdArray.push(r.refId);
            });
        }
        return {
            title: `Select Role`,
            dataSource,
            rowKey: (doc) => {
                return doc.id;
            },
            onChange: onTableChange,
            pagination: {
                current: _.get(page, 'current', 1),
                pageSize: _.get(page, 'pageSize', 10),
                total: _.get(organizationList, 'pageInfo.total', 0),
            },
            showSearchInput: true,
            searchValue: search,
            onSearch: onTableSearch,
            onSearchEnter,
            inputPlaceholder: 'Search by Name',
            loading,
            columns: [
                {
                    key: 'name',
                    title: 'Organization Name',
                    dataIndex: 'name',
                },
                {
                    key: 'roles',
                    title: 'Roles',
                    dataIndex: 'roles',
                    render: (t, doc) => {
                        let roles = doc.roles;
                        return <div>
                            {roles.map((role, index) => {
                                if (['Admin', 'Doctor', 'Nurse', 'Medical Assistant'].includes(role.name)) {
                                    const bp = {
                                        size: 'exsmall',
                                        bsStyle: 'primary',
                                        onClick: () => {
                                            this.addRole(role.refId);
                                        }
                                    };
                                    const label = '+'
                                    return (<div key={index} className="selectRoleCell">
                                        <div className="role_col">{role.name}</div>
                                        <div className="role_col">
                                            <IHButton {...bp}>
                                                {label}
                                            </IHButton>
                                        </div>
                                    </div>)
                                }
                                else {
                                    return ``;
                                }
                            })}
                        </div>;
                    }
                }
            ]
        }
    }

    addRole(roleRefId) {
        this.props.onAddRole(this.user, roleRefId);
    }
}

Component.propTypes = {
    loading: PropTypes.bool,
    refetch: PropTypes.func,
    list: PropTypes.object,
    onSearchEnter: PropTypes.func,
    onTableSearch: PropTypes.func,
    search: PropTypes.string,
}

export default Component
