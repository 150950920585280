import { React, IHSearchTable, IHCheckbox, IHInputNumber, IHButton, _, moment } from 'ihcomponent';
import { renderForm} from "../helpers";
import { browserHistory } from 'react-router';
import { showMonthsOrDays, RequestCache} from 'libModule/utils';
import { Icon,Table } from 'antd';
// import { AssignDeviceComponent } from './assignDeviceComponent';

let authMap = new Map();
authMap.set('cameraStatus', 'Camera');
authMap.set('microphoneStatus', 'Microphone');
authMap.set('photoLibraryStatus', 'Photo Library');
authMap.set('notificationStatus', 'Notifications');
authMap.set('contactsStatus', 'Contacts');
authMap.set('locationAlwaysStatus', 'Location');
// authMap.set('readContactsStatus');
// authMap.set('writeContactsStatus');
// authMap.set('coarseLocationStatus');
// authMap.set('fineLocationStatus');

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isStatusFilterClicked: false
    }
    this.changeTableActionFilter = this.changeTableActionFilter.bind(this);
  }

//   componentWillMount() {
//     this.props.onLoadParams(null, true)
//     RequestCache.set('nurse/currentPatient', undefined); //reset
//   }
//   componentWillUnmount() {
//     if (!new RegExp(/^(\/patients)/).test(Store.getState().routing.location.pathname)) {
//         this.props.onLoadParams(null, false)
//     }
//   }

  changeTableActionFilter(newFilter) {
    const { onTableChange, filter = {}, sort = {}, page } = this.props;
    const sortParam = _.pick(sort, ['field', 'order']);
    const filterParam = {
      ...filter,
      ...newFilter,
    };
    onTableChange({current: 1, size: 10}, filterParam, sortParam);
  }

  getTableProps(){
    const {
      onTableChange, data, sort, phoneInfoList
    } = this.props;
    const formatTime = (data)=>{
        return (moment(data).isValid() ? moment(data).format('MM/DD/YYYY, HH:mm') :'time format error');
    };
    const formatTimePart = (data)=>{
      return (moment(data).isValid() ? moment(data).format('MM/DD/YYYY') :'time format error');
    };
    return {
      title: `Device`,
      dataSource: phoneInfoList,

    //   onChange: (p, f, s) => {
    //     let result = null;
    //     if(this.props.sort.order && !s.order) {
    //     Mixpanel.track('clicked', 'sort', 'age is canceled', '');
    //     return onTableChange(p, newFilter, s);
    //     } else {
    //     Mixpanel.track('clicked', 'sort', 'age ' + s.order == 'descend' ? 'old to young' : 'young to old', '');
    //     return onTableChange(p, newFilter, s);
    //     }
    //   },
    //   inputPlaceholder: `Search by Name ${I18N.get('keywords.NRIC/FIN')} or ${I18N.get('keywords.PHONE')}`,
    //   pagination: {
    //     current: _.get(page, 'current', 1),
    //     pageSize: _.get(page, 'pageSize', 10),
    //     total: _.get(patientList, 'pageInfo.total', 0),
    //     onChange: (e) => {
    //       Mixpanel.track('clicked','pager','patients', {PAGE_NUM : "Page" + e});
    //     }
    //   },

      columns: [
        {
            title:'Device Name',
            key: 'deviceName',
            width:'9%',
            dataIndex:'mobileInfo.phoneName',
            render: (t, doc) => t,
        },
        {
            title: 'Model',
            key: 'model',
            width:'10%',
            dataIndex: 'mobileInfo.phoneModel',
            render: (t, doc) => t,
        },
        {
            title: 'Initial Used',
            key: 'initialUsed',
            width:'12%',
            dataIndex:'FirstUsedAt',
            render: (t, doc) => formatTimePart(t),
            sorter: (o1,o2)=> o1.FirstUsedAt-o2.FirstUsedAt
        },
        {
            title: 'OS Version',
            key: 'osVer',
            width:'11%',
            dataIndex:'mobileInfo.phoneOSVersion',
            render: (t, doc) => t,
            sorter: (o1,o2) => {
              let typeOne = o1.mobileInfo.phoneOSVersion.split(' ')[0];
              let typeTwo = o2.mobileInfo.phoneOSVersion.split(' ')[0];
              let verOne = o1.mobileInfo.phoneOSVersion.split(' ')[1];
              let verTwo = o2.mobileInfo.phoneOSVersion.split(' ')[1];
              if(_.startsWith(typeOne, 'A') && _.startsWith(typeTwo, 'i')) {
                return -1;
              } else if (_.startsWith(typeTwo, 'A') && _.startsWith(typeOne, 'i')) {
                return 1;
              } else {
                return verOne - verTwo;
              }
            }
        },
        {
            title: 'SC App Ver',
            key: 'scAppVer',
            width:'11%',
            dataIndex:'mobileInfo.appVersion',
            render: (t, doc) => t,
            sorter: (o1,o2) => {
              let appOne = o1.mobileInfo.appVersion.split('.');
              let appTwo = o2.mobileInfo.appVersion.split('.');
              return appOne[0] == appTwo[0] ? (appOne[1] == appTwo[1] ? appOne[2] - appTwo[2] : appOne[1] - appTwo[1]) : appOne[0] - appTwo[0];
            }
        },
        {
            title: 'Last Used',
            key: 'lastUsed',
            width:'12%',
            dataIndex:'LastUsedAt',
            render: (t, doc) => formatTime(t),
            sorter: (o1, o2) => o1.LastUsedAt - o2.LastUsedAt 
            
        },
        {
            title:'App Authority',
            key: 'appAuth',
            dataIndex:'authorityInfo',
            render: (t, doc) => {
                let grantList = new Array();
                let deniedList = new Array();
                let grantResult = '';
                let deniedResult = '';
                let keyArr = Object.keys(t);
                let valArr = Object.values(t);
                for(let i = 0; i < keyArr.length - 1; i++) {
                  if(valArr[i] === 'granted') {
                    grantList.push(authMap.get(keyArr[i]));
                  } else {
                    deniedList.push(authMap.get(keyArr[i]));
                  }
                }
                for(let i = 0; i < grantList.length; i++) {
                  if(i == grantList.length - 1) {
                    grantResult += (grantList[i]);
                  } else {
                    grantResult += (grantList[i] + ', ');
                  }
                }
                for(let i = 0; i < deniedList.length; i++) {
                  if(i == deniedList.length - 1) {
                    deniedResult += (deniedList[i]);
                  } else {
                    deniedResult += (deniedList[i] + ', ');
                  }
                }
                return (
                  <div>
                    <Icon type="check-circle" style={{ color:'green' }}/> 
                    {grantResult + " "} 
                    <Icon type="close-circle" style={{ color:'red' }}/>
                    {deniedResult} 
                </div>
                )
            }
        },
      ]
    }
  }

  render() {
    return (
      <div>
        <IHSearchTable {...this.getTableProps()}/>

      </div>
    );
  }
}