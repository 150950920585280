const visitTaskStatusMap = {
    'doctorSession':'doctorSession',
    'cdeSession':'cdeSession',
    'technicalSetup':'technicalSetup',
    'eyeExam':'eyeExam',
    'footExam':'footExam',
    'urineTest':'urineTest',
    'mpTest':'mpTest'
}
export default  visitTaskStatusMap;
