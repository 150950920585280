import {
    React, BaseComponent, ND, _
} from './util'
import PropTypes from 'prop-types';

const Select = ND.Select
const Component = class extends BaseComponent{

    render(){
        let p = _.omit(this.props, [])
        const option = p.option
        delete p.option

        const cn = 'IH-Select '+(p.className||'')
        delete p.className

        const dn = 'IH-Select-Dropdown '+(p.dropdownClassName||'')
        delete p.dropdownClassName

        if(!p.value){
          p.value = null
        }

        return (
            <Select className={cn} size="default" defaultActiveFirstOption={false} dropdownClassName={dn} allowClear={false} {...p}>
                {
                    _.map(option, (item, key)=>{
                        return (
                          <Select.Option key={key} disabled={item.disabled||false} value={item.value}>
                            {
                              item.renderRedDot ?
                                <div className={p.optionParentClass}>
                                  <div className={p.optionLeftDivContainer}>
                                    <div className={p.optionLeftDiv}></div>
                                  </div>
                                  <div className={p.optionRightDiv}>{item.name}</div>
                                </div>
                                :
                                item.name
                            }
                          </Select.Option>
                        )
                    })
                }
            </Select>
        )

    }
}

Component.propTypes = {
    //{name:'A', value:'a'}
    option : PropTypes.array.isRequired
}

export default Component
