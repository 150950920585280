import React, { Component } from 'react';
import {Col, Row, Form, DatePicker, Select} from "antd";
const {Option} = Select;

import moment from "moment";

const {Item} = Form;
const dateFormat = 'MM/DD/YYYY';

import {helpers as dataHelper} from '../helper/index'

class VisitInputTime extends Component{

    disabledDate(current){
        const week = moment(current).day();
        if(week === 0 || week === 6){
            return current;
        }
        return current && current < moment().startOf('day');
    }

    render() {

        const {visitInfo, editStatus, curTab} = this.props;
        let initialDate = curTab === 'Tomorrow' ? moment().add(1, 'days') : moment().add(0, 'days');
        while ( moment(initialDate).day() === 0 || moment(initialDate).day() === 6){
            initialDate = moment(initialDate).add(1, 'days');
        }

        let initialHour = null;
        let initialMin = dataHelper.minMap[0];

        if(editStatus){
            initialDate = moment(new Date(visitInfo.appointmentAt));
            initialHour = new Date(visitInfo.appointmentAt).getHours();
            if(initialHour < 10) {
                initialHour = '0' + initialHour;
            }
            initialMin = new Date(visitInfo.appointmentAt).getMinutes();
            if(initialMin < 10) {
                initialMin = '0' + initialMin;
            }
        }
        const { getFieldDecorator } = this.props.form;

        return (
            <Row gutter={20}>
                <Col span={12} >
                    <Item label="Select date" >
                        {getFieldDecorator('date', {
                            initialValue: initialDate,
                            rules: [{required: true}]})
                        (

                            <DatePicker format={dateFormat}
                                        disabledDate={this.disabledDate}/>
                        )}
                    </Item>
                </Col>

                <Col span={6}>
                    <Item label="Hour" >
                        {getFieldDecorator('hour', {
                            initialValue: initialHour,
                            rules: [{ required: true, message: 'Please Select Hour'}],
                        })(
                            <Select>{_.map(dataHelper.hourMap,(h)=><Option key={h} value={h}>{h}</Option>)}</Select>
                        )}
                    </Item>
                </Col>

                <Col span={6}>
                    <Item label="Min" >
                        {getFieldDecorator('min', {
                            initialValue: initialMin,
                            rules: [{ required: true, message: 'Please Select Min'}],
                        })(
                            <Select>{_.map(dataHelper.minMap,(m)=><Option key={m} value={m}>{m}</Option>)}</Select>
                        )}
                    </Item>
                </Col>
            </Row>

        )
    }

}

export default VisitInputTime;
