import React from "react";
import { IHSearchTable, IHCheckbox, IHInputNumber, IHButton, _, moment,IHLoading } from 'ihcomponent';
import { Table } from 'antd';
import '../style/index.scss';
import constant from '../constant/index';
import I18N from 'modulesAll/I18N';
import { browserHistory } from "react-router";

const alertTaskMap = I18N.get('smartAlert.alertType');
const { alertLevelColorMap } = constant;
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const SmartAlertListComponent = class extends React.Component {
    constructor() {
        super();
        this.state = {
            expandedRowKeys: [],
        }
    }
    componentWillMount() {
        this.props.onLoadParams(null, true);
    }

    renderTimeLeft(createdAt, left) {
        const timePassedSinceCreated = new Date() - createdAt;
        const timeLeftBeforeDue = left - timePassedSinceCreated;
        const dueInMs = moment.duration(timeLeftBeforeDue);
        const dueInDays = Math.floor(dueInMs.asDays());
        const dueInHours = Math.floor(dueInMs.asHours() % 24);
        const dueInMins = Math.floor(dueInMs.asMinutes() % 60);
        let leftTimeString = '';
        if (timeLeftBeforeDue < 0) {
            return <p style={{ color: 'red' }}>Over Due</p>
        }
        if (dueInDays > 0) {
            leftTimeString = leftTimeString.concat(`${dueInDays} day(s)`)
        }
        else if (dueInHours > 0) {
            leftTimeString = leftTimeString.concat(`${dueInHours} hour(s)`)
        }
        else if (dueInMins > 0) {

            leftTimeString = leftTimeString.concat(`${dueInMins} min(s)`);
        }

        return leftTimeString;
    }

    getColumns() {
        const { filter,type } = this.props;
        const { alertLevel } = constant;
        const filterAlertLevel = alertLevel[type]
        const alertLevelOpts = _.map(filterAlertLevel, (a) => ({
            value: a,
            text: alertTaskMap[a]
        }))
        return [
            {
                key: 'fullName',
                title: 'Name',
                dataIndex: 'id',
                render: (_, doc) => {
                    const { firstName, lastName } = doc;
                    return `${firstName} ${lastName}`
                }
            },
            {
                key: 'alertLevel',
                dataIndex: 'tasks',
                filters: alertLevelOpts,
                filteredValue: _.get(filter, 'alertLevel'),
                title: 'Alert Level',
                render: (item, doc) => {
                    const { tasks } = doc;
                    const firstTask = _.first(tasks) || {};
                    const alertLevel = _.get(firstTask, 'alertLevel', '');
                    const { color, backgroundColor } = alertLevelColorMap[alertLevel];
                    const alertLevelText = alertTaskMap[alertLevel];
                    return {
                        props: {
                            className: `alertLevelCell ${alertLevel}`,
                        },
                        children: <p style={{ color: color, fontWeight: 'bold' }}>{alertLevelText}</p>
                    }

                    // return <p style={{color:color,fontWeight:'bold'}}>{ alertLevelText }</p>;
                }
            },
            {
                key: 'dueIn',
                dataIndex: 'createdAt',
                title: 'Due In',
                render: (i, doc) => {
                    const { tasks } = doc;
                    const firstTask = _.first(tasks) || {};
                    const { createdAt, replyTime } = firstTask;
                    return <div>{this.renderTimeLeft(createdAt, replyTime)}</div>
                }
            },
            {
                key: 'taskType',
                dataIndex: 'tasks',
                title: 'Task Type',
                render: (tasks, doc) => {
                    const firstTask = _.first(tasks) || {};
                    return _.get(firstTask, 'display');
                }
            },
            {
                key: 'date',
                dataIndex: 'tasks',
                title: 'Created At',
                render: (item, doc) => {
                    const { tasks } = doc;
                    const firstTask = _.first(tasks) || {};
                    return moment(_.get(firstTask, 'createdAt')).format('MM/DD/YYYY HH:mm');
                }
            }
        ]
    };
    // onRowClick(id){
    //     browserHistory.push(`/patients/${id}/enrolledprogram/default`);
    //
    // }
    onRow = ({ id,taskType }) => {
        const { curPage, handleChangePage ,type} = this.props;
        return {
            onClick: () => {
                browserHistory.push(`/patients/${id}/enrolledprogram/default`);
                if(curPage === 'tempenrolledpatients') {
                    Mixpanel.calculateDuration('tempenrolledpatients');
                }
                if(curPage === 'smartalert') {
                    Mixpanel.calculateDuration('smartalert');
                }
                Mixpanel.time_event('patient_profile')
                Mixpanel.track('clicked','level',`${type}_vlist`,{ PATIENT_ID: id,TASKTYPE:taskType })
                handleChangePage('patient_profile');
            }
        }
    };
    render() {
        const { page, onTableChange, smartAlertTasksList, loading ,pageInfo, type } = this.props;


        let NestedTable = () => {
            const columns = [{
                key: 'dummyCol',
                title: '',
            },
            {
                key: 'alertLevel',
                title: 'Alert Level',
                dataIndex: 'alertLevel',
                render: (alertLevel, doc) => {
                    const { color } = alertLevelColorMap[alertLevel];
                    const alertLevelText = alertTaskMap[alertLevel];
                    return {
                        props: {
                            className: `alertLevelCell ${alertLevel}`,
                        },
                        children: <p style={{ color: color, fontWeight: 'bold' }}>{alertLevelText}</p>
                    }
                }
            },
            {
                key: 'dueIn',
                dataIndex: 'createdAt',
                render: (i, doc) => <div>{this.renderTimeLeft(i, doc.replyTime)}</div>
            },
            {
                key: 'taskType',
                dataIndex: 'display',
                render: (i) => i
            },
            {
                key: 'createdAt',
                dataIndex: 'createdAt',
                title: 'Created Time',
                render: (i) => moment(i).format('MM/DD/YYYY HH:mm')
            }]
            const expandedRowRender = (a, b, c) => <Table dataSource={a.tasks}
                onRow={(d,doc) => {
                    const { taskType } = d;
                    const keys = this.state.expandedRowKeys;
                    if (keys && keys.length > 0 && keys[0] != undefined) {
                        const id = smartAlertTasksList[keys[0]].id;
                       return this.onRow({ id ,taskType})
                    }
                }
                }
                columns={columns} pagination={false} showHeader={false} rowClassName='nestedRowClass' />
            const pagination = {
                current: _.get(page, 'current', 1),
                pageSize: _.get(page, 'pageSize', 10),
                total: _.get(pageInfo, 'total', 0),
            };
            const noDataElem = (
                <div>
                    <p style={{ fontSize: '18px' }}>No Smart Alert</p>
                </div>
            );
            const onChange = (p, f, s) => {
                const newAlertLevel = f.alertLevel;
                Mixpanel.track('set','alert_level_filter',`${type}_WORKING_LIST`,{ ALERT_LEVEL: newAlertLevel });
                const newFilter = { ...f };
                const newPage = { ...p };
                return onTableChange(newPage, newFilter, s)
            };
            const loadObj = { spinning:!!loading,indicator: <IHLoading/> }
            return (
                <IHSearchTable
                    className="smartAlertListTable"
                    columns={this.getColumns()}
                    onExpandedRowsChange={(expanded, data) => {
                        this.setState({
                            expandedRowKeys: [expanded.pop()]
                        },()=>{
                            const row = _.get(smartAlertTasksList,this.state.expandedRowKeys[0])
                            if(row){
                                 const PATIENT_ID = _.get(row,'memberId');
                                 Mixpanel.track('Clicked','plus_to_expand',`${type}_WORKING_LIST`,{ PATIENT_ID });
                            }
                        })
                    }}
                    loading={loadObj}
                    expandedRowKeys={this.state.expandedRowKeys}
                    expandedRowRender={(a, b, c) => expandedRowRender(a, b, c)}
                    dataSource={smartAlertTasksList}
                    pagination={pagination}
                    onChange={onChange}
                    onRow={this.onRow}
                    noDataElem={noDataElem}
                    onCell={this.onCell}
                />
            );
        }
        return <div>{NestedTable()}</div>
    }
}

export default SmartAlertListComponent;
