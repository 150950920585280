import React from 'react';
import {connect} from "react-redux";
import {compose} from "react-apollo/index";
import actions from '../actions';
import { API } from '../query/index';
import $ from 'jquery';
import { MapActionsConfiguration,startActionMap,endActionMap } from '../constants/map';
import uuidv4 from 'uuid/v4';
const { startTimer, pauseTimer,stopTimer,resetTimer,resumeTimer,abort,setUUId,resetCoolDown,resumeTimeOut,pauseTimeOut,focusPage,blurPage } = actions;
const COOLDOWN_INMS = 60000;
let GLOBAL_COOLDOWN;

const TimerContainer = class extends React.Component {
    constructor(){
        super();
        this.activePage.bind(this);

    }

    componentDidMount() {
        let {displayName, category,patientId } = this.props;
        displayName = `${displayName}:${patientId}`;
        const { startTimerAction, pauseTimerAction, resumeTimerAction,
                coolDownExpireAction,coolDownResumeAction,coolDown } = MapActionsConfiguration[category];
        if(coolDown) {
            this.startCoolDown(displayName, coolDownExpireAction, COOLDOWN_INMS);
        }
        this.props.startTimer(displayName, startTimerAction,patientId);

        $(window).blur(() => {

            clearTimeout(GLOBAL_COOLDOWN);
            // let flag = this.props.timer.isPaused;
            let { isFocused } = this.props.timer;
            if(isFocused) {
                this.props.blurPage(displayName);
                this.props.pauseTimer(displayName, pauseTimerAction);
                this.props.pauseTimeOut(displayName);
            }
        })

        $(window).focus(() => {
            // let flag = this.props.timer.isPaused;
            let { isFocused } = this.props.timer;
            if(!isFocused) {
                this.props.focusPage(displayName);
                this.props.resumeTimer(displayName, resumeTimerAction);
                this.props.resumeTimeOut(displayName);
                this.startCoolDown(displayName,coolDownExpireAction,COOLDOWN_INMS);
            }
        })
        if(coolDown) {
            $(window).click(() => {
                this.activePage(displayName, coolDownResumeAction, coolDownExpireAction);
            })
            $(window).scroll(() => {
                this.activePage(displayName, coolDownResumeAction, coolDownExpireAction);
            })

            $(window).keyup(() => {
                this.activePage(displayName, coolDownResumeAction, coolDownExpireAction);
            })

            $(window).mousemove(()=>{
                this.activePage(displayName, coolDownResumeAction, coolDownExpireAction);
            })

        }

        const canResetUUId = MapActionsConfiguration[category].sessionId;
        const path = _.get(this,'props.viewingDetails.path');
        if(typeof canResetUUId=='function' ?canResetUUId(path) : canResetUUId ) {
            const uuid = uuidv4();
            this.props.setUUId(uuid);
        }
    }

    activePage(displayName,coolDownResumeAction,coolDownExpireAction){
        const { isPaused,isFocused } = this.props.timer;
        // if(flag) {
            if(isPaused){
                this.props.resumeTimer(displayName, coolDownResumeAction);
            }
            clearTimeout(GLOBAL_COOLDOWN);
            // this.props.resumeTimer(displayName, coolDownResumeAction);
            GLOBAL_COOLDOWN = setTimeout(() => {
                this.props.pauseTimer(displayName, coolDownExpireAction);
                this.props.pauseTimeOut(displayName);
            }, COOLDOWN_INMS);
            if(isPaused) {
                this.props.resumeTimeOut(displayName);
            }
        // }
    }

    startCoolDown(displayName,coolDownExpireAction,COOLDOWN_INMS){
        clearTimeout(GLOBAL_COOLDOWN);
        GLOBAL_COOLDOWN = setTimeout(()=>{
            this.props.pauseTimer(displayName,coolDownExpireAction);
            this.props.pauseTimeOut(displayName);
        },COOLDOWN_INMS);

    }

    render() {
        return <div className='timerContainer'></div>
    }

    calculateTotalTime(clientEvents) {
        let totalTime = 0;

        for (let i = clientEvents.length-1; i >= 1; i--) {
            const curEvent = clientEvents[i];
            const preEvent = clientEvents[i-1];
            if(curEvent.ts&&endActionMap[curEvent.action]){
               if(preEvent.ts&&startActionMap[preEvent.action]){
                   totalTime += (curEvent.ts - preEvent.ts);
               }
            }
        }

        return totalTime;
    }

    componentWillReceiveProps(nextProps){
        let { displayName, category,selectedChannel,patientId } = this.props;
        displayName = `${displayName}:${patientId}`;
        const { canRestUUid,isChat,stopTimerAction } = MapActionsConfiguration[category];
        if(isChat){
            const nextChannel = nextProps.selectedChannel;
            const curChannel = selectedChannel;
            if(nextChannel&&(nextChannel!= curChannel)){
                clearTimeout(GLOBAL_COOLDOWN);
                let variables = this.collectTimerAction(false,false);
                if(variables.startTime) {
                    API.createTimeTrackingEvent(variables)
                        .then(() => this.props.resetTimer(displayName, canRestUUid,'a'))
                }
            }
        }
    }

    collectTimerAction(hasClientEvents,hasStopTime){

        const userId = this.props.patientId || window.location.pathname.split('/')[2];
        const viewerId = sessionStorage.getItem('currentUserId');
        const { category, action,viewingDetails,sessionId } = this.props;
        const { timer } = this.props ||{};
        const { startTime, clientEvents, interventionDetails } = timer;
        let abortTime;
        let { stopTime } = timer;
        const isAborted = (!stopTime)&&(!hasStopTime);
        const { abortTimerAction,version,allowAbort,stopTimerAction } = MapActionsConfiguration[category];

        if(!clientEvents||clientEvents.length==0){
            return;
        }

        if (isAborted&&allowAbort) {
            if(_.last(clientEvents).action!='ABORT') {
                clientEvents.push({
                    event: abortTimerAction,
                    action: 'ABORT',
                    ts: new Date(),
                })
            }
            abortTime = new Date();
        }else{
            if(clientEvents.length>0&&clientEvents[clientEvents.length-1].action!='STOP_TIMER') {
                clientEvents.push({
                    event: stopTimerAction,
                    action: 'STOP_TIMER',
                    ts: new Date(),
                })
                stopTime = new Date();
            }
        }

        const totalTime = isAborted&&allowAbort ? 0 : this.calculateTotalTime(clientEvents||[]);
        const variables = {
            clientEvents:hasClientEvents||clientEvents,
            startTime,
            stopTime:hasStopTime||stopTime,
            abortTime,
            userId,
            viewerId,
            category,
            action,
            totalTime,
            interventionDetails,
            viewingDetails,
            version,
            sessionId,
            timeout:COOLDOWN_INMS
        }

        return variables;
    }

    shouldCreateTimeTrackingEvent(variables,docId,category){
        const ﻿interventionId = _.get(variables,'interventionDetails[0].docId','');
        const isFoodLog = category==='FOOD_LOG';
        const { abortTime } = variables;
        //food log may create several timers if click through foodDiaryLogBook, so need control to createtimetracker only for current foodlog

        return !isFoodLog||interventionId==docId||abortTime;
    }
    componentWillUnmount() {
        const {  keepEventListener } = this.props;

        if(!keepEventListener) {

            $(window).off('blur');
            $(window).off('focus');
            $(window).off('scroll');
            $(window).off('keyup');
            $(window).off('click');
            $(window).off('mousemove');

        }

        clearTimeout(GLOBAL_COOLDOWN);

        let { displayName,category,canRestUUid,timer,patientId,docId, } = this.props;
        const { clientEvents } = this.props.timer;
        const { leaveTrigger,stopTimerAction,allowAbort,isChat } = MapActionsConfiguration[category];
        let stopTime;

        if(!clientEvents||clientEvents.length==0){
            return;
        }

        displayName = `${displayName}:${patientId}`;
        if (leaveTrigger.indexOf('unmount')!=-1) {
            if(!allowAbort){
                if(clientEvents.length>0&&clientEvents[clientEvents.length-1].action!='STOP_TIMER') {
                    clientEvents.push({
                        event: stopTimerAction,
                        action: 'STOP_TIMER',
                        ts: new Date(),
                    })
                    stopTime = new Date();
                }
            }
            const variables = this.collectTimerAction(clientEvents,stopTime);
            const ﻿interventionId = _.get(variables,'interventionDetails[0].docId','');
            const isFoodLog = category==='FOOD_LOG';
            const shouldCreateTimeTrackingEvent = this.shouldCreateTimeTrackingEvent(variables,docId,category);

            if(variables.startTime&&shouldCreateTimeTrackingEvent) {
                API.createTimeTrackingEvent(variables).then(() => {
                    this.props.resetTimer(displayName, canRestUUid,'b');
                })
            }
        }else {
            this.props.resetTimer(displayName, canRestUUid,'c');
        }
    }
}
const mapToProps = (state,props)=>{

    let { displayName,patientId } = props;
    displayName = `${displayName}:${patientId}`;

    return {
        timer:state.timer.main[displayName]||{},
        sessionId:state.timer.main.uuid,
    }
}
const mapToDispatch = (dispatch)=>{

    return {
        startTimer:(displayName,event,patientId)=>dispatch(startTimer(displayName,event,patientId)),
        pauseTimer:(displayName,event)=>dispatch(pauseTimer(displayName,event)),
        resumeTimer:(displayName,event)=>dispatch(resumeTimer(displayName,event)),
        pauseTimeOut:(displayName)=>dispatch(pauseTimeOut(displayName)),
        resumeTimeOut:(displayName)=>dispatch(resumeTimeOut(displayName)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        resetTimer:(displayName,canRestUUid,flag)=>dispatch(resetTimer(displayName,canRestUUid,flag)),
        abort:(displayName,event)=>dispatch(abort(displayName,event)),
        setUUId:(uuid)=>dispatch(setUUId(uuid)),
        resetCoolDown:(displayName,coolDown)=>dispatch(resetCoolDown(displayName,coolDown)),
        focusPage:(displayName)=>dispatch(focusPage(displayName)),
        blurPage:(displayName)=>dispatch(blurPage(displayName))
    }

}

export default compose(connect(mapToProps,mapToDispatch))(TimerContainer);
