import { IHSelect, _ } from 'ihcomponent';
import PropTypes from 'prop-types';
import React from 'react';
import { createContainer, goPath, getRouterParam, getRouterQuery } from '../../../../lib/utils';
import I18N from 'modulesAll/I18N'
import UserClass from 'graphqlModule/class/User'
import { setTab } from '../../enrolledProgramInfo/actions/ProgramInfoTab';

const MAP = {
  DISCHARGED : 'Discharged',
  CREATED : 'Incomplete',
  PENDING : 'Incomplete',
  RETURNED: 'Discharged & Returned'
};

const P_MAP = {
    BG : '/image/device/bg.png',
    BP : '/image/device/bp5.png',
    HS : '/image/device/hs4.png',
    TABLET : '/image/device/ipadmini.png'
};

const Component = class extends React.Component {
  static displayName = 'patient/main/containers/Dropdown'

  shouldComponentUpdate(np) {
    if (this.props.value === np.value && this.props.epStatus === np.epStatus /** VS-2025 **/ ) {
      return false
    }

    return true
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.value !== nextProps.value) {
      this.props.setInitDeviceData.apply(null, this.getInitData(nextProps))
    }
  }

  render() {
    const { enrolledProgramList, value, patientId } = this.props;
    const nurseEPList = UserClass.getEnrolledProgramIdList();
    const canViewEPList = enrolledProgramList.filter( (ep) => nurseEPList.indexOf(ep.id) !== -1 );
    const firstId = canViewEPList.length ? canViewEPList[0].id : 'new';

    const sortedList = _.orderBy(enrolledProgramList, [programme => programme.status === 'CREATED' ? 0 : 1, programme => programme.name.toLowerCase()], ['asc', 'asc'])
    const options = sortedList.map(enrolled => ({
      name: `${enrolled.name} ${MAP[enrolled.status]?('('+MAP[enrolled.status]+')'):''}`,
      value: enrolled.id,
      disabled: nurseEPList.indexOf(enrolled.id) === -1
    }));

    if (value === 'new' && (!this.props.tab || this.props.tab === 'programme')) {
      options.unshift({
        name: `New ${I18N.get('keywords.Program')} (Incomplete)`,
        value: 'new'
      })
    }
    else if (value !== 'new' && (!this.props.tab || this.props.tab === 'programme')) {

    }

    const other = {
      showSearch: true,
      filterOption: (inputValue, option) => {
        return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      }
    };
    // if(value !== 'new'){
    //   other.disabled = true
    // }

    const p = {
      option: [
        ...options
      ],
      size: 'large',
      value: value === 'default' ? firstId : value,
      onChange: (id) => {
        goPath(`/patients/${patientId}/enrolledprogram/${id}`);
        this.props.setTab('programme');
      },
      ...other
    };
    //pending for more design
    //hide dropdown if there is only one enrolled program;
    // const hideDropDown = enrolledProgramList.length==1 ? 'none':'block';
      // style={{'display':hideDropDown}}>

      return (
      <div className="v-drop">
        <IHSelect {...p} />
      </div>
    );
  }

  getInitData(nextProps){
      const {devices} = nextProps;
      const table = [];
      const value = {};
      _.each(devices, (item) => {
          let id = item.type;
          if (id==='TABLET') id = 'tablet'
          table.push({
              id,
              name : item.model === 'IPAD_MINI' ? I18N.get('IPAD_MINI') : item.model,
              picture : P_MAP[item.type],
              state : ''
          });
          if(item.address || item.other){
              value[id] = {
                  select : false,
                  model : true,
                  reason : item.address || item.other
              }
          }
          else{
              value[id] = {
                  select : true
              }
          }
      });

      return [table, value];
  }
};

Component.propTypes = {
  enrolledProgramList: PropTypes.array,
  value: PropTypes.string,
  patientId: PropTypes.string,
  tab: PropTypes.string,
  setInitDeviceData: PropTypes.func,
};


export default createContainer((Component), (state, ownProps) => {
  const params = getRouterParam(state);
  const tab = getRouterQuery(state, 'tab');

  return {
    ...params,
    tab,
  };
},
{ setTab }
);
