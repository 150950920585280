export default {
    'OVER_COPAY':"Concern over paying for copay",
    'NO_SMARTPHONE':"Doesn't have smartphone",
    'TECHNOLOGY_HINDERANCE':"Technology learning hinderance",
    'NO_NEED_PROGRAM_MONITORING':"Patient doesn't feel they need program monitoring",
    'NO_NEED_HOME_PROGRAM':"Patient doesn't want to do home monitoring",
    'DROPPED': "Patient dropped from MD care",
    "PHONE_ISSUE":"Issue with phone or phone permissions",
    "DISCREPANCIES_WITH_METER":"Question discrepancies with our meter",
    "ACCURACY_OF_METER":"Question accuracy of our meter",
    "MULTIPLE NO-SHOWS":"Multiple no-shows",
    "OTHER":"Other"
}
