const mesNotificationType = {
    'text':'text',
    'foodUpload':'foodUpload',
    'fileUpload': 'fileUpload',
    'measurement':'measurement',
    'measurements':'measurements',
    'resultTriggerAlert':'resultTriggerAlert',
}

export default  mesNotificationType;

