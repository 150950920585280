import  React from 'react';
import { Form } from 'antd';
import { Steps,Row,Col,Radio,DatePicker,Checkbox,Input,Button,Icon } from 'antd';
import { message } from 'ihcomponent';
import Client from 'libModule/gqlClient';
import medTypes from "../../medicine/constants/medTypes";
import moment from "moment/moment";
import editMedication from 'graphqlModule/mutation/editMedication';
import {Modal} from "antd/lib/index";

const FormItem = Form.Item;
const style = {
    backButtonStyle:{
        background:'inherit',
        border:'unset',
        paddingLeft:0,
        marginBottom: 10
    }
}
const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
}
let EditMedComponent = class extends React.Component {

    editMed(){
        const { resetFields } = this.props.form;
        const { data,setEditMedicine,refetchMedList } = this.props;
        const { id } = data;
        const patientId = _.get(data,'member.id');
        const formVariables  = this.props.form.getFieldsValue();
        let variables = {
            id,
            memberId: patientId,
            ...formVariables,
        };
        Client.mutate({
            mutation: editMedication,
            variables: variables,
        }).then(()=>{
            setEditMedicine(null);
            if(refetchMedList){
                refetchMedList
            }
        })
    }
    renderRadioField(data) {
        const {getFieldDecorator} = this.props.form;
        const itemList =
            [{
                key: 'type',
                label: "Medication Type",
                type: 'radioButton',
                valuePropName:'checked',
                className: 'radio-button add_medicine_header',
                required: true,
                initialValue:data.type
            }]

        return itemList.map((value) => {
            return (
                <FormItem
                    label={value.label}
                    key={value.label}
                    className='radio_option'
                >
                    {
                        getFieldDecorator(value.key, {
                            rules: [
                                {required: value.required, message: 'Please Select Medicine Type'},
                            ],
                            initialValue: value.initialValue,
                        })
                        (
                            <Radio.Group>
                                {
                                    _.map(medTypes, ({name, value}, index) => <Radio value={value} key={index}>{name}</Radio>)
                                }
                            </Radio.Group>
                        )
                    }
                </FormItem>
            )
        });
    }
    renderRadioGroup(data){
        const { getFieldDecorator,setFieldsValue } = this.props.form;
        const itemList =
            [
                {
                    key: 'TAKING',
                    label:'Taking',
                    type:'checkbox',
                    cols:5,
                    valuePropName:'checked',
                },
                {
                    key: 'NOT_SURE',
                    label:'Not Sure',
                    type:'checkbox',
                    cols:5,
                    valuePropName:'checked',
                },
                {
                    key: 'NOT_TAKING',
                    label:'Not Taking',
                    type:'checkbox',
                    cols:5,
                    valuePropName:'checked',
                },

            ];
        return  <Row style={{ display:'flex' }}>
            <FormItem
                label={'Status'}
                className='radio_option'
            >
                {
                    getFieldDecorator('status',{
                        initialValue: data.status,

                    })
                    (
                        <Radio.Group>
                            { _.map(itemList,(value,index)=> <Radio value={value.key} key={index}>{value.label}</Radio>) }
                        </Radio.Group>
                    )
                }
            </FormItem>
        </Row>
    }

    renderInstruction = (data)=>{
        const { getFieldDecorator } = this.props.form;
        let itemList = [[

            {
                key: 'instruction',
                label: "Instruction",
                type: 'textarea',
                initialValue: _.get(data,'instruction',''),
                className: 'textareaInput',
                span:12,
                gutter:12,
            },
            {
                key: 'otherMD',
                label: '',
                type: 'checkbox',
                valuePropName:'checked',
                initialValue: _.get(data,'otherMD'),
                required: false,
                render: () => <Checkbox>Other MD</Checkbox>,
                span:12
            }
        ]]
        return _.map(itemList,(arr,i) =>
            <Row key={i} gutter={12}>
                {
                    _.map(arr, (value, index) => {
                        return (
                            <Col span={value.span} key={index}>
                                <FormItem
                                    label={value.label}
                                    key={value.key}
                                >
                                    {getFieldDecorator(value.key, {
                                        rules: [{
                                            required: value.required,
                                            message: value.message || null,
                                        }],
                                        initialValue: value.initialValue,
                                        valuePropName: value.valuePropName || 'value'

                                    })
                                    (
                                        value.render ? value.render(value) :
                                            <Input rows={value.rows || 1} cols={value.col || 55} />
                                    )}
                                </FormItem>
                            </Col>
                        )
                    })
                }
            </Row>)
    }


    renderOtherMD=(data)=> {
        const { getFieldDecorator } = this.props.form;
        const value = {
            key: 'otherMD',
            label: '',
            type: 'checkbox',
            valuePropName:'checked',
            initialValue: _.get(data,'otherMD'),
            required: false,
            render: () => <Checkbox>Other MD</Checkbox>
        };
        return <FormItem label={value.label}>
            {
                getFieldDecorator(value.key, {
                    rules: [{
                        required: value.required,
                        message: value.message || null,
                    }],
                    initialValue: value.initialValue,
                    valuePropName:value.valuePropName
                })
                (
                    value.render ? value.render(value) :
                        <Input />
                )}
        </FormItem>
    }

    renderDosage = (data)=> {
        const { getFieldDecorator } = this.props.form;
        const itemList = [[
            {
                key: 'dosage',
                label: "Dosage",
                type: 'textarea',
                className: 'textareaInput',
                size:'default',
                required:true,
                message:'Please enter dosage',
                initialValue:data.dosage ?  data.dosage: null,
                span:8
            },
            {
                key:'startDate',
                label:'Start Date',
                span:12,
                gutter:12,
                initialValue:data.startDate ? moment(data.startDate) : null,
                render:()=><DatePicker />
            },
        ]];
        return _.map(itemList,(arr,i) =>
            <Row key={i} style={{display:'flex'}}>
                {
                    _.map(arr, (value, index) => {
                        return (
                            <Col span={value.cols} key={index} span={12}>
                                <FormItem
                                    label={value.label}
                                    key={value.key}
                                >
                                    {getFieldDecorator(value.key, {
                                        rules: [{
                                            required: value.required,
                                            message: value.message || null,
                                        }],
                                        initialValue: value.initialValue,
                                        valuePropName: value.valuePropName || 'value'
                                    })
                                    (
                                        value.render ? value.render(value) :
                                            <Input />
                                    )}
                                </FormItem>
                            </Col>
                        )
                    })
                }
            </Row>)
    }

    handleClickDisMed = (data)=>{
        let date = moment();
        Modal.confirm({
            title: 'Discontinue Medication?',
            content: <div>Are you sure you would like to Discontinue this Medication?
                        <DatePicker popupStyle={{ zIndex:3000 }} onChange={(d)=>date=d} defaultValue={ date } disabledDate={disabledDate}/>
                    </div>,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex:2000,
            mask:true,
            className:'deleteMedModule',
            maskClosable:true,
            onOk:()=>{
                this.discontinueMed(data,date);
            }
        });
    }
    discontinueMed = (data,date) =>{
        const { id } = data;
        const { setEditMedicine } = this.props;
        const patientId = _.get(data,'member.id');
        let variables = {
            id:id,
            memberId: patientId,
            endDate: date.valueOf() ||new Date()
        }

        Client.mutate({
            mutation: editMedication,
            variables: variables,
        }).then((res)=>{
            setEditMedicine(null);
            message.success('Medication Discontinued');


        }).catch((error)=>{
            console.log(error);
        })
    }

    renderBackButton = (setEditMedicine)=>{
        return  <Button  onClick={()=>setEditMedicine(null) } style={style.backButtonStyle}>
            <Icon type="left" />
            Go back
        </Button>
    }

    render(){
        const { data,setEditMedicine } = this.props;
        const { name } = data;
        return <Form>
                    { this.renderBackButton(setEditMedicine) }
                    <h4>{`Edit Medication: ${ name}`}</h4>
                    <Row gutter={20}>
                        <Col span={12}>{ this.renderDosage(data) }</Col>
                        <Col span={12}>{ this.renderRadioField(data) }</Col>
                    </Row>
                    <Row gutter={20} style={{display:'flex'}}>
                        <Col span={12}>{ this.renderInstruction(data) }</Col>
                        <Col span={9}>{ this.renderRadioGroup(data)}</Col>
                    </Row>
                    <Row style={{display:'flex'}} gutter={20}>
                        <Col><Button onClick={ ()=>this.editMed() }>Save</Button></Col>
                        <Col><Button onClick={ ()=>this.handleClickDisMed(data) }>Discontinue</Button></Col>
                    </Row>
              </Form>
    }
}
EditMedComponent = Form.create()(EditMedComponent);

export default EditMedComponent;
