import React from "react";
import HSAreaChartComponent from '../components/HSAreaChartComponent';
import { Switch,Radio } from 'antd';
import moment from "moment/moment";
import { IHSearchTable, IHLoading, IHButton, _,IHInput,message } from 'ihcomponent';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';

const  HSAreaChartContainer  = class extends React.Component {
    constructor(){
        super();
        this.state = {
            allVisit: false,
            toDate:moment().endOf('day'),
            fromDate:moment().subtract(3,'month').startOf('day'),
            timeInterval:'M',
            value:3
        }
    }

    changePre = ()=>{
        const { fromDate, toDate,timeInterval,value } = this.state;
        const { patientId } = this.props;
        const updatedFromDate = fromDate.subtract(value,timeInterval).startOf('day');
        const updatedToDate = toDate.subtract(value,timeInterval).endOf('day');
        const map = {
            3:'THREE',
            6:'SIX',
            1:'ONE'
        };
        const strVal =  map[value];
        const strUnit = (timeInterval=='M' ? 'MONTH' : 'YEAR')
        const str = strVal+'_'+strUnit;
        Mixpanel.track('clicked','BACK_WEIGHT',null,{ PATIENT_ID:patientId,DURATION: str });
        this.setState({
            fromDate:updatedFromDate,
            toDate: updatedToDate
        })
    }

    changeNext = ()=>{
        const { fromDate, toDate,timeInterval,value } = this.state;
        const { patientId } = this.props;
        const updatedFromDate = fromDate.add(value,timeInterval).startOf('day');
        const updatedToDate = toDate.add(value,timeInterval).endOf('day');
        const map = {
            3:'THREE',
            6:'SIX',
            1:'ONE'
        };
        const strVal =  map[value];
        const strUnit = (timeInterval=='M' ? 'MONTH' : 'YEAR')
        const str = strVal+'_'+strUnit;
        Mixpanel.track('clicked','NEXT_WEIGHT',null,{ PATIENT_ID:patientId,DURATION: str });
        this.setState({
            fromDate:updatedFromDate,
            toDate: updatedToDate
        })
    }

    renderNextButton = () =>{
        const { value,timeInterval } = this.state;
        const unit = (timeInterval==='Y') ? 'Year' :'Months';

        return <div style={{ display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span>{`Next ${value} ${unit}`}</span>
                    <ul className='ant-pagination logBookPreNext' >
                        <li className=" ant-pagination-next" aria-disabled="false" onClick={()=>this.changeNext()}>
                            <a className="ant-pagination-item-link"></a>
                        </li>
                    </ul>
                </div>
    }

    renderPrevButton = ()=>{
        const { value,timeInterval } = this.state;
        const unit = (timeInterval==='Y') ? 'Year' :'Months';

        return <div style={{ display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <ul className='ant-pagination logBookPreNext' >
                        <li className=" ant-pagination-prev" aria-disabled="false" onClick={()=>this.changePre()}>
                            <a className="ant-pagination-item-link"></a>
                        </li>
                    </ul>
                    <span>{`Past ${value} ${unit}`}</span>
              </div>
    }


    setTimeInterval = (updatedTimeInterval,updatedValue)=>{
        const { patientId } = this.props;
        const updatedFromDate = moment().subtract(updatedValue,updatedTimeInterval).add(1,'days').startOf('day');
        const updatedToDate = moment().endOf('day');
        this.setState({
            fromDate:updatedFromDate,
            toDate: updatedToDate,
            timeInterval: updatedTimeInterval,
            value: updatedValue
        },()=>{
            const map = {
                3:'THREE',
                6:'SIX',
                1:'ONE'
            };
            const str = map[updatedValue]+'_'+(updatedTimeInterval=='Y' ? 'YEAR' : 'MONTH');
            Mixpanel.track('clicked',str+'_WEIGHT',null,{ PATIENT_ID: patientId });
        })
    }

    renderRadioButton = ()=>{
        const { value } = this.state;
        const threeMonthChecked = (value===3) ;
        const sixMonthChecked = (value===6) ;
        const oneYearChecked = (value===1) ;

        return <Radio.Group className='it-dd logBookButtonGroup' style={{ marginLeft:10, marginRight: 10}}>
                    <Radio.Button  value='3'  onClick={()=>this.setTimeInterval('M',3)} checked = {threeMonthChecked}>3 Months</Radio.Button>
                    <Radio.Button  value='6'  onClick={()=>this.setTimeInterval('M',6)} checked = {sixMonthChecked} >6 Months</Radio.Button>
                    <Radio.Button  value='1' onClick={()=>this.setTimeInterval('Y',1)} checked = {oneYearChecked}>1 Year</Radio.Button>
                </Radio.Group>
    }

    setAllData = ()=>{
        const { patientId } = this.props;

        this.setState({ allVisit: !this.state.allVisit });
        Mixpanel.track('clicked','ALL_DATA_WEIGHT',null,{ PATIENT_ID:patientId })
    }

    render(){
        const { props,renderRadioButton,renderPrevButton,renderNextButton } = this;
        let { loading,patientId,hasHS,currentProgram } = props;
        const { allVisit,fromDate,toDate,value,timeInterval } = this.state;
        return   <div style={{ padding: 20 }} className='row'>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <div style={{ fontSize:16,fontWeight:'bold' }}>Weight</div>
                        {
                           !allVisit ?
                           <div style={{marginLeft: 'auto', display: 'flex', marginRight: 40}}>
                                {renderPrevButton()}
                                {renderRadioButton()}
                                {renderNextButton()}
                            </div> :''
                        }
                            <span style={{ marginRight: 20,marginLeft: allVisit ? 'auto':'' }}>All Data</span> <Switch onChange={()=>this.setAllData()} />
                        </div>
                        <HSAreaChartComponent
                            allVisit={allVisit}
                            patientId={patientId}
                            changePre={() => this.changePre()}
                            changeNext={() => this.changeNext()}
                            setTimeInterval={(i, v) => this.setTimeInterval(i, v)}
                            fromDate={fromDate.toDate()}
                            toDate={toDate.toDate()}
                            value={value}
                            timeInterval={timeInterval}
                            {...props}
                            currentProgram={currentProgram}/>
                </div>
    }
}

// const withData = graphql( resultList, {
//     options: (ownProps) => {
//         return {
//             variables: {
//                 "filters": {
//                     "enrolledProgramId": ownProps.currentProgram.id,
//                     "type": "HS"
//                 },
//                 "count": 999999, // retrieve all at once; intended behavior
//                 "sort": {field: 'DATE', direction: 'ASC'}
//             },
//             notifyOnNetworkStatusChange: true,
//             fetchPolicy: 'network-only'
//         }
//     },
//     props: ({ownProps, data}) => {
//         const { loading, resultList } = data;
//         if(loading){
//             return {
//                 loading
//             }
//         }
//         return {
//             vitalList: resultList.data,
//             loading,
//         }
//     }
// })
/*
const withData = graphql( visitsWithWeight, {
    options: (ownProps) => {
        return {
            variables: {
                "filters": {
                    memberId: ownProps.patientId,
                    "type": "HS"
                },
                "count": 999999, // retrieve all at once; intended behavior
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ownProps, data}) => {
        console.log(data);
        const { loading, visitList } = data;
        if(loading){
            return {
                loading
            }
        }
        const dataList = visitList.data;
        const visitsWithWeight = _.filter(dataList,(d)=>_.get(d,'details.weight')&&(_.get(d,'appointmentAt')||_.get(d,'checkinAt')));

        return {
            vitalList: visitsWithWeight,
            loading,
        }
    }
})*/
export default  (HSAreaChartContainer);
