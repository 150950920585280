import gql from 'graphql-tag'
export default gql`query taskAssignmentList($page:Int,$count:Int,$filters:TaskAssignmentListOpts,$sort:TaskAssignmentListSort){
  taskAssignmentList(page:$page,count:$count,filters:$filters,sort:$sort){
    data{
      dueDate
      assignedTo{
        id
        profile{
          fullName
        }
      }
      member{
        id
        profile{
          fullName
        }
      }
      doctor{
        id
        profile{
            fullName
        }
      }
      createdAt
      createdBy{
        id
        profile{
          fullName
        }
      }
      taskAssignments{
         id
         status
         createdAt
         member{
            id
            profile{
                fullName
            }
            identification{
                MRN
                value
            }
            
         }
         doctor{
            id
            profile{
                fullName
            }
        }
        resolvedNote{
            note
            resolvedBy{
                profile{
                    fullName
                }
            }
            resolvedAt
        } 
        dueDate
        assignedTo{
          id
          profile{
           fullName
          }
        }
        reason
        createdBy{
          id
          profile{
              fullName
        }
        }
        priorityLevel
      }
    }
    
    pageInfo{
      total
      lastPage
    }
  }
}`;
