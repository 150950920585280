import AddFoodLogNotes from './AddFoodLogNotes';
import actions from "../actions";
import React from "react";
import { Tabs } from 'antd';
import { connect }from 'react-redux';
import TimerContainer from '../../timer/containers/TimerContainer';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const TabPane = Tabs.TabPane;
const FoodDiaryLogBook = class extends React.Component {
    static displayName = 'FoodDiaryModule';

    constructor(props) {
        super(props);
        this.state = {
            imgIndex:0,
            activeKey:'0'
        }
    }

    setActiveKey(activeKey){
        this.setState({
            activeKey
        });
    }

    render(){
        const { rows,userId,hidePatientName } = this.props;
        const { activeKey } = this.state;
        const { props } = this;
        let displayName = this.constructor.displayName;
        console.log(rows);

        const fullName = _.get(rows[0],'member.profile.fullNameWithNickname','');
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const tabPane = _.map(rows,(row,key)=>{
            const isRated = _.get(row,'rating.star')!==0;
            const imgSrc = _.get(row,'images[0].link');
            const className = `${isRated ? 'icon-check' :''} foodDiaryLogImgHeader`;

            return  <TabPane key={key}
                             tab={
                                     <div data-id={key+1} className={className}>
                                        <img src={imgSrc} width='118px' height='56px'/>
                                     </div>
                                }
                    >

                    <AddFoodLogNotes  userId={userId} row={row} reset={this.props.reset}
                                      setRating={this.props.setRating}
                                      updateComment={this.props.updateComment}
                                      renderStar={this.renderStar}
                                      key={key}
                                      hidePatientName={hidePatientName}
                                      {...props}
                    />
                  </TabPane>

        })

        return<div style={{textAlign:'start',fontSize:'22px'}}>
               <Tabs type="" className='foodDiaryLogBookTab' onChange={(e)=>{
                   Mixpanel.track('clicked','preview picture','food log preview pop up', {PATIENT_ID : rows[e].member.id});
                   }}>
                 {tabPane}
               </Tabs>
              </div>
    }

}

const mapToDispatch = (dispatch)=>{
    return {
        setRating: (id,rating)=>dispatch(actions.setRating(id,rating)),
        updateComment:(id,comments)=>dispatch(actions.updateComment(id,comments)),
        reset: (id)=>dispatch(actions.reset(id)),
    }
}

export default connect(null,mapToDispatch)(FoodDiaryLogBook);
