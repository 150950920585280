import React from 'react';
import PostItCardComponent from '../components/PostItCardComponent';
import { Row,Col,Button,Modal,Icon } from 'antd';
import _ from 'lodash';
import '../style/index.scss';
import PostItCreateComponent from '../components/PostItCreateComponent';
import PostItEditComponent from '../components/PostItEditComponent';

const PostItPatientListComponent = class extends React.Component {
    constructor(){
        super();
        this.state={
            note:'',
            post: { },
            visible:false
        }
    }

    renderCreateButton = ()=>{
        const { setShowCreateComponent,openModal,createPostIt } =  this.props;
        return <Button size='small'
                       type='primary'
                       style={{ backgroundColor:'rgb(66, 120, 195)' ,border: 'unset',float:'right' }}
                       onClick={ ()=> setShowCreateComponent(true)}
                >+</Button>
    }

    setNote = (note)=>{
        this.setState({
            note
        })
    }

    setPost = (post)=>{
        this.setState({
            post
        })
    }

    render(){
        const { postItList,className,showConfirmForDeletePostIt,showCreateComponent,showEditComponent,setShowEditComponent,editPostId, setShowCreateComponent,createPostIt,patientId } = this.props;
        const { note,post } = this.state;

        return<div className={className} >
                { showCreateComponent ?
                    <Modal visible={ showCreateComponent } className='createPostItModal' closable={false}>
                        <PostItCreateComponent createPostIt={ createPostIt } setNote={ this.setNote } note={ note }
                                               setShowCreateComponent={ setShowCreateComponent }
                                               patientId = { patientId }
                        />
                    </Modal> :''
                }
                { showEditComponent ?
                    <Modal visible={ showEditComponent } className='createPostItModal' closable={false}>
                        <PostItEditComponent setShowEditComponent={setShowEditComponent}
                                             setNote = { this.setNote } note={ note } editPostId = { editPostId }
                                             setPost = { this.setNote }
                                             patientId = { patientId }
                                             post = { post }
                        />
                    </Modal> :''
                }
                <Row >
                    <Col span={12} style={{ padding: '10px 10px 0px 10px',fontWeight:'bold' }}>
                        <span style={{ fontSize:16 }}>Stickie Post-It</span>
                    </Col>
                    <Col span={12} style={{ padding: '10px 20px' }}>{ this.renderCreateButton() }</Col>
                </Row>

                {_.map(postItList,(post,i)=><PostItCardComponent key={i} post={ post }
                                                                 showConfirmForDeletePostIt = { showConfirmForDeletePostIt }
                                                                 setShowEditComponent = { setShowEditComponent }
                                                                 setNote = { this.setNote } note={ note }
                                                                 setPost = { this.setPost }
                                                                 editPostId = { editPostId }
                />)}
              </div>
    }
}

export default  PostItPatientListComponent;
