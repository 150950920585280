import React from 'react';
import { IHSearchTable, IHLoading, IHButton, _,IHInput } from 'ihcomponent';
import { goPath } from 'libModule/utils'
import { connect }from 'react-redux';
import {commonActionsHelper} from 'libModule/helpers/action-helpers';
import MedicineModalComponent from './MedicineModalComponent';
import createMedication from 'graphqlModule/mutation/createMedication';
import editMedication from 'graphqlModule/mutation/editMedication';
import { Modal,Timeline } from 'antd';
import moment from 'moment';
const enumToTyep = {
    'ORAL':'Oral Medication',
    'INJECTABLE':'Injectable',
    'EXTERNAL':'External',
    "INHALED":'Inhaled'
}
const statusEnumToType = {
    'NOT_TAKING':'Not Taking',
    'NOT_SURE':'Not Sure',
    'TAKING': 'Taking'
};

const Item = Timeline.Item;

const MedicineListComponent = class extends React.Component {
    static displayName = 'MedicineListComponent';

    constructor(props) {
        super(props);
        this.state = {
            medicine: null,
            modal: { },
            showHistory:false,
            history:[]
        }
    }

    renderMedicineModal(){
        const { showMedModal,setShowMedModal } = this.props;

        if(showMedModal){
            const { props,setModal,setMedicine } = this;

            return <Modal visible={true}
                          footer={null}
                          mask={false} width={'fit-content'}
                          maskClosable = { false }
                          destroyOnClose={ true }
                          onCancel={()=>setShowMedModal(false)}
                    >
                        <MedicineModalComponent {...props} modal = {this.state.modal} curMed={this.state.medicine} setModal={setModal} setMedicine={setMedicine}/>
                   </Modal>
        }
    }

    renderMedicineHistory(){
        const { showHistoryModal,setShowHistoryModal,curMed } = this.props;
        const { history,name } = this.state.history;
        const filteredHis = _.filter(history,h=>h.field);
        if(showHistoryModal){
            return <Modal visible={showHistoryModal} closable={true} footer={null}
                          mask={false} width={'fit-content'}
                          style={{minWidth:300}}
                          destroyOnClose={ true }
                          onCancel={()=>setShowHistoryModal(false)}
            >
                {
                    filteredHis.length > 0 ?
                        <div style={{ padding:20 }}>
                            <h4 style={{ marginLeft: 18,marginBottom: 30 }}>{ name }</h4>
                            <Timeline mode={'alternate'}>
                                {_.map(filteredHis, ({originalValue, newValue, field, modifiedAt}, index) =>
                                    <div style={{ display:'flex' }} key={index}>
                                        <li className='ant-timeline-item' style={{ marginRight:10 }}>
                                            <div className='ant-timeline-item-content'>{ moment(modifiedAt).format('MM/DD/YYYY') }</div>
                                        </li>
                                        <Item key={index}>{`${_.upperFirst(field)} changed ${originalValue} to ${newValue}`}</Item>
                                    </div>)
                                }
                            </Timeline>
                        </div> :
                        <div>
                            <h4 style={{ marginLeft: 20 }}>{ name }</h4>
                            <span style={{ marginLeft: 20 }}>No History</span>
                        </div>
                }
            </Modal>
        }
    }

    renderCurrentMedList = (tableProps,noDataElem)=>{
       let { dataSource } = tableProps;
       dataSource = _.filter(dataSource,d=>!d.endDate||d.endDate >= new Date().getTime());
       tableProps.dataSource = dataSource;
       tableProps.title = 'Current Medications';
       return  <IHSearchTable {...tableProps} noDataElem={noDataElem} />
    }

    renderPreMedList = (tableProps,noDataElem)=>{
        let { dataSource } = tableProps;
        dataSource = _.filter(dataSource,d=>d.endDate&&d.endDate < new Date().getTime());
        tableProps.dataSource = dataSource;
        tableProps.title = 'Previous Medications';
        tableProps.actions = null;
        const colLen = tableProps.columns.length;
        tableProps.columns = tableProps.columns.slice().splice(0,colLen-1);

        return  <IHSearchTable {...tableProps} noDataElem={noDataElem} />
    }

    setModal = (modal)=>{
        modal.query = editMedication;
        if(modal.type=='update') {
            this.setState({
                modal
            });
        }
    }
    setMedicine = (medicine)=>{
        this.setState({
            medicine
        })
    }
    render() {
        const {loading,page,medicationList,setShowMedModal,setShowHistoryModal } = this.props;
        const { props } = this;
        const { sort,filter } = props;
        const data = _.get(medicationList,'data')||[];
        // Columns & data for alerts table
        const modal = {
            type:'add',
            query:createMedication
        }
        const b = {
            type: 'primary',
            label: 'Add Medication',
            style: {
                position: 'absolute',
                top: '8px',
                right: '20px',
                cursor: 'pointer'
            },
            onClick:()=>{
                setShowMedModal(true);
                this.setState({
                    modal,
                    medicine:null
                })
                // this.props.openModal(<MedicineModalComponent {...props} modal = {addProps}/> )
            },
            size: 'small'
        }

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                width:'15%',
            },
            {
                title: 'Type',
                dataIndex: 'type',
                filters: [
                    { text: 'Oral Medication', value:  'ORAL' },
                    { text: 'Injectable', value:'INJECTABLE' },
                    { text: 'External', value:'EXTERNAL' },
                    { text: 'Inhaled',value:'INHALED' }
                ],
                onFilter: (value,row) => {
                    return row.type == value;
                },
                render:(value)=> {
                    return enumToTyep[value]
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                filters: [
                    { text: 'Taking', value:  'TAKING' },
                    { text: 'Not Sure', value: 'NOT_SURE' },
                    { text: 'Not Taking', value: 'NOT_TAKING' },
                ],
                onFilter: (value,row) => {
                    return row.status == value;
                },
                render:(value)=> {
                    return statusEnumToType[value]
                }
            },
            {
                title:'Dosage',
                width:'10%',
                dataIndex:'dosage',

            },
            {
                title: 'Instruction',
                width:'25%',
                dataIndex: 'instruction',
            },
            {
              title:'Start Date',
              dataIndex:'startDate',
              render:(v)=>v ? moment(v).format('MM/DD/YYYY') : '',
              sorter: (a,b)=>a.startDate-b.startDate
            },
            {
              title:'End Date',
              dataIndex:'endDate',
              render:(v)=>v ? moment(v).format('MM/DD/YYYY'):'',
              sorter:(a,b)=>a.endDate-b.endDate
            },
            {
                title:'',
                render:(curMed)=>{
                    const buttonProps = {
                        label: 'History',
                        size: 'small',
                        bsStyle: 'primary',
                        onClick: (e) => {
                            setShowHistoryModal(true);
                            this.setState({
                                history:{ history:curMed.histories,name:curMed.name }
                            })
                        },
                        className: 'table-action-button'
                    }
                    return <IHButton {...buttonProps} />
                }
            },
            {
                title: '',
                width:'10%',
                render: (curMed, row) => {
                    const buttonProps = {
                        label: 'Edit',
                        size: 'small',
                        bsStyle: 'primary',
                        onClick: (e) => {

                            const modal = {
                                type:'update',
                                query:editMedication
                            }
                            setShowMedModal(true);
                            this.setState({
                                medicine: curMed,
                                modal
                            })
                            // this.props.openModal(<MedicineModalComponent {...props} curMed ={ curMed } modal={modal}/>);
                        },
                        className: 'table-action-button'
                    }
                    return <IHButton {...buttonProps} />
                }
            }
        ];

        let tableProps = {
            columns:  columns,
            rowKey: 'id',
            // pagination: {
            //     current: _.get(page, 'current', 1),
            //     pageSize: 10,
            //     total: _.get(props.medicationList, 'pageInfo.total', 0),
            // },
            // onChange:(a,b,c)=>this.props.onTableChange(a,b,c) ,
            loading: { spinning:loading,indicator: <IHLoading/> },
            dataSource: _.isEmpty(data) ? []:data,
            optionsBox: true,
            actions: <IHButton {...b}/>
        };

        const noDataElem = (
            <div style={{  paddingTop: '2em',display:'flex',flexDirection:'column',alignItems:'center',fontFamily:'HelveticaNeue',color:'#c5c5c5' }}>
                <div>
                    <img  src="/image/no-medication-graphic.svg"
                          className="no-medication-graphic" width='116.6px' height='56.1px'/>
                </div>
                <div style={{ fontSize: '26px',marginTop:20,marginBottom:10}}>No Medications</div>
                <div style={{fontSize:'16px',maxWidth:'405px'}}>
                    There are no medications recorded for this patient.
                    Click on Add Medication to start recording
                </div>
            </div>
        );
        return (
            <div className="patient-task-table">
                { this.renderMedicineModal() }
                { this.renderMedicineHistory() }
                { this.renderCurrentMedList(Object.assign({},tableProps),noDataElem) }
                <div style={{ height:20 }}></div>
                { this.renderPreMedList(Object.assign({},tableProps),noDataElem)}
            </div>
        );
    }
};

const mapToProps = (state)=>{
    return {
    ...state.medicineList.main,

    }
}
// const mapToDispatch = (dispatch)=>{
//     return {
//         initMedList:(data)=>dispatch(actions.initMedList(data))
//     }
// }

// export default MedicineListComponent;
export default connect(null,null)(MedicineListComponent);
