import {Form} from 'antd';
import React, {Component, Fragment} from 'react';
import moment from 'moment';
import { goPath } from 'libModule/utils'

import getLatestA1C from "../../graphql/getLatestA1C";
import Client from 'libModule/gqlClient';
import {helpers as dataHelper} from "../helper"
import VisitPersonHeader from "./VisitPersonHeader"
import VisitInputTypeAndProvider from "./VisitInputTypeAndProvider";
import VisitInputA1C from "./VisitInputA1C";
import VisitInputTime from "./VisitInputTime";
import VisitInputVisitAndNote from "./VisitInputVisitAndNote";
import VisitInputNote from "./VisitInputNote";
import VisitInputCheckbox from "./VisitInputCheckbox";
import VisitInputBottomButton from "./VisitInputBottomButton";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import monthMenuMap from "../../utils/constants/monthMenuMap";

const dateFormat = 'MM/DD/YYYY';

class InputVisitFormComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            // id: null,
            // A1C: null,
            // A1CDate: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clickPatient = this.clickPatient.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    clickPatient(){
        const { isFromVisitTable } = this.props;
        const memberId = _.get(this,'props.visitInfo.member.id');
        if(isFromVisitTable){
            goPath(`/patients/${memberId}/enrolledprogram/default`)
        }
        this.props.backToSearch();
    }

    componentDidMount() {
        const {patient, visitInfo} = this.props;
        let userId = null;
        if(patient){
            userId = patient.user.id;
        } else {
            userId = visitInfo.member.id;
        }

        if(visitInfo && visitInfo.labResult){
            // const data = visitInfo.labResult;
            // const date = data.dateCollected;
            // const value = data.results[0].value;
            // this.setState({
            //     A1C: value,
            //     A1CDate: date
            // });
            return;
        }

        Client.query({
            query: getLatestA1C,
            variables: {memberId: userId},
            fetchPolicy: 'network-only'
        })
            .then(res => {
                if(!res.data.getLatestA1C){
                    return;
                }
                const data = res.data.getLatestA1C;
                const id = data.id;
                const date = data.dateCollected;
                const value = data.results[0].value;
                this.setState({
                    id: id,
                    A1C: value,
                    A1CDate: date
                })

            })
            .catch(e => {
                console.log(e);
            })

    }


    handleSubmit(e){
        e.preventDefault();

        const { editStatus } = this.props;

        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);

                const A1CDate = values.A1CDate;
                const A1C = values.A1C;
                let  A1CVariables = null;
                if(A1C && A1CDate){
                    //have input data
                    if(!this.state.A1C){
                        // no previous data, save data
                        A1CVariables = {
                            value: A1C,
                            dateCollected: A1CDate.toDate().getTime(),
                        }
                    } else {
                        //have previous data, create and save status are same.
                        const start_date = moment(this.state.A1CDate);
                        const end_date = moment();
                        if ((end_date.diff(start_date, 'days')) < 30) {
                            //create and save status, previous date < 30
                            const originalDate = moment(new Date(this.state.A1CDate)).format(dateFormat);
                            const modifiedDate = A1CDate.format(dateFormat);
                            if (this.state.A1C != A1C || originalDate != modifiedDate) {
                                // if input data is different, save
                                A1CVariables = {
                                    value: A1C,
                                    dateCollected: A1CDate.toDate().getTime(),
                                }
                            } else {

                            }
                        } else {
                            //create and edit status, previous date > 30, input < 30 save, input > 30 no save
                            const start_date = moment(A1CDate);
                            const end_date = moment();
                            if ((end_date.diff(start_date, 'days')) < 30) {
                                A1CVariables = {
                                    value: A1C,
                                    dateCollected: A1CDate.toDate().getTime(),
                                }
                            }
                        }
                    }
                } else {
                    //no input data.
                    if(editStatus) {
                        //edit status, no save
                    } else {
                        //create status, previous date < 30, save previous A1C
                        if(this.state.A1C){
                            // const start_date = moment(this.state.A1CDate);
                            // const end_date = moment();
                            // if((end_date.diff(start_date, 'days')) < 30){
                                A1CVariables = {
                                    id: this.state.id,
                                    value: this.state.A1C,
                                    dateCollected: this.state.A1CDate,
                                }
                            // }
                        }
                    }
                }
                this.handleInitialAndFollowUpDate(values, A1CVariables);
            } else {
                console.log(err);
            }
        });
        Mixpanel.track('clicked', 'create', 'create a visit popup', '', {});
    };

    handleInitialAndFollowUpDate(values, A1CVariables){

        const { createVisit, visitInfo, editStatus, editVisit } = this.props;
        const date = values.date;
        const hour = values.hour;
        const min = values.min;

        const combinedStartTime = dataHelper.combineDateHourAndMin(date,parseInt(hour),parseInt(min)) + 0;
        const combinedEndTime = combinedStartTime + 1800000; //30 minutes

        const initialType = this.getInitialTypeStatus();
        let variables = null;
        if(initialType){
            variables = {
                id: visitInfo ? visitInfo.id : null,
                memberId: visitInfo ? visitInfo.member.id:  this.props.patient.user.id,
                type:values.type,
                appointmentAt:combinedStartTime,
                appointmentTo:combinedEndTime,
                reason:values.reason,
                providers:[values.doctor],
                visitRoles: Object.keys(dataHelper.VISIT_ROLES),
                conditionsList: dataHelper.handleConditionsList(values.conditionsList),
                // needInHouseA1C: values.needInHouseA1C,
                A1C: A1CVariables
            }
        } else {
            variables = {
                id: visitInfo ? visitInfo.id : null,
                memberId: visitInfo ? visitInfo.member.id:  this.props.patient.user.id,
                type:values.type,
                appointmentAt:combinedStartTime,
                appointmentTo:combinedEndTime,
                reason:values.reason,
                providers:[values.doctor],
                visitRoles: values.visitRoles,
                // needInHouseA1C: values.needInHouseA1C,
                A1C: A1CVariables
            }
        }

        if(editStatus){
            editVisit(variables);
        } else {
            createVisit(variables);
        }
    }

    handleDelete(){
        const { visitInfo } = this.props;
        this.props.handleDelete(visitInfo);
    }

    getInitialTypeStatus(){
        const {visitInfo, patient, editStatus} = this.props;
        let initialType = false;
        if(editStatus){
            initialType = visitInfo.type === 'INIT' ;
        } else {
            const typeAndStatus = dataHelper.getTypeAndStatus(patient.enrolledPrograms, patient.user.profile.lastMeasuredAt);
            if(typeAndStatus.status === 'Potential'){
                initialType = true;
            }
        }
        return initialType;
    }

    renderInitialOrFollowUp(){

        const {visitInfo, patient, editStatus, disableSaveButton} = this.props;
        const initialType = this.getInitialTypeStatus();

        let A1CData = {};
        if(visitInfo && visitInfo.labResult){
            const data = visitInfo.labResult;
            A1CData.A1CDate = data.dateCollected;
            A1CData.A1C = data.results[0].value;
        }

        // id: null,
        //     A1C: null,
        //     A1CDate: null

        return (
            <Fragment>
                <VisitInputTypeAndProvider form={this.props.form}
                                           initialType={initialType}
                                           visitInfo={visitInfo}
                                           editStatus={editStatus} />
                { !initialType ? <VisitInputVisitAndNote form={this.props.form}
                                                         visitInfo={visitInfo}
                                                         editStatus={editStatus}
                                /> : ''}
                <VisitInputA1C form={this.props.form}  editStatus={editStatus}
                                                       data={editStatus ? A1CData : this.state}
                                                        patient={patient}
                                                        visitInfo={visitInfo}/>
                <VisitInputTime form={this.props.form} visitInfo={visitInfo} editStatus={editStatus} curTab={this.props.curTab}/>
                { initialType ? <VisitInputNote form={this.props.form} visitInfo={visitInfo} editStatus={editStatus} /> : ''}
                { initialType ? <VisitInputCheckbox form={this.props.form} visitInfo={visitInfo} editStatus={editStatus}/> : ''}
                <VisitInputBottomButton editStatus={editStatus}
                                        handleDelete={this.handleDelete}
                                        disableSaveButton={disableSaveButton}/>
            </Fragment>
        )
    }

    render() {
        const { clickPatient } = this;
        const {visitInfo, patient, backToSearch, fromAppointments, editStatus,isFromVisitTable,setShowTaskModal, fromDetail} = this.props;
        return (
            <div className="visit_popup_patient">
                <VisitPersonHeader patient={patient}
                                   clickPatient={clickPatient}
                                   visitInfo={visitInfo}
                                   fromAppointments={fromAppointments}
                                   isFromVisitTable={isFromVisitTable}
                                   editStatus={editStatus}
                                   fromDetail={fromDetail}/>
                { isFromVisitTable ? <a onClick={()=>setShowTaskModal(visitInfo)} style={{ textDecoration:'underline' }}>Show Tasks</a>:' ' }
                <Form onSubmit={this.handleSubmit}>
                    {this.renderInitialOrFollowUp()}
                </Form>
            </div>
        );
    }
}

InputVisitFormComponent = Form.create({
    onValuesChange:(props)=>{
    const { disableSaveButton } = props;
    if(disableSaveButton) {
        props.setDisableSaveButton(false);
    }
}
})(InputVisitFormComponent);

export  default  InputVisitFormComponent;
