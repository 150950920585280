import React from 'react';
import { Form, Row, Col, Input, Button, Modal } from 'antd';
import placeholder from '../constant/InputPlaceHolder';
const { TextArea } = Input;
const { assess1, assess2 } = placeholder;
const FormItem = Form.Item;
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram';
import Client from 'libModule/gqlClient';
import enrolledProgram from 'modulesAll/graphql/enrolledProgram';
import { IHSearchTable, IHLoading, IHButton, _, IHInput, message } from 'ihcomponent';
import helpers from '../helpers/';
import { goPath } from '../../../lib/utils';
import moment from 'moment/moment';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { browserHistory } from 'react-router';

const { leaveAndSaveWorkTask } = helpers;
const todayStr = moment().format('MM/DD/YYYY');
const timestampTemplateStr = `========${todayStr}========`;

const parseValue = props => {
  const { form, enrolledProgramId, enrolledProgram } = props;
  const { getFieldsValue } = form;
  const currentAssess = _.get(enrolledProgram, 'assessments', '');
  const { assessment1 = '', assessment2 = '', Assessments = '' } = getFieldsValue();
  const assessments = assessment1.concat(assessment2) + Assessments;
  //add timestamp of today here
  // 20200304 Thong
  //const formattedAssessments = timestampTemplateStr + '\n' + assessments;
  // console.log(formattedAssessments);
  return {
    id: enrolledProgramId,
    assessments //: formattedAssessments
  };
};

let NutritionComponent = class extends React.Component {
  saveEdit = (flag, setEditMode, msg) => {
    const { props } = this;
    const { toNextStep, setIsEditMode, resetDraftState } = props;
    // const { getFieldsValue } = form;
    // const { assessment1='',assessment2='',Assessments='' } = getFieldsValue();
    // const assessments = assessment1.concat(assessment2)+Assessments;
    Client.mutate({
      mutation: editEnrolledProgram,
      variables: parseValue(props)
    })
      .then(res => {
        flag ? toNextStep(null, 'Assessment', msg) : message.success(msg);
        setIsEditMode(false);
        resetDraftState();
      })
      .catch(e => {
        Modal.error({
          content: e.message
        });
      });
  };

  renderForm = isFollowUp => {
    const { props } = this;
    const { form, enrolledProgramId } = props;
    const enrolledProgram = _.get(props, 'enrolledProgram', {});
    const { getFieldDecorator, setFieldsValue } = form;
    const { assessments } = enrolledProgram;
    const splitText = '1. Typical diet routine / Meal time or 24-hr recall';

    // check last timestamp for goal, if applicable
    // 1. if it is of today, don't add timestamp
    // 2. if it is in the past, add some spaces before with timestamp for today
    const isSameDay = _.includes(assessments, timestampTemplateStr);
    let followUpNotes = isSameDay ? assessments : `${timestampTemplateStr}\n\n${assessments || ''}`;

    const assessmentsSplited = (assessments && assessments.split(splitText)) || ['', ''];
    const assess1Fromep = assessmentsSplited[0];
    const assess2Fromep = assessmentsSplited[1];
    const assessFullTemplate = assess1.concat(assess2);

    const itemList = !isFollowUp
      ? [
        [
          {
            key: 'assessment1',
            label: 'Assessment',
            span: 12,
            rows: 30,
            initialValue: assess1Fromep ? 
              ((isSameDay ? '' : `${timestampTemplateStr}\n`) + assess1Fromep) : 
              (isSameDay ? assessFullTemplate.split(splitText)[0] : `${timestampTemplateStr}\n${assessFullTemplate.split(splitText)[0]}`),
            required: true
          },
          {
            key: 'assessment2',
            label: `   `,
            span: 12,
            rows: 30,
            initialValue: assess2Fromep ? splitText + assess2Fromep : assess2,
            required: true
          }
        ]
      ]
      : [
        [
          {
            key: 'Assessments',
            label: 'Assessment',
            span: 24,
            rows: 30,
            required: true,
            initialValue: followUpNotes
          }
        ]
      ];
    return _.map(itemList, (arr, i) => (
      <Row key={i} gutter={12} style={{ opacity: !enrolledProgramId ? 0.2 : 1 }}>
        {_.map(arr, (value, index) => {
          return (
            <Col span={value.span} key={index}>
              <FormItem label={value.label} key={value.key}>
                {getFieldDecorator(value.key, {
                  rules: [
                    {
                      required: value.required,
                      message: value.message || null
                    },
                    {
                      whitespace: true
                    }
                  ],
                  initialValue: value.initialValue,
                  valuePropName: value.valuePropName || 'value'
                })(value.render ? value.render(value) : <TextArea rows={value.rows} cols={value.col || 55} />)}
              </FormItem>
            </Col>
          );
        })}
      </Row>
    ));
  };
  renderPreNotes = assessments => {
    return assessments ? <TextArea disabled={true} value={assessments} autosize style={{ marginBottom: 10 }} /> : '';
  };

  handleOnClickSaveDraft = () => {
    const { form } = this.props;
    const { saveEdit } = this;
    form.validateFieldsAndScroll((error, fields) => {
      if (!error) {
        saveEdit(false, true, 'Assessment Draft is Saved');
      }
    });
  };

  handleOnClick() {
    const { form } = this.props;
    form.validateFieldsAndScroll((error, fields) => {
      if (!error) {
        this.saveEdit(true, false, 'Assessment is Saved');
      }
    });
  }
  // componentDidMount(){
  //     const { props } = this;
  //     leaveAndSaveWorkTask(null,'Nutrition',props);
  // }
  //
  componentWillUnmount() {
    const { props } = this;
    const { setIsEditMode, isEditMode } = props;
    if (isEditMode) {
      setIsEditMode(false);
    }
  }
  renderOverlayDiv = props => {
    const visitId = _.get(props, 'visit.id');
    const memberId = _.get(props, 'member.id');
    const path = `/patients/${memberId}/enrolledprogram/${visitId}_`;
    return (
      <div
        style={{
          width: '100%',
          position: 'absolute',
          height: '100%',
          top: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          onClick={() => {
            Mixpanel.track('clicked', 'enroll patients to the program', "on nutrition step", {});
            const curUrl = browserHistory.getCurrentLocation().pathname;
            if(_.endsWith(curUrl, 'dashboard')) {
              sessionStorage.setItem('backToCurVisit', JSON.stringify(props.visit));
              goPath(path);
            } else {
              props.onCloseModal();
            }
          }}
          type={'primary'}>
          Enroll Patient to Program
        </Button>
      </div>
    );
  };
  render() {
    const { saveEdit, props, handleOnClickSaveDraft } = this;
    const { loading, isEditMode, enrolledProgramId } = props;
    const visitType = _.get(props, 'visit.type');
    const assessments = _.get(props, 'enrolledProgram.assessments');
    const isFollowUp = visitType == 'FOLLOW_UP';
    if (loading) return <IHLoading />;
    return (
      <div className='assessment'>
        <h4>Chat with patient and finish up the assessment.</h4>
        {this.renderForm(isFollowUp)}
        {!enrolledProgramId ? this.renderOverlayDiv(props) : ''}
        {/*{isFollowUp ? this.renderPreNotes(assessments) : ''}*/}
        <div style={{ float: 'right' }}>
          {isEditMode ? (
            <Button onClick={() => handleOnClickSaveDraft()} style={{ width: 130, marginRight: 10 }}>
              Save Draft
            </Button>
          ) : (
              ''
            )}
          {enrolledProgramId ? (
            <Button onClick={() => this.handleOnClick()} type={'primary'} style={{ width: 130 }}>
              Complete
            </Button>
          ) : (
              ''
            )}
        </div>
      </div>
    );
  }
};
NutritionComponent = Form.create({
  onFieldsChange(props, fields) {
    const isCompleted = _.get(props, 'visit.rdWorkingList.Assessment') == 'COMPLETED';
    const { childProps, setChildProps, isEditMode } = props;
    if (!childProps) {
      setChildProps(props, parseValue, editEnrolledProgram, null, 'Assessment');
    }
    if (!isEditMode) {
      props.setIsEditMode(true);
    }
    if (isCompleted) {
      leaveAndSaveWorkTask(null, 'Assessment', props);
    }
  }
})(NutritionComponent);
export default NutritionComponent;
