import PropTypes from 'prop-types';
import React from 'react';
import LineChart from './LineChartComponentRevised'
import moment from 'moment'
import { alertColor } from '../constants/VitalListConstants'
import { convertUnits } from 'libModule/utils/convertUnits'
import { convertKgToPound } from 'libModule/utils/convertUnits'

const VSM_US_units = convertUnits('VSM_US');

const returnValidHS = (data) => (
  data.filter(d => !!d.body_weight)
      // .filter(d => d.taskStatus === 'COMPLETED' && !!d.body_weight)
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      'Weight': d.body_weight.value,
      date: d.date,
      key: 'Weight',
      id: d.id,
      datum: d
    }))
)

const returnMissed = (data) => (
  data.filter(d => d.taskStatus === 'MISSED')
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      date: d.date,
      datum: d
    }))
)
const setFillColorHS = (d, key) => {
  let defaultFill = alertColor['NORMAL']
  if (d.datum.taskStatus === 'MISSED') return '#dcdcdc'
  return (!!d.datum.severity)? alertColor[d.datum.severity] : defaultFill
}
const formatTooltipHS = (d) => {
  if (d.datum.taskStatus === 'MISSED') {
    return `Missed Measurement`
  }
  const {body_weight: bw } = d.datum
  const bwUnit = bw? bw.unit : VSM_US_units.HS.unit
  return `${bw? bw.value : '--'} ${bwUnit}`
}
const HSChart = ({ data, range, thresholds_bodyWeight }) => {

  let chartData = returnValidHS(data, ['Weight']);
  const missed = returnMissed(data)
  const setFillColor = setFillColorHS
  const formatTooltip = formatTooltipHS
  chartData = _.map(chartData,(c)=>_.set(c,'Weight',convertKgToPound(c.Weight)));

  const p = {
    data: chartData,
    missed,
    value: ['Weight'],
    lineColor: ['#0099ff'],
    setFillColor,
    formatTooltip,
    // thresholds: thresholds_bodyWeight,
    thresholds: { Weight: [null, null] },
    range: [range[0].startOf('day').toDate(), range[1].endOf('day').toDate()]
  };

  return <div style={{minHeight: '350px'}}><LineChart {...p} /></div>
}

HSChart.propTypes = {
  data: PropTypes.array,
  range: PropTypes.array
}
export default HSChart

