
// Check if a String is base64 encoded or not
const checkIfEncoded = (str) => {
  if (str.match(/^[0-9a-fA-F]{24}$/)) {
    return false;
  } else {
    return true;
  }
}

export const COMMON_HELPERS = {
  checkIfEncoded
}