import {UserMini} from './User'
import {APDevice as Device, Organization} from './common'
import {TaskAssessment} from './Assessment'

const TaskSchedule = `
  calendar
  target
  start
  end
  beforeMeal
  mealType
`

const DailyMealSchedule = `
  before_breakfast
  after_breakfast
  before_lunch
  after_lunch
  before_dinner
  after_dinner
  bedtime
  overnight
`

const WeeklyMealSchedule = `
  mon { ${DailyMealSchedule } }
  tues { ${DailyMealSchedule } }
  wed { ${DailyMealSchedule } }
  thurs { ${DailyMealSchedule } }
  fri { ${DailyMealSchedule } }
  sat { ${DailyMealSchedule } }
  sun { ${DailyMealSchedule } }
`

const TemplateDetails = `
templateDetails {
  name
  _id
  description
  schedule {
    ${WeeklyMealSchedule}
  }
}
scheduleType
`

const Notification = `
  type
  trigger
  chance
  tmpl {
    person
    subject
    body
  }
`

const Task = `
  id
  type
  description
  repeat
  schedule {
    ${TaskSchedule}
  }
  ${TemplateDetails}
  threshold {
    measure
    range
    baseline
    unit
  }
  assessment {
    ${TaskAssessment}
  }
  notification {
    ${Notification}
  }
`

const Program = `
  id
  createdAt
  updatedAt
  modifiedAt
  name
  description
  status
  healthCondition
  organization {
    id
  }
  tasks {
    ${Task}
  }
  duration {
    length
    calendar
  }
  devices {
    ${Device}
  }
`

const ProgramWithoutCreator = `
  id
  createdAt
  updatedAt
  name
  healthCondition
  organization {
    id
  }
  description
  status
  tasks {
    ${Task}
  }
  devices {
    ${Device}
  }
  duration {
    length
    calendar
  }
`

const ProgramWithoutActivatedAt = `
  id
  createdAt
  updatedAt
  name
  healthCondition
  organization {
    id
  }
  description
  status
  tasks {
    ${Task}
  }
  devices {
    ${Device}
  }
  duration {
    length
    calendar
  }
`

export {
  Program,
  ProgramWithoutCreator,
  ProgramWithoutActivatedAt,
  Task,
  TaskSchedule,
  Notification
}
