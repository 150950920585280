import React from 'react';
import { Input,Row,Col,Form,Button } from 'antd';
import moment from 'moment';
import { validator } from "../helper/validator";
import TimerContainer from '../../timer/containers/TimerContainer';
import actions from "../../medicine/actions";

const { TextArea } = Input;
const style = {
    fullNameStyle:{
        fontSize:16,
        fontWeight: 'bold'
    },
    timeStampStyle:{
        color: '#aaaaaa'
    }

}
let PostItCreateComponent = class extends React.Component{
    static displayName ='PostItCreateComponent';

    constructor(){
        super()
    }

    renderHeader=()=>{
        const createdAtString = moment().format('MM/DD/YYYY, HH:mm')
        const header = 'New Post-It';
        return<Row style={{ margin:10 }}>
                <Col span={12}><span style={style.fullNameStyle}>{header}</span></Col>
                <Col span={24}><span style={style.timeStampStyle}>{createdAtString}</span></Col>
             </Row>
    }

    handleDoneButton = async (note)=>{
        const { createPostIt,form,setNote } = this.props;
        const res = await createPostIt({note});
        if(res) {
            form.resetFields();
            setNote('');
        }
    }

    handleCancelButton = ()=>{
        const { setShowCreateComponent,setNote,form } = this.props;
        setShowCreateComponent(false);
        setNote('');
        form.resetFields();

    }

    renderButton=()=>{
        const { note } = this.props;
        const noteSize = _.get(note,'length',0);
        const disabled = noteSize==0 || noteSize > 250;
        return<Row style={{alignSelf:'flex-end'}}>
                <Button onClick={()=>this.handleCancelButton( ) } style={{marginRight:10}}>Cancel</Button>
                <Button type='primary' onClick={()=>this.handleDoneButton(note)} disabled={disabled}>Done</Button>
              </Row>

    }


    render(){

        const { setNote,form,note,patientId } = this.props;
        const { getFieldDecorator } = form;
        const noteSize = _.get(note,'length',0);
        const remainChar = 250-noteSize < 0 ? 0 : 250-noteSize;

        let displayName = this.constructor.displayName;
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };

        const keepEventListener = true;
        return <div>
                <div>{ this.renderHeader() }</div>
                    <TimerContainer displayName={'PostItContainer'} category='POSTIT' action='UPDATE'
                                    viewingDetails={viewingDetails} patientId={patientId}
                                    keepEventListener={keepEventListener}
                    />
                    <Form>
                        <Form.Item style={{ margin:0 }}>
                            {getFieldDecorator('note', {
                                rules: [{ required: true, message: 'Please Enter Post-It' },
                                        { validator: validator }],
                                initialValue:'',
                                value:note
                            })(
                                <TextArea style={{ height: 160 }} onChange={(e)=>setNote(e.target.value.trim())} />
                            )}
                        </Form.Item>
                    </Form>
                    <div style={{display: 'flex',
                        flexDirection: 'column',
                        width: '100%'}}>
                        <span style={{ alignSelf:'flex-end',color:'#aaaaaa',marginTop:'5px 0px',}}>{ remainChar } characters</span>
                        { this.renderButton() }
                    </div>
               </div>
    }
}

PostItCreateComponent = Form.create({})(PostItCreateComponent);

const mapToDispatch = (dispatch)=> {
    return {
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}

export default  PostItCreateComponent;
