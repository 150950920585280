import { IHLoading } from 'ihcomponent'
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import userGQL from 'graphqlModule/user'
import PropTypes from 'prop-types';
import ProviderEditContainer from 'providerModule/ProviderEdit/containers/ProviderEditContainer'
import get from 'lodash/get'
import { connect } from 'react-redux'

class Container extends Component {
  static displayName = 'provider/ProviderEdit/containers/index'

  render() {
    const { data } = this.props
    const userData = data.user
    if(!userData){
      return <IHLoading />
    }

    return <ProviderEditContainer userData={userData} {...this.props} />
  }
}
Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapState = (state, ownProps) => {
  const id = get(state.routing, 'params.providerId')
  return {
    ...state.provider.ProviderEdit,
    ...ownProps,
    id
  }
}

const withGql = graphql(userGQL, {
  options: (ownProps)=>{
    const id = ownProps.id
    return {
      variables: {
        id
      },
      notifyOnNetworkStatusChange: true,
    }
  }
})
const ContainerWithData = withGql(Container)

Container.propTypes = {
  data: PropTypes.object,
}

export default connect(
  mapState
)(ContainerWithData)
