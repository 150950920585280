import React, { Component } from 'react';
import { Modal } from 'antd';
import { IHLoading } from 'ihcomponent';

import ModalImageComponent from './ModalImageComponent';

export default class MessageThumbnailComponent extends Component {

  constructor(){
    super();
    this.state = {
        visible: false,
    }
  }

  showImageDialog = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };



  render() {
    const { fileKey, userId, memberFullName,thumbNailLink,onError,loading } = this.props;
    const modalParams = {
      onCancel: this.handleCancel,
      // title: memberFullName || 'File Upload',
      visible: this.state.visible,
      footer: null,
      width: 1200,
      height: 1200,


    };

    if(loading){
      return <IHLoading/>
    }

    return (
      <div style={{marginTop: 10}} >
        <a onClick={this.showImageDialog}>
          <img src={thumbNailLink} alt={'Handling Image Uploading...'} onError={ onError } />
        </a>
        <Modal {...modalParams} className='imageUploadModal'>
          <ModalImageComponent fileKey={fileKey} userId={userId} />
        </Modal>
      </div>
    );
  }
}
