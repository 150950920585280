import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Button,Tooltip,Popover } from 'antd';
import { goPath } from 'libModule/utils';
import { IHSearchTable, IHButton, _,IHTooltip,IHLoading } from 'ihcomponent';
import { formatDateMM } from 'modulesAll/utils/helpers/task';
import {get} from 'lodash';
import actions from "../../main/actions/MainActions";
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import createTableAction from 'libModule/table/TableActions';
import Store from 'libModule/reduxStore'
import { RequestCache } from 'libModule/utils';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

let statsMap = (()=>{
    const presetNotes = JSON.parse(sessionStorage.getItem('presetNotes'));
    const obj = {};
    _.each(presetNotes,(i)=>{
            obj[i.category] = i.display;
        })
    delete obj["GENERAL"];
    delete obj["MED_RECONCILIATION"];
    return obj;
})();

const callNoAnswerMap = ['CALLED_NO_ANSWER','CALLED_VOICE_MAIL_FULL','CALLED_OUT_OF_SERVICE'];
const callNoAnswer  = 'Called No Answer';

const diffInDaysFromTime = (time)=>{
    return moment().diff(moment(time),'days');
}

const tableActions = createTableAction('InactivePatient_list',{ shouldUpdateHistory: true });
const InactivePatientsComponent = class extends React.Component {
  static displayName = 'InactivePatientsComponent';

  constructor(props) {
    super(props);
    this.addReviewedBtns = this.addReviewedBtns.bind(this);
    this.checkIfReviewed = this.checkIfReviewed.bind(this);
    this.state = { reviewedBtnList: [] };
  }

  componentWillMount() {
    this.props.onLoadParams(null, true);
  }

  // componentWillUnmount() {
  //     // console.log(Store.getState().routing.location.pathname);
  //   if (new RegExp(/^(\/inactive_patients)/).test(Store.getState().routing.location.pathname)) {
  //       this.props.onLoadParams(null, false)
  //   }
  // }

  componentWillReceiveProps({providerNoteTagList}){
      if(providerNoteTagList&&!this.props.providerNoteTagList){
          const obj = {};
          _.each(providerNoteTagList,(i)=>{
             obj[i.key] = i.description
          });

          Object.assign(statsMap,obj);
      }
  }

  addReviewedBtns(id) { // TODO: Refactor to use prevState
    this.setState({ reviewedBtnList: [...this.state.reviewedBtnList, id] });
  }

  checkIfReviewed(id) {
    return this.state.reviewedBtnList.includes(id);
  }

  getColumns() {
    const columns = [{
      title: 'First Name',
      key: 'firstName',
      dataIndex: 'firstName',
      width:'8%',
      className:'inactive-patients-cell',
    },
    {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName',
      className:'borderRight',
      width:'8%'
    },
    {
      title:'Measurement',
      align:'center',
      className:'parentHeader',
      children:[
          {
              title: 'Days No Activity',
              key: 'lastMeasurementTime',
              dataIndex: 'lastMeasurementTime',
              width:'5%',
              className:'centerContent',
              render: (item, row) => item ? diffInDaysFromTime(item) :'Never'
          },
          {
              title: 'Total',
              key: 'totalMeasurement',
              width:'5%',
              className:'borderRight centerContent',
              dataIndex: 'totalMeasurement',
          },
      ]
    },
    {
      title:'Food Diary',
      className:'parentHeader',
      children:[
          {
              title: 'Days No Activity',
              key: 'lastFoodlogTime',
              dataIndex: 'lastFoodlogTime',
              className:'centerContent',
              width:'5%',
              render: (item, row) => item ? diffInDaysFromTime(item) :'Never'
          },
          {
              title: 'Total',
              key: 'totalFoodlog',
              width:'5%',
              className:'borderRight centerContent',
              dataIndex: 'totalFoodlog',
          },
      ]
    },
    {
      title:'Last Note',
      className:'parentHeader',
      children:[
          {
              title:'Date',
              key:'lastProviderNote.createdAt',
              dataIndex:'lastProviderNote.createdAt',
              width:'7%',
              className:'inactive-patients-cell centerContent',
              render: (item, row) => item ? formatDateMM(item) : null
          },
          {
              title:'Content',
              key:'lastProviderNote.content',
              dataIndex:'lastProviderNote.content',
              className:'borderRight',
              width:'8%',
              render: (item, row) => item  ? <Popover placement="top" arrowPointAtCenter content={item} overlayStyle={{width:400}}>{_.truncate(item,{length:60})}</Popover> : '',
          },
      ]
    },
    {
      title:'Days since',
      className:'parentHeader',
      children:[
          {
              title:'Enrollment',
              key:'enrolledSince',
              dataIndex:'enrolledSince',
              className:'centerContent',
              width:'7%',
              render: (item, row) => item ? diffInDaysFromTime(item) :'Never'
          },
          {
              title:'Visit',
              key:'lastVisitTime',
              dataIndex: 'lastVisitTime',
              className:'borderRight centerContent',
              width:'5%',
              render:(item)=> item ? diffInDaysFromTime(item) :'Never'
          },
      ]
    },
    {
        title:'Vital',
        key:'vitalTypes',
        dataIndex:'vitalTypes',
        className:'inactive-patients-cell',
        width:'5%',
        render:(item)=>item.join(', ')
    },
    {
      title:'Re-Engagement Statistics (2 weeks)',
      key:'stats',
      dataIndex:'stats',
      width:'12%',
      render:(item)=> {
          let count = 0;
          let content = [];

          item.length>0 ? item.map((i,index)=>{
              if(callNoAnswerMap.indexOf(i.name)!=-1){
                  count+=i.count;
              }
              else{
                  if(statsMap[i.name]&&i.name!='RE_ENGAGEMENT') {
                      content.push(<p key={content.length+1}>{`${statsMap[i.name]}:${i.count}`}</p>);
                  }
              }
          }) : '';

          if(count!=0) {
              let contentLen = content.length;
              content.push(<p key={contentLen+1}>{callNoAnswer}:{count}</p>);
          }
          if(item.length!=0){
              let contentLen = content.length;
              let index = item.map((e)=>{ return e.name; }).indexOf('RE_ENGAGEMENT');
              if(index!=-1){
                  content.push(<p key={contentLen+1}>Total Re-Engagement:{item[index].count}</p>);
              }
          }
          return content;
      }
    },
    {
        title: 'Phone',
        key: 'phones',
        width:'8%',
        dataIndex: 'phones',
        render: (doc, row) => {
            const parseCountryCode = (countryCode)=>{
                const index = countryCode.indexOf('+');
                if(index!=-1){
                    const part1 = countryCode.substring(0, index);
                    const part2 = countryCode.substring(index + 1, countryCode.length);
                    return (part1 + part2);
                }else{
                    return countryCode;
                }
            }
            const mobile = doc.filter((item)=>item.type=='MOBILE')[0];
            const home = doc.filter((item)=>item.type=='HOME')[0];
            const mobileNum = mobile ? `${parseCountryCode(mobile.countryCode)}${mobile.areaCode||''}${mobile.number}`:'';
            const homeNum = home ? `${parseCountryCode(home.countryCode)}${home.areaCode||''}${home.number}`:'';
            return <div>
                    <p>M:{mobileNum}</p>
                    <p>H:{homeNum}</p>
                  </div>
        }
    }
    ];

    return columns;
  }
  getTableProps() {
    const { enrolledUserStatusList, isLoading_enrolledUserStats,onTableChange,page, curPage, handleChangePage } = this.props;
    let checkPage = false;
    const tableProps = {
      columns: this.getColumns(),
      rowKey: (_,index)=>index,
      loading: { spinning:isLoading_enrolledUserStats,indicator: <IHLoading/> },
      dataSource: _.get(enrolledUserStatusList, 'inactiveUsers', []),
      title: `Inactive Patients (${ _.get(enrolledUserStatusList, 'inactiveUsers.length', '0') })`,
      onRow: (row) => {
        const id = btoa(`accounts:${atob(row.id)}`);
        return {
          onClick: (e) => {
            if(checkPage == false) {
              Mixpanel.calculateDuration(curPage);
              Mixpanel.track('clicked', 'patient item', 'inactive patient', {PATIENT_ID: row.id});
            }
            goPath(`patients/${id}/enrolledprogram/default`)
            Mixpanel.time_event('patient_profile');
            handleChangePage('patient_profile')
          }
        }
      },
      onChange: (p, f, s) => {
        const newFilter = { ...f};
        const newPage = {...p};
        return onTableChange(newPage, newFilter, s)
      },
      pagination:{
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize', 10),
        onChange:(e) => {
          Mixpanel.track('clicked', 'pager', 'inactive patient', {PAGE_NUM: e});
          checkPage = true;
        }
          // onChange:(current)=>{
          //     const url = window.location.pathname;
          //     const pathName = url.split('/')[1];
          //     this.setState({
          //         current
          //     })
          //     this.props.router.push(`/${pathName}/${current}`)
          // }
      }
    };
    return tableProps;
  }
  render() {
    const tableProps = this.getTableProps();
    const noDataElem = (
      <div style={{ height: '150px', paddingTop: '2em' }}>
        <p style={{ fontSize: '18px' }}>There is no data available</p>
      </div>
    );

    return <IHSearchTable {...tableProps} noDataElem={noDataElem} style={{textOverflow:'clip'}} align={'center'}/>;
  }
}

const mapStateToProps = (state, ownProps) => ({
    ...state.globalTables.InactivePatient_list,
    ...state.InactivePatients

});
const mapDispatchToProps = (dispatch) => {
    return {
        ...tableActions,
    }
}
InactivePatientsComponent.propTypes = {
  enrolledUserStatusList: PropTypes.object,
  currentProgram: PropTypes.array
};
export default compose(connect(mapStateToProps,mapDispatchToProps))(InactivePatientsComponent);
