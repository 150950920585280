import React, {Component} from 'react';
import {connect} from "react-redux";
import '../../visitNewWorkFlow/CSS/style.scss'
import { GQLHelper } from 'lib/utils';
import CURRENT_COMPONENT from '../../visitNewWorkFlow/constants/CurrentComponent'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { Modal } from 'antd';
import InputVisitFormComponent from "../../visitNewWorkFlow/components/InputVisitFormComponent";
import actions  from 'modulesAll/common/actions/modal'
import {API} from "../query";
import WorkListContainer from '../../visitWorkList/container/workListContainer';

class EditVisitInAppointmentsContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            value: CURRENT_COMPONENT.INPUT_VISIT_COMPONENT,
            disableSaveButton: true,
            showTaskModal: false
        };
        this.editVisit = this.editVisit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.setDisableSaveButton = this.setDisableSaveButton.bind(this);
    }

    showConfirmForCancel(id) {
        return new Promise((resolve,reject)=>{
            const Yes = {
                label: 'Yes, Cancel Appointment',
                handler: ()=>{
                    resolve(this.props.deleteVisit(id));
                },
                style:{
                    border: 'solid 1px #aaaaaa',
                    color:'red',
                    background:'white',
                    textShadow:'none'
                },
                closeModal: true
            }
            const No = {
                label: 'No',
                style:{
                    border: 'solid 1px #4278c3',
                    color:'#4278c3',
                    background:'white',
                    textShadow:'none'
                },
                handler: () => {
                    resolve(false)
                }
            }
            const modalProps = {
                Yes,
                No,
                title: 'Cancel Appointment?',
                content: 'Are you sure you want to cancel this appointment? This action can not be undone.'
            }
            this.props.openConfirmModal(modalProps)
        });
    }

    setDisableSaveButton(value){
        this.setState({
            disableSaveButton: value
        });
    }

    handleDelete(){
        const {visitInfo} = this.props;
        this.showConfirmForCancel(visitInfo.id);
    }

    editVisit(variables){
        const { saveChanges } = this.props;
        saveChanges(variables);
    }

    setShowTaskModal = (visit)=>{
        console.log(visit);
        this.setState({
            showTaskModal:visit
        })
    }
    renderTaskModal = () => {
        const {
            setShowTaskModal,
            state: { showTaskModal },
            props: { refetch: refetchVisitList }
        } = this;
        console.log(this.state);
        return showTaskModal ? (
            <Modal
                visible={true}
                onCancel={() => {
                    if (refetchVisitList) refetchVisitList();
                    setShowTaskModal(null);
                }}
                footer={null}
                width={1280}
                className='workListModal'
                destroyOnClose={true}
                maskClosable={false}
            >
                <WorkListContainer {...showTaskModal} />
            </Modal>
        ) : (
            <React.Fragment></React.Fragment>
        );
    };
    renderSelectComponent(){
        const { isFromVisitTable } = this.props;
        const { showWorkListModal } = this.state;
        if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT) {
            return (<InputVisitFormComponent visitInfo={this.props.visitInfo}
                                             editStatus={true}
                                             handleDelete={this.handleDelete}
                                             editVisit={this.editVisit}
                                             disableSaveButton={this.state.disableSaveButton}
                                             setDisableSaveButton={this.setDisableSaveButton}
                                             fromAppointments={true}
                                             isFromVisitTable={isFromVisitTable}
                                             backToSearch={this.props.renderFromEditToCreate}
                                             setShowTaskModal={this.setShowTaskModal}
                                             showWorkListModal={showWorkListModal}
            />)
        }
    }

    render() {
        return (
            <div className='newCreateVisitContainer'>
                {this.renderSelectComponent()}
                {this.renderTaskModal()}
            </div>
        )
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(actions.confirm(props)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}

export default connect(null,mapToDispatch)(EditVisitInAppointmentsContainer);

