import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo'
import _ from 'lodash';
import actions from '../actions';
import ChatInput from '../components/ChatInputComponent';
import ChatHistory from '../components/ChatHistoryComponent';
import ChannelListComponent from '../components/ChannelListComponent';
import PatientMain from 'patientModule/main/containers/ViewOnlyMain';
import ChatEmptyThirdPanel from '../components/ChatEmptyThirdPanel';
import EmptyChannelComponent from '../components/EmptyChannelComponent';
import { openErrorModal } from 'modulesAll/layout/actions/MainModal';
import { PubNubHelper } from 'libModule/utils';
import { API } from '../actions/api';
import { COMMON_HELPERS } from 'libModule/helpers/common-helpers'
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from "../../timer/actions";
import { Icon,Spin } from 'antd';
import $ from 'jquery';

const { updateInterventionDetails,startChat,startTimer }= timerActions.default;

// const timeFormatString = 'HH:mm A'; // TODO: Refactor to use i18n
import mesNotificationType from '../../chat/constants/messageTypes';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';
import {changeMenuStats} from "../../layout/actions/Nav";

import Client from 'libModule/gqlClient';

const unreadMsgType = mesNotificationType;

class ChatContainer extends Component {
  static displayName = 'chat/containers/ChatContainer';

  constructor(props) {
    super(props);
    const currentUserId = sessionStorage.getItem('currentUserId');
    const decodedUserId = atob(currentUserId).split(':')[1];
    this.sendMessage = this.sendMessage.bind(this);
    this.fetchHistory = this.fetchHistory.bind(this);
    this.getTimestampOfLastTextMsg = this.getTimestampOfLastTextMsg.bind(this);
    this.handleUnreadAndLastMsg = this.handleUnreadAndLastMsg.bind(this);
    this.beforeUnload = this.beforeUnload.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.state = {
        isLoading:false,
        showStickyHeader:false,
        height:0,
        loadingChatInfo: false
    }
    this.ref = null;
  }

  componentWillReceiveProps(nextProps){
      //if channel changed reset patientDiv back to 0 and set showStickyHeader back to false;
      const { selectedChannel } = this.props;
      if(nextProps.selectedChannel!=selectedChannel){
          this.props.setPatientDivHeight(0);
          this.setState({
              showStickyHeader:false
          })
      }
  }

  componentWillMount() {
    const { props } = this;
    const userId = sessionStorage.getItem('currentUserId');
    const decodedUserId = atob(userId).split(':')[1];

    props.setCurrentUserId(decodedUserId);
  }

  componentWillUnmount() {
    const { props, sendMessage } = this;
    const { channelName,showChat } = props;
    const channels = props.channels;
    const selectedChannel = props.selectedChannel;
    const sendACKMsg = ch=>{
        if(channels[ch]&&channels[ch].history.length>0){
            const lastMsg = channels[ch].history[channels[ch].history.length-1];
            const ackMessage = {
                publisher: `team-${ch.split('-')[0]}`,
                type:"ACK"
            };
            if(ch&&lastMsg&&lastMsg.entry.type!='ACK'||(lastMsg.entry.type=='ACK'&&!lastMsg.entry.publisher.includes('team-'))) {
                sendMessage(ch,ackMessage);
            }
        }
    }
    if(channels && selectedChannel){
        sendACKMsg(selectedChannel);
        // if(channels[selectedChannel]&&channels[selectedChannel].history.length>0){
        //     const lastMsg = channels[selectedChannel].history[channels[selectedChannel].history.length-1];
        //     const ackMessage = {
        //         publisher: `team-${selectedChannel.split('-')[0]}`,
        //         type:"ACK"
        //     };
        //     if(selectedChannel&&lastMsg&&(lastMsg.entry.type!='ACK'||lastMsg.entry.type=='ACK'&&!lastMsg.entry.publisher.includes('team-'))) {
        //         sendMessage(selectedChannel,ackMessage);
        //     }
        // }
    }
    else if(channels&&channelName&&(typeof showChat=='boolean'&&!showChat)) {
        sendACKMsg(channelName);
    }


    const isExpanded = $('.VSM-LeftNav').first().hasClass('VSM-LeftNav-expanded');

    if(!isExpanded) {
        this.toggleExpand();

    }

    this.props.clearHistory(); // TODO: Refactor!
    this.props.setSelectedChannel("");
  }

  toggleExpand() {
    document.querySelector('.VSM-LeftNav').classList.toggle('VSM-LeftNav-expanded');
    document.querySelector('#master').classList.toggle('pushed');
    document.querySelector('.vsm-top-nav').classList.toggle('pushed');
    document.querySelector('.v-logo').classList.toggle('logo-hidden');
    const isToggled = document.querySelector('.VSM-LeftNav-expanded');
    this.props.changeMenuStats(!!isToggled)
  }

  componentDidMount() {
    const { props } = this;
    const { setSelectedChannel,channelName,chatWithIndividual, isFullyLoaded,selectedChannel } = props;
    // if channels r not already set (for when user navigates to different page, then back to Chat page)
    const channelsArr = [];
    props.channelGroups ? props.channelGroups.map( val => {
        return val.channelGroups.map(channelList=>{
            return channelList.channels.map(channel=>{
                channelsArr.push(channel);
            });
        })
    }):[];

   // if(chatWithIndividual&&channelName&&isFullyLoaded&&selectedChannel.length==0){
   //    setSelectedChannel(channelName);
   // }

    const isExpanded = $('.VSM-LeftNav').first().hasClass('VSM-LeftNav-expanded');
    if(isExpanded) {
        this.toggleExpand();
    }

    window.addEventListener('beforeunload', this.beforeUnload);


  }

  componentDidUpdate(preProps) {
    const { props,ref } = this;
    const { setSelectedChannel,channelName,chatWithIndividual, isFullyLoaded } = props;
    const { height } = this.state;
    const { selectedChannel } = preProps
    // if channels r not already set (for when user navigates to different page, then back to Chat page)
      const channelsArr = [];
      props.channelGroups ? props.channelGroups.map( val => {
          return val.channelGroups.map(channelList=>{
              return channelList.channels.map(channel=>{
                  channelsArr.push(channel);
              });
          })
      }):[];
    const combinedChannels = _.flatten(channelsArr);
    const channels = props.channels;
    if (combinedChannels && combinedChannels.length > 0 ) {

       if(!channels||channels.length==0) {

                this.setChannels(combinedChannels);
            }
    }
  }
  // shouldComponentUpdate(nextProps){
  //   // let flag =false;
  //   // if (nextProps.channelGroups&&!_.isEqual(this.props.channelGroups, nextProps.channelGroups)) {
  //   //   // return true if not equal
  //   //   return true;
  //   // }
  //   // const channelsInProps = this.props.channels;
  //   // const channelIsEmpty = Object.keys(channelsInProps).length == 0 ? true : false;
  //   // if(nextProps.channels&&channelIsEmpty){
  //   //   return true;
  //   //
  //   // }
  //
  //   // tell React not to re-render by default
  //   return true;
  // }
  // if browser refresh, and there is selected channel, send ACK message
  beforeUnload() {
    const { props, sendMessage } = this;
    const channels = props.channels;
    const selectedChannel = props.selectedChannel;
    if(channels&&selectedChannel){
      if(channels[selectedChannel]&&channels[selectedChannel].history.length>0){
        const lastMsg = channels[selectedChannel].history[channels[selectedChannel].history.length-1];
        const ackMessage = {
          publisher: `team-${selectedChannel.split('-')[0]}`,
          type:"ACK"
        };
        if(selectedChannel&&lastMsg&&(lastMsg.entry.type==='text')) {
          sendMessage(selectedChannel,ackMessage);
        }
      }
    }
    this.props.clearHistory(); // TODO: Refactor!
    this.props.setSelectedChannel("");
  }

  sendMessage(selectedChannel, message) {
    const { props } = this;
    const displayName = this.constructor.displayName;
    const messageType = _.get(message,'type');
    if(messageType=="fileUpload"){
        Mixpanel.track('sent','image','message');
    }
    API.getPubNub().publish({
      channel: selectedChannel,
      message: message,
    }, (status, resp) => {
      if (status.error) {
        // props.openErrorModal(PubNubHelper.formatError(status));
        console.log('Error publishing: ', status);
        // get new authKey & re-subscribe to channels if expired
        if (status.category === 'PNAccessDeniedCategory' && status.operation === 'PNPublishOperation') {

          API.apiGetUserChatInfo()
            .then(res => {

              // need to re-subscribe after auth key reset
              const channelGroupsArr = res.data.getUserChatInfo.channelGroup ? [res.data.getUserChatInfo.channelGroup.name] : [];

              if (API.getPubNub()) {
                API.getPubNub().setAuthKey(res.data.getUserChatInfo.authKey);

                API.getPubNub().subscribe({
                  channelGroups: channelGroupsArr,
                  triggerEvents: true
                });

                API.getPubNub().publish({
                  channel: selectedChannel,
                  message: message,
                }, (status, resp) => {
                  if (status.error) {
                    console.log('Error re-publishing: ', status);
                  }

                });
              }
            })
            .catch(err => console.log('Error getUserChatInfo, ', err));
        }
      } else{
          // const PATIENT_FULL_NAME = _.get(message,'displayName','');
          const CHANNEL_ID = selectedChannel;
          Mixpanel.track('send','message',null,{ CHANNEL_ID });
      }
    });
  }

  handleUnreadAndLastMsg(messages){
      const curHistory = messages;
      const historyLength = curHistory.length;
      let lastIndex = historyLength-1 ;
      let lastUnreadAckIndex = historyLength-1;
      let unreadCountFromOffLine = 0;
      let lastMsgText = ( () =>{
          if (historyLength > 0 && lastIndex >= 0 && lastUnreadAckIndex >= 0) {
              // find index of last text message
              while(!(mesNotificationType[curHistory[lastIndex].entry.type])&&
              lastIndex > 0) {
                  lastIndex--;
              }
              while (lastUnreadAckIndex > 0) {
                  // console.log('curMsgEntry:', curMsgEntry);
                  const curMsgEntry = curHistory[lastUnreadAckIndex];
                  const isTeam = _.get(curMsgEntry,'entry.publisher','').split('-')[0] ==='team';
                  // check for encoded/decoded publishers
                  // also, catch 'push-notification-tester' publisher coming from mobile
                  try {
                      
                      if (typeof curMsgEntry === 'objcet' && (curMsgEntry.entry && (curMsgEntry.entry.publisher || curMsgEntry.entry.uuid)) &&
                          typeof COMMON_HELPERS.checkIfEncoded(curMsgEntry.entry.publisher || curMsgEntry.entry.uuid) === 'boolean' &&
                          COMMON_HELPERS.checkIfEncoded(curMsgEntry.entry.publisher || curMsgEntry.entry.uuid)) {
                          //check if current msg is a ack from team breaks
                          if(curMsgEntry.entry.type == 'ACK' && isTeam){
                              break;
                          }
                      } else {
                          if(curMsgEntry.entry.type == 'ACK' && isTeam){
                              break;
                          }
                      }

                  } catch(err) {
                      console.log('Err: ', err, ' Publisher on message not valid: ', curMsgEntry.entry || curMsgEntry.entry);
                  }

                  if(mesNotificationType[`${curMsgEntry.entry.type}`]){
                      unreadCountFromOffLine++;
                  }
                  lastUnreadAckIndex--;
              }

              return lastIndex >= 0 ? curHistory[lastIndex].entry.text : '';
          }
      })()
      const lastMsgTime = historyLength > 0 ? curHistory[lastIndex].timetoken : '';
      const lastMsgSender = historyLength > 0 ? _.get(curHistory[lastIndex],`entry.displayName`,'Notification') : '';
      const msgObj = {
        unreadCountFromOffLine:unreadCountFromOffLine,
        lastMsgText:lastMsgText,
        lastMsgTime:lastMsgTime,
        lastMsgSender:lastMsgSender

      }
      return msgObj;
  }

  fetchHistory(channel, patientId, lastMsgTimestamp,isBatch,toBottom) {
    const { props } = this;
    const channelId = channel || props.selectedChannel;
    if (channelId) {
        this.setState({isLoading:true},()=>{
        API.getPubNub().history({
          channel: channelId,
          count: 20,
          stringifiedTimeToken: true,
          start: lastMsgTimestamp, // starting point for fetching messages
        }, (status, response) => {
          let lastMsgTS;
          // if there is history, query & add to userMap & add to chat history
          if (response) {
            // 'start' option does not always return msgs up to startTimeToken (exclusive) so,
            // do not add duplicate messages to chat history
            const messageCount = response.messages.length;
            if (lastMsgTimestamp === response.endTimeToken) {
              const lastElem = response.messages.pop();
            }
            if (response.messages && response.messages.length > 0) {

              // if there are messages, return timestamp of last message
                lastMsgTS = parseInt(response.startTimeToken);
                    // _.get(_.first(response.messages),'timetoken');
            }
            // if start and end time token in response is same, there are no more history, do not add to redux
            if (response.messages){
                // ((response.startTimeToken !== response.endTimeToken) ||
                //     (response.startTimeToken == 0 && response.endTimeToken == 0)) {
              props.addChatHistory(channelId, patientId, response.messages, lastMsgTS,messageCount, false, toBottom);
            } else {

            }

            const channelCount = Object.keys(props.channels);

            if (channelCount.length == 0) {
              let msgObj = this.handleUnreadAndLastMsg(response.messages);
              msgObj.isListening = false;
              props.updateChannelInfo(channelId, msgObj);
            } else {

                let msgObj = this.handleUnreadAndLastMsg(props.channels[channelId].history);
                msgObj.isListening = false;
                props.updateChannelInfo(channelId,msgObj);
            }
            this.setState({
                isLoading:false
            })
          }
        });
      })
    }
  }

  getTimestampOfLastTextMsg(messages) {
      let lastMessageTimestamp = null;

      // since first message in array is oldest, get last msg timestamp starting from index 0
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].entry.type === 'text') {
          lastMessageTimestamp = messages[i].timetoken;
          break;
        }
      }
      return lastMessageTimestamp;
  }

  getSelectedPatientId(){
    const {selectedChannel, channels} = this.props;
    if(selectedChannel && channels){
      const id = _.get(channels[selectedChannel],'patientId');
      return id ? btoa(`accounts:${id}`):'';
    }else{
      return null;
    }
  }

  handleContainerScroll(e){
    const target = e.target;
    const height = $(target).find("#profile_detail").outerHeight();
    const visitListTop = _.get($(target).find('.patientAppointCollapse').parent().parent().offset(),'top');

    const { profileHeight } = this.props;
    if(this.props.profileHeight==0){
        this.props.setPatientDivHeight(height);
    }
    //54 is the height of top menu;
    //87 is the height of collapsed patient profile div;

    //if scroll to height less than top menu height plus collapsed patient profile div,
    //then show the sticky header;
    if(visitListTop < -50){
        this.setState(((preState)=>{
            if(!preState.showStickyHeader){
                return { showStickyHeader: true }
            }
        }))
        return;
    }
    //if scroll to height to 100px less than original patient profile div
    // hide sticky header;
    if(visitListTop > 50){
        this.setState((preState)=>{
            if(preState.showStickyHeader){
                return { showStickyHeader: false }
            }
        })
        return;
    }
  }

  renderChatHeader = () =>{
      const { setSelectedChannel,selectedChannel } = this.props;
      const setChatStatus = _.get(this,'props.setChatStatus');
      const showChat = _.get(this,'props.showChat',false);
      const handleCloseButton = ()=>{
          if(showChat&&selectedChannel){
              setSelectedChannel('');
          }
          setChatStatus(false);
      }
      const chatHeader = <div className='chatHistoryHeader' ><p>Chat History</p><Icon type="close" onClick={()=> handleCloseButton() }/></div>;

      return chatHeader
  };

  showShowCloseButton = ()=>{
      const { setSelectedChannel,selectedChannel,updateUnreadCounter,updateChannelInfo,verifyAndAddChannel,channels,isFullyLoaded,getChatInfo,patientId,patientIdToChannel } = this.props;
      const pId = patientId&&atob(patientId).split(':')[1];
      let channelName  = _.get(this.props,'channelName') =='' ? _.get(patientIdToChannel,pId,'') :_.get(this.props,'channelName','');
      const { loadingChatInfo } = this.state;
      const setChatStatus = _.get(this,'props.setChatStatus');
      const showChat = _.get(this,'props.showChat',false);
      const messages =  _.get(channels,`${channelName}.history`,[]);
      const hasUnread = _.get(channels,`${channelName}.counter`,0) != 0;

      const handleShowButton = async ()=>{
              if(selectedChannel.length==0) {
                  if (channelName.length == 0) {
                      this.setState({
                          loadingChatInfo:true
                      })
                      channelName = await getChatInfo();
                      this.setState({
                          loadingChatInfo: false
                      })
                  }
                  verifyAndAddChannel(channelName);
                  setSelectedChannel(channelName);
                  let msgObj = this.handleUnreadAndLastMsg(messages);
                  msgObj.isListening = false;
                  updateChannelInfo(channelName, msgObj);
                  updateUnreadCounter(channelName);
              }
              setChatStatus(true);
              Mixpanel.track('clicked','message_float_bubble','patient_profile',{ PATIENT_ID:patientId })

      };



      return !showChat&&isFullyLoaded ? <p className={`${ hasUnread ? 'hasUnread showChatButton' :'showChatButton'}`}><img src='/image/start_chat.png'
                                                                                                            onClick={ handleShowButton } style={{ cursor:'pointer' }}/></p> :'';
  }

  render() {
    const { props, sendMessage, fetchHistory,showShowCloseButton } = this;
    const { selectedChannel,timer,chatWithIndividual,showProfileStickyHeader,showChat } = props;
    const { isLoading,showStickyHeader,loadingChatInfo } = this.state;
    const  chatStarted  = _.get(timer,'chatStarted',false);
    const selectedPatientId = this.getSelectedPatientId();
    let displayName = this.constructor.displayName;
    const displayNameWithoutPatientId = displayName;
    displayName = `${displayName}:${selectedPatientId}`;
    const viewingDetails ={
          component : displayName,
          path:window.location.pathname
    };
    const timerDiv = chatStarted ? <TimerContainer displayName={displayNameWithoutPatientId} category='CONVERSATION'  chatStarted ={chatStarted}
                                                   action='ENGAGE' viewingDetails={viewingDetails} selectedChannel={selectedChannel}
                                                   patientId={selectedPatientId}
    /> :'';

    if(chatWithIndividual) {
        if(loadingChatInfo){
            return <div className={`message-container row rowUpdate ${chatWithIndividual ? 'chatWithIndividual' : ''}
                                   ${ showProfileStickyHeader ? 'showProfileStickyHeader' :''}`}
                    >
                        <ChatHistory loadingChatInfo={loadingChatInfo}/>
                    </div>
        }
        if(selectedChannel) {
            return <div className={`message-container row rowUpdate ${chatWithIndividual ? 'chatWithIndividual' : ''}
                                   ${ showProfileStickyHeader ? 'showProfileStickyHeader' :''}
                                   ${ showChat ? 'showChat' : 'closeChat' }`
                                  }
                   >
                        { this.renderChatHeader() }
                        <ChatHistory userId={props.currentUserId} userMap={props.userMap}
                                     fetchHistory={fetchHistory} addToUserMap={props.addToUserMap}
                                     isLoading={isLoading}
                                     chatWithIndividual={chatWithIndividual}
                                     key={selectedChannel}
                                     loadingChatInf={loadingChatInfo}
                        />
                        {
                            showShowCloseButton()
                        }
                        <ChatInput userId={props.currentUserId} sendMessage={sendMessage}
                                   chatStarted={chatStarted} selectedChannel={selectedChannel}
                                   displayName={displayName}
                        />
                    </div>
        }else{
            return <div className={`message-container row rowUpdate ${chatWithIndividual ? 'chatWithIndividual' : ''}
                                       ${ showProfileStickyHeader ? 'showProfileStickyHeader' :''}
                                       ${ showChat ? 'showChat' : 'closeChat' }
                                  `}>
                    { showShowCloseButton() }
                    <EmptyChannelComponent/>
                   </div>
        }
    }

    if (props.selectedChannel) {

      return (
        <div style={{ 'display':'flex','flexDirection':'row', 'overflow':'hidden'}}>
          <div className='message-container row rowUpdate' >
            <ChannelListComponent setSelectedChannel={ props.setSelectedChannel }
                                  addToUserMap={ props.addToUserMap }
                                  fetchHistory={ fetchHistory }
            />
              {timerDiv}
                <div className='messageBox col-lg-6 colUpdate' style={{ 'height':'100%', 'overflow':'hidden' }}>
              <ChatHistory userId={ props.currentUserId } userMap={ props.userMap }
                           fetchHistory={ fetchHistory } addToUserMap={ props.addToUserMap }
                           isLoading={isLoading}
                           key={selectedChannel}
              />
              <ChatInput userId={ props.currentUserId } sendMessage={ sendMessage } chatStarted ={ chatStarted } selectedChannel={selectedChannel} displayName={displayName}/>
            </div>
          </div>
          <div className='outtercontainer'
               onScroll={(e)=>this.handleContainerScroll(e)}
               ref={ref=>this.ref = ref}
          >
              <div className='profileBox row rowUpdate' >
                {selectedPatientId? <PatientMain patientId={selectedPatientId}
                                                 isInChat={true}
                                                 showStickyHeader = { showStickyHeader }
                                                 { ...props }
                />:null}
              </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display:'flex', flexDirection:'row'}}>
          <div style={{ flex: 1 }}>
            <ChannelListComponent setSelectedChannel={ props.setSelectedChannel }
                                  addToUserMap={ props.addToUserMap }
                                  fetchHistory={ fetchHistory }
            />
          </div>
          <div style={{ flex: 1 }}>
            <ChatEmptyThirdPanel />
          </div>
        </div>
      );
    }
  }
}

const mapState = (state,ownProps) => {
  const { chat } = state;
  const selectedChannel = _.get(chat,'main.selectedChannel');
  const patientId = _.get(chat,`main.channels.${selectedChannel}.patientId`);
  const parsedPatientId = patientId ? btoa('accounts:'+patientId) : '';
  const displayName = `chat/containers/ChatContainer:${parsedPatientId}`;

  return {
    currentUserId: chat.main.currentUserId,
    selectedChannel: chat.main.selectedChannel,
    channels: chat.main.channels,
    patientIdToChannel: chat.main.patientIdToChannel,
    userMap: chat.main.userMap,
    timer:state.timer.main[displayName],
    profileHeight:chat.main.profileHeight,
    isFullyLoaded:chat.main.isFullyLoaded
  }
};


const mapDispatch = (dispatch) => {
  return {
    addMessage: (channel, msg) => dispatch(actions.addMsg(channel, msg)),
    setCurrentUserId: userId => dispatch(actions.setCurrentUserId(userId)),
    addChatHistory: (channel, patientId, messages, timestamp, messageCount, isBatch, toBottom) => dispatch(actions.addChatHistory(channel, patientId, messages, timestamp,messageCount,isBatch,toBottom)),
    clearHistory: () => dispatch(actions.clearHistory()),
    addChannels: (channelsObj) => dispatch(actions.addChannels(channelsObj)),
    setSelectedChannel: channel=> dispatch(actions.setSelectedChannel(channel)),
    addToUserMap: (newUser,avatarError) => dispatch(actions.addToUserMap(newUser,avatarError)),
    updateUnreadCounter : (channel)=>dispatch(actions.updateUnreadCounter(channel)),
    updateChannelInfo : (channel,msgObj)=>dispatch(actions.updateChannelInfo(channel,msgObj)),
    verifyAndAddChannel:(channel)=>dispatch(actions.verifyAndAddChannel(channel)),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setPatientDivHeight:(height)=>dispatch(actions.setPatientDivHeight(height)),
    changeMenuStats: (menuOpened) => dispatch(changeMenuStats(menuOpened))

  }
}

ChatContainer.propTypes = {
  channels: PropTypes.object,
  currentUserId: PropTypes.string,
  addMessage: PropTypes.func,
  setCurrentUserId: PropTypes.func,
  selectedChannel: PropTypes.string,
  addChatHistory: PropTypes.func,
  addChannels: PropTypes.func,
  openErrorModal: PropTypes.func
};

export default compose(
  connect(mapState, mapDispatch)
)(ChatContainer);
