import React from 'react';
import { Table } from 'antd';
import map from '../constants/map';
const BPResultStatisticsComponent  = class extends React.Component {
    renderBPSummary(){
        const  { stat={ } } = this.props;
        const source =[];
        const { total } = stat;

        Object.entries(stat).forEach(e=>
        {
            const key = map[`${e[0]}`];
            const value = e[1];
            if(key){
                source.push({
                    key,value
                })
            }
        });
        const columns=[
            {
                title:'',
                key:'key',
                dataIndex:'key'
            },
            {
                title:'',
                render:(doc)=> (typeof doc == 'number') ? `${doc} (${ Number((doc||0)/(total!=0 ? total: 1)*100).toFixed(1) }%)` : (`${doc.systolic} , ${doc.diastolic} `),
                key:'value',
                dataIndex:'value'
            }
        ];

        return <Table dataSource={source}
                      rowKey={(record,index) => index}
                      columns={columns}
                      showHeader={false}
                      pagination={false}
                      bordered={false}
                      title={()=><span>Blood Pressure Summary</span>}
                      rowClassName='statisticsTableRow'
                      className='statisticsTable'
        />
    }

    render(){
        const { loading } = this.props;
        if(loading){
            return <div>Loading</div>
        }
        return<div>
                { this.renderBPSummary() }
              </div>
    }
}


export default  BPResultStatisticsComponent;
