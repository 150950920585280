import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoginStep1Container from './LoginStep1Container'
import LoginStep2Container from './LoginStep2Container'
import RequestResetPasswordContainer from './RequestResetPasswordContainer'
import { browserHistory } from 'react-router'
import MainModal from 'layoutModule/containers/MainModalContainer';
import VersionContainer from './VersionContainer'
import actions from '../actions/Login'
import {Button, Col, Modal, Row} from "antd";

// container
class Login extends Component {
  static displayName = 'LoginContainer'

  componentDidMount() {
      //console.log('componentDidMount ', sessionStorage.getItem("authToken"))
      if(sessionStorage.getItem("authToken")) {
        browserHistory.push('/')
      }
  }

  showChromeSuggestion(){
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if(!isChrome){
        return (
              <Modal
              title="Please Use Chrome Browser "
              visible={true}
              closable={false}
              maskClosable={false}
              footer={null}
          >
                <div>
                  <p>Hey There,</p>
                  <p>We strongly recommend using the <span style={{fontWeight: 'bold', fontColor:'black'}}>Chrome Browser</span> to visit our portal.</p>
                  <p>It will have a better user experience when you access our portal.</p>
                  <p>So please switch <span style={{fontWeight: 'bold', fontColor:'black'}}>Chrome Browser</span> and visit us again.</p>
                  <p>Thank you very much!</p>
                </div>
          </Modal>
        )
    }
  }


  render() {
    const { step } = this.props
    return <div>
      <MainModal />
      {
        step === 1 ?
          <LoginStep1Container />
        : step === 1.5 ?
          <RequestResetPasswordContainer />
        : <LoginStep2Container />
      }
      <div style={{position: 'fixed', right: '2em', bottom: '2em'}}>
        <VersionContainer />
      </div>
      {this.showChromeSuggestion()}
    </div>

  }
}

// mapState
const mapState = ({ Login }, ownProps) => {
  return {
    ...Login
  }
}


// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {
    next: () => dispatch(actions.next())
  }
}


// PropTypes validation
Login.propTypes = {
  step: PropTypes.number
}
// connect container with mapState and mapDispatch then export
export default connect(
  mapState, mapDispatch
)(Login)
