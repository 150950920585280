import { Select} from 'antd';
import React from 'react';
import Client from 'libModule/gqlClient';
import createProviderNote from '../../graphql/createProviderNote';
const { Option } = Select;

const getAllAssignedTo = (team)=>{
    let members = [];
    const currentUserId = sessionStorage.getItem('currentUserId');
    let currentUser = null;
    _.forEach(team,t=>_.forEach(t.members,m=>{
       if(currentUserId!=m.user.id) {
           members.push(m);
       }else{
           currentUser = m;
       }
    }));
    if(currentUser) members.unshift(currentUser);
    return members;
}

const assignedToDropDown = (enrolledPrograms,team=[],disabled)=>{
    const inputTeam = _.get(_.first(enrolledPrograms),'team',null) ? [ _.get(_.first(enrolledPrograms),'team',null)] : team;
    let members = getAllAssignedTo(inputTeam);
    members = _.uniqBy(members,m=>m.user&&m.user.id);

    return <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select a person"
        optionFilterProp="children"
        disabled={disabled}
    >
        {
            _.map(members,(m,i)=>{
                const { user } = m;
                const { id,profile:{fullName}} = user;
                return <Option value={id} key={i}>{fullName}</Option>
            })
        }
    </Select>
}
const createPN = (variables,refetchVariables)=>{
    let category = 'TASK_NOTES';
    variables.category = category;
    return Client.mutate({
        mutation:createProviderNote,
        variables,
    })
}
export default {
    assignedToDropDown,
    createPN
}
