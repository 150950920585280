import {
    React, IHLoading, IHButton, message, IHTooltip,_
} from 'ihcomponent';
import {compose} from "recompose";
import {goPath} from 'libModule/utils'
import UserClass from 'modulesAll/graphql/class/User'
import userData from '../../../fake/user'
import { timeSpentGQL,timeSpentListGQL } from '../query/index'
import {graphql} from "react-apollo/index";
import userGQL from "../../../graphql/user";
import { Progress,Divider } from 'antd';
import '../style/index.scss';
import  { ProcessBarComponent,
          MinIndicatorComponent,
          TimeSpentReadingComponent,
          PatientStarComponent,
          TimeSpentListComponent} from '../components/index';
import '../../../patient/main/components/bootstrap_white.css';
import { connect } from 'react-redux'
import moment from "moment/moment";
import 'moment-timezone';


class PatientTimeSpentContainer extends React.Component{
    constructor(){
        super();
        this.state = {
            average:0
        }
    }
    renderAvator(userId){
        if(userId)
        return (
            <a href="javascript:void(0)" className="v-avator">
                <img src={this.uc.format('avator')} onError={ () => this.onAvatarError(userId) } />
            </a>
        )
    }

    renderTopButton(userId) {
        const b1 = {
            label: '< Back to Patient Profile',
            size: 'large',
            type: 'link',
            onClick: () => {
                goPath('/patients/' + userId + '/enrolledprogram/default')
            }
        }
        return (
            <div className="returnButton">
                <IHButton {...b1} />
            </div>
        )
    }
    renderName(){
        const user = this.props.fakeUser
        if(!user) return '';
        return (
            <div>
                <span className="v-name">
                    {this.uc._get('profile.fullName')}
                    <span>({this.uc.format('nric')})</span>
                </span>
                <span className={`v-status v-${user.status}`}>{user.status}</span>
                {/*{this.renderStar()}*/}
            </div>
        )
    }

    renderTimeSpentHeader(){
        return <h4 className='timeSpentHeader'>
                Billable Time Details
               </h4>
    }

    renderTimeSpentReading(key,reading){

        return <TimeSpentReadingComponent k={key} reading={reading} />
    }

    renderProgressBar(){
        const monthlyTimeSpent = _.get(this.props,'user.monthlyTimeSpent.totalTime',0);
        return <ProcessBarComponent monthlyTimeSpent={monthlyTimeSpent} />
    }

    renderMinIndicator(){
        return <MinIndicatorComponent/>

    }

    getPreMonthReading(curMonth){
        const  { timeSpentList } = this.props;
        let year = Math.round(curMonth/100);
        let month = curMonth%100;
        let preMonth = 0;

        if(month==1){
            preMonth = 12;
            year = year -1;
        }else{
            preMonth = month-1;
        }

        const preMonthReading = _.get(timeSpentList.find((month)=>month.monthOfYear==(year * 100 + preMonth)),'totalTime',0) ;

        return preMonthReading;
    }

    renderTimeSpentConent(){
        const timeSpentList = _.get(this.props,'timeSpentList',[]);
        const currentMonth = _.get(timeSpentList,'0',{});
        const currentMonthTotal = currentMonth.totalTime;
        const preMonth = this.getPreMonthReading(currentMonth.monthOfYear);
        const average = this.state.average;

        return(
            <div>
                <div>
                    {this.renderTimeSpentHeader()}
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end',height:'100px'}}>
                    <div style={{flex: 1}}>
                        {this.renderTimeSpentReading('curMonth',currentMonthTotal)}
                    </div>
                    <div style={{flex: 3,display:'flex',flexDirection:'row',height:'100%',alignItems:'flex-end',marginBottom:15}}>
                       <div>
                           {this.renderProgressBar()}
                           {this.renderMinIndicator()}
                       </div>
                       <div className='divider' style={{flex:1,display:'flex',justifyContent:'center',height:'100%'}}>
                          <Divider type="vertical"/>
                       </div>
                    </div>
                    <div style={{flex: 1}}>
                        {this.renderTimeSpentReading('preMonth',preMonth)}
                    </div>
                    <div style={{flex: 1}}>
                        {this.renderTimeSpentReading('average',average)}
                    </div>
                </div>
            </div>
        )
    }
    renderTags(){
        const tags = this.uc.format('tag');
        const len = tags.length;
        return (
            <div style={{paddingBottom: 10}}>
                {
                    _.map(tags, (tag, index)=>{
                        if(index==len-1) {
                            return (
                                <span className="v-tag" key={index}>{tag}</span>
                            )
                        }
                        else return <span className="v-tag" key={index}>{tag},</span>
                    })
                }
            </div>
        )
    }

    renderStar(){
        const uc = this.uc;
        return <PatientStarComponent uc={uc}/>
    }
    renderTimeSpentList(){
        const  data = this.props.timeSpentList;
        const { pageInfo,loading } = this.props;
        return <TimeSpentListComponent data={data} pageInfo={pageInfo} loading={loading}/>
    }


    componentWillReceiveProps(nextProps,nextState){
        if(this.state.average!=0){
            return;
        }
        const { timeSpentList,page } = nextProps;
        const length = timeSpentList.length;
        const { current } = page;

        if(_.isEmpty(current)&&timeSpentList.length>0||timeSpentList.length>0){
            const totalTime = (list) =>{
                return _.reduce(list,(sum,cur)=>{
                    return sum+cur.totalTime;
                },0)}
            const average = Math.round(totalTime(timeSpentList)/length);
            this.setState(()=>({
                average:average
            }))
        }

    }

    render(){
        this.uc = new UserClass(this.props.user)
        const userId = _.get(this.props,'user.id');
        return(
            <div>
                <div className="vsm-main">
                    {this.renderTopButton(userId)}
                </div>
                <div className="vsm-patient-member-detail">
                    {this.renderAvator(userId)}
                    <div style={{display:'flex',flexDirection:'column'}}>
                        {this.renderName()}
                        {this.renderTags()}
                    </div>
                </div>
                <div style={{padding:'40px 80px 40px 80px'}}>
                    {this.renderTimeSpentConent()}
                </div>
                {this.renderTimeSpentList()}
            </div>

        )
    }
}

const withData = graphql(userGQL, {
    options: (props) => ({
                variables: { id: _.get(props, 'params.patientId')},
                fetchPolicy:'network-only'
    }),
    props: ({data}) => ({user: data.user, fakeUser: userData, timeSpent:_.get(data,'user.monthlyTimeSpent',0)})

})

// const timeSpentData = graphql(timeSpentGQL,{
//     options: (props) => {
//         const tz = moment.tz.guess();
//         const today = moment(new Date()).tz(tz).toDate();
//         const year = today.getFullYear();
//         const month = today.getMonth();
//         const isJan = month==0;
//         const preMonth = (isJan ? year-1 : year)*100+(isJan ?  12: month);
//         return{
//             variables: { userId: _.get(props, 'params.patientId'),monthOfYear:preMonth},
//             fetchPolicy:'network-only'
//         }
//     },
//     props:({data})=> ({
//                         timeSpent:data.getMonthlyTimeSpent
//                       })
// })

const timeSpentListData = graphql(timeSpentListGQL,{
    options: (props) => ({
        variables: {
            memberId: _.get(props, 'params.patientId'),
            count:999,
            page:_.get(props,'page.current',1)
        }
    }),
    props:({ownProps,data})=>
        ({
        timeSpentList: _.get(data, 'monthlyTimeSpentList.data', []),
        pageInfo:_.get(data,'monthlyTimeSpentList.pageInfo',{}),
        loading:_.get(data,'loading')
    })
})

const mapToProps = (state,props)=>{

    return {
        ...state.globalTables.TimeSpent_List,
    }
}

export default compose(connect(mapToProps,null),withData,timeSpentListData)(PatientTimeSpentContainer);
