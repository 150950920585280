import gql from 'graphql-tag'
import { UserProfile } from './schema/User'
export default gql`
  query userList(
    $filters: UserListFilters!,
    $search: InputUserSearch,
    $page: Int,
    $count: Int,
    $sort: UserListSort
  ) {
    userList(
      page: $page,
      count: $count,
      filters: $filters,
      search: $search,
      sort: $sort
    ) {
      data {
        id
        createdAt
        username
        allRoles {
          refId
          name
          category
        }
        profile {
          ${UserProfile}
        }
      }
      pageInfo {
        total
        lastPage
      }
    }
  }`
