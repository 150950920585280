import  React  from 'react';
import MAWorkingListContainer from '../container/MAEWorkingListContainer';
import CDEWorkingListContainer from '../container/CDEWorkingListContainer';

const SmartAlertListContainer = class extends React.Component {
    
    render(){
        const { props } = this;
        let type = null;
        if(location.pathname === '/smartalert') {
            type = 'CDE';
        }
        if(location.pathname === '/tempenrolledpatients') {
            type = 'MA'
        }
        const propsCopy = {...props};
        propsCopy.type = type;
        switch(type){
            case 'MA':
                return <MAWorkingListContainer {...propsCopy}/>;
            case 'CDE':
                return <CDEWorkingListContainer {...propsCopy}/>;
            default:
                return <div>unknown error</div>
        }
    }
}

// const  smartAlertTasksList = graphql(smartAlertTaskList,{
//     options:(ownProps)=>{
//         let variables = {};
//         let filter = _.cloneDeep({ ...ownProps.filter });
//         let page = _.get(ownProps,'page.current',1);
//         let count = _.get(ownProps,'page.size',10);
//         let { filterType } = maps;
//         let type = _.get(ownProps,'type');
//         filter.taskType = filterType[type];
//         variables.page = page;
//         variables.count = count;
//         variables.filters = filter;
//
//         return {
//             variables:variables,
//             fetchPolicy:'network-only'
//         }
//     },
//     props:({data})=> {
//         const { smartAlertTasksList, loading } = data;
//         if (loading) {
//             return {
//                 loading
//             }
//         }
//
//         return {
//             smartAlertTasksList: smartAlertTasksList.data,
//             pageInfo:smartAlertTasksList.pageInfo
//         };
//     }
// })
//
// const mapStateToProps = (state, ownProps) => ({
//     ...ownProps,
//     ...state.globalTables.smartAlert_List,
// });
//
// const mapDispatchToProps = (dispatch) => {
//     return {
//         ...tableActions
//     };
// }
export default SmartAlertListContainer;
// export default compose(
//     connect(mapStateToProps, mapDispatchToProps),
//     // ,withData,
// smartAlertTasksList
// )(SmartAlertListContainer);
