import Client from 'libModule/gqlClient';
import taskAssignmentUnreadList from '../../taskAssignment/query/taskAssignmentUnreadList';

const taskAssignmentUnreadVariables = ()=>{
    const currentUserId = sessionStorage.getItem('currentUserId');
    return {
        count: 1,
        filters: {
            assignedTo:[currentUserId],
        }
    }
}
const fetchTaskUnreadList = ()=>{
    Client.query({
        query:taskAssignmentUnreadList,
        variables:taskAssignmentUnreadVariables(),
        fetchPolicy:'network-only'
    })
}

export default {
    fetchTaskUnreadList
}
