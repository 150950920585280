import _ from 'lodash';
import { browserHistory } from 'react-router';

export const updateHistory = (routing, params) => {
  const pathname = _.get(routing, 'location.pathname');
  const pickedParams = _.pick(params, ['filter', 'sort', 'search', 'page']);

  browserHistory.replace({
    pathname,
    query: _.mapValues(pickedParams, v => JSON.stringify(v)),
  });
};

export const loadFromUrl = (routing) => {
  const query = _.get(routing, 'location.query');
  const pickedQuery = _.pick(query, ['filter', 'sort', 'search', 'page']);

  return _.mapValues(pickedQuery, v => JSON.parse(v));
};
