import { constants } from 'libModule/utils';

export const alertColor = {
  NORMAL: '#64c2b1',
  RISK: '#e89b5c',
  CRITICAL: '#e95c69',
  UNDEFINED: '#64c2b1',
  MISSED: '#dcdcdc'
}
export const HSColor = {
    NORMAL: '#64c2b1',
    OVERWEIGHT: '#fca642',
    UNDEFINED: '#e3e3e3',
    MISSED: '#dcdcdc',
    OBESE:'#ff6464',
    UNDERWEIGHT:'#fdd487'
}
export default constants('patientView', 'VitalList', [
  'SORT',
  'CHANGE_PAGE',
  'LOADING',
  'CACHE',
  'CHANGE_PROGRAM',
  'CHANGE_VIEW',
  'CHANGE_RANGE',
  'CHANGE_BPMODE',
  'CHANGE_BGMODE',
  'ON_TABLE_CHANGE'
])
