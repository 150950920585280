import React from 'react';
import { connect } from 'react-redux';
import AppointmentListComponent from '../components/AppointmentListComponent';
import createTableAction from 'libModule/table/TableActions';
import actions from '../actions';
import { compose, graphql } from 'react-apollo/index';
import { helpers } from '../helpers';
import { message } from '../../../../../package/IHComponent';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import Client from 'libModule/gqlClient';
import visitList from 'modulesAll/graphql/visitList';
import modalActions from 'modulesAll/common/actions/modal';
import deleteVisitQuery from 'graphqlModule/mutation/deleteVisit';

import {
  goPath,
  FormHelper,
  GQLHelper,
  setAsyncRouteLeaveHook,
  submittingStyle
} from 'libModule/utils';
import { IHButton, _, moment } from 'ihcomponent';

import updateVisitConfirmQuery from 'graphqlModule/mutation/updateVisitConfirm';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const appointmentTableActions = createTableAction('appointment_List');
const fieldMap = {
  createdAt: 'CREATED_AT',
  appointmentAt: 'APPOINTMENT_AT'
};

const AppointmentContainer = class extends React.Component {
  static displayName = 'AppointmentContainer';
  constructor(props) {
    super(props);
    this.confirmVisit = this.confirmVisit.bind(this);
  }

  componentWillUnmount() {
    this.props.resetVisit();
  }

  // componentWillReceiveProps(nextProps) {
  //   const visitList = nextProps.visitList && nextProps.visitList.data;
  //   const visitReduxList = this.props.visitReduxList;
  //   const { loading } = nextProps;
  //   if (visitList) {
  //     if (
  //       visitReduxList &&
  //       visitReduxList.length > 0 &&
  //       !_.isEqual(this.props.visitReduxList, nextProps.visitReduxList)
  //     ) {
  //       return;
  //     }
  //     if (visitReduxList.length == 0 && visitList.length != 0) {
  //       this.props.initVisitList(visitList);
  //     }
  //     if (!_.isEqual(visitList, visitReduxList) && !loading) {
  //       this.props.initVisitList(visitList);
  //     }
  //   }
  // }

  deleteVisit = (id,sortByConfirm) => {
    const { dateRangeToFilter, defaultStartDay, defaultEndDay } = this.props;
    let startDate = _.get(dateRangeToFilter, '0', defaultStartDay);
    let endDate = _.get(dateRangeToFilter, '1', defaultEndDay);
    let variables = { sortByConfirm: true };
    Client.mutate({
      mutation: deleteVisitQuery,
      notifyOnNetworkStatusChange: true,
      variables: { id: id },
      refetchQueries: [
        {
          query: visitList,
          variables: helpers.setFromAndToDate(variables, startDate, endDate,sortByConfirm),
          fetchPolicy: 'network-only'
        }
      ]
    })
      .then(() => {
        message.success('Patient Appointment canceled');
      })
      .catch(error => {
        console.log(error);
        this.props.openErrorModal(GQLHelper.formatError(error));
      });
  };

  confirmVisit = (id, confirm) => {
    const { dateRangeToFilter, defaultStartDay, defaultEndDay } = this.props;
    let startDate = _.get(dateRangeToFilter, '0', defaultStartDay);
    let endDate = _.get(dateRangeToFilter, '1', defaultEndDay);
    let variables = { sortByConfirm: true };
    Client.mutate({
      mutation: updateVisitConfirmQuery,
      notifyOnNetworkStatusChange: true,
      variables: { id: id, confirm: confirm },
      refetchQueries: [
        {
          query: visitList,
          variables: helpers.setFromAndToDate(variables, startDate, endDate, true),
          fetchPolicy: 'network-only'
        }
      ]
    })
      .then(() => {
        console.log('Appointment Confirmed');
      })
      .catch(error => {
        console.log(error);
        this.props.openErrorModal(GQLHelper.formatError(error));
      });
    Mixpanel.track('clicked', 'confirm', "on tomorrow's visit page", {});
  };

  showConfirmForCancel = (id,sortByConfirm) => {
    return new Promise((resolve, reject) => {
      const Yes = {
        label: 'Yes, Cancel Appointment',
        handler: () => {
          resolve(this.deleteVisit(id,sortByConfirm));
        },
        style: {
          border: 'solid 1px #aaaaaa',
          color: 'red',
          background: 'white',
          textShadow: 'none'
        },
        closeModal: true
      };
      const No = {
        label: 'No',
        style: {
          border: 'solid 1px #4278c3',
          color: '#4278c3',
          background: 'white',
          textShadow: 'none'
        },
        handler: () => {
          resolve(false);
        }
      };
      const modalProps = {
        Yes,
        No,
        title: 'Cancel Appointment?',
        content:
          'Are you sure you want to cancel this appointment? This action can not be undone.'
      };
      Mixpanel.track('clicked', 'delete', "on tomorrow's visit page", {});
      this.props.openConfirmModal(modalProps);
    });
  };

  render() {
    const { props } = this;
    return (
      <div>
        <AppointmentListComponent
          {...props}
          showConfirmForCancel={this.showConfirmForCancel}
          confirmVisit={this.confirmVisit}
          refetch={this.props.refetch}
        />
      </div>
    );
  }
};

const updateFOLLOW_UP = variables => {
  const { type } = variables;
  if (type.indexOf('FOLLOW_UP') != -1) {
    let more = ['FOLLOW_UP1', 'FOLLOW_UP2', 'FOLLOW_UP3'];
    variables.type = variables.type.concat(more);
  }
};

const withData = graphql(visitList, {
  options: ownProps => {
    const {
      filter,
      sort,
      dateRangeToFilter,
      defaultStartDay,
      defaultEndDay,
      curTab
    } = ownProps;
    const variables = {
      count: 99999,
      sortByConfirm: curTab === 'Today' ? false : true
    };
    const { provider } =
      filter && filter.provider
        ? filter
        : {
            provider: _.reduce(
              JSON.parse(sessionStorage.getItem('doctorName')),
              (res, d) => {
                res.push(d.id);
                return res;
              },
              []
            )
          };
    let startDate = _.get(dateRangeToFilter, '0', defaultStartDay);
    let endDate = _.get(dateRangeToFilter, '1', defaultEndDay);
    variables.appointmentFrom = Number(startDate);
    variables.appointmentTo = Number(endDate);
    variables.sort = _.isEmpty(sort)
      ? {}
      : { field: fieldMap[sort.field], direction: sort.direction };
    variables.providerIds = provider;
    variables.type = filter.type && filter.type;

    //short term solution for jira-1635
    if (filter.type) {
      updateFOLLOW_UP(variables);
    }
    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    };
  },
  props: ({ ownProps, data }) => {
    const { loading, error, visitList, refetch } = data;
    if (error) {
      console.log(error);
      return { error };
    }

    if (loading) return { loading };

    if (visitList && visitList.data) {
      // console.log('vData ', visitList.data);
      //Currently unavailable
      // let tomorrowIndex = 1;
      // // let sortedVis = _.sortBy(visitList.data, 'appointmentAt');
      // let tomorrowVisitList = _.map(visitList.data, v => {
      //     if(v.confirmed){
      //         return Object.assign(v, { index: tomorrowIndex++ });
      //     } else {
      //         return v;
      //     }
      // });
      let todayIndex = 1;
      // let sortedVis = _.sortBy(visitList.data, 'appointmentAt');
      let indexVisitList = _.map(visitList.data, v =>
        Object.assign(v, { index: todayIndex++ })
      );
      return {
        visitList,
        indexVisitList,
        loading,
        refetch
      };
    } else {
      // alert('refetching...');
      // console.log(refetch);
      if (refetch) refetch();
      return null;
    }
  }
});

const mapToProps = (state, props) => {
  return {
    ...appointmentTableActions,
    ...state.globalTables.appointment_List,
    visitReduxList: state.visit.main.initVisitList
  };
};

const mapToDispatch = dispatch => {
  return {
    initVisitList: data => dispatch(actions.initVisitList(data)),
    updateVisit: (id, data) => dispatch(actions.updateVisit(id, data)),
    resetVisit: () => dispatch(actions.resetVisit()),
    openErrorModal: error => dispatch(openErrorModal(error)),
    openConfirmModal: props => dispatch(modalActions.confirm(props))
  };
};

export default compose(
  connect(mapToProps, mapToDispatch),
  withData
)(AppointmentContainer);

// export default AppointmentContainer;
