// Admin
import I18N from 'modulesAll/I18N';

const menus = {
  ProgrammesAdmin: {
    id: 'programmes_admin',
    title: `${I18N.get('keywords.Programs')}`,
    //icon: 'programmes',
    // icon: 'programs',
    src: '/image/programs-nav-icon.svg',
    href: '/programmes_admin',
    class:'programmes_admin'
  },
  Providers: {
    id: 'providers',
    title: 'Providers',
    //icon: 'providers',
    icon: 'person',
    href: '/providers',
    class:'providers'
  },

  Careteams: {
    id: 'careteams',
    title: 'Care Teams',
    //icon: 'careteams',
    icon: 'peopled',
    href: '/careteams',
    class:'careteams'
  },
  Assessments: {
    id: 'assessments',
    title: I18N.get('keywords.Assessments'),
    // icon: 'assessments',
    icon: 'assessment',
    href: '/assessments',
    class:'assessments'
  },
  Surveys: {
    id: 'surveys',
    title: 'Surveys',
    // icon: 'surveys',
    icon: 'survey',
    href: '/surveys',
    class:'surveys'
  },
}
const menusArray = [
  menus.Providers,
  menus.Careteams,
  menus.ProgrammesAdmin,
  menus.Assessments,
  // menus.Surveys
]

export default menusArray
export const HOMEPAGE = '/providers'
