import gql from 'graphql-tag';

export default gql `
  mutation chooseLoginOrganization($ref:Ref!){
    chooseLoginOrganization(ref:$ref){
      id
      username
      sessionToken
      role {
        refId
        name
        category
        organization {
          id
          name
          leaf
        }
        access {
          action
          rule
        }
      }
      appSettings {
        parent {
          id
          leaf
          name
        }
        group{
          id
          leaf
          name
        }
        role {
          refId
          name
          category
        }
      }
    }
  }
`