import React from "react";
import {Form, Modal} from "antd/lib/index";
import { Button,DatePicker,TimePicker,Select,Input,Alert,Checkbox } from 'antd';
import { helpers} from "../helpers";
import VISIT_TYPE_ENUM from '../constants/map';
import {connect} from "react-redux";
import {compose, graphql} from "react-apollo/index";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'lib/utils';
import moment from 'moment-timezone';
import actions  from 'modulesAll/common/actions/modal'
import {goPath} from "../../../lib/utils";
import TimerContainer from '../../timer/containers/TimerContainer';
import { API } from "../../timer/query";
import * as timerActions from '../../timer/actions/index';
const { stopTimer,updateInterventionDetails } = timerActions.default;
const { TextArea } = Input;
const Option = Select.Option;
import I18N from 'modulesAll/I18N';
const dateFormat = 'MM/DD/YYYY';
const FormItem = Form.Item;

let RescheduleAndCancelVisitComponent = class extends React.Component {
    static displayName = 'RescheduleAndCancelVisitComponent';

    constructor(props){
        super(props);
        const visitType = _.get(props,'selectedVisit.type');
        this.state = {
            visitType:visitType
        }
    }

    handlePatientNameNav = (e,patientId)=>{
        goPath(`/patients/${patientId}`)
    }

    componentWillReceiveProps(nextProps){
        const curStartTimeObj = _.get(this,'props.selectedVisit.appointmentAt');
        const nextEndTimeObj = _.get(nextProps,'selectedVisit.appointmentTo');
        const nextStartTimeObj = _.get(nextProps,'selectedVisit.appointmentAt');
        const curDatePickerValue = Number(this.props.form.getFieldValue('startTime').toDate());
        const nextVisitType = _.get(nextProps,'selectedVisit.type');
        const curVisitId = _.get(this,'props.selectedVisit.id');
        const nextVisitId = _.get(nextProps,'selectedVisit.id');

        if(curVisitId!=nextVisitId) {
            this.setState({
                visitType: nextVisitType
            });
        }

        if(!curDatePickerValue&&(nextStartTimeObj)){
              if(!nextEndTimeObj) {
                  this.props.form.setFieldsValue({
                      Date: moment(nextStartTimeObj),
                      startTime: moment(nextStartTimeObj),
                      endTime: moment(nextStartTimeObj).add(1, 'hours')
                  })
              }else{
                  this.props.form.setFieldsValue({
                      Date: moment(nextStartTimeObj),
                      startTime: moment(nextStartTimeObj),
                      endTime: moment(nextEndTimeObj)
                  })
              }
            return;
        }

        if(curStartTimeObj!=nextStartTimeObj){
            if(!nextEndTimeObj) {
                this.props.form.setFieldsValue({
                    Date: moment(nextStartTimeObj),
                    startTime: moment(nextStartTimeObj),
                    endTime: moment(nextStartTimeObj).add(1, 'hours')
                })
            }else{
                this.props.form.setFieldsValue({
                    Date: moment(nextStartTimeObj),
                    startTime: moment(nextStartTimeObj),
                    endTime: moment(nextEndTimeObj)
                })
            }
        }
    }

    changeVisitType(visitType){
        this.setState({ visitType });
    }

    renderDropDown(){

        const { props } = this;
        const isEdit  = _.get(props,'isEdit');
        const visit = _.get(props,'selectedVisit',{});
        const { visitType } = this.state;
        const { getFieldDecorator } = this.props.form;
        const doctorOptions = helpers.filterDoctors();
        const patientName = _.get(visit,'member');
        let patientNameSelected = !!patientName;
        const patientId = _.get(patientName,'id');
        const isINIT= (visitType==='INIT');

        try{
            atob(patientId);
        }
        catch (e){
            patientNameSelected = false;
            return <Alert message="Patient Id is not valid" type="error" />

        }

        let displayName = this.constructor.displayName;
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const keepEventListener = false;
        const teamRoles = I18N.get('teamRoles');

        return (
            <div className="rescheduleAndCancel">
                {
                 isEdit ?
                     <TimerContainer displayName={displayName} category='VISIT' action='ENGAGE'
                                 viewingDetails={viewingDetails} patientId={patientId}
                                 keepEventListener={keepEventListener}
                     />:''
                }
                <div style={{ marginLeft:5,color:'#303639' }}>
                    <label>Appointment Details</label>
                </div>
                <div className='firstRow'>
                    <FormItem
                        label="Patient Name"
                        className='patientNameSel'
                        style={{marginRight:0}}
                    >
                        {getFieldDecorator('Patient',{
                            initialValue:_.get(visit,'member.profile.fullName','')
                            })
                        (
                            <Select disabled={true} >
                                {
                                  _.map(doctorOptions,(program)=>{
                                    return (
                                        <Option value={program.id} key={program.id}>{program.fullName}</Option>
                                    )}
                                )}
                            </Select>
                        )}
                    </FormItem>
                    <div style={{width:'50%',marginBottom:10,display:'flex',justifyContent:'flex-end',flexDirection:'column'}} className='patientProfileNav' onClick={(e)=>this.handlePatientNameNav(e,patientId)}>
                        { patientNameSelected ? <Button type='default' style={{marginTop:21}}>View Patient Profile</Button>  : '' }
                    </div>
                </div>
                <div className='modalRow'>
                    <FormItem
                        label="Type"
                    >
                        {getFieldDecorator('Type', {
                            rules: [
                                { required: true, message: 'Please Select Appointment Type'},
                            ],
                            initialValue:_.get(visit,'type')
                        })(
                            <Select disabled={!isEdit}
                                    onChange={(e)=>this.changeVisitType(e)}
                            >

                                {_.map(VISIT_TYPE_ENUM,(type,val)=>{
                                    return (
                                        <Option value={val} key={val}>{type}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                </div>
                <div className='secondRow'>
                    <FormItem label="Clinic Provider">
                        {getFieldDecorator('Doctor',{
                            rules:[{required:true}],
                            initialValue:_.get(visit,'providers.0.id','')
                        })
                        (
                            <Select disabled={!isEdit}>
                                {_.map(doctorOptions,(program)=>{
                                    return (
                                        <Option value={program.id} key={program.id}>{program.fullName}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem style={{display:'flex',flexDirection:'column',justifyContent:'flex-end',marginLeft:0,paddingLeft:5}}>
                        {
                            getFieldDecorator('shouldSeeDoctor', {
                                valuePropName: 'checked',
                                initialValue:_.get(visit,'shouldSeeDoctor',false)
                            })(
                                <Checkbox disabled={!isEdit}>See Provider</Checkbox>,
                            )}
                    </FormItem>
                </div>
                <div className='modalRow'>
                    <FormItem label="Care Team">
                        {getFieldDecorator('visitRoles',{
                            initialValue:_.get(visit,'visitRoles'),
                            rules:[{ required:isINIT,message:'Care Team is Required'}]
                        })(
                            <Select mode="multiple" disabled={!isEdit}>
                                {_.map(teamRoles,(type,val)=>{
                                    return (
                                        <Option value={val} key={val}>{type}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                </div>
                <div className='thirdRow'>
                    <FormItem
                        label="Date"
                    >
                        {getFieldDecorator('Date', {
                            rules: [
                                { required: true, message: 'Please Select Date'},
                            ],
                            initialValue:moment(_.get(visit,'appointmentAt'))
                        })(
                            <DatePicker
                                format={dateFormat}
                                placeholder="Select Date"
                                disabled={true}

                            />
                        )}
                    </FormItem>
                    <FormItem
                        label="Start Time"
                    >
                        {getFieldDecorator('startTime', {
                            rules: [
                                { required: true, message: 'Please Select Time'}
                            ],
                            initialValue:visit.preVisitStartTime ? moment(visit.preVisitStartTime) : moment(_.get(visit,'visitStartTime',_.get(visit,'appointmentAt')))
                        })(
                            <TimePicker
                                format="HH:mm"
                                placeholder="Select Time"
                                // use12Hours={false}
                                minuteStep = {30}
                                disabled={true}
                            />
                        )}
                    </FormItem>
                    <FormItem
                        label="End Time"
                    >
                        {getFieldDecorator('endTime', {

                            rules: [
                                { required: true, message: 'Please Select Time'},
                                { validator:(rule,value,callback)=>{
                                    const formVariables  = this.props.form.getFieldsValue();
                                    const { startTime } = formVariables;
                                    if(moment(value).isBefore(startTime)){
                                        callback('End time must be latter than start Time');
                                    }
                                        callback();
                                    }
                                }
                            ],
                            initialValue:(visit.preVisitAppointmentTo) ? moment(visit.preVisitAppointmentTo) : moment(visit.appointmentTo==null ?  moment(_.get(visit,'visitStartTime')).add(1,'hours'):visit.appointmentTo)
                        })(
                            <TimePicker
                                format="HH:mm"
                                placeholder="Select Time"
                                // use12Hours={false}
                                minuteStep = {30}
                                disabled={!isEdit}
                                allowEmpty={false}

                            />

                        )}
                    </FormItem>
                </div>
                <div className='forthRow'>
                    <FormItem
                        label="Description(Optional)"
                    >
                        {getFieldDecorator('Reason', {
                            rules: [
                                { required: false },
                            ],
                            initialValue:_.get(visit,'reason')||''
                        })(
                            <TextArea disabled={!isEdit} />
                        )}
                    </FormItem>
                </div>
            </div>
        )

    }
    renderFooter() {
        const id = _.get(this.props,'selectedVisit.id','');
        const { enableEdit,setSelectedVisit,originalVisit,setOriginalVisit } = this.props;
        const isEdit = _.get(this.props,'isEdit',false);
        const baseStyle = {
            margin:5
        }
        const leftButton = !isEdit ? {
                                        key: 'Cancel',
                                        label: "Cancel Appointment",
                                        type: 'danger',
                                        size:'default',
                                        style:{ margin:5 },
                                        onClick:()=>{
                                            this.showConfirmForCancel(id);
                                        }
                                    }
                                    :
                                    {
                                        key: 'Cancel',
                                        label: "Cancel",
                                        type: 'default',
                                        size:'default',
                                        style:{ margin:5 },
                                        onClick:()=>{
                                            if(originalVisit&&!_.isEmpty(originalVisit)) {
                                                setSelectedVisit(originalVisit);
                                            }
                                            setOriginalVisit({ });
                                            enableEdit(false);
                                            this.props.form.resetFields();
                                            this.setState({
                                                visitType: null
                                            })
                                        }
                                    }
        const rightButton  = isEdit ? {
                                        key: 'Save',
                                        label: "Save",
                                        type: 'primary',
                                        style:baseStyle,
                                        onClick:()=>{
                                            this.onSubmit();
                                        }
                                      }
                                      :
                                      {
                                        key: 'Reschedule',
                                        label: "Edit",
                                        type: 'default',
                                        style:baseStyle,
                                        onClick:()=>{
                                            enableEdit(true);
                                        }
                                      }


        let buttonList =   [
                                leftButton,
                                rightButton
                           ];


        const buttons =   buttonList.map((value,index)=>{

            return (
                <FormItem
                    key={value.label}
                >
                    <Button className={value.className || ''}
                            onClick={value.onClick}
                            type={value.type} style={value.style}
                            htmlType={value.htmlType}>{value.label}
                    </Button>
                </FormItem>
            )
        });

        return (
            <div className='createButtons' style={{marginRight:15}}>{buttons}</div>
        )
    }

    showConfirmForReschedule = ()=>{
        const { enableEdit,originalVisit,setOriginalVisit,setSelectedVisit } = this.props;
        return new Promise((resolve,reject)=>{
            const Yes = {
                label: 'Confirm',
                handler: ()=>{
                    resolve(this.createVisit());
                },
                closeModal: true
            }
            const No = {
                label: 'Cancel',
                handler: () => {
                    // if(originalVisit&&!_.isEmpty(originalVisit)) {
                    //     setSelectedVisit(originalVisit);
                    // }
                    // setOriginalVisit({ });
                    //
                    // enableEdit(false);
                    // this.props.form.resetFields();
                    resolve(false)
                }
            }
            const modalProps = {
                Yes,
                No,
                title: 'Save Appointment Changes?',
                content: <p>You made some changes to the appointment.<br/>Would you like to save all changes?</p>
            }
            this.props.openConfirmModal(modalProps)
        });
    }

    showConfirmForCancel(id) {
        return new Promise((resolve,reject)=>{
            const Yes = {
                label: 'Yes, Cancel Appointment',
                handler: ()=>{
                    resolve(this.props.deleteVisit(id));
                    this.setState({
                        visitType: null
                    })
                },
                style:{
                        border: 'solid 1px #aaaaaa',
                        color:'red',
                        background:'white',
                        textShadow:'none'
                },
                closeModal: true
            }
            const No = {
                label: 'No',
                style:{
                    border: 'solid 1px #4278c3',
                    color:'#4278c3',
                    background:'white',
                    textShadow:'none'
                },
                handler: () => {
                    resolve(false)
                }
            }
            const modalProps = {
                Yes,
                No,
                title: 'Cancel Appointment?',
                content: 'Are you sure you want to cancel this appointment? This action can not be undone.'
            }
            this.props.openConfirmModal(modalProps)
        });
    }

    createVisit(){
        const { saveChanges } = this.props;
        const patientId = _.get(this.props,'selectedVisit.member.id','');
        const id = _.get(this.props,'selectedVisit.id','');
        const formVariables  = this.props.form.getFieldsValue();
        const date = formVariables.Date;
        const startTime = formVariables.startTime;
        const endTime = formVariables.endTime;
        const combinedStartTime = helpers.combineDateAndTime(date,startTime);
        const combinedEndTime = helpers.combineDateAndTime(date,endTime);
        const variables = {
            id: id,
            memberId:(patientId),
            type:formVariables.Type,
            appointmentAt:combinedStartTime,
            appointmentTo:combinedEndTime,
            providers:[formVariables.Doctor],
            reason:formVariables.Reason,
            shouldSeeDoctor: formVariables.shouldSeeDoctor,
            visitRoles: formVariables.visitRoles
        }
        saveChanges(variables);
        this.props.form.resetFields();

    }

    onSubmit=()=>{
        this.props.form.validateFields((error)=>{
            if(!error){
                this.showConfirmForReschedule();
            }
        })
    }

    render(){
        return(
            <div>
                <Form style={{display:'flex',flexDirection:'column'}}>
                    { this.renderDropDown()}
                    { this.renderFooter() }
                </Form>
            </div>
        )

    }

}
const setFromAndToDate = (variables,props)=>{
    const{ startOfWeek,endOfWeek } = props;
    variables.appointmentFrom  = startOfWeek;
    variables.appointmentTo = endOfWeek;

    return variables;
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(actions.confirm(props)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}
RescheduleAndCancelVisitComponent = Form.create()(RescheduleAndCancelVisitComponent);

export default compose(connect(null,mapToDispatch))(RescheduleAndCancelVisitComponent);


