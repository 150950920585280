import gql from 'graphql-tag'

export default gql`query getTaskAssignmentsByPatientId($memberId:EID!,$status:[TaskAssignmentStatus]){
  getTaskAssignmentsByPatientId(memberId:$memberId,status:$status){
    taskAssignments{
      id
      member{
        id
      }
      createdAt
      status
      dueDate
      assignedTo{
        id
        profile{
          fullName
        }
      }
      reason
      resolvedNote{
        note
        resolvedBy{
            id
        }
        resolvedAt
      }
      createdBy{
        id
        profile{
          fullName
        }
      }
      priorityLevel
    }
  }
}
`;
