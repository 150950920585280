import moment from 'moment'
import React from 'react'
import { convertUnits } from 'libModule/utils/convertUnits'

const VSM_US_units = convertUnits('VSM_US');

const alertColor = {
  NORMAL: '#64c2b1',
  ALERT: '#e89b5c',
  CRITICAL: '#e95c69',
  UNDEFINED: '#64c2b1'
}
const styles = {
  reading: {
    backgroundColor: '#e95c69',
    display: 'inline-block',
    padding: '0.1em 0.5em',
    borderRadius: '4px',
    color: 'white',
    cursor: 'pointer',
    textAlign: 'left'
  },
  date: {
    color: '#445661',
    fontSize: '12px'
  },
  em: {
    fontWeight: 'bold',
    paddingLeft: '5px',
    fontFamily: "'Proxima N W01 Light It', sans-serif"
  }
}
const mealMap = {
  'BREAKFAST': 'Breakfast',
  'LUNCH': 'Lunch',
  'DINNER': 'Dinner',
  'SNACK': 'Supper'
}
const renderCompletedReading = (measure, type) => {
  let reading = ''
  switch (type) {
    case 'BP':
      const {systolic_blood_pressure: sbp, diastolic_blood_pressure: dbp, heart_rate: hr } = measure
      const bpUnit = sbp? sbp.unit : dbp? dbp.unit : 'mmHg'
      reading = `${sbp? sbp.value : '--'}/${dbp? dbp.value : '--'} ${bpUnit}, ${hr? hr.value : '--'} beats/min`
      break
    case 'BG':
      const { blood_glucose: bg } = measure
      const bgUnit = bg? bg.unit : VSM_US_units.BG.unit
      reading = `${bg? bg.value : '--'} ${bgUnit}`
      break
    case 'HS':
      const { body_weight: bw } = measure
      const bwUnit = bw? bw.unit : VSM_US_units.HS.unit
      reading = `${bw? bw.value : '--'} ${bwUnit}`
      break
    default:
      reading = ''
  }
  // const alertLevel = measure.alert? measure.severity : 'UNDEFINED'
  const alertLevel = measure.severity? measure.severity : 'UNDEFINED'
  const style = {
    ...styles.reading,
    backgroundColor: alertColor[alertLevel]
  }
  return (
    <div>
      <span style={style}>
        {reading}
      </span>
      {reading !== '--'? <em style={styles.em}>M</em> : null}
    </div>
  )
}

export const formatSchedule = (item, type) => {
  switch (type) {
    case 'BP':
    case 'HS': {
      // check if it is by frequency or time slot
      if (item.schedule.start === '0000' && item.schedule.end === '2359') {
        const { target } = item.schedule
        const freq = !target? '--' : ((target === 1? 'Once' : target === 2? 'Twice' : `${target} Times`) + ' a Day')
        return <div>{freq}</div>
      } else {
        const start = item.schedule.start.slice(0, 2)+':'+item.schedule.end.slice(2)
        const end = item.schedule.end.slice(0, 2)+':'+ item.schedule.end.slice(2)
        return <div>{`${start} - ${end}`}</div>
      }
    }
    case 'BG': {
      const { schedule: { beforeMeal, mealType } } = item
      const freq = beforeMeal? (`Before ${mealMap[mealType]}`) : (`After ${mealMap[mealType]}`)
      return <div>{freq}</div>
    }
    default:
      return null
  }
}

export const formatReading = (item, type) => {
  switch (item.taskStatus) {
    case 'COMPLETED':
      return renderCompletedReading(item, type)
    case 'MISSED':
      return <div style={{...styles.reading, backgroundColor: '#6d6d6d'}}>Missed</div>
    default:
      return null
  }
}


export const formatVariables = (ownProps, type, severity) => {
  let count = 5
  let current = _.get(ownProps, 'pagination.current', 1)
  if (ownProps.view === 'Chart') {
    count = 999999
    current = 1
  }
  let variables ={
    filters: {
      "enrolledProgramId": ownProps.enrolledProgramId,
        "type": type,
        "severity": severity
    },
    page: current,
    count: count,
    sort: { field: 'DATE', direction: 'DESC'}
  }
  return variables
}