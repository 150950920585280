import  React from 'react';
import AddMedComponent from '../component/AddMedComponent'
import EditMedComponent from '../component/EditMedComponent';
import helpers from '../helpers/';
import {graphql, compose } from 'react-apollo'
import medNameList from '../../graphql/medNameList';
const { leaveAndSaveWorkTask } = helpers;
import { IHSearchTable, IHLoading, IHButton, _,IHInput } from 'ihcomponent';
import MedicineListComponent from '../component/MedicineListComponent';
import { Button,Icon } from 'antd';
const enumToTyep = {
    'ORAL':'Oral Medication',
    'INJECTABLE':'Injectable',
    'EXTERNAL':'External'
}
const statusEnumToType = {
    'NOT_TAKING':'Not Taking',
    'NOT_SURE':'Not Sure',
    'TAKING': 'Taking'
};

let MedicationAddAndListContainer = class extends React.Component {
    constructor(){
        super();
        this.state = {
            editMedicine: null
        }
    }

    renderMedList = () =>{
        const { props } = this;
        const { setEditMedicine } = this;
        return <MedicineListComponent { ...props} setEditMedicine={setEditMedicine}/>
    }


    setEditMedicine = (editMedicine)=>{
        const { refetchMedList,loading } = this.props;
        this.setState({
            editMedicine
        })
    }

    render(){
        const { props } = this;
        const { loading,refetchMedList,toNextStep } = props;

        if(loading){
            return loading;
        }
        const { editMedicine } = this.state;
        if(editMedicine) {
            return <EditMedComponent data={editMedicine}
                                     setEditMedicine={this.setEditMedicine}
                                     refetchMedList={refetchMedList}/>;
        }
        return <div>
                <AddMedComponent {...props}/>
                { this.renderMedList() }
               </div>
    }

}
const withData = graphql(medNameList,{
    options:()=>{
      return{
          fetchPolicy:'network-only'
      }
    },
    props:({data})=>{
        const { loading,medNameList } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            medNameList
        }
    }
})
export default compose(withData)(MedicationAddAndListContainer);
