import gql from 'graphql-tag'

export default gql`
  query iotDeviceConfigList($deviceStatusFilter:DeviceStatusFilterEnum){
  iotDeviceConfigList(filters:{deviceStatusFilter:$deviceStatusFilter}){
    data{
        id
        deviceId
        volume
        firmwareVersion
        language
        lastSeenAt
        battery
        createdAt
        updatedAt
        isActive
    }
  }
}`
