/**
 * Created by zizhangai on 1/2/17.
 */
import React, {Component} from 'react'
import moment from 'moment'
import { IHButton,message } from 'ihcomponent'
import {durationMap} from "../constants/map";
import I18N from 'modulesAll/I18N';
import { Button } from 'antd';
import { closeModal,openErrorModal } from 'layoutModule/actions/MainModal';
import { compose } from 'react-apollo'
import { connect } from 'react-redux';
import { GQLHelper } from 'libModule/utils'
import {Modal} from "antd/lib/index";

class Note extends Component {

    renderPauseFollowUp (row){
        const { tags,resumeDate,pauseDuration } = row;
        const durationInDays = _.find(durationMap,(i)=>i.value==pauseDuration);
        const durationValue = _.get(durationInDays,'name','unknown');

        return <div className='note-content'>
                <div className="intervention-notes-form-header note-content" >
                    <span style={{ fontWeight:'bold' }}>Reason: </span>{I18N.get(`providerNoteTags.${_.get(tags,0,'')}`)}
                </div>
                <div className="intervention-notes-form-header note-content" >
                    <span style={{ fontWeight:'bold' }}>Duration: </span>{durationValue}
                </div>
                <div className="intervention-notes-form-header note-content" >
                    <span style={{ fontWeight:'bold' }}>Est. Return Date: </span>{moment(resumeDate).format('MM/DD/YYYY')}
                </div>
              </div>
    }

    renderContentMap(category,row){
        const contentMap = {
            'PAUSE_FOLLOW_UP': this.renderPauseFollowUp(row)
        };
        const defaultContent =  '';
        return _.get(contentMap,category,defaultContent);
    }

    handleDeletePN(id,category){

        return new Promise((resolve,reject)=> {

            Modal.confirm({
                title:  'Are you sure to delete this Provider Note ?',
                onOk: () => {
                    this.deletePN(id,category);
                },
                zIndex: 2000,
                okText: 'Confirm',
                cancelText: `Cancel`,
                onCancel: ()=>{
                    resolve(true);
                }
            })
        })
    }

    deletePN(id,category){
        const { props } = this;
        const { closeModal,openErrorModal,closeAfterDelete } = props;
        const deleteProviderNote  = _.get(props,'note.deleteProviderNote',_.get(props,'deleteProviderNote'));
        deleteProviderNote(id,category)
            .catch(e=>{
                openErrorModal(GQLHelper.formatError(e))
            })
            .then(_=>message.success('Provider Note deleted'));

        if(closeAfterDelete) {
            closeModal();
        }
    }

    render() {
        const { props } = this;
        const { category, createdBy, createdAt, content, error, id } = props.note
        const { profile: {fullName} } = createdBy
        const time = moment(createdAt).format("MMM-DD-YYYY HH:mm");
            // "MMM-DD-YYYY :mm a")

        return (
            <div className={`intervention-notes-single-note ${error && 'note-error'} `}>
                <div className="intervention-notes-single-note-header note-content">
                    <div className="user-name">{fullName}</div>
                    <div className="updated-time">{time}</div>
                </div>
                <div className="note-content">
                    {category !== 'CALL_LOG' && '* '}
                    <span style={{fontWeight: 'bold'}}>Note type: </span>{I18N.get(`providerNoteCat.${category}`)}
                </div>
                { this.renderContentMap(category, props.note) }
                <div className="note-content">
                    <span style={{fontWeight: 'bold'}}>Details: </span>{content}
                </div>
                {!error && props.children}
                <div style={{ textAlign:'right' }}>
                    <Button onClick={() => this.handleDeletePN(id,category)}>
                        Delete
                    </Button>
                </div>
            </div>
        )
    }
}


const mapDispatch = (dispatch) => {
    return {
        // closeModal: () => dispatch(closeModal()),
        openErrorModal:(errorMessage) => dispatch(openErrorModal(errorMessage)),


    }
}
export default compose(connect(null,mapDispatch))(Note);
