import { React, IHSearchTable, IHCheckbox, IHInputNumber, IHButton, _, moment,IHLoading } from 'ihcomponent';
import { browserHistory } from 'react-router';
import { showMonthsOrDays, RequestCache} from 'libModule/utils';
import UserClass from 'modulesAll/graphql/class/User';
import ProgramClass from 'modulesAll/graphql/class/EnrolledProgram';
import PatientStarModal from 'modulesAll/common/containers/PatientStarModal';
import PatientStarComponent from './PatientStarComponent';
import Store from 'libModule/reduxStore'
import I18N from 'modulesAll/I18N';
import { getMonthOfYear } from 'libModule/utils';
import { DoctorNameArray } from 'libModule/constants'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import {isEqual} from 'lodash';
import CreateNewTaskComponent from '../../../taskAssignment/components/CreateNewTaskComponent';
import CreateNewTaskContainer from '../../../taskAssignment/containers/CreateNewTaskContainer';
export default class extends React.Component {
  static displayName = 'patientList/main/components/ListComponent'

  constructor(props) {
    super(props);
    this.state = {
      isStatusFilterClicked: false
    }
    this.adminProgramFilter = UserClass.getAdminProgramFilter();
    this.changeTableActionFilter = this.changeTableActionFilter.bind(this);
  }

  componentWillMount() {
    this.props.onLoadParams(null, true)
    RequestCache.set('nurse/currentPatient', undefined); //reset
  }
  componentWillUnmount() {
    if (!new RegExp(/^(\/patients)/).test(Store.getState().routing.location.pathname)) {
        this.props.onLoadParams(null, false)
    }
  }

  changeTableActionFilter(newFilter) {
    const { onTableChange, filter = {}, sort = {}, page } = this.props;

    const sortParam = _.pick(sort, ['field', 'order']);
    const filterParam = {
      ...filter,
      ...newFilter,
    };
    onTableChange({current: 1, size: 10}, filterParam, sortParam);
  }

  getTableProps(){
    const {
      showPatientStarModal, onTableChange, onTableSearch, onSearchEnter,
      data, filter, sort, page, search = '',setModalData
    } = this.props;
    const { loading, patientList,refetch } = data;
    const list = _.get(patientList, 'data');
    let checkPage = false;
    const loadObj = { spinning:loading,indicator: <IHLoading/> };
    return {
      title: `Patients (${_.get(patientList, 'pageInfo.total', 0)})`,
      rowKey: doc => doc.user._get('id'),
      showSearchInput: true,
      searchValue: search,
      onSearch: onTableSearch,
      onSearchEnter:() => {
        Mixpanel.track('searched', '', 'patient', {});
        onSearchEnter();
      },
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize', 10),
        total: _.get(patientList, 'pageInfo.total', 0),
        onChange: (e) => {
          Mixpanel.track('clicked','pager','patient', {PAGE_NUM : "Page" + e});
          checkPage = true;
        }
      },
      onChange: (p, f, s) => {
        const newFilter = {
          ...f,
          showAll: filter.showAll,
          daysRemaining: filter.daysRemaining
        }
        let result = null;
        if(!isEqual(f.doctorId, this.props.filter.doctorId)) {
          result = ['set', 'doctor name filter'];
        } else if(!isEqual(f.gender, this.props.filter.gender)) {
          result = ['set', 'gender filter'];
        } else if(!isEqual(f.timeSpent, this.props.filter.timeSpent)) {
          result = ['set', 'billable time filter'];
        } else if(!isEqual(f.status, this.props.filter.status)){
          result = ['set', 'status filter'];
        } else {
          if(this.props.sort.order && !s.order) {
            Mixpanel.track('clicked', 'sort', 'age is canceled', '');
            return onTableChange(p, newFilter, s);
          } else {
            if(checkPage == false) {
              let result = s.order == 'descend' ? 'old to young' : 'young to old';
              Mixpanel.track('clicked', 'sort', 'age ' + result, '');
            }
            return onTableChange(p, newFilter, s);
          }
        }
        if(result[1] == 'doctor name filter') {
          if((!f.doctorId && this.props.filter.doctorId) || (f.doctorId && f.doctorId.length === 0 && this.props.filter.doctorId)) {
            result[0] = 'reset';
            result[1] = 'doctor name filter';
          }
        } else if(result[1] == 'gender filter') {
          if((!f.gender && this.props.filter.gender) || (f.gender && f.gender.length === 0 && this.props.filter.gender)) {
            result[0] = 'reset';
            result[1] = 'gender filter';
          }
        } else if(result[1] == 'billable time filter') {
          if((!f.timeSpent && this.props.filter.timeSpent) || (f.timeSpent && f.timeSpent.length === 0 && this.props.filter.timeSpent)) {
            result[0] = 'reset';
            result[1] = 'billable time filter';
          }
        } else {
          if((!f.status && this.props.filter.status) || (f.status && f.status.length === 0 && this.props.filter.status)) {
            result[0] = 'reset';
            result[1] = 'status filter';
          }
        }
        if(checkPage == false) {
          Mixpanel.track(result[0] + '', result[1] + '', '', {FILTER_TYPE: result[1]});
        }
        return onTableChange(p, newFilter, s)
      },
      loading:loadObj,
      inputPlaceholder: `Search by Name ${I18N.get('keywords.NRIC/FIN')} or ${I18N.get('keywords.PHONE')}`,
      dataSource: _.map(list, l => ({
        user: new UserClass(l.user),
        o: l
      })),
      onRow: doc => {
        const user = doc.user;
        const curPage = this.props.curPage;
        const handleChangePage = this.props.handleChangePage;

        return {
          onClick: () => {
            // const PATIENT_FULL_NAME = user._get('profile.fullName');
            const PATIENT_ID = user._get('id');
            Mixpanel.calculateDuration(curPage);
            Mixpanel.track('clicked','patient','patient_list',{PATIENTS_ID : PATIENT_ID });
            browserHistory.push(`/patients/${user._get('id')}/enrolledprogram/default`);
            Mixpanel.time_event('patient_profile')
            handleChangePage('patient_profile')
          }
        }
      },
      columns: [
        {
          key: 'Star',
          width: '60px',
          render: (t, o) => (
            <PatientStarComponent
              user={o.user}
              enrolledPrograms={t.o.enrolledPrograms}
              setModalData={setModalData}
            />
          ),
        },
        {
            title:'Registration Date',
            key: 'registeredAt',
            dataIndex:'registeredAt',
            render: (t, doc) => doc.user.format('registeredAt'),
            sorter:true,
            sortOrder: _.get(sort, 'field', null) === 'registeredAt' ? _.get(sort, 'order', false) : null,
        },
        {
          title: 'Name',
          key: 'fullName',
          dataIndex: 'fullName',
          render: (t, doc) => doc.user._get('profile.fullName'),
          sorter: true,
          sortOrder: _.get(sort, 'field', null) === 'fullName' ? _.get(sort, 'order', false) : null,
        },
        {
          title:'Doctor Name',
          key:'doctorId',
          filters: _.map(JSON.parse(sessionStorage.getItem('doctorName')),({fullName,id})=>({ text:fullName,value:id })),
          render:(t,doc)=> doc.user._get('profile.doctor.profile.fullName'),
          filteredValue: _.get(filter, 'doctorId'),
        },
        {
          title: 'Age',
          key: 'age',
          dataIndex: 'age',
          width: '6%',
          render: (t, doc) => {
            return doc.user.format('age') + 'yr'
          },
          sorter: true,
          sortOrder: _.get(sort, 'field', null) === 'age' ? _.get(sort, 'order', false) : null,
        },
        {
          title: 'Gender',
          key: 'gender',
          width: '8%',
          render: (t, doc) => doc.user.format('gender'),
          filters: [
            { value: 'Male', text: 'Male' },
            { value: 'Female', text: 'Female' }
          ],
          filteredValue: _.get(filter, 'gender'),
        },
        {
          title:'CCM/RPM',
          key:'CCM/RPM',
          width:'10%',
          render:(t,doc)=>{
            const categories = doc.user.format('CCM/RPM');
            let content = [];
            categories.forEach((o,i)=>{
                if(o.enrolled){
                  content.push(o.name);
                }
            })
            return content.length>0 ? content.join(', ') :'--';
          }
        },
        {
          title:'Vitals',
          key:'vitalTypes',
          width:'10%',

          render:(t,doc)=>{
              const program = doc.o.enrolledPrograms;

              return program.map((o)=>{
                if(o.status=='STARTED') {
                    return o.tasks.map((p,i) => {
                      const key = `${o.id}${i}`;
                            return <div key={key}>{p.type}</div>
                    })
                }
              })
          }
        },
        {
          title:'Billable Time',
          key:'timeSpent',
          dataIndex:'timeSpent',
          sorter:true,
          sortOrder: _.get(sort, 'field', null) === 'timeSpent' ? _.get(sort, 'order', false) : null,
          filters: [
                    { value: `10:15`, text: '10-15 mins' },
                    { value: `40:50`, text: '40-50 mins' }
          ],
          filteredValue: _.get(filter, 'timeSpent'),
          render:(value,data)=>{
            const user = new UserClass(data);
            const totalTime = _.get(user,'variables.user.variables.monthlyTimeSpent.totalTime', 0) ;
            const timeInMins = Math.round(totalTime/60);
            return timeInMins + (timeInMins > 1 ? ` mins` : ` min`);
          }
        },
        {
          title: 'Status',
          key: 'status',
          render: (t, doc) => (
            <div>
              {
                _.map(_.orderBy(doc.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) =>
                  <div key={index}>{ep.status === 'SUSPENDED'? 'Suspended' :
                    ((ep.status === 'CREATED' || ep.status === 'PENDING') ? 'Incomplete' : (ep.status=='DISCHARGED') ? 'Discharged' : 'Enrolled' )}</div>
                )
              }
            </div>
          ),
          filters: [
            { value: 'INCOMPLETED', text: 'Incomplete' },
            { value: 'ENROLLED', text: 'Enrolled' },
            { value: 'SUSPENDED', text: 'Suspended' },
            { value: 'DISCHARGED',text:'Discharged' }
          ],
          filteredValue:_.get(filter, 'status')
        },
        // {
        //   title: `${I18N.get('keywords.Program')} Start Date`,
        //   key: 'psd',
        //   width: '10%',
        //   render: (t, doc) => {
        //     return _.map(_.orderBy(doc.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) => (
        //       (ep.status === 'CREATED' || ep.status === 'PENDING')? <br key={index}/> :
        //       ep.startDate
        //         ? <div key={index}>{moment(ep.startDate).format('MMM-DD-YYYY')}</div>
        //         : <br key={index}/>
        //     ))
        //   }
        // },
        // {
        //   title: `${I18N.get('keywords.Program')} End Date`,
        //   key: 'endDate',
        //   width: '10%',
        //   render: (t, doc) => {
        //     return _.map(_.orderBy(doc.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) => (
        //       (ep.status === 'CREATED' || ep.status === 'PENDING')? <br key={index}/> :
        //       ep.endDate
        //         ? <div key={index}>{moment(ep.endDate).format('MMM-DD-YYYY')}</div>
        //         : <br key={index}/>
        //     ))
        //   }
        // },
        // {
        //   title: 'Duration',
        //   key: 'dura',
        //   width: '7%',
        //   render: (t, doc) => {
        //     return _.map(_.orderBy(doc.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) => {
        //       if (!ep.endDate || !ep.startDate) {
        //         return <br key={index} />
        //       }
        //       if (ep.status === 'CREATED' || ep.status === 'PENDING') {
        //         return <br key={index} />
        //       }
        //
        //       const diff = moment(ep.endDate).diff(ep.startDate, 'days') + 1;
        //       return <div key={index}>{showMonthsOrDays(diff, 'text')}</div>;
        //     });
        //   }
        // },
        // {

        //   title: `Days left in ${I18N.get('keywords.Program')}`,
        //   key: 'dlip',
        //   render: (t, doc) => {
        //     return _.map(_.orderBy(doc.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) => {
        //       if (!ep.endDate) {
        //         return <br key={index} />
        //       }
        //       if (ep.status === 'CREATED' || ep.status === 'PENDING') {
        //         return <br key={index} />
        //       }
        //       let diff;
        //       if(ep.startDate > moment()){
        //         //future date
        //          diff = moment(ep.endDate).diff(ep.startDate, 'days') + 1;
        //       } else
        //          diff = moment(ep.endDate).diff(moment(), 'days') + 1;
        //       return <div key={index}>{diff} days</div>;
        //     });
        //   },
        //   filterDropdown: (
        //     <div className="custom-filter-dropdown">
        //       <IHInputNumber
        //         min={1}
        //         max={200}
        //         onChange={value => this._daysRemain = value}
        //         defaultValue={_.get(filter, 'daysRemaining.0', '')}
        //       />
        //       <IHButton
        //         type="primary"
        //         onClick={e => this.changeTableActionFilter({ daysRemaining: [this._daysRemain] })}
        //       >OK</IHButton>
        //     </div>
        //   ),
        // }
      ]
    }
  }

  render() {
    const { props } = this;
    const { showModal,loading,data } = props;
    const { refetch } = data;
    if(loading){
      return <div>loading</div>
    }
    return (
      <div>
        {<IHSearchTable {...this.getTableProps()} />}
        { showModal ? <CreateNewTaskContainer {...props} renderBody={true} refetchUserList = { refetch } />:'' }
      </div>
    );
  }
}
