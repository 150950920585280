import { combineReducers } from 'redux';
import type from '../constants';
import _ from 'lodash';
import { API } from '../actions/api';
import { createAuditLog } from 'libModule/utils/auditLog'
const INITIAL_STATE = {
  PNStatus: {},
  currentUserId: '',
  channels: {},
  userMap: {},
  selectedChannel: '',
  orgTeamMap: {},
  profileHeight:0,
  OrgHasUnReadMessageCount:{},
  OrgHasUnread:{},
  toBottom:false,
  isFullyLoaded: false,
  patientIdToChannel: {}
};

const getTeamIdFromCh = (ch)=>{
  const teamId = ch.split('-')[0];
  return teamId;
}

// const updateChatInfoInSubscribe = (channel)=>{
//     //if new message does not belong to selected channel;
//     let counterDiff = 0;
//     teamId = getTeamIdFromCh(channel);
//
//     const msgObj = newMsgObj[channel];
//     let curUpdatedCount = state.channels[channel].counter;
//
//     if (state.selectedChannel && (state.selectedChannel !== channel) && msgObj.isListening) {
//         curUpdatedCount += 1;
//         counterDiff = 1;
//     }
//     //if new message belongs to selected channel;
//     else if (state.selectedChannel === channel) {
//         counterDiff = -curUpdatedCount;
//         counterDiff = counterDiff< 0?  0 : counterDiff;
//         curUpdatedCount = 0;
//     }
//     // if there is no selected Channel and not in init process
//     else if (!state.selectedChannel && msgObj.isListening) {
//         curUpdatedCount += 1;
//         counterDiff = 1;
//     }
//     //if is during inti process
//     else if (!state.selectedChannel && !msgObj.isListening) {
//         curUpdatedCount = msgObj.unreadCountFromOffLine;
//         counterDiff = curUpdatedCount;
//     }
//     //retrieve map to find the org contains team
//     for (let org in orgTeamMap) {
//         if (orgTeamMap[org].indexOf(teamId) != -1) {
//             OrgHasUnReadMessageCount[org] = (counterDiff += OrgHasUnReadMessageCount[org] || 0);
//             newOrgHasUnread[org] = OrgHasUnReadMessageCount[org] != 0;
//         }
//     }
//     newChannels[channel].counter = curUpdatedCount;
//     newChannels[channel].lastMsgText = msgObj.lastMsgText;
//     newChannels[channel].lastMsgTime = msgObj.lastMsgTime;
//     newChannels[channel].lastMsgSender = msgObj.lastMsgSender;
// }

const reducer = (state = INITIAL_STATE, action = {}) => {


    const updateChatInfoInit = (isInit= false)=>{
        let newMsgObj = action.payload.msgObj;
        _.forEach(Object.keys(newMsgObj),channel=> {

        let counterDiff = 0;
        teamId = getTeamIdFromCh(channel);

        const msgObj = newMsgObj[channel];
        let curUpdatedCount = state.channels[channel].counter;

        // if (state.selectedChannel && (state.selectedChannel !== channel) && msgObj.isListening) {
        //     curUpdatedCount += 1;
        //     counterDiff = 1;
        // }
        // //if new message belongs to selected channel;
        // else if (state.selectedChannel === channel) {
        //     counterDiff = -curUpdatedCount;
        //     counterDiff = counterDiff< 0?  0 : counterDiff;
        //     curUpdatedCount = 0;
        // }
        // // if there is no selected Channel and not in init process
        // else if (!state.selectedChannel && msgObj.isListening) {
        //     curUpdatedCount += 1;
        //     counterDiff = 1;
        // }
        //if is during inti process
        //  if (!state.selectedChannel && !msgObj.isListening) {
        if(isInit){
            curUpdatedCount = msgObj.unreadCountFromOffLine;
            counterDiff = curUpdatedCount;
        }
        //retrieve map to find the org contains team
        for (let org in orgTeamMap) {
            if (orgTeamMap[org].indexOf(teamId) != -1) {
                OrgHasUnReadMessageCount[org] = (counterDiff += OrgHasUnReadMessageCount[org] || 0);
                newOrgHasUnread[org] = OrgHasUnReadMessageCount[org] != 0;
            }
        }
            newChannels[channel].counter = curUpdatedCount;
            newChannels[channel].lastMsgText = msgObj.lastMsgText;
            newChannels[channel].lastMsgTime = msgObj.lastMsgTime;
            newChannels[channel].lastMsgSender = msgObj.lastMsgSender;
        })
  }

  const updateChatInfoInSubscribe=(curChanelUnread)=>{

    let updatedCount = curChanelUnread;
    let counterDiff = 0;
    //if is foodlog review sikp
    if(action.payload.msgObj.entry.type ==='foodReview'){

    }
    // if there is ack from other team member;
    else if(action.payload.msgObj.entry.type ==='ACK'&&_.get(action,'payload.msgObj.entry.publisher').split('-')[0]=='team'){
        counterDiff -= (updatedCount);
        updatedCount = 0;
    }
    else if(action.payload.msgObj.entry.type ==='ACK'){

    }
    //if new message does not belong to selected channel;
    else if (state.selectedChannel && (state.selectedChannel !== action.payload.channel) && action.payload.msgObj.isListening) {
        updatedCount += 1;
        counterDiff = 1;
    }
    //if new message belongs to selected channel;
    else if (state.selectedChannel === action.payload.channel) {
        counterDiff -= updatedCount;
        updatedCount = 0;

    }
    // if there is no selected Channel and not in init process
    else if (!state.selectedChannel && action.payload.msgObj.isListening) {
        updatedCount += 1;
        counterDiff = 1;
    }
    //if is during inti process
    else if (!state.selectedChannel && !action.payload.msgObj.isListening) {
        updatedCount = action.payload.msgObj.unreadCountFromOffLine;
        counterDiff = updatedCount;

    }
    newChannels[action.payload.channel].counter = updatedCount;

      //retrieve map to find the org contains team
    for (let org in orgTeamMap) {
        if (orgTeamMap[org].indexOf(teamId) != -1) {
            const orgCount = (counterDiff += (OrgHasUnReadMessageCount[org] || 0));
            OrgHasUnReadMessageCount[org] = orgCount < 0 ? 0 : orgCount;
            newOrgHasUnread[org] = OrgHasUnReadMessageCount[org] != 0;
        }
    }
    // newChannels[action.payload.channel].counter = updatedCount;
    if(action.payload.msgObj.type!='ACK') {
        newChannels[action.payload.channel].lastMsgText = action.payload.msgObj.lastMsgText;
        newChannels[action.payload.channel].lastMsgTime = action.payload.msgObj.lastMsgTime;
        newChannels[action.payload.channel].lastMsgSender = action.payload.msgObj.lastMsgSender;
    }
  }

  let newChannels = {...state.channels};
  let newPatientIdToChannel = {...state.patientIdToChannel};
  let newUserMap = {...state.userMap};
  let OrgHasUnReadMessageCount = {...state.OrgHasUnReadMessageCount};
  let newOrgHasUnread = {...state.OrgHasUnread};
  const { orgTeamMap } = state;
  const channel  = _.get(action,'payload.channel','-');
  let teamId = _.isString(channel) ? channel.split('-')[0]:'';
  let counterDiff = 0;
  let isbatch = _.get(action,'payload.isbatch',false);
    // console.log('ACTION TYPE:',action.type);
  switch (action.type) {

    //Add Message Method Start
    case type.ADD_MESSAGE:
      try {
          let curChanelCounter = newChannels[action.payload.channel].counter || 0;
          if (newChannels[action.payload.channel]) {
              newChannels[action.payload.channel].history.push(action.payload.msgObj);
              if (action.payload.msgObj.entry.type === 'ACK' && _.get(action, 'payload.msgObj.entry.publisher').split('-')[0] == 'team') {
                  if (action.payload.channel.split('-')[1]) {
                      newChannels[action.payload.channel].counter = 0;
                  }
              }
          }
          else {
              const patientId = action.payload.channel.split('-')[1];
              const msg = action.payload.msgObj;
              const msgObj = {
                  timetoken: msg.timetoken,
                  entry: msg.message,
              }
              newChannels[action.payload.channel] = {
                  counter: 0,
                  lastMessageTimestamp: action.payload.msgObj.timetoken,
                  lastMsgText: action.payload.msgObj.text,
                  history: _.get(action, 'payload.msgObj.entry.type') != 'foodReview' ? [msgObj] : newChannels[action.payload.channel].history,
                  patientId
              }
          }

          if (_.get(action, 'payload.msgObj.entry.type') != 'foodReview') {

              updateChatInfoInSubscribe(curChanelCounter);
          }
      }catch(e){
          console.log('current error',e);
          console.log('channels list',newChannels);
          console.log('current channel',action.payload.channel);
      }
      return ({
          ...state,
          channels:newChannels,
          toBottom: true,
          OrgHasUnReadMessageCount,
          OrgHasUnread:_.isEqual(newOrgHasUnread,state.OrgHasUnread) ? state.OrgHasUnread : newOrgHasUnread
      });
    //End method

    case type.SET_CURRENT_USERID:
      return {
        ...state,
        currentUserId: action.payload
      }

    //Add History Method
    case type.ADD_HISTORY:
      const newCh = action.payload.channel;
      const messagesCount = action.payload.messageCount;
      const hasMoreMessage = action.payload.timestamp!=0&&(messagesCount!=0);
      let toBottom = _.get(action,'payload.toBottom',false);
      if(isbatch){
          _.forEach(Object.keys(newCh),channel=>{
              const c = newCh[channel];
              const curChanelHasMoreMessage = parseInt(c.timestamp)!=0&&(_.get(c,'messages.length')!=0);
              newChannels[channel] = {
                  counter: 0,
                  lastMessageTimestamp: (c.lastMsgTS),
                  history: [ ...c.messages ],
                  patientId: c.patientId,
                  hasMoreMessage: curChanelHasMoreMessage
              };
              newPatientIdToChannel[ c.patientId ] = channel;
          })
      }else {
          newChannels[newCh] = {
              counter: 0,
              lastMessageTimestamp: (action.payload.timestamp),
              history: _.uniqBy([...action.payload.messages, ...state.channels[newCh].history],(o)=>Number(o.timetoken)),//remove duplicate message object
              patientId: action.payload.patientId,
              hasMoreMessage: hasMoreMessage
          };
          newPatientIdToChannel[ action.payload.patientId ] = newCh;

      }
      return ({
        ...state,
        toBottom: toBottom,
        channels:newChannels,
        patientIdToChannel:newPatientIdToChannel
        });
    //End Add History Method

    case type.CLEAR_HISTORY:
      return {
        ...state,
        chatHistory:action.payload.messages
      };
    case type.SELECT_CHANNEL:
      // if(newChannels[action.payload]) {
      //    newChannels[action.payload].counter = 0;
      // }
      return ({
        ...state,
        selectedChannel:action.payload,
        channels: newChannels
      });
    case type.ADD_CHANNELS:
      return ({
        ...state,
        channels:action.payload});
    case type.ADD_USER_MAP:

        const uuid = _.get(action,'payload.newUser.uuid');
        const updatedUser = _.get(action,'payload.newUser');
        const hasAvatarError = _.get(action,'payload.avatarError');
        if(hasAvatarError || !newUserMap[uuid]) {
            newUserMap[uuid] = updatedUser;
        }

      return ({
        ...state,
        userMap : newUserMap
      })

    case type.SET_USER_MAP:
      newUserMap = Object.keys(state.userMap).length > 0 ? {...state.userMap, ...action.payload} : {...action.payload};
      return {
        ...state,
        userMap: newUserMap
      };
    case type.VERIFY_AND_ADD_CHANNEL:
        const channelToAdd = action.payload.channelName;
        const patientId = action.payload.patientId
        const newChannelInfo = {
            lastMessageTimestamp:new Date()*1e4,
            history:[],
            patientId:patientId,
            counter:0
        }

        if(!newChannels[channelToAdd]) {
            newChannels[channelToAdd] = newChannelInfo;
        }
        return ({
          ...state,
          channels: newChannels
        });

    //Update Channel Info Method
    case type.UPDATE_CHANNEL_INFO:
        // let updatedCount = state.channels[action.payload.channel].counter;
        if(isbatch){
            updateChatInfoInit(isbatch);
        }
        else{
            if(action.payload.msgObj.type!='ACK') {
                    newChannels[action.payload.channel].lastMsgText = action.payload.msgObj.lastMsgText;
                    newChannels[action.payload.channel].lastMsgTime = action.payload.msgObj.lastMsgTime;
                    newChannels[action.payload.channel].lastMsgSender = action.payload.msgObj.lastMsgSender;
                }
        }
            // if (action.payload.msgObj.type === 'ACK'&&
            //     _.get(action,'payload.msgObj.publisher').split('-')[0]=='team'
            // ){
            //     counterDiff -= (updatedCount);
            //     // counterDiff = counterDiff< 0?  0 : counterDiff;
            //     updatedCount = 0;
            //     console.log('inside clear',counterDiff,updatedCount);
            // }
            // //if new message does not belong to selected channel;
            // else if (state.selectedChannel && (state.selectedChannel !== action.payload.channel) && action.payload.msgObj.isListening) {
            //     updatedCount += 1;
            //     counterDiff = 1;
            // }
            // //if new message belongs to selected channel;
            // else if (state.selectedChannel === action.payload.channel) {
            //     counterDiff -= updatedCount;
            //     // counterDiff = counterDiff< 0?  0 : counterDiff;
            //     updatedCount = 0;
            // }
            // // if there is no selected Channel and not in init process
            // else if (!state.selectedChannel && action.payload.msgObj.isListening) {
            //     updatedCount += 1;
            //     counterDiff = 1;
            // }
            // //if is during inti process
            // else if (!state.selectedChannel && !action.payload.msgObj.isListening) {
            //     updatedCount = action.payload.msgObj.unreadCountFromOffLine;
            //     counterDiff = updatedCount;
            // }
            // //retrieve map to find the org contains team
            // for (let org in orgTeamMap) {
            //     if (orgTeamMap[org].indexOf(teamId) != -1) {
            //         const orgCount = (counterDiff += (OrgHasUnReadMessageCount[org] || 0));
            //         OrgHasUnReadMessageCount[org] = orgCount < 0 ? 0 : orgCount;
            //         newOrgHasUnread[org] = OrgHasUnReadMessageCount[org] != 0;
            //     }
            // }
            //
            // newChannels[action.payload.channel].counter = updatedCount;
            // if(action.payload.msgObj.type!='ACK') {
            //     newChannels[action.payload.channel].lastMsgText = action.payload.msgObj.lastMsgText;
            //     newChannels[action.payload.channel].lastMsgTime = action.payload.msgObj.lastMsgTime;
            //     newChannels[action.payload.channel].lastMsgSender = action.payload.msgObj.lastMsgSender;
            // }

        return ({
          ...state,
          channels:newChannels,
          OrgHasUnReadMessageCount,
          OrgHasUnread:_.isEqual(newOrgHasUnread,state.OrgHasUnread) ? state.OrgHasUnread : newOrgHasUnread
        });

    case type.UPDATE_UNREAD_COUNTER:
        let newCounter = 0;
        if(state.selectedChannel != action.payload.channel) {
            const channels = state.channels;
            const channelCounter = channels[action.payload.channel].counter||0;
            newCounter = channelCounter ;
            counterDiff = 0;
        }else{
            newCounter = 0;
            counterDiff = -newChannels[action.payload.channel].counter||0;
        }
        for(let org in orgTeamMap){
            if(orgTeamMap[org].indexOf(teamId)!=-1){
                const orgCount = (counterDiff += (OrgHasUnReadMessageCount[org] || 0));
                OrgHasUnReadMessageCount[org] = orgCount <0 ? 0 : orgCount;
                newOrgHasUnread[org] = (OrgHasUnReadMessageCount[org]!=0);

            }
        }
        newChannels[action.payload.channel].counter = newCounter;
        return {...state,
            channels:newChannels,
            OrgHasUnReadMessageCount,
            OrgHasUnread:newOrgHasUnread
                // _.isEqual(newOrgHasUnread,state.OrgHasUnread) ? state.OrgHasUnread : newOrgHasUnread
        };

    case type.UPDATE_PN_STATUS:
      return {...state,
      PNStatus:action.payload};

    case type.SET_ORG_TEAM_MAP:
      return {...state,
      orgTeamMap:action.payload};

    case type.SET_PROFILE_DIV_HEIGHT:
      return {...state,
        profileHeight:action.payload
      };

    case type.SET_TO_BOTTOM:
      return{
            ...state,
            toBottom:action.payload
        }

    case type.SET_IS_FULLY_LOADED:
      return {
          ...state,
          isFullyLoaded:action.payload
      }

      default:
      return state
  }
};

export default {
  chat: combineReducers({
    main: reducer
  })
};
