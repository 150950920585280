import React from 'react';
import { Button,Modal,Input,Form,Select,DatePicker,Radio,message } from 'antd';
import moment from "moment/moment";
import map from '../constants/tableMap';
const { priorityMap,patientTaskHistoryColumn } = map;
const { TextArea } = Input;
const { Item } = Form;
import { IHSearchTable,IHTable,IHLoading } from 'ihcomponent';
import helper from '../helper';
import '../style/index.scss'
import MyTasksModalComponent from "./MyTasksModalComponent";
import TrackingTaskModalComponent from './TrackingTaskModalComponent';
import TaskAssignmentTableComponent from '../components/TaskAssignmentTableComponent';
import createTaskAssign from '../query/createTaskAssign';
import Client from 'libModule/gqlClient';
import {connect} from "react-redux";
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from '../../timer/actions/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import API from '../API/index';
const { stopTimer,updateInterventionDetails }= timerActions.default;
const style = {
    rowStyle:{
        display:'flex',
        flexDirection:'column'
    },
    buttonDivStyle:{
        margin:'10px 0px'
    },
    buttonStyle:{

    },
    labelStyle:{
        fontWeight:'bold'
    }

}

let CreateNewTaskComponent = class extends React.Component {
    static displayName ='CreateNewTaskComponent';

    constructor(){
        super();
        this.state = {
            note:'',
            expandIndex:[],
            showCreateFormOnly:false
        }

    }

    onChangeInput = (e)=>{
        const note = e.target.value;
        this.setState({
            note
        })
    }

    onSubmit = ()=>{
        const { closeModal,props } = this;
        const { form,modalData,refetchUser } = props;
        const refetch = _.get(props,'data.refetch');
        const { memberId } = modalData||this.props;
        const { reason,assignedTo,dueDate,priorityLevel } = form.getFieldsValue();
        form.validateFields((error)=>{
            if(!error){
                Client.mutate({
                    mutation:createTaskAssign,
                    variables:{
                        reason,
                        assignedTo,
                        dueDate:dueDate ? moment(dueDate).endOf('day'):null,
                        priorityLevel,
                        memberId,
                    }
                }).then(res=>{
                    if(refetch) {
                        refetch();
                    }
                    if(refetchUser){
                        refetchUser();
                    }
                    API.fetchTaskUnreadList();
                    const apiName = Object.keys(res.data)[0];
                    const docId = res.data[apiName].id;
                    const resource = atob(docId).split(':')[0];
                    let displayName = this.constructor.displayName;
                    const summary = res.data[apiName].id;
                    Mixpanel.track('clicked', 'submit', 'new task popup', {PRIORITY: priorityLevel});
                    displayName = `${displayName}:${memberId}`;
                    this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
                    this.props.stopTimer(displayName, 'saveModal');
                    message.success('Task Assigned');
                    closeModal();
                }).catch(e=>{
                    Modal.error({ content : e.message });
                })
            }
        })

    }
    closeModal = ()=>{
        const { setModalData } = this.props;
        setModalData({showModal:false,modalData:null})
    }
    renderRadioGroup = ()=>{
        return  <Radio.Group>
            { _.map(priorityMap,(value,key)=><Radio value={key} key={value}>{value}</Radio>) }
        </Radio.Group>
    }

    renderFooter = ()=>{
        const { closeModal } = this;
        return <div>
            <Button type='primary' onClick={ ()=>this.onSubmit() }>Submit</Button>
            <Button onClick={ ()=>closeModal() }>Cancel</Button>
        </div>
    }

    renderTable = (enrolledPrograms)=>{

        const columns = patientTaskHistoryColumn;
        const { props } = this;
        return <TaskAssignmentTableComponent { ...props} columns={columns} enrolledPrograms={enrolledPrograms}/>
    }

    getFormProps = (enrolledPrograms,team)=>{
        const { getFieldDecorator } = this.props.form;
        const { modalData } = this.props;
        const { patientName } = modalData||{};
        const disabledDate = (current)=>{
            return current && current < moment().startOf('day');
        }
        return <Form style={{ padding:10 }} className='trackingTaskModal'>
                    <strong style={style.labelStyle}>{`New Task: ${patientName}`}</strong>
                    <Item key='reason'>
                        {getFieldDecorator('reason',{ rules: [{ required: true,message:'Reason is Required' }] })(<TextArea rows={6}/>)}
                    </Item>
                    <div style={{display:'flex'}}>
                        <Item key='assignedTo' label='Assign To'>
                            {getFieldDecorator('assignedTo')(helper.assignedToDropDown(enrolledPrograms,team))}
                        </Item>
                        <Item key='dueDate' label="Due Date">
                            {getFieldDecorator('dueDate')(<DatePicker format='MM/DD/YYYY' disabledDate={disabledDate} />)}
                        </Item>
                    </div>
                    <Item label='Priority Level'>
                        {getFieldDecorator('priorityLevel',{initialValue:'MEDIUM', rules: [{ required: true,message:'Priority Level is Required' }] })(this.renderRadioGroup())}
                    </Item>
                    { this.renderFooter() }
               </Form>
    }

    onClickCreateNewTask = ()=>{
        Mixpanel.track('clicked', 'create a new task', 'patient task list popup');
        this.setState({
            showCreateFormOnly:true
        })
    }

    renderModalFooter = ()=>{
        return <div>
                <Button type='primary' onClick={ this.onClickCreateNewTask } style={{ marginRight: 10 }}>Create New Task</Button>
               </div>
    }
    renderTimer(){
        const { modalData:{ memberId } } = this.props;
        let displayName = this.constructor.displayName;
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const keepEventListener = true;
        return <TimerContainer displayName={displayName} category='TASK_ASSIGNMENT' action='ENTER'
                               viewingDetails={viewingDetails}
                               patientId={memberId}
                               keepEventListener={keepEventListener}
        />
    }
    render(){
        const {props,state,closeModal } = this;
        const { modalData,team,taskList,loading } = props;
        const { showCreateFormOnly } = state;
        const { enrolledPrograms} = modalData||{};
        const showCreateFormOnlyFromProps = _.get(this,'props.modalData.showCreateFormOnly');
        const showCreateFormOnlyCombined = showCreateFormOnlyFromProps || showCreateFormOnly||(_.get(taskList,'length',0)==0);
        if(loading){
            return <IHLoading/>;
        }
        const teamsFromLoginUser = JSON.parse(sessionStorage.getItem('currentUser')).team;

        return <Modal visible={true} footer={null} maskClosable={false} onCancel={()=>closeModal() } destroyOnClose={true} width={'fit-content'}>
                { this.renderTimer() }
                { showCreateFormOnlyCombined ? this.getFormProps(enrolledPrograms,(!team||team.length==0) ? teamsFromLoginUser : team)  :''}
                { !showCreateFormOnlyCombined ? this.renderTable(enrolledPrograms) : ''}
                { !showCreateFormOnlyCombined ? this.renderModalFooter():'' }
               </Modal>

    }
};

const mapToProps = (state,props)=>{
    const uuid = _.get(state,'timer.main.uuid',null);
    return {
        uuid
    }
};
const mapToDispatch = (dispatch)=>{
    return {
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
};
CreateNewTaskComponent = Form.create()(CreateNewTaskComponent);
export default connect(mapToProps,mapToDispatch)(CreateNewTaskComponent);

