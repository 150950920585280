import React, {Component} from 'react';
import {connect} from "react-redux";
import '../../visitNewWorkFlow/CSS/style.scss'
import CURRENT_COMPONENT from '../../visitNewWorkFlow/constants/CurrentComponent'
import { openErrorModal } from 'layoutModule/actions/MainModal'

import InputVisitFormComponent from "../../visitNewWorkFlow/components/InputVisitFormComponent";
import SearchInputComponent from "../../visitNewWorkFlow/components/SearchInputComponent";

class CreateVisitInAppointmentsContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            value: CURRENT_COMPONENT.SEARCH_COMPONENT,
            patient: null,
            visitInfo: null
        };
        this.changeToInputVisit = this.changeToInputVisit.bind(this);
        this.createVisit = this.createVisit.bind(this);
        this.changeToSearch = this.changeToSearch.bind(this);
    }

    changeToInputVisit(patient){
        this.setState({
            value: CURRENT_COMPONENT.INPUT_VISIT_COMPONENT,
            patient
        });
    }

    changeToSearch(){
        this.setState({
            value: CURRENT_COMPONENT.SEARCH_COMPONENT,
            patient: null
        });
    }

    createVisit(variables){
        const { scheduleVisit } = this.props;
        scheduleVisit(variables);
        this.setState({
            value: CURRENT_COMPONENT.SEARCH_COMPONENT,
            patient: null,
        });
    }

    renderSelectComponent(){

        if(this.state.value === CURRENT_COMPONENT.SEARCH_COMPONENT){
            return (<SearchInputComponent selectPatient={this.changeToInputVisit}
                                          onCreate={this.changeToCreatePatient}
                                          fromAppointments={true}

            />)
        }

        if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT){
            return (<InputVisitFormComponent patient={this.state.patient}
                                             backToSearch={this.changeToSearch}
                                             createVisit={this.createVisit}
                                             editStatus={false}
            />)
        }
    }

    render() {

        return (
            <div className='newCreateVisitContainer'>
                {this.renderSelectComponent()}
            </div>
        )
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
    }
}

export default connect(null,mapToDispatch)(CreateVisitInAppointmentsContainer);

