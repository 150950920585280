import gql from 'graphql-tag';

export default gql`
query getPatientSmartAlert($memberId:EID!){
  getPatientSmartAlert(memberId:$memberId){
    id
    lastName
    firstName
    tasks {
            id
            createdAt
            taskType
            alertLevel
            status
            display
            replyTime 
            closeAt
            closeBy {
              profile {
                fullName
              }
            }  
           }
    }
}`;
