import keywords from './keywords';

const required = field => `${field} is required.`;
const requireInput = field => `Please enter your ${field}.`;
const requireSelect = field => `Please choose ${field}.`;

export default {
  email: {
    require: requireInput('email'),
    invalid: 'Your email is not valid.',
    invalid2: 'Email is not valid.',
  },

  firstName: {
    invalid: 'First Name is not valid.'
  },

  lastName: {
    invalid: 'Last Name is not valid.'
  },
  nickname: {
    invalid: 'Nickname is not valid.'
  },

  NRIC: {
    require: `Please input the ${keywords['NRIC/FIN']}.`,
    invalid: `${keywords['NRIC/FIN']} is not valid.`
  },
  username: {
    tooShort: 'Login ID needs to be at least 5 characters long.',
    require: required('username'),
    isValid:'Username is not valid'
  },

  password: {
    require: requireInput('password'),
    tooShort: 'Password should be at least 8 character.',
    combinationFail: 'Password does not meet the minimum requirements.',
  },

  passwordConfirm: {
    require: 'Please confirm your password.',
    notSame: 'Must match the previous entry.',
  },

  mobile: {
    require: 'Please enter your phone number.',
    invalid: 'Please enter a valid 10 digit mobile number.',
  },

  homePhone: {
    invalid: 'Please enter a valid 10 digit home number.'
  },
  verifyCode: {
    require: 'Please enter your verification code.',
    invalid: 'Verification code should be 6 digital',
  },

  dob: {
    invalid: 'Date of Birth cannot be a future date.',
    minimumAge: 'Age cannot be less than 1 year old.',
  },
  yearOfDiagnose: {
     invalid: 'Please enter a valid year.'
  },
  duedate: {
    invalid: 'Due date cannot be a past date.'
  },

  block: {
    invalid: 'Block is invalid'
  },

  floor: {
    invalid: 'Floor must be numeric'
  },

  unit: {
    invalid: 'Unit is invalid'
  },

  postCode: {
    length: 'Postal Code should be 5 digits.',
  },

  assessment: {
    name: {
      require: `Please input name.`,
      tooLong: 'Name should not be longer than 40 characters.',
    },
    description: {
      require: `Please input description.`,
      tooLong: 'Description should not be longer than 400 characters.',
    },
    healthCondition: {
      require: 'Please choose your health condition.',
    },
    question: {
      require: 'Please input question title.',
      tooLong: 'Question title should not be longer than 400 characters.',
    },
    option: {
      require: 'Please input option text.',
      tooLong40: 'Option text should not be longer than 40 characters.',
      tooLong400: 'Option text should not be longer than 400 characters.',
    },
  },

  patient: {
    register: {
      weight: {
        invalid: 'Weight should be between 25 and 999.'
      }
    },
    photo: {
      uploading: 'Your photo is uploading.'
    },
    glucose: {
      require: 'Glucose value is required.',
      invalid: 'Glucose value should be between 1.1 and 33.3.',
      oneDecimalPoint: 'Glucose value must have one decimal point.'
    },
    systolic: {
      require: 'Systolic is required.',
      invalid:  'Systolic should between 0 and 295.',
      wholeNumber: 'Systolic should be in whole number.',
    },
    diastolic: {
      require: 'Diastolic is required.',
      invalid:  'Diastolic should between 0 and 295.',
      wholeNumber: 'Diastolic should be in whole number.',
    },
    heartrate: {
      require: 'Heart rate is required.',
      invalid: 'Heart rate should between 30 and 250.',
      wholeNumber: 'Heart rate should be in whole number.',
    },
    weight: {
      require: 'Weight value is required.',
      invalid: 'Weight value should be between 10 and 180.',
      oneDecimalPoint: 'Weight value must have one decimal point.'
    },
    height: {
      require: 'Height is required.',
      invalid: 'Height should be between 0 and 9 feet.',
      invalid_inches: 'Height should be between 0 and 11 inches.'
    }
  },

  broadcast:{
    startDate : {
      invalid : 'Start Date Cannot be past date.',
      afterEndDate : 'Start date Cannot be after end date.'
    },
    endDate : {
      invalid : 'End Date Cannot be past date.',
      beforeStartDate : 'End date cannot be before start date.'
    }
  }

};
