import { React, IHSearchTable, BaseComponent, IHButton } from 'ihcomponent'
import UserClass from 'modulesAll/graphql/class/User'
import { goPath } from 'libModule/utils'
import { Button, Tooltip } from 'antd'

const Component = class extends BaseComponent {
    static displayName = 'superadmin/providers/providereditrole'

    render() {
        this.user = this.props.data
        this.userObject = new UserClass(this.user)
        return (
            <div className="v-provider-profileDetail">
                {this.renderBox1()}
            </div>
        )
    }

    renderBox1() {

        return (
            <div className=''>
                <div className="v-box-1">
                    <img src={this.userObject.format('avator')} />
                    <div>
                        <div className="c-n1">
                            <h2>{this.userObject._get('profile.fullName')}</h2>
                        </div>
                        <p className="c-n2">
                            <span className="c-tag">{this.userObject.format('employeeId')}    |  </span>
                            <span className="c-tag">{this.userObject.format('loginID')}    |  </span>
                            <span className="c-tag">{this.userObject.format('role')}</span>
                        </p>
                    </div>
                </div>
                <div className="vsm-main-990">
                    <div className="v-table">
                        {this.renderRolesTable()}
                    </div>
                </div>
            </div>
        )
    }
    renderRolesTable() {
        const p = this.getTablePropsForRoleList();
        return <IHSearchTable {...p} />
    }
    getTablePropsForRoleList() {
        const roles = this.userObject._get("allRoles");
        const roleArray = this.user.allRoles;
        let roleIdArray = [];
        if (roleArray && roleArray.length > 0) {
            roleArray.forEach(r => {
                roleIdArray.push(r.refId);
            });
        }
        return {
            title: `Roles`,
            dataSource: roles,
            rowKey: (doc) => {
                return doc.refId;
            },
            columns: [
                {
                    key: 'name',
                    title: 'Name',
                    dataIndex: 'name',
                    render: (t, doc) => doc.name
                },
                {
                    title: 'Organization',
                    key: 'organization',
                    render: (t, doc) => doc.organization.name
                },
                {
                    title: 'Actions',
                    key: 'Actions',
                    render: (t, doc) => {
                        const bp = {
                            size: 'exsmall',
                            bsStyle: 'primary',
                            onClick: () => {
                                this.removeRole(doc.refId);
                            },
                            className: 'table-action-button'
                        }
                        const label = '-'
                        return (
                            <IHButton {...bp}>
                                {label}
                            </IHButton>
                        )
                    }
                }
            ]
        }
    }

    removeRole(roleRefId) {
        this.props.onRemoveRole(this.user, roleRefId);
    }
}

export default Component
