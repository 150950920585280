import {Col, Row, Select} from 'antd';
import React, { Component } from 'react';
import '../CSS/style.scss';
import Client from 'libModule/gqlClient';
import userList from 'graphqlModule/patientList'
import debounce from "lodash/debounce";
import {helpers as dataHelper} from "../helper"
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
const { Option } = Select;

class SearchInputComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            data: [],
            value: undefined,
        };
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = debounce(this.handleSearch, 1000);
    }

    handleSearch(value){
        if (value) {
            const variables = {
                count: 20,
                page: 1,
                search: {
                    fields: ['NAME','IDENTIFICATION', 'PHONE', 'BIRTHDAY'],
                    match: value,
                },
                filters:{showAll:true},
                sort: { 'field': 'LAST_NAME', 'direction': 'ASC' } //{ 'field': 'BIRTHDAY', 'direction': 'DESC' }
            };
            Client.query({
                query: userList,
                variables: variables,
                fetchPolicy: 'network-only'
            })
                .then(res => {
                    this.setState({
                        data: res.data.patientList.data
                    });
                })
                .catch(error => {
                    console.log(error);
                });

        } else {
            this.setState({ data: [] });
        }
        Mixpanel.track('clicked', 'search', 'on create a visit popup', {});
    };

    handleChange(value){
        this.props.selectPatient(this.state.data[value]);
    }

    getAllOption(key, patient){

       const typeAndStatus = dataHelper.getTypeAndStatus(patient.enrolledPrograms, patient.user.profile.lastMeasuredAt);

       return(  <Option key={key}>
            <Row>
                <Col span={7} style={{textOverflow:'ellipsis', overflow:'hidden'}}>{patient.user.profile.fullName}
                </Col>
                <Col span={5}>{patient.user.profile.birthday}
                </Col>
                <Col span={3}>{patient.user.profile.gender}
                </Col>
                <Col span={5} style={{textOverflow:'ellipsis', overflow:'hidden'}}>{typeAndStatus.type}
                </Col>
                <Col span={4}>{typeAndStatus.status}
                </Col>
            </Row>
        </Option>
       )
    }

    renderSelectOption(){
        const options = [];
        for(let i = 0; i < this.state.data.length; i++){
            options.push(this.getAllOption(i, this.state.data[i]));
        }
        const {fromAppointments} = this.props;
        return (
            <Select
                showSearch
                placeholder="Search Patient By Name / MedID / DOB / Tel"
                style={{width: '100%'}}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                notFoundContent={null}
            >
                {options}
            </Select>
        )
    }

    renderBottom(){
        const text = 'Not in the system? '
        return (
            <div className="visitContentText">{text} 
                <span className='clickSpan'
                      onClick={this.props.onCreate}
                >Create a new patient profile</span>
            </div>
        )
    }

    render() {
        const {fromAppointments} = this.props;
        return(
           <div>
               {this.renderSelectOption()}
               {fromAppointments ? '' : this.renderBottom()}
            </div>
       )
    }
}

export default SearchInputComponent;
