import React from 'react';
import LabAddAndListContainer from '../container/LabAddAndListContainer';
import gql from 'graphql-tag';
import { Button } from 'antd';
import { compose, graphql } from 'react-apollo/index';
import helpers from '../helpers/';
const { leaveAndSaveWorkTask } = helpers;

const LabResInWorkListContainer = class extends React.Component {
  handleClick = () => {
    const { props } = this;
    const { toNextStep } = props;
    toNextStep('LabResults', null, 'Lab Result is Saved');
  };

  // componentWillUnmount(){
  //     const { props } = this;
  //     leaveAndSaveWorkTask('LabResults',null,props);
  // }
  handleOnClickSaveDraft = () => {
    const { saveEdit } = this;
    saveEdit(false, true, 'On Board Draft is Saved');
  };

  render() {
    const { props, handleClick, handleOnClickSaveDraft } = this;
    const { hasLabRes, isEditMode } = props;
    return (
      <div>
        <LabAddAndListContainer {...props} />
        <div style={{ float: 'right' }}>
          {isEditMode ? (
            <Button
              onClick={() => handleOnClickSaveDraft()}
              style={{ width: 130, marginRight: 10 }}
            >
              Save Draft
            </Button>
          ) : (
            ''
          )}
          <Button onClick={handleClick} type={'primary'} style={{ width: 130 }}>
            Complete
          </Button>
        </div>
      </div>
    );
  }
};
const withData = graphql(
  gql`
    query labResultList($filter: FilterFindManyLabResultsInput) {
      labResultMany(filter: $filter) {
        userId
        _id
        viewerId
        templateId
        deleted
        templateName
        dateCollected
        createdAt
        results {
          name
          unit
          value
          flag
          interval
          isInHouse
        }
      }
    }
  `,
  {
    options: ({ userId }) => ({
      variables: { filter: { userId, filterDeleted: true } },
      fetchPolicy: 'network-only'
    }),
    props: ({
      ownProps,
      data: {
        loading: labResultListLoading,
        labResultMany,
        refetch: labResultListRefetch
      }
    }) => {
      if (labResultListLoading) {
        return {
          loading: labResultListLoading
        };
      }
      return {
        hasLabRes: labResultMany.length != 0,
        labResultListLoading,
        labResultMany,
        labResultListRefetch
      };
    }
  }
);
export default compose(withData)(LabResInWorkListContainer);
