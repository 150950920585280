const alertType = { 'LEVEL_ONE':'Level One',
                    'LEVEL_TWO':'Level Two',
                    'LEVEL_THREE':'Level Three',
                    'LEVEL_FOUR':'Level Four',
                    'LEVEL_FIVE':'Level Five'
                  };

const taskType = {
                    'BG_CRITICAL_LOW':'BG Critical Low',
                    'BG_CRITICAL_HIGH':'BG Critical High',
                    'BP_CRITICAL_LOW':'BP Critical Low',
                    'BP_CRITICAL_HIGH':'BP Critical High',
                    'BG_HIGH_READING_LEVEL_TWO':'BG High Reading Level Two',
                    'BG_HIGH_READING_LEVEL_THREE':'BG High Reading Level Three'
                 };
const statusType = {
                    'TODO':'To do'
                    }
export default {
    alertType,
    taskType,
    statusType
}
