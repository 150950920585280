import gql from 'graphql-tag'
import { EnrolledProgram, EnrolledProgramForPatient } from './schema/EnrolledProgram'
import { PaginationInfo } from './schema/common'
import { UserMiniForVendor } from './schema/User'
import {Task} from './schema/Program'

export default gql`
  query enrolledProgramList(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data{
        ${EnrolledProgram}
      }
      pageInfo {
        ${PaginationInfo}
      }
    }
  }
`

export const enrolledProgramListForPatient = gql`
  query enrolledProgramList(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data{
        ${EnrolledProgramForPatient}
      }
      pageInfo {
        ${PaginationInfo}
      }
    }
  }
`

export const getUserEnrolledProgramList = gql`
  query enrolledProgramList(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data {
        id
        startDate
        name
        endDate
        status
        tasks {
          id
          type
          threshold {
            measure
            range
          }
        }
      }
    }
  }
`

export const enrolledProgramListMinimal = gql`
  query enrolledProgramList(
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      count: 99999,
      page: 1,
      filters: $filters
    ){
      data{
        id
        adminProgram {
          id
          name
        }
        status
      }
    }
  }
`


export const enrolledProgramListForPatientByVendor = gql`
  query enrolledProgramListForPatientByVendor(
    $NRIC : String!,
  ){
    enrolledProgramByPatientNRIC(
      NRIC : $NRIC,

    ){
      data{
        id
        name
        status
        startDate
        endDate
        user{
          ${UserMiniForVendor}
        }
        devices{
          address
          model
          type
          status
        }
        forms{
          type
          uri
          date
        }
        organization {
          name
          id
        }
        qrcode
        status
      }
    }
  }
`

export const lightEnrolledProgramList = gql`
  query enrolledProgramList(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data {
        id
        name
        status
        startDate
        adminProgram {
          name
          id
        }
        user {
          id
          profile {
            fullName
          }
        }
        tasks {
          ${Task}
        }
      }
      pageInfo {
        ${PaginationInfo}
      }
    }
  }
`
