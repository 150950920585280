import {
  React, IHTab, IHPanel, IHButton, BaseComponent, moment, _, message
} from 'ihcomponent';
import { Input } from 'antd';
import {createContainer, GQLHelper, TabWrapper } from 'libModule/utils';
import { setTab } from '../actions/ProgramInfoTab';
import EnrolledProgramClass from 'modulesAll/graphql/class/EnrolledProgram';
import DescArea from '../../programTab/components/DescArea';
import {modalAction} from 'modulesAll/common/actions';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import enrolledProgram from 'modulesAll/graphql/enrolledProgram';
// import { openModal, closeModal, openErrorModal } from 'layoutModule/actions/MainModal';
import { extendEnrolledProgramGQL, extendEnrolledProgramUpdateQueries } from 'modulesAll/graphql/extendEnrolledProgram';
import editEnrolledProgramGQL from 'modulesAll/graphql/mutation/editEnrolledProgram';
import resultListMinimal  from 'graphqlModule/resultList'
import Client from 'libModule/gqlClient';
import ManualInputComponent from '../components/ManualInputComponent';
import { withRouter } from 'react-router'
import { RenderTopOne } from '../../../patient/main/containers/RenderTopOne';
import { formatDateMM24Date } from "../../../utils/helpers/task";
import ProgramUpdateHistoryContainer from '../ProgramUpdateHistory/container/ProgramUpdateHistoryContainer';
import CareplanTab from 'patientModule/enrolledProgramInfo/Careplan/containers/CommonContainer';
import I18N from 'modulesAll/I18N';
import UploadConsentForm from 'modulesAll/upload/components/UploadConsentForm';
import { setExtendStep, setExtendValue, setExtendType, resetExtendValueType } from '../actions/EnrolledProgramInfoActions';
import { commonReplaceReturnDevicesFormActionsObject, commonReplaceReturnDevicesActionsObject} from '../actions/ReplaceReturnDevicesActions';
import newCarePlanActions from '../Careplan/actions/NewCarePlanActions';
import CarePlanFields from '../Careplan/components/CarePlanFields';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { Table,Checkbox,Button } from 'antd';
import { createAuditLog } from 'libModule/utils/auditLog'
import RequestCache from 'libModule/requestCache'
import ProviderDevicesList from '../containers/DevicesListContainer'
import { getPreviousKeyInDevicesInfo, populateFormData } from 'modulesAll/vendor/patient/common/utils';
import $ from 'jquery';
import ICDEditComponent from '../components/ICDEditComponent';
import HubInfoComponent from '../../AdditionalInfo/Components/HubInfoComponent';
import MainWrapper from '../../DevicesAssign/container/mainWrapper';
import { setAsyncRouteLeaveHook } from 'libModule/utils';
import {Modal} from "antd/lib/index";
import {goPath} from "../../../../lib/utils";
import ReactToPrint from 'react-to-print';
import CarePlanPrint from 'modulesAll/patient/Print/component/carePlanPrint';
import { routerWillLeaveHelper, deleteDraft } from '../helper/helpers';

const enrolledProgAPIFieldMapping = {
  careCoord: 'careCoordination',
  followUp: 'followupSchedule',
  problemList: 'problemList',
  assess: 'assessments',
  interventions: 'interventions',
  goals: 'goals',
  resource: 'resource',
  // manualInputEnable:'manualInputEnable'
};

const carePlanFieldsMap =
    {
        'careCoord':{ title:'Care Coordination',key:'careCoordination'},
        'followUp':{ title:'Follow Up', key:'followupSchedule' },
        'problemList':{ title:'Problem List',key:'problemList'},
        'assess':{ title:'Assessment',key:'assessments'},
        'interventions':{ title:'Interventions',key:'interventions'},
        'goals':{ title:'Goals', key:'goals'},
        'resource':{ title:'Other Resources',key:'resource' }
    }


const ProgramInfoTab = class extends BaseComponent {
  static displayName = 'patient/enrolledProgramInfo/containers/ProgramInfoTab'

  static propTypes = {
    setReturnDevicesStep: PropTypes.func,
    resetForm: PropTypes.func,
    setExtendStep: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleDraft = this.handleDraft.bind(this);
    this.handleInput = _.throttle(this.handleInput, 100)
    this.changeOnFirstLoadFlag = this.changeOnFirstLoadFlag.bind(this);
    this.isVitalEditBtnDisabled = this.isVitalEditBtnDisabled.bind(this);
    this.setInputFieldDisabled = this.setInputFieldDisabled.bind(this);
    this.apiEditEnrolledProgram = this.apiEditEnrolledProgram.bind(this);
    // this.updateManualInputEnabledTaskTypes = this.updateManualInputEnabledTaskTypes.bind(this);
    // this.resetManualInputTable = this.resetManualInputTable.bind(this);
    // this.enableManualInputButton = this.enableManualInputButton.bind(this);
    this.focusField = null
    this.state = {
      globalDisable: false, // controls all other input fields & btns when 1 input field is being edited
      onFirstLoad: true,
      careCoord: {
        init: '',
        isBtnDisabled: false, // controls disable of btn
        onFocus: false,
        textInput:  React.createRef(),
      },
      hubInfo:{
        disabled:false,
        isBtnDisabled:false,
        onFocus:false,
        textInput:  React.createRef(),
        value: '',
      },
      clearInfo:{
        disabled:false,
        isBtnDisabled:false,
        onFocus:false,
        textInput:  React.createRef()
      },
      loanDeviceInfo:{
        disabled:false,
        isBtnDisabled:false,
        onFocus:false,
        textInput:  React.createRef()
      },
      ICD10Code:{
        disabled:false,
        isBtnDisabled:false,
        onFocus:false,
        textInput:  React.createRef()
      },
      manualInput:{
        disabled:false,
        isBtnDisabled:false,
        onFocus:false,
        textInput:  React.createRef()
      },
      followUp: {
        init: '',
        isBtnDisabled: false,
        onFocus: false,
        textInput:  React.createRef()
      },
      problemList: {
        init: '',
        isBtnDisabled: false,
        onFocus: false,
        textInput:  React.createRef()
      },
      assess: {
        init: '',
        isBtnDisabled: false,
        onFocus: false,
        textInput:  React.createRef()
      },
      interventions: {
        init: '',
        isBtnDisabled: false,
        onFocus: false,
        textInput:  React.createRef()
      },
      goals: {
        init: '',
        isBtnDisabled: false,
        onFocus: false,
        textInput:  React.createRef()
      },
      resource: {
        init: '',
        isBtnDisabled: false,
        onFocus: false,
        textInput:  React.createRef()
      }
    }
    this.routerWillLeaveClickOK = this.routerWillLeaveClickOK.bind(this);

  }
  componentWillMount() {
    const { props } = this;
      this.props.setTab('programme');
      const {updateField,currentProgram, updateCareCoord, updateFollowUp, updateProbList, updateAssessments, updateInterventions, updateGoals, updateResource,ICD10Code } = this.props;
      const initDevices = getPreviousKeyInDevicesInfo(this.props.data);
      const localStorageKey = 'carePlanDraft' + this.props.data.id;
      const draft = localStorage.getItem(localStorageKey)
      var draftValue = null;
      const draftValueMap =
      {
          'careCoord': null,
          'followUp': null,
          'problemList':null,
          'assess': null,
          'interventions': null,
          'goals': null,
          'resource': null
      }
      if (draft){
        const draftArray=draft.split('***')
        const draftField = draftArray[0]
        draftValue = draftArray[1]
        if(draftValue) {
         const key =  carePlanFieldsMap[draftField].key;

         //update redux;
         updateField(key,draftValue);
         draftValueMap[draftField] = draftValue;
        }
      }
      this.resetDevicesTab(this.props.data.status, initDevices);
      if ( (this.props.data.status === 'STARTED' /**|| this.props.data.status === 'SUSPENDED'**/ ) && initDevices && initDevices.length)
      {
          populateFormData({onFieldsChange: this.props.onFieldsChange, isDischarged: _.get(this.programObject, 'variables.status') === 'DISCHARGED'});
      }

      this.setState(function(prevState) {
        return {
          careCoord: {
            ...prevState.careCoord,
            init: draftValueMap['careCoord'] ? draftValue : (currentProgram.careCoordination ? currentProgram.careCoordination.trim() : ''),
            disabled: currentProgram.careCoordination ? !!currentProgram.careCoordination.trim() : false, // set disable of input field
          },
          followUp: {
            ...prevState.followUp,
            init: draftValueMap['followUp'] ? draftValue : (currentProgram.followupSchedule ? currentProgram.followupSchedule.trim() : ''),
            disabled: currentProgram.followupSchedule ? !!currentProgram.followupSchedule.trim() : false,
          },
          problemList: {
            ...prevState.problemList,
            init: draftValueMap['problemList'] ? draftValue : (currentProgram.problemList ? currentProgram.problemList.trim() : ''),
            disabled: currentProgram.problemList ? !!currentProgram.problemList.trim() : false,
          },
          assess: {
            ...prevState.assess,
            init: draftValueMap['assess'] ? draftValue : (currentProgram.assessments ? currentProgram.assessments.trim() : ''),
            disabled: currentProgram.assessments ? !!currentProgram.assessments.trim() : false,
          },
          interventions: {
            ...prevState.interventions,
            init: draftValueMap['interventions'] ? draftValue : (currentProgram.interventions ? currentProgram.interventions.trim() : ''),
            disabled: currentProgram.interventions ? !!currentProgram.interventions.trim() : false,
          },
          goals: {
            ...prevState.goals,
            init: draftValueMap['goals'] ? draftValue : (currentProgram.goals ? currentProgram.goals.trim() : ''),
            disabled: currentProgram.goals ? !!currentProgram.goals.trim() : false,
          },
          resource: {
            ...prevState.resource,
            init: draftValueMap['resource'] ? draftValue : (currentProgram.resource ? currentProgram.resource.trim() : ''),
            disabled: currentProgram.resource ? !!currentProgram.resource.trim() : false,
          },
          ICD10Code:{
              ...prevState.ICD10Code,
              disabled:true
          },
          manualInput:{
              ...prevState.manualInput,
              disabled:true
          }
        }
      },() => {
          const keys = Object.keys(carePlanFieldsMap);
         _.each(keys,(k,v)=>{
           const key =  carePlanFieldsMap[k].key;
           updateField(key,currentProgram[`${key}`]);
         })
      });
       // setAsyncRouteLeaveHook(this.props.router, this.props.route, this.routerWillLeave.bind(this))

  }

  routerWillLeaveClickOK() {
    this.deleteDraft()
    this.props.setCarePlanStatus({careplanIsDisabled:false});
  }

    routerWillLeave() {
      return new Promise((resolve,reject)=> {
        if(!this.state.globalDisable||!this.props.careplanIsDisabled){
          resolve(true);
          return;
        }
         routerWillLeaveHelper(this.routerWillLeaveClickOK,resolve)
      })
    }

  componentDidMount(){
   this.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    this.focus();
  }


  handleInput(field, value) {
    const key = enrolledProgAPIFieldMapping[this.focusField];
    const hasId = _.get(this,'props.data.id');
    if (hasId&&value) {
      const tobeSave = this.focusField + '***' + value
      const localStorageKey = 'carePlanDraft' + this.props.data.id;
      localStorage.setItem(localStorageKey, tobeSave);
    }
  }

  handleDraft(field, value) {
    this.handleInput(field, value)
  }

  focus() {
    const localStorageKey = 'carePlanDraft' + this.props.data.id;
    const draft = localStorage.getItem(localStorageKey)
    if (draft){
      const draftArray=draft.split('***')
      const draftField = draftArray[0]
      const theTextArea = _.get(this.state, draftField)
      if (theTextArea && theTextArea.textInput) {
        theTextArea.disabled = false;
        // theTextArea.textInput.current.disabled = false;
        // Explicitly focus the text input using the raw DOM API // Note: we're accessing "current" to get the DOM node
        theTextArea.textInput.current.focus();
      }
    }
  }

  deleteDraft() {
    const localStorageKey = 'carePlanDraft' + this.props.data.id;
    deleteDraft(localStorageKey)
  }

  resetDevicesTab = (epStatus, initDevices) => {
    //reset

    if (initDevices && initDevices.length) {
      this.props.initDevicesStatusTableInfo(initDevices);
      this.props.setReplaceDevicesStep(2);
    } else {
      this.props.resetDevicesStatusTableInfo();
      this.props.setReplaceDevicesStep(1);
    }


    this.props.setReturnDevicesStep(1);
    this.props.resetForm();
  }
  changeOnFirstLoadFlag() {
    // Used only for vitals edit click, change onFirstLoad flag to false
    this.setState(function(prevState) {
      return {
        ...prevState,
        onFirstLoad: false
      }
    });
  }
  isVitalEditBtnDisabled() {
    return this.state.globalDisable; // vital edit disable follows global disable flag
  }

  async apiEditEnrolledProgram(field, currentValue) {
    let variables = {
      id: this.props.data.id,
      [enrolledProgAPIFieldMapping[field]]: currentValue
    };
    this.focusField = null;
    Client.mutate({
      mutation: editEnrolledProgramGQL,
      variables: variables,
      fetchPolicy: 'no-cache',
      refetchQueries:[{
        query:enrolledProgram,
        variables:{
          id:this.props.data.id
        }
      }]
    })
      .then( res => {
        message.success(I18N.get('Program.newCarePlan.save_success'));
        this.setState({ onFirstLoad: true }) // make submit btn disabled again after submit
      })
      .catch( e => this.props.openErrorModal(GQLHelper.formatError(e)))
  }

  renderDischargedDetail(){
    const { currentProgram } = this.props;
    const dischargedDetail = _.get(currentProgram,'dischargedDetail')||{};
    const isDischarged = currentProgram.status === 'DISCHARGED';
    const { description,dischargedBy,dischargedAt } = dischargedDetail;
    const fullName = _.get(dischargedBy,'profile.fullName','');
    const dischargedContent = _.isEmpty(dischargedDetail) ? '--' :
                              <div>{`${formatDateMM24Date(dischargedAt)} by ${fullName}`}
                                <p>{description}</p>
                              </div>;
    return  isDischarged ? <div style={{display:'flex'}}>
              <b style={{ fontWeight:600,paddingRight:10,color: '#474747' }}>{I18N.get('Program.programDetails.dischargeDetails')}</b>
              { dischargedContent }
            </div>:'';
  }
  render() {

    this.programInfo = this.props.data;
    const { props } = this;
    const { layoutType = 'tab' } = this.props;
    this.programObject = new EnrolledProgramClass(this.programInfo);
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')
    const patientId = _.get(currentPatient,'id');

    const itemList = [
      {
          id : 'programme',
          title : I18N.get('keywords.PROGRAM'),
          content : <TabWrapper style={{ paddingBottom: 'unset' }} component={this.renderProgramTab()} />
      },
      {
          id : 'careplan',
          title : 'CARE PLAN',
          content : <TabWrapper
            style={{ paddingBottom: 'unset' }}
            component={<CareplanTab currentProgram={this.programInfo} changeOnFirstLoadFlag={this.changeOnFirstLoadFlag}
                                    isVitalEditBtnDisabled={this.isVitalEditBtnDisabled()} patientId={patientId}/>}
          />
      },
      // {
      //   id:'manualInput',
      //   title:'Manual Input',
      //   content:<TabWrapper component={this.renderManualInput()} style={{ width:'50%'}}/>
      // },
      {
        id:'hunInfo',
        title:'HubInfo',
        content:<TabWrapper component={this.renderManualInput()} />
      },
      {
        id:'connectedDevice',
        title:'connectedDevice',
        content:<TabWrapper component={this.renderHubInfo()} style={{width:'fit-content'}} />
      },
      {
        id: 'programme2',
        title: '',
        content: <TabWrapper component={ this.renderProgramTab2() } />
      }
      ]
    const prop = {
      onSelect : (id) => {
          const tab = id.replace(/\w\S*/g, (txt)=>{return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
          createAuditLog({ action: `Select ${tab} Tab`, patientNRIC, patientName })
          this.props.setTab(id);
      },
      active : this.props.step,
      itemList,
    };
    const TabLayout = (
      <div className="v-programInfo-tab">
        <hr/>
        <p className="v-tlt">{I18N.get('keywords.Program')} Info</p>

        <div style={{margin:'0 auto'}}>

          <IHTab {...prop} />
        </div>
      </div>
    );

    const styles = {
      panel: {
        marginBottom : '20px',
        // border : '1px solid #ccc',
        borderRadius: '4px'
      },
      titleBar: {
        height: 46,
        padding: '0 12px',
      },
      main: {},

    };
      const patientProfileWidth = $('.vsm-patient-member-detail').width();
      const className = patientProfileWidth <=800 ? 'updatedTabHeader messageProgram' :'updatedTabHeader';
      const VerticalLayout = (
      <div style={{margin:'0 auto'}}>
        { itemList.map(({ id, title, content }) => <div key={id} style={styles.panel} className={className}>
            <IHPanel bodyStyle={{ border: 'unset' }} style={{ border: 'unset' }} body={content} />
          </div>
        )}
      </div>
    );
    return layoutType === 'vertical' ? VerticalLayout : TabLayout;
  }
  handleOnFocus(field) {
    this.focusField = field;

    // set global disable to true, change all other btns/inputs to disabled
    this.setState(function(prevState) {

      // if field was previously not in focus
      if (!prevState[field].onFocus) {
        const returnObj = {
          ...prevState,
          [field]: {
            ...prevState[field],
            isBtnDisabled: prevState[field].isBtnDisabled ? !prevState[field].isBtnDisabled : prevState[field].isBtnDisabled,
            onFocus: !prevState[field].onFocus
          },
          onFirstLoad: false,
          globalDisable: !prevState.globalDisable
        };

        // make all other buttons & inputs disabled, when 1 input field is being edited
        const carePlanFieldsArr = Object.keys(_.omit(this.state, 'onFirstLoad', 'globalDisable', [field] ));
        carePlanFieldsArr.forEach(val => {
          returnObj[val].isBtnDisabled = returnObj.globalDisable;
          returnObj[val].disabled = returnObj.globalDisable;
        });
        return returnObj;
      }
    },()=>{
        if(this.props.careplanIsDisabled!=this.state.globalDisable){
            this.props.setCarePlanStatus(this.state.globalDisable,field);
        }
    });

  }

  setInputFieldDisabled(field) {
    //use draft value if there is one, instead of this.props[field]
    var currentValue = this.props[field]
    const localStorageKey = 'carePlanDraft' + this.props.data.id;
    const draft = localStorage.getItem(localStorageKey)
    var draftValue = null;
    if (draft){
      const draftArray=draft.split('***')
      const draftField = draftArray[0]
      draftValue = draftArray[1]
      if(draftValue && field==draftField) {
        currentValue = draftValue;
      }
    }
    //delete draft whenever click on done
    this.deleteDraft()

    try {
        this.setState(function (prevState) {
            const returnObj = {
                ...prevState,
                [field]: {
                    ...prevState[field],
                    disabled: this.props[field] ? !prevState[field].disabled : prevState[field].disabled,
                    onFocus: !prevState[field].onFocus
                },
                onFirstLoad: prevState.onFirstLoad ? !prevState.onFirstLoad : prevState.onFirstLoad,
                globalDisable: !prevState.globalDisable
            }

            // make all other buttons enabled, or, disabled, when user clicks 'edit' or 'done'
            const carePlanFieldsArr = Object.keys(_.omit(this.state, 'onFirstLoad', 'globalDisable', [field]));

            // if other fields have no init value, then make sure input field is enabled
            carePlanFieldsArr.forEach(val => {
                if (this.props[val]) {
                    returnObj[val].isBtnDisabled = returnObj.globalDisable;
                } else {
                    returnObj[val].disabled = !prevState[val].disabled
                }
            });
            return returnObj;
        }, () => {

            // submit only if input field is disabled and not in focus after setState
            if (this.state[field].disabled && !this.state[field].onFocus) {
                this.props.restCarePlanUpdated();
                if(enrolledProgAPIFieldMapping[field]) {
                    this.apiEditEnrolledProgram(field, currentValue);
                }
            }
            //set global disabled
            if(this.props.careplanIsDisabled!=this.state.globalDisable){
                this.props.setCarePlanStatus(this.state.globalDisable);
            }
        });
    }catch(e){
      console.log(e);
    }
  }
  renderProgramTab() {
    const descProps = {
      startDate: this.props.data.startDate,
      endDate: this.props.data.endDate,
      description : this.programObject._get('description'),
      initialValue : this.programObject.format('durationProps'),
      status: _.get(this.programObject, 'variables.status'),

      onChange : (value)=>{  //value must be in format [1,'DAY'], must be 'day' type
        this.props.confirm({
          title : `Extend ${I18N.get('keywords.Program')} Duration`,
          content : `Please confirm you would like to extend duration in ${this.programObject._get('name')} by ${value[0]} days.`,
          Yes : {
            handler : async (close)=>{
              const variables = {
                id : this.programObject._get('id'),
                endDate : moment(this.programObject._get('endDate')).add(value[0], 'days').toDate()
              }
              const auditLogDetails = {
                programName: this.programObject._get('name'),
                extendBy: value,
                endDate: moment(this.programObject._get('endDate')).add(value[0], 'days').format('MMM-DD-YYYY')
              }
              const currentPatient = RequestCache.get('nurse/currentPatient')
              const patientNRIC = _.get(currentPatient, 'identification[0].value')
              const patientName = _.get(currentPatient, 'profile.fullName')

              try{

                const res = await this.props.extendEnrolledProgramGQL({variables, updateQueries : extendEnrolledProgramUpdateQueries })
                createAuditLog({
                  action: I18N.get('auditLog.provider.patient.extendProgrammeDuration'),
                  patientNRIC,
                  patientName,
                  details: auditLogDetails,
                  request: variables,
                  response: res
                })
                // console.log(res.data)
                // if(this.props.refreshProgram){
                //   _.delay(this.props.refreshProgram.bind(this), 200)
                // }
                this.props.setExtendStep(3)
                close()
              }catch(e){
                createAuditLog({
                  action: I18N.get('auditLog.provider.patient.extendProgrammeDuration'),
                  patientNRIC,
                  patientName,
                  details: auditLogDetails,
                  request: variables,
                  response: e,
                  success: false
                })
                _.delay(()=>{
                  this.props.openErrorModal(GQLHelper.formatError(e))
                }, 500)
              }finally{
                //this.props.refreshProgram()
                this.props.resetExtendValueType();// reset input number for days and drop down for week,month
              }
            }
          },
          No : {
            handler : async (close)=>{
            }
          }
        })
      }
    };

    const url = window.location.pathname;
    const team = this.programObject.format('careteams');
    const { props } = this;
    const { ICD10Code } = this.state;
    return (
      <div className="v-tab-content">
        {/*<p className="v-kv"><b>{I18N.get('keywords.Program')}</b><span>{this.programObject._get('name')}</span></p>*/}
        <DescArea ref="descarea" {...descProps} extendStep={this.props.extendStep} setExtendStep={this.props.setExtendStep} resetExtendValueType={this.props.resetExtendValueType}
          setExtendValue={this.props.setExtendValue} extendValue={this.props.extendValue}
          setExtendType={this.props.setExtendType} extendType={this.props.extendType}
          programInfo={this.props.currentProgram}
          openModal={this.props.openModal}
          closeModal = { this.props.closeModal }
          lightEnrolledProgramListRefetch = { this.props.lightEnrolledProgramListRefetch }
          dischargeEnrolledProgram = { this.props.dischargeEnrolledProgram }
          location={this.props.location}
        />
        <div className='vsm-careTeam'>
          {
            _.map(team, (t, index)=>{
              return this.getEachCareteam(t.variables, index)
            })
          }
        </div>
          {/*<ReactToPrint*/}
            {/*trigger={()=><Button>Print</Button>}*/}
            {/*content={()=>this.componentRef}*/}
          {/*/>*/}
          {/*<CarePlanPrint ref={el => (this.componentRef = el)} {...props}/>*/}
          <div>
            {this.renderDischargedDetail()}
        </div>
        <div>
            {this.renderLastUpdate()}
        </div>
        <div style={{ margin: '50px 0' }} />
          <CarePlanFields props={this.props}
                          state={this.state}
                          field='careCoord'
                          carePlanConfig ={carePlanFieldsMap}
                          handleOnFocus={ this.handleOnFocus}
                          setInputFieldDisabled = { this.setInputFieldDisabled }
                          onChange = { this.props.updateField }
                          draft = {this.handleDraft}
          />

        <div style={{ margin: '50px 0' }} />
        <CarePlanFields props={this.props}
                        state={this.state}
                        field='followUp'
                        carePlanConfig ={carePlanFieldsMap}
                        handleOnFocus={ this.handleOnFocus}
                        setInputFieldDisabled = { this.setInputFieldDisabled }
                        onChange = { this.props.updateField }
                        draft = {this.handleDraft}
        />

        {/*<div style={{ margin: '60px 0' }} />*/}
        {/*<hr className='divider' />*/}
        {/*<div style={{ margin: '50px 0' }} />*/}
        <ICDEditComponent {...props}
                          state={this.state}
                          field='ICD10Code'
                          carePlanConfig ={carePlanFieldsMap}
                          handleOnFocus={ this.handleOnFocus}
                          setInputFieldDisabled = { this.setInputFieldDisabled }
                          onChange = { this.props.updateField }
                          draft = {this.handleDraft}
        />
        {/* remove this section based on 1973*/}
        {/*<CarePlanFields props={this.props}*/}
                        {/*state={this.state}*/}
                        {/*field='problemList'*/}
                        {/*carePlanConfig ={carePlanFieldsMap}*/}
                        {/*handleOnFocus={ this.handleOnFocus}*/}
                        {/*setInputFieldDisabled = { this.setInputFieldDisabled }*/}
                        {/*onChange = { this.props.updateField }*/}
                        {/*draft = {this.handleDraft}*/}
        {/*/>*/}
        <div style={{ margin: '50px 0' }} />

        <CarePlanFields props={this.props}
                        state={this.state}
                        field='assess'
                        carePlanConfig ={carePlanFieldsMap}
                        handleOnFocus={ this.handleOnFocus}
                        setInputFieldDisabled = { this.setInputFieldDisabled }
                        onChange = { this.props.updateField }
                        draft = {this.handleDraft}
        />
        <div style={{ margin: '50px 0' }} />

        <CarePlanFields props={this.props}
                        state={this.state}
                        field='interventions'
                        carePlanConfig ={carePlanFieldsMap}
                        handleOnFocus={ this.handleOnFocus}
                        setInputFieldDisabled = { this.setInputFieldDisabled }
                        onChange = { this.props.updateField }
                        draft = {this.handleDraft}
        />

      </div>
    )
  }
  changeManualInput(value){
      const flag = value.target.value;
      let variables = {
          id: this.props.data.id,
          manualInputEnable:flag
      };
      Client.mutate({
          mutation: editEnrolledProgramGQL,
          variables: variables,
          fetchPolicy: 'no-cache'
      })
      .then( res => {
              message.success(I18N.get('Program.newCarePlan.save_success'));
              this.setState({ onFirstLoad: true }) // make submit btn disabled again after submit
      })
      .catch( e => this.props.openErrorModal(GQLHelper.formatError(e)))
  }
   /*
  updateDeviceInputEnabledTaskTypes(type,checked){
      let { deviceInputEnabledTaskTypes } = this.state;
      deviceInputEnabledTaskTypes = deviceInputEnabledTaskTypes.slice();
      if(deviceInputEnabledTaskTypes.indexOf(type)!=-1&&!checked){
          deviceInputEnabledTaskTypes = _.reject(deviceInputEnabledTaskTypes,(i)=>i==type);
      }
      else if(deviceInputEnabledTaskTypes.indexOf(type)==-1&&checked){
          deviceInputEnabledTaskTypes.push(type);
      }
      this.setState({
          deviceInputEnabledTaskTypes
      });
  }

  updateManualInputEnabledTaskTypes(type,checked){
      let { manualInputEnabledTaskTypes } = this.state;
      manualInputEnabledTaskTypes = manualInputEnabledTaskTypes.slice();
      if(manualInputEnabledTaskTypes.indexOf(type)!=-1&&!checked){
          manualInputEnabledTaskTypes = _.reject(manualInputEnabledTaskTypes,(i)=>i==type);
      }
      else if(manualInputEnabledTaskTypes.indexOf(type)==-1&&checked){
          manualInputEnabledTaskTypes.push(type);
      }
      this.setState({
          manualInputEnabledTaskTypes
      });
  }

  resetManualInputTable(){
      const { manualInputEnabledTaskTypes,deviceInputEnabledTaskTypes } = _.get(this.props,'currentProgram',{});
      this.setState({
          manualInputEnabledTaskTypes,
          deviceInputEnabledTaskTypes
      })
  }

  enableManualInputButton(){
    this.setState({
        disabledManualTableButton:false
    })
  }
  */
  renderManualInputAndHubInfo(){
    const { isInChat } = this.props;
    const flexDirection = isInChat ? 'column' :'row';
    return <div style={{display:'flex',flexDirection:flexDirection}}>
            <div style={{ flex:1 }}>
              { this.renderManualInput() }
            </div>
            <div style={{ flex:1 }}>
              { this.renderHubInfo() }
            </div>
           </div>
  }
  renderManualInput(){
    // const { disabledManualTableButton } = this.state;
    // const taskTypes = _.get(currentProgram,'tasks').map(t=>({type:t.type}));
    //
    // const columns = [
    //     {
    //       title:'',
    //       dataIndex:'type',
    //       key:'type'
    //     },
    //     {
    //       title:'Device',
    //       key:'device',
    //       render:(_,row)=><Checkbox disabled = { disabledManualTableButton } onChange={(value)=>this.updateDeviceInputEnabledTaskTypes(row.type,value.target.checked)}/>,
    //     },
    //     {
    //       title:'Manual',
    //       key:'manual',
    //       render:(_,row)=><Checkbox disabled = { disabledManualTableButton } onChange={(value)=>this.updateManualInputEnabledTaskTypes(row.type,value.target.checked)}/>
    //     },
    //     {
    //       title:''
    //     }
    // ]
    // const editButton = <Button className='ih-exsmall' style={{float:'right',top:10,right:10}}
    //                            onClick={()=>this.enableManualInputButton()}>Edit
    //                    </Button>;
    //
    // const doneButton = <Button className='ih-exsmall' style={{float:'right',top:10,right:10}}>Done</Button>;
    //
    //
    //   return (
    //     <div>
    //       <p className="care-plan-fields"><b>Measurement Input Options</b></p>
    //       <div style={{position:'absolute',zIndex:2,width:400}} className='enableButton'>
    //         { disabledManualTableButton ? editButton : doneButton }
    //       </div>
    //       <Table columns={columns} dataSource={taskTypes} style={{width:400}} pagination={false}/>
    //     </div>
    // )
    const { props } = this;
    return <ManualInputComponent {...props}
                                 state={this.state}
                                 field='manualInput'
                                 carePlanConfig ={carePlanFieldsMap}
                                 handleOnFocus={ this.handleOnFocus}
                                 setInputFieldDisabled = { this.setInputFieldDisabled }
                                 onChange = { this.props.updateField }
                                 draft = {this.handleDraft}/>
  }

  renderHubInfo(){
    const { props } = this;
    const { globalDisable } = this.state;
    return <MainWrapper {...props} globalDisable={globalDisable} handleOnFocus={this.handleOnFocus} setInputFieldDisabled={this.setInputFieldDisabled}
                        state={this.state}

    />
  }

  renderProgramTab2() {
    return (
      <div>
        <CarePlanFields props={this.props}
                        state={this.state}
                        field='goals'
                        carePlanConfig ={carePlanFieldsMap}
                        handleOnFocus={ this.handleOnFocus}
                        setInputFieldDisabled = { this.setInputFieldDisabled }
                        onChange = { this.props.updateField }
                        draft = {this.handleDraft}
        />

        <div style={{ margin: '50px 0' }} />
        <CarePlanFields props={this.props}
                        state={this.state}
                        field='resource'
                        carePlanConfig ={carePlanFieldsMap}
                        handleOnFocus={ this.handleOnFocus}
                        setInputFieldDisabled = { this.setInputFieldDisabled }
                        onChange = { this.props.updateField }
                        draft = {this.handleDraft}
        />
    </div>
    )
  }


  getEachCareteam(ct, index) {
    const members = _.map(ct.members,(m)=>{
      const { profile,id } = m.user;
      const { fullName } = profile;
      const role = _.get(m.user,'allRoles[0].name','');

      return <div key={id}>{fullName}</div>
    })

    return (
      <div className="c-e" key={index} style={{display:'flex'}}>
        <div>
        <b>{I18N.get('Program.programDetails.careTeam')}</b>
        </div>
        <div>
            {members}
        </div>
      </div>
    )
  }

  renderUpdateLogModal(){

  }

  renderUpdateLog(){
    const enrolledProgramId = _.get(this.props,'data.id');
    const props = {
          showModal: true,
          showHeaderCloseButton: true,
          className: 'programUpdateContainer',
          size: 'md'
    }
    return <div onClick={()=>this.props.openModal(<ProgramUpdateHistoryContainer enrolledProgramId={enrolledProgramId} />,props)}>
        <a style={{textDecoration:'underline',paddingLeft:10,fontWeight:900}}>Update History</a>
    </div>
  }

  renderLastUpdate(){
     const { updatedAt } =  this.props.data;
     const header = I18N.get('Program.programDetails.lastUpdated');
     const timeStamp = formatDateMM24Date(updatedAt);
     return <div style={{display:'flex'}}>
              <b style={{ fontWeight:600,paddingRight:10,color: '#474747' }}>{header}</b>
              <div>{timeStamp}</div>
              {this.renderUpdateLog()}
            </div>
  }


};

const wrapperGQL = graphql(extendEnrolledProgramGQL, {
  name : 'extendEnrolledProgramGQL'
});

const mapState = (state)=>{
  return {
    ...state.patient.enrolledProgramInfo.ProgramInfoTab,
    extendStep: state.patient.enrolledProgramInfo.EnrolledProgramInfo.extendStep,
    extendValue: state.patient.enrolledProgramInfo.EnrolledProgramInfo.extendValue,
    extendType: state.patient.enrolledProgramInfo.EnrolledProgramInfo.extendType,
    careCoord: state.patient.enrolledProgramInfo.Careplan.NewCarePlan.careCoordination,
    followUp: state.patient.enrolledProgramInfo.Careplan.NewCarePlan.followupSchedule,
    problemList: state.patient.enrolledProgramInfo.Careplan.NewCarePlan.problemList,
    assess: state.patient.enrolledProgramInfo.Careplan.NewCarePlan.assessments,
    interventions: state.patient.enrolledProgramInfo.Careplan.NewCarePlan.interventions,
    goals: state.patient.enrolledProgramInfo.Careplan.NewCarePlan.goals,
    resource: state.patient.enrolledProgramInfo.Careplan.NewCarePlan.resource,
    isVitalsEditMode: state.patient.enrolledProgramInfo.Careplan.VitalForm.isEditMode,
    carePlanUpdated:state.patient.enrolledProgramInfo.Careplan.NewCarePlan.carePlanUpdated
  }
};
const mapDispatch = (dispatch) => {
  return {
    setExtendStep,
    setExtendValue,
    setExtendType,
    openErrorModal: err => dispatch(openErrorModal(err)),
    resetExtendValueType,
    // confirm: (opts)=> (dispatch) => {
    //   dispatch(modalAction.confirm(opts))
    // },
    setTab,

    /** for replace return devices only start **/
    onFieldsChange: commonReplaceReturnDevicesFormActionsObject.onFieldsChange,
    resetForm: commonReplaceReturnDevicesFormActionsObject.resetForm,
    initDevicesStatusTableInfo: commonReplaceReturnDevicesActionsObject.initDevicesStatusTableInfo,
    setReplaceDevicesStep: commonReplaceReturnDevicesActionsObject.setReplaceDevicesStep,
    setReturnDevicesStep: commonReplaceReturnDevicesActionsObject.setReturnDevicesStep,
    resetDevicesStatusTableInfo: commonReplaceReturnDevicesActionsObject.resetDevicesStatusTableInfo,
    updateField: (field,str,isUpdated) => dispatch(newCarePlanActions.updateField(field,str,isUpdated)),
    restCarePlanUpdated:()=>dispatch(newCarePlanActions.restCarePlanUpdated())

  }
}
export default compose(connect(mapState,mapDispatch))(createContainer(wrapperGQL(withRouter(ProgramInfoTab))))
