import type from '../constants'
import {patientDetail} from "../../fake/patients";

export default {
  addMsg: (channel, msgObj) => {
    return {
      type: type.ADD_MESSAGE,
      payload: {
        channel,
        msgObj
      }
    }
  },
  initUnreadCounter:(channel,unreadMsgCount)=>{
      return {
        type: type.UPDATE_UNREAD_COUNTER,
        payload:{
            channel,
            unreadMsgCount
        }
    }
  },
  updateUnreadCounter:(channel)=>{
        return {
            type: type.UPDATE_UNREAD_COUNTER,
            payload:{
                channel,
            }
        }
    },
  setCurrentUserId: userId => {
    return {
      type: type.SET_CURRENT_USERID,
      payload: userId
    }
  },
  updateChannelInfo: (channel,msgObj,isbatch) =>{
    // console.log('update channel info');
    return {
      type:type.UPDATE_CHANNEL_INFO,
      payload:{
        channel,
        msgObj,
        isbatch
      }
    }
  },
  addChatHistory: (channel, patientId, messages, timestamp,messageCount,isbatch,toBottom) => {
    // console.log('add chat history');
    return {
      type: type.ADD_HISTORY,
      payload: {
        channel,
        patientId,
        messages,
        timestamp,
        messageCount,
        isbatch,
        toBottom
      }
    }
  },
  clearHistory: () => {
    return {
      type: type.CLEAR_HISTORY,
      payload: {
        messages: []
      }
    }
  },
  setSelectedChannel:(channel) => {
    return {
      type:type.SELECT_CHANNEL,
      payload:channel.trim()
    }
  },
  verifyAndAddChannel:(channelName,patientId)=>{
     return {
       type:type.VERIFY_AND_ADD_CHANNEL,
       payload:{
         channelName,
         patientId
       }
     }
    } ,
  addChannels: channelsObj => {
    return {
      type: type.ADD_CHANNELS,
      payload: channelsObj
    }
  },
  addToUserMap: (newUser,avatarError) => {
    return {
      type: type.ADD_USER_MAP,
      payload: {
        newUser,
        avatarError
      }
    }
  },
  setUserMap: userMap => {
    return {
      type: type.SET_USER_MAP,
      payload: userMap
    }
  },
  updatePNStatus: status => {
    return {
      type: type.UPDATE_PN_STATUS,
      payload: status
    }
  },
  setOrgTeamMap: map => {
    return {
      type: type.SET_ORG_TEAM_MAP,
      payload: map
    }
  },
  setPatientDivHeight: height =>{
    return {
      type: type.SET_PROFILE_DIV_HEIGHT,
      payload:height
    }
  },
  moveToBottom: (val)=>{
    return {
      type:type.SET_TO_BOTTOM,
      payload:val
    }
  },
  setIsFullyLoaded:(flag)=> {
    return {
        type: type.SET_IS_FULLY_LOADED,
        payload: flag
    }
    }
  };
