import {Modal} from "antd/lib/index";

export const routerWillLeaveHelper = function unload(fn, resolve,type='care plan') {
    Modal.confirm({
        title: `You have unsaved changes on this ${type}, are you sure you want to continue without saving?`,
        onOk: () => {
            fn.apply()
            if(resolve) {
                resolve(true)
            }
        },
        iconType:'warning',
        okText: `Yes,leave without saving.`,
        onCancel:()=>{
            if(resolve) {
               resolve(false);
            }
        },
        cancelText: `Cancel`,
    })
}

export const routerWillLeaveHelperWithOneButton = function unload(fn, resolve) {
    Modal.confirm({
        title: 'You are connecting on the phone',
        onOk: () => {
            if(resolve) {
                resolve(false)
            }
        },
        okText: `Ok`,
        onCancel:()=>{
            if(resolve) {
               resolve(false);
            }
        },
        cancelText: `Cancel`,
    })
}

export const deleteDraft = function deleteDraft(localStorageKey) {
    const draft = localStorage.getItem(localStorageKey)
    if (draft){
      localStorage.removeItem(localStorageKey);
    }
}
