import gql from 'graphql-tag'


const timeSpentGQL =  gql`query getMonthlyTimeSpent($userId:EID!,$monthOfYear:Int!) {
                                      getMonthlyTimeSpent(
                                        memberId:$userId,
                                        monthOfYear:$monthOfYear
                                      ) {
                                        totalTime
                                        monthOfYear
                                        updatedAt
                                      }
                                    }`


const timeSpentListGQL = gql`query monthlyTimeSpentList($memberId:EID,$count:Int,$page:Int) {
  monthlyTimeSpentList(filters: {memberId:$memberId},count:$count,page:$page){
   data{
    totalTime
    monthOfYear
    updatedAt
  }
  pageInfo{
    total
    lastPage
  }
  }
}`

const getMonthlyTimeSpent = gql`query getMonthlyTimeSpent($memberId:EID!,$monthOfYear:Int!){
  getMonthlyTimeSpent(memberId:$memberId,monthOfYear:$monthOfYear){
    totalTime
    monthOfYear
  }
}`;
export {
    timeSpentGQL,
    timeSpentListGQL,
    getMonthlyTimeSpent
}
