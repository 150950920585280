import React from 'react';
import { IHButton, _ } from 'ihcomponent'
import { compose } from 'react-apollo'
import { connect } from 'react-redux'
import { openModal,closeModal } from 'layoutModule/actions/MainModal'
import createTableAction from 'libModule/table/TableActions';
import {graphql} from "react-apollo/index";
import MedicineListComponent from '../components/MedicineListComponent'
import medicationList  from  'modulesAll/graphql/medicationList';
import actions from "../../medicine/actions";
import getVariables  from '../query/variables';
const medicinelistActions = createTableAction('medicine_list');

const MedicineContainer = class extends React.Component {
    static  displayName = 'FoodLogContainer';

    constructor(){
        super();
        this.state = {
            showMedModal:false,
            showHistoryModal: false
        }
    }

    setShowMedModal = (flag)=>{
        this.setState({
            showMedModal:flag
        })
    }

    setShowHistoryModal = (flag)=>{
        this.setState({
            showHistoryModal:flag
        })
    }

    render() {
        const { props } = this ;
        const { showMedModal,showHistoryModal } = this.state;
        return (
            <div className=" ">
                <div className="PatientMedicationTable">
                    <div className="v-table">
                        <MedicineListComponent {...props} setShowHistoryModal={this.setShowHistoryModal} showMedModal={showMedModal} showHistoryModal={showHistoryModal} setShowMedModal={ this.setShowMedModal}/>
                    </div>
                </div>
            </div>)
       }

    };

const withData = graphql(medicationList, {
    options: (ownProps) => {
        const variables = getVariables(ownProps);
        return {
            variables,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ownProps, data}) => {
        const { loading,medicationList } = data;
        if(medicationList&&medicationList.data) {
            return {
                medicationList,
                uniqueMedNames:_.map(_.uniqBy(medicationList.data,'name'),m=>m.name) ,
                loading,
            }
        }

        return {
            loading
        }
    }
})

const mapToProps = (state,props)=>{

    return {
        ...medicinelistActions,
        ...state.globalTables.medicine_list,
        modal:state.MainModal,
        status:state.medicineList.main.status
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openModal: (content, row) => dispatch(openModal(content, row)),
        closeModal:()=>dispatch(closeModal()),
    }
}

MedicineContainer.displayName = 'MedicineContainer';
export default compose(connect(mapToProps,mapToDispatch),withData)(MedicineContainer);
