
import {React, BaseComponent, _} from 'ihcomponent'
import I18N from 'modulesAll/I18N'
import { isNumeric, isPendingNumeric } from 'libModule/utils'
import { convertBG_mmolTomgdl } from 'libModule/utils/convertUnits';

const ERROR = I18N.get('ThresholdInput')
import { RequestCache } from 'libModule/utils'
const renderNumberOrNA = (num) => {
    return _.isNaN(Number(num)) || _.isNaN(num) ? "NA" : num
}
const isTextNaN=v=>isNaN(Number(v));
const Component = class extends BaseComponent{
  static displayName = 'ThresholdInput'

  init(){
    const { value } = this.props
    const convertedValues = value.map( val => {
      if (this.props.vital_type === 'BG' && val) {
        return this.determineBGUnit(val);
      }
      return val;
    });
    const currentPatient = RequestCache.get('nurse/currentPatient');
    const baselineValue = _.get(currentPatient, 'profile.weight.value');
    this.type = this.props.vital_type || this.props.type || 'BP';
    if(this.type === 'HS'){
        this.state = {
          clow : convertedValues[0] || '',
          low : convertedValues[1] || '-3',
          high : convertedValues[2] || '3',
          chigh : convertedValues[3] || '',

          cl_show : isNumeric(convertedValues[0]),
          ch_show : isNumeric(convertedValues[3]),

          hs_base : convertedValues[4] || baselineValue,

          error : []
        }
    } else {
      this.state = {
        clow : convertedValues[0] || '',
        low : convertedValues[1] || '',
        high : convertedValues[2] || '',
        chigh : convertedValues[3] || '',
        cl_show : isNumeric(convertedValues[0]),
        ch_show : isNumeric(convertedValues[3]),
        hs_base : convertedValues[4] || baselineValue,
        error : []
      }
    }



  }
  determineBGUnit(val) {
    if (val >= 40) { // if greater than 40, then it is mg/dl
      return val;
    }
    return convertBG_mmolTomgdl(val);
  }
  componentWillMount() {
    const createChangeListener = (key)=>{
      let lastV=this.state[key];
      const isValid=['BP', 'HR'].includes(this.type) ? (v)=>/^\d*$/.test(v):isPendingNumeric;
      return e => {
        let v = String(e.target.value)
        if (!isValid(v)) {
            v = v.replace(/[^\d.-]/g, '');
            e.target.value = lastV;
            return;
        };
        lastV=v;
        this.setState({ [key] : v }, this.handleOnChange)
      };
    };
    this.clowListener = createChangeListener('clow')
    this.lowListener = createChangeListener('low')
    this.highListener = createChangeListener('high')
    this.chighListener = createChangeListener('chigh')
    this.hs_baseListener = createChangeListener('hs_base')
  }

  defineBaseClassName(){
    return 'vsm-common-threshold-input'
  }
  setValue(map){
    this.setState(map, this.handleOnChange)
  }

  render(){
    const boxProps = this.getClassAndStyleProp()
    const errors = this.props.isShowError ? (
      <div className="v-error">
        {_.map(this.state.error, (err, index)=><p key={index}>{err}</p>)}
      </div>
    ) : ''
    return (
      <div {...boxProps}>
        <div className="threshold-input-wrapper">
          <div className="threshold-input-label">Ranges are inclusive</div>
        {this.renderCriticallyLow()}
        {this.renderLow()}
        {this.renderNormal()}
        {this.renderHigh()}
        {this.renderCriticallyHigh()}
        {errors}
        </div>
      </div>
    )
  }
  renderCriticallyLow(){
    if(!this.state.cl_show){
      return (
        <div className="v-b v-cl-box">
          {this.getBoxTitle('CL', 'c-1')}
          <span onClick={(()=>{this.setState({cl_show:true})}).bind(this)} className="v-add">{`+Add`}</span>
        </div>
      )
    }

    return (
      <div className="v-b v-cl-box v-ex">
        {this.getBoxTitle('Critically Low', 'c-1')}

        <div className="v-mm">
          <i className="v-icon v-icon-2" />
          <input onChange={this.clowListener} defaultValue={this.state.clow + ''} type="text" />
        </div>

        <span onClick={(()=>{this.setValue({cl_show:false, clow: ''})}).bind(this)} className="v-add v-rm">{`-Remove`}</span>
      </div>
    )
  }
  renderLowForHS(){
    const tlt = this.getBoxTitle('Low', 'c-2')
    return (
      <div className={`v-b v-l-box ${!this.state.cl_show && 'v-ex'}`}>
        {tlt}
        <div className="v-mm">
          <input onChange={this.lowListener} defaultValue={this.state.low  + ''} type="text" />
        </div>
      </div>
    )
  }
  renderHighForHS(){
    const tlt = this.getBoxTitle('High', 'c-2')
    return (
      <div className={`v-b v-h-box ${!this.state.ch_show && 'v-ex'}`}>
        {tlt}

        <div className="v-mm">
          <input onChange={this.highListener} defaultValue={this.state.high + ''} type="text" />
        </div>
      </div>
    )
  }
  renderLow(){
    if(this.type === 'HS'){
      return this.renderLowForHS()
    }
    const tlt = this.getBoxTitle('Low', 'c-2')
    const mn = ERROR[this.type].mn
    const fx = ERROR[this.type].floatFixed
    const lowRange = {
      lowerBound: this.validateNumber(this.state.clow, '') ? parseFloat(this.state.clow).toFixed(fx) : (parseFloat(this.state.clow)+mn).toFixed(fx),
      upperBound: this.validateNumber(this.state.low, '') ? parseFloat(this.state.low).toFixed(fx) : (parseFloat(this.state.low)-mn).toFixed(fx)
    }
    const normalRange = {
      lowerBound: parseFloat(this.state.low).toFixed(fx)
    }
    const criticalLow = this.state.clow

    let num
    if (normalRange.lowerBound && criticalLow) {
      const min_gap_from_low = normalRange.lowerBound - ERROR[this.type].critical_min_gap
      const diff = parseFloat(min_gap_from_low - criticalLow).toFixed(fx)
      num = (diff <= 0 || _.isNaN(Number(diff))) ? num = "NA" :
            diff === mn ? num = `${lowRange.lowerBound}` : `${lowRange.lowerBound} - ${lowRange.upperBound}`
    }
    else if (normalRange.lowerBound) {
      if(isTextNaN(normalRange.lowerBound)){
        num='NA';
      }else{
        num = `<font style="font-family:'Arial'">&#x2264;</font> ${renderNumberOrNA(lowRange.upperBound)}`
      }
    }
    else {
      num = ''
    }

    return (
      <div className={`v-b v-l-box ${!this.state.cl_show && 'v-ex'}`}>
        {tlt}

        <div className="v-mm">
          <span dangerouslySetInnerHTML={{__html:num}}></span>
        </div>
      </div>
    )
  }
  renderNormal(){
    if(this.type === 'HS'){
      if(this.props.hs_type){
        const defaultVal = this.state.hs_base === 'undefined' ? '' : this.state.hs_base;
        return (
          <div className="v-b v-n-box">
            {this.getBoxTitle('Weight', 'c-3')}
            <div className="v-mm">
              <input onChange={this.hs_baseListener} type="text" defaultValue={defaultVal} />
            </div>
          </div>
        )
      }

      return (
        <div className="v-b v-n-box">
          {this.getBoxTitle('Weight', 'c-3 c-3h')}

        </div>
      )
    }
    return (
      <div className="v-b v-n-box">
        {this.getBoxTitle('Normal', 'c-3')}

        <div className="v-mm">
          <input onChange={this.lowListener} type="text" defaultValue={this.state.low + ''} />
          <font>to</font>
          <input onChange={this.highListener} type="text" defaultValue={this.state.high + ''} />
        </div>

      </div>
    )
  }
  renderHigh(){
    if(this.type === 'HS'){
      return this.renderHighForHS()
    }
    const tlt = this.getBoxTitle('High', 'c-2')

    const mn = ERROR[this.type].mn
    const fx = ERROR[this.type].floatFixed
    const highRange = {
      lowerBound: this.validateNumber(this.state.high, '') ? parseFloat(this.state.high).toFixed(fx) : (parseFloat(this.state.high)+mn).toFixed(fx),
      upperBound: this.validateNumber(this.state.chigh, '') ? parseFloat(this.state.chigh).toFixed(fx) : (parseFloat(this.state.chigh)-mn).toFixed(fx)
    }

    const normalRange = {
      upperBound: parseFloat(this.state.high).toFixed(fx)
    }

    const criticalHigh = this.state.chigh

    let num
    if (normalRange.upperBound && criticalHigh) {
      const min_gap_from_high = parseFloat(normalRange.upperBound) + ERROR[this.type].critical_min_gap
      const diff = parseFloat((criticalHigh - min_gap_from_high).toFixed(fx))
      num = (diff <= 0 || _.isNaN(Number(diff))) ? num = "NA" :
            diff === mn ? num = `${highRange.lowerBound}` : `${highRange.lowerBound} - ${highRange.upperBound}`
    }
    else if (normalRange.upperBound) {
      if(isTextNaN(normalRange.upperBound)){
        num='NA';
      }else{
        num = `<font style="font-family:'Arial'">≥</font> ${renderNumberOrNA(highRange.lowerBound)}`
      }
    }
    else {
      num = ''
    }

    return (
      <div className={`v-b v-h-box ${!this.state.ch_show && 'v-ex'}`}>
        {tlt}

        <div className="v-mm">
          <span dangerouslySetInnerHTML={{__html:num}}></span>
        </div>
      </div>
    )
  }
  renderCriticallyHigh(){
    if(!this.state.ch_show){
      return (
        <div className="v-b v-ch-box">
          {this.getBoxTitle('CH', 'c-1')}

          <span onClick={(()=>{this.setState({ch_show:true})}).bind(this)} className="v-add">{`+Add`}</span>
        </div>
      )
    }

    return (
      <div className="v-b v-ch-box v-ex">
        {this.getBoxTitle('Critically High', 'c-1')}

        <div className="v-mm">
          <i className="v-icon v-icon-1" />
          <input onChange={this.chighListener} defaultValue={this.state.chigh + ''} type="text" />
        </div>

        <span onClick={(()=>{this.setValue({ch_show:false, chigh: ''})}).bind(this)} className="v-add v-rm">{`-Remove`}</span>
      </div>
    )
  }

  getBoxTitle = (title, className) => {
    return <div className={`v-tlt ${className}`}>{title}</div>
  }

  validateNumber(v, label){
    if(v && !/^(-)?(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(v)){
      return `${ERROR[this.type][label]}`
    }

    return null
  }

  validate(){
    const {clow, low, high, chigh} = this.state
    let error = []

    if(!low || !high){
      console.log(low, high);
      error.push(ERROR[this.type].normal_require)

      return this.setState({error})
    }

    _.each([[clow, 'Critically Low'], [low, 'Low'], [high, 'High'], [chigh, 'Critically High']], (n)=>{
      const se = this.validateNumber(n[0], n[1])

      if(se){
        error.push(se)
        //return false
      }
    })

    const a = parseFloat(clow),
      b = parseFloat(low),
      c = parseFloat(high),
      d = parseFloat(chigh)
    if(b >= c){
      error.push(ERROR[this.type].normal)
    }
    else if((clow && a>=b) || b >= c || (chigh && c >= d)){
      error = ['The values on the left should be lower than the values on the right']//ERROR[this.type].all
    }


    this.setState({error})
  }

  handleOnChange({clow, low, high, chigh, hs_base} = this.state){
    // this 500 value need to be bigger than the validation time
    // the performance is not very good in IE
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.validate()
      // if(_.size(this.state.error) < 1){
      const range = [
        clow?parseFloat(clow):null,
        parseFloat(low),
        parseFloat(high),
        chigh?parseFloat(chigh):null
      ]
      if(this.type === 'HS' && this.props.hs_type){
        range.push(hs_base?parseFloat(hs_base):null)
      }
        this.props.onChange && this.props.onChange(range, this.state.error)
    }, 300)
  }
}

export default Component