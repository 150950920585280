let moment = require('moment');

const todaysDate = moment(new Date());

const TODAY = todaysDate.clone().startOf('day');
const YESTERDAY = todaysDate.clone().subtract(1, 'days').startOf('day');
const A_WEEK_OLD = todaysDate.clone().subtract(7, 'days').startOf('day');

const isToday = momentDate => {
  return momentDate.isSame(TODAY, 'd');
};

const isYesterday = momentDate => {
  return momentDate.isSame(YESTERDAY, 'd');
};

const isWithinAWeek = momentDate => {
  return momentDate.isAfter(A_WEEK_OLD);
};

const formattedDate = (momentDate,formatString)=>{
  if( typeof formatString!='string'){
    return new error('format string invalid');
  }
  if( momentDate instanceof  moment){
        return momentDate.format(formatString);
    }
  if(  (typeof momentDate)=='number'){

      return moment(momentDate).format(formatString)
    }


}
//trim all variables in nested object, not working for values in array;
const trimAllValues = (variable,path,src)=>{
    if(typeof src=='string'){
        _.set(variable,path,src.trim());
    }
    else{
        if(!_.isEmpty(src)){
            const keys = Object.keys(src);
            if(path!=''){
                keys.map(key=>trimAllValues(variable,`${path}.${key}`,src[key]))
            }
            else{
                keys.map(key=>trimAllValues(variable,`${key}`,src[key]))
            }
        }
    }
    return variable;
}

export const DATE_HELPERS = {
  isToday,
  isYesterday,
  isWithinAWeek,
  formattedDate,
  trimAllValues
}
