// import * as types from '../constants/ProviderAlertListConstants'
// import { commonActions } from 'libModule/helpers/action-helpers'
import React from 'react';
import { openModal } from 'layoutModule/actions/MainModal'

// files
import InterventionNotes from 'modulesAll/alerts/InterventionNotes/containers/InterventionNotes'

export const onAddClick = (row) => {
  const props = {
    showModal: true,
    showHeaderCloseButton: true,
    // title: `Reading on ${moment(row.date).format("MMM-DD-YYYY")}`,
    className: 'vsm-provider-modal',
    size: 'lg'
  }
  return openModal(<InterventionNotes row={row} noteType='assessment_note' />, props)
}
