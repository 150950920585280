/**
 * Created by zizhangai on 1/2/17.
 */
import gql from 'graphql-tag'
import update from 'immutability-helper'
import _ from 'lodash'
import UserClass from 'graphqlModule/class/User'
import Client from 'libModule/gqlClient';
import resultListMinimal  from 'graphqlModule/resultList'


export default gql`
  mutation handleAlert($id: EID!) {
    handleAlert(id: $id) {
      id,
      careStatus,
      caredBy {
        id
        profile {
          fullName
        }
      }
    }
  }
`
export const updateQueries =
  // result: (prev, { mutationResult: { data } }) => {
  //   if (prev.result.id !== data.intervene.id) {
  //     return prev
  //   }
  //   const newResult = data.intervene.notes[0]
  //   return update(prev, {
  //     result: {
  //       notes: {
  //         $unshift: [newResult]
  //       }
  //     }
  //   })
  // },
  (prev) => {
    if (Object.keys(prev).length === 0 && prev.constructor === Object) {
        // console.log(prev);
      return prev
    }
    // const isCloseAlert = data.closeAlert;
    // const { id, careStatus, caredBy } = data.handleAlert || data.closeAlert
    // let total = _.get(prev,'resultList.pageInfo.total',0);
    const variables = {
        count: 1,
        filters: {
            get enrolledProgramId() {
                return UserClass.getEnrolledProgramActiveIdList()
            },
            severity: ['MISSED', 'RISK', 'CRITICAL'],
            careStatus: "NEW",
            alertsOnly: true
        },
    }

    let data  = prev.readQuery({ query:resultListMinimal,variables:variables });
    let resData = data.resultList.data;
    let total = data.resultList.pageInfo.total-1;
    let lastPage = data.resultList.pageInfo.lastPage;
    // console.log('before update',data);
    prev.writeQuery({
          query:resultListMinimal,
          data: {
              resultList:{
                  data:resData,
                  pageInfo:{
                      total:total,
                      lastPage:lastPage,
                      __typename:"PaginationInfo"
                  },
                  __typename:"PaginatedResults"
              }
          },
          variables:variables,
      });
    // console.log(prev.readQuery(
    //     { query:resultListMinimal,variables:variables }
    // ));
      // const newList =
    //     isCloseAlert ? _.filter((_.get(prev, 'resultList.data') || []),(item)=>{
    //         return item.id!=id;
    //         })
    //         :
    //     (_.get(prev, 'resultList.data') || []).map(item => {
    //   if (item.id !== id) {
    //     return item
    //   }
    //   else {
    //     return update(item, {
    //       careStatus: {
    //         $set: careStatus
    //       },
    //       caredBy: {
    //         $set: caredBy
    //       }
    //     })
    //   }
    // })
    // console.log(prev);
    // return update(prev, {
    //   resultList: {
    //     data: {
    //       $set: newList
    //     }
    //   }
    // })
  }

