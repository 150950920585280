import PropTypes from 'prop-types';
import { React, IHSearchTable, IHButton, _,IHLoading } from 'ihcomponent'
import {  formatDate } from 'libModule/helpers/component-helpers'
import { formatReadingFromRow, hasMeasurement, hasMissedAlert } from 'modulesAll/utils/helpers/task';
import { formatDateMM24Date } from 'modulesAll/utils/helpers/task';
import I18N from 'modulesAll/I18N';
import { durationMap } from '../../../alerts/InterventionNotes/constants/map';
import moment from 'moment';
import { connect } from 'react-redux';
import editProviderNote from 'graphqlModule/mutation/editProviderNote.js';
import providerNoteList from 'graphqlModule/providerNoteList'
import Client from 'libModule/gqlClient';
import { compose } from 'react-apollo';
import CreateNote from '../../../alerts/InterventionNotes/containers/AddInterventionNoteFormComponentNoReading'
import { Modal } from 'antd';
import '../../style/index.scss';

const mapDispatch = (dispatch, props) => {
    return {
        deleteProviderNote: (id,category) =>{
            const refetchVariable = (() => {
                const variables = {
                    'filters': {
                        'enrolledProgramId': props.currentProgram.id,
                        ...props.filter,
                    },
                    page: _.get(props, 'page.current', 1),
                    count: 10,
                    sort: {'field': 'CREATED_AT', 'direction': 'DESC'}
                }
                const sortField = _.get(props, 'sort.fieldGraph', null);
                if (sortField) {
                    variables.sort = {
                        field: sortField,
                        direction: _.get(props, 'sort.direction', 'DESC'),
                    };
                }
                return variables;
            })();

            return Client.mutate({
                    mutation: editProviderNote,
                    variables: {
                        id,
                        category,
                        deleted: true
                    },
                    refetchQueries: [{
                        query: providerNoteList,
                        variables: refetchVariable,
                        notifyOnNetworkStatusChange: true,
                        fetchPolicy: 'network-only',
                    }]
                })

        }
    }
}

export default compose(connect(null,mapDispatch))(class extends React.Component {
  static displayName = 'allNotes/components/ListComponent'

  static propTypes = {
      onTableChange: PropTypes.func,
      onTableSearch: PropTypes.func,
      onLoadParams: PropTypes.func,
      openAddModal: PropTypes.func,
      onRowClick: PropTypes.func,
      data: PropTypes.object,
      sort: PropTypes.object,
      search: PropTypes.string,
      page: PropTypes.object
  }
  constructor(){
      super();
      this.state = {
          showModal: false
      }
  }
  componentWillMount() {
    this.props.onLoadParams(null, true);
  }

  // renderModal() {
  //     const ownProps = this.props;
  //     const { showModal } = this.state;
  //
  //     return <Modal visible={showModal}
  //                   className='AddNoteModal'
  //                   onCancel={()=>this.setState({ showModal: false}) }
  //                   footer={null}
  //           >
  //
  //                  <CreateNote noteType='provider_note' patientId={ownProps.patientId}
  //                         programId={ownProps.currentProgram.id}
  //                         programName={ownProps.currentProgram.name} canRestUUid={ownProps.canRestUUid}
  //                         closeModal = { ()=>this.setState({showModal:false} )}
  //                  />
  //            </Modal>
  // }
  renderPauseFollowUp(row){
    const { content,category,tags,resumeDate,pauseDuration } = row;
    const durationInDays = _.find(durationMap,(i)=>i.value==pauseDuration);
    const durationValue = _.get(durationInDays,'name','unknown');

    return <div>
            <div className="intervention-notes-form-header" >
                <span style={{ fontWeight:'bold' }}>Reason: </span>{I18N.get(`providerNoteTags.${tags[0]}`)}
            </div>
            <div className="intervention-notes-form-header" >
                <span style={{ fontWeight:'bold' }}>Duration: </span>{durationValue}
            </div>
            <div className="intervention-notes-form-header" >
                <span style={{ fontWeight:'bold' }}>Est. Return Date: </span>{moment(resumeDate).format('MM/DD/YYYY')}
            </div>
            <div className="intervention-notes-form-header" >
                <span style={{ fontWeight:'bold' }}>Details: </span>{content}
            </div>
           </div>
  }

  renderContent(row){
      const { category,content } = row;
      const contentMap = {
          'PAUSE_FOLLOW_UP':this.renderPauseFollowUp(row)
      };
      const defaultContent =  (<div style={{whiteSpace: 'pre-wrap'}}>{content}</div>);
      return _.get(contentMap,category,defaultContent);
  }

  renderCategory(title, row){
      const { smartAlertLevel, smartAlertType } = row;
      if(smartAlertLevel) {
          return `${title} - ${smartAlertLevel}\n${smartAlertType}`;
      } else {
          return title;
      }
  }


  getTableProps() {
    const { onTableChange, onTableSearch, data, sort, page, search = '' , openAddModal, onRowClick, handlePNModal } = this.props;
    const { loading = true, providerNoteList } = data;

    const b = {
      type: 'primary',
      label: 'Add Note',
      style: {
        position: 'absolute',
        top: '8px',
        right: '12px',
        cursor: 'pointer'
      },
      onClick:()=>handlePNModal(true),
      size: 'small'
    }

    const notes = JSON.parse(sessionStorage.getItem('presetNotes'));
    const categoriesOpt = notes.map(i=>({
        text: I18N.get(`providerNoteCat.${i.category}`),
        value: i.category
    }));
    const loadObj = { spinning:loading,indicator: <IHLoading/> }

    return {
      title: 'Provider Notes',
      keyField: 'none',
      rowKey: 'id',
      showSearchInput: false,
      searchValue: search,
      onSearch: onTableSearch,
      onChange: onTableChange,
      loading:loadObj,
      dataSource: _.get(providerNoteList, 'data', []),
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: 10,
        total: _.get(providerNoteList, 'pageInfo.total', 0),
      },

       columns: [
        {
          title: 'Category',
          key: 'category',
          dataIndex: 'category',
          render: (cat,row) => {
              return this.renderCategory(I18N.get(`providerNoteCat.${cat}`), row);
            // return I18N.get(`providerNoteCat.${cat}`)
          },
          filterMultiple:true,
          filters:categoriesOpt,
          width: '160px',
        },
        {
          title: 'Content',
          key: 'content',
          dataIndex: 'content',
          render: (content,row) => this.renderContent(row)
        },
        {
          title: 'Created By',
          key: 'createdBy.profile.fullName',
          dataIndex: 'createdBy.profile.fullName',
          width: '120px',
        },
        {
          title: 'Result',
          key: 'result',
          dataIndex: 'result',
          width: '120px',
          render: (item, row) => item? (
            hasMissedAlert(item) ? <span className="miss-black">Missed</span> : hasMeasurement(item) ? formatReadingFromRow(item) : ''
          ) : ''
        },
        {
          title: 'Created On',
          key: 'createdAt',
          dataIndex: 'createdAt',
          width: '160px',
          render: (item, row) => formatDateMM24Date(item),
          sorter: true,
          sortOrder: _.get(sort, 'order', false)
        },
      ],
      noDataElem:
              <div style={{ height: '150px', paddingTop: '2em' }}>
                <p style={{ fontSize: '18px' }}>No Notes</p>
              </div>,
      actions: <IHButton {...b} />,
      onRow: (item) => {
        let { props } = this;
        let value = {...item,...props};
        return {
          onClick: () => {
            onRowClick(value);
          }
        }
      }
    };
  }

  render() {
    return (
        //style={{whiteSpace: 'pre-wrap'}}
      <div className="patient-task-table">
          <IHSearchTable {...this.getTableProps()} />
      </div>
    );

  }
})

