import React from 'react';
import { Table } from 'antd';
import Client from 'libModule/gqlClient';
import { setSystemDynamicConfig, deleteSystemDynamicConfig, getSystemDynamicConfigsList } from 'modulesAll/graphql/systemDynamicConfigs';
import { IHSearchTable, IHCheckbox, IHInputNumber, IHButton, _, IHForm } from 'ihcomponent';
import { message } from 'antd';
import {Button, Input, Modal} from 'antd';
import { GQLHelper } from 'lib/utils';
import {goPath} from 'libModule/utils';

export default class SystemDynamicConfigsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      showDeleteModal: false,
      editValue: ''
    };
  }

  componentWillMount() {
    this.props.onLoadParams(null, true);
  }



  renderEditModal(doc) {
    const configKey = doc.configKey;
    const value = doc.value;
    const id = doc.id;
    const title = 'Edit: ' + configKey;
    return (
      <Modal
        title = {title}
        visible={this.state.showEditModal}
        onOk={this.handleSubmit}
        onCancel={() => this.handleCancel()}
        footer={[
          <Button key="back" onClick={() => this.handleCancel()}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => this.handleEditSubmit(doc)}>
            Done
          </Button>
        ]}>
        <Input defaultValue={value} onChange={e => {this.setState({editValue: e.target.value})}}/>
      </Modal>
    );
  };

  renderDeleteModal(doc) {
    const title = 'Delete: ' + doc.configKey;
    return (
      <Modal
        title = {title}
        visible={this.state.showDeleteModal}
        onOk={this.handleDeleteSubmit}
        onCancel={() => this.handleCancel()}
        footer={[
          <Button key="back" onClick={() => this.handleCancel()}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => this.handleDeleteSubmit(doc)}>
            Delete
          </Button>
        ]}>
          Confirm?
      </Modal>
    );
  };

  handleEditSubmit(doc) {
    // console.log(this.state);
    console.log(doc)
    Client.mutate({
      mutation: setSystemDynamicConfig,
      variables: {
        id: doc.id,
        value: this.state.editValue
      }
    }).then(() => {
      goPath('/superadmin/systemdynamicconfigs');
      message.success('Updated successfully.');
      this.setState({ showEditModal: false });
    }).catch(e => {
      console.log(e);
      this.props.openErrorModal(GQLHelper.formatError(e))
    });
  }

  handleCancel() {
    this.setState({
      showEditModal: false,
      showDeleteModal: false
    });
  }

  handleDeleteSubmit(doc) {
    Client.mutate({
      mutation: deleteSystemDynamicConfig,
      variables: {
        id: doc.id
      },
      refetchQueries: [{
        query: getSystemDynamicConfigsList,
        variables: {
          count: 10,
          page: _.get(this.props.page, 'current', 1)
        }
      }]
    }).then(() => {
      goPath('/superadmin/systemdynamicconfigs');
      message.success('Deleted successfully.');
      this.setState({ showDeleteModal: false });
    }).catch(e => {
      console.log(e);
      this.props.openErrorModal(GQLHelper.formatError(e))
    });
  }

  showEditWindow(doc) {
    this.setState({
      showEditModal: true,
      editModalConfig: doc,
      editValue: ''
    });
  }

  showDeleteWindow(doc) {
    this.setState({
      showDeleteModal: true,
      deleteModalConfig: doc
    });
  }

  getTableProps() {
    const { onTableChange, page, loading } = this.props;
    const list = _.get(this.props.list, 'data');

    return {
      title: 'System Dynamic Configs',
      rowKey: doc => doc.id,
      onChange: (p, f, s) => {
        const newFilter = { ...f};
        const newPage = {...p};
        return onTableChange(newPage, newFilter, s)
      },
      loading,
      dataSource: list,
      columns: [
        {
          width: '40%',
          key: 'configKey',
          title: 'Key',
          dataIndex: 'configKey',
        },
        {
          width: '40%',
          key: 'value',
          title: 'Value',
          dataIndex: 'value',
        },
        {
          width: '20%',
          title: 'Actions',
          key: 'Actions',
          render: (t, doc) => {
            const eBtn = {
              size: 'exsmall',
              bsStyle: 'primary',
              onClick: () => {
                console.log('edit!');
                const config = {id: doc.id, configKey: doc.configKey, value: doc.value};
                this.showEditWindow(config);
              },
              className: 'table-action-button'
            };
            const eLabel = 'Edit';

            const dBtn = {
              size: 'exsmall',
              bsStyle: 'primary',
              onClick: () => {
                console.log('delete!');
                const config = {id: doc.id, configKey: doc.configKey, value: doc.value};
                this.showDeleteWindow(config);
              },
              className: 'table-action-button'
            };
            const dLabel = 'Delete';
            return (
              <div>
                <IHButton {...eBtn} style={{marginRight: '5px'}}>
                  {eLabel}
                </IHButton>
                <IHButton {...dBtn}>
                  {dLabel}
                </IHButton>
              </div>
            )
          }
        }
      ],
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize', 10),
        total: _.get(this.props.list, 'pageInfo.total', 0),
      },
    }
  }

  render() {
    const noDataElem = (
      <div style={{ height: '150px', paddingTop: '2em' }}>
        <p style={{ fontSize: '18px' }}>There is no data!</p>
      </div>
    );
    const { showEditModal, editModalConfig, showDeleteModal, deleteModalConfig } = this.state;

    return (
      <div style={{ padding:20}}>
        <IHSearchTable {...this.getTableProps()} noDataElem={noDataElem} />
        {showEditModal && this.renderEditModal(editModalConfig)}
        {showDeleteModal && this.renderDeleteModal(deleteModalConfig)}
      </div>
    );
  }
}