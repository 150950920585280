// Device for admin program template
export const APDevice = `
  model
  type
`

// Device for enrolled program , added status needed to render list to return devices
export const Device = `
  address
  model
  type
  other
  status
`

export const Address = `
  type
  streetName
  streetNumber
  floor
  unit
  city
  state
  country {
    code
    description
  }
  postCode
`

export const Phone = `
  type
  countryCode
  number
  verified
  canUseForLogin
`
export const Email = `
  address,
  primary,
  verified
`

export const Organization = `
  id
  name
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
`

export const PaginationInfo = `
  total
  lastPage
`

export const Team = `
  id
  name
  description
  healthCondition
  members{
    role {
      category
      name
    }
    primary
    user{
      id
      allRoles{
        name
      }
      profile{
        firstName
        lastName
        fullName
      }
    }
  }
`
