import { React, IHButton } from 'ihcomponent'
import { createContainer, getTimeFromProgram, GQLHelper } from 'libModule/utils'
import { convertDBToVitalList } from 'modulesAll/utils/helpers/task'
import { graphql } from 'react-apollo'
import setDevicesForEnrolledProgramGQL from 'modulesAll/graphql/setDevicesForEnrolledProgram'
import get from 'lodash/get'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { DEVICE_VITAL_TYPE_MAP } from 'modulesAll/utils/constants/task'
import I18N from 'modulesAll/I18N'

const temporaryDeviceAddress = 'XXXXXXXXXXXX';

const Component = class extends React.Component{
    static displayName = 'patient/main/containers/TemporaryButton'

    render() {
      const p = {
          type : 'primary',
          label : `Start ${I18N.get('keywords.Program')} Now`,
          onClick : this.handleClickStartEPButton.bind(this),
          className : 'v-nb',
          style : {
            //margin : '20px 20px 0 0'
          },
          disabled: this.props.assessmentHasEmptySchedule
      }
      return <IHButton {...p} />
    }
    handleClickStartEPButton = async () => {
      const { currentProgram, setDevicesForEnrolledProgram } = this.props
      const enrolledProgramId = currentProgram.id
      const currentUserId = sessionStorage.getItem('currentUserId')
      const prefixString = 'SUPER-SECRET-TOKEN-NOBODY-WILL-EVER-GUESS:action:ep_finish_enrollment'
      const userIdPair = `signee:${currentUserId}`
      const enrolledProgramIdPair = `enrolledProgramId:${enrolledProgramId}`
      const startDate = new Date().getTime()
      const { length, calendar } = get(currentProgram, 'duration')
      const endDate = startDate + getTimeFromProgram(length, calendar)

      const token = `${prefixString}:${userIdPair}:${enrolledProgramIdPair}`
      const variables = {
        token,
        startDate,
        endDate,
      }
      // console.log(variables)
      const deviceVariables = {
        forms: [
          {
            type: 'LOAN_FORM',
            uri: 'https://www.google.com',
          }
        ]
      }
      const VitalListData = convertDBToVitalList(currentProgram);
      VitalListData.forEach(vital => {
        const deviceType = DEVICE_VITAL_TYPE_MAP[vital.vitalType]

        if (!deviceVariables[deviceType]) {
          deviceVariables[deviceType] = {
            // address: deviceType + '123',
            address: temporaryDeviceAddress,
            status: 'VENDOR_LOAN'
          }
        }
      })
      deviceVariables.tablet = {
        //address: 'tablet321',
        address: temporaryDeviceAddress,
        status: 'VENDOR_LOAN'
      }
      deviceVariables.id = currentProgram.id

      try {
        const resDevice = await setDevicesForEnrolledProgram({ variables: deviceVariables })
        console.log(resDevice.data)
        location.reload()
      }
      catch(err) {
        console.log(err)
        this.props.openErrorModal(GQLHelper.formatError(err))
      }
    }
}

const wrapperGQL = graphql(setDevicesForEnrolledProgramGQL, {name: 'setDevicesForEnrolledProgram'})
(Component)

export default createContainer(wrapperGQL, (state)=>{
    return {
        // VitalListData: get(state, 'patient.Careplan.VitalList.data')
    }
}, (dispatch)=>{
    return {
        openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage))
    }
})
