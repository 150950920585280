import React, { Component } from 'react';
import '../css/index.scss';
import moment from 'moment';

class CallContainer extends Component {
    constructor() {
        super();
        this.state = {
            isMinCall: false
        }
    }

    handlerSizeBox() {
        const isMinCall = this.state.isMinCall;
        if (isMinCall) {
            this.setState({ isMinCall: false });
            this.props.maxCall();
        }
        else {
            this.setState({ isMinCall: true });
            this.props.minCall();
        }
    }

    close() {
        this.props.close();
    }

    render() {
        /**
     * callArgs
     * {
     *      phoneNumber
     *      username
     *      status    0:connecting    1:connected    2:ended     3:incoming    4:accepted    5:destroy    6:acw   7:close
     *      duration   min
     *      date
     *      showChat
     * }
     */
        const username = this.props.username;
        const status = this.props.status;
        let icon = this.state.isMinCall ? 'max' : 'min';
        if (status == 2) {
            icon = 'close';
            if (this.state.isMinCall) {
                this.maxCall();
            }
        }
        let lightClass = '';
        switch (status) {
            case 0:
                break;
            case 1:
                lightClass = 'connected';
                break;
            case 2:
                lightClass = 'ended';
                break;
        }
        let header = <div className={'callHeader'}>
            <img src={'/image/call-phone.png'} className={'callimg'} />
            <span className={'light' + ' ' + lightClass}></span>
            <div>
                <span className={'title'}>Calling {username}</span>
            </div>
            <span className={'btnclose'} onClick={() => this.handlerSizeBox()}><img src={'/image/call-' + icon + '.png'} /></span>
        </div>;
        if (status == 2) {
            let time = '';
            if (this.props.date&&this.props.duration) {
                // let date = new Date(this.props.date);
                let duration = this.props.duration;
                time = this.props.date + ', ' + duration;
            }
            header = <div className={'callHeader ended'}>
                <div>
                    <span className={'title'}>Last call: {username}</span>
                    <span className={'time'}>{time}</span>
                </div>
                <span className={'btnclose'} onClick={() => this.close()}><img src={'/image/call-' + icon + '.png'} /></span>
            </div>
        }
        let body = <div className={'callBody'} id={'callBody'}>
            {/* <iframe src={'https://www.baidu.com'}></iframe> */}
        </div>;
        return <div className={'callMain'}>
            {header}
            {body}
        </div>
    }


}
export default CallContainer