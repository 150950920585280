import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import { Link } from 'react-router'
import _ from 'lodash'
import editOrganization from '../query/editOrganization';
import organizationGQL from '../query/organization';
import Client from 'libModule/gqlClient';
import { goPath, FormHelper, convertToFieldsValue, getRouterParam, setAsyncRouteLeaveHook, GQLHelper } from 'libModule/utils'
import { IHBaseFormWithRow, IHButton, message } from 'ihcomponent'
import PropTypes from 'prop-types';
import { getFormProps } from '../component/formData/organizationEditForm'
import * as actions from '../action/organizationEditAction'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import { modalAction } from 'modulesAll/common/actions'
import I18N from 'modulesAll/I18N'
import { createAuditLog } from 'libModule/utils/auditLog'
import organizationListGQL from '../query/organizationList'

class Container extends Component {
    static displayName = '/superadmin/organization/container/OrganizationEditContainer'

    componentWillMount() {
        setAsyncRouteLeaveHook(this.context.router, this.props.route, this.routerWillLeave.bind(this))

        const { organizationData, updateForm } = this.props
        const initData = this.convertOrganizationDataToFormData(organizationData)
        const fieldsValue = convertToFieldsValue(initData)
        updateForm(fieldsValue)
    }
    componentDidMount() {
        const { organizationData, setBreadCrumb } = this.props
        if (organizationData) {
            setBreadCrumb(`Organizations / ${organizationData.name} / Edit`)
        }
    }
    componentWillUnmount() {
        this.props.resetForm()
    }

    routerWillLeave() {
        const { openConfirmModal } = this.props
        const submit = this.submit.bind(this)
        return new Promise((resolve, reject) => {
            if (this.props.isFormSaved) {
                resolve(true)
                return
            }
            openConfirmModal(resolve, submit)
        })
    }

    convertOrganizationDataToFormData() {
        const { organizationData } = this.props;

        const initData = {
            id: organizationData.id,
            identifier: organizationData.identifier,
            name: organizationData.name,
            parentId: organizationData.parent ? organizationData.parent.id : '',
            leaf: organizationData.leaf,
            //accessTmpl: organizationData.accessTmpl,
            description: organizationData.description,
            timezone: organizationData.timezone,
            streetNumber: organizationData.address ? organizationData.address.streetNumber : '',
            streetName: organizationData.address ? organizationData.address.streetName : '',
            city: organizationData.address ? organizationData.address.city : '',
            state: organizationData.address ? organizationData.address.state : '',
            country: organizationData.address ? organizationData.address.country.code : '',
            postCode: organizationData.address ? organizationData.address.postCode : '',
            phoneCountryCode:organizationData.phone ? '+'+organizationData.phone.countryCode : '',
            phone: organizationData.phone ? organizationData.phone.number : ''
        }
        return initData
    }

    render() {
        const body = this.renderBody()
        const footer = this.renderFooter();
        return (
            <div className='vsm-provider-new-page vsm-form-container'>
                {this.renderBackLink()}
                <div className='provider-new-form v-form-required'>
                    {body}
                    {footer}
                </div>
            </div>
        )
    }

    renderBackLink() {
        return (
            <div className="v-link">
                <Link to={`/superadmin/organizations`}>
                    <i className="fa fa-angle-left" />
                    <span>Back to Organizations</span>
                </Link>
            </div>
        )
    }
    renderBody() {
        const { onFieldsChange, organizationData,organizationList,orgsLoading } = this.props
        const initData = this.convertOrganizationDataToFormData(organizationData)
        const fieldsValue = convertToFieldsValue(initData)
        const formProps = getFormProps({ onFieldsChange, fieldsValue,organizationList })
        if (!orgsLoading) {
            return <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
        }
    }
    renderFooter() {
        const styles = {
            footer: {
                display: 'flex',
                flexBasis: '100%',
                justifyContent: 'flex-end',
                width: '100%',
                paddingRight: 5,
                marginTop: 10
            }
        }
        const buttonProps = {
            cancel: {
                onClick: (e) => {
                    goPath(`/superadmin/organizations`)
                },
                bsStyle: 'default',
                label: 'Cancel'
            },
            done: {
                onClick: (e) => {
                    e.preventDefault();
                    this.submit()
                },
                bsStyle: 'primary',
                label: 'Save',
                style: {
                    marginRight: 10
                }
            }
        }

        const footer = (
            <div style={styles.footer}>
                <IHButton {...buttonProps.done} />
                <IHButton {...buttonProps.cancel} />
            </div>
        )
        return footer
    }

    getMutateVariable(data) {
        let phone = null;
        if (data.phone&&data.phone.trim()) {
            phone = {
                countryCode: data.phoneCountryCode ? parseInt(data.phoneCountryCode, 10) : '',
                number: data.phone ? data.phone.trim() : '',
                type: 'WORK'
            };
        }
        const id = data.id;
        const name = data.name ? data.name.trim() : '';
        const identifier = data.identifier ? data.identifier.trim() : name.replace(/\s+/g,'_');
        const parentId = data.parentId;
        const leaf = data.leaf;
        const accessTmpl = data.accessTmpl ? data.accessTmpl : (leaf ? 'LEAF' : 'GROUP');
        const description = data.description ? data.description.trim() : '';
        const timezone = data.timezone ? data.timezone.trim() : '';

        const streetNumber = data.streetNumber ? data.streetNumber.trim() : '';
        const streetName = data.streetName ? data.streetName.trim() : '';
        const state = data.state ? data.state.trim() : '';
        const city = data.city ? data.city.trim() : '';
        const country = data.country ? data.country.trim() : '';
        const postCode = data.postCode ? data.postCode.trim() : '';
        let address = null;
        if (streetNumber && streetName && state && city && country && postCode) {
            address = {
                type: 'MAIN',
                streetNumber,
                streetName,
                city,
                state,
                country,
                postCode
            };
        }

        const result = {
            id: id,
            identifier,
            name,
            description,
            timezone,
            address,
            phone
        };

        return result;
    }

    async submit() {
        const { mutate, fieldsValue, handleFormSaved, openErrorModal } = this.props;
        const formValue = FormHelper.getValue(fieldsValue)
        let isFormValid = true
        const callback = (result, value) => {
            if (!result) {
                isFormValid = false
            }
        }

        await this.baseForm.getFormData(callback)
        if (!isFormValid) {
            message.error('Oops, please check the errors below')
            return
        }

        const variables = this.getMutateVariable(formValue)
        try {
            await handleFormSaved()
            const res = await mutate({ variables })

            createAuditLog({
                action: I18N.get('auditLog.admin.organization.edit'),
                details: formValue,
                request: variables,
                response: res
            })

            if (res.data.editOrganization) {

                message.success('Edit organization success');

                const variables = {
                    id: this.props.id
                };
                Client.query({
                    query: organizationGQL,
                    variables: { ...variables },
                    fetchPolicy: 'network-only'
                })
                    .then(res => goPath(`/superadmin/organizations`))
                    .catch(err => console.log('----- JENNA! err: ', err))
                    .finally(() => goPath(`/superadmin/organizations`));
            }
        }
        catch (e) {
            openErrorModal(GQLHelper.formatError(e))
            createAuditLog({
                action: I18N.get('auditLog.admin.organization.edit'),
                details: formValue,
                request: variables,
                response: e,
                success: false
            })
        }
    }
}

Container.contextTypes = {
    router: PropTypes.object.isRequired
}

const ContainerWithData = graphql(editOrganization)(Container)

const mapState = (state, ownProps) => {
    const id = getRouterParam(state, 'organizationId')
    return {
        ...state.organization.OrganizationEdit,
        id
    }
}

const mapDispatch = (dispatch) => {
    return {
        onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
        handleFormSaved: () => dispatch(actions.handleFormSaved()),
        updateForm: (initData) => dispatch(actions.updateForm(initData)),
        resetForm: () => dispatch(actions.resetForm()),
        setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
        openConfirmModal: (resolve, submit) => dispatch(modalAction.openConfirmModal(resolve, submit)),
        openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
    }
}

Container.propTypes = {
    id: PropTypes.string,
    onFieldsChange: PropTypes.func,
    resetForm: PropTypes.func,
    handleFormSaved: PropTypes.func,
    updateForm: PropTypes.func,
    organizationData: PropTypes.object,
    route: PropTypes.object,
    mutate: PropTypes.func,
    fieldsValue: PropTypes.object,
    isFormSaved: PropTypes.bool,
    setBreadCrumb: PropTypes.func,
    openConfirmModal: PropTypes.func,
    openErrorModal: PropTypes.func,
}

const organizationList = graphql(organizationListGQL, {
    options: (ownProps) => {
        const variables = {
            page: 1,
            count: 99999,
            filters: {
                leafOnly: false
            }
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ data }) => {
        return {
            organizationList: data.organizationList,
            orgsLoading: data.loading
        }
    }
})


export default compose(connect(
    mapState, mapDispatch
), organizationList)(ContainerWithData)
