import React from 'react';
import { Table,Radio,Icon } from 'antd';
import moment from "moment/moment";
import { convertBG_mmolTomgdl } from '../../../lib/utils/convertUnits';
import { openModal,closeModal } from 'layoutModule/actions/MainModal'
import SingleMeasureRenderComponent from "./SingleMeasureRenderComponent";
import MultiMeasureComponent from './MultiMeasureComponent';
import PairedMeasuresComponent from './PairedMeasuresComponment'
import '../style/index.scss';
import {compose} from "react-apollo/index";
import {connect} from "react-redux";
import $ from 'jquery';
const { Column, ColumnGroup } = Table;
import { IHLoading } from 'ihcomponent'

// const { Button,Group } = Radio;
const readingSeverityMap ={
    "RISK":'riskReading',
    "NORMAL":'normalReading',
    "CRITICAL":'criticalReading'
}

const LogBookListComponent = class extends React.Component {
    constructor(){
        super();
    }
    generateDaySpots(){
        const fromDate = moment(_.get(this,'props.fromDate'));
        const toDate = moment(_.get(this,'props.toDate'));
        let timeSpots = [];

        while(fromDate.isSameOrBefore(toDate,'day')){
            timeSpots.push(fromDate.format('MM/DD/YYYY'));
            fromDate.add(1,'day');
        }

        return timeSpots.reverse();
    }


    renderUnit(){
        return <Icon type='info-circle-o' className='unitIcon'>
            <span style={{paddingLeft:5}}>Measurements are in</span>
            <span style={{fontWeight:900}}>&nbsp;mmHg</span>
        </Icon>
    }

    groupData(){
        const data = this.props.resultList;
        const timeSpots = this.generateDaySpots();
        const parsedData =  _.groupBy(data,(d)=>{
            return moment(d.date).format('MM/DD/YYYY')
        })

        let groupedData = _.map(timeSpots,(key)=>{
            return {
                [key]:parsedData[key]||[{date:new Date(key)}]
            }

        })

        return groupedData;
    }

    renderTimeRange(){
        const { fromDate,toDate } = this.props;
        const fromDateString = moment(fromDate).format('MM/DD/YYYY');
        const toDateString = toDate==undefined ? moment().subtract(6,'day').format('MM/DD/YYYY') : moment(toDate).format('MM/DD/YYYY');
        return <div style={{marginLeft:5,marginRight:5}}>
            <p className='timeRange'>{fromDateString} - {toDateString}</p>
        </div>
    }

    renderNextPreFooter(){
        return <div>
            <ul className='ant-pagination logBookPreNext' >
                <li title="Previous Page" className=" ant-pagination-prev" aria-disabled="false" onClick={()=>this.props.changePre()}>
                    <a className="ant-pagination-item-link"></a>
                </li>
                { this.renderTimeRange() }
                <li title="Next Page" className=" ant-pagination-next" aria-disabled="false" onClick={()=>this.props.changeNext()}>
                    <a className="ant-pagination-item-link"></a>
                </li>
            </ul>
        </div>
    }

    renderRadioButton(){
        const fromDate = moment(_.get(this,'props.fromDate',moment()));
        const toDate = moment(_.get(this,'props.toDate',moment()));
        const diffInWeek = toDate.diff(fromDate,'week');
        const oneWeekChecked = (diffInWeek===0) ;
        const twoWeekChecked = (diffInWeek===1) ;
        const oneMonthChecked = (diffInWeek===4) ;
        return <Radio.Group className='it-dd logBookButtonGroup' style={{marginLeft:20}}>
            <Radio.Button value="7"   onClick={()=>this.props.setTimeInterval('w',1)} checked = {oneWeekChecked}>1 Week</Radio.Button>
            <Radio.Button value="14"  onClick={()=>this.props.setTimeInterval('w',2)} checked = {twoWeekChecked} >2 Weeks</Radio.Button>
            <Radio.Button value="30"  onClick={()=>this.props.setTimeInterval('M',1)} checked = {oneMonthChecked}>Month</Radio.Button>
        </Radio.Group>
    }

    renderVitalType(){
        return <h4 style={{marginBottom:0,flex:1}}>Blood Pressure</h4>
    }

    renderTableHeader(){
        return <div style=
                        {{
                            height: '46px',
                            padding: '0 20px',
                            position: 'relative',
                            display:'flex',
                            alignItems:'flex-end',
                        }}
                    className='ih-dd logBookHeaderContainer'>
                    { this.renderVitalType() }
                    { this.renderNextPreFooter() }
                    { this.renderRadioButton() }
        </div>
    }

    parseData = (data) =>{
        const map = [{
                key:'DAWN',
                startTime:0,
                endTime: 4*3.6e6
            },
            {
                key:'MORNING',
                startTime:4,
                endTime: 12*3.6e6
            },
            {
                key:'NOON',
                startTime: 12,
                endTime: 18*3.6e6
            },
            {
                key:'NIGHT',
                startTime: 18,
                endTime: 24*3.6e6
            }];
        const { date } = data;
        const dateObj = new Date(date);
        const startHour = dateObj.getHours();
        const endHour = dateObj.getHours()*3.6e6 + dateObj.getMinutes()*6e4;
        let type = '';
        _.forEach(map,(o)=>{
            if(startHour>=o.startTime&&endHour<o.endTime&&!(startHour==0&&endHour==0)){
                type = o.key;
            }
        })

        return type;
    }

    handleData(filteredList){
        const allMissed = filteredList.length>0 && !_.find(filteredList,(i)=>(i.severity!='MISSED'));
        let readingCount = !allMissed ? _.reject(filteredList,(i)=>i.severity=='MISSED').length :'';
        const readingObj = filteredList.length>0&&!allMissed ? _.find(filteredList,(i)=>(i.severity&&i.severity!='MISSED'))||{} : { };
        const readingDate = readingObj.date;
        const hasUserNote = (_.filter(filteredList,(i)=>_.get(i,'measure.user_notes'))).length!=0;
        let className = allMissed ? 'missedReading' : (readingCount>1 ? 'logbookCellWithBadge BP':readingCount>0 ? 'logbookCell':'') +" "+(readingSeverityMap[readingObj.severity]||'');
        const readingValue =   allMissed ? 'Missed' : !_.isEmpty(readingObj) ? {
            ﻿                   'systolic_blood_pressure_value' : (readingObj.﻿﻿systolic_blood_pressure ? readingObj.﻿﻿systolic_blood_pressure.value: '' ),
                               ﻿'diastolic_blood_pressure_value': (readingObj.﻿diastolic_blood_pressure ? readingObj.﻿diastolic_blood_pressure.value : ''),
                               'heart_rate_value': readingObj.heart_rate.value ? readingObj.heart_rate.value :''
                             } : '';

        const valueString = allMissed ? '':( _.get(readingValue,'systolic_blood_pressure_value','')+''+_.get(readingValue,'diastolic_blood_pressure_value','')+''+_.get(readingValue,'heart_rate_value',''));
        const valueLenClass = valueString.length==6 ? 'sixDigit' : valueString.length==7 ? 'sevenDigit' :valueString.length==8 ? 'eightDigit': valueString.length==9 ? 'nineDigit':'' ;
        const unit =  readingValue ? 'bpm':'';
        readingCount = readingCount>1 ? readingCount : null;
        const hasUserNoteClassName = hasUserNote  ? ` hasUserNote ${valueLenClass}`:'';
        className =className.concat(hasUserNoteClassName);

        return {
            readingCount,
            unit,
            readingValue,
            className,
            readingDate,
            allMissed,
            hasUserNote
        }
    }

    renderReadingInCell(readingValue){
        if(typeof readingValue==='string'){
            return<span>{readingValue}</span>;
        }
        return <div>
                <div>
                    <span>{readingValue.systolic_blood_pressure_value}/{readingValue.diastolic_blood_pressure_value}</span>
                    <span style={{ marginLeft:10 }}>{`${readingValue.heart_rate_value} bpm`}</span>
                </div>
              </div>
    }

    renderTable(){
        const { loading }= this.props;
        const dataToRender = this.groupData();
        const parsedData = _.map((dataToRender),(d)=>{
            const valueArray = Object.values(d)[0];
            return {
                data:_.groupBy(valueArray,(d)=>this.parseData(d)),
                date:valueArray[0].date
            }
        })
        return  <div className='IH-SearchTable' style={{border:'unset'}}>
            {this.renderTableHeader()}
            <Table dataSource={parsedData} rowKey={(data,index)=>data.date+index}
                   className='BPlogBookTable' bordered
                   scroll={{ y: 360 }}
                   style={{ borderTop:'solid 1px #e9e9e9',margin:20,marginBottom:0}}
                   pagination={false}
                   loading={{spinning: loading, indicator: <IHLoading/>}}
            >
                <Column
                    title="Date"
                    dataIndex="date"
                    // key='date'
                    sorter={(a,b)=>a.date-b.date}
                    render={(data)=>moment(data).format('MM/DD/YYYY')}
                    width={'12%'}
                />
                <Column
                    title={<div>Overnight<p>(24:00 - 4:00)</p></div>}
                    dataIndex="data.DAWN"
                    key='DAWN'
                    className='logBookIcon'
                    width='11%'
                    render={(data, index) => {
                        const { readingCount,className,mgdlValue,unit,readingValue } = this.handleData(data||[]);

                        return {
                            props: {
                                className:'number',
                                data:readingCount

                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}
                                {/*<span>{ typeof readingValue!='string' ? `${readingValue.systolic_blood_pressure_value}/${readingValue.diastolic_blood_pressure_value} ${readingValue.heart_rate_value}`:readingValue }</span>*/}
                            </div>
                        }
                    }
                    }

                    onCell={(data) => {
                        return {
                            onClick: (e) => {
                                const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                const ele = e.target;
                                const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                const isMissedReading = eleIsMissedReading || parentIsMissedReading;

                                if(isMissedReading){
                                    return;
                                }

                                // const measures = data.data.DAWN||[];
                                const measures = _.isArray(data.data.DAWN) ? _.filter(data.data.DAWN,d=>d.severity!='MISSED') : [];

                                if (measures.length == 0) return;
                                if (measures.length == 1) {
                                    this.props.openModal(<SingleMeasureRenderComponent
                                        data={measures}
                                        title='Overnight Measurement'
                                        handleData={this.handleData}
                                        beforeMeal={false}
                                        patientId={this.props.patientId}
                                        date={data.date}
                                        showSingleReading={true}
                                        type='BP'
                                    />,{
                                        className:'singlePMeasureModal'
                                    })
                                }
                                if (measures.length > 1) {
                                    this.props.openModal(<MultiMeasureComponent
                                            data={measures}
                                            title='Overnight Measurement'
                                            handleData={this.handleData}
                                            beforeMeal={false}
                                            patientId={this.props.patientId}
                                            date={data.date}
                                            type='BP'

                                        />,{
                                            className:'multiBPMeasureModal'
                                        }
                                    )
                                }
                            }
                        }
                    }
                    }
                />
                <Column
                    title={<div>Morning<p>(4:00 - 12:00)</p></div>}
                    dataIndex="data.MORNING"
                    key='MORNING'
                    className='logBookIcon'
                    width='11%'
                    render={(data, index) => {
                        const { readingCount,className,mgdlValue,unit,readingValue } = this.handleData(data||[]);

                        return {
                            props: {
                                className:'number',
                                data:readingCount

                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}

                                {/*<span>{ typeof readingValue!='string' ? `${readingValue.systolic_blood_pressure_value}/${readingValue.diastolic_blood_pressure_value} ${readingValue.heart_rate_value}`:readingValue }</span>*/}
                            </div>
                        }
                    }
                    }

                    onCell={(data) => {
                        return {
                            onClick: (e) => {
                                const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                const ele = e.target;
                                const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                const isMissedReading = eleIsMissedReading || parentIsMissedReading;

                                if(isMissedReading){
                                    return;
                                }

                                const measures = _.isArray(data.data.MORNING) ?  _.filter(data.data.MORNING,d=>d.severity!='MISSED') : [];
                                if (measures.length == 0) return;
                                if (measures.length == 1) {
                                    this.props.openModal(<SingleMeasureRenderComponent
                                        data={measures}
                                        title='Morning Measurement'
                                        handleData={this.handleData}
                                        beforeMeal={false}
                                        patientId={this.props.patientId}
                                        date={data.date}
                                        showSingleReading={true}
                                        type='BP'

                                    />,{
                                        className:'singlePMeasureModal'
                                    })
                                }
                                if (measures.length > 1) {
                                    this.props.openModal(<MultiMeasureComponent
                                            data={measures}
                                            title='Morning Measurement'
                                            handleData={this.handleData}
                                            beforeMeal={false}
                                            patientId={this.props.patientId}
                                            date={data.date}
                                            type='BP'

                                        />,{
                                            className:'multiBPMeasureModal'
                                        }
                                    )
                                }
                            }
                        }
                    }
                    }
                />
                <Column
                    title={<div>Afternoon<p>(12:00 - 18:00)</p></div>}
                    dataIndex="data.NOON"
                    key='NOON'
                    className='logBookIcon'
                    width='11%'
                    render={(data, index) => {
                        const { readingCount,className,mgdlValue,unit,readingValue } = this.handleData(data||[]);
                        return {
                            props: {
                                className:'number',
                                data:readingCount

                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}

                                {/*<span>{ typeof readingValue!='string' ? `${readingValue.systolic_blood_pressure_value}/${readingValue.diastolic_blood_pressure_value} ${readingValue.heart_rate_value}`:readingValue }</span>*/}
                            </div>
                        }
                    }
                    }

                    onCell={(data) => {
                        return {
                            onClick: (e) => {
                                const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                const ele = e.target;
                                const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                const isMissedReading = eleIsMissedReading || parentIsMissedReading;

                                if(isMissedReading){
                                    return;
                                }

                                // const measures = data.data.NOON||[];
                                const measures = _.isArray(data.data.NOON) ?  _.filter(data.data.NOON,d=>d.severity!='MISSED'): [];

                                if (measures.length == 0) return;
                                if (measures.length == 1) {
                                    this.props.openModal(<SingleMeasureRenderComponent
                                        data={measures}
                                        title='Afternoon Measurement'
                                        handleData={this.handleData}
                                        beforeMeal={false}
                                        patientId={this.props.patientId}
                                        date={data.date}
                                        showSingleReading={true}
                                        type='BP'

                                    />,{
                                        className:'singlePMeasureModal'
                                    })
                                }
                                if (measures.length > 1) {
                                    this.props.openModal(<MultiMeasureComponent
                                            data={measures}
                                            title='Afternoon Measurement'
                                            handleData={this.handleData}
                                            beforeMeal={false}
                                            patientId={this.props.patientId}
                                            date={data.date}
                                            type='BP'

                                        />,{
                                            className:'multiBPMeasureModal'
                                        }
                                    )
                                }
                            }
                        }
                    }
                    }
                />
                <Column
                    title={<div>Evening<p>(18:00 - 24:00)</p></div>}
                    dataIndex="data.NIGHT"
                    key='NIGHT'
                    width='11%'
                    className='logBookIcon'
                    render={(data, index) => {
                        const { readingCount,className,mgdlValue,unit,readingValue } = this.handleData(data||[]);

                        return {
                            props: {
                                className:'number',
                                data:readingCount

                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}

                                {/*<span>{ typeof readingValue!='string' ? `${readingValue.systolic_blood_pressure_value}/${readingValue.diastolic_blood_pressure_value} ${readingValue.heart_rate_value}`:readingValue }</span>*/}
                            </div>
                        }
                    }
                    }

                    onCell={(data) => {
                        return {
                            onClick: (e) => {
                                const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                const ele = e.target;
                                const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                const isMissedReading = eleIsMissedReading || parentIsMissedReading;
                                if(isMissedReading){
                                    return;
                                }

                                const measures = _.isArray(data.data.NIGHT) ? _.filter(data.data.NIGHT,d=>d.severity!='MISSED') : [];
                                if (measures.length == 0) return;
                                if (measures.length == 1) {
                                    this.props.openModal(<SingleMeasureRenderComponent
                                        data={measures}
                                        title='Evening Measurement'
                                        handleData={this.handleData}
                                        beforeMeal={false}
                                        patientId={this.props.patientId}
                                        date={data.date}
                                        showSingleReading={true}
                                        type='BP'

                                    />,{
                                        className:'singlePMeasureModal'
                                    })
                                }
                                if (measures.length > 1) {
                                    this.props.openModal(<MultiMeasureComponent
                                            data={measures}
                                            title='Evening Measurement'
                                            handleData={this.handleData}
                                            beforeMeal={false}
                                            patientId={this.props.patientId}
                                            date={data.date}
                                            type='BP'

                                        />,{
                                            className:'multiBPMeasureModal'
                                        }
                                    )
                                }
                            }
                        }
                    }
                    }
                />
            </Table>
        </div>
    }

    render(){
        return <div>
            {this.renderTable()}
            {this.renderUnit()}
        </div>
    }
}


const mapToDispatch = (dispatch)=> {
    return {
        openModal: (content, row) => dispatch(openModal(content, row)),
        closeModal:()=>dispatch(closeModal()),
        // changePre:()=>dispatch(actions.changePre()),
        // changeNext:()=>dispatch(actions.changeNext()),
        // setTimeInterval:(interval,value)=>dispatch(actions.setTimeInterval(interval,value)),
        // initialState:()=>dispatch(actions.initialState()),
        // resetState:()=>dispatch(actions.resetState()),
    }
}

export default compose(connect(null,mapToDispatch))(LogBookListComponent);
