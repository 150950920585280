import React from "react";

let  AllTaskFinishedComponent  = class extends React.Component {
        componentWillUnmount(){
            const { setShowTaskFinished } = this.props;
            setShowTaskFinished(false);
        }
        render(){
            let { type } = this.props;
            if(type === 'MA') {
                type =  'CA'
            }
            return <div style={{ height:600,width:'inherit',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center' }}>
                        <img src={'/image/smile_face.png'} width={130} style={{ marginBottom: 20 }}/>
                        <h4>{`Great! You have finish the ${type} worklist!`}</h4>
                   </div>
        }
}
export default AllTaskFinishedComponent;
