import I18N from "../../../I18N";
import moment from "moment/moment";
import React from 'react';
import { nationOptions, langOptions, raceOptions, countryOptions,
         docTypeOptions, HEALTH_CONDITION_DISPLAY,HEALTH_CONDITION_ICDCODE } from 'libModule/constants'
import { formatFieldLabels,renderRadio,renderDropDown,renderRadioButton }from '../helpers';
import { Input, Radio, DatePicker ,Alert,Tooltip,Icon } from 'antd'
import { dobValidator, emailValidator2, postCodeValidator, blockValidator, floorValidator, unitValidator,
    firstAndLastNameValidator, NRICValidator, patientWeightRegiterValidator, patientHeightInputValidator,
    patientHeightInchesInputValidator, mobileValidator, homePhoneValidator,yearOfDiagnoseValidator } from 'libModule/validator';
import { programCategoriesMap } from '../constants/map';
import 'moment-timezone';
import $ from 'jquery';
import { ReactInput, templateParser, templateFormatter, parseDigit } from 'input-format'
// DOB template
const TEMPLATE = 'xx/xx/xxxx'
const parse = templateParser(TEMPLATE, parseDigit)
const dobParseFormat = templateFormatter(TEMPLATE)

const rules = {
                identification:false,
                DOB:true,
                firstName:true,
                lastName:true,
                nickname:false,
                gender:false,
              }

const radioMap = {
    race:raceOptions,
    nationality:nationOptions,
    country:countryOptions,
    maritalStatus:[
        {
            value:'SINGLE',
            label:'Single'
        },
        {
            value:'MARRIED',
            label:'Married'
        },
        {
            value:'DIVORCED',
            label:'Divorced'
        },
        {
            value:'WIDOWED',
            label:'Widowed'
        },
        {
            value:'SEPARATED',
            label:'Separated',
        }
    ],
    economicStatus:[
        {
            value:'Independent',
            label:'Independent'
        },
        {
            value:'Dependent',
            label:'Dependent'
        }
    ],
    occupation:[
        {
            value:'Student',
            label:'Student'
        },
        {
            value:'Employed',
            label:'Employed'
        },
        {
            value:'Unemployed',
            label:'Unemployed'
        },
        {
            value:'Self-Employed',
            label:'Self-Employed'
        },
        {
            value:'Retired',
            label:'Retired'
        }
    ],
    CCMEnrolled:[
        { value:true,name:'YES'},
        { value:false,name:'NO'}
    ],
    RPMEnrolled:[
        { value:true,name:'YES'},
        { value:false,name:'NO'}
    ],
    language:langOptions
}

const phoneValidator = (value)=>{
    if (!value) return;
    if (!/^\d{10}$/.test(value)) {
        return I18N.get('validator.mobile.invalid');
    }
};

const getProgramCategories = (val,type)=>{
    const filteredRes  = _.filter(val,(k)=>k.name==programCategoriesMap[type]);
    const enrolledDate = _.get(filteredRes,'[0].enrolledDate',null);
    const programCategory  = {
        enrolled: _.get(filteredRes,'[0].enrolled'),
        enrolledDate: enrolledDate ? moment(enrolledDate) : enrolledDate
    }
    return  programCategory;
}
const genRules = (form,localThis) =>{
    const fieldArray = ['streetNumber','streetName','city','state','postCode','country'];
    const fieldValues = form.getFieldsValue();
    const address = _.get(localThis,'props.user.address[0]',{});
    let hasValue = [];

    _.each(fieldArray,(v)=>{
        if((!(v in fieldValues)&&address[v])||fieldValues[v]){
            hasValue.push(v);
        }
    });

    if(hasValue.length!=0){
        return [{
            required:true,
            // message:`${field} is required`
        }]
    }
}

const basicInfoConfig = (ccmEnrolled,rpmEnrolled)=>
    [
    [
        {
            key:'identification',
            label: formatFieldLabels('identificationValue', 'nric'),
            path:'identification[0].value',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span:4,
            rules: [{ required:rules.identification,message : `${I18N.get('keywords.NRIC/FIN')} is required`}, {validator: (rule, value, callback) => callback(NRICValidator(value))}],
            inputContent:(props)=><Input disabled={true}/>,
        },
        {
            key: 'DOB',
            label: formatFieldLabels('DOB', 'dob'),
            labelCol: { span: 24 },
            wrapperCol: { span: 6 },
            span:20,
            path:'profile.birthday',
            placeholder: 'MM/DD/YYYY',
            format: 'MM/DD/YYYY',
            initialValue: '',
            rules : [{required : rules.DOB, message : 'DOB is required'}, 
            { validator: (rule, value, callback) => {
                callback(dobValidator(value)) 
            }}],
            //inputContent:({format})=><DatePicker format={format}/>,
            formatValue:(data,format)=>{
                const tz = moment.tz.guess();
                const formValue = moment(data).format('MM/DD/YYYY')
                return formValue;//moment(data).tz(tz);
            },
            // inputContent({
            //     className,
            //     format,
            //     value,
            //     onChange,
            //     localThis,
            //     key,
            //     form
            // }, user, localThis)
            inputContent: (p1, p2, p3) => {
                const { format, form, className, value } = p1
                var formValue = form.getFieldValue('DOB');
                formValue = formValue.replace(/-/g,'')
                formValue = formValue.replace(/\//g,'')
                return (
                    <div id="dob-input"  >
                        <ReactInput
                            className="ant-input"
                            placeholder="MM/DD/YYYY"
                            value={formValue}
                            onChange={DOB => { } }
                            parse={parse}
                            format={dobParseFormat}/>
                    </div>
                );
            },
        }
    ],
    [
        {
            key: 'firstName',
            label: formatFieldLabels('firstName', 'firstName'),
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span:4,
            path:'profile.firstName',
            rules : [
                {required : rules.firstName, message : 'First Name is required'},
                {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}
            ],
            style:{float:'left',width:'100%'},
            inputContent:({disabled})=><Input disabled={disabled}/>
        },
        {
            key: 'lastName',
            label: formatFieldLabels('lastName', 'lastName'),
            labelCol: { span: 24 },
            wrapperCol: { span: 6},
            span:20,
            path:'profile.lastName',
            rules : [
                {required : rules.firstName, message : 'Last Name is required'},
                {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName'))}
            ],
            style:{float:'left',width:'100%'},
            inputContent:({disabled})=><Input disabled={disabled}/>
        }
    ],
    [
        {
            key: 'nickname',
            label: formatFieldLabels('nickname', 'nickname'),
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span:4,
            path:'profile.nickname',
            rules : [
                //{validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'nickname'))}
            ],
            style:{float:'left',width:'100%'},
            inputContent:({disabled})=><Input disabled={disabled}/>
        },
        {
            key: 'gender',
            label: formatFieldLabels('gender', 'gender'),
            type: 'radioButton',
            labelCol: { span: 24 },
            wrapperCol: { span: 6 },
            span:20,
            path:'profile.gender',
            className: 'radio-button-2-option',
            rules : [
                {required : rules.gender, message : 'Gender is required'}
            ],
            style:{float:'left',width:'100%'},
            inputContent:({value,disabled})=><Radio.Group disabled={disabled} >
                <Radio.Button value={'Male'}>Male</Radio.Button>
                <Radio.Button value={'Female'}>Female</Radio.Button>
            </Radio.Group>,
            formatValue:(data)=>data=='M'?'Male':'Female'
        }
    ],
    [
        {
            key: 'homePhone',
            label: formatFieldLabels('homePhone','homePhone'),
            path:(localThis)=>{
                const { user } = localThis.props;
                const { phone } = user;
                const homeIndex = phone.length>0 ? phone.map((e)=>{ return e.type; }).indexOf('HOME'): -1;

                return `phone[${homeIndex}].number`;
            },
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 3,
            inputContent:({disabled})=><Input disabled={disabled}/>,
            rules:[{ required:false, validator:(rule,v,callback)=>callback(phoneValidator(v)) }]

        },
        {
            key: 'mobilePhone',
            label: formatFieldLabels('mobilePhone','mobilePhone'),
            path:(localThis)=>{
                const { user } = localThis.props;
                const { phone } = user;
                const mobileIndex = phone.length>0 ? phone.map((e)=>{ return e.type; }).indexOf('MOBILE'): -1;

                return `phone[${mobileIndex}].number`;
            },
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 3,
            inputContent:({disabled})=><Input disabled={disabled}/>,
            rules:[{ required:false, validator:(rule,v,callback)=>callback(phoneValidator(v)) }]
        },
        {
            key: 'canUseForLogin',
            label: formatFieldLabels('canUseForLogin', 'canUseForLogin'),
            type: 'radioButton',
            labelCol: { span: 24  },
            wrapperCol: { span: 22 },
            span: 4,
            path:(localThis)=>{
                const { user } = localThis.props;
                const { phone } = user;
                const homeIndex = phone.length>0 ? phone.map((e)=>{ return e.type; }).indexOf('MOBILE'): -1;
                return `phone[${homeIndex}].canUseForLogin`;
            },
            className: 'radio-button-2-option',
            style:{float:'left',width:'100%'},
            inputContent:({value,disabled})=> <div>
                                                    <Radio.Group disabled={disabled} defaultValue={typeof value=='boolean' ? value.toString() : (typeof value=='string'&&value.length>0) ? value :'true'}>
                                                        <Radio.Button value='true' style={{width: 60, textAlign: 'center'}}>On</Radio.Button>
                                                        <Radio.Button value='false' style={{width: 60, textAlign: 'center'}}>Off</Radio.Button>
                                                    </Radio.Group>
                                                    <Tooltip title={I18N.get('OTPLogInInfo')}>
                                                        <Icon type='info-circle' style={{fontSize: 14, marginLeft: 5,position:'absolute',marginTop:-20,marginLeft:-15}}/>
                                                    </Tooltip>
                                                </div>,
            // formatValue:(data)=> data.toString()
        }
    ],
    [
        {
            key: 'email',
            label: formatFieldLabels('email','emailAddress'),
            labelCol: { span: 24 },
            wrapperCol: { span: 6 },
            span: 24,
            path:'email[0].address',
            rules : [
                { required : rules.email, message : 'Email is required'},
                { type: 'email', message: 'E-mail! Not valid'}
            ],
            inputContent:({disabled})=><Input disabled={disabled}/>
        }
    ],
    [
        {
            key: 'streetNumber',
            label: formatFieldLabels('streetNumber','streetNumber'),
            path:'address[0].streetNumber',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            colStyle:{height:87},
            span:4,
            inputContent:()=><Input />,
            rules:(form,localThis)=>{
                return genRules(form,localThis);
            },
        },
        {
            key: 'streetName',
            label: formatFieldLabels('streetName','streetName'),
            path:'address[0].streetName',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            colStyle:{height:87},
            span:8,
            rules:(form,localThis)=>{
                return genRules(form,localThis);
            },
            inputContent:({disabled})=><Input disabled={disabled}/>,
            // rules : [
            //     { min: 2 , message: 'Need to more than 2 characters' },
            // ]
        },
        {
            key: 'city',
            label: formatFieldLabels('city', 'city'),
            path:'address[0].city',
            colStyle:{height:87},
            rules:(form,localThis)=>{
                return genRules(form,localThis);

            },
            inputContent:({disabled})=><Input disabled={disabled}/>,
            labelCol: {span: 24},
            wrapperCol: {span: 8 },
            span:12
        }
    ],
   [
       {
            key: 'state',
            label: formatFieldLabels('state','state'),
            path:'address[0].state',
            rules:(form,localThis)=>{
                return genRules(form,localThis);

            },
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            colStyle:{height:87},
            span:4,
            inputContent:({disabled})=><Input disabled={disabled}/>,
        },
        {
            key: 'postCode',
            label: formatFieldLabels('postCode','postCode'),
            path:'address[0].postCode',
            rules:(form,localThis)=>{
                return genRules(form,localThis);

            },
            colStyle:{height:87},
            maxLength: 6,
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span:4,
            colStyle:{whiteSpace:'nowrap'},
            inputContent:({disabled})=><Input disabled={disabled} style={{whiteSpace:'nowrap'}}/>,
            // rules : [ {validator: (rule, value, callback) => callback(postCodeValidator(value))} ]
        },
        {
            key: 'country',
            label: formatFieldLabels('country','country'),
            path:'address[0].country.code',
            rules:(form,localThis)=>{
                return genRules(form,localThis);

            },
            colStyle:{height:87},
            labelCol: { span: 24 },
            wrapperCol: { span: 8 },
            span:16,
            inputContent:(props)=>renderDropDown(radioMap['country'],props),
        }
    ],
    [
        {
            key:'race',
            label: formatFieldLabels('race', 'race'),
            path:'profile.race.code',
            labelCol: { span: 24 },
            wrapperCol: { span: 16 },
            span:8,
            inputContent:(props)=>renderDropDown(radioMap['race'],props)
        },
        {
            key:'language',
            label: formatFieldLabels('language', 'language'),
            path:'profile.language.code',
            labelCol: { span: 24 },
            wrapperCol: { span: 16 },
            span:8,
            inputContent:(props)=>renderDropDown(radioMap['language'],props)
        }
    ],
    [
        {
            key:'maritalStatus',
            label: formatFieldLabels('maritalStatus', 'maritalStatus'),
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
            span:24,
            path:'profile.maritalStatus.code',
            inputContent:(props)=>renderRadio(radioMap['maritalStatus'],props)
        }
    ],
    [
        {
            key:'economicStatus',
            label: formatFieldLabels('economicStatus', 'economicStatus'),
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
            span:24,
            path:'profile.economicStatus',
            inputContent:(props)=>renderRadio(radioMap['economicStatus'],props)
        }
    ],
    [
        {
            key:'occupation',
            label: formatFieldLabels('occupation', 'occupation'),
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
            span:24,
            path:'profile.occupation',
            inputContent:(props)=>renderRadio(radioMap['occupation'],props)
        }
    ],
    [
        {
            key:'insuranceProvider',
            label:formatFieldLabels('insuranceProvider','insuranceProvider'),
            labelCol:{ span:24 },
            wrapperCol: { span:8 },
            span:24,
            path:'profile.insuranceProvider',
            inputContent:({disabled})=><Input disabled={disabled}/>
        }
    ],
    [
        {
            key: 'CCMEnrolled.enrolled',
            label: formatFieldLabels('CCMEnrolled.enrolled', 'CCMEnrolled.enrolled'),
            type: 'radioButton',
            labelCol: { span: 8 },
            wrapperCol: { span: 24 },
            span:6,
            path:'profile.programCategories',
            className: 'radio-button-2-option',
            inputContent:(props)=>renderRadioButton(radioMap['CCMEnrolled'],props),
            onChange:(value,localThis)=> {
                const { form } = localThis.props;
                const fieldValue = form.getFieldValue('CCMEnrolled.enrolledDate');
                if(value&&!fieldValue) {
                    form.setFieldsValue({
                        'CCMEnrolled.enrolledDate': moment(new Date())
                    });
                }
                if(fieldValue&&!value){

                    form.setFieldsValue({
                        'CCMEnrolled.enrolledDate':null
                    });
                }
                if(!fieldValue&&!value){
                    if(_.get(form.getFieldError('CCMEnrolled.enrolledDate'),'length',0)>0){
                        form.setFields({
                            'CCMEnrolled.enrolledDate':{
                                value:null,
                                errors:[]
                            }
                        })
                        $('body').find('.ccmEnrolledDate').parent().parent().removeClass('has-error');
                    }
                }
            },
            formatValue:(data,format)=> (getProgramCategories(data,'CCMEnrolled'))['enrolled']
        },
        {
            key:'CCMEnrolled.enrolledDate',
            label:formatFieldLabels('CCMEnrolled.enrolledDate','CCMEnrolled.enrolledDate'),
            labelCol:{ span:24 },
            wrapperCol:{ span:10 },
            span:18,
            placeholder: 'MM/DD/YYYY',
            format: 'MM/DD/YYYY',
            colStyle:{height:87},
            className:'ccmEnrolledDate',
            rules:(form)=>[ { required :form.getFieldValue('CCMEnrolled.enrolled') , message : 'CCM Enrolled Date is required'} ],
            path:'profile.programCategories',
            inputContent:({ format,form,className })=> {
                const ccmDateRequired = form.getFieldValue('CCMEnrolled.enrolled');
                return <DatePicker disabled={!ccmDateRequired} format={format} className={className}/>
            },
            formatValue:(data,format)=>(getProgramCategories(data,'CCMEnrolled'))['enrolledDate']||null
        }
    ],
    [
        {
            key: 'RPMEnrolled.enrolled',
            label: formatFieldLabels('RPMEnrolled.enrolled', 'RPMEnrolled.enrolled'),
            type: 'radioButton',
            labelCol: { span: 8 },
            wrapperCol: { span: 24 },
            span:6,
            path:'profile.programCategories',
            className: 'radio-button-2-option',
            inputContent:(props)=>renderRadioButton(radioMap['RPMEnrolled'],props),
            formatValue:(data,format)=>(getProgramCategories(data,'RPMEnrolled'))['enrolled'],
            onChange:(value,localThis,e)=> {
                const { form } = localThis.props;
                const fieldValue = form.getFieldValue('RPMEnrolled.enrolledDate');
                if(value&&!fieldValue) {
                    form.setFieldsValue({
                        'RPMEnrolled.enrolledDate': moment(new Date())
                    });
                }
                if(fieldValue&&!value){
                    form.setFieldsValue({
                        'RPMEnrolled.enrolledDate':null
                    });
                }

                if(!fieldValue&&!value){
                    if(_.get(form.getFieldError('RPMEnrolled.enrolledDate'),'length',0)>0){
                        form.setFields({
                            'RPMEnrolled.enrolledDate':{
                                value:null,
                                errors:[]
                            }
                        })
                        $('body').find('.rpmEnrolledDate').parent().parent().removeClass('has-error');
                    }
                }
            },
        },
        {
            key:'RPMEnrolled.enrolledDate',
            label:formatFieldLabels('RPMEnrolled.enrolledDate','RPMEnrolled.enrolledDate'),
            labelCol:{ span:24 },
            wrapperCol:{ span:10 },
            span:18,
            colStyle:{height:87},
            placeholder: 'MM/DD/YYYY',
            format: 'MM/DD/YYYY',
            className:'rpmEnrolledDate',
            rules:(form)=>[ { required :form.getFieldValue('RPMEnrolled.enrolled') , message : 'RPM Enrolled Date is required'} ],
            path:'profile.programCategories',
            inputContent:({ format,form,className })=> {
               const rpmDateRequired = form.getFieldValue('RPMEnrolled.enrolled');
               return <DatePicker disabled={!rpmDateRequired} format={format} className={className}/>
            },
            formatValue:(data,format)=>(getProgramCategories(data,'RPMEnrolled'))['enrolledDate'],
        }
    ]
   ]

export{
    basicInfoConfig,
}
