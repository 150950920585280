import gql from 'graphql-tag'
export default gql`

mutation removeFromInventory($organizationId:EID!, $memberId:EID!, $products:[InventoryProductInput!]!, $note:String) {
    removeFromInventory(organizationId: $organizationId, memberId:$memberId, products: $products, note:$note) {
        id
        organization {
            id
        }
        products {
            productType
            quantity
            description
        }
    }
}`
