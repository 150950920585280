import React from 'react';
import { IHLoading } from 'ihcomponent'
export default class extends React.Component {
    constructor(){
        super();
    }

    render(){
        const { src,removeImg,width,height,onClickImg,loading } = this.props;
        if(loading){

            return <IHLoading />
        }
        return <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <p onClick={ onClickImg }><img src={src} width={width} height={height} /></p>
                <div  onClick={ ()=>removeImg() } style={{ position: 'relative',left:-10,top:-40 }}>
                    <img src='image/crossbtn.png' width={22} className='attachImg' />
                </div>
               </div>
    }
}
