import React from 'react';
import VitalListTable from './VitalListTableComponent';
import InterventionNotes from '../../../alerts/InterventionNotes/containers/InterventionNotes'
import { Modal } from 'antd';
import '../css/index.scss';
const typeMap = {
    'BG':'bg_note',
    'BP':'bg_note',
    'HR':'hr_note',
    'HS':'hs_note',
    'PO':'po_note',
    'TM':'tm_note'
}
const VitalList = class extends React.Component{

  constructor(){
      super();
      this.state = {
          showAddVitalModal: false,
          note:{}
      }
  }

  openPatientVitalModal=(note)=>{
      this.setState({
          showAddVitalModal:true,
          note
      })
  }

  closePatientVitalModal = ()=>{
      this.setState({
          showAddVitalModal:false,
          note:{}
      })
  }

  renderInterventionNote(){
      const { showAddVitalModal,note } = this.state;
      const { type } = note;
      if(showAddVitalModal) {
          return <Modal visible={true} footer={null} width={'1000px'}
                        style={{ marginTop:-50 }}
                        onCancel={()=>this.closePatientVitalModal()}
                        maskClosable={false}
                        className='vitalAlertModal'
                 >
                    <InterventionNotes row={note} noteType={typeMap[type]} closeModal={()=>this.closePatientVitalModal()}/>
                </Modal>
      }
  }
  render() {
      const { props } = this;
      return <div>
                { this.renderInterventionNote() }
                <VitalListTable {...props} openPatientVitalModal={this.openPatientVitalModal} />
             </div>
  }
}

export default VitalList
