import React from "react";
import {Form} from "antd/lib/index";
import { API } from '../query/index';
import { Button,DatePicker,TimePicker,Input,Select } from 'antd';
import { helpers} from "../helpers";
import VISIT_TYPE_ENUM from '../constants/map';
import { message} from "../../../../../package/IHComponent";
import get from "lodash/get";
import actions from "../actions";
import {connect} from "react-redux";
import {compose} from "react-apollo/index";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'lib/utils';
import moment from 'moment';
const InputGroup = Input.Group;
const Option = Select.Option;
const FormItem = Form.Item;
const dateFormat = 'MM/DD/YYYY';

let CreateVisitComponent = class extends React.Component {
    static displayName = 'CreateVisitComponent';

    constructor(props){
        super(props);
    }
    openNewWindow(url){
        var params = [
            'height='+screen.height,
            'width='+screen.width,
            'fullscreen=yes' // only works in IE, but here for completeness
        ].join(',');
        let newwindow = window.open(url,'name',params);
        if (window.focus) {newwindow.focus()}
        return false;
    }
    renderDropDown(){
        const { getFieldDecorator } = this.props.form;
        const doctorOptions = helpers.filterDoctors();
        return (
            <div className="createVisit">
              <div className='firstRow'>
                <FormItem
                    label="Doctor"
                >
                    {getFieldDecorator('Doctor')(
                        <Select>
                            {_.map(doctorOptions,(program)=>{
                                return (
                                    <Option value={program.id} key={program.id}>{program.fullName}</Option>
                                )
                            })}
                        </Select>
                    )}
                </FormItem>
              </div>

              <div className='secondRow'>
                <FormItem
                    label="Type"
                >
                    {getFieldDecorator('Type', {
                        rules: [
                            { required: true, message: 'Please Select Appointment Type'},
                        ]
                    })(
                        <Select>
                            {_.map(VISIT_TYPE_ENUM,(type,val)=>{
                                return (
                                    <Option value={val} key={val}>{type}</Option>
                                )
                            })}
                        </Select>
                    )}
                </FormItem>
                {/*<div className='timepickerContainer'>*/}
                    <FormItem
                        label="Date"
                    >
                        {getFieldDecorator('Date', {
                            rules: [
                                { required: true, message: 'Please Select Date'},
                            ],
                            initialValue:moment().tz(moment.tz.guess())
                        })(
                            <DatePicker
                                format={dateFormat}
                                placeholder="Select Date"

                            />
                        )}
                    </FormItem>
                    <FormItem
                        label="Time"
                    >
                        {getFieldDecorator('Time', {
                            rules: [
                                { required: true, message: 'Please Select Time'},
                            ],
                        })(
                            <TimePicker
                                format="HH:mm"
                                placeholder="Select Time"
                                use12Hours={false}
                                minuteStep = {30}
                            />
                        )}
                    </FormItem>
                {/*</div>*/}
              </div>
                <div className='thirdRow'>
                    <FormItem
                        label="Description(Optional)"
                    >
                        {getFieldDecorator('Reason', {
                            rules: [
                                { required: false },
                            ]
                        })(

                            <textarea  rows={ 1 } cols={ 60 }/>

                        )}
                    </FormItem>
                    <div className="homeWindowLink">
                        <span onClick={()=>{this.openNewWindow('/')}} style={{fontSize:12,color:'blue',cursor:'pointer',margin:'5px'}}>Show Appointment Schedule ></span>
                    </div>
                </div>
            </div>
            )

    }
    renderFooter() {

        const baseStyle = {
            margin:5
        }

        let buttonList =   [
            {
                key: 'cancel',
                label: "Cancel",
                type: 'default',
                size:'default',
                style:baseStyle,
                onClick:()=>{
                    this.props.closeModal();
                }
            },
            {
                key: 'submit',
                label: "Save",
                type: 'primary',
                htmlType:"submit",
                style:baseStyle,
            }
        ]


        const buttons =   buttonList.map((value,index)=>{

            return (
                <FormItem
                    key={value.label}
                >
                    <Button className={value.className || ''}  onClick={value.onClick}
                            type={value.type} style={value.style} htmlType={value.htmlType}>{value.label}</Button>
                </FormItem>
            )
        });

        return (
            <div className='createButtons'>{buttons}</div>
        )
    }
    createVisit(){
        const patientId = this.props.patientId;
        const formVariables  = this.props.form.getFieldsValue();
        // const currentUserFirstRole = get(currentUser, 'allRoles')[0];
        // let organizationId = currentUserFirstRole ? currentUserFirstRole.organization.id : '';

        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const organizationId = _.get(currentUser,'selectedRole.organization.id');
        // refactored from allRole[0] to selectedRole(IH-1052)

        const date = formVariables.Date;
        const time = formVariables.Time;

        const combinedTime = helpers.combineDateAndTime(date,time);
        const variables = {
            memberId:patientId,
            type:formVariables.Type,
            appointmentAt:combinedTime,
            providers:[formVariables.Doctor],
            organizationId:organizationId,
            reason:formVariables.Reason,
            programIds:[formVariables.Program]
        }

        API.createVisit(variables)
        .then((res)=>{
            const data = res.data.createVisit;
            this.props.addVisit(res.data.createVisit);
            this.props.closeModal();
            message.success('Appointment Scheduled');
        }).catch((error)=>{
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }
    onSubmit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((error)=>{
            if(!error){
               this.createVisit();
            }
        })
    }
    render(){
        return(
            <div>
                <Form onSubmit={this.onSubmit} style={{display:'flex',flexDirection:'column'}} className='visit_form'>
                    { this.renderDropDown()}
                    { this.renderFooter() }
                </Form>
            </div>
        )

    }

}

const mapToDispatch = (dispatch)=> {
    return {
        addVisit:(data) => dispatch(actions.addVisit(data)),
        openErrorModal:(error)=>dispatch(openErrorModal(error))

    }
}
CreateVisitComponent = Form.create()(CreateVisitComponent);

export default compose(connect(null,mapToDispatch))(CreateVisitComponent);


