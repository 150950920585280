import React, { Component } from 'react';
import { Button } from 'antd';
import { removeTaskFromEnrolledProgram } from 'graphqlModule/mutation/removeTaskFromEnrolledProgram';
import { modalAction } from 'modulesAll/common/actions'
import { connect } from 'react-redux'
import Client from 'libModule/gqlClient';
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'libModule/utils';
import { message } from 'ihcomponent'
import I18N from 'modulesAll/I18N';
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import get from "lodash/get";

class DeleteTaskComponent extends Component {
    constructor(){
        super();
        this.openConfirmModal.bind(this);
    }

    render(){
      return <Button  onClick={()=>this.openConfirmModal()} style={{marginLeft:'auto',color:'#f0666b',borderColor:'#f0666b'}}>Delete</Button>
    }

    openConfirmModal(){
        const { fullName,vitalType } = this.props;
        return new Promise((resolve, reject) => {
            const Yes = {
                label: 'Confirm',
                handler: ()=>{
                    resolve(this.handleRemoveTaskFromEnrolledProgram());
                },
                closeModal: true
            }
            const No = {
                label: 'Cancel',
                handler: () => {
                    resolve(false)
                }
            }

            const modalProps = {
                Yes,
                No,
                title: I18N.get('vital.deleteVital.confirm.title'),
                content: I18N.get('vital.deleteVital.confirm.content')(vitalType,fullName)
            };
            this.props.openConfirmModal(modalProps)
        })
    }

    handleRemoveTaskFromEnrolledProgram(){
        const { enrolledProgramId,taskId,changePage,openErrorModal } = this.props;
        try{
            Client.mutate({
                mutation: removeTaskFromEnrolledProgram,
                variables: {
                    id:enrolledProgramId,
                    taskId:[taskId]
                },
                refetchQueries:[
                    {
                        query:enrolledProgramGQL,
                        variables: {
                            id: enrolledProgramId
                        },
                    }
                ]
            })
            .then(()=>{
                message.success('Vital deleted successfully.');
                changePage();
            })
            .catch(e=>openErrorModal(GQLHelper.formatError(e)));
        }catch(e){
           openErrorModal(GQLHelper.formatError(e))
        }
    }

}
const mapToDispatch = (dispatch)=>{
    return {
        openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
        openErrorModal:(e)=>dispatch(openErrorModal(e))
    }
}

export default  connect(null,mapToDispatch)(DeleteTaskComponent);
