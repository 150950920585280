import types from '../constants/Nav';

export const handleScroll = () => {
  return {
    type: types.SCROLL
  }
}

export const setTopNavPath = (path)=>{
  return {
    type : types.SET_TOP_PATH,
    path
  }
}


export const setBreadCrumb = (breadCrumb)=>{
  return {
    type : types.SET_BREAD_CRUMB,
    breadCrumb
  }
}

export const setInstitution = (institution)=>{
  return {
    type : types.SET_INSTITUTION,
    institution
  }
}
export const changeMenuStats = (menuOpened)=>{
  return {
    type: types.CHANGE_MENU_STATS,
    menuOpened
  }
}
export const resetState = ()=>{
  return {
    type : types.RESET_STATE
  }
}
