import { mobileValidator,postCodeValidator } from 'libModule/validator';
import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers';
import { mapPropsToFields } from 'libModule/utils';
import { phoneOptions,countryOptions } from 'libModule/constants';
const FORMLABELS = I18N.get('patientlist.manualInputForm');
import get from 'lodash/get';
import I18N from 'modulesAll/I18N';

export const schema = {
    id:{
        initialValue:'',
        required:true
    },

    name: {
        initialValue: '',
        required: true
    },

    parentId: {
        initialValue: '',
        required: true
    },
    identifier: {
        initialValue: '',
        required: true
    },
    leaf: {
        initialValue: false,
        required: true
    },
    // accessTmpl: {
    //     initialValue: '',
    //     required: true
    // },
    description: {
        initialValue: '',
        required: true
    },
    address: {
        initialValue: '',
        required: true
    },
    timezone: {
        initialValue: '',
        required: true
    },
    phone: {
        initialValue: '',
        required: false
    },
    phoneCountryCode: {
        initialValue: '+1',
        required: false
    },
    streetName: {
        initialValue: '',
        required: false
    },
    streetNumber: {
        initialValue: '',
        required: false
    },
    city: {
        initialValue: '',
        required: false
    },
    state: {
        initialValue: '',
        required: false
    }, country: {
        initialValue: '',
        required: false
    }, postCode: {
        initialValue: '',
        required: false
    }
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

export const getFormProps = ({ fieldsValue, onFieldsChange ,organizationList}) => {

    const orgs = organizationList && organizationList.data && organizationList.data.length > 0 ? organizationList.data : [];
   

    const itemList = [
        [
            {
                key: 'organizationInfo',
                label: 'Organization Info',
                type: 'label',
                col: { span: 4, offset: 0 }
            },
            {
                key: 'name',
                label: 'Organization Name',
                type: 'text',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                // placeholder: 'First Name',
                placeholder: '',
                initialValue: initData['name'],
                col: { span: 6, offset: 0 },
                rules: [
                    { required: isFieldsRequired.name, message: 'Organization Name is required' }
                ]
            },
            {
                key: 'identifier',
                label: 'Identifier',
                type: 'text',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                placeholder: '',
                initialValue: initData['identifier'],
                col: { span: 6, offset: 0 },
            }
        ],
        [
            {
                key: 'description',
                label: 'Description',
                type: 'text',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                placeholder: '',
                initialValue: initData['description'],
                col: { span: 12, offset: 4 },
                rules: [
                    { required: isFieldsRequired.username, message: 'Description is required' }
                ],
            }
        ],
        [
            {
                key: 'leaf',
                label: 'Leaf',
                type: 'radioButton',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                placeholder: '',
                initialValue: initData['leaf'],
                col: { span: 6, offset: 4 },
                option: [
                    { name: 'Yes', value: true },
                    { name: 'No', value: false }
                ],
                className: 'radio-button-2-option',
                rules: [
                    { required: isFieldsRequired.leaf, message: 'Leaf is required' }
                ],disabled: true,
            }
        ],
        [
            {
                key: 'parentId',
                label: 'Parent Id',
                type: 'select',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                placeholder: 'Select Parent',
                initialValue: initData['parentId'],
                col: { span: 10, offset: 4 },
                // option: departments.map((department) => {
                //     return { name: department.name, value: department.code }
                // }),
                option: orgs.map((org) => {
                    return { name: org.name, value: org.id }
                }),
                rules: [
                    { required: isFieldsRequired.parentId, message: 'ParentId is required' }
                ],
                showSearch: true,
                filterOption: (inputValue, option) => {
                    return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                },disabled: true,
            }
        ],
        [
            {
                key: 'timezone',
                label: 'Time Zone',
                type: 'select',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                placeholder: '',
                initialValue: initData['timezone'],
                col: { span: 10, offset: 4 },
                option: [
                    { name: 'America/Los_Angeles', value: 'America/Los_Angeles' },
                    { name: 'America/Phoenix', value: 'America/Phoenix' },
                    { name: 'America/Chicago', value: 'America/Chicago' },
                    { name: 'America/New_York', value: 'America/New_York' }
                ],
                rules: [
                    { required: isFieldsRequired.timezone, message: 'Time Zone is required' }
                ]
            }
        ],
        [
            {
                key: 'contactInfo',
                label: 'Contact Info',
                type: 'label',
                col: { span: 4, offset: 0 }
            },
            {
                key: 'phoneCountryCode',
                label: 'Country Code',
                type: 'text',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                initialValue: '+'+initData.phoneCountryCode,
                col: { span: 4, offset: 0 },
                option: phoneOptions,
                disabled: true,
            },
            {
                key: 'phone',
                label: 'Phone',
                type: 'text',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                placeholder: '',
                initialValue: initData['phone'],
                col: { span: 6, offset: 0 },
                rules: [
                    // {required : isFieldsRequired.mobile, message : 'Mobile Number is required'},
                    { validator: (rule, value, callback) => callback(mobileValidator(value)) }
                ]
                // className: 'hide-required-mark'
            }
        ], [
            {
                key: 'streetNumber',
                label: 'Street Number',
                //path: 'address[0].streetNumber',
                type: 'text',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                col: { span: 6, offset: 4 },
                initialValue: initData['streetNumber'],
                placeholder: '',
            },
            {
                key: 'streetName',
                label: 'Street Name',
                //path: 'address[0].streetName',
                type: 'text',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                col: { span: 6, offset: 0 },
                initialValue: initData['streetName'],
                placeholder: '',
            },
            {
                key: 'city',
                label: 'City',
                type: 'text',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                col: { span: 6, offset: 0 },
                initialValue: initData['city'],
                placeholder: '',
            }
        ],
        [
            {
                key: 'state',
                label: 'State',
                type: 'text',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                col: { span: 6, offset: 4 },
                initialValue: initData['state'],
                placeholder: '',
            },
            {
                key: 'postCode',
                label: 'Post Code',
                type: 'text',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                col: { span: 6, offset: 0 },
                initialValue: initData['postCode'],
                placeholder: '',
                rules: [
                    // {required : isFieldsRequired.mobile, message : 'Mobile Number is required'},
                    { validator: (rule, value, callback) => callback(postCodeValidator(value)) }
                ]
            },
            {
                key: 'country',
                label: 'Country',
                type: 'select',
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                col: { span: 6, offset: 0 },
                initialValue: initData['country'],
                option:countryOptions,
                showSearch:false
            }
        ]
    ]
    return {
        vertical: true,
        fieldsValue,
        onFieldsChange,
        mapPropsToFields,
        itemList
    }
}
