const assess1 = 'Medical history: \n' +
    '- Disease name / Year of diagnosis\n' +
    '- Family history: \n' +
    '- Treatment history (when started oral meds, insulin, recent hospitalization, or surgery, etc.) \n' +
    '- Medication compliance:'+'\n'+'- Home monitoring (vitals, current device, frequency) \n' +
    '- Social Support: \n' +
    '\n' +
    'Lifestyle: \n' +
    '- Daily routine (activity, sleep, etc.) \n' +
    '- Diet history: '+'\n\n';

const assess2 = '1. Typical diet routine / Meal time or 24-hr recall \n' +
    'B: \n' +
    'L: \n' +
    'D: \n' +
    'Snacks: \n' +
    'Fluids: Water intake / Caffeine intake \n' +
    'Eating out / Frequency per week (Restaurant VS Fast food)\n\n' +
    '2. Restriction / Food allergies \n' +
    '\n' +
    '3. Basic nutrition knowledge (know carb/pro?, food labels, etc.) \n' +
    '\n' +
    '4. Previous diets tried / Special diet (Who cooks & shops for food) \n' +
    '\n' +
    '- Alcohol: Y / N (type/ frequency/ amount) \n' +
    '- Smoking: Y / N (type/ frequency/ amount/ history)\n' +
    '- Exercise: Type/ routine/ duration \n' +
    '- Weight history / Weight change:\n\n' +
    '* For BG pt only, Last exam date \n' +
    '- Eye exam:\n' +
    '- Foot exam:\n' +
    '- Dental exam:';
const goalPlaceHolder = 'Clinical Prognosis and Treatment Goals\n' +
    '\n'+'Behavioral Goals\n' +
    '\n'
export default {
  assess1,
  assess2,
  goalPlaceHolder
};
