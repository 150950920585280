import {
  React, IHLoading, IHButton, message, IHSelect,_
} from 'ihcomponent';
import { graphql } from 'react-apollo';
import I18N from 'modulesAll/I18N';
import { lightEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList';
import MemberDetail from './ViewOnlyMemberDetail';
import PatientAppointmentList from '../../../visit/components/PatientAppointmentList'
import CreateVisitComponent from '../../../visit/components/CreateVisitComponent_not_used';
import TimerContainer from '../../../timer/containers/TimerContainer';
import   chatAction  from 'modulesAll/chat/actions/index';
//import Dropdown from './Dropdown';
import {modalAction} from 'modulesAll/common/actions'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import actions from 'modulesAll/patient/deviceTab/actions'
import UserClass from 'graphqlModule/class/User'
import { compose, withProps } from 'recompose';
import * as R from 'ramda';
import userGQL from '../../../graphql/user';

import { createContainer, GQLHelper, getRouterParam, RequestCache, goPath } from 'libModule/utils';
import { scrollToBottom } from 'libModule/helpers/scrollTo';

import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import dischargeEnrolledProgram from 'modulesAll/graphql/dischargeEnrolledProgram';
import resumeEnrolledProgram from 'modulesAll/graphql/resumeEnrolledProgram';

import EnrolledProgramClass from 'modulesAll/graphql/class/EnrolledProgram';
import Threshold from 'modulesAll/common/components/Threshold';
import PatientProgramTaskListContainer from 'patientModule/enrolledProgramInfo/containers/PatientProgramTaskListContainer';
import ProgramInfoTab from 'patientModule/enrolledProgramInfo/containers/ProgramInfoTab';
import DischargeConfirmContainer from 'patientModule/enrolledProgramInfo/containers/DischargeConfirmContainer';
import SuspendConfirmContainer from 'patientModule/main/containers/SuspendConfirmContainer';
import PatientAlertTableContainer from 'patientModule/alertTable/containers/PatientAlertTableContainer'
import PatientVitals from 'patientModule/vitalList/containers/main'
import Assessments from 'patientModule/assessmentList/containers/PatientAssessmentListContainer'
import AllNotes from 'modulesAll/allNotes/List/containers/ListContainer'
import { openModal, closeModal, openErrorModal } from 'layoutModule/actions/MainModal';
import ReportModal from 'modulesAll/report/containers/ReportModalContentContainer'
import {setResuming, setExtendStep, resetExtendValueType} from 'patientModule/enrolledProgramInfo/actions/EnrolledProgramInfoActions';
import { createAuditLog } from 'libModule/utils/auditLog'
import { setTab } from 'patientModule/enrolledProgramInfo/actions/ProgramInfoTab';
import EnrolledProgramInfo from '../../enrolledProgramInfo/containers/main'

import moment from 'moment';
import $ from 'jquery';
import PatientAppointmentContainerWrapper from 'modulesAll/visit/containers/PatientAppointmentContainerWrapper';

const NoActiveProgram = (props)=>{
  return <div style={{ padding:25 }}>No Active Program</div>
}

const debug = false;

const wrapGQLData2 = graphql(lightEnrolledProgramList, {
  options: ownProps => ({
    variables: {
      count: 999,
      page: 1,
      filters: {
        memberId: ownProps.patientId,
      },
      sort: {
        field: 'NAME',
        direction: 'ASC',
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
});

const displayName = 'patient/main/containers/ViewOnlyMain'
class ProviderViewPatientContainer extends React.Component {
    static displayName = displayName;
  constructor(props) {
    super(props);
    this.state = {
      selectedProgramIndex:0,
      tabSelected:'vitals'
  };
  };

  renderSelect(options){
    const p = {
      option: [
        ...options
      ],
      size: 'large',
      value: options[this.state.selectedProgramIndex].name,
      onChange: (index) => {
        this.setState({selectedProgramIndex:parseInt(index)});
      },
    };

    return (
      <div style={{backgroundColor:"white", width:"100%", display:"flex", justifyContent:"center"}} className="programDropDown">
        <IHSelect {...p} />
      </div>
    );
  }

  updateTabSelected=(tabSelected)=>{
      this.setState({
          tabSelected
      })
  }

  render() {
    const {  data, enrolledProgramId,  patientId,patientName,showStickyHeader,patientBirthday,doctor } = this.props;
    let { enrolledProgramList, loading, refetch } = data;
    const { tabSelected } = this.state;
    if(debug) console.log('ProviderViewPatientContainer props', this.props);

    let memberDetail = <MemberDetail patientId={patientId} currentPrograms={items}/>;
    let body = <IHLoading />;

    if (loading) {
      return (
        <div>
          {memberDetail}
          {body}
        </div>
      );
    }

    const sortedEnrolledProgramList = _.get(enrolledProgramList, 'data') || [];

    const nurseEPList = UserClass.getEnrolledProgramIdList()
    const canViewEPList = sortedEnrolledProgramList.filter( (ep) => (nurseEPList || []).indexOf(ep.id) !== -1 )
    const startedProgramsCanView = canViewEPList.filter(enrolled => (enrolled.status !== 'DISCHARGED' && enrolled.status !== 'RETURNED'))

    //TODO please check logic here. make showStarted=false will have some errors.

    this.currentProgram = startedProgramsCanView[this.state.selectedProgramIndex]||null;
    const hasStartedProgram = this.currentProgram&&this.currentProgram.status=='STARTED';
    RequestCache.set('enrolledProgramList', enrolledProgramList);


    const items = startedProgramsCanView.map((item,index)=>({value:index.toString(), name:item.name}));
    const currentProgramNames = startedProgramsCanView.map(item=>item.name);
    const patientProfileWidth = $('.vsm-patient-member-detail').width();
    let displayName = this.constructor.displayName;
    displayName = `${displayName}:${patientId}`;
    const viewingDetails ={
        component : displayName,
        path:window.location.pathname
    };

    const patient = {
      user: _.get(this.props, 'user'),
      enrolledPrograms: _.get(this.props, 'data.enrolledProgramList.data')
    };

    return (
      <div>
        <TimerContainer displayName={displayName} category='PATIENT_PROFILE'
                        action='REVIEW' patientId={patientId} viewingDetails={viewingDetails} canRestUUid={true}/>
        <MemberDetail patientId={patientId}
                      currentProgramNames={currentProgramNames}
                      showStickyHeader={showStickyHeader}
                      currentProgramId={ _.get(this,'currentProgram.id') }
        />

        <div style={{minWidth:'100%'}}>
            <PatientAppointmentContainerWrapper patientId={ patientId }
                                                patientName = { patientName }
                                                patientBirthday = { patientBirthday }
                                                className='inChat'
                                                doctor={doctor}
                                                isInChat = { true }
                                                patient = {patient}
                                                router={this.props.router} route={this.props.route}

            />
        </div>
        {items.length > 1?
          <div style={{
            display: 'flex',
            padding: '18px 44px',
            alignItems: 'center',
            background: 'white',
            justifyContent: 'space-between',

          }}>{this.renderSelect(items)}</div>
          :(items.length === 0? <NoActiveProgram/> : null)
        }
        {
          this.currentProgram ?
            <EnrolledProgramInfo patientId={patientId} refetchEnrolledProgramList={refetch}
                                 enrolledProgramId={_.get(this,'currentProgram.id')} currentProgram={this.currentProgram}
                                 location={this.props.location} {...this.props} canRestUUid={false}
                                 isInChat = { true }
                                 patientName={patientName} tabSelected = {tabSelected} updateTabSelected={this.updateTabSelected}
            />
            :
            null
        }

      </div>
    );
  }
};

const mapToProps = (state)=>{
  return {
    menuOpened: state.Nav.menuOpened
  }
}
const mapDispatch = (dispatch, ownProps) => {
  return {
    setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
    openAlertModal: (opts) => dispatch(modalAction.alert(opts)),
    openModal: (content, row) => dispatch(openModal(content, row)),
    closeModal:()=>dispatch(closeModal()),
    setInitDeviceData: (table, value) => dispatch(actions.setInitDeviceData(table, value)),
  }
};
const enhance = compose(
  wrapGQLData2,
  withProps(()=>{
    const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
    const viewerId = viewerInfo ? R.last(R.split(':', atob(viewerInfo.id))) : null;
    return { viewerInfo, viewerId }
  }),
  graphql(userGQL, {
    options : ({ patientId })=>({
      variables : { id: patientId },
    }),
    props: ({ ownProps, data: { loading, user }}) => ({
      patientLoading: loading,
      patientName: loading ? '' : R.path(['profile', 'fullName'], user ),
      patientBirthday: loading ? '' :R.path(['profile','birthday'],user),
      doctor:loading ? '':R.path(['profile','doctor'],user),
      user: loading ? '' : user
    }),
  }),
);



export default createContainer(enhance(ProviderViewPatientContainer), mapToProps, mapDispatch);
