/**
 * Created by zizhangai on 1/2/17.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import { IHSelect, IHButton, _, moment,message } from 'ihcomponent'
import { Input,DatePicker,Alert } from 'antd';
const { RangePicker } = DatePicker;
//mutation
// import intervene from 'modulesAll/graphql/intervene'
import RequestCache from 'libModule/requestCache'
import { createProviderNoteToResult as createProviderNote } from 'modulesAll/graphql/createProviderNote'
import { updateQueries } from 'modulesAll/graphql/createProviderNote'
import { openErrorModal,closeModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'libModule/utils';
import { addNewNote } from '../actions/InterventionsActions'
import { Checkbox } from 'antd';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import TimerContainer from '../../../timer/containers/TimerContainer';
// import {closeModal} from "../../../layout/actions/MainModal";
import actions  from '../../../timer/actions/';
import {API} from "../../../timer/query";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const { stopTimer, updateInterventionDetails }= actions;
const COOLDOWN_INMS = 60000;

class InterventionNoteForm extends Component {
  static displayName = 'AddInterventionNoteFormComponent'

  constructor(props) {
    super(props);
    const { setCategoryToAlerts } = props;
    this.state = {
      category: 'ALERTS',
      subCats: [],
      note: '',
      isInputTouched: false,
      isDropDownTouched: false,
      startTime:null,
      stopTime:null,
      startTimeError:false,
      stopTimeError:false,
      validCallTime:true,
      callTimeString:''
    }

    this._getButtonProps = this._getButtonProps.bind(this)
    this._submit = this._submit.bind(this)
    this._getDropDownProps = this._getDropDownProps.bind(this)
  }
    onChange(value) {
        const startTime = value[0];
        const stopTime = value[1];
        const startString = moment(startTime).format('MM-DD-YYYY HH:mm');
        const startTimeError = !!startTime;
        const stopTimeErro = !!stopTime;
        const isInTheFuture = moment(stopTime).isAfter(new Date());

        let validCallTime = true;
        if(startTime&&stopTime) {
            if (stopTime.format('HH:mm:ss') === startTime.format('HH:mm:ss')||isInTheFuture) {
                validCallTime = false;
            }
        }
        const stopString = moment(stopTime).format('MM-DD-YYYY HH:mm');
        const callTimeString = startString +' - ' + stopString;
        this.setState({
            startTime,
            stopTime,
            startTimeError:!startTimeError,
            stopTimeError:!stopTimeErro,
            callTimeString,
            validCallTime
        })
    }

    onOk(value) {
        const startTime = value[0];
        const stopTime = value[1];
        const startString = moment(startTime).format('MM-DD-YYYY HH:mm');
        const startTimeError = !!startTime;
        const stopTimeErro = !!stopTime;
        const isInTheFuture = moment(stopTime).isAfter(new Date());

        let validCallTime = true;
        if(startTime&&stopTime) {
            if (stopTime.format('HH:mm:ss') === startTime.format('HH:mm:ss')||isInTheFuture) {
                validCallTime = false;
            }
        }
        const stopString = moment(stopTime).format('MM-DD-YYYY HH:mm');
        const callTimeString = startString +' - ' + stopString;
        this.setState({
            startTime,
            stopTime,
            startTimeError:!startTimeError,
            stopTimeError:!stopTimeErro,
            callTimeString,
            validCallTime
        })
    }

    renderCallLog(){
        const dropDown = this.state.category;
        const { startTimeError,validCallTime,startTime,stopTime } = this.state;
        let errorMsg = startTimeError ? "Please select Call Date": (validCallTime ? '':'Invalid time selection');
        // check selected stop time is later than now
        const isInTheFuture = moment(stopTime).isAfter(new Date());
        errorMsg = isInTheFuture ?  'Cannot select a future time period' : errorMsg;
        const errorDiv = (startTimeError||!validCallTime||isInTheFuture)
            ? <Alert type="error" message={ errorMsg } className='callLogAlert' showIcon/>
            : ''
        if(dropDown!='CALL_LOG'){
            return;
        }

        return <div className='row callLog'>
            <div className='col-lg-12' style={{'display': 'flex',
                alignItems: 'flex-end'}}>
                <span style={{fontWeight:'600',color:'#474747'}}>Call Time</span>
            </div>
            <div className='col-lg-12' style={{'display': 'flex',
                alignItems: 'flex-end',margin:'10px 0'}}>
                <RangePicker
                    showTime={{
                        format:"HH:mm",
                        defaultValue: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')],
                    }}                    format="MM-DD-YYYY HH:mm"
                    placeholder={['Start Time', 'End Time']}
                    onOk={(value)=>this.onOk(value)}
                    onChange={(value)=>this.onChange(value)}
                />
            </div>
            <div className='col-lg-12' >
                {errorDiv}
            </div>
        </div>
    }

  render() {
    const sp = this._getDropDownProps()
    const bp = this._getButtonProps()
    const displayName = this.constructor.displayName;
    const patientId = this.props.row.patientId || this.props.row.user.id;
    const viewingDetails = {
        component : displayName,
        path:window.location.pathname
    }
    const categorySelected = !!this.state.category;
    return (
      <div className="intervention-notes-form">
        <TimerContainer displayName={displayName} category='VITALS' action='REVIEW'
                        patientId={patientId} viewingDetails={viewingDetails}/>
        <div className="intervention-notes-form-header"><span>Select Note Type </span>{/* (* will be sent to NEHR) */}</div>
        <div className={"intervention-notes-dropDown-wrapper " + ((this.state.isDropDownTouched && !this.state.category)? 'has-error': '')} >
          <IHSelect {...sp} style={{width: '200px'}} />
          <div className="ant-form-explain" style={{display: (this.state.isDropDownTouched && !this.state.category)? null: 'none'}}>Cannot be empty!</div>
        </div>
        {/*<div className="intervention-notes-subcats">*/}
          {/*{*/}
            {/*this.state.subCats.map((d, i) => (*/}
              {/*<div key={i}>*/}
                {/*<Checkbox onChange={this._checkBoxOnChange.bind(this)} value={d.value} checked={d.checked}>{d.value}</Checkbox>*/}
              {/*</div>*/}
            {/*))*/}
          {/*}*/}
        {/*</div>*/}
        {/*<div>*/}
          {/*{*/}
            {/*this.state.subCats.filter(d => d.checked).map((d, i) => (*/}
              {/*<div key={i}>{d.value}</div>*/}
            {/*))*/}
          {/*}*/}
        {/*</div>*/}
        {this.renderCallLog()}
        <div className={"intervention-notes-textArea-wrapper" +
          ((this.state.isInputTouched && (!this.state.subCats.some(d => d.checked) && !this.state.note))? ' has-error': '')}>

          <Input.TextArea autosize={{minRows:4,maxRows: 25}} disabled={!categorySelected}
                 value={this.state.note}
                 size={"large"}
                 onChange={(e) => {
                   this.setState({ note: e.target.value, isInputTouched: true })
                 }}
                 placeholder="Add Note Here"
          />

          <div className="ant-form-explain"
               style={{display: (this.state.isInputTouched && (!this.state.subCats.some(d => d.checked) && !this.state.note))? null: 'none'}}>Cannot be empty</div>
        </div>
        <div className="intervention-notes-button-wrapper">
          <IHButton {...bp}/>
        </div>
      </div>
    )
  }

  _checkBoxOnChange(e) {
    this.setState({
      subCats: this.state.subCats.map(n => (n.value !== e.target.value)? n: { ...n, checked: e.target.checked }),
      note: e.target.checked? (this.state.note?`${this.state.note}\r\n${e.target.value}`
        :e.target.value)
        :this.state.note
    })
    // delay this action so user can see checkbox change
    setTimeout(() => {
      this.setState({
        isInputTouched: true
      })
    }, 300)
  }

  _getDropDownProps() {
    let notes = JSON.parse(sessionStorage.getItem('presetNotes'));
    notes = _.filter(notes,(n)=>n.category!="RE_ENGAGEMENT");
    const option = notes.map(n => ({
      name: n.display,
      value: n.category
    }))
    const pSelect = {
      option,
      placeholder : 'Select...',
      value: this.state.category,
      disabled: true,
      onChange: (v) => {
        this.setState({
          category: v,
          subCats: _.find(notes, ['category', v]).subCategories.map(n => ({ value: n, checked: false })),
          note: _.find(notes, ['category', v]).subCategories.join('\r\n')
        })
      },
      size: "large",
    }
    return pSelect
  }

  _getButtonProps() {
    const bp = {
      label: 'Submit',
      bsStyle: 'primary',
      ref: 'btn_sub_note',
      disabled: this.state.note.trim() == "" ? true : false,
      onClick: this._submit,
    }
    return bp
  }

  createCallLogTimer(docId){
        let variables;
        const clientEvents = [];
        const { startTime,stopTime } = this.state;
        const { sessionId } = this.props;
        const userId = _.get(this.props.row,'user.id',_.get(this.props.row,'patientId'),'');
        const viewerId = sessionStorage.getItem('currentUserId');
        const category = 'CALL_LOG';
        const action = 'ENTER';
        const totalTime = stopTime-startTime;
        let interventionDetails = [{
            apiName:'createCallLog',
            resource:'provider_notes',
            docId:docId,
            summary:'call log'
        }]

        let viewingDetails = {
            component:this.constructor.displayName,
            path:window.location.pathname
        }

        clientEvents.push({
            event:'startCall',
            action:'START_TIMER',
            ts:startTime
        })

        clientEvents.push({
            event:'endCall',
            action:'STOP_TIMER',
            ts:stopTime
        })

        variables = {
            clientEvents:clientEvents,
            startTime,
            stopTime,
            userId,
            viewerId,
            category,
            action,
            totalTime,
            interventionDetails,
            viewingDetails,
            sessionId,
            timeout:COOLDOWN_INMS
        }
        API.createTimeTrackingEvent(variables);
    }

  _submit = () => {
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientName = _.get(this.props.row, 'user.profile.fullName') || _.get(currentPatient, 'profile.fullName')
    const patientNRIC = _.get(this.props.row, 'user.identification.0.value') || _.get(currentPatient, 'identification.0.value')
    const patientId = _.get(this.props.row,'user.id');
    const { category, subCats, note,startTime,stopTime,callTimeString,validCallTime } = this.state
    const { id, mutate } = this.props;
    
    Mixpanel.track('clicked','submit','alert', {NOTE_TYPE: this.state.category});

    if(category == 'CALL_LOG'){
        if(!startTime){
            this.setState({
                startTimeError:true
            })
            return;
        }
        if(!stopTime){
            this.setState({
                stopTimeError:true
            })
            return;
        }
        if(!validCallTime){
            return;
        }
    }

    if (!category || (!subCats.some(d => d.checked) && !note)) {
      this.setState({
        isDropDownTouched: true,
        isInputTouched: true,
      })
      return
    }

    const variables = {
      category,
      resultId: id,
      content: callTimeString +" "+ note//subCats.filter(d => d.checked).map(d => d.value).concat([note]).join('\r\n')
    }

    let auditLogDetails
    if(this.props.row.type && (this.props.row.type === 'ASSESSMENT' || this.props.row.type === 'SURVEY')) {
      auditLogDetails = {
        noteDetails: {
          ..._.omit(variables, 'resultId')
        },
        recordDetails: {
          dateTime: moment(this.props.row.date).format('MMM-DD-YYYY hh:mm a'),
          programName: this.props.row.enrolledProgram.name,
          type: this.props.row.type,
          name: this.props.row.assessment.name,
        }
      }
    } else {
      auditLogDetails = {
        noteDetails: {
          ..._.omit(variables, 'resultId')
        },
        recordDetails: {
          dateTime: moment(this.props.row.date).format('MMM-DD-YYYY hh:mm a'),
          programName: this.props.row.enrolledProgram.name,
          type: this.props.row.type,
          reading: this.props.row.measure &&
                   _.omit(this.props.row.measure, ['id', 'type', 'beforeMeal', 'date', 'imageUrl', 'mealType', 'user_notes'])
        }
      }
    }

    this.refs.btn_sub_note.loading(true)
    mutate({ variables, updateQueries })
      .then(res => {
        this.refs.btn_sub_note.loading(false);
        const apiName = Object.keys(res.data)[0];
        const docId = res.data[apiName].id;
        const summary = res.data[apiName].content;
        const resource = atob(docId).split(':')[0];
        let displayName = this.constructor.displayName;
        const dropDown = this.state.category;
        displayName = `${displayName}:${patientId}`;

        if(dropDown=='CALL_LOG') {
            this.createCallLogTimer(docId);
        }

        this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
        this.props.stopTimer(displayName,'saveModal');

        this.setState({
            category: 'ALERTS',
            subCats: [],
            note: '',
            isInputTouched: false,
            isDropDownTouched: false,
            startTime:null,
            stopTime:null,
            startTimeError:false,
            stopTimeError:false,
            validCallTime:true,
            callTimeString:''
        })

        // this.props.closeModal();
        message.success('Note Created!')

        createAuditLog({
          action: I18N.get(`auditLog.provider.alerts.create_${this.props.noteType}`),
          details: auditLogDetails,
          request: variables,
          response: res,
          patientNRIC,
          patientName
        })
      })
      .catch((err) => {
        this.refs.btn_sub_note.loading(false)
        createAuditLog({
          action: I18N.get(`auditLog.provider.alerts.create_${this.props.noteType}`),
          details: auditLogDetails,
          request: variables,
          response: err,
          success: false,
          patientNRIC,
          patientName
        })
        this.props.openErrorModal(GQLHelper.formatError(err))
      })
  }
}

const mapToProps = (state,props)=>{
    return {
        sessionId:state.timer.main.uuid,
    }
}

export default compose(
  graphql(createProviderNote),
  connect(mapToProps,
      { openErrorModal, addNewNote,
      stopTimer,
      updateInterventionDetails
})
)(InterventionNoteForm)