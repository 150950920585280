import React from 'react';
import TaskListFormComponent from '../components/TaskListFormComponent';
import { API } from '../query/';
import { goPath, FormHelper, GQLHelper, setAsyncRouteLeaveHook, submittingStyle } from 'libModule/utils'
import { connect } from "react-redux";
import actions  from 'modulesAll/common/actions/modal'

let TaskListFormContainer = class extends React.Component {
    static displayName = 'TaskListFormContainer';
    constructor(){
        super();

    }

    componentWillUnmount(){
        const { setIsSaved } = this.props;
        setIsSaved(true);
    }

    handleOnSubmit = (variables) =>{

        API.updateVisitFromVisitList(variables)
            .then(()=>this.props.setIsSaved(true))
            .catch((error)=>console.log(error))

    }

    render(){
        return <TaskListFormComponent handleOnSubmit={ this.handleOnSubmit }
                                      data={this.props.data} setIsSaved={this.props.setIsSaved}/>
    }
}
const mapToDispatch = (dispatch)=> {
    return {
        openConfirmModal: (props) => dispatch(actions.confirm(props)),
    }
}

export default connect(null,mapToDispatch)(TaskListFormContainer);

