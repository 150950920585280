import React from 'react';
import { Redirect, Route, IndexRoute } from 'react-router';

// Lib
import I18N from 'modulesAll/I18N';
import { requireAuth, setLocation, enterIndex } from './lib/accountsClient';
import Store from 'libModule/reduxStore';

// Modules
import MainLayout from './modules/layout/containers/MainLayoutContainer';
import PreLoginLayout from './modules/layout/containers/PreLoginLayoutContainer';

import { setBreadCrumb } from 'modulesAll/layout/actions/Nav';

import NotFound from './modules/layout/components/NotFoundComponent';
import NotAuthorized from 'modulesAll/common/components/NotAuthorized';
import Dashboard from './modules/dashboard/main/containers/main';
import {
  ProgramNew,
  ProgramEdit,
  ProgramList,
  ProgramListAdmin,
  ProgramIncomplete
} from './modules/program/Common/containers';

import PatientDetail from 'modulesAll/patient/profileDetail/containers/main';
import PatientEdit from 'modulesAll/patient/profileEdit/containers';
import PatientTimeSpent from 'modulesAll/patient/TimeSpent/containers/TimeSpentContainer';
import PatientPage from './modules/patient/main/containers/main';
import PatientSettings from './modules/patient/Settings/containers/settingsContainer';
import PatientProgramSummary from './modules/patient/main/containers/ProgramSummaryMain';
import ProgramSummary from 'modulesAll/program/Summary/containers/main';
import PatientReimport from 'modulesAll/patient/profileReimport/containers/main';

import PatientListPage from './modules/patientList/main/containers/main';
import PatientRegisterNewImportPatientInfo from './modules/patientList/registerNew/containers/mainImportPatientInfo';
import PatientRegisterNewManualInput from './modules/patientList/registerNew/containers/mainManualInput';
import { AddLabResults } from './modules/patient/main/containers/AddLabResults';

import LoginPage from './modules/auth/containers/LoginContainer';
// import Onboard from './modules/onboard/containers/SimpleOnboardContainer';
import Onboard from './modules/onboard/containers/OnboardVerificationContainer';
import OnboardPassword from './modules/onboard/containers/OnboardPasswordContainer';
import ResetPassword from './modules/auth/containers/ResetPasswordContainer';
import ChangePwContainer from './modules/patient/main/containers/ChangePwContainer';
import NewPwContainer from './modules/patient/main/containers/NewPwContainer';
// import DeleteUser from './modules/auth/containers/DeleteUserContainer';
import intermediaLogOutComponent from './modules/auth/components/intermediaLogOutComponent';
import PatientTaskList from './modules/patientView/patientTaskList/components/PatientTaskListComponent';
import VitalList from './modules/patientView/vitalList/containers/main';
import EmergencyContacts from './modules/patient/emergencyContacts/components/emergencyContactsComponent';
import MyPrograms from './modules/patientView/myPrograms/containers/MyProgramsContainer';
import MyProgramSummary from './modules/patientView/myPrograms/components/Summary';
// provider
import {
  ProviderNew,
  ProviderEdit,
  ProviderList,
  ProviderDetail
} from './modules/provider';

// task
import ProviderTaskList from './modules/providerTask/TaskList/containers/TaskListContainer';

// Inventory
import InventoryWrapper from './modules/inventory/container/InventoryWrapper';

// tagged patients
import TaggedPatientsList from './modules/providerTaggedPatients/containers/TaggedPatientsContainer';

// inactive patients
import InactivePatientsList from './modules/patientList/inactivePatients/containers/InactivePatientsContainer';

// alerts
import ProviderAlertList from './modules/alerts/ProviderAlerts/containers/ProviderAlertListContainer';

// admin assessment & survey
import AssessmentList from './modules/assessment/main/containers/ListContainer';
import AdminAssessmentCreate from './modules/assessment/edit/containers/CreateContainer';
import AdminAssessmentEdit from './modules/assessment/edit/containers/EditContainer';
import AdminPreviewAssessment from './modules/assessment/preview/components/PreviewComponent';

import SurveyList from './modules/survey/main/containers/ListContainer';
import AdminSurveyCreate from './modules/survey/edit/containers/CreateContainer';
import AdminSurveyEdit from './modules/survey/edit/containers/EditContainer';
import AdminPreviewSurvey from './modules/survey/preview/components/PreviewComponent';

// nurse assessment & survey
import AssessmentListNurse from './modules/assessment/main/containers/NurseListContainer';
import SurveyListNurse from './modules/survey/main/containers/NurseListContainer';
import NursePreviewSurvey from './modules/survey/preview/components/NursePreviewComponent';
import NursePreviewAssessment from './modules/assessment/preview/components/NursePreviewComponent';

// vendor
import PatientVendor from './modules/vendor/patient/containers';

// my assessment and survey
import myAssessmentAndSurveyList from './modules/patientView/myAssessmentAndSurvey/main/containers/ListContainer';
import PatientNewAssessment from './modules/patientView/myAssessmentAndSurvey/edit/containers/NewContainer';
import PatientUpdateAssessment from './modules/patientView/myAssessmentAndSurvey/edit/containers/UpdateContainer';
import PatientPreviewAssessment from './modules/patientView/myAssessmentAndSurvey/preview/components/PreviewComponent';

import CareTeamListPage from 'modulesAll/careteam/list/containers/main';
import CareTeamNew from 'modulesAll/careteam/createCareTeam/containers/NewCareTeamContainer';
import CareTeamEdit from 'modulesAll/careteam/editCareTeam/containers';

//Broadcasts
import BroadcastList from './modules/broadcasts/main/containers/ListContainer';
import BroadcastNew from './modules/broadcasts/create/containers/CreateContainer';
import BroadcastEdit from './modules/broadcasts/edit/containers';

// temp enrolled patients
import TempEnrolledPatients from 'modulesAll/tempEnrolledPatients/containers/TempEnrolledPatientsListContainer';

// SuperAdmin
import {
  SuperAdmin,
  TroubleshootingList,
  TroubleshootingOverview,
  AppAnalytics,
  AuditTrail,
  Organization,
  OrganizationCreate,
  OrganizationEdit,
  Providers,
  ProviderEditRole,
  SystemDynamicConfigs
} from './modules/superAdmin';

// chat
import ChatWindow from './modules/chat/containers/ChatContainer';
import FoodLogWindow from './modules/foodLog/containers/FoodLogContainer';

//appointments
import AppointmentCalenderWrapperContainer from './modules/visit/containers/AppointmentCalenderWrapperContainer';

//smart alert
import SmartAlertListContainer from './modules/smartAlert/container/SmartAlertListContainer';

//upload
import UploadImgComponent from './modules/upload/components/UploadImgComponent';

//task list
import TaskAssignmentListContainer from './modules/taskAssignment/containers/TaskAssignmentListContainerWrapper';

//visit work list
import WorkListContainer from './modules/visitWorkList/container/workListContainer';
function setNavVal(value) {
  return (nextState, replace) => {
    Store.dispatch(setBreadCrumb(value));
  };
}

const routes = (
  <Route>
    <Route component={PreLoginLayout}>
      <Route path='/onboard' components={{ main: Onboard }} />
      <Route path='/onboard-password' components={{ main: OnboardPassword }} />
      <Route path='/login' components={{ main: LoginPage }} />
      <Route path='/reset-password' components={{ main: ResetPassword }} />
      <Route path='/logout' components={{ main: intermediaLogOutComponent }} />
    </Route>

    <Route path='/' component={MainLayout} onEnter={requireAuth}>
      <IndexRoute components={Dashboard} onEnter={enterIndex} />
      /**************************** dashboard ****************************/
      <Route
        path='dashboard'
        components={Dashboard}
        triggerRouter={true}
        onEnter={setNavVal('Dashboard')}
      />
      /**************************** admin assessment & survey
      ****************************/
      <Route
        path='assessments'
        components={AssessmentList}
        onEnter={setNavVal(I18N.get('keywords.Assessments'))}
      />
      <Route
        path='create_assessment'
        components={AdminAssessmentCreate}
        onEnter={setNavVal(`${I18N.get('keywords.Assessments')} / New`)}
      />
      <Route
        path='edit_assessment/:assessmentId'
        components={AdminAssessmentEdit}
      />
      <Route
        path='admin_preview_assessment/:assessmentId'
        components={AdminPreviewAssessment}
      />
      <Route
        path='surveys'
        components={SurveyList}
        onEnter={setNavVal('Surveys')}
      />
      <Route
        path='create_survey'
        components={AdminSurveyCreate}
        onEnter={setNavVal('Surveys / New')}
      />
      <Route path='edit_survey/:surveyId' components={AdminSurveyEdit} />
      <Route
        path='admin_preview_survey/:surveyId'
        components={AdminPreviewSurvey}
      />
      /**************************** patient assessment & survey
      ****************************/
      <Route
        path='assessments_surveys'
        components={myAssessmentAndSurveyList}
        onEnter={setNavVal(`${I18N.get('keywords.Assessments')} & Surveys`)}
      />
      <Route
        path='create_assessment_filled/:assessmentId/:refId'
        components={PatientNewAssessment}
      />
      <Route
        path='update_assessment_filled/:assessmentFilledId'
        components={PatientUpdateAssessment}
      />
      <Route
        path='preview_assessment_filled/:assessmentFilledId'
        components={PatientPreviewAssessment}
      />
      <Route
        path='create_survey_filled/:assessmentId/:refId'
        components={PatientNewAssessment}
      />
      <Route
        path='update_survey_filled/:assessmentFilledId'
        components={PatientUpdateAssessment}
      />
      <Route
        path='preview_survey_filled/:assessmentFilledId'
        components={PatientPreviewAssessment}
      />
      /**************************** nurse assessment & survey
      ****************************/
      <Route
        path='nurse_assessments'
        components={AssessmentListNurse}
        onEnter={setNavVal(I18N.get('keywords.Assessments'))}
      />
      <Route
        path='nurse_surveys'
        components={SurveyListNurse}
        onEnter={setNavVal('Surveys')}
      />
      <Route
        path='nurse_preview_assessment/:assessmentId'
        components={NursePreviewAssessment}
      />
      <Route
        path='nurse_preview_survey/:surveyId'
        components={NursePreviewSurvey}
      />
      /**************************** programmes ****************************/
      <Route path='programmes_admin/:id/edit' components={ProgramEdit} />
      <Route
        path='programmes_admin/:id/edit-incomplete'
        components={ProgramIncomplete}
      />
      <Route
        path='programmes'
        components={ProgramList}
        onEnter={setNavVal(I18N.get('keywords.Programs'))}
      />
      <Route
        path='programmes_admin'
        components={ProgramListAdmin}
        onEnter={setNavVal(I18N.get('keywords.Programs'))}
      />
      <Route
        path='programmes/new'
        components={ProgramNew}
        onEnter={setNavVal(`${I18N.get('keywords.Programs')} / New`)}
      />
      /**************************** patients ****************************/
      <Route
        path='patients/:patientId/enrolledprogram/:enrolledProgramId'
        components={PatientPage}
        triggerRouter={true}
      />
      <Route path='patients/profile/:patientId' components={PatientDetail} />
      <Route path='patients/profile/:patientId/edit' components={PatientEdit} />
      <Route path='providers/profile/:providerId' components={ProviderDetail} />
      <Route
        path='providers/profile/:providerId/edit'
        components={ProviderEdit}
      />
      <Route path='programmes/:programId' components={ProgramSummary} />
      <Route path='myprogrammes/:programId' components={MyProgramSummary} />
      <Route
        path='patients/profile/:patientId/reimport'
        components={PatientReimport}
      />
      <Route
        path='patients/:patientId/change-password'
        components={ChangePwContainer}
      />
      <Route
        path='patients/:patientId/new-password'
        components={NewPwContainer}
      />
      <Route
        path='patients/:patientId/addlabresults'
        components={AddLabResults}
        triggerRouter={true}
      />
      <Route
        path='patients/:patientId/timespent'
        components={PatientTimeSpent}
        triggerRouter={true}
      />
      /************************ User Troubleshooting *******************/
      <Route
        path='superadmin'
        components={() => <div key='0'> comp0 </div>}
        onEnter={setNavVal('Super admin')}
      />
      <Route
        path='superadmin/audittrail'
        components={AuditTrail}
        onEnter={setNavVal('Audit Trail - Overview')}
      />
      <Route
        path='superadmin/troubleshooting'
        components={TroubleshootingList}
        onEnter={setNavVal('User Troubleshooting - List')}
      />
      <Route
        path='superadmin/troubleshooting/:userId'
        components={TroubleshootingOverview}
        onEnter={setNavVal('User Troubleshooting - Details')}
      />
      <Route
        path='superadmin/appanalytics'
        components={AppAnalytics}
        onEnter={setNavVal('App Analytics')}
      />
      <Route
        path='superadmin/organizations'
        components={Organization}
        onEnter={setNavVal('Organizations - List')}
      />
      <Route
        path='superadmin/organizations/Create'
        components={OrganizationCreate}
        onEnter={setNavVal('Organizations - Create')}
      />
      <Route
        path='superadmin/organizations/:organizationId/Edit'
        components={OrganizationEdit}
        onEnter={setNavVal('Organizations - Edit')}
      />
      <Route
        path='superadmin/providers'
        components={Providers}
        onEnter={setNavVal('Provider - List')}
      />
      <Route
        path='superadmin/providers/:providerId/setrole'
        components={ProviderEditRole}
        onEnter={setNavVal('Provider - EditRole')}
      />
      <Route
        path='superadmin/systemdynamicconfigs'
        components={SystemDynamicConfigs}
        onEnter={setNavVal('SystemDynamicConfigs - List')}
      />
      /**************************** vendors ****************************/
      <Route
        path='patient_vendor'
        components={PatientVendor}
        onEnter={setNavVal('Patients')}
      />
      <Route
        path='patients'
        components={PatientListPage}
        onLeave={PatientListPage.onLeave}
        onEnter={setNavVal('Patients')}
      />
      <Redirect
        from='patients/:patientId'
        to='patients/:patientId/enrolledprogram/default'
      />
      <Route
        path='patients/register/import_patient_info'
        components={PatientRegisterNewImportPatientInfo}
        onEnter={setNavVal('Patients / New / Import Patient')}
      />
      <Route
        path='patients/register/manual_input'
        components={PatientRegisterNewManualInput}
        onEnter={setNavVal('Patients / New / Manual Input')}
      />
      <Route
        path='patients/program/enroll'
        components={PatientProgramSummary}
        onEnter={setLocation}
      />
      <Route path='patient/settings' components={PatientSettings} />
      // provider
      <Route
        path='providers/new'
        components={ProviderNew}
        onEnter={setNavVal('Providers / New')}
      />
      <Route
        path='providers'
        components={ProviderList}
        onEnter={setNavVal('Providers')}
      />
      // task
      <Route
        path='tasks'
        components={ProviderTaskList}
        onEnter={setNavVal('Tasks')}
      />
      <Route
        path='alerts'
        components={ProviderAlertList}
        onEnter={setNavVal('Alerts')}
      />
      // inventory
      <Route
        path='inventory'
        components={InventoryWrapper}
        onEnter={setNavVal('Inventory')}
      />
      // tagged patients
      <Route
        path='tagged_patients'
        components={TaggedPatientsList}
        onEnter={setNavVal('Tagged Patients')}
      />
      // inactive patients
      <Route
        path='inactive_patients(/:page)'
        components={InactivePatientsList}
        onEnter={setNavVal('Patients')}
      />
      // temp enrolled patients
      <Route
        path='tempenrolledpatients'
        components={SmartAlertListContainer}
        onEnter={setNavVal('Temp Enrolled Patients')}
      />
      // chat
      <Route
        path='messages'
        components={ChatWindow}
        onEnter={setNavVal('Messages')}
      />
      <Route
        path='foodLog'
        components={FoodLogWindow}
        onEnter={setNavVal('Food Diary')}
      />
      <Route
        path='mytasks'
        components={PatientTaskList}
        onEnter={setNavVal('My Tasks')}
      />
      <Route
        path='myvitals'
        components={VitalList}
        onEnter={setNavVal('My Vitals')}
      />
      <Route
        path='emergency_contact'
        components={EmergencyContacts}
        onEnter={setNavVal('My Emergency Contacts')}
      />
      <Route
        path='myprogrammes'
        components={MyPrograms}
        onEnter={setNavVal(`My ${I18N.get('keywords.Programs')}`)}
      />
      <Route
        path='careteams'
        components={CareTeamListPage}
        onEnter={setNavVal('Care Teams')}
      />
      <Route
        path='careteams/new'
        components={CareTeamNew}
        onEnter={setNavVal('Care Teams / New')}
      />
      <Route path='careteams/edit/:careteamId' components={CareTeamEdit} />
      //smart alert
      <Route path='smartalert' components={SmartAlertListContainer} />
      /**************************** Broadcasts ****************************/
      <Route
        path='broadcasts'
        components={BroadcastList}
        onEnter={setNavVal('Broadcasts')}
      />
      <Route
        path='broadcasts/new'
        components={BroadcastNew}
        onEnter={setNavVal('Broadcasts / New')}
      />
      <Route
        path='broadcasts/edit/:broadcastId'
        components={BroadcastEdit}
        onEnter={setNavVal('Broadcasts / Edit')}
      />
      <Route
        path='audit_trails'
        components={SuperAdmin}
        onEnter={setNavVal('Audit Trail')}
      />
      <Route path='TaskAssignments' components={TaskAssignmentListContainer} />
      <Route
        path='appointments/reschedule/:visitId'
        components={AppointmentCalenderWrapperContainer}
        onEnter={setNavVal('Appointments')}
      />
      <Route
        path='appointments/create/:patientId/:fullName(/:birthday)'
        components={AppointmentCalenderWrapperContainer}
        onEnter={setNavVal('Appointments')}
      />
      <Route
        path='appointments'
        components={AppointmentCalenderWrapperContainer}
        onEnter={setNavVal('Appointments')}
      />
      <Route path='visitWorList' components={WorkListContainer} />
      <Route
        path='not-authorized'
        components={NotAuthorized}
        onEnter={setNavVal('Not authorized')}
      />
      <Route path='upload' components={UploadImgComponent} />
      <Route path='*' components={NotFound} />
    </Route>
  </Route>
);

export default routes;
